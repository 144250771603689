import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Form,Table,Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class statusnotificationlist extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          idTagSorting: false,
          selectFindStatus : false,
          saveSelectedIdTag:"",
          idTags:"",
          selectDataArray:[],
          notificationType:[],
          models:[],
          intial_data:[],
          items:[],
          list: [],
          activeArray: [],
          model1:"false" ,
          model2:"false",
          model3:"false",
          page_title:"Charge Point OCPP Tags",
          fields:{
            modelChange:"",
            typeChange:""
         },
         dataArray:[],
      
        noOfRecords:'',
        data:new Map(),
        pageSize:"10",
        pageNo: 1,
         activePage: 1,
        model:"",
        type:"",
        delete_msg:"",
        deleteHeader:"",
        deleteButton:"",
        deleteText:"",
        deletecolor:"danger",
        modalsession:false,
        user:{
          permissions: [
               
              ]
          },
          errors: {},

    }
    this.showEntriesChange=this.showEntriesChange.bind(this);
    this.addNew = this.addNew.bind(this);
    this.redirectViewPage = this.redirectViewPage.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggleactive = this.toggleactive.bind(this);
    this.toggledeactive = this.toggledeactive.bind(this);
    this.tagOk2 = this.tagOk2.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.tagOk3 = this.tagOk3.bind(this);
    this.activeChargePoint = this.activeChargePoint.bind(this);
    this.deactiveChargePoint = this.deactiveChargePoint.bind(this);
    this.modelChange = this.modelChange.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.gettypechangeFromDb = this.gettypechangeFromDb.bind(this);
    this.getConnectorsFromDb=this.getConnectorsFromDb.bind(this);
    this.togglesession = this.togglesession.bind(this);

   
  
  
   
    this.sortingExpiryTime = this.sortingExpiryTime.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);

  }

  async togglesession(){
   
    this.setState({
    modalsession: !this.state.modalsession
    });
  }
  
  
  sessiontagOk(){
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
  
  showEntriesChange(){
    var entries=document.getElementById("showEntries").value;
    // this.state.data.clear();
    // this.setState({pageSize:entries});
    this.state.activePage=1;
    this.state.pageSize=entries;
   
    this.getConnectorsFromDb(this.state.pageSize,this.state.activePage);
  }
addNew(){
    this.props.history.push('/addstatusnotificationlist');
};

toggle1(event){
    this.setState({
        modal1: !this.state.modal1
      });
      // event.preventDefault();
  if(this.state.list[0]!="" && this.state.list[0]!=undefined){
  

    this.setState({
      delete_msg:"Are you sure you want to delete?",
      deleteHeader:"Confirmation",
      deleteButton:"",
      deleteText:"Close",
      deletecolor:"danger"
  })
  }else{
  
    this.setState({
        delete_msg:"Please select Push Id.",
        deleteHeader:"Warning",
        deleteButton:"d-none",
        deleteText:"Ok",
        deletecolor:"primary"
    })
  }
  
}
toggle2 = () => {
  this.setState({
    modal2: !this.state.modal2
  });
}
toggleactive = () => {
  this.setState({
    modalactive: !this.state.modalactive
  });
}
toggledeactive = () => {
  this.setState({
    modaldeactive: !this.state.modaldeactive
  });
}
toggle3 = () => {
  this.setState({
    modal3: !this.state.modal3
  });
}
tagOk3(){
 
  this.setState({
    modal3: !this.state.modal3
  });

}
tagOk2(){

  window.location.reload();
 }
 handlePageChange(pageNumber) {


  if(pageNumber !=this.state.activePage){
    var data=this.state.data;
    var pageData=data.get(pageNumber);
    if(pageData==undefined){
    this.setState({activePage: pageNumber});
    this.getConnectorsFromDb(this.state.pageSize,pageNumber);
    }else{
      this.setState({activePage: pageNumber});
      this.setState({dataArray:pageData})
    }
  }
}
componentDidMount(){
  
  this.getstatusnotificationlist(this.state.pageSize, this.state.pageNo, this.state.model, this.state.type);
  this.state.user.permissions = localStorage.getItem("roleAccess");
  this.getModels();
  this.getNotificationType();

   }
    
   getstatusnotificationlist=(pageSize, pageNo, model, type)=> {

    var url =  baseUrl.URLPath + "pushnotifications/search?pageNo="+pageNo+ "&pageSize="+pageSize+ "&model="+ model+ "&type=" +type ;

    fetch(url, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
    
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(response => {
      if (response != undefined) {
   
        this.state.noOfRecords = response.count.count;
        this.setState({
          dataArray: response.snList,
          noOfRecords: response.count.count
        });

      }
   
    })
  }

    redirectViewPage(id, e){
     var url = "/viewTag/"+id;
      this.props.history.push(url);
    }
    modelChange(){
      var id = document.getElementById("con_status").value;
      let fields = this.state.fields;
      fields["modelChange"] = id;
      this.setState({ modelChange: id, activePage:1}, () => {
      this.getConnectorsFromDb(10,1,id);
      });
  }

gettypechangeFromDb(pageSize,pageNo,type){
        var errors = {}
        let status = this.state.fields.typeChange;
        let model="";
        if(this.state.fields.modelChange!="" && this.state.fields.modelChange!=null && this.state.fields.modelChange!=undefined){
          model = this.state.fields.modelChange;
        }
       var url =  baseUrl.URLPath + "pushnotifications/search?pageSize="+pageSize+"&pageNo="+pageNo+"&type="+type+"&model="+model;
  
       fetch(url,{
            method: "GET",
              headers:{
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization"),
              }
            })
            .then((resp) => {
           
              if(resp.status==401){
                this.togglesession();
                localStorage.clear();
              }
              if(resp.status==200){
                return resp.json();
              }
              if(resp.status==404){
                
             
                 
              }
              
            }) 
            .then((response) => {
        
              if(response.count!=undefined && response.count!=null){
              this.setState({ 
                dataArray: response.snList,
                 noOfRecords:response.count.count, 
                items: response.snList
              })
              }
              if(response.count==null){
                this.setState({ 
                  dataArray:[],
                   noOfRecords:0, 
                  items: []
                })
              }
            })
            .catch((error) => {
              console.log(error, "catch the loop")
            })
          
      }
    getConnectorsFromDb(pageSize,pageNo,model){
      var errors = {}
      let status = this.state.fields.modelChange;
      let type="";
      if(this.state.fields.typeChange!="" && this.state.fields.typeChange!=null && this.state.fields.typeChange!=undefined){
        type=this.state.fields.typeChange;
      }
     var url =  baseUrl.URLPath + "pushnotifications/search?pageSize="+pageSize+"&pageNo="+pageNo+"&model="+this.state.fields.modelChange+"&type="+type;

     fetch(url,{
          method: "GET",
            headers:{
              "content-type":"application/json",
              "Authorization":localStorage.getItem("Authorization"),
            }
          })
          .then((resp) => {
         
            if(resp.status==401){
              this.togglesession();
              localStorage.clear();
            }
            if(resp.status==200){
              return resp.json();
            }
            if(resp.status==404){
              
           
            
            }
            
          }) 
          .then((response) => {
            if(response.count!=undefined && response.count!=null){
         
            this.setState({ 
              dataArray: response.snList,
               noOfRecords:response.count.count, 
              items: response.snList
            })
           
          }
          if(response.count==null){
            this.setState({ 
              dataArray:[],
               noOfRecords:0, 
              items: []
            })
          }
          })
          .catch((error) => {
            console.log(error, "catch the loop")
          })
        
    }
    activeChargePoint(){
      
      if(this.state.activeArray!="" && this.state.activeArray.length != "0" ){
       var url = baseUrl.URLPath + "pushnotifications/bulkactivate";
       let data = {"id":this.state.activeArray, "active":1 };
       fetch(url,{
          method: "POST",
          body: JSON.stringify(data),
          headers: {
           "content-type":"application/json",
           "Authorization":localStorage.getItem("Authorization")
          }
          })
          .then((resp) => {
       
       
            if(resp.status==200){
              return resp.json();
            }
          }) 
          .then((resp) =>{
           if(resp.status=="SUCCESS"){
            this.toggleactive()
          
            }
         })
         .catch((error) => {
              console.log(error, "catch the loop")
        })
        }else if(this.state.activeArray=="" && this.state.activeArray.length=="0"){
          this.toggle3()
        }
     }
     deactiveChargePoint(){
      
      if(this.state.activeArray!="" && this.state.activeArray.length != "0" ){
       var url = baseUrl.URLPath + "pushnotifications/bulkactivate";

       let data = {"id":this.state.activeArray, "active":0 };

       fetch(url,{
          method: "POST",
          body: JSON.stringify(data),
          headers: {
           "content-type":"application/json",
           "Authorization":localStorage.getItem("Authorization")
          }
          })
          .then((resp) => {
       
       
            if(resp.status==200){
              return resp.json();
            }
          }) 
          .then((resp) =>{
           if(resp.status=="SUCCESS"){
            this.toggledeactive()
          
            }
         })
         .catch((error) => {
              console.log(error, "catch the loop")
        })
        }else if(this.state.activeArray=="" && this.state.activeArray.length=="0"){
          this.toggle3()
        }
     }
    typeChange(){
      var id = document.getElementById("type_status").value;
      let fields = this.state.fields;
      fields["typeChange"] = id;
      this.setState({ typeChange: id, activePage:1 }, () => {
      this.gettypechangeFromDb(10,1,id);
      });
  }
    handleChange(id,cpid,event){
    
  
     // event.preventDefault();
      let arr = [];
      var newlist = [];
      var checkedValue = document.getElementById(id).checked;
   
        if(checkedValue==true){
        if(this.state.list[0]!="" && this.state.list[0]!= undefined ){
          document.getElementById("custom-"+this.state.list[0]).checked = false;
          document.getElementById(id).checked = true;
         
        }
        this.state.list[0] = cpid ; 
        this.setState({
          // deleteList: [ ...this.state.deleteList, cpid], 
          activeArray:this.state.list
          });
        // [].concat(arr)
      }

      if(checkedValue==false){
        this.state.list[0] = "" ; 
        document.getElementById(id).checked = false;
      }
    
       
        //this.deleteAll(arr);
        this.state.activeDeactiveArray = cpid;
       
       // this.setState({ activeDeactiveArray:this.state.cpid1 })
    }

  //  function after clicking on checkbox to stored in an array 
  handleChange(id, cpid, event) {
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue == true) {
      this.state.list.push(cpid);
      this.setState({
        activeArray: this.state.list
      });
    } else {
      const index = this.state.list.indexOf(cpid);
      if (index > -1) {
        this.state.list.splice(index, 1);
      }
      this.setState({
        activeArray: this.state.list
      });
    }
  }

    handleDelete(event){
      
      this.setState({
        modal1: !this.state.modal1
      });
      event.preventDefault();

      var url = baseUrl.URLPath + "pushnotifications/bulkactivate";
      let data = {"id":this.state.activeArray, "active":2 };
   
      fetch(url,{
        method: "POST",
      body:JSON.stringify(data),
        headers: {
          "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization"),
       
        }
        })
        .then((resp) => {
       
       
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((resp) => {

          if(resp.status=="SUCCESS"){
            this.toggle2() 
       
          }
     
        }) 
  
        .catch((error) => {
            console.log(error, "catch the loop")
        })
    }
  
  //function for sorting table data on click
ascendingOrder(){
  if(this.state.idTagSorting == false){
    this.state.selectDataArray.sort(this.dynamicSort("idTag"));
    this.state.idTagSorting = true;
       this.setState({selectDataArray : this.state.selectDataArray.sort(this.dynamicSort("idTag"))})
  }
   else{
    this.state.selectDataArray.sort(this.dynamicSort("idTag"));
    this.state.idTagSorting = false;
       this.setState({selectDataArray : this.state.selectDataArray.reverse()});
   } 
}
sortingExpiryTime(){
 if(this.state.idTagSorting == false){
  this.state.selectDataArray.sort((a, b) => new Date(...a.expiryDate.split('/').reverse()) - new Date(...b.expiryDate.split('/').reverse()));
  this.state.idTagSorting = true;
  this.setState({selectDataArray : this.state.selectDataArray.sort((a, b) => new Date(...a.expiryDate.split('/').reverse()) - new Date(...b.expiryDate.split('/').reverse()))})
}
 else{
  this.state.selectDataArray.sort(this.dynamicSort("idTag"));
  this.state.idTagSorting = false;
  this.setState({selectDataArray : this.state.selectDataArray.sort((a, b) => new Date(...a.expiryDate.split('/').reverse()) - new Date(...b.expiryDate.split('/').reverse()))})
  this.setState({selectDataArray : this.state.selectDataArray.reverse()});
 } 
}

dynamicSort(property) {
  var sortOrder = 1;

  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a,b) {
      if(sortOrder == -1){
          return b[property].localeCompare(a[property]);
      }else{

          return a[property].localeCompare(b[property]);
      }        
  }
}
getModels(){
  let url = baseUrl.FOTAPath + "cpinventory/evmodel/";
  fetch(url, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": localStorage.getItem("Authorization"),
    }
    }).then(resp =>{
        return resp.json();
    })
    .then((resp) =>{
      if(resp!=undefined){
        this.setState({
          models: resp
        });
      }
    })    
}
getNotificationType(){
  let url = baseUrl.URLPath + "pushnotifications/search/type";
  fetch(url, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": localStorage.getItem("Authorization"),
    }
    }).then(resp =>{
        return resp.json();
    })
    .then((resp) =>{
      if(resp!=undefined){
        this.setState({
          notificationType: resp
        });
      }
    })    
}
render() {
  const{t}=this.props
  let user = this.state.user;
  const ref = React.createRef();
  let models = this.state.models.map((model, index) => {
    return (
      <option value={model.trim()}>{model.trim()}</option>
    );
  })

  let notificationType = this.state.notificationType.map((type, index) => {
    if(type != null){
      return (
        <option value={type}>{type}</option>
      );
    }
  })
let products;
  if(this.state.dataArray!=undefined){
  products=
    this.state.dataArray.map((dataArray, index) => {

  
    return(
      <tr key={index}>
          <td  className="text-center"> 
            <Form.Check 
              custom
              type="checkbox"
              id={`custom-${dataArray.id}`}
              onChange={this.handleChange.bind(this,`custom-${dataArray.id}`,dataArray.id)}
              label = ""
            />
          </td> 
       
         
           <td className="">{ dataArray.pushId} </td>
           <td className="">{dataArray.category}</td>
           <td className="">{dataArray.type}</td>
           <td className="">{dataArray.model}</td>
           <td className="">{ dataArray.errorCode} </td>
           <td className="">{dataArray.info}</td>
           <td className="">{dataArray.description}</td>
         
           <td className="">
             {dataArray.status ? "Active" : "Deactive"}
             </td>
          <td className="">
            <Link to={{ pathname: "/editStatusNotification", pushId: dataArray.pushId, id: dataArray.id }}>
            <i class="fas fa-pencil-alt pl-2 action-icon" title="Edit"></i>
            </Link>
          </td>
         
      </tr>
      )
     
  })
}
    return (
      <>
    <main class="content-div" >
    <p> {t('Status Notification List')}
              <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page"> {t('Status Notification List')}</span>
              </div>
          </p>
          <div className="page-outerdiv">
          {/* <div className="breadcrumb_div"> Charge Point > OCPP Tags</div> */}

            {/* page title */}
            {/* <p> OCPP Tags</p> */}
            {/* end page title */}
            {/* page menu bar */}
              
            <div className="row menubar">
              <div className="col-md-12 ">
                  <div className="">
                   <button type="button" data-test="button" onClick={this.addNew}>
                      <i className="fa fa-plus">
                      </i>
                      <span>{t('add')}</span>
                    </button> 
                    <button onClick={this.activeChargePoint.bind(this,1) }>
                    <i className="fas fa-toggle-on">
                    </i>
                    <span>{t('active')}</span>
                  </button>
                  <button onClick={this.deactiveChargePoint.bind(this,1) }>
                    <i className="fas fa-toggle-off">
                    </i>
                    <span>{t('deactive')}</span>
                  </button>
                 <button type="button" data-test="button" onClick={this.toggle1}>
                      <i className="fas fa-trash">
                      </i>
                      <span>{t('delete')}</span>
    </button> 
    {/* select dropdown for Table */}
                   <div className="pull-right">
                    <label className="sub-text pull-left">{t('show_entries')}</label>
                    <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                      <Form.Control as="select" className="showentry_sel custom_selectBox"  id="showEntries"onChange={this.showEntriesChange}> 
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                      </Form.Control>
                    </Form.Group>
                    </div>
                </div>
              </div>
            </div>
  
            
            {/* end page menu bar  */}
            <div className="row">
            <div className="col-md-3  pull-right">
         
      <select className="browser-default custom-select select_height " id="con_status" onChange={this.modelChange}>
   <option value="">--{t('select_model')}--</option>
   <option value="All products">{t('all_products')}</option>
   {models}
  
    </select>
   
         </div>
         
         <div className="col-md-3  pull-right">
         
         <select className="browser-default custom-select select_height " id="type_status" onChange={this.typeChange}>
      <option value="">--{t('select_notification_type')}--</option>
      {notificationType}

       </select>
      
            </div>
         
         </div>
              
            
            
            {/* table start */}
            <div className="row ">
             <div className="col-md-12">
            <Table striped hover size="sm" className="page-table" id="statusnotificationtable">
              <thead>
                <tr>
                  <th className="text-center"></th>
                  <th>{t('push_id')} </th>
                  <th className="">{t('category')}</th>
                  <th>{t('warning/error/info')} </th>
                  <th className="">{t('model')}</th>
                  <th className="">{t('errorcode_from_statusnotification')}</th>
                  <th className="">{t('info_from_statusnotification')}</th>
                  <th className="">{t('customerdescription_in_pushnotification')}</th>
                  <th className="">{t('chargingSessionState')}</th>
                  <th className="">{t('action')}</th>
                </tr>
              </thead>
              <tbody>
             {products}
              </tbody>
            </Table>
            </div>
          
            <div className="col-md-12 mt-2 mb-4 text-right">
   
             <Pagination
             hideDisabled
    
      activePage={this.state.activePage}
      itemsCountPerPage={this.state.pageSize}
      totalItemsCount={this.state.noOfRecords}
      onChange={this.handlePageChange.bind(this)}
    />
            </div>
            
            {/* End table  */}
           
            </div>
             </div> 
            <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
              <MDBModalHeader toggle={this.toggle1}> {this.state.deleteHeader}</MDBModalHeader>
                <MDBModalBody>
                  {this.state.delete_msg}
                </MDBModalBody>
              <MDBModalFooter>
              <button type="button" data-test="button" onClick={this.toggle1} className="btn_primary">{this.state.deleteText}</button>
              <button type="button" data-test="button" onClick={this.handleDelete} className={`btn_primary ${this.state.deleteButton}`}>{t('yes')}</button>
                  {/* <MDBBtn color={this.state.deletecolor} onClick={this.toggle1}>{this.state.deleteText}</MDBBtn>
                  <MDBBtn color="primary" className={this.state.deleteButton} onClick={this.handleDelete}>YES</MDBBtn> */}
              </MDBModalFooter>
            </MDBModal>


<MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
<MDBModalBody>
{t('status_successfully_deleted')}
</MDBModalBody>
<MDBModalFooter>
{/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
<button type="button" data-test="button" onClick={this.tagOk2} className="btn_primary">{t('ok')}</button>
</MDBModalFooter>
</MDBModal>
<MDBModal isOpen={this.state.modalactive} toggle={this.toggleactive} size="md" className="model_top">
<MDBModalHeader toggle={this.toggleactive}>{t('success')}</MDBModalHeader>
<MDBModalBody>
{t('status_notification_activated')}
</MDBModalBody>
<MDBModalFooter>
{/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
<button type="button" data-test="button" onClick={this.tagOk2} className="btn_primary">{t('ok')}</button>
</MDBModalFooter>
</MDBModal>
<MDBModal isOpen={this.state.modaldeactive} toggle={this.toggledeactive} size="md" className="model_top">
<MDBModalHeader toggle={this.toggledeactive}>{t('success')}</MDBModalHeader>
<MDBModalBody>
{t('status_notification_dectivated')}
</MDBModalBody>
<MDBModalFooter>
{/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
<button type="button" data-test="button" onClick={this.tagOk2} className="btn_primary">{t('ok')}</button>
</MDBModalFooter>
</MDBModal>
<MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle3}>{t('warning')}</MDBModalHeader>
<MDBModalBody>
  {t('select_Push_Id')}
  </MDBModalBody>
<MDBModalFooter>
{/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
<button type="button" data-test="button" onClick={this.tagOk3} className="btn_primary">{t('ok')}</button>
</MDBModalFooter>
</MDBModal>


{/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">{t('ok')}</button>
      </MDBModalFooter>
      </MDBModal>
        </main>
   </>
    );
  }
}

export default withTranslation()(statusnotificationlist);