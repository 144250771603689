import React from 'react';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from "jquery";
import * as baseUrl from './constants';
import * as Roles from './roles';
import * as PlatformUser from './PlatformUser';
import * as TenantUser from './TenantUser';
import { withTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class addUserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sucess1: false,
      colour: 'sucess',
      message: "",
      roledisplay: "",
      defaultroledisplay: "",
      fields: { id: '', active: '', email: '', firstName: '', lastName: '', mobileNumber: '', organisation: '', role: '', employeeId: '', status: '', rfidTag: '', aliasName: '', language: '' },
      sucess: {},
      errors: {},
      activeItem: "1",
      model: "false",
      dataArray: [],
      modalsession: false,
      roles: [],
      multipleRfidArray: [
        {
          rfidTag: '', aliasName: '', tagType: 'other'
        }
      ],
      multipleRfidArrayAlias: [],
      availableSites: [],
      selectedSites: [],
      listOfLanguages: []
    }
    this.getLanguages = this.getLanguages.bind(this);
    this.toggle = this.toggle.bind(this)
    this.tagOk = this.tagOk.bind(this)
    this.addNew = this.addNew.bind(this)
    this.backToList = this.backToList.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.roleidChange = this.roleidChange.bind(this)
    this.organizationChange = this.organizationChange.bind(this)
    this.activeChange = this.activeChange.bind(this)
    this.togglesession = this.togglesession.bind(this)
    this.getRoles = this.getRoles.bind(this)
    this.addMultipleRfid = this.addMultipleRfid.bind(this)
    this.removeRfid = this.removeRfid.bind(this)
    this.changeRfid = this.changeRfid.bind(this)
    this.onShowAlert = this.onShowAlert.bind(this)
    this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);
    this.getIsAllSitesSelected = this.getIsAllSitesSelected.bind(this);
this.languageChange = this.languageChange.bind(this);
  }
  getLanguages() {
    let url = baseUrl.URLPath + 'admin/v1.0/account/languages/preferred';

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    }).then(response => {
      if (response.status == 200) {
        return response.json();
      }
    }).then(result => {
      if (result) {
        this.setState({
          listOfLanguages: result
        })
      }
    }).catch(err => {
      console.warn('get preferred lanuages api failed', err)
    })
  }
  onShowAlert() {
    this.setState({ sucess1: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess1: false })
      }, 5000)
    });

  }


  changeRfid(index, field, e) {

    let tempArray = [...this.state.multipleRfidArray];


    tempArray[index][field] = e.target.value;
    if (field == "rfidTag") {
      if (tempArray[index][field] != 0) {

        $(`.rfidTag_${index}`).text("")
      }
    }
    if (field == "aliasName") {
      if (tempArray[index][field] != 0) {

        $(`.aliasName_${index}`).text("")
      }
    }
    if (field == "tagType") {
      if (tempArray[index][field] != 0) {

        $(`.tagType_${index}`).text("")
      }
    }
    this.setState({ multipleRfidArray: tempArray });
    console.log(this.state.multipleRfidArray)


  }


  addMultipleRfid() {
    let errors = {};
    this.setState({
      multipleRfidArray: [...this.state.multipleRfidArray, { rfidTag: '', aliasName: '', tagType: 'other' }]
    })
    errors["emptyidTagArray"] = "";
    this.setState({ errors: errors })
  }
  removeRfid(index) {
    let data = this.state.multipleRfidArray

    // remove object
    data.splice(index, 1);
    this.setState({
      multipleRfidArray: data,
      disabledAttr: ""
    });

    let lookup = this.state.multipleRfidArray.reduce((a, e) => {
      a[e.rfidTag] = ++a[e.rfidTag] || 0;
      return a;
    }, {});

    let duplicateCount = this.state.multipleRfidArray.filter(e => lookup[e.rfidTag]);

    if (duplicateCount.length == 0) {
      $('.rfidexists').text("");
    }
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }
  roleidChange() {
    var x = document.getElementById("role").value;
    let fields = this.state.fields;
    fields["role"] = x;
    this.setState({ fields });
    if (x != 0) {
      this.state.errors["role"] = " ";

    }

  }
  tagOk() {
    this.props.history.push('/userManagementController');
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }




  addNew() {
    this.props.history.push('/Userlist');
  }

  backToList() {
    this.props.history.push('/userManagementController');
  }

  // checking the format of number
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }
  changeHandler(field, e) {
    var email = document.getElementById("email").value;
    var firstName = document.getElementById("firstName").value;
    var lastName = document.getElementById("lastName").value;
    var mobileNumber = document.getElementById("mobileNumber").value;
    var employeeId = document.getElementById("employeeId").value;
    if (field == "employeeId") {
      $('.employeeidexists').text("");
    }
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    if (e.target.value != "") {
      this.state.errors["rfidempty"] = ""


    }
    if (fields["email"] = email) {
      this.state.errors["emailempty"] = " ";
      this.state.errors["userexists"] = " ";


    }
    if (fields['firstName'] = firstName) {
      this.state.errors["firstName"] = " ";
    }
    if (fields['lastName'] = lastName) {
      this.state.errors["lastName"] = " ";
    }
    if (fields['mobileNumber'] = mobileNumber) {
      this.state.errors["mobileNumber"] = " ";
    }
    if (fields['employeeId'] = employeeId) {
      this.state.errors["employeeId"] = " ";
    }
  }
  isHaveAlphabetic(string) {
    if (/[a-zA-Z]/.test(string) == true) {
      return true;
    } else {
      return false;
    }
  }
  isLengthAllowed(string) {
    if (string.length <= 20) {
      return true;
    } else {
      return false;
    }
  }
  isLengthAllowed128(string) {
    if (string.length <= 128) {
      return true;
    } else {
      return false;
    }
  }
  activeChange = () => {
    var x = document.getElementById("myCheck").checked;
    let fields = this.state.fields;
    if (x) {
      fields.status = true;
    }
    else {

      fields.status = false;
    }
    this.setState({ fields });

  }
  async handleSubmit(event) {
    event.preventDefault();
    let errors = {};
    let validFreeCharging = 0;
    this.state.multipleRfidArray.forEach((element, index) => {
      if (element.rfidTag != "" && element.rfidTag != null) {
        $(`.rfidTag_${index}`).text("");
        if (!element.rfidTag.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) {
          errors["rfidempty"] = this.props.t('please_enter_valid_rfid_tag');
        }
      }
      else {
        $(`.rfidTag_${index}`).text(this.props.t('please_enter_rfid_tag'))
      }

      if (element.rfidTag == "0815" && element.rfidTag != null && element.rfidTag != "") {
        $(`.rfidTag_${index}`).text(this.props.t('rfid_tag_reserved_for_free_charging'));
        validFreeCharging = 1;
      }

      if (element.aliasName != "" && element.aliasName != null) {
        $(`.aliasName_${index}`).text("");
      }
      else {
        $(`.aliasName_${index}`).text(this.props.t('please_enter_alias_name'))
      }

    })


    var email = this.state.fields['email'];
    var organisation = this.state.fields['organisation'];
    var firstName = this.state.fields['firstName'];
    var lastName = this.state.fields['lastName'];
    var mobileNumber = this.state.fields['mobileNumber'];
    var role = this.state.fields['role'];
    var siteManagementsIds = [];

    this.state.selectedSites.map((site, i) => {
      siteManagementsIds.push(site.id);
    })

    var getRole = localStorage.getItem("role")
    if (email == '') {
      errors["emailempty"] = this.props.t('please_enter_email');
    }
    if (email != '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["entervalidemail"] = this.props.t('enter_valid_email_id');
      }
    }
    if (organisation == '') {
      errors["organisationempty"] = this.props.t('please_select_organization');
    }
    if (firstName == '') {
      errors["firstName"] = this.props.t('enter_first_name');
    }
    if (lastName == '') {
      errors["lastName"] = this.props.t('enter_last_name');
    }

    if (mobileNumber == "") {
      errors["mobileNumber"] = this.props.t('enter_mobile_no');
    }
    if (mobileNumber != '') {
      if (!mobileNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {

        errors["validphoneNo"] = this.props.t('enter_10digit_mobile_no');
      }
    }
    if (getRole != "WEBASTO_SITE_ADMIN") {
      if (role == "") {
        errors["role"] = this.props.t('please_select_role');
      }
    }
    if (role == "WEBASTO_END_USER_COMMERCIAL" && this.state.multipleRfidArray.length == 0) {
      errors["emptyidTagArray"] = this.props.t('add_rfid_and_alias_name');
    }

    if (getRole != Roles.WEBASTO_SITE_ADMIN) {
      if (validFreeCharging == 1 || errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName") || errors.hasOwnProperty("mobileNumber") || errors.hasOwnProperty("role") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("validphoneNo") || errors.hasOwnProperty("rfidempty") || errors.hasOwnProperty("emptyidTagArray") || errors.hasOwnProperty("aliasNameEmpty")) { }
      else {
        let url = "";
        if (getRole == Roles.WEBASTO_CUSTOMER_ADMIN || getRole == Roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.CREATE_USER;
        } else if (getRole == Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || getRole == Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          url = PlatformUser.CREATE_USER;
        }

        let data = { "email": this.state.fields.email.toLowerCase(), "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role": this.state.fields.role, "organisation": this.state.fields.organisation, "employeeId": this.state.fields.employeeId, "status": this.state.fields.status, "rfids": this.state.multipleRfidArray, siteManagementsIds, "preferredLanguage": this.state.fields.language };
        document.getElementById("loader_image_div").style.display = "block";
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }

        }).then(resp => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
            this.hideLoder();
          }
          else if (resp.status == 404) {
            this.setState({ errors: errors });
            this.hideLoder();

          } else if (resp.status == 500) {
          }

          else if (resp.status == 409) {
            this.setState({
              errors: errors
            });
            this.hideLoder();
          }
          else if (resp.status == 400) {
          } else if (resp.status == 201) {
            return resp.json();
            this.hideLoder();
          }
        }).then(response => {
          this.hideLoder();
          if (response.status == this.props.t('succees_condition')) {
            this.toggle()
            $('.rfidexists').text("");
          }
          else if (response.status == this.props.t('failure_condition') && response.errorCode == "3104" && response.errorMessage == 'user_already_exists') {
            errors["userexists"] = this.props.t('user_already_exists')
            this.setState({
              errors: errors
            });
            $('.rfidexists').text("");
          }
          else if (response.status == this.props.t('failure_condition') && response.errorCode == "3123" && response.errorMessage == 'rfid_tag_issue') {
            var rfids = response.rfids[0];
            var errorRfid = rfids.rfidTag;
            var errorrfidMessage = rfids.errorReason;
            if (rfids.errorReason == 'rfid_is_already_assigned') {
              errors["rfidexists"] = this.props.t('rfid_is_already_assigned');
              $('.rfidexists').text("");
              this.setState({ errors: errors });
              this.setState({ message: errorRfid + "  " + this.props.t('is_already_assigned'), colour: 'danger' })
              this.onShowAlert();
            } else if (rfids.errorReason == 'duplicate_rfid_tag') {
              $('.rfidexists').text(this.props.t('duplicate_rfidds_not_allowed'));
            }


          } else if (response.status == this.props.t('failure_condition') && response.errorCode == "3124" && response.errorMessage == this.props.t('mployeeid_is_already_exists')) {
            $('.employeeidexists').text(this.props.t('employeeId_already_exists'));
          }
          else { }


          this.setState({
            errors: errors
          });

        })
          .catch(error => alert('Error:' + error));
      }
    } else {

      if (errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName") || errors.hasOwnProperty("mobileNumber") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("validphoneNo") || errors.hasOwnProperty("rfidempty") || errors.hasOwnProperty("aliasNameEmpty") || errors.hasOwnProperty("emptyidTagArray") || errors.hasOwnProperty("employeeId")) { }

      else {
        let url = "";
        let getRole = localStorage.getItem('role');
        if (getRole == Roles.WEBASTO_CUSTOMER_ADMIN || getRole == Roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.CREATE_USER;
        } else if (getRole == Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || getRole == Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          url = PlatformUser.CREATE_USER;
        }

        let data = { "email": this.state.fields.email, "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role": this.state.fields.role, "organisation": this.state.fields.organisation, "status": this.state.fields.status, "employeeId": this.state.fields.employeeId, "rfids": this.state.multipleRfidArray, siteManagementsIds };
        document.getElementById("loader_image_div").style.display = "block";
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }
        }).then(resp => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
            this.hideLoder();
          }
          else if (resp.status == 404) {
            this.setState({ errors: errors });
            this.hideLoder();
          } else if (resp.status == 500) {

          }

          else if (resp.status == 409) {
            this.setState({
              errors: errors
            });
            this.hideLoder();
          }
          else if (resp.status == 400) {

          } else if (resp.status == 201) {
            return resp.json();
          }
        }).then(response => {
          this.hideLoder();
          if (response.status == "SUCCESS") {
            this.toggle();
          } else if (response.status == this.props.t('failure_condition') && response.errorCode == "3104" && response.errorMessage == 'user_already_exists') {
            errors["userexists"] = this.props.t('user_already_exists')
            this.setState({
              errors: errors
            });
          } else if (response.status == this.props.t('failure_condition') && response.errorCode == "3123" && response.errorMessage == 'rfid_tag_issue') {
            var rfids = response.rfids[0];
            var errorRfid = rfids.rfidTag;

            if (rfids.errorReason == 'rfid_is_already_assigned') {
              this.setState({ message: errorRfid +"  " + this.props.t('is_already_assigned'), colour: 'danger' })
              this.onShowAlert();
            }
          }
          else { }

          this.setState({
            errors: errors
          });

        })
          .catch(error => alert('Error:' + "Internal Server Error"));
      }
    }
    this.setState({ errors: errors });
  }

  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }

  organizationChange() {
    var orgid = document.getElementById("organisation").value;

    let fields = this.state.fields;
    fields["organisation"] = orgid;
    this.setState({ fields });
    if (orgid != 0) {
      this.state.errors["organisationempty"] = " ";
    }
    if (localStorage.getItem("role") !== "WEBASTO_END_USER_RESIDENTIAL") {
      this.getAllAvailableSites(orgid);
    }
  }

  languageChange(){
    let selectedLanguage = document.getElementById('language').value;
    let fields = this.state.fields;
    fields['language'] = selectedLanguage;

    this.setState({
      fields
    })
  }

  componentDidMount() {
    document.getElementById("myCheck").checked = true;

    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        let fields = this.state.fields;
        fields.status = true;
        if (response != undefined) {
          this.setState({ dataArray: response.organisationDTOS, fields })
        }
        this.getRoles();
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
      this.getLanguages();
  }

  getAllAvailableSites(orgId) {
    let url = `${baseUrl.URLPath}sitemanagement/all?orgId=` + orgId;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    }).then((response) => {
      let beforeSort = response;
      let afterSort = [];
      let names = [];
      beforeSort.map((item, index) => {
        names.push(item.name);
      });
      names = names.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      names.map((site, index) => {
        beforeSort.map((itm, index) => {
          if (itm.name == site) {
            afterSort.push(itm);
          }
        })
      });

      this.setState({ ...this.state, availableSites: afterSort });
    }).catch((error) => {
      console.log(error, "error in getting sites list");
    });
  }

  getIsSiteSelected(site) {
    const siteIndex = this.state.selectedSites.findIndex(el => el === site);
    return siteIndex > -1;
  }

  getIsAllSitesSelected() {
    const { selectedSites, availableSites } = this.state;
    let isAllChecked = true;
    if (availableSites) {
      availableSites.forEach(element => {
        const isChecked = selectedSites.some(el => el.id === element.id);
        if (!isChecked) {
          isAllChecked = false;
        }
      });
    } else {
      isAllChecked = false;
    }
    return isAllChecked;
  }

  onSelectSite(event, el) {
    const isChecked = event.target.checked;
    const selectedSites = [...this.state.selectedSites];
    const elementIndex = selectedSites.findIndex(element => element === el);
    if (isChecked) {
      if (elementIndex < 0) {
        selectedSites.push(el);
      }
    } else {
      selectedSites.splice(elementIndex, 1);
    }

    this.setState({ ...this.state, selectedSites });
  }

  onUpdateSiteSelectAll(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      const selectedSites = [...this.state.availableSites];
      this.setState({ ...this.state, selectedSites });
    } else {
      this.setState({ ...this.state, selectedSites: [] });
    }
  }

  getRoles() {
    var url = baseUrl.URLPath + "roles/";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        let fields = this.state.fields;
        fields.status = true;
        if (response != undefined) {
          this.setState({ roles: response.roles })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }


  render() {
    const{t}=this.props
    var getRole = localStorage.getItem("role");

    let addCommercialAndResidential = false;
    let isCommercialAndResidential = false;

    let addCommercialAndResidentialAndSiteAdmin = false;
    let isCommercialAndResidentialAndSiteAdmin = false;

    if (getRole == "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole == "WEBASTO_CUSTOMER_ADMIN" ||
      getRole == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" ||
      getRole == "WEBASTO_SITE_ADMIN") {
      addCommercialAndResidential = true;
    }

    if (this.state.fields.role == "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role == "WEBASTO_END_USER_RESIDENTIAL") {
      isCommercialAndResidential = true;
    }

    if (getRole == "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole == "WEBASTO_CUSTOMER_ADMIN" ||
      getRole == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") {
      addCommercialAndResidentialAndSiteAdmin = true;
    }

    if (this.state.fields.role == "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role == "WEBASTO_END_USER_RESIDENTIAL" || this.state.fields.role == "WEBASTO_SITE_ADMIN") {
      isCommercialAndResidentialAndSiteAdmin = true;
    }

    if (getRole == "WEBASTO_SITE_ADMIN") {
      this.state.roledisplay = "block";
      this.state.defaultroledisplay = "none"
    }
    else {
      this.state.roledisplay = "none";
      this.state.defaultroledisplay = "block"
    }

    const organisationDTOS = this.state.dataArray.map((dataArray, index) => {
      return (
        <option value={dataArray.id}>{dataArray.company}</option>
      )

    })
    const roles = this.state.roles.map((role, index) => {
      return (
        <option value={role.identifier}>{role.displayName}</option>
      )
    })
    return (
      <>
        <main className="content-div" >
          <p> {t('Users')}
            <div className="breadcrumb_div">
              {t('Settings')} &gt; <Link to="/userManagementController">{t('Users')}</Link > &gt; <span className="breadcrumb_page">{t('add')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button type="button" data-test="button" className="btn_primary" onClick={this.backToList}><i className="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
                </div>
              </div>
            </div>
            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>

                      <div className="col-md-12 mb-3">

                        <span className=" form-title">{t('user_details')}</span>

                      </div>

                      <MDBCol md="4" >
                        <select className="browser-default custom-select select_height mb_8" id="organisation" onChange={this.organizationChange.bind(this)}  >
                          <option value="">-- {t('select_organization')} * --</option>
                          {organisationDTOS}
                        </select>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["organisationempty"]}</span></span>
                      </MDBCol>
                      <div className="col-md-4 md-form">
                        <input name="Email" type="text" id="email" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "email")} value={this.state.fields["email"]} />
                        <label htmlFor="email" className={`${(this.state.fields['email'] != "" && this.state.fields['email'] != null) ? "active" : ""} ml-15`}>{t('e_mail')} *</label>
                        <div className="invalid-feedback">{t('provide_valid_email')}</div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["validemail"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["userexists"]}</span></span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input name="firstName" type="text" id="firstName" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "firstName")} value={this.state.fields["firstName"]} />
                        <label htmlFor="firstName" className={`${(this.state.fields['firstName'] != "" && this.state.fields['firstName'] != null) ? "active" : ""} ml-15`}>{t('firstName')} *</label>
                        <div className="invalid-feedback">{t('provide_valid_email')}</div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["firstName"]}</span></span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input name="lastName" type="text" id="lastName" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "lastName")} value={this.state.fields["lastName"]} />
                        <label htmlFor="lastName" className={`${(this.state.fields['lastName'] != "" && this.state.fields['lastName'] != null) ? "active" : ""} ml-15`}>{t('lastName')} *</label>
                        <div className="invalid-feedback">{t('provide_valid_email')}</div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["lastName"]}</span></span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input name="mobileNumber" type="text" id="mobileNumber" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "mobileNumber")} value={this.state.fields["mobileNumber"]} />
                        <label htmlFor="mobileNumber" className={`${(this.state.fields['mobileNumber'] != "" && this.state.fields['mobileNumber'] != null) ? "active" : ""} ml-15`}>{t('mobile_no')} *</label>
                        <div className="invalid-feedback">{t('provide_valid_email')}</div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["mobileNumber"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["validphoneNo"]}</span></span>
                      </div>
                      <MDBCol md="4">
                        <select className="browser-default custom-select select_height mb_8" id="role" onChange={this.roleidChange.bind(this)} value={this.state.fields.role}>
                          <option value=""> -- {t('select_role')} * --</option>
                          {
                            (getRole == "WEBASTO_SITE_ADMIN") ?
                              <>
                                <option value="WEBASTO_END_USER_COMMERCIAL">{t('end_user_commercal')}</option>
                                <option value="WEBASTO_END_USER_RESIDENTIAL">{t('end_user_residential')}</option>
                              </> :
                              <>
                                {roles}
                              </>
                          }

                        </select>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["role"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4">
                        <select className="browser-default custom-select select_height mb_8" id="language" onChange={this.languageChange.bind(this)} value={this.state.fields.language}>
                          <option value=""> -- {t('select_language')} --</option>
                          {
                            this.state.listOfLanguages?.map((language,index)=>{
                              return <option key={index}>{language}</option>
                            })
                          }
                        </select>
                      </MDBCol>
                      <div className="col-md-4 md-form">
                        <input name="employeeId" type="text" id="employeeId" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "employeeId")} value={this.state.fields["employeeId"]} />
                        <label htmlFor="employeeId" className={`${(this.state.fields['employeeId'] != "" && this.state.fields['employeeId'] != null) ? "active" : ""} ml-15`}>{t('employee_number_optional')}</label>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["employeeId"]}</span></span>
                      </div>
                      {
                        ((isCommercialAndResidential && addCommercialAndResidential) || (isCommercialAndResidentialAndSiteAdmin && addCommercialAndResidentialAndSiteAdmin)) && this.state.availableSites && this.state.availableSites.length > 0 &&
                        <>
                          <div className="col-md-12 mt-5">
                            <div className="d-flex align-items-center">
                              <span className="form-title border-0 mt-0">{t('group_access')}</span>
                              <Form.Check
                                custom
                                type="checkbox"
                                id="siteAccessCheck"
                                label={this.props.t('all')}
                                className="ml-5"
                                checked={this.getIsAllSitesSelected()}
                                onChange={this.onUpdateSiteSelectAll}
                              />
                            </div>
                          </div>
                          <MDBCol md="6">
                            <MDBRow>
                              {
                                this.state.availableSites?.map((el, index) =>
                                  <MDBCol md="6" key={index}>
                                    <Form.Check
                                      custom
                                      type="checkbox"
                                      id={`siteAccess_siteName_${el.id}`}
                                      label={el.name}
                                      checked={this.getIsSiteSelected(el)}
                                      onChange={(event) => this.onSelectSite(event, el)}
                                    />
                                  </MDBCol>
                                )
                              }
                            </MDBRow>
                            <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["siteAccess"]}</span></span>
                          </MDBCol>
                        </>
                      }
                      <MDBCol md="12" className="mt-4">
                        <Form.Check
                          custom
                          value={this.state.fields.status}
                          onChange={this.activeChange}
                          type="checkbox"
                          id="myCheck"
                          label={t('active')}
                        />
                      </MDBCol>
                      <MDBCol md="12" className="mt-4">
                        <i className="fa fa-plus-circle mr-2" aria-hidden="true" onClick={this.addMultipleRfid} style={{ fontSize: "18px" }}></i><h>{t('additional_rfid_tag')}</h>
                        <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["emptyidTagArray"]}</span></span>
                      </MDBCol>
                      {
                        this.state.multipleRfidArray.map((multipleRfidArray, index) => {
                          return (
                            <>
                              <div className="col-md-3 md-form mt-4">
                                <input name={`rfid_${index}`} type="text" id={`rfid_${index}`} className="form-control text_lowercase" onChange={this.changeRfid.bind(this, index, "rfidTag")} value={multipleRfidArray.rfidTag} />
                                <label htmlFor={`rfid_${index}`} className={`${(multipleRfidArray.rfidTag != "" && multipleRfidArray.rfidTag != null) ? "active" : ""} ml-15`}>{t('rfid_tag_optional')}</label>
                                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["rfidempty"]}</span></span>
                                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["rfidexists"]}</span></span>
                                <span className={`rfidTag_${index}`} style={{ color: "red", fontSize: "13px" }}></span>
                              </div>

                              <div className="col-md-3 md-form mt-4">
                                <input name={`aliasName_${index}`} type="text" id={`aliasName_${index}`} className="form-control text_lowercase" onChange={this.changeRfid.bind(this, index, "aliasName")} value={multipleRfidArray.aliasName} />
                                <label htmlFor={`aliasName_${index}`} className={`${(multipleRfidArray.aliasName != "" && multipleRfidArray.aliasName != null) ? "active" : ""} ml-15`}>{t('rfid_tag_alias')}</label>
                                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["aliasNameEmpty"]}</span></span>
                                <span className={`aliasName_${index}`} style={{ color: "red", fontSize: "13px" }}></span>
                              </div>
                              <MDBCol md="5" className="form_margin">
                                <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">{t('choose_rfid_tag_type')} <span className="custom_tooltip"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                  <div className="custom_tooltiptext">
                                    <div className="custom_tooltip_header">{t('rfid_tag_type')}</div>
                                    <div className="custom_tooltip_body">
                                      <div>{t('type_of_rfid_tag')}</div>
                                      <div><span className="font-bold">{t('private')}: </span>{t('rfid_tag_intended_for_private_use')}</div>
                                      <div><span className="font-bold">{t('company')}: </span>{t('rfid_tag_was_issued_by_company')}</div>
                                      <div><span className="font-bold">{t('other')}: </span>{t('default_setting_no_further_logic_involved')}</div>
                                    </div>
                                  </div>
                                </span></label>
                                <select className="browser-default custom-select select_height w_53 indent mt-1" id="rfidTagType" onChange={this.changeRfid.bind(this, index, "tagType")} value={multipleRfidArray.tagType}>
                                  <option value="other">{t('other')}</option>
                                  <option value="private">{t('company')}</option>
                                  <option value="company">{t('company')}</option>
                                </select>
                                <div className="mt-3"><span className={`tagType_${index}`} style={{ color: "red", fontSize: "13px" }}></span></div>
                              </MDBCol>
                              <MDBCol md="1" className=" form_margin">
                                <i className="fas fa-times-circle mt-4" onClick={this.removeRfid.bind(this, index)}></i>
                              </MDBCol>
                            </>
                          )
                        })
                      }
                    </MDBRow>
                    <div className="error_msg w3-animate-top">  <span style={{ color: "red" }} className="rfidexists"></span></div>
                    <div className="error_msg w3-animate-top">  <span style={{ color: "red" }} className="employeeidexists"></span></div>

                    <button type="button" data-test="button" className="mt-5 btn_primary mr-3" onClick={this.handleSubmit}> {t('save')} <i className="fas fa-save ml-2"></i></button>

                  </form>

                </div>
              </MDBCol>
            </MDBRow>
          </div>

        </main>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('Success')}</MDBModalHeader>
          <MDBModalBody>
            {t('user_successfully_added')}
          </MDBModalBody>
          <MDBModalFooter>
            <button className="btn_primary" data-test="button" type="button" onClick={this.tagOk}>{t('OK')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button className="btn_primary" data-test="button" type="button" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(addUserManagement);