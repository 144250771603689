import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import * as baseUrl from './constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';
import CpSearch from '../common/chargePointSearch';
import Pagination from "react-js-pagination";
import { types } from '../common/ApiMethodTypes';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getSecurityHeaders, getSecurityHeadersWithTimezone } from '../common/HttpRequestClass';

const history = createBrowserHistory();

class Ocppnewtag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { idTag: '', parentIdTag: '', expiryDate: '', inTransaction: '0', note: '', blocked: '0', tagType: 'other' },
      sucess: {},
      errors: {},
      activeItem: "1",
      dataArray: "",
      isChecked: "1",
      startDate1: "",
      startDate: "",
      model: "false",
      page_title: "Add OCPP Tag",
      modalsession: false,
      allCPList: [],
      acpId: '',
      noWallboxExists: '',
      selectedItems: [],
      associatedCPList: [],
      RfidAccessVal: false,
      isFreeChargeEnabled: false,
      deletedWallboxes: [],
      fromDateApi: "",
      toDateApi: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      activePage: 1,
      cpid:"",
      pageSize: 30,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.redirectToListPage = this.redirectToListPage.bind(this)
    this.toggleChange = this.toggleChange.bind(this)
    this.setStartDate = this.setStartDate.bind(this)
    this.toggle = this.toggle.bind(this)
    this.tagOk = this.tagOk.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.transactionChange = this.transactionChange.bind(this)
    this.togglesession = this.togglesession.bind(this);
    this.rfidTypeChange = this.rfidTypeChange.bind(this);
    this.getAllCpList = this.getAllCpList.bind(this);
    this.getCustomization = this.getCustomization.bind(this);
    this.getSecurityData = this.getSecurityData.bind(this);
    this.handleWallboxChange = this.handleWallboxChange.bind(this);
    this.clearWallboxerror = this.clearWallboxerror.bind(this);
    this.handleWallboxPress = this.handleWallboxPress.bind(this);
    this.FindRfidCp = this.FindRfidCp.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    // this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.removeSelectedItem = this.removeSelectedItem.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this)

  }

  rfidTypeChange(type) {
    let val = document.getElementById('rfidTagType').value;
    let field = this.state.fields;
    field["tagType"] = val;
    this.setState({
      fields: field
    });
    $('.error_tagType').text("");
  }

  redirectToListPage() {
    this.props.history.push('/Ocpptags')
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  handleChange = date => {
    this.setState({ startDate: date });

    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;

    this.setState({ startDate1: createdDateTo });
  };

  tagOk() {
    this.props.history.push('/Ocpptags');
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  setStartDate(date) {
    this.setState({ startDate: date });
    var tempDate = new Date(date);
    var date1 = (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes();
    this.setState({ startDate1: date1 });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let errors = {};
    var idTag = this.state.fields['idTag'];
    var tag_type = this.state.fields["tagType"];
    let associated_cpids = this.state.selectedItems;

    if (idTag == '') {
      errors["idtagempty"] = this.props.t('enter_a_id_tag');
    }

    if (idTag == "0815" ) {
      errors["idtagempty"] = this.props.t('rfid_tag_reserved_for_free_charging');
    }

    if (tag_type == '') {
      $('.error_tagType').text(this.props.t('select_rfid_tag_type'));
    }

    let valid = true;

    if ((this.state.RfidAccessVal == true && this.state.isFreeChargeEnabled == false) > 0) {
      if (!this.state.selectedItems.length > 0) {
        $('.error_cpids').text(this.props.t('select_atleast_onecharge_point'));
        valid = false;
      } else {
        valid = true;
      }
    }

    if (idTag != '' && tag_type != '' && valid && idTag != "0815") {
      if (!idTag.match(/^[0-9a-zA-Z]{0,20}$/)) {
        errors["entervalididtag"] = this.props.t('enter_valid_id_tag');
      }
      
      else {
        let url = baseUrl.URLPath + "ocpptags";
        let data = {
          "idTag": this.state.fields.idTag,
          "expiryDate": this.state.startDate1,
          "inTransaction": this.state.fields.inTransaction,
          "note": this.state.fields.note,
          "blocked": this.state.fields.blocked,
          "parentIdTag": { "idTag": this.state.fields.parentIdTag },
          "tagType": this.state.fields.tagType,
          "associatedCPList": this.state.selectedItems,
          "deAssociatedCPList": this.state.deletedWallboxes
        };
        document.getElementById("loader_image_div").style.display = "block";

        fetch(url, {
          method: types.POST, // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers: getSecurityHeadersWithTimezone()
        }).then(resp => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          else if (resp.status == 404) {
            errors["entervalidID"] = this.props.t('enter_valid_id_tag')
            this.setState({ errors: errors });
            this.hideLoder();
            return resp.json();
          } else if (resp.status == 500) {
          } else if (resp.status == 400) {

            errors["equalids"] = this.props.t('enter_valid_id_tag')
            this.setState({
              errors: errors
            });
            this.hideLoder();
            return resp.json();

          }
          else if (resp.status == 409) {
            errors["idtagexist"] = this.props.t('id_tag_is_exists')
            this.setState({
              errors: errors
            });
            this.hideLoder();
          }
          else {
            this.toggle()
            this.hideLoder();
            return resp.json();
          }
        }).then(response => {
          if (response != null && response.hasOwnProperty("errorList")) {
            this.hideLoder();
            if (response.errorList[0] === this.props.t('id_and_parent_tag_not_be_equal')) {
              errors["parentchild"] = this.props.t('Id_tag_and_parent_tag_not_same');
            }
            if (response.errorList[0] === this.props.t('child_id_not_become_parent_of_another_id')) {
              errors["childtag"] = this.props.t('child_tag_not_become_the_parent_of_another_tag');
            }
            if (response.errorList[0] === this.props.t('enter_valid_parent_id_tag')) {
              errors["entervalidtag"] = this.props.t('enter_valid_parent_id_tag');
            }
          }
          this.setState({
            errors: errors
          });
        })
          .catch(error =>
            setTimeout(() => {
              alert('Error:' + error)
              document.getElementById("loader_image_div").style.display = "none";
            }, 2000)
          );
      }
    }
    this.setState({ errors: errors });
  }

  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    var errors = {};
    fields[field] = e.target.value;
    if (fields[field] == 0) {
      errors["idtagempty"] = " ";
      this.setState({ errors: errors })
    } else {
      this.setState({ fields });
      errors["idtagempty"] = "";
      this.setState({ errors: errors })
    }
  }
  toggleChange = () => {
    var x = document.getElementById("myCheck").checked;
    if (x) {
      this.state.fields.blocked = "1";
    }
    else {
      this.state.fields.blocked = "0";
    }

  }
  transactionChange = () => {
    var y = document.getElementById("transcheck").checked;
    if (y) {
      this.state.fields.inTransaction = "1";
    }
    else {
      this.state.fields.inTransaction = "0";
    }

  }

  countryChange() {
    var x = document.getElementById("parentIdTag").value;
    let fields = this.state.fields;
    fields["parentIdTag"] = x;
    this.setState({ fields });
  }

  componentDidMount() {
    document.getElementById("loader_image_div").style.display = "block";
    setTimeout(() => {
      this.getCustomization();
      this.getSecurityData();
      this.getAllCpList(true);
      document.getElementById("loader_image_div").style.display = "none";
    }, 5000);
  }

   getAllCpList(countValue,cpid,text) {
    var cpId = "";
    if(cpid !="" && cpid!=undefined && cpid!=null){
      cpId = cpid
      this.setState({
        activePage:1,
        pageSize:this.state.pageSize,
        acpId:cpid
      })
    }
    if (text == "Clear") {
      this.setState({
        acpId:cpid
      })
    }
    var url = baseUrl.URLPath + servicesUrl.getAllCplistForNewRfid + "?cpid=" + cpId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&pageSize=" + this.state.pageSize;
     fetch(url, {
      method: types.GET,
      headers: getSecurityHeaders()
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.data != undefined) {
          
          var noWallboxExists1 = 1;
          if (cpId != "" && response.count.count == 0) {
            localStorage.setItem("noWallboxExists", response.count.count);
          }

          if (response.data.length == 0) {
            // this.setState({
            //   allCPList: [],
            //   associatedCPList: [],
            // })
          } else {

            var totalcount = "";
            if (countValue == false) {
                totalcount = this.state.noOfRecords
              } else {
                totalcount = response.count.count
              }
            this.setState({
              allCPList: response.data,
              noOfRecords: totalcount,
              noWallboxExists:noWallboxExists1,
              prevPageFirstCreTime: this.state.curPageFirstCreTime,
              curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              // selectedItems:response.associatedCPList,
              // associatedCPList:response.associatedCPList
            })
          }
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }




  getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.URLPath + servicesUrl.getCustomizationWithTenant + tenant;

    fetch(url, {
      method: types.GET,
      headers: getSecurityHeaders()
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }else{
        return resp.json();
      }
    }).then(response => {
      let accessVal = false;debugger
      response.map((operation, index) => {
        if (operation.operation == 'Charging Session') {
          operation.subOperations.map((subOperations, index) => {
            if (subOperations.name == "RFID Access Control") {
              accessVal = subOperations.access;
            }
          })
        }
      })
      this.setState({
        RfidAccessVal: accessVal
      })
    })
  }

  /* To get SecurityData list*/
  getSecurityData() {
    let url = baseUrl.URLPath + servicesUrl.getSecurityPreferences;

    fetch(url, {
      method: types.GET,
      headers: getSecurityHeaders()
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(response => {
      if (response != undefined) {debugger
        this.setState({
          isFreeChargeEnabled: response.isFreeChargeEnabled
        })
      }
    }).catch((error) => {
      console.log(error, "get connector status catch the loop");
    })
  }

  handleWallboxChange(e) {
    this.setState({
      cpid: e.target.value
    }, () => {
      this.clearWallboxerror();
      if (this.state.cpid.length == 0) {
        this.getRFIDCPlist();
      }
    });
  }

  clearWallboxerror() {
    this.setState({
      noWallboxExists: ''
    })
  }

  handleWallboxPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ cpid: e.target.value });
      this.getAllCpList();
    }
    if ((key == "Backspace" || key == "Delete") && e.target.value.length == 1) {
      this.setState({
        cpid: ""
      }, () => {
        this.clearWallboxerror();
        this.getAllCpList();
      });
    }
  }

  FindRfidCp(e) {
    e.preventDefault();
    if (this.state.cpid == "") {
      this.setState({
        noWallboxExists: this.props.t('enter_the_charge_point')
      })
    } else {
      this.getAllCpList();
      this.setState({
        noWallboxExists: ""
      })
    }
  }

  isItemSelected(item) {
    const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
    return retVal;
  }

  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedItems];
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    $('.error_cpids').text("");
    if (event.target.checked) {
      const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item);
      allItems.push(item);
      deletedWallboxes.splice(isAlreadyDeleted, 1);
    } else {
      allItems.splice(selectedItemIndex, 1);
      deletedWallboxes.push(item);
    }
    this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
  }

  // getIsAllSitesSelected() {
  //   const { selectedItems, allCPList } = this.state;
  //   let isAllChecked = false;
  //   if (allCPList && selectedItems) {
  //     for (let i = 0; i < allCPList.length; i++) {
  //       if (selectedItems.some(e => e == allCPList[i]) == true) {
  //         isAllChecked = true;
  //       } else {
  //         isAllChecked = false;
  //         break;
  //       }
  //     }
  //   } else {
  //     isAllChecked = false;
  //   }
  //   return isAllChecked;
  // }

  // onUpdateSiteSelectAll(event) {
  //   document.getElementById("loader_image_div").style.display = "block";
  //   const isChecked = event;
  //   $('.error_cpids').text("");
  //   if (isChecked) {
  //     var selectedItems = [];
  //     // this.state.allCPList.map((site,index)=>{
  //     selectedItems = [...this.state.allCPList];
  //     // });
  //     setTimeout(() => {
  //       this.setState({ selectedItems: selectedItems, disabledbtn: "", deletedWallboxes: [] }, () => {
  //         document.getElementById("loader_image_div").style.display = "none";
  //       });
  //     }, 2000);
  //   } else {
  //     var deletedWallboxes = [];
  //     // this.state.allCPList.map((site,index)=>{
  //     deletedWallboxes = [...this.state.allCPList];
  //     // });
  //     setTimeout(() => {
  //       this.setState({ selectedItems: [], deletedWallboxes: deletedWallboxes, disabledbtn: "" }, () => {
  //         document.getElementById("loader_image_div").style.display = "none";
  //       });
  //     }, 2000);

  //   }
  // }


  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getAllCpList(false);
      });
    } else {
      //next
      
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getAllCpList(false);
      });
    }
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  removeSelectedItem(item, index) {
    const allItems = [...this.state.selectedItems];
    allItems.splice(index, 1);
    const deletedWallboxIndex = this.state.allCPList.findIndex(el => el === item);
    const deletedWallboxes = [...this.state.deletedWallboxes];
    if (deletedWallboxIndex > -1) {
      deletedWallboxes.push(item);
    }
    this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
  }


  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: "",
      toDateApi: ""
    }, () => {
      this.getAllCpList(true,this.state.acpId);
    })
  }


  render() {
  const{t}=this.props
    return (
      <>
        <main className="content-div" >
          <p>{t('Authorization')}
            <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/Ocpptags"><span>{t('Authorization')}</span></Link > &gt; <span className="breadcrumb_page">{t('add')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button className="pull-right btn_primary" type="button" data-test="button" onClick={this.redirectToListPage} >
                    <i className="fas fa-angle-left mr-2"></i>  {t('back')}
                  </button>
                </div>
              </div>
            </div>
            <MDBRow className="mb-4 mt-3">
              <MDBCol sm="12">
                <div className="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{ display: "none" }} ></div>
                <div>
                  <form
                    className='needs-validation'
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          name="tag"
                          value={this.state.fields["idTag"]}
                          onChange={this.changeHandler.bind(this, "idTag")}
                          type="text"
                          id="idTag"
                          label={t('rfid_tag*')}
                        >
                          <div className="valid-feedback">{t('looks_good')}</div>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["idtagempty"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["entervalididtag"]}</span></span>
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["idtagexist"]}</span></span>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4" className="form_margin" >
                        <MDBInput
                          value={this.state.fields["note"]}
                          name="noteId"
                          onChange={this.changeHandler.bind(this, "note")}
                          type="text"
                          id="noteId"
                          label={t('additional_note')}
                        >
                          <div className="valid-feedback">{t('looks_good')}</div>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4"></MDBCol>
                      <MDBCol md="4" className="form_margin">
                        <span className="cal_label mr-2">{t('expiry_date_time')}</span>
                        <div className="pull-left mt-2" style={{ width: "55%" }} >
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            className="cal_margin tag_cal"
                            timeCaption="time"
                            dateFormat="dd-MMM-yyyy HH:mm"
                            minDate={new Date()}
                          />
                        </div>
                      </MDBCol>

                      <MDBCol md="4" className="form_margin">
                        <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">
                          {t('choose_rfid_tag_type')} <span className="custom_tooltip"><i className="fa fa-info-circle" aria-hidden="true"></i>
                            <div className="custom_tooltiptext">
                              <div className="custom_tooltip_header">{t('rfid_tag_type')}</div>
                              <div className="custom_tooltip_body">
                                <div>{t('type_of_rfid_tag')}</div>
                                <div><span className="font-bold">{t('private')}: </span>{t('rfid_tag_intended_for_private_use')}</div>
                                <div><span className="font-bold">{t('company')}: </span>{t('rfid_tag_was_issued_by_company')}</div>
                                <div><span className="font-bold">{t('other')}: </span>{t('default_setting_no_further_logic_involved')}</div>
                              </div>
                            </div>
                          </span> </label>
                        <select className="browser-default custom-select select_height w_53 indent" id="rfidTagType" onChange={this.rfidTypeChange} value={this.state.fields.tagType}>
                          <option value="other">{t('other')}</option>
                          <option value="private">{t('private')}</option>
                          <option value="company">{t('company')}</option>
                        </select>
                        <div className="mt-3"><span className="error_msg w3-animate-top error_tagType" style={{ color: "red" }}></span></div>
                      </MDBCol>
                      <MDBCol md="4"></MDBCol>
                    </MDBRow>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>

            {(this.state.RfidAccessVal == true && this.state.isFreeChargeEnabled == false) > 0 &&
              <>
                <div className="col-md-12">
                  <div className='row'>
                    <div className="col-md-4 mt-1">
                     <CpSearch
                       getData = {this.getAllCpList}
                       pagename = "chargePoint"
                       noWallboxExists = {this.state.noWallboxExists}
                     >
                     </CpSearch>
                    </div>

                    <div className="col-md-8">
                      <div className="pull-right">
                        <label className="pull-left sub-text">{t('show_entries')}</label>
                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv p-rel cpselectdiv" >
                          <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                            <option>10</option>
                            <option>20</option>
                            <option selected="selected">30</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="col-md-3 mt-2">
                      {/* <div className="d-flex align-items-center">
                        {
                          this.getIsAllSitesSelected() ? <><i class="far fa-square" onClick={this.onUpdateSiteSelectAll.bind(this, false)}></i> <span className='ml-2'>Unselect All</span></> : <><i class="far fa-square" onClick={this.onUpdateSiteSelectAll.bind(this, true)}></i> <span className='ml-2'>Select All</span></>
                          //   <Form.Check
                          //   custom
                          //   type="checkbox"
                          //   id="siteAccessCheck"<i class="far fa-square"></i>
                          //   label={"Unselect All"}
                          //   className="ml-5"
                          //   checked={false}
                          //   onChange={this.onUpdateSiteSelectAll}
                          // />
                          // :
                          // <Form.Check
                          //   custom
                          //   type="checkbox"
                          //   id="siteAccessCheck"
                          //   label={"Select All"}
                          //   className="ml-5"
                          //   checked={false}
                          //   onChange={this.onUpdateSiteSelectAll}
                          // />
                        }

                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-1">
                  <div className="mt-1 mb-2"><span className="error_msg w3-animate-top error_cpids" style={{ color: "red" }}></span></div>
                  <div className='content-innerdiv mt-2'>
                    <div className='row rfidDiv'>
                      <div className="col-md-8 lightBlue">
                        <label><b>{t('charge_point')}:</b>
                        </label>
                        <div className="row">
                          {this.state.allCPList.map((el, index) => {
                            return (
                              <div className="col-md-3 pl-0">
                                <Form.Check
                                  className=""
                                  custom
                                  id={`${el.chargePointId}_${index}`}
                                  type="checkbox"
                                  checked={this.isItemSelected(el.chargePointId)}
                                  onChange={(event) => this.updateCheckboxSelection(event, el.chargePointId)}
                                  label={el.chargePointId}
                                />
                              </div>
                            )
                          })
                          }
                        </div>
                      </div>
                      {this.state.selectedItems.length > 0 &&
                        <div className="col-md-4 lightBlue">
                          <label>
                            <b>{t('rfid_tag_valid_for_charge_point')}:</b>
                            {/* <b>Selected Charge Point:</b> */}
                          </label>
                          <div className="row">
                            <div className="col-md-12">
                              {
                                this.state.selectedItems.map((el, index) =>
                                  <span key={index} className="cpChargePointSspan">
                                    {el} <i class="fa fa-times" onClick={() => this.removeSelectedItem(el, index)}></i>
                                  </span>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-3 mb-1 text-right">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={this.state.noOfRecords}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Prev"
                        nextPageText="Next"
                        itemClassPrev="prevBtn"
                        pageRangeDisplayed="1"
                        activeClass="activeLi active"
                        itemClassNext="nextBtn"
                        disableInitialCallback={true}
                        disabledClass="disabled disabledtextColor"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
            <div className='col-md-12 mt-2 mb-5'>
              <button type="button" data-test="button" onClick={this.handleSubmit.bind(this)} className="btn_primary mr-1 pull-right">
                {t('save')} <i class="fas fa-save ml-2"></i>
              </button>
            </div>
          </div>
        </main>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('ocpp_tag_successfully_added')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>{t('ok')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
           {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('OK')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(Ocppnewtag);