import React from 'react';
import { MDBDataTable,MDBRow,MDBIcon,Select,MDBInput,MDBCol,MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import {Form,Table,Check } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import * as baseUrl from './constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import  $, { get } from "jquery";
import Header from './header';
import { Alert } from 'reactstrap';
import { createBrowserHistory } from 'history';
import {Typeahead} from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';


import moment from 'moment';
import servicesUrl from '../common/servicesUrl';


const history = createBrowserHistory();


class showDiagnostics extends React.Component {  
    constructor (props) {
            super(props);
            this.state = {
              cpIdSorting:false,
              noOfRecords:0,
              data:new Map(),
              pageSize:10,
              activePage: 1,
              intial_data:[],
              multiple:false,
              options:[],
              selectDataArray:[],
              saveSelectedCpid:"",
              selectFindStatus : false,

              fileName:"",
              diagnosticURL:"",

              model:false,
              startDate:new Date(),
              dataArray: [
                            
                        ],
                        getArray:[],
                        getChargepoint:[],
                        modal2: false,
                        page_title:"Show Diagnostics",
                        org_name:"",
                        fields:{
                          productName:"",
                          description:"",
                          buildType:"",
                          productCode:"",
                          chargePointId:""
                        },
                        orgId:"",
                        sucess:false,
                        colour:'sucess',
                        message:"",
                        errors:{},
                        identifier:"",
                        modal:false,
                        modal1:false,
                        archiveStatus:"Active",
                        popupStatus:"",
                        id:"",
                        chargePointId:"",
                        modalsession:false,
                        icondisplay:""
        }

         this.getDiagnosticbasedonchargepoint=this.getDiagnosticbasedonchargepoint.bind(this);
         this.showEntriesChange=this.showEntriesChange.bind(this);
         this.handleKeyDown=this.handleKeyDown.bind(this);
         this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
         this.chargepointIDChange=this.chargepointIDChange.bind(this);
         this.onBlurEvent=this.onBlurEvent.bind(this);

         this.onShowAlert=this.onShowAlert.bind(this);
         this.changeHandler = this.changeHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getShowdiagnostics = this.getShowdiagnostics.bind(this);
        this.AddArchive = this.AddArchive.bind(this);
        this.toggleArchive = this.toggleArchive.bind(this);
        this.CloseArchive = this.CloseArchive.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.tagOk2 = this.tagOk2.bind(this);
        this.onClickDownloadicon = this.onClickDownloadicon.bind(this);
        this.ClearChargepoint=this.ClearChargepoint.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);





        }
        onBlurEvent(event){
          ;
            var chargePoint = event.target.value;
             if(chargePoint.length==0){
              this.getShowdiagnostics(this.state.pageSize,this.state.activePage,this.state.saveSelectedCpid)
              this.setState({
                 getArray:this.state.getArray,
              });
            }
             let errors = {};
            errors["chargePointNotExists"] = "";
             errors["selectChargepoint"] = "";
            this.setState({
              errors:errors
              }); 
           }
          
          
        // api to get chargepointId based on search filter

chargepointIDChange(cpid){
  this.state.selectFindStatus = true;

   this.state.saveSelectedCpid = cpid
  this.setState({saveSelectedCpid:cpid})
  if(cpid.length==0){

    this.getShowdiagnostics(this.state.pageSize,this.state.activePage)
    this.setState({
       getArray:this.state.getArray,
    });
  }else{
    let errors = {};
    errors["chargePointNotExists"] = "";
     errors["selectChargepoint"] = "";
    this.setState({
      errors:errors
      }); 

    this.getShowdiagnostics(this.state.pageSize,this.state.activePage,cpid);
  }

}
        // search filter 
handleKeyDown(ref,e){
  ;
  var serial_no = e.target.value;
  var key = e.key;
  var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false); 
  var getList = this.state.intial_data;
  if(serial_no!=0){
    this.state.errors["selectChargepoint"] = "";
  }
  if( key == "Backspace" || key == "Delete" ){
    this.state.errors["selectChargepoint"] = "";
      $("#notexists").text("");
      var x=e.target.value
      var sno=x.slice(0,x.length-1)
      if(sno==""){
       
        this.state.saveSelectedCpid=sno;
  
        this.getShowdiagnostics(this.state.pageSize,this.state.activePage)
      }

    }
    var sno=serial_no.slice(0,serial_no.length-1)
    this.getSearchInventoryList(sno)

      if(e.keyCode == 13){
        this.FindCpName(ref,"enter");
        $("#rbt_id").hide();
      }
      if((e.keyCode == 86 && ctrl)){
        this.getSearchInventoryList(serial_no)
      }
      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
        break;
        default:
          var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
        
            e.preventDefault();
    
            return false;
         
           
        }
        break;
      
    }
   
  }
  getSearchInventoryList(cpid){
 

    ;
      var cp_id = [];
    
       var url = baseUrl.URLPath+"diagnostics/lookup/search?cpid="+cpid;
      //  /api/diagnostics/lookup/search?cpid={chargePointId}
    
       fetch(url,{
          method: "GET",
          headers:{
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
        })
        .then((resp) => {
          ;
          if(resp.status==401){
            this.togglesession();
            localStorage.clear();
          }
          if(resp.status==200){
            return resp.json();
          }    
        }) 
        .then((response) =>{
              
            if(response!=undefined){
                var option = [];
                var data = [];
                this.setState({
                  options:response,
                  intial_data:response
                });
            }
          })
          .catch((error) => {
              console.log(error, "catch the loop");
          })
    }

        showEntriesChange(){
          var entries=document.getElementById("showEntries").value;
          // this.state.data.clear();
          // this.setState({pageSize:entries});
          this.state.activePage=1;
          this.state.pageSize=entries;
          this.getShowdiagnostics(this.state.pageSize,this.state.activePage);
        }

        
    FindCpName(ref,event){
     
      this.state.saveSelectedCpid = ref.current.inputNode.value;
      var errors = {};
      if(this.state.saveSelectedCpid == null || this.state.saveSelectedCpid ==""){
        this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
        $("#notexists").text(" ");
      }else{
        this.state.errors["entercharge"] = " ";
        this.state.pageSize=10;
        this.state.activePage=1;
        this.getShowdiagnostics(this.state.pageSize,this.state.activePage,event);
      }
        this.state.selectFindStatus = true;
        this.state.pageSize=10;
        this.state.activePage=1;
    this.getShowdiagnostics(this.state.pageSize,this.state.activePage,event);
    }
    
        ClearChargepoint(ref,event){
          this.state.selectFindStatus = false;
          let errors = {};
          this.state.errors["selectChargepoint"] = "";
  $("#notexists").text("");
            this.state.saveSelectedCpid = "";
            this.state.pageSize=10;
        this.state.activePage=1;
        document.getElementById("showEntries").value=10;
            this.getShowdiagnostics(this.state.pageSize,this.state.activePage,this.state.saveSelectedCpid);
            this.setState({
              getArray:this.state.datagetArrayArray,errors:errors
            });
            ref.current.clear();
            this.setState({
              chargePoint:""
            })
        }
        


  handlePageChange(pageNumber) {
    
    if (pageNumber != this.state.activePage) {
      this.state.activePage=pageNumber;
      this.getShowdiagnostics(this.state.pageSize, pageNumber);
    }
  }
        toggle2 = () => {
          this.setState({
            modal2: !this.state.modal2
          });
        }
        tagOk2(){
          // this.props.history.push('/');
          // history.push('/');
          window.location.reload();
        }

        async togglesession(){
          ;
          this.setState({
          modalsession: !this.state.modalsession
          });
        }
        
        
        sessiontagOk(){
          // this.props.history.push('/');
          history.push('/');
          window.location.reload();
        }
        
 CloseArchive = () => {
  this.setState({
    modal1: !this.state.modal1
  });

}
        toggleArchive = (id,text) => {
          this.setState({
              modal1: !this.state.modal1,
             id:id,
              popupStatus:text
          });
        }
        
        changeHandler(){

        }
        
 onShowAlert(){
  
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
       // function to add in archive list 
       AddArchive(){
  
  this.setState({
      modal1: !this.state.modal1
   });
   var id = this.state.id;
   var status ="";
   if(this.state.popupStatus=="Active"){
    status = "2";
   }else{
    status = "1";
   }
   
   var url = baseUrl.FOTAPath +servicesUrl.getDiagnosticsArchive + "?status="+status+"&id="+id;
  //  api/diagnostics/{id}/archive/{status}
   fetch(url,{
     method: "PUT",
     headers: {
       "content-type":"application/json",
       "Authorization":localStorage.getItem("Authorization"),     }
     })
     .then((resp) => {
       ;
       if(resp.status==401){
        this.togglesession();
         // alert("Access token is expired, please login again")
         localStorage.clear();
      
       }
       if(resp.status== 412){
          this.setState({modal2:false})
          this.setState({message:this.props.t('failed_msg'),colour:'danger'})
          this.onShowAlert();
          return resp.json();
       }
       if(resp.status==200){

        this.setState({modal2:false})
        // this.setState({message:"This diagnostic is added to "+this.state.popupStatus.toLowerCase()+" sucessfully.",colour:'success'})
        // this.onShowAlert();
        // window.location.reload();
        this.toggle2()

         return resp.json();

       }        }) 
     .then((response) =>{
      //  if(response!=undefined){
      //   this.getBuildStatus(this.state.pageSize,this.state.activePage);
      //  }
     })
     .catch((error) => {
         console.log(error, "catch the loop")
     })
     this.getShowdiagnostics(this.state.pageSize,this.state.activePage);


  }

  changeHandler(field, e){ 

    let fields = this.state.fields;
    fields[field] = e.target.value; 
    fields.chargePointid = document.getElementById('chargePointId')

    this.setState({fields});
    }

        componentDidMount(){
      
          // api for get all the organization 
          var orgname  = localStorage.getItem("tenant");
          this.setState({
              org_name:orgname
          })
          // var url = baseUrl.URLPath + "admin/v1.0/account/all";
          // fetch(url,{
          //   method: "GET",
          //   headers: {
          //         "content-type":"application/json",
          //         "Authorization":localStorage.getItem("Authorization")
          //       }
          //   })
          //   .then((resp) => {
          //     ;
          //     if(resp.status==401){
          //       // alert("Access token is expired, please login again")
          //       this.togglesession();
          
          //       localStorage.clear();
               
          //     }
          //     if(resp.status==200){
          //       return resp.json();
          //     }
          //   }) 
          //   .then((response) =>{
          //     
          //     if(response!=undefined){
          //   this.setState({ dataArray:response.organisationDTOS })
          //   var org_name = response.organisationDTOS;
          //   // alert(org_name)
          //   for(var i=0; i < org_name.length;i++){
          //         if(this.state.org_name.toLowerCase == org_name[i].company.toLowerCase){
          //             this.setState({ identifier:org_name[i].identifier })
          //         }
          //     }
               this.getShowdiagnostics(this.state.pageSize,this.state.activePage);
          //   }
          //   })
          //   .catch((error) => {
          //       console.log(error, "catch the loop")
          //   })
      }
    
      handleChange(event){
        ;
        this.state.identifier = event.target.value;
        this.getShowdiagnostics(this.state.pageSize,this.state.activePage);
    }

    getDiagnosticbasedonchargepoint(){
      let fields = this.state.fields
      this.setState({fields}, () =>{
        if(this.state.fields.chargePointId!=""){
      var url = baseUrl.URLPath + servicesUrl.getDiagnosticsCP+ "?chargePointId="+this.state.fields.chargePointId;
      // GET /api/diagnostics/chargepoints/{chargePointId}
            fetch(url,{
        method: "GET",
        headers: {
              "content-type":"application/json",
              "Authorization":localStorage.getItem("Authorization")
            }
        })
        .then((resp) => {
          ;
          if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          
          if(response!=undefined){
        this.setState({ getArray:response})
          }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
      }
      });
        }

        getShowdiagnostics(pageSize,pageNo, action){
          if(this.state.selectFindStatus == true){
            var url = baseUrl.URLPath + "diagnostics/all?chargePointId="+this.state.saveSelectedCpid+"&pageSize="+pageSize+"&pageNo="+pageNo;
          }
           else{
          // var url = baseUrl.URLPath + "chargepoints/getbycp/"+cpid;
          var url = baseUrl.URLPath + "diagnostics/all?chargePointId="+this.state.saveSelectedCpid+"&pageSize="+pageSize+"&pageNo="+pageNo;
        } 
  
           
            // Basepath + /api/diagnostics/all?chargePointId={}&pageNo={}&pageSize={}

            fetch(url,{
              method: "GET",
              headers: {
                    "content-type":"application/json",
                    "Authorization":localStorage.getItem("Authorization")
                  }
              })
              .then((resp) => {
                ;
                if(resp.status==401){
                  // alert("Access token is expired, please login again")
                  this.togglesession();
            
                  localStorage.clear();
                 
                }
                if(resp.status==200){
                  return resp.json();
                }
              }) 
              .then((response) =>{
                
                if(response!=undefined){
                  if(response.count.count==0){
                    if(action=="click" || action=="enter"){ 
                      $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
                                      }
                                      this.setState({ 
                                        getArray:[],
                                        noOfRecords:0,
                                        selectDataArray:[]
                        
                        
                                      })
                  }else{
              this.setState({ 
                getArray:response.diagnostics,
                noOfRecords:response.count.count,
                selectDataArray:response.diagnostics


              })
              if(action=="click" || action=="enter"){ 
                $("#notexists").text(" ");
                                }
              var data= this.state.data;
              data.set(pageNo,response.count.count)
                }
              }
              })
              .catch((error) => {
                  console.log(error, "catch the loop")
              })
             
          }
  

          // onClickDownloadicon(diagnosticURL){
            
          // }
         onClickDownloadicon(diagnosticURL) {
            if(diagnosticURL == "" || diagnosticURL == null){
              this.setState({ message:this.props.t('file_not_exists'), colour: 'danger' })
             this.onShowAlert();
            }
           
          }
 //function for sorting table data on click
 ascendingOrder(){
  // this.setState({getArray : this.state.getArray.reverse()})
  if(this.state.cpIdSorting == false){
    this.state.getArray.sort(this.dynamicSort("chargePointId"));
    this.state.cpIdSorting = true;
       this.setState({getArray : this.state.getArray.sort(this.dynamicSort("chargePointId"))})
  }
   else{
    this.state.getArray.sort(this.dynamicSort("chargePointId"));
    this.state.cpIdSorting = false;
       this.setState({getArray : this.state.getArray.reverse()});
   } 
}
dynamicSort(property) {
  var sortOrder = 1;

  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a,b) {
      if(sortOrder == -1){
          return b[property].localeCompare(a[property]);
      }else{

          return a[property].localeCompare(b[property]);
      }        
  }
}
    render() {
      const{t}=this.props
      const ref = React.createRef();

        const organisation =this.state.dataArray.map((dataArray, index) => {
            var setOrg = false;
            if(this.state.org_name.toLowerCase() == dataArray.company.toLowerCase()){
                setOrg = true;
                this.state.identifier = dataArray.identifier;

            }
            return(
              <option value={dataArray.identifier} selected={setOrg} >{dataArray.company}</option>
            )
        })





        const data =this.state.selectDataArray.map((getArray, index) => {
            ;
           if(getArray.diagnosticURL != null && getArray.diagnosticURL!=""){
        this.state.icondisplay = "inline-block"
           }
           else{
            this.state.icondisplay = "none"

           }


          // if(getArray.diagnosticURL != null && getArray.diagnosticURL!=""){
            var archiveicon = "";
            var unarchiveicon = "";
            if(getArray.archiveStatus=="2"){
              archiveicon = "inline-block";
              unarchiveicon ="none";
             }else{
              archiveicon = "none";
              unarchiveicon ="inline-block";
             }
             if(getArray.startTime==null || getArray.startTime==""){
              var startTime = "";
              var startTime1="";
            }else{
              var startTime = moment(getArray.startTime).format("DD MMM YYYY");
              var startTime1 = moment(getArray.startTime).format("HH:mm");
            }
      
            
            return(
                
                <tr>
                <td className=""> {getArray.chargePointId }</td>
                <td className="">{getArray.fileName}</td>
                <td className="wrap-word">{ startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>}</td> 
                <td className=""><a href={getArray.diagnosticURL} download> <i class="fas fa-download action-icon" title="Download Diagnostics" style={{display:this.state.icondisplay}} onClick = {this.onClickDownloadicon.bind(this,getArray.diagnosticURL)}></i></a>
                <i  class="fas fa-archive action-icon cursor_defult pl-2 pr-2" style={{display:archiveicon}} title="Archive" onClick={this.toggleArchive.bind(this,getArray.id,"Archive")}></i>  
                <label onClick={this.toggleArchive.bind(this,getArray.id,"Active")} className="unarchive_icon mb-0 cursor_defult pl-2 pr-2" title="Active" style={{display:unarchiveicon}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.55 5.22l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.15.55L3.46 5.22C3.17 5.57 3 6.01 3 6.5V19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.49-.17-.93-.45-1.28zM12 9.5l5.5 5.5H14v2h-4v-2H6.5L12 9.5zM5.12 5l.82-1h12l.93 1H5.12z"/></svg></label>

                </td>

                </tr>
                )
            
            // }
            })
   

      return (
        <>
       <main class="content-div" >
           <p>{t('Show Diagnostics')}
              <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Show Diagnostics')}</span>
              </div>
           </p>
            <div className="page-outerdiv">
            {/* <div className="breadcrumb_div">Maintenance > Show Diagnostics</div> */}
              {/* end page title */}
              {/* page menu bar */}
              <div className="row">
             <div className="col-md-4 pull-right">
             <div class="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <Typeahead
                  labelKey="name"
                  className="w_80"
                  multiple={this.state.multiple}
                  options={this.state.options}
                  minLength={3}
                  placeholder={t('choose_a_charge_point_id')}
                   onKeyDown ={this.handleKeyDown.bind(this,ref)}
                   onChange={this.chargepointIDChange.bind(this)}
                  onBlur={this.onBlurEvent}
//  onFocus={this.ClearChargepoint.bind(this,ref)}
id="rbt_id"
                  ref={ref}
                />
                <button type="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this,ref)}  >
                  <span aria-hidden="true">×</span>
                </button>
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this,ref,"click")} >{t('find')}</button>
                </div>
                <span className="error_msg w3-animate-top mt-2">  <span style={{color: "red"}}>{this.state.errors["entercharge"]}</span></span> 
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
            </div>
             </div>
             <div className="col-md-8">
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}> 
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="pull-right sub-text mb-0">{t('show_entries')}</label>
               </div>
              </div>
          {/* end page menu bar  */}
          {/* search filter */}
              <div className="row  ">
                {/* <div className="col-md-3 ">
                    <select className="browser-default custom-select select_height " id="organization" onChange={this.handleChange.bind(this)}>
                        <option value="WEBASTO">Select Organization</option>
                        {organisation}
                    </select>
                </div> */}
                {/* <div className="col-md-4 ">
                  <div class="input-group">
                    <i className="fas fa-search fa-sm input-searchIcon"></i>
                    <input type="text"  id = "chargePointId" value={this.state.fields["chargePointId"]}  onChange={this.changeHandler.bind(this, "chargePointId")} class="form-control input-searchBox pad-2" placeholder="Charge Point ID" name="srch-term"  /> 
                    
                    <div class="input-group-btn">
                        <button class="input-searchBtn find-btn" type="submit" onClick={this.getDiagnosticbasedonchargepoint} >Find</button>
                    </div>
                  </div>
                </div> */}
                    {/* <MDBCol md="4">
                        <MDBInput
                            id = "chargePointId"
                            value={this.state.fields["chargePointId"]}
                            onChange={this.changeHandler.bind(this, "chargePointId")}
                            type="text"
                            name="adminAddress"
                            label="Charge Point ID"
                        >
                        <span className="error_msg w3-animate-top"> <span style={{color:"red"}}>{this.state.errors["entervalidlat"]}</span></span> 
                        </MDBInput>
                    </MDBCol>
                
               
                <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1" style={{color:"#fff"}} onClick={this.getDiagnosticbasedonchargepoint}>
                      Find
                </MDBBtn> */}
               
            </div>
            <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>

              {/* end page menu bar  */}
              {/* table start */}
             <div className="row mt-2 ">
               <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                    <thead>
                    <tr>
                        <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                        <th className="">{t('file_name')}</th>
                        <th>{t('received_date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                        <th className="">{t('action')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* <tr >
                     <td></td>
                     <td></td>
                     <td></td>
                     <td>
                         <a  download> <i class="fas fa-download action-icon" title="Download Firmware"></i></a>
                         <a  download ><i class="fas fa-sticky-note pl-2 pr-2 action-icon" title="Release Notes"></i></a>
                         <a download ><i class="fas fa-archive action-icon cursor_defult " title="Release Notes"></i></a>
                     </td>
                 </tr> */}
                        {data}
                    </tbody>
               </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
             <Pagination
               hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
             />
            </div>
              {/* End table  */}
              </div>
              </div>
          </main>
             {/* confirmation message popup for archive  */}
      <MDBModal isOpen={this.state.modal1} toggle={this.toggleArchive} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleArchive}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
               {t('do_you_want_to')} {this.state.popupStatus.toLowerCase()} {t('this_diagnostic')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.CloseArchive.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.AddArchive.bind(this)}>{t('yes')}</button>
                {/* <MDBBtn color="secondary" onClick={this.CloseArchive}>Close</MDBBtn> 
                <MDBBtn color="primary" onClick={this.AddArchive} >Yes</MDBBtn> */}
            </MDBModalFooter>
      </MDBModal>


      {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk.bind(this)}>{t('ok')}</button>
      {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
      </MDBModalFooter>
      </MDBModal>
      <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
          {t('diagnostic_added_to')} {this.state.popupStatus.toLowerCase()} {t('sucessfully')}         
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk2.bind(this)}>{t('ok')}</button>
            {/* <MDBBtn color="primary" onClick={this.tagOk2}>OK</MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>
     </>
      );
    }
}

export default withTranslation()(showDiagnostics);