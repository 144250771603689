import dotenv from  'dotenv'
dotenv.config();

//export const URLPath = "http://13.235.76.72:8080/cpms/"; //local server
//export const URLPath = "http://192.168.0.166:8090/cpms/"; //manoj machine
// export const URLPath = "http://3.6.26.113:80/cpms/"; //hug innovation
//export const URLPath = "http://localhost:8080/"; //local machine
// export const URLPath = "http://13.126.233.149:8081/cpms/";//techmo
//export const URLPath = "http://127.0.0.1:8080/cpms/"; //local machine
//export const URLPath = "http://192.168.1.38:8080/cpms/"; //manoj machine
// export const FOTAPath = "http://3.6.26.113:80/cpms/";
// export const FOTAPath = "http://13.126.233.149:8081/cpms/"; // techmo FOTA machine

// export const FOTAPath = "http://13.233.76.64/cpms/";//mongodb techmo
// export const URLPath = "http://13.233.76.64/cpms/";//mongodb techmo

// export const FOTAPath = "http://localhost:8080/api/";//techmo server
// export const URLPath = "http://localhost:8080/api/";
// export const LoginPath = "http://localhost:8080";



// export const FOTAPath = "http://3.6.226.231/cpms/api/";//techmo server
// export const URLPath = "http://3.6.226.231/cpms/api/";
// export const LoginPath = "http://3.6.226.231/cpms";

// export const FOTAPath = "http://devcpms.evprowebasto.com/cpms/api/";//dev server
// export const URLPath = "http://devcpms.evprowebasto.com/cpms/api/";
// export const LoginPath = "http://devcpms.evprowebasto.com/cpms";



export const FOTAPath  = process.env.REACT_APP_APIPATH +"/api/"; //multitenancy server
export const URLPath   = process.env.REACT_APP_APIPATH +"/api/";
export const LoginPath = process.env.REACT_APP_APIPATH;
export const DataPrivacyPath = process.env.REACT_APP_DATAPRIVACYPATH;
export const Imprint = process.env.REACT_APP_IMPRINT;
export const  CHANGE_AVILABILITY="ChangeAvailability";
export const  CHANGE_CONFIGURATION="ChangeConfiguration";
export const  CLEAR_CACHE="ClearCache";
export const  GET_DIAGNOSTICS="GetDiagnostics";
export const  REMOTE_START_TRANSACTION="RemoteStartTransaction";
export const  REMOTE_STOP_TRANSACTION="RemoteStopTransaction";
export const  RESET="Reset";
export const  UNLOCK_CONNECTOR="UnlockConnector";
export const  UPDATE_FIRMWARE="UpdateFirmware";
export const  RESERVE_NOW="ReserveNow";
export const  CANCEL_RESERVATION="CancelReservation";
export const  DATA_TRANSFOR="DataTransfer";
export const  GET_CONFIGURATION="GetConfiguration";
export const  GET_LOCAL_LIST_VERSION="GetLocalListVersion";
export const  SEND_LOCAL_LIST="SendLocalList";
export const  TRIGGER_MESSAGE="TriggerMessage";
export const  GET_COMPOSITE_SCHEDULE_REQUEST="GetCompositeSchedule";
export const  CLEAR_CHARGING_PROFILE_REQUEST="ClearChargingProfile";
export const  SET_CHARGING_PROFILE_REQUEST="SetChargingProfile";
export const UPLOAD_DIAGNOSTICS="uploadDiagnostics";


