import React from "react";
import ReactDOM from "react-dom";
import Chart from "react-apexcharts";
import * as baseUrl from "../constants";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Form, Table, Check } from "react-bootstrap";
class statusNotificationDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descList: [],
    };
    this.redirectToListPage = this.redirectToListPage.bind(this);
  }

  redirectToListPage() {
    this.props.history.push("/cpDashboard");
  }
  componentDidMount() {
    let desclist = JSON.parse(localStorage.getItem("statusNotificationDesc"));
    let errorIndex = localStorage.getItem("errorIndex");
    let errorList=[];
    errorList.push(desclist[errorIndex]);

    this.setState({ descList: errorList});
  }
  render() {
    const inventoryTable = this.state.descList.map((list, index) => {
      if(list.count>0){
        return list.descList.map((description,i)=>{
          return(
            <tr>
              {(i===0)? <td rowSpan={list.count}>{list.group}</td> :null}
            <td>{list.descList[i]}</td>
          </tr>
          );
        })
      }else{
        return(<></>);
      }
    });
    return (
      <>
        <main class="content-div">
          <p>
            CSR
            <div className="breadcrumb_div">
             CSR &gt;{" "}
              <span className="breadcrumb_page"> CP Dashboard</span>  &gt;{" "}
              <span className="breadcrumb_page">StatusNotificationDescription</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button
                    class="pull-right btn_primary"
                    type="button"
                    data-test="button"
                    onClick={this.redirectToListPage}
                  >
                    <i className="fas fa-angle-left mr-2"></i> Back
                  </button>
                </div>
              </div>
            </div>
            <div className="row menubar">
              <div className="col-md-12 ">
                <div className="row ">
                  <div className="col-md-12">
                    <Table striped hover size="sm" className="page-table">
                      <thead>
                        <tr>
                          <th>Group</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                      {inventoryTable}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default statusNotificationDescription;
