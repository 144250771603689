import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ChargePointSelection from './chargePointSelection';
import * as baseUrl from './constants';
import { Alert } from 'reactstrap';
import Header from './header';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import $, { get } from "jquery";
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();


class reserveNow extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
         fields:{
              connectorId:'',
              idTag:'',
              // reservationId:'',
              parentIdTag:''

          },
          cpList:[],
          tagList:[],
          expiryDate:'',
          sucess:false,
          colour:'sucess',
          startDate:'',
          page_title:"Reserve Now",
          errors:{},
          parentIds:[],
          modalsession:false,
          startDate_in:'',
          scheduleDate:'',
          chargePoint:'',
          operationId:''
        }
        this.setStartDate1 = this.setStartDate1.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
        this.getParentId=this.getParentId.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.scheduleDateChange = this.scheduleDateChange.bind(this);
      }


      async togglesession(){
        ;
        this.setState({
        modalsession: !this.state.modalsession
        });
      }
      
      
      sessiontagOk(){
        // this.props.history.push('/');
        history.push('/');
        window.location.reload();
      }
      onShowAlert(){
        
       this.setState({sucess:true},()=>{
         window.setTimeout(()=>{
           this.setState({sucess:false})
         },5000)
       });
     
      }
      getParentId(){
        var idTags=this.state.tagList;
        for(var i=0;i<idTags.length;i++){
          if(idTags[i].idTag==this.state.fields.idTag){
            if(idTags[i].parentIdTag!=null){
              this.setState({parentIds:[idTags[i].parentIdTag.idTag]})
            }else{
              this.setState({parentIds:[]})
            }
           
          }
        }
      }
      setStartDate1(date){
        ;
        this.setState({ expiryDate: date })
      }

      async handleSubmit(event) {
        
        var dfdf=this.state;
        var dfdff= this.getMessageType();
        var dfdfs= this.getPayLoad();
        var payload=this.getPayLoad();
        // alert(document.getElementById("tagList").value);
      
        var errors={};
        var connectorId=payload.connectorId;
        var idTag=payload.idTag;
        var expiryDate=payload.expiryDate;
        var scheduleDate=payload.scheduleDate;
        // var reservationId=payload.reservationId; 
        var ocpptag =document.getElementById("tagList").value;

      if(this.getCPList()==0){
        errors["emptyChargePoint"]=this.props.t('select_charge_point')
      }
      if(isNaN(connectorId)){
        errors["emptyConnectorId"]=this.props.t('enter_connector_id')
      }
     
      if (ocpptag == `-- ${this.props.t("select_rfid_tag")} --`) {
        errors["emptyTag"] = this.props.t("select_ocpp_id_tag");
      }
      if(expiryDate==''){
        errors["emptyexpiryDate"]=this.props.t('enter_expiry_date_time')
      }
      if(scheduleDate==''){
        errors["emptyscheduleDate"]=this.props.t('enter_schedule_date_time')
      }
      if(this.getCPList()!=0 && !isNaN(connectorId) && ocpptag!=`-- ${this.props.t('select_rfid_tag')} --` && expiryDate!='' && scheduleDate!=''){
        var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
       if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
          this.onShowAlert();
        })
      } else {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
          this.onShowAlert();
        })
      }
     }
     this.setState({errors:errors})
      }
      
      getCPList() {
        var Id = this.state.operationId;
 if(Id != null&& Id != ""){
  var cplist = Id.split();

 }
 else{
  var cplist = this.state.cpList;

}
return cplist;
      }
      
      getPayLoad() {
        var payload =
        {
          "connectorId": parseInt(this.state.fields.connectorId),
          "expiryDate": this.state.expiryDate,
          "idTag": this.state.fields.idTag,
          // "reservationId": parseInt(this.state.fields.reservationId),
          "parentIdTag":this.state.fields.parentIdTag,
          "scheduleDate": this.state.scheduleDate,
        };
         if(this.state.fields.parentIdTag==""){
           delete payload.parentIdTag
         }
        return payload;
      
      }
      
      getMessageType() {
        return constants.RESERVE_NOW;
      }

      getSelectedCP(cpList){
        this.setState({cpList:cpList});
        if(cpList!=""){
          this.state.errors["emptyChargePoint"]=" "
        }
        this.getRFIDTag(cpList);
      }
      // connectorIdChange(){
      //   var id = document.getElementById("connectorId").value;
      //   let fields = this.state.fields;
      //   fields["connectorId"] = id;
      //   this.setState({fields});
      // }
      changeHandler(field, e){ 

        let fields = this.state.fields;
        fields[field] = e.target.value; 
        this.setState({fields});
        }
        numberHandler(field, e){ 
        
          let fields = this.state.fields;
          if(e.target.validity.valid){
            fields[field] = e.target.value; 
            this.setState({fields});
            this.state.errors["emptyConnectorId"] = "";
          
          }else if(e.target.value==""){
            fields[field] = e.target.value; 
            this.setState({fields});
          
          
          }
        
        }
 
      idTagChange(){
     

        ;
        var id = document.getElementById("tagList").value;
        let fields = this.state.fields;
        fields["idTag"] = id;
        this.setState({fields});
        this.getParentId();
        if(id!=`-- ${this.props.t('select_rfid_tag')} --`){
        
          this.state.errors["emptyTag"]=" "
          
        }
        
      }
      parentidTagChange(){
        ;
        var id = document.getElementById("parentidList").value;
        let fields = this.state.fields;
        fields["parentIdTag"] = id;
        this.setState({fields});
      }
    
      
      reservationIdChange(){
        ;
        var id = document.getElementById("reservationId").value;
        let fields = this.state.fields;
        fields["reservationId"] = id;
        this.setState({fields});
      }
      componentDidMount(){
        let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
      this.getRFIDTag(propsdata.operationId);
    }
  }

       
      }


      getRFIDTag(cpid){
        var url = baseUrl.URLPath + servicesUrl.getOcpptagList + "?cpId=" + cpid
      var errors = {};
        fetch(url,{
          method: "GET",
          headers: {
            "content-type":"application/json",
              "Authorization":localStorage.getItem("Authorization"),
          }
          })
          .then((resp) => {
            ;
            if(resp.status==401){
              // alert("Access token is expired, please login again")
              this.togglesession();
        
              localStorage.clear();
             
            }
            if(resp.status==200){
              return resp.json();
            }
          }) 
          .then((response) =>{
            
            if(response!=undefined){
          if(cpid!="" && cpid!=null && cpid != undefined && response.data.length==0){
            errors["emptyTag"] = this.props.t('rfid_found_for_charge_point');
          }else{
            errors["emptyTag"] = "";
          }
          this.setState({ tagList:response.data ,errors:errors })
      
            }
          this.setSelectedStatus();
          })
          .catch((error) => {
              console.log(error, "catch the loop")
          })
      }



      handleChange = date => {
     

       
        this.setState({startDate:date});
        //var tempDate = new Date(date);
      
      
        var currentDate = new Date(date);
      var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
      var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
      var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
      var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
      var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
      var csFormatDate=currentDate.getFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":00Z";
 
        // var date1 = (tempDate.getMonth()+1)  + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() 
       
        this.setState({expiryDate:csFormatDate});
        if(date!=null){
       
          this.state.errors["emptyexpiryDate"]=" "
          
        }
       
      };


      scheduleDateChange = date => {
       

        this.setState({startDate_in:date});
        //var tempDate = new Date(date);
          var currentDate = new Date(date);
        var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
        var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
        var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
        var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
        var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
        
        var csFormatDate=currentDate.getFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":00Z";
          // var date1 = (tempDate.getMonth()+1)  + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() 
         
          this.setState({scheduleDate:csFormatDate});
          if(date!=null){
        
            this.state.errors["emptyscheduleDate"]=" "
          
          }
          
     
        };
        componentWillUnmount(){
          localStorage.setItem('chargePoint', '');
          localStorage.setItem('operationId', '');
        }
render() {

  const{t}=this.props
  const parentIds =this.state.parentIds.map((tag, index) =>{
    return(
      <>
    
      
         {
         (tag.trim()!=0 || tag.trim()!="")? <option value={tag}>{tag}</option>: null
      }
    </>
    )
         });
  const tagList =this.state.tagList.map((tag, index) =>{
    return(
      <>
        {
         (tag.idTag.trim()!=0 ||tag.idTag.trim()!="")? <option value={tag.idTag}>{tag.idTag}</option>: null
      }
      
    </>
    )
         });
  return (
    <>
   

   <main class="content-div" >
   <p>  {t('Reserve Now')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">  {t('Reserve Now')}  </span>
              </div>
           </p>
    {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Reserve Now </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Reserve Now </a></div> */}

          {/* <p>Reserve Now</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
      {/* <section className="dashboard">
        <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left" >Reserve Now</h4>
                <div class="clearfix"></div>
            </div>
        </div>
        <section className="content_wbg">  */}
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            {/* <MDBCol md="4">
            <select className="browser-default custom-select select_height">
                <option>Select One:	</option>
                <option value="1"></option>
            </select>
            </MDBCol> */}
          <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName = "ReserveNow"></ChargePointSelection>
          <MDBCol md="12">
         <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
         </MDBCol>
          <MDBCol md="4">
         
              <MDBInput
              value={this.state.fields["connectorId"]}
              name="ConnectorID"
                onInput={this.numberHandler.bind(this,"connectorId")}
                type="text"  pattern="[0-9]*"
             
                id="connectorID"
                label={t('connector_id_integer')}
                required
              >
        <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyConnectorId"]}</span></span>

                {/* <div className="valid-feedback">Looks good!</div> */}
                <small id="heartbeatinterval" className="form-text text-muted">
                {t('if_empty0charge_point')}
                </small>
              </MDBInput>
            </MDBCol>
             
              <MDBCol md="4">
            <select className="browser-default custom-select select_height mb_8" id="tagList" onChange={this.idTagChange.bind(this)}>
                <option>-- {t('select_rfid_tag')} --</option>
                {tagList}

            </select>
            <span className="error_msg w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyTag"]}</span></span>      	

            </MDBCol>
            <MDBCol md="4">
            <select className="browser-default custom-select select_height mb_8" id="parentidList" onChange={this.parentidTagChange.bind(this)}>
                <option>-- {t('select_parent_id_tag')} --</option>
                {parentIds}
                {/* {tagList} */}

            </select>

            </MDBCol>
            
              
       <MDBCol md="4" className="" >
                    
               <span className="cal_label">{t('schedule_date_time')}</span>
               <div className="pull-left mt-2" style={{width:"50%"}} >
              <DatePicker
                  selected={this.state.startDate_in}
                  onChange={this.scheduleDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  className="cal_margin tag_cal ml-2"
                  timeCaption="time"
                  dateFormat="dd-MMM-yyyy HH:mm"
                  maxDate={this.state.startDate}
                />
                  {/* <Datetime name="expirydate" id="expiryDate"   value={this.state.fields["expiryDate"]}  dateFormat="MM/DD/YYYY" timeFormat="HH:mm:ss" onChange={this.setStartDate1.bind(this, "expiryDate")} /> */}
              </div>
              <span className="error_msg w3-animate-top cal_errormsg"> <span style={{color: "red"}} >{this.state.errors["emptyscheduleDate"]}</span></span>      	
              <span className="error_msg w3-animate-top cal_errormsg"> <span style={{color: "red"}} >{this.state.errors["sheduleGreater"]}</span></span>      	

               </MDBCol>
            <MDBCol md="4" className="">
          
            <span className="cal_label">{t('expiry_date_time')}</span>
            <div className="pull-left mt-2" style={{width:"55%"}} >
              <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  className="cal_margin tag_cal"
                  timeCaption="time"
                  dateFormat="dd-MMM-yyyy HH:mm"
                  minDate={this.state.startDate_in}
                />
                  {/* <Datetime name="expirydate" id="expiryDate"   value={this.state.fields["expiryDate"]}  dateFormat="MM/DD/YYYY" timeFormat="HH:mm:ss" onChange={this.setStartDate1.bind(this, "expiryDate")} /> */}
              </div>
              <span className="error_msg w3-animate-top cal_errormsg"> <span style={{color: "red"}} >{this.state.errors["emptyexpiryDate"]}</span></span>      	

                   </MDBCol>
                 
          </MDBRow>
          <button type = "button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
          {/* <MDBBtn color="primary" onClick={this.handleSubmit} className="mt-5">Perform</MDBBtn> */}
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
        </div>


        {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
    </main>
    </>
  );
}
}

export default withTranslation()(reserveNow);