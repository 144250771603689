import React from 'react';
import { createBrowserHistory } from 'history'
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Switch , Route } from 'react-router-dom';
// import {browserHistory} from 'react-router';
import { Link } from "react-router";
import  $ from "jquery";
import * as baseUrl from './constants';
import { hasRole, hasPermission } from './auth';
import * as jwtJsDecode from 'jwt-js-decode';

const history = createBrowserHistory();
const location = history.location;
class Header extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          active : this.props.activetab,
          userName:"",
          title: this.props.page_title,
          modelswitch:false,
          orgArray:[],
          orgName:"",
          choosenTenant:"",
          modalsession:false,
          disableMenu:"disabled-link",
          switch_org_name:"",
          disable_switchacc:"disabled-link",
          logoff_Account:"none",
          UserTenant:"",
          user:{
              permissions: [
                 
              ],
          }
      };
      this.logOut = this.logOut.bind(this);
      this.showSidebar = this.showSidebar.bind(this);
      this.switchAccount = this.switchAccount.bind(this);
      this.SwitchAcctoggle = this.SwitchAcctoggle.bind(this);
      this.switchAccountOrgChange = this.switchAccountOrgChange.bind(this);
      this.togglesession = this.togglesession.bind(this);
      this.sessiontagOk = this.sessiontagOk.bind(this);
      this.LogOffAccount = this.LogOffAccount.bind(this);
      this.SwitchOkAcctoggle = this.SwitchOkAcctoggle.bind(this);
  }

  SwitchOkAcctoggle(){

     if(this.state.identifier!=""){

   this.state.logoff_Account = "block";

    // logoff_account
    var url = baseUrl.URLPath + "switchaccount/";

     fetch(url, {
      method: "GET",
      headers: {
                  'Content-Type': 'application/json',
                  "Authorization":localStorage.getItem("Authorization"),
                  "stenantid":this.state.identifier,
                }
      })
      .then((resp) => {
        if(resp.status==401){
          this.togglesession();
          localStorage.clear();
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
      
        
        if(response!=undefined){
          if(response.status=="SUCCESS"){
            localStorage.setItem("Authorization", "Bearer "+response.token);
            localStorage.setItem("tenant", this.state.switch_org_name);
            localStorage.setItem("choosen_tenant", this.state.identifier);
            window.location.reload();
          }
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })

      this.setState({
        modelswitch: !this.state.modelswitch
      });
    }
  }
  
  switchAccountOrgChange(event){
    this.state.identifier = event.target.value;
    var index = event.nativeEvent.target.selectedIndex;
    var switch_org_name = event.nativeEvent.target[index].text
    this.state.switch_org_name =  this.state.identifier;
    localStorage.setItem("switchOrgName",  this.state.identifier);
  }

  LogOffAccount(event){
    this.state.identifier = event.target.value;
    var url = baseUrl.URLPath + "loggedoff/";
     fetch(url, {
      method: "GET",
      headers: {
                  'Content-Type':'application/json',
                  "Authorization": localStorage.getItem("Authorization"),
                }
      })
      .then((resp) => {
        if(resp.status==401){
          this.togglesession();
          localStorage.clear();
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        
        if(response!=undefined){
          if(response.status=="SUCCESS"){
            localStorage.setItem("Authorization", "Bearer "+response.token);
            localStorage.setItem("choosen_tenant","none");
            localStorage.setItem("tenant",localStorage.getItem("UserTenant"));
            localStorage.setItem("switchOrgName","");
            history.push('/dashboard')
            window.location.reload();
          }
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })

  }


  async togglesession(){
    ;
    this.setState({
    modalsession: !this.state.modalsession
    });
  }

  sessiontagOk(){
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  SwitchAcctoggle(){
    this.setState({
      modelswitch: !this.state.modelswitch
    });
    if(this.state.identifier!=""){
      this.state.identifier = localStorage.getItem("tenant");
      localStorage.setItem("switchOrgName", localStorage.getItem("tenant"));
    }
   
  }
  
  switchAccount(){
    this.SwitchAcctoggle()
  }
  
  logOut(){
    
    localStorage.clear();
    history.push('/');
    window.location.reload();
  }

componentWillMount(){
  
  // this.state.choosenTenant = localStorage.getItem("choosen_tenant");
  // if(this.state.choosenTenant=="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
  //     this.setState({
  //         disableMenu:"disabled-link"
  //     })
  // }
  // this.state.user.permissions=localStorage.getItem("roleAccess");

   var user= localStorage.getItem('user');
   this.setState({userName:user});

}

showSidebar(){
    var js = document.getElementsByClassName('sidebar-main-div');
    if($(js).hasClass("scroll_nav")){
      $(js).removeClass("scroll_nav");
      $('nav').removeClass("scroll_nav");
      $('#navbar').removeClass("nav_p");
    }else{
      $(js).addClass("scroll_nav");
      $('#navbar').addClass("nav_p");
    }
}   

componentDidMount(){
  this.state.user.permissions = localStorage.getItem("roleAccess");
    if(hasPermission(this.state.user, ['SwitchAccount'])){
    this.state.disable_switchacc = "";
    this.setState({
        UserTenant:localStorage.getItem("UserTenant")
    })
}

    // 
    this.state.choosenTenant = localStorage.getItem("choosen_tenant");
    if(this.state.choosenTenant=="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
        this.setState({
            disableMenu:""
        })
    }

  var org_name = localStorage.getItem("tenant");
  this.setState({
    orgName:org_name
  })
  var url = baseUrl.URLPath + "admin/v1.0/account/all";
  fetch(url,{
    method: "GET",
    headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
    })
    .then((resp) => {
      if(resp.status==401){
        // alert("Access token is expired, please login again")
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==200){
        return resp.json();
      } 
    }) 
    .then((response) =>{
      if(response!=undefined){
         this.setState({ orgArray:response.organisationDTOS })
      }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}

    render(){
      
         let switch_org = localStorage.getItem("switchOrgName");
         let org_full_name = "";
         const organisation =this.state.orgArray.map((orgArray, index) => {
            var setOrg = false;
            var HideOrg = "";
            if(switch_org!="" && switch_org!= null && (switch_org.toLowerCase() == orgArray.identifier.toLowerCase())){
                switch_org = orgArray.company;
                switch_org = switch_org.split(" ");
                switch_org = switch_org[0];
                this.state.logoff_Account = "block";
            }
            if(localStorage.getItem("tenant").toLowerCase() == orgArray.company.toLowerCase()){
               setOrg = true
                this.state.identifier = orgArray.identifier;
                org_full_name = orgArray.company
            }
            if(this.state.UserTenant == orgArray.company.toLowerCase()){
              HideOrg = "none";
            }
              return(
                <option value={orgArray.identifier} selected={orgArray.identifier==this.state.UserTenant ? false : setOrg} style={{"display":HideOrg}} > {orgArray.company} </option>
              )
        })
       return (
           <>
           {/* <!-- Navbar --> */}
           <nav className="navbar fixed-top navbar-expand-lg scrolling-navbar double-nav " id="navbar" style={{display:this.props.isShow}}>
             {/* <!-- SideNav slide-out button --> */}
             {/* <div className="float-left" >
               <a href="#"  data-activates="slide-out" className="button-collapse" ><i className="fas fa-bars"></i></a>
             </div> */}
             {/* <!-- Breadcrumb --> */}
             
             <div className="d-flex change-mode">
             <div className="breadcrumb-dn mr-auto" >
              <p className="new_title"> {this.state.title} </p>
                {/* <p><i className="fas" style={{color:"rgb(78, 74, 74)"}}></i></p> */}
              </div>
               {/* <div className="ml-auto mr-3 change-mode-wrapper">
                 <button className="btn btn-outline-black btn-sm" id="dark-mode" >Change Mode</button>
               </div> */}
       
               {/* <!-- Navbar links --> */}
               <ul className="nav navbar-nav nav-flex-icons ml-auto pull-right" >
                 {/* <!-- Dropdown --> */}
                 {/* <li className="nav-item dropdown notifications-nav">
                   <a className="nav-link dropdown-toggle waves-effect" id="navbarDropdownMenuLink" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false">
                     <span className="badge red">3</span> <i className="fas fa-bell"></i>
                     <span className="d-none d-md-inline-block">Notifications</span>
                   </a>
                   <div className="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
                     <a className="dropdown-item" href="#">
                       <i className="far fa-money-bill-alt mr-2" aria-hidden="true"></i>
                       <span>New order received</span>
                       <span className="float-right"><i className="far fa-clock" aria-hidden="true"></i> 13 min</span>
                     </a>
                     <a className="dropdown-item" href="#">
                       <i className="far fa-money-bill-alt mr-2" aria-hidden="true"></i>
                       <span>New order received</span>
                       <span className="float-right"><i className="far fa-clock" aria-hidden="true"></i> 33 min</span>
                     </a>
                     <a className="dropdown-item" href="#">
                       <i className="fas fa-chart-line mr-2" aria-hidden="true"></i>
                       <span>Your campaign is about to end</span>
                       <span className="float-right"><i className="far fa-clock" aria-hidden="true"></i> 53 min</span>
                     </a>
                   </div>
                 </li> */}
                 <li className="nav-item navorg_label pr-3">
                    Organization : { org_full_name }
                 </li>
                 {/* <li className="nav-item">
                   <a className="nav-link waves-effect"><i className="far fa-comments"></i> <span
                       className="clearfix d-none d-sm-inline-block">Support</span></a>
                 </li> */}
                 <li className="nav-item dropdown">
                   <a className="nav-link dropdown-toggle waves-effect" href="#" id="userDropdown" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false">
                     <i className="fas fa-user"></i> <span className="clearfix d-none d-sm-inline-block">{this.state.userName}</span>
                   </a>
                   <div className="dropdown-menu dropdown-menu-right drop_menu  pt-1 pb-1" aria-labelledby="userDropdown">
                     {/* <a className="dropdown-item" href="/" >Log Out</a> */}
                     {/* <label className="dropdown-item pt-1 pb-1" onClick={this.logOut} >
                        <span className="btn_cursor"> Logout </span>   
                      </label> */}
                     {hasPermission(this.state.user, ['SwitchAccount']) && <>
                     <a className={ "dropdown-item cursor-p " + this.state.disable_switchacc }   onClick={this.switchAccount} >
                        {/* <i className="fa fa-exchange-alt mr-2" aria-hidden="true" ></i> */}
                        <span className="fw-500" > Switch Account </span>
                     </a>
                     </>
    }
    {hasPermission(this.state.user, ['SwitchAccount']) && <>
                     <a className= {"dropdown-item cursor-p"} style={{"display":this.state.logoff_Account}} id="logoff_account"  onClick={this.LogOffAccount} >
                       {/* <i className="fa fa-exchange-alt mr-2" aria-hidden="true" ></i> */}
                       <span className="fw-500"> Log Off <b>{switch_org}</b></span>
                     </a>
                     </>
    }
                     <a className="dropdown-item cursor-p" href="/" onClick={this.logOut}>
                       {/* <i className="fa fa-sign-out-alt mr-2" aria-hidden="true"></i> */}
                       <span className="fw-500"> Sign Out </span>
                     </a>
                     {/* <a className="dropdown-item" href="#">My account</a> */}
                   </div>
                 </li>
       
               </ul>
           </div>
           </nav>
           <MDBModal isOpen={this.state.modelswitch} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
              <MDBModalHeader toggle={this.SwitchAcctoggle}>Switch Account</MDBModalHeader>
              <MDBModalBody>
                <div class="row">
                    <label className="switch_acc" > Select Organization </label>
                    <div class="col-md-7">
                      <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.switchAccountOrgChange.bind(this)}>
                        <option value=""> Select Organization </option>
                        {organisation}
                      </select>
                    </div>
                </div>
              </MDBModalBody>
              <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.SwitchAcctoggle}>Cancel</MDBBtn>
                <MDBBtn color="primary" onClick={this.SwitchOkAcctoggle}>OK</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            {/* popup for session expire */}
            <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
          </>
        )
    }
}

export default Header;