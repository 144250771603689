import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Header from './header';

class remoteStopTransaction extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          fields:{transactionId:''},
          sucess:false,
          colour:'sucess',
          page_title:"Remote Stop Transaction",
          errors:{},
          cpList:[],
          chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
}
componentDidMount(){
  let propsdata = this.props.location.state;
    if(propsdata!=undefined){
      if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    } 
}
onShowAlert(){
  
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
async handleSubmit(event) {
  
  var fds=this.getPayLoad();
  var fds= this.getCPList();
  var payload=this.getPayLoad();

  var errors={};
 
  var transactionId=payload.transactionId;
//  alert(this.getCPList())
if(this.getCPList()==0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
}
if(isNaN(transactionId)){
  errors["emptytransactionId"]=this.props.t('enter_transaction_id')
}
if(this.getCPList()!=0 && !isNaN(transactionId)){
  var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
  // alert(response);
  if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else {
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }
}
this.setState({errors:errors})
}

getCPList() {
  var Id = this.state.operationId;
    if(Id != null && Id!=""){
     var cplist = Id.split();
   
    }
    else{
     var cplist = this.state.cpList;
   
   }
   return cplist;
}

getPayLoad() {
  var payload ={
    "transactionId": parseInt(this.state.transactionId)
  };
   
  return payload;

}

getMessageType() {
  return constants.REMOTE_STOP_TRANSACTION;
}
transactionChange(){
  this.setState({transactionId:document.getElementById("transactionId").value});
}

getSelectedCP(cpList){
 
  this.setState({cpList:cpList});
  if(cpList!=""){
  
    this.state.errors["emptyChargePoint"]=" "
   
  }
 
}
numberHandler(field, e){ 
  let fields = this.state;
  if(e.target.validity.valid){
    fields[field] = e.target.value; 
    this.setState({fields});
    
  
  }else if(e.target.value==""){
    fields[field] = e.target.value; 
    this.setState({fields});
    
   
  }
  if(fields[field]!=0){

    this.state.errors["emptytransactionId"]=" "
  }
  }
  componentDidMount(){
    let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
  }
  componentWillUnmount(){
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }
render() {
  const{t}=this.props
  return (
    <>
    

    <main class="content-div" >
    <p>  {t('Remote Stop Transaction')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Remote Stop Transaction')} </span>
              </div>
           </p>
          {/* <p className="breadcrumb_div"><a>Operations ></a> <a> Remote Stop Transaction</a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations ></a> <a> Remote Stop Transaction</a></div> */}

          {/* <p>Remote Stop Transaction</p> */}
      
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
        <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>

          	
          {/* <MDBCol md="4">
            <select className="browser-default custom-select select_height">
                <option>Select One:	</option>
                <option value="1"></option>
                
            </select>
            </MDBCol> */}
          <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP}operationName = "RemoteStopTransaction" ></ChargePointSelection>
          <MDBCol md="12">
          <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>
          </MDBCol>
          <MDBCol md="4">
            
                     <MDBInput
              value={this.state.fields["transactionId"]}
              name="ID of the Active Transaction"
                onInput={this.numberHandler.bind(this,"transactionId")}
                type="text"  pattern="[0-9]*"
                id="transactionId"
                label={t('id_of_active_transaction')}
                required
              >
<span className="error_msg w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptytransactionId"]}</span></span>

                 

              </MDBInput>
            </MDBCol>
          </MDBRow>
         
          <button className="mt-5 refresh-btn" type="button" onClick={this.handleSubmit} >{t('perform')}</button>
          
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
        
        </div>
 
    </main>
    </>
  );
}
}

export default withTranslation()(remoteStopTransaction);