import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './constants';
import {Form,Table,Pagination,Check } from 'react-bootstrap';
import  $ from "jquery";
import Datetime from 'react-datetime';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createBrowserHistory } from 'history';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Header from './header';
import { Alert } from 'reactstrap';
import { hasRole, hasPermission } from './auth';
import servicesUrl from '../common/servicesUrl';

import { withTranslation } from 'react-i18next';
const history = createBrowserHistory();

class viewChargingSchedule extends React.Component{  
    constructor (props) {
        super(props);
        this.state = {
          hideActionBtn:"none", // show hide btn varible based on permission 
          writePermission:this.props.ChargePoint_W, // ChargePoint_W conndition
          scheduleId:"",



          modalConfirmation:false, 
          message:"",
          colour:'sucess',
          sucess:false,


          modalConfirmation:false,  



          dataArray:[
            {
              "duration":"",
              "chargingScheduleName": "",
              "startSchedule":"",
              "schedulingUnit":"",
              "minChargingRate":"",
              "chargingSchedulePeriod": []
            }
        ],
        user:{
          permissions: [
               
              ]
          },
            page_title:"View Charging Schedule"
        }
    
         this.backToList = this.backToList.bind(this)
         this.toggleConfirmation = this.toggleConfirmation.bind(this)
         this.handleDelete = this.handleDelete.bind(this)
         this.toggleDelete = this.toggleDelete.bind(this)
         this.tagOk = this.tagOk.bind(this)
         this.togglesession = this.togglesession.bind(this)
         this.sessiontagOk = this.sessiontagOk.bind(this)
         this.onShowAlert=this.onShowAlert.bind(this);
         
}

onShowAlert(){
  
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}



 secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600*24));
  var i_d = d < 10 ? "0" : ""
  d = i_d+d;
  var h = Math.floor(seconds % (3600*24) / 3600);
  var h_d = h < 10 ? "0" : ""
  h = h_d+h;
  var m = Math.floor(seconds % 3600 / 60);
  var m_d = m < 10 ? "0" : ""
  m = m_d + m;
  var s = Math.floor(seconds % 60);
  var s_d = s < 10 ? "0" : ""
  s = s_d + s;
  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return d+":"+h+":"+m+":"+s;
  }


toggleDelete = () => {
  this.setState({
    modalDelete: !this.state.modalDelete
  });
}

tagOk = () => {
  this.props.history.push('/chargingSchedule');
}

toggleConfirmation = () => {
  this.setState({
    modalConfirmation: !this.state.modalConfirmation
  });
}

handleDelete(event){
  this.setState({
    modalConfirmation: !this.state.modalConfirmation
  });
 
  event.preventDefault();
  this.deleteChargingSchedule();
}


toggleDeleteClose(){
  this.setState({
    modalDelete: !this.state.modalDelete
  });
}

async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}

sessiontagOk(){
  history.push('/');
  window.location.reload();
}


 backToList(){
  this.props.history.push('/chargingSchedule');
}
componentDidMount(){
  this.state.user.permissions = localStorage.getItem("roleAccess");
  
  if(this.props.location.state==null){
    this.state.scheduleId = this.state.scheduleId
  }else{
    this.state.scheduleId = this.props.location.state;
  }
  this.getChargingSchedule()
}

async getChargingSchedule(){
  var timeZone=  localStorage.getItem("timezone");
  ;
  var url = baseUrl.URLPath + servicesUrl.getchargingschedulesByscheduleid+ "?scheduleid="+this.state.scheduleId;
  await fetch(url, {
      method: "GET",
          headers: {
            'Content-Type': 'application/json',
            "Authorization":localStorage.getItem("Authorization")
          }
      })
    .then((resp) => {
      ;
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==500){
        alert("internal server error")
      }
      if(resp.status==200){
        return resp.json();
      } 
    }) 
    .then((response) =>{
      
      if(response!=undefined){
        this.setState({
           dataArray:response,
       })
      }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}
async deleteChargingSchedule(){
  var timeZone=  localStorage.getItem("timezone");
  ;
  var url = baseUrl.URLPath + servicesUrl.getchargingschedules+ "?scheduleid="+this.state.scheduleId;
    await fetch(url, {
      method: "DELETE",
          headers: {
            'Content-Type': 'application/json',
            "Authorization":localStorage.getItem("Authorization")
          }
      })
    .then((resp) => {
      ;
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==500){
        alert("internal server error")
      }
      if(resp.status==204){
        this.toggleDelete() 
      } 
      
      return resp.json();
    }) 
    .then((response) =>{
      if(response.status==400){
        this.setState({ message:this.props.t(response.message), colour: 'danger' })
        this.onShowAlert();
      }
      
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}

render() {
  const{t}=this.props
  let duration_secounds = this.secondsToDhms(this.state.dataArray["duration"]);
  
  let user = this.state.user;
  let SchedulePeriodRows = "";
  if( this.state.dataArray.schedulingUnit != undefined ){
      var SchedulePeriod = this.state.dataArray.chargingSchedulePeriod;
      SchedulePeriodRows = SchedulePeriod.map((childRows, index) => {
      return(
              <tr key={childRows.index} style={{backgroundColor:"#fff"}} >
                  <td className="">{childRows.startPeriod +" ("+this.secondsToDhms(childRows.startPeriod)+") "}</td>
                  <td className="">{childRows.limit}</td>
                  <td className="">{childRows.numberPhases}</td>
              </tr>
            )
      })
  
  }
  
return(
  
  <>
   <main class="content-div" >
   <p>{t('Charging Schedule')}
              <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/chargingSchedule">{t('Charging Schedule')}</Link > &gt; <span className="breadcrumb_page">{t('view')}</span>
              </div>
          </p>
      <div className="page-outerdiv">
        {/* breadCrumb */}
        {/* <div className="breadcrumb_div"><a> Charge Point > <Link to="/chargingSchedule" > <span className="">Charging Schedule</span></Link> > </a>View</div> */}
        {/* end breadCrumb */}
        <div className="row">
          <div className="col-md-12">
            <div className="pull-left">
            {hasPermission(user,['ChargingSchedule_R']) && 
                <button className="btn_primary" onClick={this.backToList} type="button" data-test="button"><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
                }
            </div>
          </div>
        </div>
        <form
          className='needs-validation'
          onSubmit={this.toggle1}
          noValidate
        >

        <MDBRow className="mb-4 mt-3 " >
        <MDBCol sm="12">

        <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2 b-1" >{this.state.message}</Alert>

          <div class=" " style={{width:"100%"}}>
            <div className="row">
              <div className="col-sm-6">
                <div className="content-innerdiv">
                  <div className="" >
                    <label>
                      {t('schedule_name')}:
                    </label>
                    <label >
                      {this.state.dataArray["chargingScheduleName"]}
                    </label>
                  </div>
                  <div className=" ">
                 <label>
                    {t('start_schedule')}:
                 </label>
                 <label>
                   {this.state.dataArray["startSchedule"] ? moment(this.state.dataArray["startSchedule"]).format("DD-MMM-YYYY HH:mm").toUpperCase() : null}
                 </label>
               </div>
               <div className="">
                 <label>
                 {t('min_charging_rate')}:
                 </label>
                 <label>
                 {this.state.dataArray["minChargingRate"]}
                 </label>
               </div>

                </div>
              </div>
              <div className="col-sm-6">
                <div className="content-innerdiv">
                <div className="">
                 <label>
                 {t('chargingDuration')}:
                 </label>
                 <label>
                   {
                     (this.state.dataArray["duration"]!="" && this.state.dataArray["duration"]!=null)? this.state.dataArray["duration"]+ " (" + duration_secounds + ") " :""
                   }
                 </label>               
               </div>
               <div className="">
                 <label>
                  {t('charging_rate_unit')}:
                 </label>
                 <label>
                 {this.state.dataArray["schedulingUnit"]}
                 </label>
               </div>
                </div>
              </div>
            </div>
              </div>
              </MDBCol>
           <div className="col-md-12">
           <div className="mb-3">
                    <span className="form-title charge_pl mt-3" >{t('charging_schedule_period')}: </span> 
                 </div>
          <Table striped hover size="sm" className="page-table">
                <thead>
                  <tr>
                    <th className="">{t('start_period')}</th>
                    <th className="">{t('limit')}</th>
                    <th className="">{t('number_phases')}</th>
                  </tr>
                </thead>
                 <tbody>
                    {SchedulePeriodRows}
                 </tbody>
              </Table>
                </div>
               
              <MDBCol md="12" className="text-right">
                  {hasPermission(user,['ChargingSchedule_D']) && 
                  <button className="btn_secondary mt-5 mr-3" onClick={this.toggleConfirmation}  id="deleteBtn" type="button" data-test="button">{t('delete')} <i class="fas fa-trash-alt ml-2"></i></button>
                  }
                  {hasPermission(user,['ChargingSchedule_U']) && 
                  <Link to={{
                    pathname:'/editChargingSchedule', 
                    state: this.state.scheduleId, 
                    }}>
                      <button className="btn_primary mt-5 mr-3" onClick={this.editTag}  id="editBtn" type="button" data-test="button">{t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                </Link>
                }
            </MDBCol>  
        </MDBRow>
        </form>
      </div>
      <MDBModal isOpen={this.state.modalDelete}  size="md" className="model_top">
        <MDBModalHeader toggle={this.toggleDelete}>{t('success')}</MDBModalHeader>
        <MDBModalBody>
            {t('charging_schedule_deleted')}
        </MDBModalBody>
        <MDBModalFooter>
        <button type="button" data-test="button" onClick={this.tagOk} className="btn_primary">{t('ok')}</button>
        </MDBModalFooter>
      </MDBModal>

      <MDBModal isOpen={this.state.modalConfirmation} toggle={this.toggleConfirmation} size="md" className="model_top">
        <MDBModalHeader toggle={this.toggleConfirmation}> {t('confirmation')} </MDBModalHeader>
          <MDBModalBody>
           {t('you_want_to_delete')}
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" data-test="button" onClick={this.toggleConfirmation} className="btn_primary">{t('close')}</button>
          <button type="button" data-test="button" onClick={this.handleDelete} className="btn_primary">{t('yes')}</button>
          </MDBModalFooter>
      </MDBModal>

      <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
        </MDBModal>

    </main>
    </>
  );
}
}

export default withTranslation()(viewChargingSchedule);

