import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import './cpDashboard.css';

export default class Transactionstable extends React.Component {
    render() {
        let actionDisplay = false;

        // Print the table heading 
        let title = this.props.tableField.map((tableTilte, index) => {
            if (tableTilte.isSelected) {
                var icon = "";
                if (tableTilte.key == "id" || tableTilte.key == "start_time" || tableTilte.key == "stop_time") {
                    icon = <i class="fas fa-sort sortIcon" onClick={this.props.sorting}></i>
                }

                if (tableTilte.key == "actions") {
                    actionDisplay = true;
                }

                if (tableTilte.key == "referenceNumber" || tableTilte.key == "customerNumber") {
                    if (this.props.editAction == true) {
                        return (
                            <th key={tableTilte.key} >
                                {this.props.t(tableTilte.key)}
                                {icon}
                            </th>
                        )
                    }
                } else {
                    return (
                        <th key={tableTilte.key} >
                            {this.props.t(tableTilte.key)}
                            {icon}
                        </th>
                    )
                }
            }
        });

        // Print the table body
        const data = this.props.item.map((dataArray, index) => {
            var startTime, startTime1, stopTime, stopTime1;

            if (dataArray.startTime == null || dataArray.startTime == "") {
                startTime = "";
                startTime1 = "";
            } else {
                startTime = moment(dataArray.startTime).format("DD MMM YYYY");
                startTime1 = moment(dataArray.startTime).format("HH:mm");
            }

            if (dataArray.stopTime == null || dataArray.stopTime == "") {
                stopTime = "";
                stopTime1 = "";
            } else {
                stopTime = moment(dataArray.stopTime).format("DD MMM YYYY");
                stopTime1 = moment(dataArray.stopTime).format("HH:mm");
            }

            var status_img = "";
            var status_text = "";

            if (dataArray.chargingSessionState == "activeCharging") {
                status_img = "activeCharging";
                status_text = "Active Charging";
            } else if (dataArray.chargingSessionState == "Finished") {
                status_img = "Finished";
                status_text = "Finished";
            } else if (dataArray.chargingSessionState == "activeNotCharging") {
                status_img = "activeNotCharging";
                status_text = "Active Not Charging";
            } else if (dataArray.chargingSessionState == "prepairing") {
                status_img = "prepairing";
                status_text = "Prepairing";
            } else if (dataArray.chargingSessionState == "activePreparing") {
                status_img = "prepairing";
                status_text = "Active Preparing";
            } else if (dataArray.chargingSessionState == "SuspendedEV") {
                status_img = "activeNotCharging";
                status_text = "Active Not Charging";
            } else if (dataArray.chargingSessionState == "Halted") {
                status_img = "Halted";
                status_text = "Halted";
            }

            var trsactionData = { dataArray };
            var chargedEnergy = '';

            if (dataArray.chargedEnergy == null || dataArray.chargedEnergy == "") {
                chargedEnergy = 0;
            } else {
                chargedEnergy = dataArray.chargedEnergy;
            }

            if (dataArray.transactionId == dataArray.vendorTransactionId) {
                return (
                    <tr>
                        {
                            this.props.tableField.map((tableTilte, index) => {
                                if (tableTilte.isSelected == true) {
                                    return Object.entries(dataArray).map(([key, value]) => {
                                        if (tableTilte.key == key) {
                                            if (key == "transactionId") {
                                                return (
                                                    <td>
                                                        <Link to={{
                                                            pathname: '/meterValues',
                                                            state: trsactionData,
                                                        }}>{value}</Link>
                                                    </td>
                                                )
                                            } else if (key == "startTime") {
                                                return (
                                                    <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </td>
                                                )
                                            } else if (key == "stopTime") {
                                                return (
                                                    <td className="wrap-word">{stopTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {stopTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {stopTime1} </div> </>}  </td>
                                                )
                                            } else if (key == "chargedEnergy") {
                                                return (
                                                    <td className="wrap-word"><div className="nowrap pull-left pb-0 w-100">{currencyFormatter.format(chargedEnergy, { code: dataArray?.currencyCode, symbol: '' })}<b> kWh</b></div><div className="timespan pull-left pb-0">{(chargedEnergy * 1000)?.toFixed()} wh</div></td>
                                                )
                                            } else if (key == "chargingSessionState") {
                                                return (
                                                    <td className="wrap-word  p-rel ">
                                                        <div class="click-to-top">
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" />
                                                            <span>{status_text}</span>
                                                        </div>
                                                    </td>
                                                )
                                            } else if (key == "customerNumber" || key == "referenceNumber") {
                                                if (this.props.editAction == true) {
                                                    return (
                                                        <td>{value}</td>
                                                    )
                                                }
                                            } else if (key == "chargePointId") {
                                                return (
                                                    <td>{value} {dataArray.connectorId ? '(' + dataArray.connectorId + ')' : ''}</td>
                                                )
                                            } else if (key == "chargingSessionCost" || key == "costsPerKwh" || key == "costsPerChargingSession") {
                                                return (
                                                    <td>{currencyFormatter.format(value, { code: dataArray?.currencyCode, symbol: '' })}</td>
                                                )
                                            } else {
                                                return (
                                                    <td>{value}</td>
                                                );
                                            }
                                        }
                                    })
                                }
                            })
                        }
                        {actionDisplay &&
                            <td>
                                {
                                    (this.props.editAction == true) ? <>
                                        {(dataArray.chargingSessionState == "prepairing" || dataArray.chargingSessionState == "activePreparing" || dataArray.chargingSessionState == "activeCharging") ?
                                            <i class="fas fa-pencil-alt pr-2 action-icon" title="Remote Start Transaction" onClick={this.props.toggleRemoteStart.bind(this, dataArray.chargePointId, dataArray.transactionId, dataArray.energyLevel, dataArray.idTag, dataArray.customerNumber, dataArray.referenceNumber, dataArray.chargingSessionState, dataArray.chargedEnergy)} ></i>
                                            : ""}
                                    </> : <></>
                                }
                                {
                                    (this.props.carAction == true) ? <i class="fas fa-car-side" title="Add mileage and inventory number" onClick={this.props.displayCarIcon.bind(this, dataArray.transactionId, dataArray.mileage, dataArray.vehicleInventoryNumber)} ></i> : <></>
                                }
                            </td>
                        }
                        <td></td>
                    </tr>
                )
            } else {
                return (
                    <tr>
                        {
                            this.props.tableField.map((tableTilte, index) => {
                                if (tableTilte.isSelected == true) {
                                    return Object.entries(dataArray).map(([key, value]) => {
                                        if (tableTilte.key == key) {
                                            if (key == "transactionId") {
                                                return (
                                                    <td>
                                                        <Link to={{
                                                            pathname: '/meterValues',
                                                            state: trsactionData,
                                                        }}>{value}</Link>{dataArray.vendorTransactionId ? '(' + dataArray.vendorTransactionId + ')' : ''}
                                                    </td>
                                                )
                                            } else if (key == "startTime") {
                                                return (
                                                    <td className="wrap-word">{startTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {startTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {startTime1} </div> </>} </td>
                                                )
                                            } else if (key == "stopTime") {
                                                return (
                                                    <td className="wrap-word">{stopTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {stopTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {stopTime1} </div> </>}  </td>
                                                )
                                            } else if (key == "chargedEnergy") {
                                                return (
                                                    <td className="wrap-word"><div className="nowrap pull-left pb-0 w-100">{currencyFormatter.format(chargedEnergy, { code: dataArray?.currencyCode, symbol: '' })}<b> kWh</b></div><div className="timespan pull-left pb-0">{(dataArray.chargedEnergy * 1000)?.toFixed()} wh</div></td>
                                                )
                                            } else if (key == "chargingSessionState") {
                                                return (
                                                    <td className="wrap-word  p-rel ">
                                                        <div class="click-to-top">
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/cs-status/" + status_img + ".png"} style={{ "height": "16px" }} alt="" />
                                                            <span>{status_text}</span>
                                                        </div>
                                                    </td>
                                                )
                                            } else if (key == "customerNumber" || key == "referenceNumber") {
                                                if (this.props.editAction == true) {
                                                    return (
                                                        <td>{value}</td>
                                                    )
                                                }
                                            } else if (key == "chargePointId") {
                                                return (
                                                    <td>{value} {dataArray.connectorId ? '(' + dataArray.connectorId + ')' : ''}</td>
                                                )
                                            } else if (key == "chargingSessionCost" || key == "costsPerKwh" || key == "costsPerChargingSession") {
                                                return (
                                                    <td>{currencyFormatter.format(value, { code: dataArray?.currencyCode, symbol: '' })}</td>
                                                )
                                            } else {
                                                return (
                                                    <td>{value}</td>
                                                );
                                            }
                                        }
                                    })
                                }
                            })
                        }
                        {actionDisplay &&
                            <td>
                                {
                                    (this.props.editAction == true) ? <>
                                        {(dataArray.chargingSessionState == "prepairing" || dataArray.chargingSessionState == "activePreparing" || dataArray.chargingSessionState == "activeCharging") ?
                                            <i class="fas fa-pencil-alt pr-2 action-icon" title="Remote Start Transaction" onClick={this.props.toggleRemoteStart.bind(this, dataArray.chargePointId, dataArray.transactionId, dataArray.energyLevel, dataArray.idTag, dataArray.customerNumber, dataArray.referenceNumber, dataArray.chargingSessionState, dataArray.chargedEnergy)} ></i>
                                            : ""}
                                    </> : <></>
                                }
                                {
                                    (this.props.carAction == true) ? <i class="fas fa-car-side" title="Add mileage and inventory number" onClick={this.props.displayCarIcon.bind(this, dataArray.transactionId, dataArray.mileage, dataArray.vehicleInventoryNumber)} ></i> : <></>
                                }
                            </td>
                        }
                        <td></td>
                    </tr>
                )
            }
        });

        return (
            <>
                <Table striped hover size="sm" className="page-table responsive">
                    <thead>
                        <tr>
                            {title}
                            <th className="p-rel">
                                <div className="gear_iconDiv">
                                    <i className="fas fa-cog gear_icon" onClick={this.props.settingAction}></i>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data}
                    </tbody>
                </Table>
            </>
        )
    }
}