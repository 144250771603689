import React from 'react';
import { Link } from 'react-router-dom';
import { MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from './constants';
import { Alert } from 'reactstrap';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const history = createBrowserHistory();

 class addBuild extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: false,
      dataArray: [],
      targetData: [],
      buildTypes: [],
      taregtTypeValue: "",
      buildTypeId: "",
      releaseNotes: '',
      prerequisite: "",
      prerequisites: [],
      version: "",
      fields: {
        version: "",
        prerequisite: "",
        releaseNotes: "",
        targetIds: "",
        model: "",
      },
      sucess: {},
      errors: {},
      activeItem: "1",
      model: false,
      page_title: "Add Build",
      file: "",
      sucess: false,
      colour: '',
      message: 'Multiple charge points not supported for this operation.',
      allOrSelected: false,
      typeofRadio: false
    }
    this.onShowAlert = this.onShowAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.backToList = this.backToList.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.getPrerequisite = this.getPrerequisite.bind(this);
    this.prerequisiteChange = this.prerequisiteChange.bind(this);
    this.addtoggle = this.addtoggle.bind(this);
    this.addtagOk = this.addtagOk.bind(this);
  }

  addtagOk() {
    this.props.history.push('/builds');
  }

  tagOk() {
    history.push('/');
    window.location.reload();
  }

  async toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  addtoggle = () => {
    this.setState({
      addmodal: !this.state.addmodal
    });
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  productChange(e) {
    this.getPrerequisite();
    var x = e.target.value
    if (x != 0) {
      this.state.errors["prodError"] = " "
    }
  }

  prerequisiteChange() {
    var id = document.getElementById("prerequisites").value;
    if (id != 0) {
      this.state.errors["prerequisite"] = " "
    }
    this.setState({ prerequisite: id })
  }

  fileChange(e) {
    var imagedata = document.getElementById("buildFile").files[0];
    var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
    simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);
    simple_file_upload.addEventListener('focus', function () {
      simple_file_upload.classList.add('focus');
    });
    simple_file_upload.addEventListener('blur', function () {
      simple_file_upload.classList.remove('focus');
    });
    this.setState({
      file: imagedata
    })
    if (imagedata != 0) {
      this.state.errors["nofileError"] = " "
    }
  }

  releaseChange() {
    var imagedata = document.getElementById("releaseNotes").files[0];
    var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
    simple_file1.nextElementSibling.setAttribute('data-file', simple_file1.files[0].name);
    simple_file1.addEventListener('focus', function () {
      simple_file1.classList.add('focus');
    });
    simple_file1.addEventListener('blur', function () {
      simple_file1.classList.remove('focus');
    });
    this.setState({
      releaseNotes: imagedata
    })
    if (imagedata != 0) {
      this.state.errors["releaseNoteError"] = " "
    }
  }

  getPrerequisite() {

    var product_id = document.getElementById("productId").value;
    var url = baseUrl.FOTAPath + "dashboard/prerequisite?model=" + product_id;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ prerequisites: [{ version: 'none' }, ...response] })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }

  getProducts(e) {
    var url = baseUrl.FOTAPath + "cpinventory/evmodel/";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    let fields = this.state.fields;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
    if (fields[field] != 0) {
      this.state.errors["versionError"] = " "
    }
  }

  componentDidMount() {
    this.getProducts();

    // document.getElementById("all").checked = true;

    this.setState({ productId: this.props.location.state })
    // var url = baseUrl.FOTAPath + "web/dashboard/targettypes";
    // fetch(url, {
    //   method: "GET",
    //   headers: {
    //     "content-type": "application/json",
    //     "Authorization": localStorage.getItem("Authorization"),
    //   }
    // })
    //   .then((resp) => {

    //     if (resp.status == 401) {
    //       this.toggle();
    //       // alert("Access token is expired, please login again")
    //       localStorage.clear();

    //     }
    //     if (resp.status == 200) {
    //       return resp.json();
    //     }
    //   })
    //   .then((response) => {

    //     if (response != undefined) {

    //       // alert(JSON.stringify(response));
    //       this.setState({ targetData: response })
    //     }
    //     // alert("data"+JSON.stringify(this.state.data));
    //     // this.setState({ total_charge_point:response.data.chargePointCount })

    //   })
    //   .catch((error) => {
    //     console.log(error, "catch the loop")
    //   })

    // var simple_file1 = document.getElementsByClassName('simple_upload1')[0];
    // simple_file1.addEventListener('change', function(){
    //   this.nextElementSibling.setAttribute('data-file', this.files[0].name);
    // });
    // simple_file1.addEventListener('focus', function(){
    //   simple_file1.classList.add('focus');
    // });
    // simple_file1.addEventListener('blur', function(){
    //   simple_file1.classList.remove('focus');
    // });

  }

  backToList() {

    this.props.history.state = this.props.location.state;
    window.history.state.state = this.props.location.state;
    this.props.history.push('/builds')
  }

  handleSubmit() {
    var text = this.state.taregtTypeValue;
    this.state.fields.targetIds = text;
    var productId = document.getElementById("productId").value;

    var errors = {};

    if (this.state.file == "" || this.state.file == undefined) {
      errors["nofileError"] = this.props.t('select_firmware_file')
    }
    if (productId == "") {
      errors["prodError"] = this.props.t('select_the_product')
    }
    if (this.state.fields.version == "") {
      errors["versionError"] = this.props.t('enter_the_version')
    }
    let prerequisiteValue = this.state.prerequisite;// === 'none'? '' : this.state.prerequisite;
    if (!this.state.prerequisite) {
      errors["prerequisite"] = this.props.t('select_prerequsite')
    }
    if (this.state.fields.version <= prerequisiteValue && prerequisiteValue !== 'none') {
      errors["prerequisite"] = this.props.t('prerequsite_not_greater_or_equal_of_version')
    }
    if (this.state.releaseNotes == "" || this.state.releaseNotes == undefined) {
      errors["releaseNoteError"] = this.props.t('select_release_notes')
    }
    if (this.state.file != '' && this.state.file != undefined && productId != "" && this.state.fields.version != "" && this.state.releaseNotes != "" && this.state.releaseNotes != undefined && (this.state.fields.version > prerequisiteValue || prerequisiteValue === 'none') && this.state.prerequisite) {
      var url = baseUrl.FOTAPath + "dashboard/ota/upload";
      this.setState({ errors: errors })
      
      var data = new FormData();
      data.append("file", this.state.file);
      data.append("version_name", this.state.fields.version);
      data.append("target_type_id", 0);
      data.append("release_note", this.state.releaseNotes);
      data.append("prerequisite", prerequisiteValue);
      data.append("target_ids", this.state.fields.targetIds);
      data.append("model", productId);

      document.getElementById("loader_image_div").style.display = "block";
      fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {

          if (resp.status == 401) {
            this.toggle();
            localStorage.clear();
          }
          if (resp.status == 201) {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              this.addtoggle();
            }, 2000);
          }
          if (resp.status == 404) {
          }
          return resp.json();
        })
        .then((response) => {

          if (response.status == 404) {
            if (response.message == this.props.t('prerequsite_not_greater_or_equal_of_version')) {
              errors["prerequisite"] = response.message;
            } else {
              errors["versionError"] = response.message;
            }
            document.getElementById("loader_image_div").style.display = "none";
            this.setState({ errors: errors })
          } else {
            if (response.status == this.props.t('success_status')) {
              setTimeout(() => {
                document.getElementById("loader_image_div").style.display = "none";
                this.addtoggle();
              }, 2000);
            }
          }

        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else {
      this.setState({ errors: errors })

    }
  }

  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    const{t}=this.props
    const products = this.state.dataArray.map((dataArray, index) => {
      return (
        <option value={dataArray}>{dataArray}</option>
      )
    });

    const prerequisites = this.state.prerequisites.map((data, index) => {
      return (
        <option value={data.id}>{data.version}</option>
      )
    });

    return (
      <>
        <main class="content-div" >
          <p> {t('builds')}
            <div className="breadcrumb_div">
              {t('fota')} &gt; <Link to="./builds">{t('builds')}</Link > &gt; <span className="breadcrumb_page">{t('add')}</span>
            </div>
          </p>
          <form
            className='needs-validation'
            noValidate
          >
            <div className="page-outerdiv">
              <div className="row">
                <div className="col-md-12">
                  <div className="pull-left">
                    <button onClick={this.backToList} type="button" data-test="button" className="btn_primary">
                      <i class="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div class="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{ display: "none" }}></div>
                  <span class=" form-title mt-0">{t('details')}:</span>
                </div>
                <MDBCol md="4" >
                  <div className="w_f">
                    <label className="pull-left pt-3">{t('model')}: *</label>
                    <select className="browser-default custom-select select_height w-75 pull-right" id="productId" onChange={this.productChange.bind(this)}>
                      <option value="">{t('select_model')}</option>
                      {products}
                    </select>
                  </div>
                  <span className="error_msg w3-animate-top">
                    <span style={{ color: "red" }}>{this.state.errors["prodError"]}</span>
                  </span>
                </MDBCol>
                <MDBCol md="4">
                  <MDBInput
                    value={this.state.fields["version"]}
                    onChange={this.numberHandler.bind(this, "version")}
                    type="text"
                    label="Version *"
                    autoComplete="off"
                  >
                    <span className="error_msg w3-animate-top">
                      <span style={{ color: "red" }}>{this.state.errors["versionError"]}</span>
                    </span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4" >
                  <div className="w_f">
                    <label className="pull-left pt-3">{t('prerequisite')}: *</label>
                    <select className="browser-default custom-select select_height w-70 pull-right" id="prerequisites" onChange={this.prerequisiteChange.bind(this)}>
                      <option value="">{t('select_prerequisite')}</option>
                      {prerequisites}
                    </select>
                  </div>
                  <span className="error_msg w3-animate-top">
                    <span style={{ color: "red" }}>{this.state.errors["prerequisite"]}</span>
                  </span>
                </MDBCol>
                <MDBCol md="4">
                  <label className="mt-1" >
                    <input type="file" class="simple_file_upload" id="buildFile" onChange={this.fileChange.bind(this)} />
                    <span data-file="No file choosen">{t('choose_firmware_file')} *</span>
                  </label>
                  <span className="error_msg w3-animate-top">
                    <span style={{ color: "red" }}>{this.state.errors["nofileError"]}</span>
                  </span>
                </MDBCol>
                <MDBCol md="4" >
                  <label className="mt-1">
                    <input type="file" class="simple_upload1" id="releaseNotes" onChange={this.releaseChange.bind(this)} />
                    <span data-file="No file choosen">{t('choose_release_notes')} *</span>
                  </label>
                  <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["releaseNoteError"]}</span></span>
                </MDBCol>
              </div>
              <div className="row">
                <MDBCol md="12" className="text-right mt-3">
                  <button type="button" data-test="button" onClick={this.handleSubmit} className="btn_primary mr-2">
                    {t('save')} <i class="fas fa-save ml-2"></i>
                  </button>
                </MDBCol>
              </div>
            </div>
          </form>
        </main>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>

        <MDBModal isOpen={this.state.addmodal} toggle={this.addtoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.addtoggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
           {t('new_build_added_sucessfully')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.addtagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(addBuild);