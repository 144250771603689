import { createBrowserHistory } from 'history'
import { MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact'
import React from 'react'
import { Form, Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead'
import NewSiteSettings from './newSiteSettings';
import NewSiteUsers from './newSiteUsers';
import NewSiteWallboxes from './newSiteWallboxes';
import NewSiteCosts from './newSiteCosts';
import { Doughnut } from "react-chartjs-2";
import ReactSpeedometer from "react-d3-speedometer"
import * as baseUrl from '../constants';
import Pagination from "react-js-pagination";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import $ from "jquery";
import * as roles from '../roles';
import servicesUrl from '../../common/servicesUrl';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();
class SiteManagement extends React.Component {
    typeAHeadRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: -1,
            minRotation: 0,
            maxRotation: 150,
            noOfRecords: 0,
            availableSites: {
                count: {
                    count: 0
                },
                smList: []
            },
            siteSettings: {
                name: '',
                city: '',
                zipcode: '',
                address: ''
            },
            selectedWallboxes: [],
            selectedSite: null,
            isNewSite: false,
            activePage: 1,
            pageSize: 10,
            searchStr: '',
            searchSuggestions: [],
            modalType: 'site-settings',
            modalMsg: '',
            modal: false,
            modalAddSite: false,
            reloadOnOk: true,
            modalShowMaximumSites: false,
            isOnSearchFind: false,
            isSearchNotFound: false,
            canShowSearchMenu: true,
            fields: {
                id: '',
                name: '',
                city: '',
                zipcode: '',
                address: '',
                selectedItems: [],
                availableWallBoxes: [],
                siteWallboxes: [],
                deletedWallboxes: [],
                users: []
            },
            siteValidationError: '',
            isfieldvalidate: false,
            siteSucess: '',
            isSiteSuccess: false,
            emailError:'',
            wallboxError:"",
            displayInvite:'none',
            inviteMessage:'',
            invitePopup: false,
            errorCode:'',
            noWallboxExists:'',
            email:'',
            inviteEmail:'',
            wallbox:'',
            isCreatedBy:'',
            btnAction:"",
            currency: '€',
            isCostPerSessionActive: false,
            isCostBasedonUsageActive: false,
            CostPerSessionValue: '',
            CostPerUsageValue: '',
            currencyList: [],
            currencyCode: 'EUR',
            currecyCodeList: {},
            errors: {},
            demotedList: [],
            promotedList: [],
            europeStates:['EUR','SEK','NOK','TRY','RUB','DKK','PLN','CZK']
        }
        this.searchSite = this.searchSite.bind(this);
        this.setSelectedTab = this.setSelectedTab.bind(this);
        this.getKWRotation = this.getKWRotation.bind(this);
        this.addNewSite = this.addNewSite.bind(this);
        this.editSite = this.editSite.bind(this);
        this.onChangeWallboxes = this.onChangeWallboxes.bind(this);
        this.onConfirmDeleteSite = this.onConfirmDeleteSite.bind(this);
        this.getAvailableSites = this.getAvailableSites.bind(this);
        this.onSiteSettingsUpdated = this.onSiteSettingsUpdated.bind(this);
        this.onCloseSiteAddPopup = this.onCloseSiteAddPopup.bind(this);
        this.onWallboxUpdated = this.onWallboxUpdated.bind(this);
        this.onUpdateUsers = this.onUpdateUsers.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchSuggestions = this.getSearchSuggestions.bind(this);
        this.onChangeTypeahead = this.onChangeTypeahead.bind(this);
        this.clearSearchSuggestions = this.clearSearchSuggestions.bind(this);
        this.switchSite = this.switchSite.bind(this);
        this.toggle = this.toggle.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.toggleAddPopup = this.toggleAddPopup.bind(this);
        this.toggleDeleteSite = this.toggleDeleteSite.bind(this);
        this.getSiteLimit = this.getSiteLimit.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.toggleMaximumSites = this.toggleMaximumSites.bind(this);
        this.updateSiteSettings = this.updateSiteSettings.bind(this);
        this.saveNewSite = this.saveNewSite.bind(this);
        this.toggleFieldValidations = this.toggleFieldValidations.bind(this);
        this.toggleSiteSuccess = this.toggleSiteSuccess.bind(this);
        this.removeSelectedItem = this.removeSelectedItem.bind(this);
        this.isAccessChecked = this.isAccessChecked.bind(this);
        this.isAdminChecked = this.isAdminChecked.bind(this);
        this.onChangeAccessCheckbox = this.onChangeAccessCheckbox.bind(this);
        this.onChangeAdminCheckbox = this.onChangeAdminCheckbox.bind(this);
        this.updateExistingSite = this.updateExistingSite.bind(this);
        this.wallboxSearch = this.wallboxSearch.bind(this);
        this.emailSearch = this.emailSearch.bind(this);
        this.inviteEmail = this.inviteEmail.bind(this);
        this.toggleInvitePopup = this.toggleInvitePopup.bind(this);
        this.toggleDisplayInvite = this.toggleDisplayInvite.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.clearWallboxerror = this.clearWallboxerror.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.onEmailkeyPress = this.onEmailkeyPress.bind(this);
        this.onchangeInviteMail = this.onchangeInviteMail.bind(this);
        this.handleWallboxChange = this.handleWallboxChange.bind(this);
        this.handleWallboxPress = this.handleWallboxPress.bind(this);
        this.updatedata = this.updatedata.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.onChangeCostPerSessionActive = this.onChangeCostPerSessionActive.bind(this);
        this.onChangeCostBasedonUsageActive = this.onChangeCostBasedonUsageActive.bind(this);
        this.onChangeCostPerUsageValue = this.onChangeCostPerUsageValue.bind(this);
        this.onChangeCostPerSessionValue = this.onChangeCostPerSessionValue.bind(this);
        this.getCurrencies = this.getCurrencies.bind(this);
        this.updateDemotedAndPromotedUsersList = this.updateDemotedAndPromotedUsersList.bind(this);
        this.onBlurEvent = this.onBlurEvent.bind(this);
    }

    onBlurEvent(event) {
        this.setState({ ...this.state, searchStr: event.target.value });
    }
    handleWallboxPress(e) {
        var key = e.key;

        if (e.keyCode == 13) {
            this.setState({ wallbox: e.target.value });
            this.wallboxSearch(this.state.fields.id, this.state.wallbox);
        }
        
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var key = e.key;
                
                break;

        }
    }

    handleWallboxChange(e){
        $("#empty_wallboxes").text("")
        this.setState({
            wallbox: e.target.value
        }, () => {
            this.clearWallboxerror();
            if (this.state.wallbox.length == 0) {
                this.wallboxSearch(this.state.fields.id, this.state.wallbox);
            }
        });
    }

    onchangeInviteMail(){
        this.setState({ inviteEmail: this.state.email });
    }

    clearWallboxerror(){
        this.setState({
            noWallboxExists:''
        })
    }

    toggleDisplayInvite(){
        this.setState({
            displayInvite:'none'
        });
    }

    toggleInvitePopup(){
        this.setState({
            invitePopup: !this.state.invitePopup
        });
    }

    toggleSiteSuccess() {
        this.setState({
            isSiteSuccess: !this.state.isSiteSuccess
        })
    }

    toggleFieldValidations() {
        this.setState({
            isfieldvalidate: !this.state.isfieldvalidate
        })
    }

    componentDidMount() {
        if(localStorage.role == roles.WEBASTO_SUPER_ADMIN_DEVOPS || 
           localStorage.role == roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || 
           localStorage.role == roles.WEBASTO_CUSTOMER_ADMIN){
               this.getSiteLimit();
           }
        this.getAvailableSites(1, this.state.pageSize, this.state.searchStr);
        this.getCurrencies();
    }

    getSiteLimit() {
        const url = `${baseUrl.URLPath}preferences/getSiteLimit`;
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        }).then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }
            if (resp.status == 500) {
                console.log("internal server error")
            }
            return resp.json();
        }).then((response) => {
            if (response) {
                const { isSitelimitChecked, sitelimit } = response;
                this.setState({ ...this.state, isSitelimitChecked, sitelimit });
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        });
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    getAvailableSites(page, pageSize, site) {
        let url = `${baseUrl.URLPath}sitemanagement/search?pageSize=${pageSize}&pageNo=${page}&site=${site}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            },
        }).then(resp => {
            if (resp.status === 401) {
                this.togglesession();
                localStorage.clear();
                return [];
            } else if (resp.status == 200) {
                return resp.json();
            }
        }).then((response) => {
            if (this.state.isOnSearchFind) {
                if (response.count.count > 0) {
                    this.setState({ ...this.state, availableSites: response, noOfRecords: response.count.count, isSearchNotFound: false, isOnSearchFind: false });
                } else {
                    this.setState({ ...this.state, isSearchNotFound: true });
                    this.state.isSearchNotFound = true;
                }
            } else {
                this.setState({ ...this.state, availableSites: response, noOfRecords: response.count.count, isSearchNotFound: false, isOnSearchFind: false });
            }
        }).catch((error) => {
            console.log(error, "error in getting sites list");
        });
    }

    getUsers(site){
        let url = `${baseUrl.URLPath}${servicesUrl.getSitemanagement}?siteid=${site}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            },
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else if (resp.status == 200) {
                return resp.json();
            }
        }).then((response) => {
            let fields = this.state.fields;
            fields.users = response.users;
            this.setState({ fields});
        }).catch((error) => {
            console.log(error, "error in getting users list");
        });
    }

    searchSite() {
        this.setState({ ...this.state, isOnSearchFind: true });
        this.getAvailableSites(1, this.state.pageSize, this.state.searchStr);
    }

    addNewSite() {
        const totalExistingSites = this.state.availableSites?.count?.count || 0;
        this.state.wallbox = "";
        if (totalExistingSites < this.state.sitelimit || !this.state.isSitelimitChecked) {
            let fields = {
                id: '',
                name: '',
                city: '',
                zipcode: '',
                address: '',
                selectedItems: [],
                availableWallBoxes: [],
                siteWallboxes: [],
                deletedWallboxes: [],
                users: []
            }
            
            this.state.noWallboxExists = "";
            this.setState({ ...this.state, fields, selectedTab: 0, isNewSite: true, modalAddSite: true, currency: '€', isCostPerSessionActive: true, isCostBasedonUsageActive: true, CostPerSessionValue: '', CostPerUsageValue: '', currencyCode: 'EUR', errors: {} });
        } else {
            this.setState({ ...this.state, modalShowMaximumSites: true });
        }
    }

    editSite(site) {
        let url = `${baseUrl.URLPath}${servicesUrl.getSitemanagement}?siteid=${site.id}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            },
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else if (resp.status == 200) {
                return resp.json();
            }
        }).then((response) => {
            let fields = this.state.fields;
            fields.name = response.name;
            fields.address = response.address;
            fields.zipcode = response.zipcode;
            fields.city = response.city;
            fields.id = response.id;
            fields.users = response.users;
            localStorage.setItem('usersFromApi', JSON.stringify(response.users))
            this.state.noWallboxExists = "";
            this.state.btnAction = "Edit";
            this.state.email = "";
            this.state.wallbox = "";
            this.state.currencyCode = response.currencyCode;

            if (this.state.europeStates.includes(this.state.currencyCode)) {
                this.state.CostPerSessionValue = response.costsPerChargingSession.toString().replace('.',',');
            } else {
                this.state.CostPerSessionValue = response.costsPerChargingSession;
            }

            if (this.state.europeStates.includes(this.state.currencyCode)) {
                this.state.CostPerUsageValue = response.costsPerKwh.toString().replace('.',',');
            } else {
                this.state.CostPerUsageValue = response.costsPerKwh;
            }
            
            if(response.chargingSessionCurrency){
                this.state.currency = response.chargingSessionCurrency;
            }else{
                this.state.currency = '€';
            }

            this.state.isCostBasedonUsageActive = response.isDisabledUsageCost;
            this.state.isCostPerSessionActive = response.isDisabledSessionCost;
            this.setState({ ...this.state, selectedTab: 0, isNewSite: false, modalAddSite: true , isCreatedBy:response.createdBy});
        }).catch((error) => {
            console.log(error, "error in getting sites list");
        });
    }

    setSelectedTab(tabId, id) {
        if(this.state.selectedTab != tabId){
            this.state.noWallboxExists = "";
        }
        this.setState({ ...this.state, selectedTab: tabId });
    }

    getKWRotation(kwVal) {
        const retVal = kwVal * 180 / this.state.maxRotation - 90;
        return retVal;
    }

    onChangeWallboxes(selectedWallboxes) {
        this.setState({ ...this.state, selectedWallboxes });
    }

    onSiteSettingsUpdated(site) {
        // This is useful to auto navigate to wallboxes tab
        // this.setState({ ...this.state, selectedSite: site, selectedTab: 1 });
        // this.getAvailableSites(this.state.activePage, this.state.pageSize, this.state.searchStr);

        this.setState({ ...this.state, modalMsg: this.state.isNewSite ? this.props.t('new_group_added_successfully') : this.props.t('group_updated_successfully'), modal: true, selectedTab: -1, modalAddSite: false, reloadOnOk: true });
    }

    onWallboxUpdated() {
        // This is useful to auto navigate to users tab
        // this.setState({ ...this.state, selectedTab: 2 });
        this.setState({ ...this.state, modalMsg: this.props.t('Wallboxes_updated_successfully'), modal: true, selectedTab: -1, modalAddSite: false, reloadOnOk: false });
    }

    onCloseSiteAddPopup() {
        this.setSelectedTab(-1);
    }

    onDeleteSite(site) {
        this.setState({ ...this.state, selectedSite: site, modalDeleteSite: true });
    }

    onConfirmDeleteSite() {
        let url = `${baseUrl.URLPath}${servicesUrl.getSitemanagement}?siteid=${this.state.selectedSite.id}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Authorization')
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else if(resp.status == 403){
                return resp.json();
            }
            else{
                return resp.json();
            }
        })
        .then(response => {
            if (response != undefined) {
                if (response.status == 403) {
                    this.setState({ ...this.state, modalMsg: this.props.t(response.message), modal: true, selectedTab: -1, modalDeleteSite: false, reloadOnOk: true });
                } else {
                    this.setState({ ...this.state, modalMsg: this.props.t('group_deleted_successfully'), modal: true, selectedTab: -1, modalDeleteSite: false, reloadOnOk: true });
                }
            }
        })
        .catch((error) => {
            console.log(error, "error in deleting the site");
        });
    }

    onUpdateUsers() {
        // this.setState({ ...this.state, selectedTab: -1, selectedSite: null, modalAddSite: false });
        this.setState({ ...this.state, modalMsg: this.props.t('users_updated_successfully'), modal: true, selectedTab: -1, modalAddSite: false, reloadOnOk: false });
    }

    getDoughnutData(site) {
        const isEmpty = !site.onlineCPCount && !site.oflineCPCount;
        let total = 0;
        let perOnline = 0;
        let perOffline = 0;
        if (!isEmpty) {
            total = site.oflineCPCount + site.onlineCPCount;
            perOnline = (site.onlineCPCount / total) * 100;
            perOffline = (site.oflineCPCount / total) * 100;
            perOffline = perOffline.toFixed();
            perOnline = perOnline.toFixed();
        }

        const data = {
            datasets: [
                {
                    data: isEmpty ? [100] : [perOffline, perOnline],
                    backgroundColor: isEmpty ? ['#d6d6d6'] : ['#00529e', 'rgba(0, 255, 0, 1)'],
                    borderWidth: 1,
                },
            ],
            options: {
                plugins: {
                    legend: {
                        display: true
                    }
                }
            }
        };
        return data;
    }

    handlePageChange(pageNumber) {
        if (pageNumber != this.state.activePage) {
            this.setState({ ...this.state, activePage: pageNumber });
            this.getAvailableSites(pageNumber, this.state.pageSize, this.state.searchStr);
        }
    }

    showEntriesChange(event) {
        const pageSize = event.target.value;
        if (pageSize !== this.state.pageSize) {
            this.setState({ ...this.state, pageSize, activePage: 1 });
            this.getAvailableSites(1, pageSize, this.state.searchStr);
        }
    }

    handleKeyDown(event) {
        this.getSearchSuggestions(event.target.value);
        if (event.key === 'Enter') {
            this.setState({ ...this.state, searchStr: event.target.value, isOnSearchFind: true });
            $("#rbt_id").hide();
            this.getAvailableSites(1, this.state.pageSize, event.target.value);
        } else {
            this.setState({ ...this.state, isSearchNotFound: false });
        }
    }

    getSearchSuggestions(searchStr) {
        let url = `${baseUrl.URLPath}sitemanagement/search/partial?site=${searchStr}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Authorization')
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else{
                return resp.json();
            }
        }).then(response => {
            this.setState({ ...this.state, searchSuggestions: response })
        }).catch((error) => {
            console.log(error, "error in deleting the site");
        });
    }

    onChangeTypeahead(site) {
        $('.empty_site').text("")
        this.getAvailableSites(1, this.state.pageSize, site);
    }

    clearSearchSuggestions() {
        this.typeAHeadRef.current.clear();
        $('.empty_site').text("")
        this.setState({ ...this.state, activePage: 1, searchStr: '' });
        this.getAvailableSites(1, this.state.pageSize, '');
    }

    switchSite(selectedSite) {
        let url = `${baseUrl.URLPath}switchsite/`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization"),
                'siteid': selectedSite.id
            }
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }
        }).then((response) => {
            localStorage.setItem("Authorization", `${response.tokenType} ${response.token}`);
            const { id, name } = selectedSite;
            const siteDetails = { id, name };
            localStorage.setItem("SelectedSite", JSON.stringify(siteDetails));
            history.push('/dashboard');
            window.location.reload();
        }).catch((error) => {
            console.log(error, "error in switching the site");
        })
    }

    tagOk() {
        if (this.state.reloadOnOk) {
            window.location.reload();
        } else {
            this.getAvailableSites(this.state.activePage, this.state.pageSize, this.state.searchStr);
            this.setState({ ...this.state, modal: false });
        }
    }

    async toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleAddPopup() {
        this.state.fields.selectedItems =[];
        this.setState({ ...this.state, modalAddSite: !this.state.modalAddSite });
    }

    toggleDeleteSite() {
        this.setState({ ...this.state, modalDeleteSite: !this.state.modalDeleteSite })
    }

    toggleMaximumSites() {
        this.setState({ ...this.state, modalShowMaximumSites: !this.state.modalShowMaximumSites });
    }

    updateSiteSettings(event) {
        let fields = this.state.fields;
        let { name, value } = event.target;

        if (name) {
            fields[name] = value;
            this.setState({ ...this.state, fields });
        }
    }

    saveNewSite() {
        let address = this.state.fields.address;
        let city = this.state.fields.city;
        let zipcode = this.state.fields.zipcode;
        let name = this.state.fields.name;

        if (address.trim() == "" || city.trim() == "" || zipcode.trim() == "" || name.trim() == "") {
            this.setState({
                siteValidationError: this.props.t('group_setting_field_must_valid')
            });
            this.toggleFieldValidations();
        } else {
            let url = baseUrl.URLPath + "sitemanagement";

            let data = {};

            data["address"] = address.toLowerCase();
            data["city"] = city.toLowerCase();
            data["name"] = name.toLowerCase();
            data["zipcode"] = zipcode;
            data["tenant"] = localStorage.getItem('tenant');
            data["newWallboxs"] = this.state.fields.selectedItems;
            data['chargingSessionCurrency'] = this.state.currency;
            data['currencyCode'] = this.state.currencyCode;
            
            data['isDisabledSessionCost'] = this.state.isCostPerSessionActive;
            
            if(this.state.isCostPerSessionActive){
                data['costsPerChargingSession'] = 0;
            }else{
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue.toString().replace(',','.');
                } else {
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue;
                }
            }

            data['isDisabledUsageCost'] = this.state.isCostBasedonUsageActive;

            if(this.state.isCostBasedonUsageActive){
                data['costsPerKwh'] = 0;
            }else{
                if (this.state.europeStates.includes(this.state.currencyCode)) {
                    data['costsPerKwh'] = this.state.CostPerUsageValue.toString().replace(',','.');
                } else {
                    data['costsPerKwh'] = this.state.CostPerUsageValue;
                }        
            }

            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Authorization')
                }
            }).then(resp => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }else{
                    return resp.json();
                }
            }).then((response) => {
                if (response.status === 409) {
                    if (response.message === 'group_name_already_exists') {
                        this.setState({
                            siteValidationError:this.props.t('group_name_already_exists')
                        });
                        this.toggleFieldValidations();
                    }
                } else if (!response.status) {
                    this.setState({
                        siteSucess: this.props.t('new_group_added_successfully')
                    });
                    this.toggleSiteSuccess();
                }

            }).catch((error) => {
                console.log(error, 'error in creating the site');
            });
        }
    }

    removeSelectedItem(item, index) {
        const allItems = [...this.state.fields.selectedItems];
        allItems.splice(index, 1);
        const deletedWallboxIndex = this.state.fields.siteWallboxes.findIndex(el => el === item);
        const deletedWallboxes = [...this.state.fields.deletedWallboxes];
        if (deletedWallboxIndex > -1) {
            deletedWallboxes.push(item);
        }
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                selectedItems: allItems,
                deletedWallboxes
            }
        })
    }

    isAccessChecked(user) {
        const index = this.state.fields.users.findIndex(el => el === user && el.hasAccess);
        return index > -1;
    }

    isAdminChecked(user) {
        const index = this.state.fields.users.findIndex(el => el === user && el.isSiteAdmin);
        return index > -1;
    }

    onChangeAccessCheckbox(event, user) {
        const isChecked = event.target.checked;
        const users = [...this.state.fields.users];
        const selectedUser = users.find(el => el === user);
        if (isChecked) {
            if (selectedUser) {
                selectedUser.hasAccess = true;
            }
        } else {
            selectedUser.hasAccess = false;
        }
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                users
            }
        });
    }

    onChangeAdminCheckbox(event, user) {
        
        const isChecked = event.target.checked;
        const users = [...this.state.fields.users];

        let usersFromApi = localStorage.getItem('usersFromApi');
        let defaultUsers = [];
        if(usersFromApi!=undefined){
            defaultUsers = JSON.parse(usersFromApi)
        }

        const selectedUser = users.find(el => el === user);
        const defaultUser = defaultUsers.find(el => el.id === user.id);

        let promotedList = this.state.promotedList;
        let demotedList = this.state.demotedList;

        if (isChecked) {
            if (selectedUser) {
                selectedUser.isSiteAdmin = true;
            }
            if (defaultUser.isSiteAdmin == true) {
                if(promotedList.includes(selectedUser.id)){
                    let index = promotedList.indexOf(selectedUser.id);
                    promotedList.splice(index, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if(demotedList.includes(selectedUser.id)){
                    let position = demotedList.indexOf(selectedUser.id);
                    demotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
            } else {
                if(demotedList.includes(selectedUser.id)){
                    let position = demotedList.indexOf(selectedUser.id);
                    demotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if(!promotedList.includes(selectedUser.id)){
                    promotedList.push(selectedUser.id);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
            }
        } else {
            selectedUser.isSiteAdmin = false;
            if (defaultUser.isSiteAdmin == true) {
                if(promotedList.includes(selectedUser.id)){
                    let position = promotedList.indexOf(selectedUser.id);
                    promotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if(!demotedList.includes(selectedUser.id)){
                    demotedList.push(selectedUser.id);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                } 
            } else {
                if(promotedList.includes(selectedUser.id)){
                    let index = promotedList.indexOf(selectedUser.id);
                    promotedList.splice(index, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
                if(demotedList.includes(selectedUser.id)){
                    let position = demotedList.indexOf(selectedUser.id);
                    demotedList.splice(position, 1);
                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            users
                        },
                        promotedList: promotedList,
                        demotedList: demotedList
                    },()=>{
                        console.log('promoted', JSON.stringify(this.state.promotedList));
                        console.log('demoted', JSON.stringify(this.state.demotedList));
                    });
                }
            }
        }
    }

    updateExistingSite(siteId) {
     
        let address = this.state.fields.address;
        let city = this.state.fields.city;
        let zipcode = this.state.fields.zipcode;
        let name = this.state.fields.name;

        if (address.trim() == "" || city.trim() == "" || zipcode.trim() == "" || name.trim() == "") {
            this.setState({
                siteValidationError: this.props.t('group_setting_field_must_valid')
            });
            this.toggleFieldValidations();
        } else {
            let url = baseUrl.URLPath + servicesUrl.getSitemanagement +"?siteid=" + siteId;
            let errors = {}

            let data = {};
            data["address"] = address.toLowerCase();
            data["city"] = city.toLowerCase();
            data["name"] = name.toLowerCase();
            data["zipcode"] = zipcode.toLowerCase();
            data["tenant"] = localStorage.getItem('tenant');
            data["id"] = this.state.fields.id;
            data["newWallboxs"] = this.state.fields.selectedItems;
            data["deletedWallboxs"] = this.state.fields.deletedWallboxes;;
            data["users"] = this.state.fields.users;
            data['chargingSessionCurrency'] = this.state.currency;
            data['isDisabledSessionCost'] = this.state.isCostPerSessionActive;
            data['isDisabledUsageCost'] = this.state.isCostBasedonUsageActive;
            data['demotedList'] = this.state.demotedList;
            data['promotedList'] = this.state.promotedList;
            data['currencyCode'] = this.state.currencyCode;
            
            if(!this.state.isCostPerSessionActive && !this.state.CostPerSessionValue.toString()){
                errors['costPerSessionError'] = this.props.t('enter_cost_per_session_value');
            }else{
                if(this.state.europeStates.includes(this.state.currencyCode)){
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue.toString().replace(',','.');
                } else {
                    data['costsPerChargingSession'] = this.state.CostPerSessionValue;
                }
            }

            if(!this.state.isCostBasedonUsageActive && !this.state.CostPerUsageValue.toString()){
                errors['costPerUsageError'] =this.props.t('please_enter_cost_per_usage_value');
            }else{
                if(this.state.europeStates.includes(this.state.currencyCode)){
                    data['costsPerKwh'] = this.state.CostPerUsageValue.toString().replace(',','.');
                } else {
                    data['costsPerKwh'] = this.state.CostPerUsageValue;
                }
            }

            if(errors['costPerSessionError'] || errors['costPerUsageError']){

            } else {
                fetch(url, {
                    method: "PUT",
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('Authorization')
                    }
                }).then(resp => {
                    if (resp.status == 401) {
                        this.togglesession();
                        localStorage.clear();
                    }else if(resp.status == 400){
                        return resp.json();
                    }else{
                        return resp.json();
                    }
                }).then((response) => {
                    if (response.status === 409 || response.status == 400) {
                        if (response.message === 'group_name_already_exists') {
                            this.setState({
                                siteValidationError:this.props.t('group_name_already_exists')
                            });
                            this.toggleFieldValidations();
                        }else if(response.status == 400){
                            this.setState({
                                siteValidationError: this.props.t('group_name_already_exists')
                            });
                            this.toggleFieldValidations();
                        }
                    }else if (!response.status) {
                        this.setState({
                            siteSucess:this.props.t('group_updated_sucesfuly')
                        });
                        this.toggleSiteSuccess();
                    }
    
                }).catch((error) => {
                    console.log(error, 'error in creating the site');
                });
            }

            this.setState({
                errors: errors
            });
        }
    }

    wallboxSearch(siteId, chargePointId){

        let url = baseUrl.URLPath + "sitemanagement/wallbox?siteid="+siteId+"&chargePointId="+chargePointId;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Authorization')
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else{
                return resp.json();
            }
        }).then((response) => {
            
            if(response.length > 0){
                this.setState({
                    ...this.state,
                    fields:{
                        ...this.state.fields,
                        availableWallBoxes: response
                    }
                })
            }else{
                this.setState({
                    ...this.state,
                    noWallboxExists:this.props.t('wallbox_not_found')
                });
            }
            
        }).catch((error) => {
            console.log(error, 'error in creating the site');
        });
    }

    emailSearch(siteId, email){
        let mail = "";
        let isvalid = false;
        let role = localStorage.getItem('role');
        if(email!="" && email!=undefined && email!=null){
            mail = email.trim();
            isvalid = true;
        }
        if(mail.length > 0 && isvalid){
            let url = baseUrl.URLPath + servicesUrl.getSitemanagementUser+ "?siteid="+siteId+"&email="+email;

            fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Authorization')
                }
            }).then(resp => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }else if(resp.status == 500){
                    this.setState({
                        ...this.state,
                        emailError: this.props.t('enter_valid_email'),
                        displayInvite: 'none'
                    });
                }else{
                    return resp.json();
                }
            }).then((response) => {
                if (response.status == 404) {
                    this.setState({
                        ...this.state,
                        emailError: '',
                        displayInvite: 'block'
                    });
                } else {
                    let userArray=[];
                    userArray.push(response);
                    this.setState({
                        ...this.state,
                        fields:{
                            ...this.state.fields,
                            users: userArray
                        },
                        emailError:"",
                        displayInvite: 'none'
                    });
                }
            }).catch((error) => {
                console.log(error, 'error in creating the site');
            });
        }else{
            this.setState({
                ...this.state,
                emailError: '',
                displayInvite: 'none'
            },()=>{this.getUsers(siteId)});
        }
    }

    inviteEmail(siteId, email){
        let url = baseUrl.URLPath + "sitemanagement/user/invite";

        let bodyJson = {};
        bodyJson.email = email;
        bodyJson.siteId = siteId;

        fetch(url,{
            method:"POST",
            body: JSON.stringify(bodyJson),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Authorization')
            }
        }).then(resp =>{
            if(resp.status == 401){
                this.togglesession();
                localStorage.clear();
            }else if(resp.status == 200){
                return resp.json();
            }else if(resp.status == 403){
                return resp.json();
            }else if(resp.status == 404){
                return resp.json();
            }else if(resp.status == 500){
                return resp.json();
            }else if(resp.status == 400){
                return resp.json();
            }
        }).then(response =>{
            if(response.message == 'group_invite_send_to_user'){
                this.setState({
                    inviteMessage: this.props.t(response.message),
                    invitePopup: true,
                    errorCode: 200,
                    displayInvite: 'none'
                });
            }else{
                this.setState({
                    inviteMessage: this.props.t(response.message),
                    invitePopup: true,
                    errorCode: response.status,
                    displayInvite: 'none'
                });
            }
            
        }).catch(error => console.error(error));
    }

    handleEmailChange(e) {
        $("#empty_users").text("")
        this.setState({
            email: e.target.value
        }, () => {
            if (this.state.email.length == 0) {
                this.emailSearch(this.state.fields.id, this.state.email);
            }
        });
    }

    onEmailkeyPress(e) {
        var key = e.key;

        if (e.keyCode == 13) {
            this.setState({ email: e.target.value, inviteEmail: e.target.value });
            this.emailSearch(this.state.fields.id, this.state.email);
        }

        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9!#$%&'*+-/=^_`{|}~.@]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    updatedata( selected ){
     this.state.fields.selectedItems = selected.selectedItems;
     this.state.fields.deletedWallboxes = selected.deletedWallboxes;
    }

    onChangeCurrency(){
        let currencySymbol = document.getElementById('currency').value;
        let currencyArray = currencySymbol?.split('_')
        this.setState({
            currency: currencyArray[0],
            CostPerUsageValue: '',
            CostPerSessionValue: '',
            currencyCode: currencyArray[1]
        })
    }

    onChangeCostPerSessionActive(){
        this.setState({
            isCostPerSessionActive: !this.state.isCostPerSessionActive
        },()=>{
            if(this.state.isCostPerSessionActive){
                this.setState({
                    CostPerSessionValue: '0'
                })
            }
        })
    }

    onChangeCostBasedonUsageActive(){
        this.setState({
            isCostBasedonUsageActive: !this.state.isCostBasedonUsageActive
        },()=>{
            if(this.state.isCostBasedonUsageActive){
                this.setState({
                    CostPerUsageValue: '0'
                })
            }
        })
    }

    onChangeCostPerUsageValue(e) {
        if (this.state.europeStates.includes(this.state.currencyCode)) {
            if (/^[0-9]*(\,[0-9]{0,3})?$/.test(e.target.value)) {
                let error = this.state.errors;
                error['costPerUsageError'] = ''
                this.setState({
                    CostPerUsageValue: e.target.value,
                    errors: error
                });
            }
        } else {
            if (/^[0-9]*(\.[0-9]{0,3})?$/.test(e.target.value) && e.target.validity.valid) {
                let error = this.state.errors;
                error['costPerUsageError'] = ''
                this.setState({
                    CostPerUsageValue: e.target.value,
                    errors: error
                });
            }
        }
    }

    onChangeCostPerSessionValue(e) {
        if (this.state.europeStates.includes(this.state.currencyCode)) {
            if(/^[0-9]*(\,[0-9]{0,2})?$/.test(e.target.value)){
                let error = this.state.errors;
                error['costPerSessionError'] = ''
                this.setState({
                    CostPerSessionValue: e.target.value,
                    errors: error
                });
            }
        } else {
            if (/^[0-9]*(\.[0-9]{0,2})?$/.test(e.target.value) && e.target.validity.valid) {
                let error = this.state.errors;
                error['costPerSessionError'] = ''
                this.setState({
                    CostPerSessionValue: e.target.value,
                    errors: error
                });
            }
        }
    }

    getCurrencies(){
        let url = `${baseUrl.URLPath}sitemanagement/currencycodes`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            },
        }).then(resp => {
            if (resp.status === 401) {
                this.togglesession();
                localStorage.clear();
                return [];
            } else if (resp.status == 200) {
                return resp.json();
            }
        }).then((response) => {
            if(response!=undefined && response.length>0){
                let currecyCodeList = {}

                let x = response?.map((currency)=>{
                    currecyCodeList[currency.currencysymbol] = currency.currencyCode;
                });

                this.setState({
                    currencyList: response,
                    currecyCodeList: currecyCodeList
                })
            }
        }).catch((error) => {
            console.log(error, "error in getting currecy list");
        });
    }

    updateDemotedAndPromotedUsersList(demotedList, promotedList){
        this.setState({
            ...this.state,
            demotedList: demotedList,
            promotedList: promotedList
        });
    }
    
    render() {
        const{t}=this.props
        return (
            <main class="content-div">
                <p>{t('Group Management')}</p>
                <div className="page-outerdiv">
                    <div className="row menubar">
                        <div className="col-md-12">
                            {this.props.Sitemanagement_W &&
                                <button type="button" data-test="button" onClick={this.addNewSite.bind(this)} data-toggle="modal" data-target="#operationsModal">
                                    <i className="fa fa-plus"></i>
                                    <span>{t('add')}</span>
                                </button>
                            }
                            <div className="pull-right">
                                <label className="sub-text pull-left" >{t('show_entries')}</label>
                                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" onChange={this.showEntriesChange}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pull-right">
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <Typeahead
                                    labelKey="name"
                                    className="w_80"
                                    options={this.state.searchSuggestions}
                                    id="rbt_id"
                                    minLength={3}
                                    placeholder={t('search_group')}
                                    onKeyDown={this.handleKeyDown}
                                    ref={this.typeAHeadRef}
                                    onBlur={this.onBlurEvent}
                                    onChange={this.onChangeTypeahead}
                                />

                                <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.clearSearchSuggestions}>
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={()=>{
                                        if(this.state.searchStr == "" || this.state.searchStr == null){
                                            $('.empty_site').text(t('please_enter_group_name'))
                                        }else{
                                            $('.empty_site').text("")
                                            this.searchSite();
                                        }
                                    }}>{t('find')}</button>
                                </div>
                                <span className="pull-left error_msg w3-animate-top mt-1 empty_site" style={{ color: "red" }}></span>
                                {
                                    this.state.isSearchNotFound && <span className="pull-left error_msg w3-animate-top mt-1">
                                        <span style={{ color: "red" }}>{t('group_not_exists')}</span>
                                    </span>
                                }
                            </div>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Table striped hover size="sm" className="page-table">
                                <tbody>
                                    {
                                        this.state.availableSites.smList?.map((site, index) => {
                                            const isEmpty = !site.onlineCPCount && !site.oflineCPCount;
                                            let total = 0;
                                            let perOnline = 0;
                                            let perOffline = 0;
                                            if (!isEmpty) {
                                                total = site.oflineCPCount + site.onlineCPCount;
                                                perOnline = (site.onlineCPCount / total) * 100;
                                                perOffline = (site.oflineCPCount / total) * 100;
                                                perOffline = perOffline.toFixed();
                                                perOnline = perOnline.toFixed();
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td className="align-middle text-center">
                                                        <h6>#{(this.state.activePage - 1) * this.state.pageSize + index + 1}</h6>
                                                    </td>
                                                    <td className="align-middle">
                                                        <div className="d-flex flex-column">
                                                            <h4 style={{ cursor: 'pointer' }} className="site-link" onClick={() => this.switchSite(site)}>{site.name}</h4>
                                                            <span>{site.address}</span>
                                                            <span>{site.zipcode} {site.city}</span>
                                                        </div>
                                                    </td>
                                                    <td>{site.level}</td>
                                                    <td className="align-middle">
                                                        <div className="d-flex align-items-center">
                                                            <div style={{ height: '60px', width: '60px', position: 'relative', top: '-5px' }}>
                                                                <Doughnut data={this.getDoughnutData(site)} options={{
                                                                    responsive: true,
                                                                    tooltips: {
                                                                        enabled: false
                                                                    }
                                                                }} height={60} width={60} />
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <span className="text-success">{t('online')} {perOnline} %</span>
                                                                <span>{t('offline')} {perOffline} %</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/Wallbox.png"} alt="" style={{ maxHeight: '50px', marginTop: "14px" }} />
                                                        <h3>{site.cpCount}</h3>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex flex-column">
                                                            <ReactSpeedometer
                                                                minValue={0}
                                                                maxValue={5000}
                                                                value={site.totalPower || 0}
                                                                needleColor="#00529e"
                                                                startColor="#00529e"
                                                                segments={1}
                                                                maxSegmentLabels={0}
                                                                endColor="#00529e"
                                                                width={100}
                                                                height={100}
                                                                ringWidth={5}
                                                                dimensionUnit="px"
                                                                currentValueText={`${site.totalPower || 0} kW`}
                                                                paddingHorizontal={5}
                                                                needleHeightRatio={0.45}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <button className="btn_primary mr-3" type="button" onClick={() => this.editSite(site)}> {t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                                                        <button className="btn_secondary mr-3" type="button" onClick={() => this.onDeleteSite(site)}> {t('delete')} <i class="fas fa-trash-alt ml-2"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )
                                    }
                                    {
                                        (this.state.availableSites.smList.length == 0)? 
                                            <tr>
                                                <td colSpan={7} className="align-middle text-center">
                                                    {
                                                        (localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS || localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || localStorage.getItem('role') == roles.WEBASTO_CUSTOMER_ADMIN)?
                                                        <>{t('no_groups_available')}</>:<>{t('You_see_if_you_are_admin_of_a_group')}</>
                                                    }
                                                </td>
                                            </tr> : null
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                hideDisabled
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.noOfRecords}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <MDBModal isOpen={this.state.modalAddSite} toggle={this.toggleAddPopup} size="lg">
                        <MDBModalHeader toggle={this.toggleAddPopup} className="pl-4 mt-3">
                            <h4>
                                {this.state.isNewSite ? t('add_new_group') : t('edit_group')}
                            </h4>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBNav className=" mt-1 p-rel border-0">
                                <MDBNavItem className="nav-first">
                                    <MDBNavLink to="#" className={`b-75 pl-5 pr-5 ${this.state.selectedTab === 0 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 0)}>{t('Settings')}</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="#" className={`b-75 pl-5 pr-5 ${this.state.selectedTab === 1 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 1, this.state.fields.id)}>{t('wallboxes')}</MDBNavLink>
                                </MDBNavItem>
                                {
                                    this.state.isNewSite ? <></> :
                                        <MDBNavItem>
                                            <MDBNavLink to="#" className={`b-75 pl-5 pr-5 ${this.state.selectedTab === 2 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 2)}>{t('Users')}</MDBNavLink>
                                        </MDBNavItem>
                                }
                                <MDBNavItem className="nav-last">
                                    <MDBNavLink to="#" className={`b-75 pl-5 pr-5 ${this.state.selectedTab === 3 ? 'activetab' : ''}`} role="tab" onClick={this.setSelectedTab.bind(this, 3)}>{t('costs')}</MDBNavLink>
                                </MDBNavItem>
                            </MDBNav>
                            {
                                this.state.selectedTab === 0 &&
                                <NewSiteSettings
                                    cancel={this.toggleAddPopup}
                                    name={this.state.fields.name}
                                    city={this.state.fields.city}
                                    zipcode={this.state.fields.zipcode}
                                    address={this.state.fields.address}
                                    updateSiteSettings={this.updateSiteSettings}
                                    isNewSite={this.state.isNewSite}
                                    id={this.state.fields.id}
                                    saveNewSite={this.saveNewSite}
                                    updateExistingSite={this.updateExistingSite}
                                />
                            }
                            {
                                this.state.selectedTab === 1 &&
                                <NewSiteWallboxes
                                    cancel={this.toggleAddPopup}
                                    isNewSite={this.state.isNewSite}
                                    selectedItems={this.state.fields.selectedItems}
                                    btnAction ={this.state.btnAction}
                                    onWallboxUpdated={this.onWallboxUpdated}
                                    id={this.state.fields.id}
                                    removeSelectedItem={this.removeSelectedItem}
                                    availableWallBoxes={this.state.fields.availableWallBoxes}
                                    saveNewSite={this.saveNewSite}
                                    updateExistingSite={this.updateExistingSite}
                                    wallboxSearch={this.wallboxSearch}
                                    noWallboxExists = {this.state.noWallboxExists}
                                    clearWallboxerror = {this.clearWallboxerror}
                                    wallbox={this.state.wallbox}
                                    handleWallboxChange={this.handleWallboxChange}
                                    handleWallboxPress = {this.handleWallboxPress}
                                    updatedata ={this.updatedata}
                                />
                            }
                            {
                                this.state.selectedTab === 2 && !this.state.isNewSite &&
                                <NewSiteUsers
                                    cancel={this.toggleAddPopup}
                                    users={this.state.fields.users}
                                    isAccessChecked={this.isAccessChecked}
                                    isCreatedBy = {this.state.isCreatedBy}
                                    isAdminChecked={this.isAdminChecked}
                                    onChangeAccessCheckbox={this.onChangeAccessCheckbox}
                                    onChangeAdminCheckbox={this.onChangeAdminCheckbox}
                                    updateExistingSite={this.updateExistingSite}
                                    id={this.state.fields.id}
                                    emailSearch={this.emailSearch}
                                    emailError={this.state.emailError}
                                    displayInvite={this.state.displayInvite}
                                    inviteEmail = {this.inviteEmail}
                                    hideinvite = {this.toggleDisplayInvite}
                                    getUsers = {this.getUsers}
                                    handleEmailChange = {this.handleEmailChange}
                                    email={this.state.email}
                                    onpress = {this.onEmailkeyPress}
                                    inviteemail ={this.state.inviteEmail}
                                    onChangeInvitemail = {this.onchangeInviteMail}
                                    demotedList={this.state.demotedList}
                                    promotedList={this.state.promotedList}
                                    updateDemotedAndPromotedUsersList={this.updateDemotedAndPromotedUsersList}
                                />
                            }
                            {
                                this.state.selectedTab === 3 &&
                                <NewSiteCosts
                                    cancel={this.toggleAddPopup}
                                    isNewSite={this.state.isNewSite}
                                    currency={this.state.currency}
                                    currencyCode={this.state.currencyCode}
                                    isCostPerSessionActive={this.state.isCostPerSessionActive}
                                    isCostBasedonUsageActive={this.state.isCostBasedonUsageActive}
                                    CostPerSessionValue={this.state.CostPerSessionValue}
                                    CostPerUsageValue={this.state.CostPerUsageValue}
                                    onChangeCurrency={this.onChangeCurrency}
                                    onChangeCostPerSessionActive={this.onChangeCostPerSessionActive}
                                    onChangeCostBasedonUsageActive={this.onChangeCostBasedonUsageActive}
                                    onChangeCostPerSessionValue={this.onChangeCostPerSessionValue}
                                    onChangeCostPerUsageValue={this.onChangeCostPerUsageValue}
                                    currencyList={this.state.currencyList}
                                    currecyCodeList={this.state.currecyCodeList}
                                    saveNewSite={this.saveNewSite}
                                    updateExistingSite={this.updateExistingSite}
                                    id={this.state.fields.id}
                                    errors = {this.state.errors}
                                />
                            }
                        </MDBModalBody>
                    </MDBModal>
                </div>

                <MDBModal isOpen={this.state.modalsession} toggle={this.tagOk} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggle}>{t('session_has_expired')}</MDBModalHeader>
                    <MDBModalBody>
                       {t('please_login_again')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modal} toggle={this.tagOk} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggle}>{t('alert')}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.modalMsg}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.tagOk}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalDeleteSite} toggle={this.toggleDeleteSite} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleDeleteSite}>{t('confirmation')}</MDBModalHeader>
                    <MDBModalBody>
                        {t('you_want_to_delete_group')} <b>{this.state.selectedSite?.name}</b>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.toggleDeleteSite}>{t('no')}</button>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.onConfirmDeleteSite}>{t('yes')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modalShowMaximumSites} toggle={this.toggleMaximumSites} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleMaximumSites}>{t('alert')}</MDBModalHeader>
                    <MDBModalBody>
                        {t('limit_is_reached')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.toggleMaximumSites}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.isfieldvalidate} toggle={this.toggleFieldValidations} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleFieldValidations}>{t('alert')}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.siteValidationError}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.toggleFieldValidations}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.isSiteSuccess} toggle={this.toggleSiteSuccess} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleSiteSuccess}>{t('success')}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.siteSucess}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={() => { window.location.reload(); }}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.invitePopup} toggle={this.toggleInvitePopup} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggleInvitePopup}>{(this.state.errorCode==200)? "Success":"Alert"}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.inviteMessage}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.toggleInvitePopup}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
            </main>
        )
    }
}

export default withTranslation()(SiteManagement);