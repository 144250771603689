import React from 'react';
import Chart from "react-apexcharts";
import * as baseUrl from '../constants';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import servicesUrl from '../../common/servicesUrl';
import { withTranslation } from 'react-i18next';

class heartbeatStatus extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
              userValue:[],
              user_date:[],
              calendarValue: new Date(),
              startDate:null,
              value:[null, null],
              series: [
                        {
                          data:[]
                        },
                      ],
              options : {
                chart: {
                  width:'100%',
                  type: 'bar',
                  height:350,
                  toolbar: {
                    tools: {
                      download: true,
                      selection: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      reset: false,
                      pan: false
                    }
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '20%',
                    endingShape: 'rounded'
                  },
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent'],
                  fill:'#00529e'
                },
                xaxis: {
                  categories:[],
                },
                fill: {
                  opacity: 1,
                  colors: ['rgb(114, 201, 62)']
                },
                tooltip: {
                        enabled: false,
                 
                }
              },
          }
          this.onChange = this.onChange.bind(this);
          this.getHeatbeatValues = this.getHeatbeatValues.bind(this);
        }



        //  api to get charging session chart
        componentDidMount(){
          var today = new Date(),
          
          date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
          
           var now = new Date();
           now.setDate(now.getDate() - 7); // add -7 days to your date variable 
           this.state.startDate = moment(today).format("YYYY-MM-DD").toUpperCase();
           
           var garphInitData = this.state.startDate
           this.setState({
             value:garphInitData
           })
           this.getHeatbeatValues(date);
        }

        getHeatbeatValues(startDate){
          var user_date = [];
          var user_value = [];
          ;
        
          let chargePointId = localStorage.getItem("chargerId");
          var url = baseUrl.URLPath +servicesUrl.getCustomerserviceCPHeartbeats+"?chargePointId="+chargePointId+"&date="+startDate;
          // /api/customerservice/chargepoints/{chargePointId}/heartbeats
          var Date = moment(startDate).format("DD-MM-YYYY").toUpperCase();
           fetch(url,{
            method: "GET",
            headers: {
                  "content-type":"application/json",
                  "Authorization":localStorage.getItem("Authorization")
                }
            })
            .then((resp) => {
              ;
              if(resp.status==401){
                this.togglesession();
          
                localStorage.clear();
               
              }
              if(resp.status==200){
                return resp.json();
              }
            }) 
            .then((response) =>{
              ;
              if(response!=undefined){
              for(var i=0; i<response.length; i++){
                var timestamp = moment(response[i].timestamp).format("DD-MMM");
                user_date.push(timestamp);

                // user_date.push(response[i]["timestamp"])
                user_value.push(response[i]["value"])
                
              }
              this.state.series[0].data =  user_value;
              var data_options =  {
                    xaxis: {
                          categories:[Date]
                        },
                      };
                  }
            this.setState({options:data_options})
  
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
        }


        // date picker onchange function
        onChange(value, event){
          
          if(value != null && value != ""){
            var startDate = moment(value).format("YYYY-MM-DD").toUpperCase();
             this.setState({
                    calendarValue:value,
                    startDate:startDate
              })
              this.getHeatbeatValues(startDate);
              
          }else{
                this.setState({
                    calendarValue:value
                })
             }
          }
  

        render() {
                return (
                    <>
                    <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                        <div class="content-box">
                            <div class="dashboard-subtitle">
                                {this.props.t('heartbeat_frequency')}
                            </div>
                            <div className="mt-2 mb-5">
                                <DatePicker
                                    onChange={this.onChange}
                                    value={this.state.calendarValue}
                                    format = "dd-M-yyyy"
                                    dayPlaceholder = ""
                                    yearPlaceholder = ""
                                    monthPlaceholder = ""
                                    nativeInputAriaLabel = "Date"
                                />
                            </div>
                            <div className="mt-3 bottom-pos pb-3 pr-3 mr-15 w-100">
                                {/* <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/graph5.png"} alt="logo" className="w-100" /> */}
                                <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    data={this.state.user_date}
                                    type="bar"
                                    width="100%"
                                    height="232"
                                   />
                            </div>
                        </div>
                    </div>
                    </>
                )
            };
}

export default withTranslation()(heartbeatStatus);