import React from 'react';
import { MDBCol,MDBModal,MDBModalHeader,MDBModalBody,MDBRow,MDBModalFooter} from 'mdbreact';
import {Form,Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import $ from 'jquery';
import {dateRangeValidate,activeDateFormat,getDateRange, dateformatinUTC, dateRangeValidateForCpmsLogs} from './_methods';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const history = createBrowserHistory();

class cpmsLogs extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
  
          modalView: false,
          saveStartDate:'',
          saveEndDate:'',
          modalsession:false,
          calenderFrom:"none",
          calenderTo:"none",
          fields:{
            chargePointId:""
          },
          fromDate:'',
          toDate:'',
          fromDateApi:'',
          toDateApi:'',
            page_title:"CPMS Logs",
            collapseID: "",
              items: [
              
              ],
            operationData:[],
            dataArray: [],
          noOfRecords:'',
          data:new Map(),
          pageSize:"10",
          activePage: 1,
          pageNo:1,
          errors: {},
          curPageFirstCreTime:"",
          curPageLastCreTime:"",
          prevPageFirstCreTime:"",
          listOfFirstCreatedtimes:[]
      }
      this.getOperationLog = this.getOperationLog.bind(this);
      this.changeHandler = this.changeHandler.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.showEntriesChange=this.showEntriesChange.bind(this);
      this.onChangeChargepoint=this.onChangeChargepoint.bind(this);
      this.togglesession = this.togglesession.bind(this);
      this.sessiontagOk = this.sessiontagOk.bind(this);
      this.toggleView = this.toggleView.bind(this);
      this.toggleView1 = this.toggleView1.bind(this);
      this.keyPress = this.keyPress.bind(this);
      this.createdDatesArray = this.createdDatesArray.bind(this);
      this.handleClick = this.handleClick.bind(this);
}

createdDatesArray(dateTime) {
  let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
  this.setState({
    listOfFirstCreatedtimes: listOfFirstCreatedtimes
  });
};

toggleView(requestMessage,responseMessage){
  this.setState({displayReq:requestMessage})
  this.setState({displayRes:responseMessage})
  this.setState({
    modalView: !this.state.modalView
  });
}
toggleView1(){
  this.setState({
    modalView: !this.state.modalView
  }); 
}

async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}

sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}


changeHandler(field, e){ 
  let fields = this.state.fields;
  fields[field] = e.target.value; 
  fields.chargePointid = document.getElementById('chargePointId')

  this.setState({ fields }, ()=>{
    if (this.state.fields.chargePointId.length == 0) {
      this.state.activePage = 1;
      this.getOperationLog(this.state.pageSize, 1);
    }
  });
  this.state.errors["entercharge"] = ""
  $("#notexists").text(" ");
  }

  handleClick() {
    let fields = this.state.fields;
    fields.chargePointId = '';
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");
    this.setState({
        fields
    });
    this.state.pageSize = 10;
    this.state.activePage = 1;
    document.getElementById("showEntries").value = 10;
    this.getOperationLog(this.state.pageSize, this.state.activePage);
}
  
  keyPress(e){
    var key = e.key;
    if(e.keyCode == 13){
        this.setState({ chargePointId: e.target.value });
        this.getOperationLog(this.state.pageSize,1);
        if(this.state.fields.chargePointId == ""){
          this.state.errors["entercharge"] = "Please enter charge point id"
          $("#notexists").text(" ");
        
        }else{
          this.state.errors["entercharge"] = " "
          this.getOperationLog(this.state.pageSize,1,"enter");
    
        }
      }
     
      if( key == "Backspace" || key == "Delete" ){
        
        var x=e.target.value
        var sno=x.slice(0,x.length-1)
        if(sno==""){
     
          this.state.fields.chargePointId =sno;
    
         this.getOperationLog(this.state.pageSize,1);
        }
        }
        switch (e.keyCode) {
          case 8:  // Backspace
          case 9:  // Tab
          case 13: // Enter
          case 37: // Left
          case 38: // Up
          case 39: // Right
          case 40: // Down
          break;
          default:
            var regex = new RegExp("^[a-zA-Z0-9., -]");
          var key = e.key;
          if (!regex.test(key)) {
          
              e.preventDefault();
      
              return false;
           
             
          }
          break;
        
      }
  }
// for from date calender 
handleFromChange = date => {
  $('.future_err1').text("");
  this.setState({fromDate:date});
  this.setState({
     fromDateApi: dateformatinUTC(date),
     activePage:1
}, ()=> {
 
  if(dateRangeValidateForCpmsLogs(this.state.fromDate, this.state.toDate) == true){
    $('.date_range_error').text('');
    this.getOperationLog(this.state.pageSize,1);
}else{
    $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
}
  
});
  this.setState({ disabledAttr: "" });
};

// for to date calender 
handleToChange = date => {
  this.setState({ toDate:date });
  this.setState({
    toDateApi:dateformatinUTC(date),
    activePage:1
 }, ()=> {
      if(dateRangeValidateForCpmsLogs(this.state.fromDate, this.state.toDate) == true){
      $('.date_range_error').text('');
      this.getOperationLog(this.state.pageSize,1);
      }else{
      $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
      }
  });
  this.setState({disabledAttr: ""});
};
formatCalDate1(date){
  var format = date.toISOString();
  var xyz = moment(format).format("DD MMM YYYY")
  return xyz;
}
// get date 
formatCalDate(date){
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [year, month, day].join('-');
}


formatData(date){
  var currentDate = date;
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  var csFormatDate = currentDate.getFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":00Z";
}
showEntriesChange(){
  
  var entries=document.getElementById("showEntries").value;
  
  let fromDateApi = dateformatinUTC(moment(this.state.fromDate));
  let toDateApi = dateformatinUTC(moment(this.state.toDate));
  
  this.setState({
    activePage: 1,
    pageSize: entries,
    fromDateApi: fromDateApi,
    toDateApi: toDateApi
  },()=>{
    this.getOperationLog(entries, 1);
  })
}

handlePageChange(pageNumber) {
  
  if (pageNumber < this.state.activePage) {
    // alert("prev")  
    //prev
      this.setState({ 
        activePage:pageNumber, 
        fromDateApi:this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, ()=> {
        this.getOperationLog(this.state.pageSize, pageNumber,"", false);
    })
      
  }else{
    //next
    let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
    this.setState({ 
      activePage:pageNumber, 
      fromDateApi:dateformatinUTC(this.state.fromDate),
      toDateApi:this.state.curPageLastCreTime,
      prevPageFirstCreTime:this.state.curPageFirstCreTime,
    }, ()=> {
      if(pageNumber > _datesArrayLength + 1){
        this.createdDatesArray(this.state.curPageFirstCreTime);
      }
      this.getOperationLog(this.state.pageSize, pageNumber,"", false);
  })
  }
  console.log(this.state.listOfFirstCreatedtimes)
}



getOperationLog(pageSize,pageNo, action, countValue){
  let chargePointId = this.state.fields.chargePointId 
//  this.setState({fields}, () =>{
  var url = baseUrl.URLPath + "cs/operations/logs?charge_point_id="+chargePointId+"&start_date="+this.state.fromDateApi+"&end_date="+this.state.toDateApi+"&pageNo="+pageNo+"&pageSize="+pageSize;
  //localhost:8080/api/cs/operations/logs?charge_point_id={}&start_date={}&end_date={}&pageNo={}&pageSize={}
   fetch(url,{
    method: "GET",
    headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
    })
    .then((resp) => {
      ;
      if(resp.status==401){
        // alert("Access token is expired, please login again")
        this.togglesession();
  
        localStorage.clear();
       
      }
      if(resp.status==200){
        return resp.json();
      }else if(resp.status == 400){
        return resp.json();
      }
    }) 
    .then((response) =>{
      if(response!=undefined){
        if(response.status == 400){
          $('.date_range_error').text(response.message);
        }else if(response.count.count==0){
        if(action=="click" || action=="enter"){ 
          $("#notexists").text("Charge point id not exists.");
                          }
                          this.setState({
                            items:[],
                            noOfRecords:0
                            
                        })
      }else{
        var totalcount = "";
            ;
            if(countValue==false){
              totalcount = this.state.noOfRecords
            }else{
              totalcount = response.count.count
            }
            this.setState({
              prevPageFirstCreTime:this.state.curPageFirstCreTime,
              items:response.logs,
              noOfRecords:totalcount,
              curPageFirstCreTime: moment(response.logs[0].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.logs[response.logs.length-1].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"), 
            })
      if(action=="click" || action=="enter"){ 
        $("#notexists").text(" ");
                        }
   
    }
  }
  
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
  // });
}


  componentDidMount(){
  let duration = localStorage.getItem('listLogDuration');
  duration = duration -1;   
  let fromDate = moment().subtract(duration,'d');
  let fromDateApi = dateformatinUTC(moment().subtract(duration,'d'));
  let toDate = new Date();
  let toDateApi = dateformatinUTC(moment(toDate));
  this.setState({
    fromDate:new Date(fromDate),
    fromDateApi:fromDateApi,
    toDate:toDate,
    toDateApi:toDateApi,
  },()=>{
    getDateRange();
    this.getOperationLog(this.state.pageSize, this.state.activePage);
  })
  }
 //change function for chargepoint status filter
  onChangeChargepoint() {
  var chargePoint = document.getElementById("chargePointId").value;

    this.setState({ chargePointId: chargePoint }, () => {
      this.getOperationLog(this.state.pageSize,1);
    });
  
    if(this.state.fields.chargePointId == ""){
      this.state.errors["entercharge"] = "Please enter charge point id"
      $("#notexists").text(" ");
    
    }else{
      this.state.errors["entercharge"] = " "
      this.getOperationLog(this.state.pageSize,1,"click");

    }
}

//function for sorting table data on click
ascendingOrder(){
  this.setState({items : this.state.items.reverse()})
}

render() {

 
  const operationData=this.state.items.map((item, index) => {    
      // var time = item.createdTime.split(" ");
      var status_value = "Rejected";
    
      if(item.responseMessage!="null" || item.responseMessage!=""){

      }
      if(item.createdTime==null || item.createdTime==""){
        var createdTime = "";
        var createdTime1="";
      }else{
        var createdTime = moment(item.createdTime).format("DD MMM YYYY");
        var createdTime1 = moment(item.createdTime).format("HH:mm");
      }

      if(item.responseTime==null || item.responseTime==""){
        var responseTime = "";
        var responseTime1="";
      }else{
        var responseTime = moment(item.responseTime).format("DD MMM YYYY");
        var responseTime1 = moment(item.responseTime).format("HH:mm");
      }
      return(             
        <tr>
           <td className="wrap-word">{ createdTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdTime1} </div> </>}</td>
          <td className="">{item.chargePonintId}</td>
          <td className="">{item.name}</td>
          <td className="wrap-word">{ responseTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {responseTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {responseTime1} </div> </>}</td>
          <td className=""><i class="fas fa-eye action-icon cursor_defult"  title="View"  onClick={this.toggleView.bind(this,item.requestMessage,item.responseMessage)} ></i></td>
      </tr>
      )
  });

  return (
    <>
 <main class="content-div" >
          <p>CPMS logs
              <div className="breadcrumb_div">
              Operations &gt; <span className="breadcrumb_page">CPMS logs</span>
              </div>
           </p>
        <div className="page-outerdiv">
          {/* page title */}
          {/* <p>Unknown Charge Points</p> */}
          {/* end page title */}
          {/* page menu bar */}
          {/* <div className="breadcrumb_div">Operations > CPMS logs</div> */}
          {/* end page menu bar  */}
          <div className="row">
          <div className="col-md-3 t-8">
                  <div class="input-group">
                    <i className="fas fa-search fa-sm input-searchIcon"></i>
                    <input type="text"  id = "chargePointId" value={this.state.fields["chargePointId"]} onKeyDown={this.keyPress} onChange={this.changeHandler.bind(this, "chargePointId")} class="form-control input-searchBox pad-2" placeholder="Charge Point ID" name="adminAddress"  /> 
                    <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                    <div class="input-group-btn">
                        <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >Find</button>
                    </div>
                    <span className="error_msg w3-animate-top"> <span style={{color:"red"}}></span></span>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{color: "red"}}>{this.state.errors["entercharge"]}</span></span> 
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
                </div>
                
                <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">From Time</span>
                <div className="pull-left mt-2" style={{width:"68%" }} >
                  <DatePicker
                    selected={this.state.fromDate}
                    onChange={this.handleFromChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    maxDate={(this.state.toDate!=""&&this.state.toDate!=null)? new Date(this.state.toDate):new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
              </MDBCol>

              <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">To Time</span>
                <div className="pull-left mt-2" style={{ width: "70%" }} >
                  <DatePicker
                    selected={this.state.toDate}
                    onChange={this.handleToChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    minDate={new Date(this.state.fromDate)}
                    maxDate={new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
              </MDBCol>

              <div className='col-md-3'></div>
              <div className='col-md-9 date_range_error' style={{ color: "red" }}></div>
            
                      <div className="col-md-12 mt-2">
                      <div className="pull-right">
            <label className="pull-left sub-text">Show entries</label>
              <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                </Form.Control>
              </Form.Group>
            </div>
                </div>
            </div>
          {/* table start */}
          <div className="row ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                {/* <th>Date/Time</th>
                <th>Charge Point ID</th> 
                <th>Operation Name</th>
                <th>Response Time</th> */}
                

                <th className="">Date/Time <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">Charge Point ID <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th> 
                <th className="">Operation Name <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">Response Time <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="text-center" >View</th>
             </tr>
            </thead>
            <tbody>
            {operationData}
            {
              (this.state.items.length == 0) ? <td colSpan={5} style={{ textAlign: "center" }}>No Data Available for CPMS Logs</td> : null
            }
            </tbody>
          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right" >
              <Pagination
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pageSize}
                totalItemsCount={this.state.noOfRecords}
                onChange={this.handlePageChange.bind(this)}
                prevPageText="Prev"
                nextPageText="Next"
                itemClassPrev="prevBtn"
                pageRangeDisplayed="1"
                activeClass="activeLi active"
                itemClassNext="nextBtn"
                hideFirstLastPages={true}
              />
          </div> 
          {/* End table  */}
          </div>
          </div>
        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
          <MDBModalBody>
              Please login again.
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
          </MDBModalFooter>
      </MDBModal>
            {/* Popup for  View */}
            <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="lg" className="model_top">
            <div data-test="modal-header" class="modal-header">
        <div class="modal-title fw-800 mt-1" ref={(node)=>{if(node){node.style.setProperty("font-size","18px","important");}}}>View</div>
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
        {/* <MDBModalHeader toggle={this.toggleView}>View</MDBModalHeader> */}
        <MDBModalBody>
        <MDBRow className="mb-4 mt-3" >
       
       <MDBCol sm="12">
       <div className="view-page-row view-bg-row" >
            <label className="">
              Request Message:
            </label>
            <label className="wrap-word">
              { this.state.displayReq}
            
            </label>
          </div>
          </MDBCol>
          </MDBRow>
          <MDBRow className="mb-4 mt-3" >

          <MDBCol sm="">
       <div className="view-page-row view-bg-row">
            <label className="w-40">
               Response Message:
            </label>
            <label className="wrap-word">
              
            { this.state.displayRes}
            </label>               
          </div>
          </MDBCol>
         </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <button className="btn_primary mt-2 mb-2" onClick={this.toggleView1}>Close</button>
          {/* <MDBBtn color="primary">Save</MDBBtn> */}
          {/* onClick={this.addBuildtype} */}
        </MDBModalFooter>
      </MDBModal>

     
      </main>
 </>
  );
}
}

export default cpmsLogs;