import React from 'react';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import  $ from "jquery";
import { withTranslation } from 'react-i18next';
import { hasPermission } from '../auth';
import * as baseUrl from '../constants';
import Header from './header';

class Sidebar extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            inventoryDashboardDisplay:"",
            enablecronjob: "true",
            logsdisplay:"",
            operationdisplay:"",
            brokerdisplay:"",
            connectorDisplay:"",
            vehicledisplay:"",
            enableBroker: "true",
            enableConnector:"true",
            enableVehicle: "true",
            enableCSOperation: "true",
            dashboarddisplay:"",
            ocppTagsDisplay:"",
            getOperationList:[],
           

            hideActionBtn:"none", // show hide btn varible based on permission 
            writePermission:this.props.ChargePoint_W, // ChargePoint_W conndition
			listOpen0: false, // done by manoj ux designer 
			listOpen1: false,
			listOpen2: false,
			listOpen3: false,
			listOpen4: false,
            listOpen5: false,
            listOpen6: false,
			listOpen7: false,
			listOpen8: false,
			listOpen9: false,
            listOpen10: false,
			listOpen11: false,
			listOpen13: false,
            listOpen12: false, 
            listOpen14: false,  
            listOpen15: false,              
            prev_id:"",
            // disableMenu:"",
            user:{
                permissions: [
                    
                ],
            }
		};
      
        this.toggleMenuOperation = this.toggleMenuOperation.bind(this);
        this.toggleList = this.toggleList.bind(this);
        this.getEnableBrokerStatus = this.getEnableBrokerStatus.bind(this);
        
    }
    

    //  toogle functionality of sidebar done by manoj ux designer 
    toggleList(img, index, e ){
        var elemnt  = e.target.parentElement;
        $(".collapse").removeClass("show");
        var list ="";
        if(index==this.state.prev_id){
        }else{
            this.setState({
                listOpen0: false, 
                listOpen1: false,
                listOpen2: false,
                listOpen3: false,
                listOpen4: false,
                listOpen5: false,
                listOpen6: false, 
                listOpen7: false,
                listOpen8: false,
                listOpen9: false,
                listOpen10: false,
				listOpen11:false,
                listOpen13:false,
                listOpen12:false,
                listOpen14 :false,
                listOpen15:false
            })
        }
        switch(index) {
            case 0: this.setState(prevState => ({listOpen0: !prevState.listOpen0 })); break;
            case 1: this.setState(prevState => ({listOpen1: !prevState.listOpen1 }));break;
            case 2: this.setState(prevState => ({listOpen2: !prevState.listOpen2 }));break;
            case 3: this.setState(prevState => ({listOpen3: !prevState.listOpen3 }));break;
            case 4: this.setState(prevState => ({listOpen4: !prevState.listOpen4 }));break;
            case 5: this.setState(prevState => ({listOpen5: !prevState.listOpen5 }));break;
            case 6: this.setState(prevState => ({listOpen6: !prevState.listOpen6 }));break;
            case 7: this.setState(prevState => ({listOpen7: !prevState.listOpen7 }));break;
            case 8: this.setState(prevState => ({listOpen8: !prevState.listOpen8 }));break;
            case 9: this.setState(prevState => ({listOpen9: !prevState.listOpen9 }));break;
            case 10: this.setState(prevState => ({listOpen10: !prevState.listOpen10 }));break;
			case 11: this.setState(prevState => ({listOpen11: !prevState.listOpen11 }));break;
            case 13: this.setState(prevState => ({listOpen13: !prevState.listOpen13 }));break;
            case 12: this.setState(prevState => ({listOpen12: !prevState.listOpen12 }));break;
            case 14: this.setState(prevState => ({listOpen14: !prevState.listOpen14 }));break;
            case 15: this.setState(prevState => ({listOpen15: !prevState.listOpen15}));break;
          } 
          this.state.prev_id = index;
          localStorage.removeItem("chargerId");
    }
    
      toggleMenuOperation(){
          localStorage.setItem('operation', 'operationMenu')
          localStorage.removeItem("operationId")
              }
    
      toggleList12(){
        this.setState(prevState => ({
            toggleList12: !prevState.toggleList12
        }))
      }

    componentDidMount(){
        var location = window.location.href;
        var id = location.split("/");
        
        if(id[3] == "dashboard"){
            this.setState({
                listOpen0: true
            })
        }
        if(id[3] == "csrLanding"){
            this.setState({
                listOpen11: true
            })
        }
        if(id[3] == "inventoryList"){
            this.setState({
                listOpen7:true
            })
        }
        if(id[3] == "cpPingPong"){
            this.setState({
                listOpen7:true
            })
        }
        
        // permission related code
        this.state.user.permissions=localStorage.getItem("roleAccess");
        this.state.choosenTenant = localStorage.getItem("choosen_tenant");

        if(this.state.choosenTenant=="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
            this.setState({
                disableMenu:"disabled-link"
            })
        }
        else{
              if(this.state.choosenTenant!="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
                this.setState({
                    disableMenu:""
                })
              }
        }

       this.state.user.permissions=localStorage.getItem("roleAccess");
        var fsd=localStorage.getItem("roleAccess");
        this.state.writePermission=hasPermission(this.state.user,['OCPPTags_R']);
                if(this.state.writePermission==true){
                    this.setState({
                    hideActionBtn:"inline-block"
                    })
                }

      this.getEnableBrokerStatus();
      this.getCustomization();
    }

        getEnableBrokerStatus(){
        
            let url = baseUrl.LoginPath +"/login/v1.0/enableInfo";  
             fetch(url,{
              method: "GET",
              headers: {
                    "content-type":"application/json",
                    "Authorization":localStorage.getItem("Authorization")
                  }
              })
              .then((resp) => {
                if(resp.status==401){
                  this.togglesession();
            
                  localStorage.clear();
                 
                }
                if(resp.status==200){
                  return resp.json();
                }
              }) 
              .then((response) =>{
                if(response!=undefined){
                    localStorage.setItem("broker",response.enableBroker);
                   }
              })
              .catch((error) => {
                //   console.log(error, "catch the loop")
              })
            
          }
          getCustomization=()=> {
            let tenant=localStorage.getItem('tenant');
            let url = baseUrl.LoginPath +"/api/admin/v1.0/account/tenant/customization?orgName="+tenant;
            
            fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
              }
            }).then(resp => {
            
              if (resp.status == 200) {
                return resp.json();
              }
            }).then(response => {
             this.setState({getOperationList:response})
            })
          } 
    renderHeader(){
        render(<Header shouldreset={true} />,document.getElementById('header'));
    }
    render() {
        const{listOpen0}  = this.state
        const{listOpen1} = this.state
        const{listOpen2} = this.state
        const{listOpen3} = this.state
        const{listOpen4} = this.state
        const{listOpen5} = this.state
        const{listOpen6} = this.state
        const{listOpen7} = this.state
        const{listOpen8} = this.state
        const{listOpen9} = this.state
		const{listOpen10} = this.state
		const{listOpen11} = this.state
        const{listOpen13} = this.state
        const{listOpen12} = this.state
        const{listOpen14} = this.state
        const{listOpen15} = this.state;


        const user = this.state.user



        let logOffAccount = localStorage.getItem("tenant")


        let getTenanatFord = localStorage.getItem("switchOrgName");
       if(getTenanatFord != "FORD" && getTenanatFord != null){
        localStorage.removeItem("enableFord")
       }

        let getTenant = localStorage.getItem("enableFord");

    if(getTenant == "ford" || getTenanatFord == "FORD" || logOffAccount == "ford"){
        this.state.inventoryDashboardDisplay="block";
            this.state.logsdisplay = "block";

             // condition for enablecronjob
             let cronJob = localStorage.getItem("cronJobStatus")
             if(this.state.enablecronjob == cronJob){
              this.state.logsdisplay = "block";
             }
             else {
                this.state.logsdisplay = "none";
          
              }
          }
          else {
            this.state.logsdisplay = "none";
      this.state.inventoryDashboardDisplay="none";
          }

        // condition for enableCSOperation
          let csOperation = localStorage.getItem("csOperation")
         if(this.state.enableCSOperation == csOperation){
            this.state.operationdisplay = "block";
           }
          else {
            this.state.operationdisplay = "none";

          }
           // condition for enableBroker
           let Broker = localStorage.getItem("broker")
          if(this.state.enableBroker == Broker){
            this.state.brokerdisplay = "block";
           }
          else {
            this.state.brokerdisplay = "none";
          }
        //   condition for connector enable
        let getConnectorStatus = localStorage.getItem("saveConnector")
        if(this.state.enableConnector == getConnectorStatus){
            this.state.connectorDisplay = "block";
        }
        else{
            this.state.connectorDisplay = "none";

        }
           // condition for enableVehicle
          let vehicle = localStorage.getItem("vehicle")
           if(this.state.enableVehicle == vehicle){
            this.state.vehicledisplay = "block";
           }
          else {
            this.state.vehicledisplay = "none";
          }

        
         
         if(localStorage.getItem("role") == "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
            this.state.dashboarddisplay = "none";
            this.state.brokerdisplay = "none";
            this.state.vehicledisplay = "none"
          }
          else{

            let Broker = localStorage.getItem("broker")
           if(this.state.enableBroker == Broker){
             this.state.dashboarddisplay = "block";
             this.state.brokerdisplay = "block";
            }
            else{
            this.state.dashboarddisplay = "block";
            this.state.brokerdisplay = "none";
        }

         }

        return (
            <>
            <div className="sidebar-main-div">
            <div id="slide-out" className="side-nav sn-bg-4">
                <ul className="custom-scrollbar ps ps--active-y">
                    <li>
                        <ul className="collapsible collapsible-accordion left-sidebar">
                            {
                                (this.state.getOperationList!=undefined)?
                                this.state.getOperationList.map((Operations, index) => {
                                    
                                    if(Operations.operation == "Dashboard" && (localStorage.getItem('tenant')=="ford" || localStorage.getItem('tenant')=="Ford")){
                                        return <li className="">
                                        <div className={ `collapsible-header waves-effect arrow-r panel-heading ${listOpen0 ? "active" : "" } `}  style={{paddingLeft: "0px"}} onClick={this.toggleList.bind(this, "w-dashboard.png", 0 )} >
                                          <span className="panel-title">
                                           <a data-toggle="collapse" href="#collapse15">
                                               {listOpen0 ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"}  className="menuicon_img" alt="" style={{marginRight:"11px"}} /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"}  className="menuicon_img" alt="" style={{marginRight:"11px"}} />} {this.props.t('Dashboard')} {listOpen0 ? <i className = "fas fa-chevron-right"/> : <i className = "fas fa-chevron-right rotate-icon"/>}
                                           </a>
                                           </span>
                                       </div>
                                       <div id="collapse15" className="panel-collapse collapse">
                                           <ul>
                                                {
                                                    Operations.subOperations.map((operation, i) => {
                                                        return (
                                                            <>
                                                                {(hasPermission(user, operation.permissions) || hasPermission({ permissions: ["allow"] }, operation.permissions)) && <li style={{ display: `${operation.access ? "block" : "none"}` }}><Link to={`/${operation.link}`} className="waves-effect">{this.props.t(operation.name)}</Link> {window['listOpen' + index] ? <i className="fas fa-chevron-right rotate-icon" /> : <i className="fas fa-chevron-right" />}</li>}
                                                            </>
                                                        )
                                                    })
                                                }
                                          </ul>
                                       </div>
                                   </li>
                                    }else if(Operations.operation == "Dashboard" && localStorage.getItem('tenant')!="ford" && localStorage.getItem("role") !="WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
                                        return <li style={{display: `${Operations.active? "block":"none"}`}} className={` ${listOpen0 ? "active" : ""} `} >
                                            <Link to="./dashboard" className="collapsible-header waves-effect" onClick={this.toggleList.bind(this, "w-dashboard.png", 0)}>
                                                {listOpen0 ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"}  className="menuicon_img" alt="" /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Dashboard.png"}  className="menuicon_img" alt="" />}
                                                {this.props.t('Dashboard')}
                                    </Link>
                                        </li>
                                    }
                                    else if(Operations.operation == "Dashboard" && localStorage.getItem('tenant')!="ford" && localStorage.getItem("role") =="WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
                                        return <></>
                                        }
                                      else if(Operations.operation == "Settings" && Operations.subOperations != undefined && Operations.subOperations.length > 0 && localStorage.getItem("role") !="WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") !="WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
                                            let showSettins=false;
                                            let booleanArray=[];
                                            Operations.subOperations.map((op,i)=>{
                                                booleanArray.push(op.access);
                                            });
                                            showSettins=booleanArray.some(ele => ele===true);
                                            let roles=false;
                                            if((localStorage.getItem("role") == "WEBASTO_SUPER_ADMIN_DEVOPS" || localStorage.getItem("role") == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE")){
                                                roles=true;
                                            }
                                            return (
                                                <li>
                                                <div className={`collapsible-header waves-effect arrow-r panel-heading ${window['listOpen'+index] ? "active" : "" }`} style={{ paddingLeft: "0px" }} onClick={this.toggleList.bind(this, "w-dashboard.png", index )}>
                                                    <span className="panel-title">
                                                        <a data-toggle="collapse" href={`#collapse${index}`}>
                                                        {window['listOpen'+index] ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/"+Operations.operation+".png"}  className="menuicon_img" alt="" style={{marginRight:"11px"}} /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/"+Operations.operation+".png"}  className="menuicon_img" alt="" style={{marginRight:"11px"}} />  }  {this.props.t(Operations.operation)} {window['listOpen'+index] ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-chevron-right"/>}
                                                        </a>
                                                    </span>
                                                </div>
                                                <div id={`collapse${index}`} className="panel-collapse collapse">
                                                    <ul>
                                                        {
                                                            Operations.subOperations.map((operation, i) => {
                                                                if(operation.link == "preferences" && (localStorage.getItem("role") == "WEBASTO_SUPER_ADMIN_DEVOPS" || localStorage.getItem("role") == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE")){
                                                                    return (<>
                                                                    <li><Link to={`/${operation.link}`} className="waves-effect">{this.props.t(operation.name)}</Link> {window['listOpen'+index] ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-chevron-right"/>}</li>
                                                                    </>)
                                                                }else{
                                                                    return (
                                                                        <>
                                                     {(hasPermission(user, operation.permissions) || hasPermission({permissions:["allow"]},operation.permissions) )&&<li style={{display: `${operation.access? "block":"none"}`}}><Link to={`/${operation.link}`} className="waves-effect">{this.props.t(operation.name)}</Link> {window['listOpen'+index] ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-chevron-right"/>}</li>}
                                                                        </>
                                                                        )
                                                                }
                                                            })
                                                         
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                            );
                                        }
                                        else if(Operations.operation == "Settings" && Operations.subOperations != undefined && Operations.subOperations.length > 0 && localStorage.getItem("role")=="WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") =="WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
                                        return<>
                                        </>
                                        
                                        }
                                            else if(Operations.operation=="Charging Session" && localStorage.getItem("role") !="WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") !="WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
                                            return <li style={{display: `${Operations.active? "block":"none"}`}} >
                                            <Link to="./transactions" className="collapsible-header waves-effect" onClick={this.toggleList.bind(this, "w-dashboard.png", 5)}>
                                                {true ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Charging Session.png"}  className="menuicon_img" alt="" /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Charging Session.png"}  className="menuicon_img" alt="" />}
                                        {this.props.t('Charging Session')}
                                    </Link>
                                        </li>
                                        }else if(Operations.operation=="Charging Session" && localStorage.getItem("role") =="WEBASTO_SUPERADMIN_FIRST_LEVEL" && localStorage.getItem("role") =="WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
                                            return<>
                                            </>
                                        }
                                        else if (Operations.subOperations != undefined && Operations.subOperations.length > 0) {
                                            let array=[];
                                            Operations.subOperations.map((op,i)=>{
                                                array.push(op.permissions[0]);
                                            });
                                            let show=false;
                                            let boolArray=[];
                                            Operations.subOperations.map((op,i)=>{
                                                boolArray.push(op.access);
                                            });
                                            show=boolArray.some(ele => ele===true);
                                            return (<>{(hasPermission(user, array) && show && Operations.active )&&
                                                <li className="">
                                                <div className={`collapsible-header waves-effect arrow-r panel-heading ${window['listOpen'+index] ? "active" : "" }`} style={{ paddingLeft: "0px" }} onClick={this.toggleList.bind(this, "w-dashboard.png", index )}>
                                                    <span className="panel-title">
                                                        <a data-toggle="collapse" href={`#collapse${index}`}>
                                                        {window['listOpen'+index] ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/"+Operations.operation+".png"}  className="menuicon_img" alt="" style={{marginRight:"11px"}} /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/"+Operations.operation+".png"}  className="menuicon_img" alt="" style={{marginRight:"11px"}} />  }  {this.props.t(Operations.operation)} {window['listOpen'+index] ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-chevron-right"/>}
                                                        </a>
                                                    </span>
                                                </div>
                                                <div id={`collapse${index}`} className="panel-collapse collapse">
                                                    <ul>
                                                        {
                                                            Operations.subOperations.map((operation, i) => {
                                                                return (
                                                                <>
                                                                {
                                                                    ((localStorage.getItem('tenant').toLowerCase()!="ford" && localStorage.getItem('tenant').toLowerCase()!="webasto") && (operation.name=="Execution History Logs" || operation.name=="Error Logs" || operation.name=="Audit Mail"))? <></>:
                                                                    (hasPermission(user, operation.permissions) || hasPermission({permissions:["allow"]},operation.permissions) )&&<li style={{display: `${operation.access? "block":"none"}`}}><Link to={`/${operation.link}`} className="waves-effect">{this.props.t(operation.name)}</Link> {window['listOpen'+index] ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-chevron-right"/>}</li>
                                                                }
                                             
                                                                </>
                                                                )
                                                            })
                                                         
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                            }</>)
                                            
                                    }else if(Operations.operation=="Notifications"){
                                        return <li style={{display: `${Operations.active? "block":"none"}`}} >
                                        <Link to="/notifications" className="collapsible-header waves-effect" onClick={()=>{this.toggleList.bind(this, "w-dashboard.png", 15 );this.renderHeader(); }}>
                                            {true ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Notifications.png"}  className="menuicon_img" /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Notifications.png"} className="menuicon_img" />}
                                            {this.props.t('Notifications')}
                                </Link>
                                    </li>
                                    }
                                    else{
                                        return (<>{(hasPermission(user, Operations.permissions))&&<li className={ ` ${window['listOpen'+index] ? "active" : "" } `} style={{display: `${Operations.active? "block":"none"}`}}>
                                                    <Link to={`/${Operations.link}`} className="collapsible-header waves-effect" onClick={this.toggleList.bind(this, "w-dashboard.png", index )}>
                                                    {window['listOpen'+index] ? <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/"+Operations.operation+".png"}  className="menuicon_img" alt="" /> : <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/"+Operations.operation+".png"}  className="menuicon_img" alt="" />  } 
                                                        {this.props.t(Operations.operation)}</Link>
                                                </li>}</>)
                                    }
                                    
                                }): null
                            }
                        </ul>
                    </li>
                    {/*
                    <!-- Side navigation links -->*/}
                </ul>
                <div className="sidenav-bg mask-strong"></div>
            </div>
        </div>
        </>
    // <!-- end Sidebar navigation -->
        )
    }
}

export default withTranslation()(Sidebar);
