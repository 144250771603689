import React from 'react';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import {Form,Table,Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import $, { get } from "jquery";
import Pagination from "react-js-pagination";
import {Link} from 'react-router-dom';
import Header from './header';
import { createBrowserHistory } from 'history';
import { hasRole, hasPermission } from './auth';
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';
import servicesUrl from '../common/servicesUrl';
const history = createBrowserHistory();


class organizationList extends React.Component {  
    constructor (props) {
        super(props);
       
        this.state = {
          selectFindStatus : false,
          fields: { organisation:''},
          getOrgList:[],
          activePage: 1,
          active:"active",
          activeArray: [],
          checked:false,
          activeDeactiveArray:'',
          list: [],
          cpid1:'',
          dataArray:[
          ],
        noOfRecords:'',
        data:new Map(),
        pageSize:"10",
        page_title:"Organization",
        modalsession:false,
        errors: {},
        user:{
          permissions: [
               
              ]
          }
        
   }
   this.showEntriesChange=this.showEntriesChange.bind(this);
   this.togglesession = this.togglesession.bind(this);
   this.organizationChange = this.organizationChange.bind(this);
   this.handleChange = this.handleChange.bind(this)
   this.addNew = this.addNew.bind(this);
   this.activeorgnization = this.activeorgnization.bind(this);
   this.redirectToListPage = this.redirectToListPage.bind(this);
   this.tagOk1 = this.tagOk1.bind(this);
   this.toggle2 = this.toggle2.bind(this);
   this.tagOk2 = this.tagOk2.bind(this);
   this.toggle1 = this.toggle1.bind(this);
   this.tagOk3=this.tagOk3.bind(this);
   this.toggle3=this.toggle3.bind(this);
   this.onChangeChargepoint=this.onChangeChargepoint.bind(this);
   this.changeHandler = this.changeHandler.bind(this);
   this.handleClick = this.handleClick.bind(this);
      this.Focus = this.Focus.bind(this);
   this.keyPress = this.keyPress.bind(this);




}
handleClick(){

 
  let fields = this.state.fields;
  fields.organisation='';
  this.state.errors["entercharge"] = " "
  $("#notexists").text(" ");
  this.setState({
fields
  });
  this.state.pageSize=10;
        this.state.activePage=1;
        document.getElementById("showEntries").value=10;
  this.getOrganizationList(this.state.pageSize,this.state.activePage);


}
Focus(){
 
 
//   let fields = this.state.fields;
//   fields.organisation='';

//   this.setState({
// fields
//   });
//   this.getOrganizationList(this.state.pageSize,this.state.activePage);



}
changeHandler(field, e){ 

  let fields = this.state.fields;
  fields[field] = e.target.value; 
  fields.chargePointid = document.getElementById('organisation')

  this.setState({fields});
  this.state.errors["entercharge"] = ""
  $("#notexists").text(" ");
  }


 //change function for chargepoint status filter
 onChangeChargepoint() {
  this.state.selectFindStatus = true;
  this.state.activeArray = "";
  var chargePoint = document.getElementById("organisation").value;
  $("input[type='checkbox']").prop("checked", false);
  this.setState({ organisation: chargePoint }, () => {
    this.getOrganizationList(this.state.pageSize,this.state.activePage);
    });
    if(this.state.fields.organisation == ""){
      this.state.errors["entercharge"] =this.props.t('enter_org')
      $("#notexists").text(" ");
    
    }else{
      this.state.errors["entercharge"] = " "
      this.getOrganizationList(this.state.pageSize,this.state.activePage,"click");
    }
}
keyPress(e){

  var key = e.key;
  if(e.keyCode == 13){
    this.state.selectFindStatus = true;
  this.state.activeArray = "";
  $("input[type='checkbox']").prop("checked", false);
      this.setState({ chargePointId: e.target.value });
      this.getOrganizationList(10,1);
      if(this.state.fields.organisation == ""){
        this.state.errors["entercharge"] = this.props.t('enter_org')
        $("#notexists").text(" ");
      
      }else{
        this.state.errors["entercharge"] = " "
        this.getOrganizationList(this.state.pageSize,this.state.activePage,"enter");
      }
    }

    if( key == "Backspace" || key == "Delete" ){
    
    var x=e.target.value
    var sno=x.slice(0,x.length-1)
    if(sno==""){
 
     this.state.fields.organisation=sno;

    this.getOrganizationList(10,1);
    }
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
      break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
      var key = e.key;
      if (!regex.test(key)) {
      
          e.preventDefault();
  
          return false;
       
         
      }
      break;
    
  }
}
organizationChange(){
  this.state.selectFindStatus = true;
  this.state.activeArray = "";
  var x = document.getElementById("organisation").value;
  let fields = this.state.fields;
  fields["organisation"] = x;
  this.setState({organisation: x}, () => {
    this.getOrganizationList(this.state.pageSize,this.state.activePage);

   });
}
async togglesession(){
 
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}


handlePageChange(pageNumber) {
 
  if(pageNumber !=this.state.activePage){
    var data=this.state.data;
    var pageData=data.get(pageNumber);
    if(pageData==undefined){
  this.setState({activePage: pageNumber});
  this.getOrganizationList(this.state.pageSize,pageNumber);
    }else{
      this.setState({activePage: pageNumber});
      this.setState({dataArray:pageData})
    }
  }
}

redirectToListPage(){
  this.props.history.push('/chargepoint')
}


toggle1(){
  this.setState({
    modal1: !this.state.modal1
  });
}
toggle2 = () => {
  this.setState({
    modal2: !this.state.modal2
  });
}
toggle3 = () => {
  this.setState({
    modal3: !this.state.modal3
  });
}
tagOk1(){
  window.location.reload();
}
tagOk2(){
  window.location.reload();
 }
 tagOk3(){
  this.setState({
    modal3: !this.state.modal3
  });
 }
showEntriesChange(){
  var entries=document.getElementById("showEntries").value;
  // this.state.data.clear();
  // this.setState({pageSize:entries});
  this.state.pageSize=entries;
  this.state.activePage=1;
  this.getOrganizationList(entries,1);
}

getOrganizationList(pageSize,pageNo, action){
  
  this.state.user.permissions = localStorage.getItem("roleAccess");

  //  to get all orgnization 
  if( this.state.selectFindStatus == true){

  var url = baseUrl.URLPath + "admin/v1.0/account/search?pageSize="+pageSize+"&pageNo="+1+"&orgName="+this.state.fields.organisation;

  }
  else{
    var url = baseUrl.URLPath + "admin/v1.0/account/search?pageSize="+pageSize+"&pageNo="+pageNo+"&orgName="+this.state.fields.organisation;
  }
  fetch(url,{
    method: "GET",
    headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
    })
    .then((resp) => {
    
      if(resp.status==401){
        // alert("Access token is expired, please login again")
        this.togglesession();
  
        localStorage.clear();
       
      }
      if(resp.status==200){
        return resp.json();
      }
    }) 
    .then((response) =>{
   
      if(response!=undefined){
        if(response.count.count==0){
          if(action=="click" || action=="enter"){ 
            $("#notexists").text(this.props.t('organisation_not_exists'));
                            }

        }else{
    this.setState({
       dataArray:response.organizationList,
       noOfRecords:response.count.count,

      })
      if(action=="click" || action=="enter"){ 
        $("#notexists").text(" ");
                        }
      }
    }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}
componentDidMount(){  
   var url = baseUrl.URLPath + "admin/v1.0/account/all";
fetch(url,{
  method: "GET",
  headers: {
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
  })
  .then((resp) => {
 
    if(resp.status==401){
      // alert("Access token is expired, please login again")
      this.togglesession();

      localStorage.clear();
     
    }
    if(resp.status==200){
      return resp.json();
    }
  }) 
  .then((response) =>{

   
    if(response!=undefined){
  this.setState({ getOrgList:response.organisationDTOS })
    }
  })
  .catch((error) => {
      console.log(error, "catch the loop")
  })


  this.getOrganizationList(this.state.pageSize,this.state.activePage);


    }
    
    // calling function when click on checkbox
      handleChange(id,identifier,event){

   
      let arr = [];
      var newlist = [];
      var checkedValue = document.getElementById(id).checked;
        if(checkedValue==true){
        // if(this.state.list[0]!="" && this.state.list[0]!= undefined ){
        //   document.getElementById(this.state.list[0]).checked = false;
        //   document.getElementById(id).checked = true;
        // }
        this.state.list[0] = id ; 
        this.setState({
          activeArray:identifier
          });

      }
      if(checkedValue==false){
        this.state.list[0] = "" ; 
        document.getElementById(id).checked = false;
        this.setState({
          activeArray:""
        });
      }
        this.state.activeDeactiveArray = identifier;
      }
      addNew(){
        this.props.history.push('/organisationAdd');
    }

    // calling function when click on active deactive button
    activeorgnization(status){

 if(this.state.activeArray!="" && this.state.activeArray.length != "0" ){
   if(status==true){
    var url = baseUrl.URLPath + servicesUrl.activateAdminAccount + "?tenant="+this.state.activeArray;
   }else{
    var url = baseUrl.URLPath + servicesUrl.deactivateAdminAccount + "?tenant="+this.state.activeArray;

   }
   
  // let data = {"id":this.state.activeArray, "active":status}; 
  fetch(url,{
     method: "GET",
     //body: JSON.stringify(data), // data can be `string` or {object}!
     headers: {
      "content-type":"application/json",
      "Authorization":localStorage.getItem("Authorization")
     }
     })
     .then((resp) => {
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }else{
        return resp.json();
      }
     }) 
     .then((response) =>{
      if(status==0){
          this.toggle1()
     
       }else{
         this.toggle2()
       }
    })
    .catch((error) => {
         console.log(error, "catch the loop")
   })
   }else if(this.state.activeArray=="" && this.state.activeArray.length == "0" ){
     this.toggle3()
   }
}




render() {
  const{t}=this.props
  let user = this.state.user;
const active = this.props.active;


const orgList =this.state.getOrgList.map((getOrgList, index) => {
  return(
    <option value={getOrgList.company}>{getOrgList.company}</option>
  )
 
})
let data="";
if (this.state.dataArray) {
 data=this.state.dataArray.map((dataArray, index) => {

  var orgdata={
    company:dataArray.company,id:dataArray.id
  }
 
  if(dataArray.active==false || dataArray.active=="Inactive"){
    dataArray.active = "Inactive";
  }else{
    if(dataArray.active==true){
      dataArray.active = "Active";
    }
  }

  if(dataArray.address != null){
    return(
      <tr>
        <td className="text-center"> 
       <Form.Check 
              custom
              type = "checkbox"
              id={`custom-${dataArray.id}`}
              //checked = {false}
              onChange = { this.handleChange.bind(this,`custom-${dataArray.id}`, dataArray.identifier )}
              key={dataArray.id}
              label = "" />
              </td>
         <td className="">
            <Link to={{
              pathname:'/organisationView', 
              state:dataArray.id, 
              }}>
              { dataArray.company }
            </Link>
            </td>
            <td className="">{ dataArray.officeAddress}</td>
            <td className="">{ dataArray.city}</td>
            <td className="">{ dataArray.state}</td>
            <td className="">{ dataArray.country}</td>
            <td className="">{dataArray.url}</td>
            <td className="">{dataArray.active}</td>
       </tr>
       );
  }else{
    return(
      <tr>
        <td className="text-center"> 
          <Form.Check 
              custom
              type = "checkbox"
              id={`custom-${dataArray.id}`}
              //checked = {false}
              onChange = { this.handleChange.bind(this,`custom-${dataArray.id}`, dataArray.identifier)}
              key={dataArray.id}
              label = "" />
              </td>
         <td className="">
            <Link to={{
              pathname:'/organisationView', 
              state:dataArray.id, 
              }}>
              { dataArray.company }
            </Link>
            </td>
            <td className="">{ dataArray.identifier}</td>
            <td className="">{ dataArray.officeAddress}</td>
            <td className="">{ dataArray.city}</td>
            <td className="">{ dataArray.state}</td>
            <td className="">{ dataArray.country}</td>
            <td className="">{dataArray.url}</td>
            <td className="">{dataArray.active}</td>
       </tr>
      // <></>
       );
    }
  })

}


  return (
    <>
   
 
<main class="content-div" >
<p>{t('Organization')}
              <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page"> {t('Organization')}</span>
              </div>
           </p>
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div"> Settings > Organization </div> */}
          {/* page title */}
         
          {/* end page title */}
          {/* page menu bar */}
            
          <div className="row menubar">
            
          <div className="col-md-12 ">
                <div className="">
                {hasPermission(user, ['Organizations_W']) &&  <button onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
  </button> }
                  {/* <button>
                    <i className="fas fa-pencil-alt">
                    </i>
                    <span>Edit</span>
                </button> */}
                  {/* <button onClick={this.deleteAll}>
                    <i className="fas fa-trash">
                    </i>
                    <span>Delete</span>
                  </button> */}
                  {hasPermission(user, ['Organizations_W']) && 
                  <button onClick={this.activeorgnization.bind(this,true)}>
                    <i className="fas fa-toggle-on">
                    </i>
                    <span>{t('active')}</span>
                  </button>}
                  {hasPermission(user, ['Organizations_W']) && 
                  <button  onClick={this.activeorgnization.bind(this,false)}>
                    <i className="fas fa-toggle-off">
                    </i>
                    <span>{t('deactive')}</span>
                  </button>}
                  <div className="pull-right">
                     <label className="sub-text pull-left" >{t('show_entries')}</label>
                      <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                        <Form.Control as="select" className="showentry_sel custom_selectBox"  id="showEntries" onChange={this.showEntriesChange}> 
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>40</option>
                          <option>50</option>
                        </Form.Control>
                    </Form.Group>                 
                </div>

                
              </div>
            </div>
          </div>
          {/* end page menu bar  */}
          <div className="row">
          {/* <MDBCol md="3" >
          <select className="browser-default custom-select select_height mb_8" id="organisation" onChange={this.organizationChange.bind(this)}  >                          
                          <option value="">-- Select Organization--</option>
                          {orgList}
          </select>
     </MDBCol> */}
        <MDBCol md="4"  className="mt-2" >
        <div class="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <input type="text" id="organisation" onKeyDown={this.keyPress} value={this.state.fields["organisation"]} style={{ width: "70%" }} onChange={this.changeHandler.bind(this, "organisation")} class="form-control input-searchBox pad-2" placeholder={t('Organization')} name="adminAddress" onFocus={this.Focus} />
                <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick} >
                  <span aria-hidden="true">×</span>
                </button>
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                </div>
              </div>
              <span className="error_msg w3-animate-top mt-2">  <span style={{color: "red"}}>{this.state.errors["entercharge"]}</span></span> 
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
     
                    </MDBCol>
          </div>
          
          {/* table start */}
         <div className="row ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
                <tr>
                <th></th> 
                    <th className="">{t('Organization')}</th> 
                    <th className="">{t('short_name')}</th> 
                    <th className="">{t('office_add')}</th>
                    <th className="">{t('city')}</th>
                    <th className="">{t('state')}</th>
                    <th className="">{t('country')}</th>
                    <th className="">{t('url')}</th>
                    <th className="">{t('chargingSessionState')}</th>
                </tr>
            </thead>
            <tbody>
                 {data}
            </tbody>
          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right">
             <Pagination
                 hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pageSize}
                totalItemsCount={this.state.noOfRecords}
                onChange={this.handlePageChange.bind(this)}
            />
          </div>
          {/* End table  */}
          </div>
          </div>
          <MDBModal isOpen={this.state.modal3}  size="md" className="model_top"  backdrop="static" data-backdrop="static" data-keyboard="false">
            <MDBModalHeader toggle={this.toggle3}>{t('warning')}</MDBModalHeader>
            <MDBModalBody>
            {t('select_organization')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
              <button onClick={this.tagOk3} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal1}  size="md" className="model_top"  backdrop="static" data-backdrop="static" data-keyboard="false">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
            {t('org_deactivate_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
              <button onClick={this.tagOk1} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal2}  size="md" className="model_top"  backdrop="static" data-backdrop="static" data-keyboard="false">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
            {t('org_activate_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="primary" onClick={this.tagOk2}>OK</MDBBtn> */}
              <button onClick={this.tagOk2} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button onClick={this.sessiontagOk} className="btn_primary" type="button" data-test="button">{t('ok')}</button>
      {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
      </MDBModalFooter>
      </MDBModal>
      </main>
      </>
  );
}
}

export default withTranslation()(organizationList);