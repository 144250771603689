import React from 'react';
import { Link } from 'react-router-dom';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Form, Table, Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Header from './header';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { hasRole, hasPermission } from './auth';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import $, { get } from "jquery";
import servicesUrl from '../common/servicesUrl';
import { t } from 'i18next';



const history = createBrowserHistory();


class Ocpptags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idTagSorting: false,
      selectFindStatus: false,
      saveSelectedIdTag: "",
      idTags: "",
      selectDataArray: [],
      options: [],
      intial_data: [],
      multiple: false,
      list: [],
      activeArray: [],
      model1: "false",
      model2: "false",
      model3: "false",
      page_title: "Charge Point OCPP Tags",

      dataArray: [
        {
          idTag: '',
          parentIdTag: { idTag: '' },
          expiryDate: '',
          transaction: "",
          blocked: "",


        }
      ],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      modalsession: false,
      user: {
        permissions: [

        ]
      },
      errors: {},

    }
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.addNew = this.addNew.bind(this);
    this.redirectViewPage = this.redirectViewPage.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.tagOk1 = this.tagOk1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.tagOk2 = this.tagOk2.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.tagOk3 = this.tagOk3.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.timeChange = this.timeChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.idTagChange = this.idTagChange.bind(this);
    this.onClickFindbtn = this.onClickFindbtn.bind(this);
    this.getIdTags = this.getIdTags.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.sortingExpiryTime = this.sortingExpiryTime.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);

  }
  getIdTags(event) {

    var idTags = event.target.value;
    this.setState({
      idTags: idTags
    })
    if (idTags.length == 0) {
      this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag)
      this.setState({
        dataArray: this.state.dataArray,
      });
    }
    var errors = {};
    errors["idTagNotExists"] = "";
    this.setState({ errors: errors });
  }

  ClearChargepoint(ref, event) {

    this.state.selectFindStatus = false;

    var errors = {}
    this.state.errors["entercharge"] = "";
    $("#notexists").text(" ");
    this.state.saveSelectedIdTag = "";
    this.state.pageSize = 10;
    this.state.activePage = 1;
    document.getElementById("showEntries").value = 10;
    this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag)
    this.setState({
      dataArray: this.state.dataArray, errors: errors
    });
    ref.current.clear()
    // this.typeahead.current.state.selected= []
    this.setState({
      idTags: ""
    })
  }

  async togglesession() {

    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    // this.state.data.clear();
    // this.setState({pageSize:entries});
    this.state.activePage = 1;
    this.state.pageSize = entries;
    this.getOCPPTags(this.state.pageSize, this.state.activePage);
  }
  addNew() {
    this.props.history.push('/ocppNewTag');
  };

  toggle1(event) {
    this.setState({
      modal1: !this.state.modal1
    });
    event.preventDefault();
    if (this.state.list[0] != "" && this.state.list[0] != undefined) {


      this.setState({
        delete_msg: this.props.t('are_you_want_to_delete'),
        deleteHeader: this.props.t('confirmation'),
        deleteButton: "",
        deleteText: this.props.t('close'),
        deletecolor: "danger"
      })
    } else {

      this.setState({
        delete_msg: this.props.t('please_select_ocpp_tag'),
        deleteHeader: this.props.t('warning'),
        deleteButton: "d-none",
        deleteText: this.props.t('ok_label'),
        deletecolor: "primary"
      })
    }

  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }
  toggle3 = () => {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  tagOk1() {

    //this.props.history.push('/Ocpptags');
  }
  tagOk3() {
    setTimeout(window.location.reload(), 3000);
    this.props.history.push('/Ocpptags');
    //this.props.history.push('/Ocpptags');
  }
  tagOk2() {
    //alert("gvjk");
    setTimeout(window.location.reload(), 3000);
    this.props.history.push('/Ocpptags');
  }
  handlePageChange(pageNumber) {


    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getOCPPTags(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }
  componentDidMount() {

    this.state.user.permissions = localStorage.getItem("roleAccess");
    this.getOCPPTags(this.state.pageSize, this.state.activePage);
    $("#nochargeexists").hide();
  }

  getOCPPTags(pageSize, pageNo, action) {
    
    var timeZone = localStorage.getItem("timezone");
    if (this.state.selectFindStatus == true) {
      var url = baseUrl.URLPath + "ocpptags/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&idTag=" + this.state.saveSelectedIdTag;

    }
    else {
      var url = baseUrl.URLPath + "ocpptags/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&idTag=" + this.state.saveSelectedIdTag;

    }
    //localhost:8080/cpms/ocpptags/search?pageSize=&pageNo=
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),

        "timeZone": timeZone

      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {
          if (response.count.count == 0) {
            if (action == "click" || action == "enter") {
              $("#notexists").text(this.props.t('ocpp_tag_not_exists'));
            }

          } else {

            this.setState({
              dataArray: response.ocppTagList,
              selectDataArray: response.ocppTagList,
              noOfRecords: response.count.count,

            });
            if (action == "click" || action == "enter") {
              $("#notexists").text(" ");
            }




            var option = [];
            for (var i = 0; i < this.state.dataArray.length; i++) {
              option.push(this.state.dataArray[i].idTag);

            }

            this.setState({ options: option });
          }
        }
      })
      .catch((error) => {
        alert(error);
        console.log(error, "catch the loop")
      })
  }

  redirectViewPage(id, e) {
    var url = "/viewTag/" + id;
    this.props.history.push(url);
  }


  // search filter 
  handleKeyDown(ref, e) {

    var serial_no = e.target.value;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
    var getList = this.state.intial_data;
    if (serial_no != 0) {
      this.state.errors["entercharge"] = "";
    }

    if (key == "Backspace" || key == "Delete") {
      this.state.errors["entercharge"] = "";
      $("#notexists").text("");
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {

        this.state.saveSelectedIdTag = sno;

        this.getOCPPTags(this.state.pageSize, this.state.activePage)
      }
    }
    var sno = serial_no.slice(0, serial_no.length - 1)
    this.getSearchInventoryList(sno)
    if (e.keyCode == 13) {

      this.FindCpName(ref, e);
      $("#rbt_id").hide();
    }
    if ((e.keyCode == 86 && ctrl)) {

      this.getSearchInventoryList(serial_no)
    }
    if (serial_no.length >= 0) {
      if (serial_no.length > 1) {
        this.getSearchInventoryList(serial_no)
      }
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;
        }
        break;

    }
  }

  getSearchInventoryList(Idtag) {
    ;
    var cp_id = [];
    var url = baseUrl.URLPath + "ocpptags/search/partial?idTag=" + Idtag;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        ;
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        
        if (response != undefined) {
          var option = [];
          var data = [];
          this.setState({
            options: response,
            intial_data: response
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }


  handleChange(id, cpid, event) {


    // event.preventDefault();
    let arr = [];
    var newlist = [];
    var checkedValue = document.getElementById(id).checked;

    if (checkedValue == true) {
      if (this.state.list[0] != "" && this.state.list[0] != undefined) {
        document.getElementById("custom-" + this.state.list[0]).checked = false;
        document.getElementById(id).checked = true;

      }
      this.state.list[0] = cpid;
      this.setState({
        // deleteList: [ ...this.state.deleteList, cpid], 
        activeArray: this.state.list
      });
      // [].concat(arr)
    }

    if (checkedValue == false) {
      this.state.list[0] = "";
      document.getElementById(id).checked = false;
    }
    // else{
    //   this.state.list.splice(this.state.list.index,1);
    //   this.setState({

    //     activeArray:this.state.list
    //     });
    // }

    //this.deleteAll(arr);
    this.state.activeDeactiveArray = cpid;

    // this.setState({ activeDeactiveArray:this.state.cpid1 })
  }

  handleDelete(event) {
    this.setState({
      modal1: !this.state.modal1
    });
    event.preventDefault();

    var url = baseUrl.URLPath + servicesUrl.getOcpptag +"?ocppTagId=" + this.state.activeArray;
    //localhost:8080/cpms/ocpptags/{ocppTagId}
    var data = this.state.activeArray;
    fetch(url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),

      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        else if (resp.status == 400) {
          //  alert("this id have the child we cant delete this") 
          // document.getElementById("warning").text = "hello";
          this.toggle3()

        }
        else {
          this.toggle2()
        }
      })

      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  timeChange = date => {
    this.setState({ startDate: date });
    //var tempDate = new Date(date);

    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;


    this.setState({ startDate1: createdDateTo });
  };




  //   getting Idtag based on search filter

  idTagChange(idTag) {
    this.state.selectFindStatus = true;

    this.state.saveSelectedIdTag = idTag;
    this.setState({ saveSelectedIdTag: idTag });


    // if(idTag.length==0){
    //     this.setState({selectDataArray:this.state.dataArray});
    //   }else{
    //     var selectDataArray =  this.state.selectDataArray;
    //     console.log(idTag);
    //     var selectedId = [];
    //     for(var i = 0; i < this.state.dataArray.length; i++)
    //     {
    //       if(this.state.dataArray[i].idTag == idTag){
    //         selectedId.push(this.state.dataArray[i]);
    //       }

    //     }

    //     this.setState({selectDataArray:selectedId});
    //     var errors=this.state.errors;
    //    errors["idTagNotExists"] = "";
    //    this.setState({errors:errors});
    //   }

    if (idTag.length == 0) {

      this.getOCPPTags(this.state.pageSize, this.state.activePage)
      this.setState({
        dataArray: this.state.dataArray
      });
    } else {
      let errors = {};
      errors["idTagNotExists"] = "";
      //  errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      });
      // this.onClickFindbtn(idTag);
      this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag);

    }

  }


  FindCpName(ref, event) {
    
    this.state.saveSelectedIdTag = ref.current.inputNode.value;
    this.state.selectFindStatus = true;
    this.state.pageSize = 10;
    this.state.activePage = 1;
    this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag);

    if (this.state.saveSelectedIdTag == null || this.state.saveSelectedIdTag == "") {
      this.state.errors["entercharge"] = this.props.t('select_ocpp_tags');
      $("#notexists").text(" ");
    }
    else {
      this.state.errors["entercharge"] = " ";
      this.state.pageSize = 10;
      this.state.activePage = 1;
      this.getOCPPTags(this.state.pageSize, this.state.activePage, "click");



    }
    if (this.state.selectDataArray.length == 0) {
      if (this.state.saveSelectedIdTag == null || this.state.saveSelectedIdTag == "") {
        let errors = {}
        errors["entercharge"] = this.props.t('select_ocpp_tags');

        this.setState({ errors: errors });
      } else {
        let errors = {}
        errors["entercharge"] = " ";

        this.setState({ errors: errors });
      }
    }

  }

  onClickFindbtn(idTags) {

    if (idTags == null || idTags == "") {

    } else {

      let errors = {};
      var url = baseUrl.URLPath + servicesUrl.getOcpptagsByIdtag + "?idTag=" + idTags;
      //localhost:8080/api/ocpptags/getbyidtag/{idTag}
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {

          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
          if (resp.status == 404) {
            errors["idTagNotExists"] = this.props.t('ocpp_tag_not_exists');
            this.setState({
              errors: errors
            });
          }

        })
        .then((response) => {

          if (response != undefined) {
            this.setState({ selectDataArray: [] });
            //    this.setState({selectDataArray: response})
            var dataArr = [];
            dataArr.push(response);
            this.setState({ selectDataArray: dataArr });
            // this.setState({filterData:dataArr});
            //console.log("filterdate: " +this.state.filterData);
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    }

  }

  //function for sorting table data on click
  ascendingOrder() {
    if (this.state.idTagSorting == false) {
      this.state.selectDataArray.sort(this.dynamicSort("idTag"));
      this.state.idTagSorting = true;
      this.setState({ selectDataArray: this.state.selectDataArray.sort(this.dynamicSort("idTag")) })
    }
    else {
      this.state.selectDataArray.sort(this.dynamicSort("idTag"));
      this.state.idTagSorting = false;
      this.setState({ selectDataArray: this.state.selectDataArray.reverse() });
    }
  }
  sortingExpiryTime() {
    if (this.state.idTagSorting == false) {
      this.state.selectDataArray.sort((a, b) => new Date(...a.expiryDate.split('/').reverse()) - new Date(...b.expiryDate.split('/').reverse()));
      this.state.idTagSorting = true;
      this.setState({ selectDataArray: this.state.selectDataArray.sort((a, b) => new Date(...a.expiryDate.split('/').reverse()) - new Date(...b.expiryDate.split('/').reverse())) })
    }
    else {
      this.state.selectDataArray.sort(this.dynamicSort("idTag"));
      this.state.idTagSorting = false;
      this.setState({ selectDataArray: this.state.selectDataArray.sort((a, b) => new Date(...a.expiryDate.split('/').reverse()) - new Date(...b.expiryDate.split('/').reverse())) })
      this.setState({ selectDataArray: this.state.selectDataArray.reverse() });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {

        return a[property].localeCompare(b[property]);
      }
    }
  }

  render() {
    const{t}=this.props
    let user = this.state.user;
    const ref = React.createRef();


    const data = this.state.selectDataArray.map((dataArray, index) => {

      let tags = "";
      var stringDate = dataArray.expiryDate;
      if (dataArray.expiryDate == null || dataArray.expiryDate == "") {
        var date = "";
        var date1 = "";
      } else {
        var date = moment(stringDate).format("DD MMM YYYY");
        var date1 = moment(stringDate).format("HH:mm");

      }

      if (dataArray.blocked == 1) {
        dataArray.blocked = "Yes";
      } else {
        if (dataArray.blocked == "0") {
          dataArray.blocked = "No";
        }
      }
      if (dataArray.inTransaction == 1) {
        dataArray.inTransaction = "Yes";
      } else {
        if (dataArray.inTransaction == "0") {
          dataArray.inTransaction = "No";

        }
        // dataArray.inTransaction = "No";
      }
      if (dataArray.parentIdTag == null) {
        tags = '';
      } else {
        tags = dataArray.parentIdTag.idTag;
      }

      return (
        <tr>
          <td className="text-center">
            <Form.Check
              custom
              type="checkbox"
              id={`custom-${dataArray.id}`}
              onChange={this.handleChange.bind(this, `custom-${dataArray.id}`, dataArray.id)}
              label=""
            />
          </td>
          <td>
            <Link to={{
              pathname: '/viewTag',
              state: dataArray.id,
            }}>{dataArray.idTag}</Link>
            {/* <a onClick={this.redirectViewPage.bind(this,dataArray.id )} >{ dataArray.idTag }</a> */}
          </td>
          {/* <td className="">{tags}</td> */}
          <td className="wrap-word">{date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>} </td>
          {/* <td className="">{dataArray.inTransaction}</td>
           <td className="">{dataArray.blocked}</td> */}
        </tr>
      )

    })
    return (
      <>
        <main class="content-div" >
          <p> {t('Authorization')}
            <div className="breadcrumb_div">
              {t('charge_point')} &gt; <span className="breadcrumb_page"> {t('Authorization')}</span>
            </div>
          </p>
          <div className="page-outerdiv">

            <div className="row menubar">
              <div className="col-md-12 ">
                <div className="">
                  {this.props.ChargePoint_W && <button type="button" data-test="button" onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
                  </button>}
                  {this.props.ChargePoint_W && <button type="button" data-test="button" onClick={this.toggle1}>
                    <i className="fas fa-trash">
                    </i>
                    <span>{t('delete')}</span>
                  </button>}
                  {/* select dropdown for Table */}
                  <div className="pull-right">
                    <label className="sub-text pull-left">{t('show_entries')}</label>
                    <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                      <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>


            {/* end page menu bar  */}
            <div className="row">
              <div className="col-md-4  pull-right">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    id="rbt_id"
                    minLength={3}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    placeholder={t('choose_ocpp_tag')}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.idTagChange.bind(this)}
                    onBlur={this.getIdTags}
                    ref={ref}
                  />
                  <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)}  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                </div>
              </div>
            </div>


            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="text-center"></th>
                      <th>{t('idTag')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('expiry_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>

              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

              {/* End table  */}

            </div>
          </div>
          <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" onClick={this.toggle1} className="btn_primary">{this.state.deleteText}</button>
              <button type="button" data-test="button" onClick={this.handleDelete} className={`btn_primary ${this.state.deleteButton}`}>{t('yes')}</button>
            </MDBModalFooter>
          </MDBModal>


          <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
             {t('id_tag_successfully_deleted')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" onClick={this.tagOk2} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle3}>{t('warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('delete_other_associated_tags_before_deleting_id_tag')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.toggle2}>Close</MDBBtn> */}
              <button type="button" data-test="button" onClick={this.tagOk3} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default withTranslation()(Ocpptags)