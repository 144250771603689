import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter,MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink} from 'mdbreact';
import {Form,Table,Check } from 'react-bootstrap';
import Header from './header';
import $, { get } from "jquery";
import { createBrowserHistory } from 'history';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { hasRole, hasPermission } from './auth';
import { withTranslation } from 'react-i18next';
import {Typeahead} from 'react-bootstrap-typeahead';


const history = createBrowserHistory();


class chargingSchedule extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          saveSelectedSchedule:"",
          intial_data:[],
          selectFindStatus: false,
          errors: {},
          cpIdSorting:false,
          multiple:false,
          options:[],
          selectDataArray:[],
          page_title:"Charging Schedule",
          activePage: 1,
          noOfRecords:'',
          data:new Map(),
          pageSize:"10",
          modalsession:false,
          user:{
            permissions: [
                 
                ]
            },
          dataArray:[
                      {
                        "duration":"",
                        "chargingScheduleName": "",
                        "startSchedule":"",
                        "schedulingUnit":"",
                        "minChargingRate":"",
                        "chargingSchedulePeriod": [
                                                    {
                                                      "startPeriod":"1",
                                                      "limit":"1",
                                                      "numberPhases":"1"
                                                    }
                                                 ]
                      }
                  ],
        }
        this.slideDownChildRow = this.slideDownChildRow.bind(this);
        this.addNew = this.addNew.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.showEntriesChange=this.showEntriesChange.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.FindCpName=this.FindCpName.bind(this);
        this.handleKeyDown=this.handleKeyDown.bind(this);
        this.chargingScheduleChange=this.chargingScheduleChange.bind(this);
        this.getScheduleNames=this.getScheduleNames.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);



}
getScheduleNames(event){
 
  ;
  var crgProfiles = event.target.value;
  this.setState({
    cpProfiles:crgProfiles
  })
  if(crgProfiles.length==0){
     this.getChargingSchedule(this.state.pageSize,this.state.activePage,this.state.saveSelectedSchedule)
     this.setState({
       items:this.state.items,
     });
   }
   var errors={};
   errors["idTagNotExists"] = "";
   this.setState({errors:errors});
 }
chargingScheduleChange(chargingScheduleName){
 
  this.state.selectFindStatus = true;
  this.state.saveSelectedSchedule = chargingScheduleName;
  this.setState({saveSelectedSchedule:chargingScheduleName});
if(chargingScheduleName.length==0){
  this.getChargingSchedule(this.state.pageSize,this.state.activePage)
  this.setState({
    items:this.state.items
  });
}else{
  let errors = {};
  errors["idTagNotExists"] = "";
    this.setState({
        errors:errors
    }); 
    this.getChargingSchedule(this.state.pageSize,this.state.activePage,this.state.saveSelectedSchedule);
}
  
  }
      // search filter 
      handleKeyDown(ref,e){
  
   
       
        this.state.saveSelectedSchedule = ref.current.inputNode.value;
     
        if(this.state.saveSelectedSchedule == null || this.state.saveSelectedSchedule ==""){
       
        
             this.getChargingSchedule(this.state.pageSize,this.state.activePage)
             this.state.errors["selectChargepoint"] = "";
        }
        var profile_no = e.target.value;
    
 
    
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false); 
        var getList = this.state.intial_data;
        if( key == "Backspace" || key == "Delete" ){
         this.state.errors["selectChargepoint"] = "";
          $("#notexists").text("");
             var profile_no = e.target.value;
             
              if(profile_no.length <= 1){
                getList = [];
                this.setState({
                    options:getList,
                  });
              }else{
                this.setState({
                  options:getList,
                });
              }
      
          }
          else{
            if(e.keyCode == 13){
              $("#selectcharge").hide();
              this.FindCpName(ref,e);
              $("#rbt_id").hide();
            }
            if((e.keyCode == 86 && ctrl)){
              $("#selectcharge").hide();
              this.getChargingSchedule(this.state.pageSize,this.state.activePage);
            }
      
       switch (e.keyCode) {
              case 8:  // Backspace
              case 9:  // Tab
              case 13: // Enter
              case 37: // Left
              case 38: // Up
              case 39: // Right
              case 40: // Down
              break;
              default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
              var key = e.key;
              if (!regex.test(key)) {
              
                  e.preventDefault();
          
                  return false;
               
                 
              }
              break;
            
          }
          
          }
        }
FindCpName(ref,event){
  this.state.saveSelectedSchedule = ref.current.inputNode.value;

  this.state.selectFindStatus = true;

  this.getChargingSchedule(this.state.pageSize,this.state.activePage,this.state.saveSelectedSchedule);
 if(this.state.saveSelectedSchedule == null || this.state.saveSelectedSchedule ==""){
    this.state.errors["selectChargepoint"] = this.props.t('select_charging_schedule');
    $("#notexists").text(" ");
     
  }else{
    this.state.errors["selectChargepoint"] = " ";
    this.getChargingSchedule(this.state.pageSize,this.state.activePage,"click");
 
}
if(this.state.selectDataArray.length==0){
  if(this.state.saveSelectedSchedule == null || this.state.saveSelectedSchedule ==""){
  let errors={}
  errors["selectChargepoint"] = this.props.t('please_select_charging_profile');
 
  this.setState({errors:errors});
  }else{
    let errors={}
    errors["selectChargepoint"] = "";
   
    this.setState({errors:errors});
  }
}
    
}


async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}

showEntriesChange(){
  
  var entries=document.getElementById("showEntries").value;
  this.state.data.clear();
  this.setState({pageSize:entries, activePage: 1});
  this.getChargingSchedule(entries,1);
}

sessiontagOk(){
  history.push('/');
  window.location.reload();
}

//This is for adding the charge profile
addNew(){
  this.props.history.push('/addchargingSchedule');
}

slideDownChildRow(u_email,e){
  if(e==""){
     var rows = $("#content0");
     var header = $("#header0");
    //  this.getAdminWallbox(1, 1, u_email)
     $(header).text("▲");
     $(rows).slideDown();
  }else{
    var rows = e.target.parentElement.nextElementSibling.firstElementChild.children;
    var header = e.target;
      if ($(rows).is(":hidden")) {
        $(".wallboxrow div").slideUp();
        $(".arrow_icon").text("▼");
        $(header).text("▲");
        $(rows).slideDown();
        this.getChargingSchedule(this.state.pageSize,this.state.activePage);
      } else {
        $(header).text("▼");
        $(rows).slideUp();
      }
    }
}


handlePageChange(pageNumber) {
   if(pageNumber !=this.state.activePage){
    var data=this.state.data;
    var pageData = data.get(pageNumber);
    var pageData = undefined;
    if(pageData == undefined){

    var pageData=data.get(pageNumber);
    var pageData = undefined;
    if(pageData==undefined){
    this.setState({activePage: pageNumber});
    this.getChargingSchedule(this.state.pageSize,pageNumber);

    }else{
      this.setState({activePage: pageNumber});
      this.setState({dataArray:pageData})
    }
  }
}
}

//  function after clicking on checkbox to stored in an array 
handleChange(){
  // var checkedValue = document.getElementById(id).checked;
  // if(checkedValue==true){
  //   this.state.list.push(cpid);
  //   this.setState({
  //     activeArray:this.state.list
  //     });
  // }else{
   
  //   const index = this.state.list.indexOf(cpid);
  //     if (index > -1) {
  //       this.state.list.splice(this.state.list.index, 1);
  //     }
  //   this.setState({
  //     activeArray:this.state.list
  //     });
  // }
}

async getChargingSchedule(pageSize,pageNo, action){
  var timeZone=  localStorage.getItem("timezone");
  var errors = {};
  ;
  if(this.state.selectFindStatus == true){
    var url = baseUrl.URLPath + "chargingschedules?pageNo="+1+"&pageSize="+10+"&chargingScheduleName="+this.state.saveSelectedSchedule;

  }
  else{
    var url = baseUrl.URLPath + "chargingschedules?pageNo="+pageNo+"&pageSize="+pageSize+"&chargingScheduleName="+this.state.saveSelectedSchedule;

  }
  await fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization":localStorage.getItem("Authorization")
    }
    })
    .then((resp) => {
      ;
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==500){
        alert("internal server error")
      }
      if(resp.status==200){
        return resp.json();
      } 
    }) 
    .then((response) =>{
     if(response!=undefined){
        if(response.count.count==0){
          if(action=="click" || action=="enter"){ 
            $("#notexists").text(this.props.t('charging_schedule_not_exists'));
                            }
                            this.setState({
                              items:[],
                              noOfRecords:0,
                              selectDataArray:[]
                             });
        }else{
        this.setState({
           dataArray:response.chargingSchedules,
           noOfRecords:response.count.count,
           selectDataArray:response.chargingSchedules

          })
   if(action=="click" || action=="enter"){ 
            $("#notexists").text(" ");
                            }
        var data= this.state.data;
        data.set(pageNo,response.count.count)


        var option = [];
        for(var i = 0; i < this.state.dataArray.length; i++)
        {
          //alert(this.state.items[i].serial_number);
          option.push(this.state.dataArray[i].chargingScheduleName);
          
        }
        
        this.setState({options:option});
     
      }
    }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}

componentDidMount(){
  this.state.user.permissions = localStorage.getItem("roleAccess");
  this.getChargingSchedule(this.state.pageSize,1)
 $("#nochargeexists").hide();
}
ClearChargepoint(ref,event){

  ;
  this.state.selectFindStatus = false;
  this.state.errors["selectChargepoint"] = "";
  $("#notexists").text("");
  var errors={}

  this.state.saveSelectedSchedule = "";
  this.state.pageSize=10;
        this.state.activePage=1;
        document.getElementById("showEntries").value=10;
   this.getChargingSchedule(this.state.pageSize,this.state.activePage,this.state.saveSelectedSchedule);
    this.setState({
      items:this.state.items,errors:errors
    });
    ref.current.clear()
    // this.typeahead.current.state.selected= []
    this.setState({
      idTags:""
    })
}

//function for sorting table data on click
ascendingOrder(){
  // this.setState({dataArray : this.state.dataArray.reverse()})

  if(this.state.cpIdSorting == false){
    this.state.dataArray.sort(this.dynamicSort("chargingScheduleName"));
    this.state.cpIdSorting = true;
       this.setState({dataArray : this.state.dataArray.sort(this.dynamicSort("chargingScheduleName"))})
  }
   else{
    this.state.dataArray.sort(this.dynamicSort("chargingScheduleName"));
    this.state.cpIdSorting = false;
       this.setState({dataArray : this.state.dataArray.reverse()});
   } 
  
}
minRateSorting(){
  if(this.state.cpIdSorting == false){
    this.state.dataArray.sort(function (a, b) {
      return a.minChargingRate - b.minChargingRate;
    });
    this.state.cpIdSorting = true;
this.setState({dataArray:this.state.dataArray})
    }
    else{
      this.state.cpIdSorting = false;
      this.setState({dataArray : this.state.dataArray.reverse()});
     }
}
dynamicSort(property) {
  var sortOrder = 1;

  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a,b) {
      if(sortOrder == -1){
          return b[property].localeCompare(a[property]);
      }else{

          return a[property].localeCompare(b[property]);
      }        
  }
}

render() {
  const{t}=this.props
  let user = this.state.user;
  const ref = React.createRef();

  return (
    <>
  <main class="content-div" >
  <p>{t('Charging Schedule')}
              <div className="breadcrumb_div">
              {t('charge_point')} &gt; <span className="breadcrumb_page">{t('Charging Schedule')}</span>
              </div>
          </p>
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div">Charge Point > Charging Schedule</div> */}
          {/* page title */}
          {/* <p>Reservations</p> */}
          {/* end page title */}
          {/* page menu bar */}
            
          <div className="row menubar ">
            <div className="col-md-12 ">
              <div className="">

               {this.props.ChargingSchedule_W && 
                <button onClick={this.addNew} type="button" data-test="button">
                  <i className="fa fa-plus">
                  </i>
                  <span>{t('add')}</span>
                 </button> 
               }
               <div className="pull-right">
               <label className="sub-text pull-left">{t('show_entries')}</label>
               <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox"  id="showEntries" onChange={this.showEntriesChange}> 
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
               </div>
                
                



                {/* <button onClick={this.toggle1}>
                  <i className="fas fa-trash">
                  </i>
                  <span>Delete</span>
                </button>  */}
              </div>
            </div>
           </div>
           <div className="row">
            <div className="col-md-4  pull-right">
            <div class="input-group">
            <i className="fas fa-search fa-sm input-searchIcon"></i>
              <Typeahead
                 labelKey="name"
                 className="w_80"
                 id="rbt_id"
                 minLength={3}
                 multiple={this.state.multiple}
                 options={this.state.options}
                 placeholder={t('choose_charging_schedule')}
                 onKeyDown ={this.handleKeyDown.bind(this,ref)}
                onChange={this.chargingScheduleChange.bind(this)}
              
                  onBlur={this.getScheduleNames}
                  ref={ref}
              />
                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this,ref)}
 >
                  <span aria-hidden="true">×</span>
                </button>
                {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-right" style={{color:"#fff"}} onClick={this.FindCpName.bind(this,ref)}
 >
                    Find
                </MDBBtn> */}
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this,ref)} >{t('find')}</button>
                </div>
                <span className="pull-left error_msg w3-animate-top mt-1" id="notexists" style={{color: "red"}}><span style={{color: "red"}}>{this.state.errors["chargePointNotExists"]}</span></span> 
                <span className="pull-left error_msg w3-animate-top mt-1"><span style={{color: "red"}}>{this.state.errors["selectChargepoint"]}</span></span> 
</div>
              </div>
          </div>
           
            {/* start page menu bar  */}
           {/* <div className="row mt-4">
              <div className="col-md-12">
                <label className="pull-left sel_lbl">Show entries</label>
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left " >
                  <Form.Control as="select" className="form_select showentry_sel"  id="showEntries" onChange={this.showEntriesChange}> 
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
            </div>
          </div> */}
          {/* end page menu bar  */}
          
          {/* table start */}
         <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                <th></th>
                <th className="">{t('name')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">{t('chargingDuration')}</th>
                <th>{t('start_schedule')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">{t('charging_rate_unit')}</th>
                <th className="">{t('min_charging_rate')} <i class="fas fa-sort sortIcon" onClick={this.minRateSorting.bind(this)}></i></th>
              </tr>
            </thead>
          <tbody className="nested_Table" >
           {this.state.dataArray.map((items, index) =>{ 
             if(items.startSchedule==null || items.startSchedule==""){
              var date = "";
              var date1="";
            }else{
              var date = moment(items.startSchedule).format("DD MMM YYYY");
              var date1 = moment(items.startSchedule).format("HH:mm");
          
            }
             return(
             <>
             <tr>
              <td  id={'header'+index} className="arrow_icon " onClick={this.slideDownChildRow.bind(this,items.scheduleId)}>▼</td>
              <td className="">
               <Link to={{
                pathname:'/viewchargingSchedule', 
                state:items.scheduleId, 
                }}>{items.chargingScheduleName}
              </Link>
              </td>
              <td className="">{items.duration}</td>
              <td className="wrap-word">{ date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
              <td className="">{items.schedulingUnit}</td>
              <td className="">{items.minChargingRate}</td>
            </tr>
             <tr style={{backgroundColor:"#fff !important" }} className="wallboxrow">
             <td colSpan="8" style={{padding:"0px"}} >
                 <div id={'content'+index} class='content02' style={{display:"none"}} >
                     <table cellSpacing='0' cellPadding='0' width="100%" className='form_table2'>
                         <tr>
                            <th colSpan="2" className="pl-2 " > <b>{t('start_period')}</b></th>
                            <th colSpan="1" className="pl-2 " > <b> {t('limit')}</b> </th>
                            <th colSpan="1" className="pl-2 " > <b> {t('number_phases')} </b> </th>
                         </tr>
                         {items.chargingSchedulePeriod.map((childRows, index) => (
                             <tr key={childRows.index} style={{backgroundColor:"#fff"}} >
                             <td colSpan="2" className="">{childRows.startPeriod}</td>
                             <td colSpan="1" className="">{childRows.limit}</td>
                             <td colSpan="1" className="">{childRows.numberPhases}</td>
                         </tr>
                         ))}
                     </table>
                 </div>
             </td>
         </tr>    
      </>
            )})}
            </tbody>


          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right">
             <Pagination
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
             />
            </div>
          {/* End table  */}
          </div>
          </div>
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
             <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
        </MDBModal>
      </main>
 </>
  );
}
}

//export default chargingSchedule;
export default withTranslation()(chargingSchedule)