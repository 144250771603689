import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import moment from 'moment';
import {activeDateFormat} from '../views/_methods';
import CustomModal from '../common/CustomModal';

class chargePointOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textdisplay:""
    }
  }
  render() {
    const{t}=this.props
    const ref = React.createRef();

    var data = this.props.selectDataArray.map((dataArray, index) => {
      var chargePoint = {
        chargePointId: dataArray.chargePointId,
         id: dataArray.id,
         cpmodel:dataArray.chargePointModel,
         onlineStatus:dataArray.backendConnection
      }
      if (dataArray.active == "0" || dataArray.active == "Inactive") {
        dataArray.active = "Inactive";
      } else {
        if (dataArray.active != "") {
          dataArray.active = "Active";
        }
      }
      if (dataArray.heartbeatTime == null || dataArray.heartbeatTime == "") {
        var heartbeatTime = "";
        var heartbeatTime1 = "";
      } else {
        var heartbeatTime = moment(dataArray.heartbeatTime).format("DD MMM YYYY");
        var heartbeatTime1 = moment(dataArray.heartbeatTime).format("HH:mm");
      }
      // logic for getting backendConnectionTimeout;

      var lostBackendConnectionTime = Number(dataArray.lostBackendConnectionTime);
      var seconds = Math.floor(lostBackendConnectionTime % 60);
      var mins = Math.floor(lostBackendConnectionTime % 3600 / 60);
      var hrs = Math.floor(lostBackendConnectionTime % (3600 * 24) / 3600);
      var days = Math.floor(lostBackendConnectionTime / (3600 * 24));

      var dDisplay = days > 0 ? days + (days == 1 ? "d" : "d") : "";
      var hDisplay = hrs > 0 ? hrs + (hrs == 1 ? "h" : "h") : "";
      var mDisplay = mins > 0 ? mins + (mins == 1 ? "m" : "m") : "";
      var sDisplay = seconds > 0 ? seconds + (seconds == 1 ? "s" : "s") : "";

      // condition for backendConnection and timeOut
      var color = "";
      if (dataArray.backendConnection == true) {
        color = "#29be29";
      } else if (dataArray.backendConnection == null) {
        color = "#ef1111";
        this.state.textdisplay = "block";
      } else {
        color = "#ef1111";
        if(dDisplay != 0) {
          dataArray.lostBackendConnectionTime = dDisplay
          this.state.textdisplay = "block"
        } else if (hDisplay != 0) {
          dataArray.lostBackendConnectionTime = hDisplay
          this.state.textdisplay = "block"
        } else if (mDisplay != 0) {
          dataArray.lostBackendConnectionTime = mDisplay
          this.state.textdisplay = "block"
        } else if (sDisplay != 0) {
          dataArray.lostBackendConnectionTime = sDisplay
          this.state.textdisplay = "block"
        }
      }

      if (dataArray.fordOnboardDate == null || dataArray.fordOnboardDate == "") {
        var onboarddate = "";
        var onboarddate1 = "";
      } else {
        var onboarddate = moment(dataArray.fordOnboardDate).format("DD MMM YYYY");
        var onboarddate1 = moment(dataArray.fordOnboardDate).format("HH:mm");
      }

      let role = localStorage.getItem('role');
      let isResidentialOrCommercial = false;
      let ischeckboxdisable = false;

      if (role == "WEBASTO_END_USER_COMMERCIAL" || role == "WEBASTO_END_USER_RESIDENTIAL") {
        isResidentialOrCommercial = true;
      }

      if (isResidentialOrCommercial) {
        if (dataArray.isSiteAdminAccess || dataArray.isChargerOwner) {
          ischeckboxdisable = false;
        } else {
          ischeckboxdisable = true;
        }
      } else {
        ischeckboxdisable = false;
      }

      if (dataArray.address != null) {
        return (
          <tr key={index}>
            <td>
              <Form.Check
                custom
                type="checkbox"
                id={`custom-${dataArray.id}`}
                label=""
                onChange={this.props.handleChange.bind(this, `custom-${dataArray.id}`, dataArray.id)}
                key={dataArray.chargePointId}
                disabled={ischeckboxdisable}
              />
            </td>
            <td>
              <Link to={{
                pathname: '/viewChargePoint',
                state: chargePoint,
              }}>{dataArray.chargePointId}</Link>
            </td>
            <td>{dataArray.chargePointModel}</td>
            <td>{dataArray.address.city}</td>
            <td>{dataArray.address.country}</td>
            <td>{dataArray.address.zipCode}</td>
            <td className="wrap-word">{heartbeatTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {heartbeatTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {heartbeatTime1} </div> </>} </td>
            <td><i className="fas fa-square-full connection-pl" style={{ color: color }}>{dataArray.backendConnection}</i> <label name="timer" className="con-ti" style={{ display: this.state.textdisplay }}>{dataArray.lostBackendConnectionTime}</label></td>
            <td className="text-rowcen">{dataArray.localListVersion}</td>
            {this.props.isSitemanagementAvailable && <td>{dataArray.siteName || ''}</td>}
            <td>{dataArray.active}</td>
            {(localStorage.getItem("enableCPImport") == "true" && localStorage.getItem("tenant") == "ford") ?
              <td className="wrap-word">{onboarddate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {onboarddate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {onboarddate1} </div> </>} </td> : ""}
            {(localStorage.getItem("enableCPImport") == "true" && localStorage.getItem("tenant") == "ford") ? <td>{dataArray.siteStatus}</td> : ""}
          </tr>
        );
      } else {
        return (
          <tr key={index}>
            <td className="text-center">
              <Form.Check
                custom
                type="checkbox"
                id={`custom-${dataArray.id}`}
                onChange={this.props.handleChange.bind(this, `custom-${dataArray.id}`, dataArray.id)}
                key={dataArray.id}
                label="" />
            </td>
            <td>
              <Link to={{
                pathname: '/viewChargePoint',
                state: chargePoint,
              }}>{dataArray.chargePointId}</Link>
            </td>
            <td>{dataArray.chargePointModel}</td>
            <td></td>
            <td></td>
            <td><a ></a></td>
            <td className="wrap-word">{heartbeatTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {heartbeatTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {heartbeatTime1} </div> </>} </td>
            <td><i className="fas fa-square-full connection-pl" style={{ color: color }}>{dataArray.backendConnection}</i> <label name="timer" className="con-ti" style={{ display: this.state.textdisplay }}>{dataArray.lostBackendConnectionTime}</label></td>
            <td className="text-rowcen">{dataArray.localListVersion}</td>
            {this.props.isSitemanagementAvailable && <td>{dataArray.siteName || ''}</td>}
            <td>{dataArray.active}</td>
            {(localStorage.getItem("enableCPImport") == "true" && localStorage.getItem("tenant") == "ford") ?
              <td className="wrap-word">{onboarddate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {onboarddate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {onboarddate1} </div> </>} </td>  : ""}
            {(localStorage.getItem("enableCPImport") == "true" && localStorage.getItem("tenant") == "ford") ? <td>{dataArray.siteStatus}</td> : ""}
          </tr>
        );
      }
    })

    return (
      <main className="content-div" >
        {/* page title */}
        <p>{this.props.t('Overview')}
          <div className="breadcrumb_div">
            {t('charge_point')} &gt; <span className="breadcrumb_page">{this.props.t('Overview')}</span>
          </div>
        </p>
        {/* end page title */}
        <div className="page-outerdiv">
          <div className="row menubar">
            <div className="col-md-12 ">
              <div>
                <button onClick={this.props.activeChargePoint.bind(this, 1)} style={{ "display": this.props.hideActionBtn }}  >
                  <i className="fas fa-toggle-on"></i><span>{t('active')}</span>
                </button>
                <button onClick={this.props.activeChargePoint.bind(this, 0)} style={{ "display": this.props.hideActionBtn }}>
                  <i className="fas fa-toggle-off"></i><span>{t('deactive')}</span>
                </button>
                {(localStorage.getItem("enableCPImport") == "true" && localStorage.getItem("tenant") == "ford") ?
                  <button style={{ "display": this.props.hideActionBtn }} onClick={this.props.bulkImport.bind(this, 0)} >
                    <i className="fas fa-file-import"></i> <span>{t('bulk_import')}</span>
                  </button> : " "}
                  {
                    this.props.paginationHide == false && 
                <div className="pull-right">
                  <label className="sub-text pull-left" >{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                  }
            </div>
          </div>
          </div>
          {/* end page menu bar  */}

          <div className="row mt-2">
            {/* search filter */}
            <div className="col-md-4">
              <div className="input-group" >
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <Typeahead
                  labelKey="name"
                  className="w_80"
                  multiple={this.props.multiple}
                  options={this.props.options}
                  id="rbt_id"
                  minLength={3}
                  placeholder={t('choose_a_charge_point_id')}
                  onKeyDown={this.props.handleKeyDown.bind(this, ref)}
                  onChange={this.props.chargepointIDChange.bind(this)}
                  ref={ref}
                />
                <button type="button" className="close close_icon" id="clearInput" aria-label="Close" onClick={this.props.ClearChargepoint.bind(this, ref)}  >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="input-group-btn">
                  <button className="input-searchBtn find-btn" type="submit" onClick={this.props.FindCpName.bind(this, ref)} >{t('find')}</button>
                </div>
                <span className="pull-left error_msg w3-animate-top mt-1" id="nochargeexists"><span style={{ color: "red" }}>{this.props.errors["chargePointNotExists"]}</span></span>
                <span className="pull-left error_msg w3-animate-top mt-1" id="selectcharge"><span style={{ color: "red" }}>{this.props.errors["selectChargepoint"]}</span></span>
              </div>
            </div>
            <div className="col-md-4 p-rel">
              <div className="input-group">
                <input type="text" className="form-control input-searchBox pl-3" placeholder={t('onboard_date')} name="srch-term" id="srch-term" value={this.props.onboardDate} onChange={this.props.changeOnboardDate} onKeyDown={this.props.keyPress} />
                <div className="input-group-btn">
                  <button className="input-searchBtn" type="submit" onClick={this.props.displayCalender} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                </div>
              </div>
              <div style={{ display: this.props.calenderOpen }} className="calenderContainer">
                <div onClick={this.props.closeCalender}><i className="far fa-times-circle"></i></div>
                <Calendar
                  selected={this.props.onboardDate}
                  onChange={date => this.props.selectDate(date)}
                  value={activeDateFormat(this.props.onboardDate)}
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  showTimeSelect
                  timeCaption="time"
                  dateFormat="dd-MMM-yyyy"
                  onBlur={this.props.closeCalender}
                  maxDate={new Date()}
                />
              </div>
              <span className="pull-left future_err w3-animate-top mt-1" style={{ color: "red" }}></span>
            </div>
            <div className="col-md-4">
              <div className="input-group" style={{width: "94%"}}>
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <input
                  type="text"
                  id="fwVersion"
                  value={this.props.fwVersion}
                  onChange={this.props.changeHandler.bind(this, "fwVersion")}
                  onKeyDown={this.props.keyPressForFWVersion}
                  className="form-control input-searchBox pad-2"
                  placeholder={t('fw_version')}
                  name="srch-term"
                  pattern="[0-9.a-zA-Z]*"
                />
                <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.props.clearFWVersion} >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="input-group-btn">
                  <button className="input-searchBtn find-btn" type="submit" onClick={()=>{
                    if (this.props.fwVersion == "" || this.props.fwVersion == undefined || this.props.fwVersion == null) {
                      $('.fwVersion_noexists').text(t('enter_fwversion'));
                    }else{
                      this.props.getChargePoints("",true,"");
                    }
                  }}>{t('find')}</button>
                </div>
              </div>
              <span className="error_msg w3-animate-top mt-2 fwVersion_noexists" style={{ color: "red" }}></span>
            </div>
            <div className="col-md-4">
              <div className="input-group" style={{width: "94%"}}>
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <input
                  type="text"
                  id="Model"
                  value={this.props.selectedModel}
                  onChange={this.props.changeHandler.bind(this, "selectedModel")}
                  onKeyDown={this.props.keyPressForModel}
                  className="form-control input-searchBox pad-2"
                  placeholder={t('model')}
                  name="srch-term"
                />
                <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.props.clearModel} >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="input-group-btn">
                  <button className="input-searchBtn find-btn" type="submit" onClick={()=>{
                    if (this.props.selectedModel == "" || this.props.selectedModel == undefined || this.props.selectedModel == null) {
                      $('.selectedModel_noexists').text(t('enter_model_name'));
                    }else{
                      this.props.getChargePoints("",true,"");
                    }
                  }}>{t('find')}</button>
                </div>
              </div>
              <span className="error_msg w3-animate-top mt-2 selectedModel_noexists" style={{ color: "red" }}></span>
            </div>
            <div className="col-md-4 mt-1">
              <Form.Check
                className=""
                custom
                checked={this.props._backendConnection}
                value={this.props._backendConnection}
                onChange={this.props.handleChecked.bind(this)}
                id="backendConnection"
                type="checkbox"
                label={t('backend_connection')}
              />
            </div>

            {/* Export by Lakshmikanth */}
            <div className="col-md-4 pull-right">
              <button type="button" data-test="button" className="btn_primary pull-right mr-2" onClick={this.props.exportChargePoints}>
                {t('export')} <i className="fas fa-file-export ml-2"></i>
              </button>
            </div>
          </div>

          {/* table start */}
          <div className="row ">
            <div className="col-md-12">
              <Table striped hover size="sm" className="page-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t('charge_point_id')} <i className="fas fa-sort sortIcon" onClick={this.props.ascendingOrder.bind(this)}></i></th>
                    <th>{t('model')}</th>
                    <th>{t('city')}</th>
                    <th>{t('country')}</th>
                    <th>{t('zipCode')}</th>
                    <th>{t('heartbeat')} <i className="fas fa-sort sortIcon" onClick={this.props.ascendingOrder.bind(this)}></i></th>
                    <th>{t('backend_connection')}</th>
                    <th>{t('list_version')}</th>
                    {this.props.isSitemanagementAvailable && <th>{t('current_group')}</th>}
                    <th>{t('chargingSessionState')}</th>
                    {(localStorage.getItem("enableCPImport") == "true" && localStorage.getItem("tenant") == "ford") ? <th>{t('on_board_date')}</th> : ""}
                    {(localStorage.getItem("enableCPImport") == "true" && localStorage.getItem("tenant") == "ford") ? <th>{t('group_status')}</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {data}
                </tbody>
              </Table>
            </div>
            {
              this.props.paginationHide == false && 
            <div className="col-md-12 mt-2 mb-4 text-right">
              <Pagination
                activePage={this.props.activePage}
                itemsCountPerPage={this.props.pageSize}
                totalItemsCount={this.props.noOfRecords}
                onChange={this.props.handlePageChange.bind(this)}
                prevPageText={t('prev')}
                nextPageText={t('next')}
                itemClassPrev="prevBtn"
                pageRangeDisplayed="1"
                activeClass="activeLi active"
                itemClassNext="nextBtn"
                hideFirstLastPages={true}      
                disableInitialCallback={true}
                disabledClass="disabled disabledtextColor"                   
              />
            </div>
            }
            {/* End table  */}
          </div>
        </div>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>

        {/* popup for charge point success activation by Lakshmikanth */}
        <CustomModal
          open={this.props.modal1}
          toggle={this.props.toggle1}
          title={t('success')}
          bodyMessage={t('point_deactivated_successfully')}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.tagOk1}
        />

        {/* popup for charge point success activation by Lakshmikanth */}
        <CustomModal
          open={this.props.modal2}
          toggle={this.props.toggle2}
          title={t('success')}
          bodyMessage={t('charge_point_activated_successfully')}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.tagOk2}
        />

        {/* popup for charge point selection alert by Lakshmikanth */}
        <CustomModal
          open={this.props.modal3}
          toggle={this.props.toggle3}
          title={t('success')}
          bodyMessage={t('select_charge_point')}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.toggle3}
        />

        {/* popup for session expire by Lakshmikanth */}
        <CustomModal
          open={this.props.modalsession}
          toggle={this.props.togglesession}
          title={t('session_has_expired')}
          bodyMessage={t('please_login_again')}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.sessiontagOk}
        />

        {/* popup for session expire by Lakshmikanth */}
        <CustomModal
          open={this.props.export500}
          toggle={this.props.toggleexport500}
          title={t('internal_server_error')}
          bodyMessage={t('export_failed')}
          PrimaryButtonText={t('ok')}
          PrimaryButtonOperation={this.props.toggleexport500}
        />
      </main>
    );
  }
}

export default withTranslation()(chargePointOverview);