import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import apiCallUtility from './APICallUtility';
import { Form, Table, Pagination, Check } from 'react-bootstrap'
import * as constants from './constants';
import ChargePointModelSelection from './chargePointModelSelection';
import ChargePointSelection from './chargePointSelection';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import servicesUrl from '../common/servicesUrl';

class getConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cpList: [],
      fields: { text: 'Game', value: 'Id' },
      sucess: false,
      colour: 'sucess',
      value: 'Game3',
      page_title: "Get Configuration",
      errors: {},
      modal: false,
      config_key: [],
      configKeyList: [],
      chargePoint: '',
      operationId: '',
      modalsession: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.toggle_list = this.toggle_list.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.saveConfigKey = this.saveConfigKey.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.togglesession = this.togglesession.bind(this);
  }
  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  saveConfigKey = () => {
    this.setState({
      modal: !this.state.modal
    });
    var config_status = "";
    for (var i = 0; i < this.state.config_key.length; i++) {
      config_status += this.state.config_key[i][1] + ", "

    }
    document.getElementById("configration_key_id").innerHTML = config_status;
  }

  toggle_list = () => {

    this.setState({ modal: !this.state.modal }, () => {
      window.setTimeout(() => {
        var config_status = "";
        for (var i = 0; i < this.state.config_key.length; i++) {
          config_status = this.state.config_key[i][0];
          document.getElementById("custom-" + config_status).checked = true;
        }
      }, 700)
    });

  }

async handleSubmit(event) {
  
  var fdf=this.state;

    var fdf = this.getPayLoad();
    var fsds = this.getCPList();
    var errors = {};
    if (this.getCPList() == 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (this.getCPList() != 0) {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
      //alert(response);
      if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
          this.onShowAlert();
        })
      } else {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
          this.onShowAlert();
        })
      }
    }
    this.setState({ errors: errors })
  }
  
  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata != undefined) {
      if (propsdata.chargePoint != undefined && propsdata.operationId != undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
     
      }
    }
  }

  getCPList() {
    var Id = this.state.operationId;
    if (Id != null && Id != "") {
      var cplist = Id.split();
    }
    else {
      var cplist = this.state.cpList;
    }
    return cplist;
  }

  getPayLoad() {
    var keys = [];
    var configs = this.state.config_key;
    for (var i = 0; i < configs.length; i++) {
      keys.push(configs[i][1]);
    }
    var payload =
    {
      "key": keys
    };
    return payload;
  }

  getMessageType() {
    return constants.GET_CONFIGURATION;
  }

  async togglesession(){
    this.setState({
    modalsession: !this.state.modalsession
    });
  }
  
  sessiontagOk(){
    this.props.history.push('/');
    window.location.reload();
  }

  getSelectedCP(cpList) {
    this.setState({cpList:cpList});
    this.getConfigKey(cpList);
  }

  getConfigKey(cpList) {
    var url = constants.URLPath + servicesUrl.getCPConfigurationKeys;
    var data = { "cpList": cpList }
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if(resp.status==401){
          this.togglesession();
          localStorage.clear();
        }else{
          return resp.json();
        }
      })
      .then((response) => {
        this.setState({ configKeyList: response })
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  // after clicking on checkbox 
  handleChangeCheckbox(id, text, e) {
    var checkedValue = document.getElementById("custom-" + id).checked;
    if (checkedValue == true) {
      this.state.config_key.push([id, text]);
    } else {
      var config_status = "";
      var index = "";
      for (var i = 0; i < this.state.config_key.length; i++) {
        if (id == this.state.config_key[i][0]) {
          config_status = this.state.config_key[i][0];
          const index = this.state.config_key.indexOf(this.state.config_key[i]);
          if (index > -1) {
            this.state.config_key.splice(index, 1);
          }
        }
      }
    }
  }

  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata != undefined) {
      if (propsdata.chargePoint != undefined && propsdata.operationId != undefined) {
        this.setState({
          chargePoint:propsdata.chargePoint,
          operationId:propsdata.operationId
        });
        this.getConfigKey([propsdata.operationId])
      }
    }
  }

  render() {
    const{t}=this.props
    const { plainArray } = this.state;
    let configKeyListLi = "";
    if (this.state.configKeyList != "" || this.state.configKeyList != undefined || this.state.configKeyList.length != 0) {
       configKeyListLi = this.state.configKeyList.map((configKeyListData, index) => {
        return (
          <li style={{width:'33%'}}><Form.Check
            custom
            type="checkbox"
            id={`custom-${index}`}
            label=""
            name="chargepoint"
            onChange={this.handleChangeCheckbox.bind(this, index, configKeyListData)}
          /> {configKeyListData}
          </li>
        )
      })
    }




    return (
      <>

        <main class="content-div" >
          <p>  {t('Get Configuration')}
            <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">   {t('Get Configuration')} </span>
            </div>
          </p>
          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="GetConfiguration"></ChargePointSelection>
                      <MDBCol md="12">
                        <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                      </MDBCol>
                      <MDBCol md="12">
                        <span className="form-title mt-0 pb-4">{t('selected_configuration_key')}:  <button onClick={this.toggle_list} className="refresh-btn ml-0 p-2 pull-right" type="button" data-test="button">{t('selected_configuration_key')}</button> </span>
                        <div className="chargepoint_listdiv_out" id="configration_key_id" ></div>
                      </MDBCol>
                    </MDBRow>
                    <button className="mt-5 refresh-btn" type="button" data-test="button" onClick={this.handleSubmit} >{t('perform')}</button>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </main>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle_list} className="custom_confkey_w" size="lg" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
          <div data-test="modal-header" class="modal-header">
            <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('selected_configuration_key')}</div>
            <button type="button" data-test="button" class="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          {/* model body for single selection  */}
          <MDBModalBody>
            {/* Charge Point table  */}
            <MDBRow className=" check-list ">
              <div className="col-md-12">
                <ul>
                  {configKeyListLi}
                </ul>
              </div>
            </MDBRow>
            {/* end Charge Point table  */}
          </MDBModalBody>
          <MDBModalFooter className="border-top-0">
            <button className="btn_cancel" onClick={this.toggle_list} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
            <button className="btn_primary" onClick={this.saveConfigKey} type="button" data-test="button"> {t('done')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" className="btn_primary mt-2 mb-2" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(getConfiguration);