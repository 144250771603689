import React from 'react';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"
import * as baseUrl from '../constants';
import Loader from './../loader';
import { withTranslation } from 'react-i18next';

class ChargingPower extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPower: "",
            power: "",
            chargepointid: "",
            errors: {},
            showLoder: "true"
        }
        this.handleChange = this.handleChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }


    handleChange(e) {

        this.setState({ chargepointid: e.target.value });
        if (e.target.value == "") {
            this.state.chargepointid = "";
            this.setState({ errors: {} });
            this.getChargingPower("");
        }
    }

    keyPress(e) {
        if (e.keyCode == 13) {
            this.setState({ chargepointid: e.target.value, showLoder: "true" });
            this.getChargingPower("");
        }
    }


    componentDidMount() {
        this.getChargingPower("totalValue");
    }

    getChargingPower(t_power) {
        let errors = {};
        var url = baseUrl.URLPath + "dashboard/chargingpower?chargePointId=" + this.state.chargepointid;
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                ;
                if (resp.status == 401) {
                    localStorage.clear();
                    this.setState({
                        showLoder: "false",
                    })
                }
                if (resp.status == 500) {
                    this.setState({
                        showLoder: "false",
                    })
                }
                if (resp.status == 404) {
                    if (this.state.chargepointid != "") {
                        errors["chargepointdoesNotExist"] = this.props.t('charge_point_not_exists');
                        this.setState({
                            errors: errors,
                            showLoder: "false",
                        })
                    }
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {

                if (response != undefined) {
                    if (t_power == "") {
                        this.setState({
                            power: response.totalPower,
                            showLoder: "false",
                            errors: {}
                        })
                    } else {
                        this.setState({
                            power: response.totalPower,
                            totalPower: response.totalPower,
                            errors: {},
                            showLoder: "false"
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
                this.setState({
                    showLoder: "false",
                    errors: {}
                })
            })
    }

    render() {
        let percentage = ((this.state.power / this.state.totalPower) * 100).toFixed(2);
        if (percentage == "NaN") {
            percentage = 0;
        }
        return (
            <>
                <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                    <div className="content-box" >
                        {this.state.showLoder == "true" ? <Loader loader={this.state.showLoder} /> : ""}
                        <div className="dashboard-subtitle">
                            {this.props.t('charging_power')}
                        </div>
                        <div class="input-group input-search mt-2">
                            <i class="fas fa-search fa-sm"></i>
                            <input type="text" class="border-0 small" placeholder={this.props.t('search_chargepoint')} onKeyDown={this.keyPress} onChange={this.handleChange} value={this.state.chargepointid} />
                            <span className="error_msg w3-animate-top mt-1"> <span style={{ color: "red" }}>{this.state.errors["chargepointdoesNotExist"]}</span></span>
                        </div>
                        <div className="circular-progress-bar w-100">
                            <CircularProgressbar className="percentage" value={percentage} text={`${this.state.power}kW`} strokeWidth="8" styles={buildStyles({
                                backgroundColor: '#000', pathColor: `#72c93e`,
                                textColor: '#72c93e',
                                trailColor: '#d6d6d6'
                            })} />
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-6"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    };
}

export default withTranslation()(ChargingPower);