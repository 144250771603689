import React from 'react';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import Sidebar from './sidebar';
import Headers from './header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Form} from 'react-bootstrap';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';
import Header from './header';
import  $ from "jquery";
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';


import { createBrowserHistory } from 'history';

const history = createBrowserHistory();



class addOauthUser extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          sucess1: false,
          colour: 'sucess',
          message:"",
          roledisplay:"",
          defaultroledisplay:"",
          fields: {id: '', active:'', email:'', firstName:'', lastName:'', mobileNumber:'', organisation:'', role:'', employeeId:'', status:'',rfidTag:'',aliasName:''},
          sucess:{},
          errors: {},
          activeItem: "1",
          model:"false",
          page_title:"Add User",
          dataArray:[],
          modalsession:false,
          roles:[],
          multipleRfidArray:[
            {
              rfidTag: '', aliasName: '' , tagType:'other'
            }
          ],
          multipleRfidArrayAlias:[
           
          ],
          selectedOrganization:''
        

        }
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this)
        this.addNew = this.addNew.bind(this)
        this.backToList = this.backToList.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.roleidChange = this.roleidChange.bind(this)
        //this.organizationChange = this.organizationChange.bind(this)
        this.activeChange = this.activeChange.bind(this)
        this.togglesession = this.togglesession.bind(this)
        this.getRoles=this.getRoles.bind(this)
        this.addMultipleRfid = this.addMultipleRfid.bind(this)
        this.removeRfid=this.removeRfid.bind(this)
        this.changeRfid = this.changeRfid.bind(this)
        this.onShowAlert=this.onShowAlert.bind(this)



}
onShowAlert(){
 this.setState({sucess1:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess1:false})
   },5000)
 });

}


changeRfid(index,field,e){
  let tempArray = [...this.state.multipleRfidArray] ; 
 

  tempArray[index][field] = e.target.value;
  if(field == "rfidTag"){
    if(tempArray[index][field]!=0){
    
      $(`.rfidTag_${index}`).text("")
    }
  }
  if(field == "aliasName"){
    if(tempArray[index][field]!=0){
    
      $(`.aliasName_${index}`).text("")
    }
  }
  if(field=="tagType"){
    if(tempArray[index][field]!=0){
    
      $(`.tagType_${index}`).text("")
    }
  }
  this.setState({multipleRfidArray : tempArray});
  console.log(this.state.multipleRfidArray)
  

}


addMultipleRfid(){
  let errors = {};
  this.setState({
    multipleRfidArray:[...this.state.multipleRfidArray,{}]
  })
  errors["emptyidTagArray"] = "";
this.setState({errors : errors})
  }
  removeRfid(index){
    let data=this.state.multipleRfidArray

    // remove object
    data.splice(index, 1);
    this.setState({
      multipleRfidArray:data,
        disabledAttr:""
    });
   
    let lookup = this.state.multipleRfidArray.reduce((a, e) => {
      a[e.rfidTag] = ++a[e.rfidTag] || 0;
      return a;
    }, {});

    let duplicateCount = this.state.multipleRfidArray.filter(e => lookup[e.rfidTag]);

    if(duplicateCount.length == 0){
      $('.rfidexists').text("");
    }
  }

async togglesession(){
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}
roleidChange(){
 
  var x = document.getElementById("role").value;
  let fields = this.state.fields;
  fields["role"] = x; 
  this.setState({fields});
  if( x!=0){
   this.state.errors["role"] = " ";
   
  }
 
}
tagOk(){
  this.props.history.push('/oauthUsers');
}
toggle = () => {
  this.setState({
  modal: !this.state.modal
  });
}




addNew(){
  this.props.history.push('/Userlist');
}

backToList(){
  this.props.history.push('/oauthUsers');
}

// checking the format of number
  numberHandler(field, e){ 
      let fields = this.state;
      if(e.target.validity.valid){
        fields[field] = e.target.value; 
        this.setState({fields});
      }else if(e.target.value==""){
        fields[field] = e.target.value; 
        this.setState({fields});
      }
  }
  changeHandler(field, e){ 
    var email= document.getElementById("email").value;
    var firstName= document.getElementById("firstName").value;
    var lastName= document.getElementById("lastName").value;
    var mobileNumber= document.getElementById("mobileNumber").value;
 
  let fields = this.state.fields;
    fields[field] = e.target.value; 
    this.setState({fields});
    if(e.target.value != ""){
      this.state.errors["rfidempty"]=""
   
     
    }
    if (fields["email"] = email){
      this.state.errors["emailempty"] = " ";
      this.state.errors["userexists"]=" ";

      
   }
 if( fields['firstName'] =firstName){
      this.state.errors["firstName"] = " ";
  }
  if( fields['lastName'] =lastName){
    this.state.errors["lastName"] = " ";
}
if( fields['mobileNumber'] =mobileNumber){
  this.state.errors["mobileNumber"] = " ";
}

    }

 
    activeChange = () => {
      var x = document.getElementById("myCheck").checked;  
      let fields = this.state.fields;
      if(x){
         fields.status=true;
           }
         else{
          
          fields.status=false;
           }
           this.setState({fields});
        
    }
    async handleSubmit(event) {
      event.preventDefault();
      let errors = {};
  

         
          var email= this.state.fields['email'];
          //var organisation= this.state.fields['organisation'];
          var firstName = this.state.fields['firstName'];
          var lastName= this.state.fields['lastName'];
          var mobileNumber= this.state.fields['mobileNumber'];
          var role= this.state.fields['role'];
          var rfidTag =this.state.fields['rfidTag'];
          var aliasName =this.state.fields['aliasName'];

          // var role = "WEBASTO_END_USER_COMMERCIAL"
          var getRole = localStorage.getItem("role")
           if(email==''){
               errors["emailempty"] = this.props.t('enter_email');
             }
             if(email!=''){
              if(!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)){
              errors["entervalidemail"] = this.props.t('enter_valid_email_id');
             }
            
            }
            //  if(organisation==''){
            //     errors["organisationempty"] = "Please select organization.";
            //   }
              if(firstName==''){
               errors["firstName"] = this.props.t('enter_first_name');
              }
              if(lastName==''){
                errors["lastName"] = this.props.t('enter_last_name')
               }
              
             if(mobileNumber==""){
                  errors["mobileNumber"] = this.props.t('enter_mobile_no');
             }
             if(mobileNumber!=''){
             if(!mobileNumber.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)){

              errors["validphoneNo"] = this.props.t('enter_10digit_mobile_no');
              }
            }
            if(getRole != "WEBASTO_SITE_ADMIN"){
             if(role==""){
              errors["role"] = this.props.t('please_select_role');
             }
            }
            if(role == "WEBASTO_END_USER_COMMERCIAL" && this.state.multipleRfidArray.length == 0){
              errors["emptyidTagArray"] = this.props.t('add_rfid_and_alias_name');

            }
            // if(rfidTag==''){
            //   errors["rfidempty"] = "Please enter rfid tag.";
            // }
            // if(aliasName==''){
            //   errors["aliasNameEmpty"] = "Please enter alias name.";
            // }
            //  if(role=="WEBASTO_END_USER_COMMERCIAL" && rfid ==""){
            //   errors["rfidempty"]="Please enter rfid tag."
            //  }
             
          
            
            
             if(getRole != "WEBASTO_SITE_ADMIN"){
 
          
           if(errors.hasOwnProperty("emailempty")||errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName")|| errors.hasOwnProperty("mobileNumber")|| errors.hasOwnProperty("role")|| errors.hasOwnProperty("entervalidemail")|| errors.hasOwnProperty("validphoneNo"))
             {}
        
           else{
           let url = baseUrl.URLPath+"v1.0/tenant/register/user";
           let data = {"email": this.state.fields.email.toLowerCase(), "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role":this.state.fields.role,"organisation":this.state.selectedOrganization,"status":this.state.fields.status}; 

           fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization"),
          }
        
          }).then(resp =>{
            if(resp.status==401){
              this.togglesession();
              // alert("Access token is expired, please login again")
              localStorage.clear();
            }
            else if(resp.status==404){
              this.setState({errors: errors});
              
             }else if(resp.status==500){
              }
           
           else if(resp.status==409){
              this.setState({
                errors: errors
                });
             }
             else if(resp.status==400){
          }else if(resp.status==201){
            return resp.json();
         
            }
          }) .then(response=>{
            debugger
               if(response.status==this.props.t('succees_condition')){
                 this.toggle()
                 $('.rfidexists').text("");
               }
               else if(response.status == this.props.t('failure_condition') && response.errorCode =="3104" && response.errorMessage == 'user_already_exists'){
                        errors["userexists"]=this.props.t('user_already_exists')
                      this.setState({
                        errors: errors
                        });
              }
              else if(response.status == this.props.t('failure_condition') && response.errorCode =="3123" && response.errorMessage == 'rfid_tag_issue'){
                var rfids = response.rfids[0];
              var errorRfid = rfids.rfidTag;
              var errorrfidMessage = rfids.errorReason;
                   if(rfids.errorReason=='rfid_is_already_assigned'){
                    errors["rfidexists"]=this.props.t('rfid_is_already_assigned');
                    $('.rfidexists').text("");
                    this.setState({errors: errors});
                    this.setState({ message: errorRfid +"  is already assigned", colour: 'danger' })
                    this.onShowAlert();
                  }else if(rfids.errorReason=="Duplicate RFID tag"){
                    $('.rfidexists').text(this.props.t('duplicate_rfidds_not_allowed'));
                  }
                 
                 
        }else if(response.status==this.props.t('failure_condition') && response.errorCode == "3124" && response.errorMessage == 'employeeId_already_exists'){
          $('.employeeidexists').text(this.props.t('employeeId_already_exists'));
        }
        else{}
               
              
              this.setState({
              errors: errors
              });
              
          })
          .catch(error => alert('Error:' + "Internal Server Error"));
        }
  }else{
        
    if(errors.hasOwnProperty("emailempty")||errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName")|| errors.hasOwnProperty("mobileNumber") || errors.hasOwnProperty("entervalidemail")|| errors.hasOwnProperty("validphoneNo")|| errors.hasOwnProperty("rfidempty") || errors.hasOwnProperty("aliasNameEmpty")|| errors.hasOwnProperty("emptyidTagArray"))
    {}

  else{

  let url = baseUrl.URLPath+"v1.0/tenant/register/user";
  let data = {"email": this.state.fields.email, "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role":"WEBASTO_END_USER_COMMERCIAL","organisation":"webasto","status":this.state.fields.status,"employeeId":this.state.fields.employeeId,"rfids":this.state.multipleRfidArray}; 
  fetch(url, {
 method: 'POST', // or 'PUT'
 body: JSON.stringify(data), // data can be `string` or {object}!
 headers: {
   "content-type":"application/json",
   "Authorization":localStorage.getItem("Authorization"),
 }

 }).then(resp =>{
   if(resp.status==401){
     this.togglesession();
     // alert("Access token is expired, please login again")
     localStorage.clear();
   }
   else if(resp.status==404){
     this.setState({errors: errors});
     
    }else if(resp.status==500){

     }
  
  else if(resp.status==409){
     this.setState({
       errors: errors
       });
    }
    else if(resp.status==400){
 }else if(resp.status==201){
   return resp.json();

   }
   
 }) .then(response=>{

      if(response.status==this.props.t('succees_condition')){
        this.toggle()
     
      
       
      }
      else if(response.status == this.props.t('failure_condition') && response.errorCode =="3104" && response.errorMessage == 'user_already_exists'){
                errors["userexists"]=this.props.t('user_already_exists_with_email')
              this.setState({
                errors: errors
                });
            
      }
      else if(response.status ==  this.props.t('failure_condition') && response.errorCode =="3123" && response.errorMessage == 'rfid_tag_issue'){
        var rfids = response.rfids[0];
      var errorRfid = rfids.rfidTag;
      var errorrfidMessage = rfids.errorReason;
           if(rfids.errorReason=='rfid_is_already_assigned'){
         
      this.setState({ message: errorRfid +"  is already assigned", colour: 'danger' })
          this.onShowAlert();
         }
      
}
else{}
       
     
     this.setState({
     errors: errors
     });

 })
 .catch(error => alert('Error:' + "Internal Server Error"));
}
  }
      this.setState({errors: errors});
  }

  // organizationChange(){
   
  //   var x = document.getElementById("organisation").value;
   

  //   let fields = this.state.fields;
  //   fields["organisation"] = x;
  //   this.setState({fields});
  //   if(x!=0){
    
  //     this.state.errors["organisationempty"] = " ";
     
  //   }
    
  // }
  componentDidMount(){
       document.getElementById("myCheck").checked = true;
       this.getAllOrganizations();
  }
  async getAllOrganizations(){
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
   await fetch(url,{
      method: "GET",
      headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
      })
      .then((resp) => {
        if(resp.status==401){
          // alert("Access token is expired, please login again")
          this.togglesession();
    
          localStorage.clear();
         
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        let fields = this.state.fields;
        fields.status = true;
        if(response!=undefined){
          let organisation="";
          response.organisationDTOS.map((org, index) => {
            if (org.identifier == "webasto") {
              organisation = org.id;
            }
          })
      this.setState({ dataArray:response.organisationDTOS,fields, selectedOrganization: organisation })   
        }
        this.getRoles();
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }
  getRoles(){
    var url = baseUrl.URLPath + "roles/";
    fetch(url,{
      method: "GET",
      headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
      })
      .then((resp) => {
        if(resp.status==401){
          // alert("Access token is expired, please login again")
          this.togglesession();
    
          localStorage.clear();
         
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        let fields = this.state.fields;
        fields.status = true;
        if(response!=undefined){
      this.setState({ roles:response.roles})
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }


render() {
const{t}=this.props
var getRole = localStorage.getItem("role")
if(getRole == "WEBASTO_SITE_ADMIN"){
  this.state.roledisplay = "block";
  this.state.defaultroledisplay = "none"
}
else{
  this.state.roledisplay = "none";
  this.state.defaultroledisplay = "block"
}

  const organisationDTOS =this.state.dataArray.map((dataArray, index) => {
    return(
      <option value={dataArray.id}>{dataArray.company}</option>
    )
   
  })
  const roles =this.state.roles.map((role, index) => {
    return(
      <option value={role.identifier}>{role.displayName}</option>
    )
   
  })
  return (
    <>
     <main class="content-div" >
     <p>{t('OAuth Users')}
              <div className="breadcrumb_div">
              <Link to="/oauthUsers">{t('OAuth Users')}</Link > &gt; <span className="breadcrumb_page">{t('add')}</span>
              </div>
          </p>
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div">Settings ><Link to="/userManagementController"> <span className="">Users > </span></Link>  Add User</div> */}
<div className="row">
  <div className="col-md-12">
    <div className="pull-left">
    <button type="button" data-test="button" className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
    </div>
  </div>
</div>
      <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>

          <div className="col-md-12 mb-3">
          
          <span class=" form-title">{t('user_details')}</span> 
         
       </div>
           
             {/* <MDBCol md="4" >
          <select className="browser-default custom-select select_height mb_8" id="organisation" onChange={this.organizationChange.bind(this)}  >                          
                          <option value="">-- Select Organization--</option>
                          {organisationDTOS}
          </select> 
          <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["organisationempty"]}</span></span> 
          </MDBCol> */}
          <div className="col-md-4 md-form">
            <input name="Email" type="text" id="email" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "email")} value={this.state.fields["email"]} />
            <label htmlFor="email" className={`${(this.state.fields['email']!="" && this.state.fields['email']!=null)? "active" : ""} ml-15`}>{t('e_mail')}</label>
            <div className="invalid-feedback">{t('provide_valid_email')}</div>
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["validemail"]}</span></span> 
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["emailempty"]}</span></span>
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidemail"]}</span></span> 
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["userexists"]}</span></span> 
          </div>
          <div className="col-md-4 md-form">
            <input name="firstName" type="text" id="firstName" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "firstName")} value={this.state.fields["firstName"]} />
            <label htmlFor="firstName" className={`${(this.state.fields['firstName']!="" && this.state.fields['firstName']!=null)? "active" : ""} ml-15`}>{t('firstName')}</label>
            <div className="invalid-feedback">{t('provide_valid_email')}</div>
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["firstName"]}</span></span> 
          </div>
          <div className="col-md-4 md-form">
            <input name="lastName" type="text" id="lastName" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "lastName")} value={this.state.fields["lastName"]} />
            <label htmlFor="lastName" className={`${(this.state.fields['lastName']!="" && this.state.fields['lastName']!=null)? "active" : ""} ml-15`}>{t('lastName')}</label>
            <div className="invalid-feedback">{t('provide_valid_email')}</div>
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["lastName"]}</span></span> 
          </div>
          <div className="col-md-4 md-form">
            <input name="mobileNumber" type="text" id="mobileNumber" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "mobileNumber")} value={this.state.fields["mobileNumber"]} />
            <label htmlFor="mobileNumber" className={`${(this.state.fields['mobileNumber']!="" && this.state.fields['mobileNumber']!=null)? "active" : ""} ml-15`}>{t('mobile_no')}</label>
            <div className="invalid-feedback">{t('provide_valid_email')}</div>
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["mobileNumber"]}</span></span> 
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["validphoneNo"]}</span></span> 
          </div>
            <MDBCol md="4" style={{display:this.state.defaultroledisplay}} >
            <select className="browser-default custom-select select_height mb_8" id="role" onChange={this.roleidChange.bind(this)} >
                          <option value=""> -- {t('select_role')} --</option>
                          <option value="WEBASTO_OAUTH_ADMIN">{t('webasto_oauth_admin')}</option>
                          {/* {roles} */}
          </select> 
          <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["role"]}</span></span> 
          </MDBCol>

          <MDBCol md="4" style={{display:this.state.roledisplay}}>
            <select disabled className="browser-default custom-select select_height mb_8" id="role" onChange={this.roleidChange.bind(this)} >
              <option value="">{t('end_user_commercal')}</option>
            </select> 
          </MDBCol>
       
            <MDBCol md="12" className="mt-4">
          <Form.Check 
              custom
             value={this.state.fields.status}
              onChange={this.activeChange}
               
              type="checkbox"
              id="myCheck"
              label={t('active')}
            />
            </MDBCol>

          
          </MDBRow>
          {/* <div className="error_msg w3-animate-top">  <span style={{color: "red"}} className="rfidexists"></span></div> 
          <div className="error_msg w3-animate-top">  <span style={{color: "red"}} className="employeeidexists"></span></div> */}

          <button type="button" data-test="button" className="mt-5 btn_primary mr-3" onClick={this.handleSubmit}> Save <i class="fas fa-save ml-2"></i></button>
         
        </form>
       
      </div>
         </MDBCol>
        </MDBRow>
        </div>
 
    </main>
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
      <MDBModalBody>
        {t('user_successfully_added')}
      </MDBModalBody>
      <MDBModalFooter>
      <button className="btn_primary"  data-test="button" type="button" onClick={this.tagOk}>{t('ok')}</button>
      </MDBModalFooter>
      </MDBModal>

      {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button className="btn_primary"  data-test="button" type="button" onClick={this.sessiontagOk}>{t('ok')}</button>
      </MDBModalFooter>
      </MDBModal>
    </>
  );
}
}

export default withTranslation()(addOauthUser);