import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './constants';
import  $ from "jquery";
import Datetime from 'react-datetime';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Header from './header';
import { createBrowserHistory } from 'history';
import { hasRole, hasPermission } from './auth';
import servicesUrl from '../common/servicesUrl';

import { withTranslation } from 'react-i18next';
const history = createBrowserHistory();


class viewChargingProfile extends React.Component{  
    constructor (props) {
        super(props);
        this.state = {
          scheduleId:"",
          hideActionBtn:"none", // show hide btn varible based on permission 
          writePermission:this.props.ChargePoint_W, // ChargePoint_W conndition
          fields:{},
          disabled:"true",
          chargingProfileId:"",
          page_title:"View Charging Profile",
          modalDelete:false,
          modalConfirmation:false,
          user:{
            permissions: [
                 
                ]
            },
        }
        
        this.deleteChargingProfile = this.deleteChargingProfile.bind(this)
        this.toggleConfirmation = this.toggleConfirmation.bind(this)
        this.togglesession = this.togglesession.bind(this)
        this.backToList = this.backToList.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.tagOk = this.tagOk.bind(this)
        this.toggleDeleteClose = this.toggleDeleteClose.bind(this)
        this.sessiontagOk = this.sessiontagOk.bind(this)
}

backToList(){
  this.props.history.push('/chargingProfile');
}

toggleDelete = () => {
  this.setState({
    modalDelete: !this.state.modalDelete
  });
}

tagOk = () => {
  // setTimeout(window.location.reload(), 3000);
  this.props.history.push('/chargingProfile');
}

toggleConfirmation = () => {
  this.setState({
    modalConfirmation: !this.state.modalConfirmation
  });
}


handleDelete(event){
  this.setState({
    modalConfirmation: !this.state.modalConfirmation
  });
 
  event.preventDefault();
  this.deleteChargingProfile();
}

toggleDeleteClose(){
  this.setState({
    modalConfirmation: !this.state.modalConfirmation
  });
}

async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}

sessiontagOk(){
  history.push('/');
  window.location.reload();
}

componentDidMount(){
  this.state.user.permissions = localStorage.getItem("roleAccess");
  if(this.props.location.state==null){
    this.state.chargingProfileId = this.state.chargingProfileId;
    this.state.scheduleId = this.state.scheduleId;
  }else{
    this.state.chargingProfileId = this.props.location.state;
    // this.state.scheduleId = this.props.location.state
  }
  this.getChargingProfile()
}

async getChargingProfile(){
  var timeZone=  localStorage.getItem("timezone");
  ;
  var url = baseUrl.URLPath + servicesUrl.getChargingprofilesByprofileId+ "?profileId="+this.state.chargingProfileId;
  await fetch(url, {
      method: "GET",
          headers: {
            'Content-Type': 'application/json',
            "Authorization":localStorage.getItem("Authorization")
          }
      })
    .then((resp) => {
      ;
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==500){
        alert("internal server error")
      }
      if(resp.status==200){
        return resp.json();
      } 
    }) 
    .then((response) =>{
      
      if(response!=undefined){
        this.setState({
          fields:response,
       })
       
      }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}

async deleteChargingProfile(){

  var timeZone=  localStorage.getItem("timezone");
  ;
  var url = baseUrl.URLPath + servicesUrl.getChargingProfilesWId+ "?profileId="+this.state.chargingProfileId;
    await fetch(url, {
      method: "DELETE",
          headers: {
            'Content-Type': 'application/json',
            "Authorization":localStorage.getItem("Authorization")
          }
      })
    .then((resp) => {
      ;
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==500){
        alert("internal server error")
      }
      if(resp.status==204){
        this.toggleDelete() 
      } 
      return resp.json();
    }) 
    .then((response) =>{
      
      // if(response!=undefined){
       
      //   this.setState({
      //     modalDelete: !this.state.modalDelete
      //   });
      // }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}

render() {
  const{t}=this.props
  let user = this.state.user;

//   var yesterday = Datetime.moment().subtract( 1, 'day' );
//   var valid = function( current ){
//       return current.isAfter( yesterday );
//   };
return(
  <>
     <main class="content-div" >
     <p>{t('chargingProfileId')}
              <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/chargingProfile">{t('chargingProfileId')}</Link > &gt; <span className="breadcrumb_page">{t('view')}</span>
              </div>
          </p>
      <div className="page-outerdiv">
        {/* breadCrumb */}
        {/* <div className="breadcrumb_div"><a> Charge Point > <Link to="/chargingProfile" > <span className="">Charging Profile </span></Link> > </a>View</div> */}
        {/* end breadCrumb */}
        <div className="row">
          <div className="col-md-12">
            <div className="pull-left">
            {hasPermission(user,['ChargingProfile_R']) && 
            <button class="pull-right btn_primary" type="button" data-test="button" onClick={this.backToList} >
            <i className="fas fa-angle-left mr-2"></i>  {t('back')}
          </button>
                  // <button className="refresh-btn mt-5" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
                // <MDBBtn color="primary" className="mt-5" onClick={this.backToList} >
                //     <span style={{color:"#fff"}}>Back</span>   
                // </MDBBtn>
}
            </div>
          
          </div>
        </div>
        <form
          className='needs-validation'
          onSubmit={this.toggle1}
          noValidate
        >
          <div className="row mb-4 mt-3">
            <div className="col-sm-6">
              <div className="content-innerdiv">
              <div className="" >
                 <label>
                   {t('charging_profilename')}:
                 </label>
                 <label >
                 {this.state.fields["chargingProfileName"]}
                 </label>
               </div>
               <div className=" ">
                 <label>
                 {t('charging_profilepurpose')}:
                 </label>
                 <label>
                   {this.state.fields["chargingProfilePurpose"]}
                 </label>
               </div>
               <div className="">
                 <label>
                 {t('recurrency_kind')}:
                 </label>
                 <label>
                 {this.state.fields["recurrencyKind"]}
                 </label>
               </div>
               <div className="">
                 <label>
                   {t('valid_from')}:
                 </label>
                 <label>
                 {this.state.fields["validFrom"] ? moment(this.state.fields["validFrom"]).format("DD-MMM-YYYY HH:mm").toUpperCase() : null}
                 </label>
               </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="content-innerdiv">
              <div className="">
                 <label>
                    {t('stack_level')}:
                 </label>
                 <label>
                 {this.state.fields["stackLevel"]}
                 </label>               
               </div>
               <div className="">
                 <label>
                  {t('charging_profile_kind')}:
                 </label>
                 <label>
                 {this.state.fields["chargingProfileKind"]}
                 </label>
               </div>
               <div className="">
                 <label>
                 {t('Charging Schedule')}:
                 </label>
                 <label>
                 <Link to={{
                pathname:'/viewchargingSchedule', 
                 state:this.state.fields["scheduleId"]
                }}>{this.state.fields["chargingSchedule"]}
              </Link>
                 
                 </label>
               </div>
               <div className="">
                 <label>
                   {t('valid_to')}: 
                 </label>
                 <label>
                 {this.state.fields["validTo"] ? moment(this.state.fields["validTo"]).format("DD-MMM-YYYY HH:mm").toUpperCase() : null}
                 </label>
               </div>
              </div>
              <div className="col-md-12 text-right">
              {hasPermission(user,['ChargingProfile_D']) &&
              <button className="btn_secondary mt-5 mr-3" onClick={this.toggleConfirmation}  id="deleteBtn" type="button" data-test="button"> {t('delete')} <i class="fas fa-trash-alt ml-2"></i></button> 
                  // <MDBBtn color="danger" className="mt-5 mr-3" onClick={this.toggleConfirmation}  id="deleteBtn" >
                  //   Delete
                  // </MDBBtn>
                }
                 {hasPermission(user,['ChargingProfile_U']) && 
                  <Link to={{
                  pathname:'/editChargingProfile', 
                  state: this.state.chargingProfileId, 
                  }}>
                    <button className="btn_primary mt-5 mr-3" onClick={this.editChargingProfile}  id="editBtn" type="button" data-test="button">{t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                    {/* <MDBBtn color="primary" className="mt-5 mr-3" onClick={this.editChargingProfile}  id="editBtn" >
                      Edit
                    </MDBBtn> */}
                  </Link>
                 }
                  {/* {hasPermission(user,['ChargingProfile_R']) && 
                  <button className="refresh-btn mt-5" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
                // <MDBBtn color="primary" className="mt-5" onClick={this.backToList} >
                //     <span style={{color:"#fff"}}>Back</span>   
                // </MDBBtn>
} */}
              </div>

            </div>
          </div>
        {/* <MDBRow className="mb-4 mt-3" >
       
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row" >
                 <label className="w-40">
                   Charging Profile Name:
                 </label>
                 <label >
                 {this.state.fields["chargingProfileName"]}
                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
            <div className="view-page-row view-bg-row">
                 <label className="w-40">
                    Stack Level:
                 </label>
                 <label>
                 {this.state.fields["stackLevel"]}
                 </label>               
               </div>
               </MDBCol>
               {/* <MDBCol sm="6"> */}
              {/* <div className="view-page-row ">
                 <label className="w-40">
                   Transaction Id:
                 </label>
                 <label>
                 {this.state.fields["transactionId"]}
                 </label>
               </div>
               </MDBCol> 
               <MDBCol sm="6">
              <div className="view-page-row ">
                 <label className="w-40">
                 Charging Profile Purpose:
                 </label>
                 <label>
                   {this.state.fields["chargingProfilePurpose"]}
                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
              <div className="view-page-row view-bg-row">
                 <label className="w-40">
                  Charging Profile Kind:
                 </label>
                 <label>
                 {this.state.fields["chargingProfileKind"]}
                 </label>
               </div>
               </MDBCol>
               <MDBCol sm="6">
               <div className="view-page-row view-bg-row">
                 <label className="w-40">
                 Recurrency Kind:
                 </label>
                 <label>
                 {this.state.fields["recurrencyKind"]}
                 </label>
               </div>
              </MDBCol>
              <MDBCol sm="6">
               <div className="view-page-row view-bg-row">
                 <label className="w-40">
                 Charging Schedule:
                 </label>
                 <label>
                 <Link to={{
                pathname:'/viewchargingSchedule', 
                 state:this.state.fields["scheduleId"]
                }}>{this.state.fields["chargingSchedule"]}
              </Link>
                 
                 </label>
               </div>
              </MDBCol>
              <MDBCol sm="6">
               <div className="view-page-row view-bg-row">
                 <label className="w-40">
                   Valid From:
                 </label>
                 <label>
                 {this.state.fields["validFrom"] ? moment(this.state.fields["validFrom"]).format("DD-MMM-YYYY HH:mm").toUpperCase() : null}
                 </label>
               </div>
              </MDBCol>
              <MDBCol sm="6">
               <div className="view-page-row view-bg-row">
                 <label className="w-40">
                   Valid To: 
                 </label>
                 <label>
                 {this.state.fields["validTo"] ? moment(this.state.fields["validTo"]).format("DD-MMM-YYYY HH:mm").toUpperCase() : null}
                 </label>
               </div>
              </MDBCol>
             
              <MDBCol md="12" className="text-right">
                {hasPermission(user,['ChargingProfile_D']) && 
                  <MDBBtn color="danger" className="mt-5 mr-3" onClick={this.toggleConfirmation}  id="deleteBtn" >
                    Delete
                  </MDBBtn>
                }
                 {hasPermission(user,['ChargingProfile_U']) && 
                  <Link to={{
                  pathname:'/editChargingProfile', 
                  state: this.state.chargingProfileId, 
                  }}>
                    <MDBBtn color="primary" className="mt-5 mr-3" onClick={this.editChargingProfile}  id="editBtn" >
                      Edit
                    </MDBBtn>
                  </Link>
                 }
                  {hasPermission(user,['ChargingProfile_R']) && 
                <MDBBtn color="primary" className="mt-5" onClick={this.backToList} >
                    <span style={{color:"#fff"}}>Back</span>   
                </MDBBtn>
}
              </MDBCol>  
          </MDBRow>*/}
        </form>
      </div>
      <MDBModal isOpen={this.state.modalDelete}  size="md" className="model_top">
        <MDBModalHeader toggle={this.toggleDelete}>{t('success')}</MDBModalHeader>
        <MDBModalBody>
           {t('charging_profile_deleted')}
        </MDBModalBody>
        <MDBModalFooter>
        <button type="button" data-test="button" onClick={this.tagOk} className="btn_primary">{t('OK')}</button>
        </MDBModalFooter>
      </MDBModal>
      <MDBModal isOpen={this.state.modalConfirmation} toggle={this.toggleConfirmation} size="md" className="model_top">
        <MDBModalHeader toggle={this.toggleConfirmation}> {t('confirmation')} </MDBModalHeader>
          <MDBModalBody>
          {t('you_want_to_delete')}
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" data-test="button" onClick={this.toggleDeleteClose} className="btn_primary">{t('close')}</button>
          <button type="button" data-test="button" onClick={this.handleDelete} className="btn_primary">{t('yes')}</button>
          </MDBModalFooter>
      </MDBModal>
      <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
             <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">{t('ok')}</button>
            </MDBModalFooter>
        </MDBModal>
    </main>
    </>
  );
}
}

export default withTranslation()(viewChargingProfile);




