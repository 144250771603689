import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './constants';
import  $ from "jquery";
import Datetime from 'react-datetime';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();

class Ocppnewtag extends React.Component{  
    constructor (props) {
        super(props);
        this.state = {
            fields:{ idTag: '', parentIdTag: '', expiryDate:'', inTransaction: '', note: '', blocked:'',tagType:''},
            expdate:'',
            sucess:{},
            errors:{},
            disabled:"true",
            id:"",
            activeItem:"1",
            isChecked:"1",
            startDate1:"",
            startDate:new Date(),
            model1:"false",
            model2:"false",
            par_id_tag:"",
            page_title:"View OCPP Tag",
            disabledAttr:true,
            modalsession:false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.redirectToListPage = this.redirectToListPage.bind(this)
        this.toggle1 = this.toggle1.bind(this)
        this.toggleChange = this.toggleChange.bind(this)
       this.toggle2 = this.toggle2.bind(this)
       this.tagOk2 = this.tagOk2.bind(this)
       this.handleChange = this.handleChange.bind(this)
       this.transactionChange = this.transactionChange.bind(this)
       this.togglesession = this.togglesession.bind(this)
       this.rfidTypeChange=this.rfidTypeChange.bind(this);
}

rfidTypeChange(){
  let val=document.getElementById('rfidTagType').value;
        let field=this.state.fields;
        field["tagType"]=val;
        this.setState({
          fields:field,
          disabledAttr:""
        });
        
}
async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  history.push('/');
  window.location.reload();
}

toggle1(event){
  this.setState({
    modal1: !this.state.modal1
  });
}
toggle2 = () => {
  this.setState({
    modal2: !this.state.modal2
  });
}
tagOk2(){
   this.props.history.push('/Ocpptags');
 }

handleChange = date => {
  this.setState({startDate:date});

  var currentDate = new Date(date);
var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  

this.setState({startDate1:createdDateTo});
this.setState({disabledAttr:""});
};

componentDidMount(){
  
  ;
  var timeZone=  localStorage.getItem("timezone");
    var id_tag = this.props.location.state;
    this.state.id = id_tag;
     var url = baseUrl.URLPath + servicesUrl.getOcpptagById + "?id=" + this.state.id;
    fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization"),
       
          "timeZone":timeZone
         }
        })
        .then((resp) => {
          if(resp.status==401){
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
        
        }) 
        .then((response) =>{

          var data1 =[];
          data1["idTag"] = response.idTag;
          if(response.parentIdTag!=null){
            data1["parentIdTag"] = response.parentIdTag.idTag;
          }else{
            data1["parentIdTag"] = "";
          }
          data1["expiryDate"] = response.expiryDate;
          data1["inTransaction"] = response.inTransaction;
          data1["blocked"] = response.blocked;
          data1["note"] = response.note;
          data1["tagType"]=response.tagType;
        this.setState({
            fields:data1,
        })
        this.setState({
          startDate1:this.state.fields.expiryDate
        })
      
        if(this.state.fields.expiryDate!=null && this.state.fields.expiryDate!="" && this.state.fields.expiryDate!=undefined){
          this.setState({startDate:new Date(this.state.fields.expiryDate)})
        }else{
          this.setState({startDate: ""})
        }
        
        
        if(this.state.fields.blocked=="1"){ 
          document.getElementById("myCheck").checked = true;
        }
        if(this.state.fields.inTransaction=="1"){
          document.getElementById("transcheck").checked = true;
        }
        })
        .catch((error) => {
          //alert(error);
          console.log(error, "catch the loop")
     })
     $(".rdt").addClass("cal_disable");
   this.setState({id:this.props.location.state})
  }
toggleChange = () => {
  
  var x = document.getElementById("myCheck").checked;  
  if(x){
      this.state.fields.blocked="1";
       }
     else{
       this.state.fields.blocked="0";
   }
   this.setState({disabledAttr:""});
    
}
transactionChange = () => {
 
 var y = document.getElementById("transcheck").checked;  
  if(y){
      this.state.fields.inTransaction="1";
     }
     else{
       this.state.fields.inTransaction="0";
      }
      this.setState({disabledAttr:""});

}


async handleSubmit(event){
  
  event.preventDefault();
  this.setState({
    modal1: !this.state.modal1
  });
    let errors = {};
      let url = baseUrl.URLPath + "ocpptags";
      //localhost:8080/cpms/ocpptags
    let data = {"idTag": this.state.fields.idTag, "expiryDate": this.state.startDate1, "inTransaction": this.state.fields.inTransaction, "note": this.state.fields.note, "blocked": this.state.fields.blocked,"parentIdTag":{"idTag":this.state.fields.parentIdTag},"tagType":this.state.fields.tagType}; 
    await fetch(url, {
      method: 'PUT', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
       headers:{
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization"),
       
       }
      }).then(resp =>{
           
        if(resp.status==401){
          this.togglesession();
          localStorage.clear();
        }
        else if(resp.status==404){
       errors["entervalidID"]="Please enter ID."
          this.setState({errors: errors});
          return resp.json();
         }else if(resp.status==500){
           alert("internal server error")
        } else if(resp.status==400){
            return resp.json();
              ;
        }
      else{
        this.toggle2()
        }
      })
      .then(response=>{
        if(response !=null && response.hasOwnProperty("message"))
        {
          errors["equalids"] = response.message;       
        }
       
          this.setState({
          errors: errors
          });
          
      })
      .catch(error => console.log('Error:' + error));
      this.setState({errors:errors});
    
}
redirectToListPage(){
  this.props.history.push('/Ocpptags')
}
changeHandler(field, e){ 
let fields = this.state.fields;
fields[field] = e.target.value; 
this.setState({fields});
this.setState({fields,disabledAttr:""});
}

countryChange(){
  var x = document.getElementById("parentIdTag").value;
  let fields = this.state.fields;
  fields["parentIdTag"] = x; 
  this.setState({fields});
}

//  Enable all the inputs for editing onclick of edit button 
  editTag(){
    this.setState({disabled:false})
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("saveBtn").style.visibility = "visible";
    
  }

render() {
    var yesterday = Datetime.moment().subtract( 1, 'day' );
    var valid = function( current ){
        return current.isAfter( yesterday );
    };
  return (
    <>
  <main class="content-div" >
  <p>Authorization
              <div className="breadcrumb_div">
              Charge Point &gt; <Link to="/Ocpptags" > <span className="">Authorization</span></Link>  &gt;  <Link to={{
              pathname:'/viewTag', 
              state: this.state.id, 
              }}>View &gt; </Link> <span className="breadcrumb_page">Edit</span>
              </div>
          </p>
        <div className="page-outerdiv">
        
        <MDBRow className="mb-4  mt-3">
            <MDBCol sm="12">
            <div class="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{display:"none"}} >
         </div>

               <div>
        <form
          className='needs-validation'
          onSubmit={this.toggle1}
          noValidate
        >
          <div className="viewInput row">
          <div className="col-md-4 md-form">
              <input disabled="true" name="tag" type="text" id="idTag" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "idTag")} value={this.state.fields["idTag"]} />
              <label htmlFor="idTag" className={`${(this.state.fields['idTag']!="")? "active" : ""} ml-15`}>ID Tag</label>
          </div>
          {/* <div className="col-md-4 md-form">
              <input name="parentTag" type="text" id="parentIdTag" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "parentIdTag")} value={this.state.fields["parentIdTag"]} />
              <label htmlFor="parentIdTag" className={`${(this.state.fields['parentIdTag']!="")? "active" : ""} ml-15`}>Parent ID Tag</label>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["childtag"]}</span></span> 
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["parentchild"]}</span></span>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["parenttag"]}</span></span>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidtag"]}</span></span> 
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["equalids"]}</span></span>
          </div>             */}
             
            {/* <MDBCol md="4" className="mt-3">
            
              <Form.Check 
              custom
              value="isChecked"
              onChange={this.toggleChange}
              type="checkbox"
              id="myCheck"
              label="Blocked"
            />
            </MDBCol> */}
             
          <div className="col-md-4 md-form">
                <input name="noteId" type="text" id="noteId" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "note")} value={this.state.fields["note"]} />
                <label htmlFor="noteId" className={`${(this.state.fields['note']!="" && this.state.fields['note']!=null)? "active" : ""} ml-15`}>Additional Note</label>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["childtag"]}</span></span> 
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["parentchild"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["parenttag"]}</span></span>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidtag"]}</span></span> 
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["equalids"]}</span></span> 
            </div>
            <MDBCol md="4"></MDBCol>
            <MDBCol md="4">
            <span className="cal_label mr-2"> Expiry Date/Time</span>
            <div className="pull-left mt-2" style={{width:"55%"}} >
            <DatePicker
                selected={this.state.startDate}
                onChange={this.handleChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                id="expiryDateId" 
                className="cal_margin tag_cal"
                timeCaption="time"
                dateFormat="dd-MMM-yyyy HH:mm"
              />

            </div>
            <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{color: "red"}}>{this.state.errors["expirydate"]}</span></div> 

          </MDBCol>
            <MDBCol md="4" className="">
              <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">
                <b style={{fontSize:"14px"}}>RFID Tag Type * <span className="custom_tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                <div className="custom_tooltiptext">
                  <div className="custom_tooltip_header">RFID Tag Type</div>
                  <div className="custom_tooltip_body">
                    <div>Type of the RFID Tag</div>
                    <div><span className="font-bold">Private: </span>An RFID tag intended for private use.</div>
                    <div><span className="font-bold">Company: </span>An RFID tag that was issued by a company</div>
                    <div><span className="font-bold">Other: </span>Default setting, no further logic involved here.</div>
                  </div>
                </div>
                </span></b> </label>
              <select className="browser-default custom-select select_height w_59 indent" id="rfidTagType" onChange={this.rfidTypeChange} value={this.state.fields.tagType}>
                <option value="other">Other</option>
                <option value="company">Company</option>
                <option value="private">Private</option>
              </select>
              <div className="mt-3"><span className="error_msg w3-animate-top error_rfidTagType" style={{color: "red"}}></span></div>
            </MDBCol>
          {/* <div className="col-md-4 md-form">
              <input name="noteId" type="text" id="noteId" className="form-control text_lowercase" onChange={this.changeHandler.bind(this, "note")} value={this.state.fields["note"]} />
              <label htmlFor="noteId" className={`${(this.state.fields['note']!="")? "active" : ""} ml-15`}>Additional Note</label>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["childtag"]}</span></span> 
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["parentchild"]}</span></span>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["parenttag"]}</span></span>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidtag"]}</span></span> 
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["equalids"]}</span></span>
          </div>  */}
            {/* <MDBCol md="4" className="mt-3" id="chkbox-down">
               <Form.Check 
              custom
               value={this.state.isChecked}
               onChange={this.transactionChange}
               type="checkbox"
              id="transcheck"
              label="Transaction"
            />
            </MDBCol> */}
            
           
            <MDBCol md="12" className="text-right">
            <button className="mt-5 mr-3 btn_primary" disabled={this.state.disabledAttr} onClick={this.toggle1} id="saveBtn" type="button" data-test="button">
            Save<i class="fas fa-save ml-2"></i>
            </button>
            <Link to={{
              pathname:'/viewTag', 
              state: this.state.id, 
              }}>
            <button className="mt-5 btn_cancel" type="button" data-test="button">
            Cancel <i class="fas fa-times ml-2"></i>
            </button>
            </Link>
            </MDBCol>  
              
          </div>
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
        </div>
        <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle1}>Confirmation</MDBModalHeader>
<MDBModalBody>
 Are you sure you want to save the changes?
</MDBModalBody>
<MDBModalFooter>
<button type="button" data-test="button" className="btn_primary" onClick={this.toggle1}>Close</button>
<button type="button" data-test="button" className="btn_primary" onClick={this.handleSubmit}>Yes</button>
</MDBModalFooter>
</MDBModal>


<MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle2}>Success</MDBModalHeader>
<MDBModalBody>
 OCPP Tag updated successfully.
</MDBModalBody>
<MDBModalFooter>
<button type="button" data-test="button" className="btn_primary" onClick={this.tagOk2}>OK</button>
</MDBModalFooter>
</MDBModal>


{/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
      </MDBModalFooter>
      </MDBModal>
    </main>

    </>
  );
}
}

export default Ocppnewtag;