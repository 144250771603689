import React from 'react';
import Chart from "react-apexcharts";
import * as baseUrl from '../constants';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { withTranslation } from 'react-i18next';

class reservationStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userValue:[],
      chart_Date:[],
      chart_Count:[],
      startDate:null,
      value:[null, null],
      series: [
                {
                  data:[]
                },
              ],
      options : {
        chart: {
          width:'100%',
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '40%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
          fill:'#00529e'
        },
        xaxis:{
          categories:[],
        },
        fill:{
          opacity:1,
          colors: ['#ff9b49']
        },
        tooltip: {
          enabled: false,
         }
      },
  }
  this.onChange = this.onChange.bind(this);
}



//  api to get charging session chart
componentDidMount(){

  var today = new Date(),
  date = ('0' + (today.getMonth()+1)).slice(-2) +'/'+('0' + (today.getDate())).slice(-2)+'/'+today.getFullYear();

   var now = new Date();
   now.setDate(now.getDate() - 7); // add -7 days to your date variable 

   this.state.startDate = moment(now).format("MM/DD/YYYY").toUpperCase();
   this.state.endDate = date; 
   var garphInitData = [this.state.startDate,this.state.endDate]
   this.setState({
     value:garphInitData
   })
  this.getChargingSessionData();

}

getChargingSessionData(){
var chart_Date = [];
var chart_Count = [];
let chargePointId = localStorage.getItem("chargerId");
if(chargePointId != null){
var url = baseUrl.URLPath + "dashboard/reservations?startDate="+moment(this.state.startDate).format("YYYY-MM-DD").toUpperCase()+"&endDate="+moment(this.state.endDate).format("YYYY-MM-DD").toUpperCase()+"&chargePointId="+chargePointId;

}
else{
var url = baseUrl.URLPath + "dashboard/reservations?startDate="+moment(this.state.startDate).format("YYYY-MM-DD").toUpperCase()+"&endDate="+moment(this.state.endDate).format("YYYY-MM-DD").toUpperCase()+"&chargePointId=";
}
 fetch(url,{
  method: "GET",
  headers: {
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization")
      }
  })
  .then((resp) => {
    ;
    if(resp.status==401){
      this.togglesession();
      localStorage.clear();
    }
    if(resp.status==200){
      return resp.json();
    }
  }) 
  .then((response) =>{
    ;
    if(response!=undefined){
      response = response.reverse();
    for(var i=0; i<response.length; i++){
     var date = moment(response[i].date).format("DD-MMM");
      chart_Date.push(date);
      chart_Count.push(response[i]["count"]);
    }
    var series = [
                  {
                    data:chart_Count
                  },
                ]; 
    var data_options =  {
        xaxis: {
          categories:chart_Date,
        },
      };
     this.setState({
      options:data_options,
      series:series
     })
    }
  })
  .catch((error) => {
      console.log(error, "catch the loop")
  })
}

// date picker onchange function
onChange(value, event){
  
  if(value != null && value != ""){
    var startDate = moment(value[0]).format("MM/DD/YYYY").toUpperCase();
    var endDate =  moment(value[1]).format("MM/DD/YYYY").toUpperCase();
     this.setState({
        value:value,
       
      })
      this.state.startDate = startDate;
      this.state.endDate = endDate;
      this.getChargingSessionData();
  }else{
    this.setState({
      value:value
    })
  }
}

render() {
        return (
            <>
            <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                <div class="content-box">
                    <div class="dashboard-subtitle">
                       {this.props.t('Reservations')}
                    </div>
                   
                    <div className="mt-2 mb-5">
                    <DateRangePicker
                        onChange={this.onChange}
                        value={this.state.value}
                        selectRange = "true" 
                        dayPlaceholder = ""
                        yearPlaceholder = ""
                        monthPlaceholder = ""
                        format="MM/d/y"
                        nativeInputAriaLabel ="Date"
                    />
                    </div>
                    <div className="mt-3 bottom-pos pb-3 pr-3 mr-15 w-100">
                        {/* <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/graph5.png"} alt="logo" className="w-100" /> */}
                        <Chart
                          options={this.state.options}
                          series={this.state.series}
                          data={this.state.chart_Date}
                          type="bar"
                          height="240"
                          width="100%"/>
                    </div>
                </div>
            </div>
            </>
        )
    };
  }

export default withTranslation()(reservationStatus);
