import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { Typeahead } from 'react-bootstrap-typeahead';
import $ from "jquery";
import Calendar from 'react-calendar';
import { withTranslation } from 'react-i18next';
import { dateRangeValidate, activeDateFormat } from './_methods';

const history = createBrowserHistory();

class Unknown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpIdSorting: false,
      errors: {},
      saveSelectedCpid: "",
      selectFindStatus: false,
      startDate1: '',
      startDate2: '',
      intial_data: [],
      options: [],
      selectDataArray: [],
      multiple: false,
      page_title: "Unknown Charge Points",
      dataArray: [
        {

          chargepointid: '1',
          address: '',
          noofattempts: '',
          updatedDate: ''
        },
      ],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      modalsession: false,
      startDate: "",
      toDate: "",
      calenderFrom: "none",
      calenderTo: "none",
    }
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.chargepointIDChange = this.chargepointIDChange.bind(this);
    this.getUnknownCP = this.getUnknownCP.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getPartialSearchList = this.getPartialSearchList.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.GetFromDate = this.GetFromDate.bind(this);
    this.exportUnknowncpCSV = this.exportUnknowncpCSV.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
    this.keyPress1 = this.keyPress1.bind(this);
    this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);



    this.fromTime = React.createRef();
    this.toTime = React.createRef();
  }
  keyPress1(e) {
    ;
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ startDate: e.target.value });
        var dateFormat = moment(this.state.startDate).format("YYYY-MM-DD");
        this.state.startDate1 = dateFormat;
        // if(dateRangeValidate(this.state.startDate1,this.state.startDate2)==true){
        //   $('.date_range_error').text('');
          this.getUnknownCP(this.state.pageSize, 1);
        // }else{
        //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        // }
        $('.future_err1').text("");
      } else {
        this.setState({ startDate: "" });
        this.state.startDate1 = "";
        $('.future_err1').text("You can't select future date");
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate1 = "";
      this.getUnknownCP(this.state.pageSize, 1);
      $('.future_err1').text("");
    }
  }
  keyPress2(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ toDate: e.target.value });
        var dateFormat = moment(this.state.toDate).format("YYYY-MM-DD");
        this.state.startDate2 = dateFormat;
        // if(dateRangeValidate(this.state.startDate1,this.state.startDate2)==true){
        //   $('.date_range_error').text('');
          this.getUnknownCP(this.state.pageSize, 1);
        // }else{
        //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        // }
        $('.future_err2').text("");
      } else {
        this.setState({ toDate: "" });
        this.state.startDate2 = "";
        $('.future_err2').text("You can't select future date");
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate2 = "";
      this.getUnknownCP(this.state.pageSize, 1);
      $('.future_err2').text("");
    }
  }
  changeScheduleFrom(e) {
    this.setState({ startDate: e.target.value });
  }
  changeScheduleTo(e) {
    this.setState({ toDate: e.target.value });
  }
  GetFromDate = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ startDate: fdate });
    this.state.startDate1 = this.formatCalDate(date);
    // if (dateRangeValidate(this.state.startDate1, this.state.startDate2) == true) {
      $('.date_range_error').text('');
      this.getUnknownCP(this.state.pageSize, 1);
      var calenderFrom = "none";
      this.setState({
        calenderFrom: calenderFrom
      })
    // } else {
    //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var calenderFrom = "none";
    //   this.setState({
    //     calenderFrom: calenderFrom
    //   })
    // }
  };

  // get date 
  formatCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day,].join('-');

  }
  dateChange = date => {
    var fdate = this.formatCalDate1(date);

    this.setState({ toDate: fdate });
    this.state.startDate2 = this.formatCalDate(date);
    //if (dateRangeValidate(this.state.startDate1, this.state.startDate2) == true) {
      $('.date_range_error').text('');
      this.getUnknownCP(this.state.pageSize, 1);
      var calenderTo = "none";
      this.setState({
        calenderTo: calenderTo
      })
    // } else {
    //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var calenderTo = "none";
    //   this.setState({
    //     calenderTo: calenderTo
    //   });
    // }
  }
  formatCalDate1(date) {
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }

  ClearFromTime(ref, event) {
    this.state.selectFindStatus = false;
    let errors = {};
    this.setState({ startDate: "" });
    this.state.startDate1 = this.formatCalDate("October 13, 2014");
    errors["chargePointNotExists"] = "";
    this.setState({ errors: errors });
    this.state.saveSelectedCpid = "";
    this.getUnknownCP(this.state.pageSize, 1);
    this.setState({ dataArray: this.state.dataArray, errors: errors });
  }

  ClearToTime(ref, event) {
    this.state.selectFindStatus = false;
    let errors = {};
    this.setState({ toDate: "" });
    this.state.startDate2 = this.formatCalDate("");
    errors["chargePointNotExists"] = "";
    this.setState({ errors: errors });
    this.state.saveSelectedCpid = "";
    this.getUnknownCP(this.state.pageSize, 1);
    this.setState({ dataArray: this.state.dataArray, errors: errors });
  }

  ClearChargepoint(ref, event) {
    this.state.selectFindStatus = false;
    let errors = {};
    this.state.errors["selectChargepoint"] = "";
    $("#notexists").text("");
    this.state.saveSelectedCpid = "";
    this.getUnknownCP(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    this.setState({
      dataArray: this.state.dataArray, errors: errors
    });
    ref.current.clear();
    this.setState({
      chargePoint: ""
    })
  }
  FindCpName(ref, event) {
    
    this.state.saveSelectedCpid = ref.current.inputNode.value;
    this.state.selectFindStatus = true;
    this.getUnknownCP(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);

    if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
      this.state.errors["selectChargepoint"] = this.props.t('select_the_charge_point');
      $("#notexists").text(" ");

    } else {
      this.state.errors["selectChargepoint"] = " ";
      this.getUnknownCP(this.state.pageSize, this.state.activePage, "click");


    }
  }

  // search filter 
  handleKeyDown(ref, e) {

    var serial_no = e.target.value;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
    var getList = this.state.intial_data;

    if (serial_no != 0) {
      this.state.errors["selectChargepoint"] = "";
    }

    if (key == "Backspace" || key == "Delete") {
      this.state.errors["selectChargepoint"] = "";
      $("#notexists").text("");
      
      var x = e.target.value
      var delitem = x.slice(0, x.length - 1)
      if (delitem == "") {

        this.state.saveSelectedCpid = delitem;

        this.getUnknownCP(this.state.pageSize, this.state.activePage);

      }
    }
    var sno = serial_no.slice(0, serial_no.length - 1)
    this.getPartialSearchList(sno);
    if (e.keyCode == 13) {
      this.FindCpName(ref, e);
      $("#rbt_id").hide();

    }
    if ((e.keyCode == 86 && ctrl)) {
      this.getPartialSearchList(serial_no)


    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;


        }
        break;

    }

  }
  // api to get partial search list

  getPartialSearchList(cpid) {

    var cp_id = [];

    var url = baseUrl.URLPath + "chargepoints/unknown/partial?cpid=" + cpid;
    //localhost:80/cpms/api/chargepoints/unknown/partial?cpid=3155
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {
          var option = [];
          var data = [];
          this.setState({
            options: response,
            intial_data: response
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }


  async togglesession() {

    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
  showEntriesChange() {

    var entries = document.getElementById("showEntries").value;
    // this.state.data.clear();
    // this.setState({pageSize:entries});
    this.state.pageSize = entries;
    this.state.activePage = 1;
    this.getUnknownCP(entries, 1);
  }
  handlePageChange(pageNumber) {


    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getUnknownCP(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }

  getUnknownCP(pageSize, pageNumber, action) {
    var timeZone = localStorage.getItem("timezone");

    // var page = parseInt(pageNumber) -1;
    if (action == "click" || action == "enter") {
      var url = baseUrl.URLPath + "chargepoints/unknown?chargePointId=" + this.state.saveSelectedCpid + "&pageSize=" + pageSize + "&pageNo=" + 1 + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;
    }
    else {
      var url = baseUrl.URLPath + "chargepoints/unknown?chargePointId=" + this.state.saveSelectedCpid + "&pageSize=" + pageSize + "&pageNo=" + pageNumber + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "timeZone": timeZone,
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {
          if (response.count.count == 0) {
            if (action == "click" || action == "enter") {
              $("#notexists").text("Charge point id not exists.");
            }
            this.setState({
              dataArray: [],
              noOfRecords: 0,
              selectDataArray: []
            })
          } else {
            this.setState({
              dataArray: response.unknownCPList,
              noOfRecords: response.count.count,
              selectDataArray: response.unknownCPList


            })
            if (action == "click" || action == "enter") {
              $("#notexists").text(" ");
            }
          }
        }


      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  componentDidMount() {

    let fromDate = moment().subtract(29, 'd').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');

    let startdate = moment().subtract(29, 'd');
    let enddate = moment();

    this.state.startDate = this.formatCalDate1(startdate);
    this.state.toDate = this.formatCalDate1(enddate);

    this.state.startDate1 = fromDate;
    this.state.startDate2 = toDate;

    this.getUnknownCP(this.state.pageSize, this.state.activePage);
  }
  getChargePoint(event) {

    var chargePoint = event.target.value;
    this.setState({
      chargePoint: chargePoint
    })
    if (chargePoint.length == 1) {
      this.getUnknownCP(this.state.pageSize, this.state.activePage)
      this.setState({
        dataArray: this.state.dataArray,
      });
    }
  }

  // function to get chargepointId based on search filter

  chargepointIDChange(cpid) {


    this.state.selectFindStatus = true;

    this.state.saveSelectedCpid = cpid
    this.setState({ saveSelectedCpid: cpid })

    if (cpid.length == 0) {

      this.getUnknownCP(this.state.pageSize, this.state.activePage);
      this.setState({
        dataArray: this.state.dataArray,
      });
    } else {


      this.getUnknownCP(this.state.pageSize, this.state.activePage, cpid);
    }

  }

  exportUnknowncpCSV() {
    var timezone = moment().tz(moment.tz.guess()).format('z');
    var url = baseUrl.URLPath + "chargepoints/unknown/download/CSV?chargePointId=" + this.state.saveSelectedCpid + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": timezone
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
      })
      .then((response) => {

        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Unknown_CP_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }

  ascendingOrder() {
    // this.setState({dataArray : this.state.dataArray.reverse()})
    if (this.state.cpIdSorting == false) {
      this.state.dataArray.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = true;
      this.setState({ dataArray: this.state.dataArray.sort(this.dynamicSort("chargePointId")) })
    }
    else {
      this.state.dataArray.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = false;
      this.setState({ dataArray: this.state.dataArray.reverse() });
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {

        return a[property].localeCompare(b[property]);
      }
    }
  }

  //function to display calender popup
  displayCalender(type, event) {
    var calenderFrom = "";
    var calenderTo = "";
    if (type == "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }
    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }

  //function to close calender popup
  closeCalender(type, event) {
    if (type == "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }

  render() {

    const{t}=this.props
    const ref = React.createRef();

    const data = this.state.selectDataArray.map((dataArray, index) => {

      var stringDate = dataArray.updatedDate;
      if (dataArray.updatedDate == null || dataArray.updatedDate == "") {
        var date = "";
        var date1 = "";
      } else {
        var date = moment(stringDate).format("DD MMM YYYY");
        var date1 = moment(stringDate).format("HH:mm");
      }
      if (dataArray.ipAddress == "null" || dataArray.ipAddress == "") {
        dataArray.ipAddress = "";
      }
      else {
        dataArray.ipAddress = dataArray.ipAddress;
      }


      return (
        <tr>
          <td className="">{dataArray.chargePointId}</td>
          <td className="">{dataArray.ipAddress}</td>
          {/* <td>{dataArray.createdTime}</td>*/}
          <td className="wrap-word">{date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
          <td className="">{dataArray.noOfAttempts}</td>
        </tr>
      )

    })
    return (
      <>
        <main class="content-div" >
          <p>{t('Unknown Charge Points')}</p>
          <div className="page-outerdiv">
            {/* end page menu bar  */}
            <div className="row mt-2">
              <div className="col-md-4 pull-right">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    minLength={3}
                    placeholder={t('choose_a_charge_point_id')}
                    onChange={this.chargepointIDChange.bind(this)}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onBlur={this.getChargePoint.bind(this)}
                    // onFocus={this.ClearChargepoint.bind(this,ref)}
                    id="rbt_id"
                    ref={ref}
                  />
                  <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this, ref)} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                  </div>
                  {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-2 pull-right" style={{color:"#fff"}} onClick={this.FindCpName.bind(this,ref)}>
                  Find
                </MDBBtn> */}
                  <span className="pull-left error_msg w3-animate-top mt-1" id="notexists" style={{ color: "red" }}><span style={{ color: "red" }}>{this.state.errors["chargePointNotExists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                </div>
              </div>
              <div className="col-md-3 p-rel">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeScheduleFrom} onKeyDown={this.keyPress1} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.startDate}
                    onChange={date => this.GetFromDate(date)}
                    value={activeDateFormat(this.state.startDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'from')}
                    //ref={this.fromTime}
                    maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                  />
                </div>
                <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>
              <div className="col-md-3 p-rel">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="toDate" value={this.state.toDate} onChange={this.changeScheduleTo} onKeyDown={this.keyPress2} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.toDate}
                    onChange={date => this.dateChange(date)}
                    value={activeDateFormat(this.state.toDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    // className="cal_margin tag_cal"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'to')}
                    minDate={new Date(this.state.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>
              <MDBCol md="2" >
                <button className="btn_primary pull-right " onClick={this.exportUnknowncpCSV} type="button" data-test="button">
                  {t('export')}
                  <i className="fas fa-file-export ml-2"></i>
                </button>
              </MDBCol>
              <div className='col-md-12 date_range_error' style={{ color: "red", textAlign: "center" }}></div>
              <div className="col-md-12 mt-2">

                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="sub-text pull-right">{t('show_entries')}</label>
              </div>
            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                {/* <label>Show Entries</label>
          <MDBCol md="1">
              <select className="browser-default custom-select select_height mb_8" id="showEntries" onChange={this.showEntriesChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                </select>
                </MDBCol> */}

                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('ip_address')}</th>
                      {/* <th>Created Time</th>*/}
                      <th>{t('last_attempt')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('no_of_attempts')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                {/* <Pagination className="pull-right">
              <Pagination.First />
              <Pagination.Item>{1}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{10}</Pagination.Item>
              <Pagination.Item>{11}</Pagination.Item>
              <Pagination.Item active>{12}</Pagination.Item>
              <Pagination.Item>{13}</Pagination.Item>
              <Pagination.Item disabled>{14}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{20}</Pagination.Item>
              <Pagination.Last />
            </Pagination>*/}
                <Pagination
                  hideDisabled

                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

              {/* End table  */}
            </div>
          </div>

          {/* popup for session expire */}

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default withTranslation()(Unknown);