import React from 'react';
import { withTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import $ from "jquery";
import * as baseUrl from '../constants';
import { hasPermission } from '../auth';
import { Form, Table } from 'react-bootstrap';
import '../cpDashboard.css';

const history = createBrowserHistory();

class Header extends React.Component {
    constructor(props){
        super(props);
        let selectedSite;
        try {
            selectedSite = JSON.parse(localStorage.getItem('SelectedSite'));
        } catch (error) {
          selectedSite = null; 
        }

        let selectedLanguage;
        try{
          selectedLanguage = localStorage.preferredLanguage
        } catch (error){
          selectedLanguage = ''
        }

        this.state = {
            active : this.props.activetab,
            userName:"",
            title: this.props.page_title,
            modelswitch:false,
            modelSwitchSite: false,
            modelDelete:false,
            orgArray:[],
            orgName:"",
            choosenTenant:"",
            modalsession:false,
            disableMenu:"disabled-link",
            switch_org_name:"",
            disable_switchacc:"disabled-link",
            logoff_Account:"none",
            UserTenant:localStorage.getItem("UserTenant"),
            user:{
                permissions: [
                   
                ],
            },
            information:false,
            error:false,
            warning:false,
            settingsModal:false,
            notificationsModal:false,
            notificationsList:[],
            savemodal:false,
            count:0,
            availableSites: [],
            selectedSite,
            choosenSiteId: '',
            hasSitemanagement: false,
            listOfLanguages: [],
            selectedLanguage: selectedLanguage
        };
        this.logOut = this.logOut.bind(this);
        this.showSidebar = this.showSidebar.bind(this);
        this.switchAccount = this.switchAccount.bind(this);
        this.SwitchAcctoggle = this.SwitchAcctoggle.bind(this);
        this.switchAccountOrgChange = this.switchAccountOrgChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.LogOffAccount = this.LogOffAccount.bind(this);
        this.SwitchOkAcctoggle = this.SwitchOkAcctoggle.bind(this);
        this.DeleteAcctoggle = this.DeleteAcctoggle.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.deleteAccountApi = this.deleteAccountApi.bind(this);
        this.closeOkPopup = this.closeOkPopup.bind(this);
        this.toggledeletesuccess= this.toggledeletesuccess.bind(this);
        this.toggleSettings=this.toggleSettings.bind(this);
        this.toggleNotifications=this.toggleNotifications.bind(this);
        this.getNotifications=this.getNotifications.bind(this);
        this.setNotificationType=this.setNotificationType.bind(this);
        this.togglesavesuccess=this.togglesavesuccess.bind(this);
        this.getNotificationType=this.getNotificationType.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.openSwitchSiteModal = this.openSwitchSiteModal.bind(this);
        this.closeSwitchSiteModal = this.closeSwitchSiteModal.bind(this);
        this.confirmSwitchSiteModal = this.confirmSwitchSiteModal.bind(this);
        this.switchSiteChange = this.switchSiteChange.bind(this);
        this.logOffSite = this.logOffSite.bind(this);
        this.getAllAvailableSites = this.getAllAvailableSites.bind(this);
        this.getCustomization = this.getCustomization.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
    }
    getLanguages() {
      let url = baseUrl.URLPath + 'admin/v1.0/account/languages/preferred';
  
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      }).then(response => {
        if (response.status == 200) {
          return response.json();
        }
      }).then(result => {
        if (result) {
          this.setState({
            listOfLanguages: result
          })
        }
      }).catch(err => {
        console.warn('get preferred lanuages api failed', err)
      })
    }
    getAllAvailableSites() {
      let url = `${baseUrl.URLPath}sitemanagement/all`;
      fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              "Authorization": localStorage.getItem("Authorization")
          }
      }).then(resp => {
        if(resp.status === 401){
          this.togglesession();
          localStorage.clear();
          return [];
        }
        if (resp.status === 200) {
          return resp.json();
        }
        return [];
      }).then((response) =>{
        this.setState({...this.state, availableSites: response});
      }).catch((error) => {
          console.log(error, "error in getting sites list");
      })
    }

    getCustomization() {
      let tenant=localStorage.getItem('tenant');
      let url = baseUrl.LoginPath +"/api/admin/v1.0/account/tenant/customization?orgName="+tenant;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization")
        }
      }).then(resp => {
        if(resp.status === 401){
          this.togglesession();
          localStorage.clear();
        }
        if(resp.status === 200){
          return resp.json();
        }
      }).then(response => {
        if(response){
          const siteManagementCustomization = response.find(el=>el.operation === 'Group Management');
          if(siteManagementCustomization && siteManagementCustomization.active) {
            this.setState({...this.state, hasSitemanagement:true});
          }else{
            this.setState({...this.state, hasSitemanagement:false});
          }
        }
      })
    }
    
    resetNotificationCount(){
      let url=baseUrl.URLPath+"pushnotifications/reset/unseenNotification";
      fetch(url,{
        method:"GET",
        headers:{
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
        }
    }).then((resp)=>{
      if(resp.status === 401){
        this.togglesession();
        localStorage.clear();
      }else{
        return resp.json();
      }
    }).then((response)=>{
        if(response!=undefined){
            if(response.count!=null && response.count!=undefined && response.count.count>0){
                this.setState({ count: response.count.count, notificationsList: response.pnList });
            }else{
                this.setState({ count:0 });
            }
        }
      }
    ).catch((error) => {
      console.log(error, "catch the loop")
    })
  }
  changePassword() {
    history.push('/changePassword');
    window.location.reload();

  }
  getNotificationType() {
    let url = baseUrl.URLPath + "pushnotifications/settings/get";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then((resp) => {
      if(resp.status === 401){
        this.togglesession();
        localStorage.clear();
      }else{
        return resp.json();
      }
    }).then((response) => {
      if (response != undefined) {
        this.setState({
          error: response.error,
          warning: response.warning,
          information: response.info
        });
      }
    }).catch((error) => {
      console.log(error, "catch the loop")
    })
  }
  togglesavesuccess() {
    this.setState({
      savemodal: !this.state.savemodal
    })
  }
  getNotifications() {
    let url = baseUrl.URLPath + "pushnotifications/search/pushNotification?pageSize=" + 10 + "&pageNo=" + 1;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then((resp) => {
      if(resp.status === 401){
        this.togglesession();
        localStorage.clear();
      }else{
        return resp.json();
      }
    }).then((response) => {
      if (response != undefined) {
        if (response.pnList != null && response.pnList != undefined && response.pnList.length > 0) {
          this.setState({ notificationsList: response.pnList });
        } else {
          this.setState({ notificationsList: [] });
        }
      }
    }).catch((error) => {
      console.log(error, "catch the loop")
    })
  }
  setNotificationType() {
    let url = baseUrl.URLPath + "pushnotifications/settings/save";
    let data = {
      error: this.state.error,
      warning: this.state.warning,
      info: this.state.information,
      userId: "",
      id: ""
    }
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then((resp) => {
      if(resp.status === 401){
        this.togglesession();
        localStorage.clear();
      }else{
        return resp.json();
      }
    }).then((response) => {
      if (response != undefined) {
        this.setState({
          savemodal: !this.state.savemodal,
          error: response.error,
          warning: response.warning,
          information: response.info
        });
      }
    }).catch((error) => {
      console.log(error, "catch the loop")
    })
  }
  toggledeletesuccess = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleNotifications() {
    this.setState({
      notificationsModal: !this.state.notificationsModal
    });
  }
  toggleSettings() {
    this.setState({
      settingsModal: !this.state.settingsModal
    });
  }
  closeOkPopup() {
    history.push('/');
    window.location.reload();
  }
  deleteAccountApi() {
    var errors;
    this.setState({
      modelDelete: !this.state.modelDelete
    });

    var url = baseUrl.URLPath + "v1.0/user/account";

    fetch(url,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization"),
        }
      }
    ).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }
      else if (resp.status == 404) {
        this.setState({ errors: errors });
        return resp.json();
      }
      else if (resp.status == 500) {
        alert("internal server error")
      } else if (resp.status == 400) {
        alert("Bad request")
      } else if (resp.status == 200) {
        this.setState({
          modal: !this.state.modal
        });
      }
      else { }
    })
      .catch(error => console.log('Error:' + error));
    this.setState({ errors: errors });
  }
  SwitchOkAcctoggle() {

    if (this.state.identifier != "") {
      localStorage.setItem("switchOrgName", this.state.switch_org_name);

      this.state.logoff_Account = "block";

      // logoff_account
      var url = baseUrl.URLPath + "switchaccount/";

      fetch(url, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization"),
          "stenantid": this.state.identifier,
        }
      })
        .then((resp) => {
          if(resp.status === 401){
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            let getViewDetails = localStorage.getItem("saveDetails")
            localStorage.removeItem('SelectedSite');
            if(response.status=="SUCCESS" && getViewDetails == "true"){
              localStorage.setItem("saveDetails", false);
              localStorage.setItem("Authorization", "Bearer " + response.token);
              localStorage.setItem("tenant", this.state.identifier);
              localStorage.setItem("choosen_tenant", this.state.identifier);
              history.push('/dashboard');
              window.location.reload();
            }
            else if (response.status == "SUCCESS") {
              localStorage.setItem("saveDetails", false);
              localStorage.setItem("Authorization", "Bearer " + response.token);
              localStorage.setItem("tenant", this.state.identifier);
              localStorage.setItem("choosen_tenant", this.state.identifier);
              history.push('/dashboard');
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

      this.setState({
        modelswitch: !this.state.modelswitch
      });
    }
  }
  switchAccountOrgChange(event) {
    this.state.identifier = event.target.value;
    var index = event.nativeEvent.target.selectedIndex;
    var switch_org_name = event.nativeEvent.target[index].text
    this.state.switch_org_name = switch_org_name;
  }
  LogOffAccount(event) {
    this.state.identifier = event.target.value;
    var url = baseUrl.URLPath + "loggedoff/";
    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if(resp.status === 401){
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          if (response.status == "SUCCESS") {
            localStorage.setItem("Authorization", "Bearer " + response.token);
            localStorage.setItem("choosen_tenant", "none");
            localStorage.setItem("tenant", localStorage.getItem("UserTenant"));
            localStorage.setItem("switchOrgName", "");
            history.push('/dashboard')
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }
  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }
  SwitchAcctoggle() {
    this.setState({
      modelswitch: !this.state.modelswitch
    });
    if (this.state.identifier != "") {
      this.state.identifier = localStorage.getItem("tenant");
    }
  }
  switchAccount() {
    this.SwitchAcctoggle()
  }
  DeleteAcctoggle() {
    this.setState({
      modelDelete: !this.state.modelDelete
    });
  }
  deleteAccount() {
    this.DeleteAcctoggle()
  }
  logOut() {
    localStorage.clear();
    history.push('/');
    window.location.reload();
  }

    openSwitchSiteModal() {
      this.setState({...this.state, modelSwitchSite: true});
    }

    switchSiteChange(event){
      const choosenSiteId = event.target.value;
      this.setState({...this.state, choosenSiteId});
    }

    closeSwitchSiteModal() {
      this.setState({...this.state, modelSwitchSite: false});
    }

    confirmSwitchSiteModal() {
      const selectedSite = this.state.availableSites.find(el=> el.id === this.state.choosenSiteId);
      if(!selectedSite) {
        this.setState({...this.state, modelSwitchSite: false});
        return;
      }
      this.setState({...this.state, selectedSite});
      let url = `${baseUrl.URLPath}switchsite/`;
      fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              "Authorization": localStorage.getItem("Authorization"),
              'siteid': selectedSite.id
          }
      }).then(resp => {
        if(resp.status === 401){
          this.togglesession();
          localStorage.clear();
        }else if (resp.status == 200) {
          return resp.json();
        }
      }).then((response) =>{
        localStorage.setItem("Authorization", `${response.tokenType} ${response.token}`);
        const {id, name} = this.state.selectedSite;
        const siteDetails = { id, name};
        localStorage.setItem("SelectedSite", JSON.stringify(siteDetails));
        history.push('/dashboard');
        window.location.reload();
      }).catch((error) => {
          console.log(error, "error in switching the site");
      })
    }

    logOffSite() {
      let url = `${baseUrl.URLPath}loggedoff/site/`;
      fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              "Authorization": localStorage.getItem("Authorization")
          }
      }).then(resp => {
        if(resp.status === 401){
          this.togglesession();
          localStorage.clear();
        }else if (resp.status == 200) {
          return resp.json();
        }
      }).then((response) =>{
        console.log('response ', response);
        if(response.status === 'SUCCESS') {
          localStorage.removeItem("SelectedSite");
          localStorage.setItem("Authorization", `${response.tokenType} ${response.token}`);
          history.push('/dashboard');
          window.location.reload();
        }
      }).catch((error) => {
          console.log(error, "error in switching the site");
      })
    }
  
  componentWillMount(){
    var user = localStorage.getItem('user');
    this.setState({ userName: user });
  }

  showSidebar() {
    var js = document.getElementsByClassName('sidebar-main-div');
    if ($(js).hasClass("scroll_nav")) {
      $(js).removeClass("scroll_nav");
      $('nav').removeClass("scroll_nav");
      $('#navbar').removeClass("nav_p");
    } else {
      $(js).addClass("scroll_nav");
      $('#navbar').addClass("nav_p");
    }
  }

  componentDidMount() {
    var localSelectedLng = localStorage.getItem("localSelectedLng");
    this.state.user.permissions = localStorage.getItem("roleAccess");
    if (hasPermission(this.state.user, ['SwitchAccount'])) {

      this.state.disable_switchacc = "";


    }
    this.state.choosenTenant = localStorage.getItem("choosen_tenant");
    if (this.state.choosenTenant == "none" && hasPermission(this.state.user, ['SwitchAccount'])) {
      this.setState({
        disableMenu: "",
      })
    }

    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ orgArray: response.organisationDTOS })
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })  
      this.getNotificationCount();
      if(this.props.shouldreset==true){
        this.resetNotificationCount();
      }
      setInterval(()=>{this.getNotificationCount()},1000*60*60);
        this.getAllAvailableSites();
      this.getCustomization();
      this.getLanguages();

      

      let lan = "";
      if(localSelectedLng=="" || localSelectedLng==null){
        lan = localStorage.preferredLanguage;
      }else{
        lan = localStorage.localSelectedLng
      }
      if(lan == "French"){
        this.props.i18n.changeLanguage('fr')
      }else if(lan == "English"){
        this.props.i18n.changeLanguage('en')
      }

      this.setState({
      selectedLanguage: lan
      })

  }
  getNotificationCount() {
    let url = baseUrl.URLPath + "pushnotifications/search/unseenNotificationCount";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then((resp) => {
      if(resp.status === 401){
        this.togglesession();
        localStorage.clear();
      }else {
        return resp.json();
      }
    }).then((response) => {
      if (response != undefined) {
        if (response.count != null && response.count != undefined && response.count.count > 0) {
          this.setState({ count: response.count.count });
        } else {
          this.setState({ count: 0 });
        }
      }
    }).catch((error) => {
      console.log(error, "catch the loop")
    })
  }

  showSidebar() {
    var js = document.getElementsByClassName('sidebar-main-div');
    if ($(js).hasClass("scroll_nav")) {
      $(js).removeClass("scroll_nav");
    } else {
      $(js).addClass("scroll_nav");
    }
  }

  onChangeLanguage(lng){
    let lngV = lng.target.value;
    this.setState({
      selectedLanguage: lng.target.value
    },()=>{
     
      localStorage.setItem("localSelectedLng", lngV);
      window.location.reload();
    })
  
  }

  render() {
    if (localStorage.getItem("roleAccess") == "Inventory_R,CSR_R" && this.state.orgArray.length == 0) {
      this.state.orgName = localStorage.getItem("tenant");
    }

    let switch_org = localStorage.getItem("switchOrgName");

    let org_full_name = "";
    const organisation = this.state.orgArray.map((orgArray, index) => {
      var setOrg = false;
      var HideOrg = "";
      if (switch_org != "" && switch_org != null && (switch_org.toLowerCase() == orgArray.company.toLowerCase())) {
        switch_org = orgArray.company;
        switch_org = switch_org.split(" ");
        switch_org = switch_org[0];
        this.state.logoff_Account = "block";
        setOrg = true
        this.state.identifier = orgArray.identifier;
        org_full_name = orgArray.company
      }
      if (this.state.UserTenant.toLowerCase() == orgArray.identifier.toLowerCase()) {
        HideOrg = "none";
        org_full_name = orgArray.company;
        this.state.orgName = orgArray.company;
      }
              return(
                <option value={orgArray.identifier} selected={orgArray.identifier==this.state.UserTenant ? false : setOrg} style={{"display":HideOrg}} > {orgArray.company} </option>
              )
          })

          
          const sitesDropdownHTML = this.state.availableSites?.map((el, index)=>{
            return(
              <option value={el.id} defaultValue={this.state.selectedSite}> {el.name} </option>
            )
          })
        return (
                <>
                  <header>
                    {/* <!-- Navbar--> */}
                    <nav className="navbar fixed-top navbar-expand-md navbar-light white double-nav scrolling-navbar custom-navbar" >
                    {/* <!-- SideNav slide-out button --> */}
                    <div className="breadcrumb-dn slide-arrow" onClick={this.showSidebar}>
                        <p><i className="fas fa-bars" id="toggle_arrow" style={{color:"#00529e"}}> </i></p>
                    </div>
                    <div className="text-left w-15 pl-4">
                    {
                    localStorage.getItem("role") !="WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"?  
                    <a href="/dashboard" className="pl-0"><img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{width: "120px" }} alt="" /></a>
               
              
                     :
                     <img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{width: "120px" }} alt="" />
                       }
                       
                    </div>
                    {/* <!-- Navbar links--> */}
                    <div className="mr-auto pl-2 input-div">
                        <div className="input-group input-search ">
                            <i className="fas fa-search fa-sm"></i>
                            <input type="text" className="border-0 small" placeholder={this.props.t('search_something')} aria-label="Search" aria-describedby="basic-addon2" />
                        </div>
                    </div>
                    {/* <!--Navigation icons--> */}
                    <span id="dpl-navbar-right-buttons" >
                        {/* <!--Navigation icons--> */}
                        <ul className="nav navbar-nav nav-flex-icons ml-auto">
                        <select className="browser-default custom-select modelSelect mt-3 mr-3" id="countryList" value={this.state.selectedLanguage} onChange={this.onChangeLanguage}>
                          {
                            this.state.listOfLanguages?.map((language,index)=>{
                              return <option value={language} key={index}>{language}</option>
                            })
                          }
                        </select>
                        <li className="nav-item pr-3 pt-12" onClick={()=>{this.resetNotificationCount();this.toggleNotifications();this.getNotifications();}}>
                        <i class="far fa-bell fa-2x notification p-relative bellicon" style={{color:"#00529e"}}></i>
                        <div className="numberCircle">{(this.state.count>0)? this.state.count:""}</div>
                        </li>
                        <li className="nav-item orgnization_name pr-3" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 0}}>
                            <span>{this.props.t('Organization')} : { (localStorage.getItem("switchOrgName")=="" || localStorage.getItem("switchOrgName")==null) ? this.state.orgName : localStorage.getItem("switchOrgName") }</span>
                            {this.state.selectedSite && <span>{this.props.t('group')} : { this.state.selectedSite.name }</span>}
                        </li>
                            {/* <!-- Tools --> */}
                                <li id="navbar-static-tools" className="nav-item dropdown">
                                        <a className="nav-link" id="navbar-tools" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                             <label className="profile-pic" >
                                                <img src={process.env.PUBLIC_URL + "/assets/img/user.png"} alt="" />
                                             </label>
                                             
                                            <span className="d-none d-xl-inline-block ml-1 profile-pic-span">{this.state.userName}</span>
                                            <i class="fas fa-chevron-down profile-pic-arrow"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right drop_menu  pt-1 pb-1" aria-labelledby="userDropdown">
                  
                     {hasPermission(this.state.user, ['SwitchAccount']) && <>
                     <a className={ "dropdown-item cursor-p " + this.state.disable_switchacc }   onClick={this.switchAccount} >
                        <span className="fw-500">{this.props.t('switch_account')}</span>
                     </a>
                     </>
    }
                    {hasPermission(this.state.user, ['SwitchSite']) && this.state.hasSitemanagement &&
                      <a className={ "dropdown-item cursor-p "}   onClick={this.openSwitchSiteModal} >
                          <span className="fw-500">{this.props.t('switch_group')}</span>
                      </a>
                    }
    {hasPermission(this.state.user, ['SwitchAccount']) && <>
                     <a className= {"dropdown-item cursor-p"} style={{"display":this.state.logoff_Account}} id="logoff_account"  onClick={this.LogOffAccount} >
                       <span className="fw-500">{this.props.t('logoff_organization')}: <b>{switch_org}</b></span>
                     </a>
                     </>
    }
                    {
                      this.state.selectedSite && 
                      <a className= {"dropdown-item cursor-p"} onClick={this.logOffSite} >
                        <span className="fw-500">{this.props.t('logoff_group')}: <b>{this.state.selectedSite.name}</b></span>
                      </a>
                     }
                     <a className="dropdown-item cursor-p" href="/" onClick={this.logOut}>
                       <span className="fw-500">{this.props.t('signout')}</span>
                     </a>
                   </div>
                               </li>
                               {/* <!-- Login / register --> */}
                                </ul>
                            </span>
                        </nav>
                        {/* <!-- /.Navbar--> */}
                    </header>
                    <MDBModal isOpen={this.state.modelswitch} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
                        <MDBModalHeader toggle={this.SwitchAcctoggle}>{this.props.t('switch_account')}</MDBModalHeader>
                        <MDBModalBody>
                            <div class="row">
                                <label className="switch_acc">{this.props.t('select_organization')}</label>
                                <div class="col-md-7">
                                <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.switchAccountOrgChange.bind(this)}>
                                    <option value="">{this.props.t('select_organization')}</option>
                                    {organisation}
                                </select>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                        <button type="button" data-test="button" onClick={this.SwitchAcctoggle} className="btn_cancel">{this.props.t('cancel')} <i class="fas fa-times ml-2"></i></button>
                        <button type="button" data-test="button" onClick={this.SwitchOkAcctoggle} className="btn_primary">{this.props.t('ok')}</button>
                        </MDBModalFooter>
                        </MDBModal>
                       {/* popup for delete account */}
                        <MDBModal isOpen={this.state.modelDelete} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
                        <MDBModalHeader toggle={this.DeleteAcctoggle}>{this.props.t('confirmation')}</MDBModalHeader>
                        <MDBModalBody>
                        {this.props.t('delete_confirmation')}<br></br><br></br>
                        {this.props.t('user_softdelete_note')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" onClick={this.DeleteAcctoggle} className="btn_cancel">{this.props.t('cancel')} <i class="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" onClick={this.deleteAccountApi} className="btn_primary">{this.props.t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>
        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{this.props.t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
          {this.props.t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">{this.props.t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
        {/* popup for sucessfull delete */}
        <MDBModal isOpen={this.state.modal} toggle={this.toggledeletesuccess} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggledeletesuccess}>{this.props.t('success')}</MDBModalHeader>
          <MDBModalBody>
          {this.props.t('account_delete_alert')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" onClick={this.closeOkPopup} className="btn_primary">{this.props.t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
        <div class="modal" id="" ref={(node) => { if (node) { node.style.setProperty("z-index", "100002", "important"); } }} style={{ display: (this.state.savemodal) ? "block" : "none" }}>
          {/* Operations Modal */}
          <div class="modal-backdrop fade show"></div>
          <div class="modal-dialog modal_top t-200" ref={(node) => { if (node) { node.style.setProperty("z-index", "100003", "important"); } }}>
            <div class="modal-content b-15">

              {/* <!-- Modal Header --> */}
              <div class="modal-header row mx-0">
                <h4 class="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{this.props.t('success')}</h4>
              </div>

              {/* <!-- Modal body --> */}
              <div class="modal-body">
                {this.props.t('notification_save_alert')}
              </div>

              {/* <!-- Modal footer --> */}
              <div class="modal-footer border">
                <button type="button" data-test="button" onClick={() => { window.location.reload() }} className="btn_primary">{this.props.t('ok')}</button>
              </div>
            </div>
          </div>
        </div>
        <MDBModal isOpen={this.state.notificationsModal} toggle={this.toggleNotifications} size="md" className="model_top w-800">
          <div class="modal-header row mx-0" toggle={this.toggleNotifications}>
            <h4 class="modal-title mt-1 col-md-6" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{this.props.t('notification_header')}</h4>
            <div className="col-md-5"></div>
            <div className="col-md-1"><img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Operations.png"} data-toggle="modal" data-target="#noticationssetting" style={{ position: "relative", top: "5px", fontSize: "18px" }} onClick={this.getNotificationType.bind(this)} alt=""></img></div>
          </div>
          <MDBModalBody>
            <Table striped hover size="md" className="page-table">
              <tbody>
                {
                  this.state.notificationsList.map((notification, index) => {
                    if (index < 5) {
                      return (
                        <tr onClick={() => { localStorage.setItem("notificationObject", JSON.stringify(notification)); window.location.replace("/notification") }}>
                          <td style={{ width: "15%" }}>{notification.createdDate}</td>
                          <td style={{ width: "15%" }}>{notification.chargePointId}</td>
                          <td style={{ width: "10%" }}>{notification.type}</td>
                          <td style={{ width: "60%" }}>{notification.notificationDescription}</td>
                        </tr>
                      )
                    }
                  })
                }
              </tbody>
            </Table>
          </MDBModalBody>
          <div data-test="modal-footer" class="modal-footer" style={{ justifyContent: "space-between" }}>
            <button type="button" data-test="button" onClick={() => { window.location.replace("/notifications") }} className="btn_primary">{this.props.t('show_all')}</button>
            <button type="button" data-test="button" onClick={this.toggleNotifications} className="btn_primary">{this.props.t('ok')}</button>
          </div>
        </MDBModal>
            <div class="modal" id="noticationssetting" ref={(node) => { if (node) { node.style.setProperty("z-index", "100001", "important"); } }} style={{ display: (this.state.settingsModal) ? "block" : "none" }}>
              {/* Operations Modal */}
              <div class="modal-dialog t-50 cp-operations_model">
                <div class="modal-content b-15">

                  {/* <!-- Modal Header --> */}
                  <div class="modal-header row mx-0" ref={(node) => { if (node) { node.style.setProperty("border-bottom", "none", "important"); } }}>
                    <h4 class="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{this.props.t('notification_settings')}</h4>
                  </div>

                  {/* <!-- Modal body --> */}
                  <div class="modal-body">
                    <div style={{ color: "darkgray" }}>{this.props.t('notification_settings_desc')}</div>
                    <div id="notification">
                      <MDBRow className="" >
                        <MDBCol sm="12">
                          <div className="" >
                            <Form.Check
                              className="mt-3"
                              custom
                              checked={this.state.information}
                              id="notifyIfInfo"
                              type="checkbox"
                              label={<div>{this.props.t('notification_info')}</div>}
                              onChange={() => { this.setState({ information: !this.state.information }) }}
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="" >
                        <MDBCol sm="12">
                          <div className="" >
                            <Form.Check
                              className="mt-3"
                              custom
                              checked={this.state.warning}
                              id="notifyIfWarning"
                              type="checkbox"
                              label={<div>{this.props.t('notification_warn')}</div>}
                              onChange={() => { this.setState({ warning: !this.state.warning }) }}
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="" >
                        <MDBCol sm="12">
                          <div className="" >
                            <Form.Check
                              className="mt-3"
                              custom
                              checked={this.state.error}
                              id="notifyIfError"
                              type="checkbox"
                              label={<div>{this.props.t('notification_error')}</div>}
                              onChange={() => { this.setState({ error: !this.state.error }) }}
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>

                  {/* <!-- Modal footer --> */}
                  <div class="modal-footer border">
                    <button type="button" data-test="button" data-toggle="modal" data-target="#noticationssetting" className="btn_cancel">{this.props.t('cancel')} <i class="fas fa-times ml-2"></i></button>
                    <button type="button" data-test="button" data-toggle="modal" data-target="#noticationssetting" onClick={this.setNotificationType} className="btn_primary">{this.props.t('done')}</button>
                  </div>
                </div>
              </div>
            </div>

            {/* Switch site modal */}
            <MDBModal isOpen={this.state.modelSwitchSite} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" toggle={this.closeSwitchSiteModal}>
              <MDBModalHeader toggle={this.closeSwitchSiteModal}>{this.props.t('switch_group')}</MDBModalHeader>
              <MDBModalBody>
                  <div class="row">
                      <label className="switch_acc">{this.props.t('select_group')}</label>
                      <div class="col-md-7">
                        <select defaultValue={this.state.selectedSite?.id} className="browser-default custom-select select_height mb_8" id="switch_site_dropdown" onChange={this.switchSiteChange.bind(this)}>
                            <option value="">{this.props.t('select_group')}</option>
                            {sitesDropdownHTML}
                        </select>
                      </div>
                  </div>
              </MDBModalBody>
              <MDBModalFooter>
                <button type="button" data-test="button" onClick={this.closeSwitchSiteModal} className="btn_cancel">{this.props.t('cancel')} <i class="fas fa-times ml-2"></i></button>
                <button type="button" data-test="button" onClick={this.confirmSwitchSiteModal} className="btn_primary">{this.props.t('ok')}</button>
              </MDBModalFooter>
            </MDBModal>
          </>
        )
    }
}

export default withTranslation()(Header);
