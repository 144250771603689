import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Header from './header';

class cancelReservation extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
            fields:{
              reservationId:''
          },
          cpList:[],
          sucess:false,
          colour:'sucess',
          page_title:"Cancel Reservation",
          errors:{},
          chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);

}
onShowAlert(){
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
async handleSubmit(event) {
  var fdsfs=this.state;
  var payload=this.getPayLoad();
  // alert(document.getElementById("tagList").value);

  var errors={};
  var reservationId=payload.reservationId;
if(this.getCPList()==0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
}
if(isNaN(reservationId)){
  errors["emptyreservationId"]=this.props.t('enter_reservation_id')
}



if(this.getCPList()!=0 && !isNaN(reservationId)){
  var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
 //alert(response);
 if(response==this.props.t('unable_toprocess_request_try_after_some_time')){
  this.setState({message:this.props.t('unable_toprocess_request_try_after_some_time'),colour:'danger'})
  this.onShowAlert();
 }else{
  this.setState({message:this.props.t('request_processed_successfully'),colour:'success'})
  this.onShowAlert();
 }
}
this.setState({errors:errors})
}
getCPList() {
  // var cplist = ["CP0001"];
  var Id = this.state.operationId;
  if(Id != null && Id != ""){
   var cplist = Id.split();
 
  }
  else{
   var cplist = this.state.cpList;
 
 }
 return cplist;
}

getPayLoad() {
  var payload =
  
  {
    // "reservationId": 1
    "reservationId": parseInt(this.state.fields.reservationId),

  };
   
  return payload;

}

getMessageType() {
  return constants.CANCEL_RESERVATION;
}
reservationIdChange(){
  var x = document.getElementById("reservationId").value;
  let fields = this.state.fields;
  fields["reservationId"] = x;
  this.setState({fields});
}
getSelectedCP(cpList){
  
  this.setState({cpList:cpList});
  if(cpList!=""){
 
    this.state.errors["emptyChargePoint"]=" "
  
  }

}
numberHandler(field, e){ 
  let fields = this.state;

  if(e.target.validity.valid){
    fields[field] = e.target.value; 
    this.setState({fields});
    this.state.errors["emptyreservationId"]=" "
  
  }else if(e.target.value==""){
    fields[field] = e.target.value; 
    this.setState({fields});
   
 
  }
 
}
componentDidMount(){
  let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
}

render() {
  const{t}=this.props
  return (
    <>

<main class="content-div" >
<p>  {t('Cancel Reservation')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Cancel Reservation')} </span>
              </div>
           </p>
    {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Cancel Reservation </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Cancel Reservation </a></div> */}

          {/* <p>Cancel Reservation</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

      {/* <section className="dashboard">
        <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left" >Cancel Reservation</h4>
                <div class="clearfix"></div>
            </div>
        </div>
        <section className="content_wbg">  */}
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
        <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName ="CancelReservation"></ChargePointSelection>
        <MDBCol md="12">
       <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
      </MDBCol>

          {/* <MDBCol md="4">
            <select className="browser-default custom-select select_height" id="reservationId" onChange={this.reservationIdChange.bind(this)} >
                <option>ID of the Existing Reservation	</option>
                <option value="1">1</option>
                <option value="2">2</option>
           </select>
           <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyreservationId"]}</span></span>

            </MDBCol> */}
               <MDBCol md="4">
         
         <MDBInput
         value={this.state.fields["reservationId"]}
         name="ReservationID"
           onInput={this.numberHandler.bind(this,"reservationId")}
           type="text"  pattern="[0-9]*"
        
           id="reservationId"
           label={t('id_of_reservation_integer')}
           required
         >
           <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyreservationId"]}</span></span>

           {/* <div className="valid-feedback">Looks good!</div> */}
           {/* <small id="heartbeatinterval" className="form-text text-muted">
           if empty, 0 = charge point as a whole
           </small> */}
         </MDBInput>
       </MDBCol>

          </MDBRow>
          <button type = "button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
          {/* <MDBBtn color="primary" onClick={this.handleSubmit} className="mt-5" > Perform </MDBBtn> */}
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
       
    </div>
    </main>
    </>
  );
}
}

export default withTranslation()(cancelReservation);