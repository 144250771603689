import React from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Table } from 'react-bootstrap'
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import CustomModal from '../common/CustomModal';

class ConnectorStatusTemplate extends React.Component {
    render() {
        const{t}=this.props
        const ref = React.createRef();

        const data = this.props.connectorsData?.map((dataArray, index) => {
            var stringDate = dataArray.createdTime;
            if (dataArray.createdTime == null || dataArray.createdTime == "") {
                var date = "";
                var date1 = "";
            } else {
                var date = moment(stringDate).format("DD MMM YYYY");
                var date1 = moment(stringDate).format("HH:mm");
            }

            // logic for getting chargingProfiles by comma saparated
            var dict = dataArray.chargingProfiles;

            const commaSep = dict.map((item, index) => {
                return (
                    <Link to={{
                        pathname: '/viewChargingProfile',
                        state: item.id,
                    }}>{item.profileName + ",  "}
                    </Link>
                )
            })

            return (
                <tr>
                    <td>{dataArray.chargePointId}</td>
                    <td>{dataArray.connectorId}</td>
                    <td>{dataArray.status}</td>
                    <td className="wrap-word">{date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
                    <td>{commaSep}</td>
                </tr>
            )

        });

        return (
            <main className="content-div" >
                <p>{t('Connectors')}
                    <div className="breadcrumb_div">
                        {t('charge_point')} &gt; <span className="breadcrumb_page">{t('Connectors')}</span>
                    </div>
                </p>
                <div className="page-outerdiv">
                    <div className="row mt-2">
                        <div className="col-md-8 pull-right t-8">
                            <div className="row mt-2">
                                <div className="col-md-6 pull-right t-8">
                                    <div className="input-group">
                                        <i className="fas fa-search fa-sm input-searchIcon"></i>
                                        <Typeahead
                                            labelKey="name"
                                            className="w_80"
                                            multiple={this.props.multiple}
                                            options={this.props.options}
                                            minLength={3}
                                            placeholder={t('choose_a_charge_point_id')}
                                            onKeyDown={this.props.handleKeyDown.bind(this, ref)}
                                            id="rbt_id"
                                            ref={ref}
                                        />
                                        <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.props.clearChargePoint.bind(this, ref)}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="input-group-btn">
                                            <button className="input-searchBtn find-btn" type="submit" onClick={this.props.onChangeChargepoint.bind(this, ref)} >{t('find')}</button>
                                        </div>
                                        <span className="pull-left error_msg w3-animate-top mt-1" style={{ color: "red" }}>{this.props.errors["chargePointNotExists"]}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <select className="browser-default custom-select select_height" id="status" onChange={this.props.onStatusChange} value={this.props.statusChange}>
                                        <option value="">{t('select_status')}</option>
                                        <option value="Faulted">{t('faulted')}</option>
                                        <option value="Unavailable">{t('unavailable')}</option>
                                        <option value="SuspendedEVSE">{t('suspendedevse')}</option>
                                        <option value="SuspendedEV">{t('suspendedev')}</option>
                                        <option value="Preparing">{t('preparing')}</option>
                                        <option value="Reserved">{t('reserved')}</option>
                                        <option value="Available">{t('available')}</option>
                                        <option value="Charging">{t('charging')}</option>
                                        <option value="Finished">{t('finished')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 pull-right t-8">
                            <div className="row mt-2">
                                <div className="col-md-7 t-8">
                                    <div className="pull-right">
                                        <label className="sub-text pull-left">{t('show_entries')}</label>
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                            <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                                <option>50</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="col-md-5 t-8">
                                    <button className="refresh-btn pull-right" type="button" data-test="button" style={{ cursor: "pointer" }} onClick={this.props.refreshData}>
                                        {t('refresh')} <i className="fas fa-refresh fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th>{t('charge_point_id')}</th>
                                        <th>{t('connector_id')}</th>
                                        <th>{t('chargingSessionState')}</th>
                                        <th>{t('timestamp')}</th>
                                        <th>{t('chargingProfileId')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data}
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-4">
                            <Pagination
                                hideDisabled
                                activePage={this.props.activePage}
                                itemsCountPerPage={this.props.pageSize}
                                totalItemsCount={this.props.noOfRecords}
                                onChange={this.props.handlePageChange}
                            />
                        </div>
                    </div>
                </div>

                {/* popup for session expire */}
                <CustomModal
                    open={this.props.modalsession}
                    title={t('session_has_expired')}
                    bodyMessage={t('please_login_again')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.sessiontagOk}
                />
            </main>
        )
    }
}

export default withTranslation()(ConnectorStatusTemplate);