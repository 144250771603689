import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import * as baseUrl from './constants';
import Header from './header';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class settings extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          fields:{configValue:'',configName:''},
          preData:"",
          errors: {},
          model:"false",
            expiration:"2020-01-23 16:34:23",
            protocol:"ocpp 1.6",
            host:"webasto.com",
            port:"8081",
            from:"2020-01-23 16:34:24",
            recipients:"user",
            page_title:"OCPP",
            disabledAttr:true,
            modalsession:false,
          }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.changeHandlerToCheckEmpty = this.changeHandlerToCheckEmpty.bind(this)
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this)
        this.togglesession = this.togglesession.bind(this)
}


async togglesession(){
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  history.push('/');
  window.location.reload();
}

changeHandlerToCheckEmpty(field, e){ 

  if(e.target.value=="" || this.state.preData == e.target.value){
    
    this.setState({
      disabledAttr:true,
      
    });
  }else{

      if(e.target.value!=""){
        this.setState({
          disabledAttr:"",
        });
      }
  }
}


toggle = () => {
  this.setState({
  modal: !this.state.modal
  });
  }

  tagOk(){
    window.location.reload();

  }

changeHandler(field, e){ 
    let fields = this.state.fields;
    fields[field] = e.target.value; 
    this.setState({fields,disabledAttr:""});
}

  componentDidMount(){
    
   var url = baseUrl.URLPath + "configurations";

   //localhost:8080/cpms/configurations
    fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization"),
         
         }
        })
        .then((resp) => {
          if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
           
          //  alert(resp);
        }) 
        .then((response) =>{
         
         if(response!=undefined){
                this.setState({
                  fields:response.data,
                  preData:response.data.configValue
                })
              }
          })
        .catch((error) => {
          console.log(error, "catch the loop")
     })
     
  }


async handleSubmit(event) {
  event.preventDefault();
     ;
   let url = baseUrl.URLPath + "configurations"
  //localhost:8080/cpms/configurations
   let data = { "configValue": this.state.fields.configValue,"configName": "HEARTBEAT_INTERVAL"}; 
   fetch(url, {
     method: 'PUT', // or 'PUT'
     body: JSON.stringify(data), // data can be `string` or {object}!
      headers:{
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization"),
       
      }
     }).then(resp =>{
       
       if(resp.status==401){
        this.togglesession();
        // alert("Access token is expired, please login again")
        localStorage.clear();
      }
   else if(resp.status==500){
          alert("internal server error")
       } else if(resp.status==400){
        alert("Bad request")
     }else{
      //  alert("Heartbeat Interval Updated successfully");
      this.toggle()

       
       
       }
     })
     .catch(error => alert('Error:' + error));

 // this.setState({errors: errors});
}
render() {
  const{t}=this.props
  return (
    <>
    <main class="content-div" >
        <p>{t('OCPP')}
          <div className="breadcrumb_div">
            {t('Settings')} &gt; <span className="breadcrumb_page"> {t('OCPP')}</span>
          </div>
        </p>
         <div className="page-outerdiv">        
        <MDBRow className="mb-4 mt-3">
            <MDBCol sm="12">
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
          <MDBCol md="4">
              <MDBInput
                name="chargebox"
                oldData = {this.state.preData}
                value={this.state.fields["configValue"]}
                onChange={this.changeHandler.bind(this, "configValue")}
                onBlur={this.changeHandlerToCheckEmpty.bind(this, "configValue")}
                type="text"
                id="heartbeatInterval"
                label={t('heartbeat_interval')}
                required
              >
                <div className="valid-feedback">Looks good!</div>
                <small id="heartbeatinterval" className="form-text text-muted">
                {t('heartbeat_interval_input_desc')}
                </small>
              </MDBInput>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          </MDBRow>
          <button type="button" className="mt-5 refresh-btn" disabled={this.state.disabledAttr} onClick={this.handleSubmit}>{t('update')}</button>
        </form>
         </MDBCol>
          
        </MDBRow>
        </div>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
<MDBModalBody>
{t('heartbeat_interval_been_updated')}
</MDBModalBody>
<MDBModalFooter>
<MDBBtn color="primary" onClick={this.tagOk}>{t('ok')}</MDBBtn>
</MDBModalFooter>
</MDBModal>

{/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
    </main>
    </>
  );
}
}

//export default settings;
export default withTranslation()(settings);