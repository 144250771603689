import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form, Table, Pagination, Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import { Alert } from 'reactstrap';
import $ from "jquery";
import Sidebar from './sidebar';
import Headers from './header';
import servicesUrl from '../common/servicesUrl';


class chargePointModelSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            dataArray: [

            ],
            cpList: [],
            ModelList: [],
            empty: [],
            sucess: false,
            disPlayModelcp: false,
            colour: '',
            selectedModel: "",
            message: 'Multiple charge points not supported for this operation.',
            isCheckBox: true,
            style: "block",
            check_type: "",
            buttondisplay: ""
        }
        this.onShowAlert = this.onShowAlert.bind(this);

    }

    onShowAlert() {
        
        this.setState({ sucess: true }, () => {
            window.setTimeout(() => {
                this.setState({ sucess: false })
            }, 5000)
        });
    }

    componentWillMount() {
        
        var location = window.location.href;
        var page = location.split("/")[3];
        if (page == 'remoteStartTransaction' || page == "remoteStopTransaction" || page == "reserveNow" || page == "cancelReservation" || page == "unlockConnector" || page == "sendLocalList") {
            this.setState({ isCheckBox: false, style: "none" });
        } else {
            this.setState({ style: "block", disPlayModelcp: true });
        }
    }


    setSelectedStatus() {
        var location = window.location.href;
        var page = location.split("/")[3];
        for (var i = 0; i < this.state.cpList.length; i++) {
            var fdfdd = this.state.cpList[i];
            if (document.getElementById("idStatus")) {
                var txt = document.getElementById("idStatus").innerHTML;
                txt += "<span class='chargepoint_span' id=chargepoint_span" + this.state.cpList[i] + ">" +
                    this.state.cpList[i] + "<i class='fa fa-times' id=" + this.state.cpList[i] + " )}></i></span>";
                document.getElementById("idStatus").innerHTML = txt;
                // document.getElementById(this.state.cpList[i]).onclick = function () { this.myfun() };
            }
        }
    }

    singleChargePointChange(id, e) {
        this.setState({ cpList: [id], check_type: "Radio" });
    }

    handleChange(id, e) {
        //  e.preventDefault();
        this.setState({ check_type: "Checkbox" });


        if (this.state.disPlayModelcp == true) {
            this.getModelChargePoint(id, "");
            let isChecked = document.getElementById(`custom-${id}`).checked;
            localStorage.setItem('chargePointId', id);
            this.state.cpList.push(id);
        } else {
            var cpLength = this.state.cpList.length;
            var location = window.location.href;
            var page = location.split("/")[3];
            let isChecked = document.getElementById(`custom-${id}`).checked;
            // setter
            localStorage.setItem('chargePointId', id);
            if (isChecked) {
                var txt = document.getElementById("idStatus").innerHTML;
                this.state.cpList.push(id);
                txt += "<span class='chargepoint_span' id=chargepoint_span" + id + ">" +
                    id + " <i class='fa fa-times' id=" + id + "></i></span>";
                document.getElementById("idStatus").innerHTML = txt;
                // document.getElementById(id).addEventListener('click', this.myfun);
            } else {
                var idList = this.state.cpList;
                var index = this.state.cpList.indexOf(id);;
                idList.splice(index, 1);
                this.setState({ cpList: idList });
                document.getElementById("chargepoint_span" + id).remove();
            }
        }

    }

    toggle = () => {
        // alert(this.state.cpList);
        this.setState({
            modal: !this.state.modal,
        })
        if (this.state.cpList.length == 0) {
            this.getChargePoints(this);
            this.setState({ disPlayModelcp:true, selectedModel:"" })
        } else {
            this.setState({ disPlayModelcp:false, selectedModel:"" })
            this.getModelChargePoint("", this.state.selectedModel);
        }
    }

    Savetoggle = () => {
        this.setState({
            modal: !this.state.modal,
        })
        const { data } = this.props;
        data(this.state.cpList);
    }

    closetoggle = () => {
        this.setState({
            modal: !this.state.modal,
            disPlayModelcp: true
        });
        const { data } = this.props;
        data(this.state.empty);
        this.setState({ cpList: [] })
    }

    getChargePoints(event) {
        var opeName = this.props.operationName;
        var url = baseUrl.URLPath + servicesUrl.getChargePointCplistWithModels+ "?operationName=" + opeName;
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
            .then((resp) => {

                return resp.json();
            })
            .then((response) => {

                var fsd = response;
                this.setState({ dataArray: response.cpList, ModelList: response.modelList })
                // this.setSelectedStatus();
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })

    }

    getModelChargePoint(cpid, model) {
        // event.preventDefault();
        
        var fdfd = this.state.cpList;
        var opeName = this.props.operationName;
        var url = baseUrl.URLPath +servicesUrl.getChargePointCplistByModelOrCPId+ "?operationName=" + opeName + "&cpId=" + cpid + "&model=" + model;
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
            .then((resp) => {
                
                return resp.json();
            })
            .then((response) => {
                
                var fsd = response;
                document.getElementById("modelList").value = response.modelList;
                this.setState({ dataArray: response.cpList, disPlayModelcp: false, selectedModel: response.modelList[0] })
                this.setSelectedStatus();
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })

    }

    modelOnChange() {
        var x = document.getElementById("modelList").value;
        var selectedModel = "";
        
        if (x != "") {
            selectedModel = x;
            document.getElementById("idStatus").innerHTML = "";
            const { data } = this.props;
            data(this.state.empty);
            if(this.state.cpList.length > 0){
                this.state.cpList.map((id, index) => {
                    document.getElementById(`custom-${id}`).checked = false
                })
            }          
            this.setState({cpList:[], disPlayModelcp: false, selectedModel:selectedModel}, () => {
                this.getModelChargePoint('', x);
            })
        } else {
            selectedModel = null;
            const { data } = this.props;
            data(this.state.empty);
            this.setState({cpList:[], disPlayModelcp: true, selectedModel:""}, () => {
                document.getElementById("idStatus").innerHTML = "";
                this.getChargePoints();
            })
        }
    }

    render() {
        var viewCp = this.props.cpid;
        if (viewCp == "chargePointId") {
            var Id = this.props.opData;
            this.state.buttondisplay = "none"
        }
        else {

        }
        var operationToggle = localStorage.getItem("operation")
        if (operationToggle == "operationMenu") {
            localStorage.removeItem("operationId")
            this.state.buttondisplay = "block"
        }
        // alert(this.state.dataArray)
        const data = this.state.dataArray.map((dataArray, index) => {
            if (this.state.isCheckBox) {
                var vcvc = this.state.dataArray.length;
                if(this.state.cpList.length > 0){
                for (var i = 0; i < this.state.cpList.length;i++){
                    if (dataArray == this.state.cpList[i]) {
                        return (
                            <li><Form.Check onChange={this.handleChange.bind(this, dataArray)}
                                custom
                                type="checkbox"
                                id={`custom-${dataArray}`}
                                label=""
                                checked
                            />
                                {dataArray}
                            </li>
                        )
                    }
                }
            }
                
                return (
                    <li>
                        <Form.Check onChange={this.handleChange.bind(this, dataArray)}
                            custom
                            type="checkbox"
                            id={`custom-${dataArray}`}
                            label=""
                        />
                        {dataArray}
                    </li>

                )
            } else {
                return (

                    <li><Form.Check onChange={this.singleChargePointChange.bind(this, dataArray)}
                        custom
                        type="radio"
                        id={`custom-${dataArray}`}
                        label=""
                        name="chargepoint"
                    />
                        {dataArray}
                    </li>
                )
            }




        })

        const cpStatus = this.state.cpList.map((cpId, index) => {

        });

        const modelListRow = this.state.ModelList.map((model, index) => {
            return (
                <option value={model}>{model}</option>
            )
        });



        const selectedCPId = this.state.cpList.map((cpList, index) => {
            var comms_txt = ",";
            if (this.state.check_type == "Radio") {
                comms_txt = "";
            }
            return (
                <span>{cpList}{comms_txt}</span>
            )
        });
        return (
            <>
                <MDBCol md="12" className="mb-4" >
                    <span className="form-title mt-0 pb-4">Selected Charge Point *:  <button data-test="button" type="button" id="btn_image_div" style={{ display: this.state.buttondisplay }} onClick={this.toggle} className="ml-0 btn_primary pull-right" size="md">Select Charge Point</button> </span>
                    <div class="chargepoint_listdiv_out" >{selectedCPId}{Id}</div>
                </MDBCol>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} className="custom_model_w" size="lg" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
                    <div data-test="modal-header" class="modal-header">
                        <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); node.style.setProperty("width", "100%", "important") } }}>Select Charge Point
                            <MDBCol md="8" className=' pull-right'>
                                <select className="browser-default custom-select modelSelect" id="modelList" onChange={this.modelOnChange.bind(this)} value={this.state.selectedModel}>
                                    <option value="">None</option>
                                    {modelListRow}
                                </select>
                                <label className='modelLabel'>Select Model</label>
                            </MDBCol>
                        </div>
                        <button type="button" class="close" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {/* model body for multipal selection  */}
                    <MDBModalBody >

                        {/* display row of selecting charge point */}
                        <MDBRow>
                            <MDBCol md="12" id="statusTag" style={{ display: this.state.style }}  >
                                <Alert color={this.state.colour} className="pt-1 pb-1" isOpen={this.state.sucess}>{this.state.message}</Alert>
                                <span className="form-title tp-0 pt-0 mb-2 mt-0">Selected Charge Point:</span>
                            </MDBCol>
                            <MDBCol md="12" id="idStatus" className="chargepoint_seldiv mb-1" >
                                {cpStatus}
                            </MDBCol>
                        </MDBRow>
                        {/* end display row of selecting charge point */}

                        {/* Charge Point table  */}
                        <MDBRow>
                            <MDBCol md="12" className="chargepoint_listdiv" >
                                <ul>
                                    {data}
                                </ul>
                            </MDBCol>
                        </MDBRow>
                        {/* end Charge Point table  */}
                    </MDBModalBody>


                    {/* model body for single selection  */}
                    <MDBModalBody style={{ display: "none" }}>
                        {/* Charge Point table  */}
                        <MDBRow>
                            <MDBCol md="12" className="chargepoint_listdiv">
                                <ul>
                                    {data}
                                </ul>
                            </MDBCol>
                        </MDBRow>
                        {/* end Charge Point table  */}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button className="btn_cancel" data-test="button" type="button" onClick={this.closetoggle}>Cancel <i class="fas fa-times ml-2"></i></button>
                        <button className="btn_primary" data-test="button" type="button" onClick={this.Savetoggle}>Done</button>
                    </MDBModalFooter>
                </MDBModal>

            </>
        );
    }
}

export default chargePointModelSelection;