import React from 'react';
import {Switch , Route , Link} from 'react-router-dom';
import  $ from "jquery";
import { hasRole, hasPermission } from './auth';
import * as jwtJsDecode from 'jwt-js-decode';

class Sidebar extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            hideActionBtn:"none", // show hide btn varible based on permission 
            writePermission:this.props.ChargePoint_W, // ChargePoint_W conndition
			listOpen: true, // done by manoj ux designer 
			listOpen1: true,
			listOpen2: true,
			listOpen3: true,
			listOpen4: true,
            listOpen5: true,
            listOpen14: true,
            // disableMenu:"",
            user:{
                permissions: [
                    
                ],
            }
		};
        this.showSidebar = this.showSidebar.bind(this);
        this.toggleMenuOperation = this.toggleMenuOperation.bind(this);
    }
    

    //  toogle functionality of sidebar done by manoj ux designer 
    toggleList() {
		this.setState(prevState => ({
			listOpen: !prevState.listOpen
		}))
	}
      toggleList1(){
        this.setState(prevState => ({
            listOpen1: !prevState.listOpen1
        }))
      }
      toggleListB(){
        this.setState(prevState => ({
            listOpen14: !prevState.listOpen14
        }))
      }

      toggleList2(){
          ;
        this.setState(prevState => ({
            listOpen2: !prevState.listOpen2
        }))
       
      }
      toggleMenuOperation(){
          localStorage.setItem('operation', 'operationMenu')
          localStorage.removeItem("operationId")
              }
    
      toggleList3(){
        this.setState(prevState => ({
            listOpen3: !prevState.listOpen3
        }))
      }

      toggleList4(){
        this.setState(prevState => ({
            listOpen4: !prevState.listOpen4
        }))
      }

      toggleList5(){
        this.setState(prevState => ({
            listOpen5: !prevState.listOpen5
        }))
      }

    showSidebar(){
        var js = document.getElementsByClassName('sidebar-main-div');
        if($(js).hasClass("scroll_nav")){
          $(js).removeClass("scroll_nav");
        }else{
          $(js).addClass("scroll_nav");
        }
    }

    componentDidMount(){

        // permission related code
        // 
        this.state.user.permissions=localStorage.getItem("roleAccess");
        this.state.choosenTenant = localStorage.getItem("choosen_tenant");

        if(this.state.choosenTenant=="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
            this.setState({
                disableMenu:"disabled-link"
            })
        }
        else{
              if(this.state.choosenTenant!="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
                this.setState({
                    disableMenu:""
                })
              }
        }

    
    
       this.state.user.permissions=localStorage.getItem("roleAccess");
        var fsd=localStorage.getItem("roleAccess");
        this.state.writePermission=hasPermission(this.state.user,['OCPPTags_R']);
                if(this.state.writePermission==true){
                    this.setState({
                    hideActionBtn:"inline-block"
                    })
                }
    }
    render() {
        const{listOpen} = this.state
        const{listOpen1} = this.state
        const{listOpen14} = this.state
        const{listOpen2} = this.state
        const{listOpen3} = this.state
        const{listOpen4} = this.state
        const{listOpen5} = this.state
        const user = this.state.user
       

        
        return (
            <>
                <div className="breadcrumb-dn mr-auto slide-arrow" onClick={this.showSidebar}>
                    <p><i className="fas fa-bars" id="toggle_arrow" style={{color:"#00529e"}}> </i></p>
                </div>
                <div className="sidebar-main-div">
                    <div id="slide-out" className="side-nav sn-bg-4 fixed" style={{transform: "translateX(0px)"}}>
                        <ul className="custom-scrollbar ps ps--active-y">
                            {/* <!-- Logo -->*/}
                            <li className="logo-sn waves-effect py-3">
                                <div className="text-center">
                                    <a href="/dashboard" className="pl-0"><img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} alt="logo" style={{width: "138px"}} /></a>
                                </div>
                            </li>
                            {/* <!-- Search Form -->*/}
                            <li>
                                <form className="search-form" role="search">
                                    <div className="md-form mt-0 waves-light">
                                    </div>
                                </form>
                            </li>
                            {/* <!-- Side navigation links -->*/}
                            <li>
                                <ul className="collapsible collapsible-accordion left-sidebar">
                                    <li>
                                        <Link to="./dashboard" className="collapsible-header waves-effect"> <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/icon-dashboard.png"} alt="logo" className="menuicon_img" />Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link to="./csrLanding" className="collapsible-header waves-effect"> <img src={process.env.PUBLIC_URL + "/assets/img/svg1/home.svg"} alt="logo" className="menuicon_img" />CSR</Link>
                                    </li>
                                    <li >
                                        <div className="collapsible-header waves-effect arrow-r panel-heading" style={{paddingLeft: "0px"}} onClick={() => this.toggleList()}>
                                            <span className="panel-title">
                                                <a data-toggle="collapse" href="#collapse2">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/charge.png"} alt="logo" className="menuicon_img" />Charge Points{listOpen ? <i className = "fas fa-angle-right rotate-icon"/> : <i className = "fas fa-angle-down rotate-icon"/>}
                                                </a>
                                            </span>
                                        </div>
                                        <div id="collapse2" className="panel-collapse collapse">
                                            <ul>
                                                {hasPermission(user, ['ChargePoint_R']) &&  <li><Link to="/chargePoint" className="waves-effect"> Overview </Link></li> }
                                                {/* {hasPermission(user, ['unknown_R']) &&  <li> <Link to="/unknowncp" className="waves-effect"> Unknown  </Link> </li> } */}
                                                {hasPermission(user, ['OCPPTags_R']) && <li ><Link to="/Ocpptags" className="waves-effect"> OCPP Tags</Link></li> }
                                                {hasPermission(user, ['ConnectorStatus_R']) &&   <li><Link to="/connectorStatus" className="waves-effect"> Connectors</Link></li> }
                                                {hasPermission(user, ['ChargingProfile_R']) &&   <li><Link to="/chargingProfile" className="waves-effect"> Charging Profile </Link></li> }
                                                {hasPermission(user, ['ChargingSchedule_R']) &&   <li><Link to="/chargingSchedule" className="waves-effect"> Charging Schedule </Link></li> }
										   </ul>
                                        </div>
                                    </li>
                                    { hasPermission(user, ['unknown_R'])  && 
                                    <li>
                                        <Link to="./unknowncp" className="collapsible-header waves-effect">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/unknown.png"}  alt="logo" className="menuicon_img" />Unknown Charge Points</Link>
                                    </li>
                                   
    }


                                    { hasPermission(user, ['Resevations_R']) && 
                                    <li> <Link to="./reservations" className="collapsible-header waves-effect"><img src={process.env.PUBLIC_URL + "/assets/img/svg1/reservation.svg"} alt="logo" className="menuicon_img" />Reservations</Link> </li>}
                                    { hasPermission(user, ['Transactions_R']) && 
                                    <li>
                                        <Link to="./transactions" className="collapsible-header waves-effect">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/charging.png"} alt="logo" className="menuicon_img" />Charging Session</Link>
                                    </li>
                                   
    }                                {hasPermission(user, ['settings_R','Users_R','Organizations_R','BuildNotification_R','ChargingProfile_R']) &&
                                    <li >
                                        <div className="collapsible-header waves-effect arrow-r panel-heading" style={{paddingLeft: "0px"}}  onClick={() => this.toggleList1()}>
                                            <span className="panel-title">
                                                <a data-toggle="collapse" href="#collapse4">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/settings.png"} alt="logo" className="menuicon_img" />Settings{listOpen1 ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-angle-down rotate-icon"/>}
                                                </a>
                                            </span>
                                        </div>
                                        <div id="collapse4" className="panel-collapse collapse">
                                            <ul>
                                                {hasPermission(user, ['settings_R']) && <li> <Link to="/settings" className="waves-effect"> OCPP </Link></li> }
                                                {hasPermission(user, ['Users_R']) &&  <li> <Link to="/userManagementController" className="waves-effect">Users</Link></li> }
                                                {hasPermission(user, ['Organizations_R']) && <li> <Link to="/organisationList" className="waves-effect"> Organization </Link> </li> }
                                                {hasPermission(user, ['BuildNotification_R']) &&   <li><Link to="/buildNotification" className="waves-effect">Build Notification</Link> </li> }
                                                {hasPermission(user, ['Prefereces_R']) &&  <li><Link to="/preferences" className="waves-effect">Preferences</Link> </li>}
                                            </ul>
                                        </div>
                                    </li>
    }
                                    <li >
                                        <div className="collapsible-header waves-effect arrow-r panel-heading" style={{paddingLeft: "0px"}} onClick={() => this.toggleList2()}>
                                            <span className="panel-title">
                                                <a data-toggle="collapse" href="#collapse1">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Operations.png"} alt="logo" className="menuicon_img" />Operations{listOpen2 ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-angle-down rotate-icon"/>}
                                                </a>
                                           </span>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse">
                                            <ul>
                                                {hasPermission(user, ['CSOprMsg_R']) && <li><Link to="./logs" className="waves-effect">Logs</Link> </li> }
                                                {hasPermission(user, ['CSOprMsg_R']) &&    <li>
                                                    <Link to="./cpmsLogs" className="waves-effect">CPMS Logs</Link> </li> }
                                                {hasPermission(user, ['ChangeAvailability_W']) && <li> <Link to="./changeAvailability" className="waves-effect" onClick={ this.toggleMenuOperation}>Change Availability</Link></li> }
                                                {hasPermission(user, ['ChangeConfiguration_W']) &&  <li><Link to="./changeConfiguration" className="waves-effect" onClick={ this.toggleMenuOperation}>Change Configuration</Link></li> }
                                                {hasPermission(user, ['ClearCache_W']) && <li><Link to="./clearCatch" className="waves-effect" onClick={ this.toggleMenuOperation}>Clear Cache</Link></li> }
                                                {hasPermission(user, ['GetDiagnostics_W']) && <li><Link to="./getDiagnostics" className="waves-effect" onClick={ this.toggleMenuOperation}>Get Diagnostics</Link></li> }
                                                {hasPermission(user, ['RemoteStartTransaction_W']) && <li><Link to="./remoteStartTransaction" className="waves-effect" onClick={ this.toggleMenuOperation}>Remote Start Transaction</Link></li> }
                                                {hasPermission(user, ['RemoteStopTransaction_W']) && <li><Link to="./remoteStopTransaction" className="waves-effect" onClick={ this.toggleMenuOperation}>Remote Stop Transaction</Link></li> }
                                                {hasPermission(user, ['Reset_W']) &&  <li><Link to="./reset" className="waves-effect" onClick={ this.toggleMenuOperation}>Reset</Link></li> }
                                                {hasPermission(user, ['UnlockConnector_W']) &&  <li><Link to="./unlockConnector" className="waves-effect" onClick={ this.toggleMenuOperation}>Unlock Connector</Link></li> }
                                                {hasPermission(user, ['UpdateFirmware_W']) &&  <li><Link to="./updateFirmware" className="waves-effect" onClick={ this.toggleMenuOperation}>Update Firmware</Link> </li> }
                                                {hasPermission(user, ['ReserveNow_W']) && <li><Link to="./reserveNow" className="waves-effect" onClick={ this.toggleMenuOperation}>Reserve Now</Link> </li> }
                                                {hasPermission(user, ['CancelReservation_W']) && <li><Link to="./cancelReservation" className="waves-effect" onClick={ this.toggleMenuOperation}>Cancel Reservation</Link></li>}
                                                {hasPermission(user, ['DataTransfer_W']) && <li><Link to="./dataTransfer" className="waves-effect" onClick={ this.toggleMenuOperation}>Data Transfer</Link> </li>}
                                                {hasPermission(user, ['GetConfiguration_W']) && <li><Link to="./getConfiguration" className="waves-effect" onClick={ this.toggleMenuOperation}>Get Configuration</Link> </li>}
                                                {hasPermission(user, ['GetLocalListVersion_W']) && <li> <Link to="./getLocalListVersion" className="waves-effect" onClick={ this.toggleMenuOperation}>Get Local List Version</Link></li> }
                                                {hasPermission(user, ['SendLocalList_W']) && <li> <Link to="./sendLocalList" className="waves-effect" onClick={ this.toggleMenuOperation}>Send Local List</Link> </li>  }
                                                {hasPermission(user, ['TriggerMessage_W']) && <li> <Link to="./triggerMessage" className="waves-effect" onClick={ this.toggleMenuOperation}>Trigger Message</Link></li> }
                                                {hasPermission(user, ['GetCompositeSchedule_W']) && <li><Link to="./getCompositeSchedule" className="waves-effect" onClick={ this.toggleMenuOperation}>Get Composite Schedule</Link></li> }
                                                {hasPermission(user, ['ClearChargingProfile_W']) && <li><Link to="./clearChargingProfile" className="waves-effect" onClick={ this.toggleMenuOperation}>Clear Charging Profile</Link></li> }
                                                {hasPermission(user, ['SetChargingProfile_W']) && <li><Link to="./setChargingProfile" className="waves-effect" onClick={ this.toggleMenuOperation}>Set Charging Profile</Link> </li> }
                                            </ul>
                                        </div>
                                    </li>
                                    <li >
                                        <div className="collapsible-header waves-effect arrow-r panel-heading" style={{paddingLeft: "0px"}} onClick={() => this.toggleList3()}>
                                            <span className="panel-title">
                                                <a data-toggle="collapse" href="#collapse3">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/maintance.png"} alt="logo" className="menuicon_img" />Maintenance {listOpen3 ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-angle-down rotate-icon"/>}
                                                </a>
                                            </span>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <ul>
                                               {hasPermission(user, ['OWTInventory_R']) && <li><Link to="/owtChargePointList" className="waves-effect"> OWT Charge Points</Link></li>}
                                                {hasPermission(user, ['Inventory_R']) && <li ><Link to="/inventoryList" className="waves-effect"> Inventory</Link> </li> }
                                                {hasPermission(user, ['showDiagnostics_R']) && <li  ><Link to="./showDiagnostics" className="waves-effect">Show Diagnostics</Link></li> }
                                                {hasPermission(user, ['uploadDiagnostics_W']) && <li ><Link to="./uploadDiagnostics" className="waves-effect" onClick={ this.toggleMenuOperation}>Upload Diagnostics</Link></li> }
                                                {hasPermission(user, ['CPOprMsg_R']) &&  <li ><Link to="/log" className="waves-effect">CP Logs</Link></li>}
                                                {hasPermission(user, ['CPOprMsg_R']) && <li ><Link to="./chargePointLogs" className="waves-effect break-word"> Charge Point Operation Messages </Link></li>  }
                                                 
                                                { hasPermission(user, ['builds_R']) &&  <>
                                                  <div className="collapsible-header waves-effect arrow-r panel-heading" style={{paddingLeft: "0px"}} onClick={() => this.toggleList4()}> 
                                                    <span className="panel-title">
                                                        <a data-toggle="collapse" href="#collapse9">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/svg6.svg"} alt="logo" className="menuicon_img" />FOTA{listOpen4 ? <i className = "fas fa-angle-right rotate-icon"/> : <i className = "fas fa-angle-down rotate-icon"/>}
                                                        </a>
                                                    </span>
                                                    </div>
                                                    <div id="collapse9" className="panel-collapse collapse">
                                                        <ul>
                                                            <li>
                                                                <Link to="/builds" className="waves-effect break-word">  Builds </Link>
                                                            </li>
                                                        </ul>
                                                    </div> 
                                                    </> 
                                                   }
                                                    { hasPermission(user, ['CP_Notification_R']) && <li ><Link to="/cpNotifications" className="waves-effect break-word"> Charge Point Notifications</Link></li> }
                                                    {/* log */}
                                                    {/* <li>
                                                        <Link to="/log" className="waves-effect break-word"> Log</Link>
                                                    </li> */}
                                                </ul>
                                        </div>
                                    </li>
                                  
                                           <li >
                                        <div className="collapsible-header waves-effect arrow-r panel-heading" style={{paddingLeft: "0px"}}  onClick={() => this.toggleListB()}>
                                            <span className="panel-title">
                                                <a data-toggle="collapse" href="#collapseB">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/broker.png"} alt="logo" className="menuicon_img" />Broker{listOpen14 ? <i className = "fas fa-chevron-right rotate-icon"/> : <i className = "fas fa-angle-down rotate-icon"/>}
                                                </a>
                                            </span>
                                        </div>
                                        <div id="collapseB" className="panel-collapse collapse">
                                            <ul>
                                            <li><Link to="./vendor" className="waves-effect">Vendor</Link></li>
                                             
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="./vehicles" className="collapsible-header waves-effect">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/svg1/transaction.svg"} alt="logo" className="menuicon_img" />Vehicles</Link>
                                    </li>
                                </ul>
                            </li>
                            {/*
                            <!-- Side navigation links -->*/}
                        </ul>
                        <div className="sidenav-bg mask-strong"></div>
                    </div>
                </div>
            </>
        // <!-- end Sidebar navigation -->
        )
    }
}
export default Sidebar; 
