import React from 'react';
import { MDBDataTable, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBInput, MDBSelect, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility';
import { createBrowserHistory } from 'history';
import * as constants from './constants';
import Header from './header';
import { Alert } from 'reactstrap';
import ChargePointSelection from './chargePointSelection';
import { withTranslation } from 'react-i18next';
const history = createBrowserHistory();

class setChargingProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page_title: "Set Charging Profile",
      sucess: false,
      colour: 'sucess',
      message: "",
      errors: {},
      profileName: "",
      modalsession: false,
      items: [],
      fields: {
        connectorID: '',
        csChargingProfiles: '',
        transactionId:''
      },
      chargePoint: '',
      operationId: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
    this.ProfileChange = this.ProfileChange.bind(this);
  }

  ProfileChange() {

    var profileName = document.getElementById("profileNameId").value;
    let fields = this.state;
    fields["csChargingProfiles"] = profileName;
    this.setState({ fields });
    if (profileName != 0) {

      this.state.errors["emptycsChargingProfiles"] = " "

    }

  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {

    let fields = this.state.fields;

    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
      this.state.errors["emptyConnectorId"] = " "

    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });


    }

  }

  async togglesession(){
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  onShowAlert(){
    
   this.setState({sucess:true},()=>{
     window.setTimeout(()=>{
       this.setState({sucess:false})
     },5000)
   });
  }
  
  async handleSubmit(event) {
    
  var fdsfs=this.state;
  var payload=this.getPayLoad();
  var errors={};
  var connectorId = payload.connectorId;
  var csChargingProfiles = payload.csChargingProfiles;

  if(this.getCPList()== 0 || this.getCPList() == undefined ){
    errors["emptyChargePoint"]=this.props.t('select_charge_point')
  }
  if(isNaN(connectorId)){
    errors["emptyConnectorId"] = this.props.t('enter_connector_id')
  }
  if(!csChargingProfiles){
    errors["emptycsChargingProfiles"] = this.props.t('select_charging_profile_name')
  }
  
  if(this.getCPList()!= 0 && this.getCPList() != undefined && !isNaN(connectorId) && csChargingProfiles){
    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
   // alert(response);
   if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else {
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }
  }
  this.setState({errors:errors})
  }

  getSelectedCP(cpList) {

    this.setState({ cpList: cpList });
    if (cpList != "") {

      this.state.errors["emptyChargePoint"] = " "

    }

  }

  getCPList() {
    // var cplist = ["CP0001"];
    // return cplist;
    var Id = this.state.operationId;
    if (Id != null && Id != "") {
      var cplist = Id.split();

    }
    else {
      var cplist = this.state.cpList;

    }
    return cplist;
  }

  getMessageType() {
    return constants.SET_CHARGING_PROFILE_REQUEST;
  }

  getPayLoad() {
    
    var payload =
    {
      "connectorId": parseInt(this.state.fields.connectorID),
      "csChargingProfiles": this.state.fields.csChargingProfiles,
      "transactionId": parseInt(this.state.transactionId)
    };
    return payload;
  }

  async getChargingProfile(){
    var timeZone=  localStorage.getItem("timezone");
    var url = constants.URLPath + "chargingprofiles/search"
    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if(resp.status==401){
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 500) {
          alert(this.props.t('internal_server_error'))
        }
        if (resp.status == 200) {
          return resp.json();
        } 
      }) 
      .then((response) =>{
        
        if(response!=undefined){
            this.setState({
             items:response,
            })
        }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata != undefined) {
      if (propsdata.chargePoint != undefined && propsdata.operationId != undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }
    this.getChargingProfile()
  }
  componentWillUnmount() {
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }
  render() {
   const{t}=this.props
    let ChargingprofileData = "";
    if (this.state.items.length != 0) {
      ChargingprofileData = this.state.items.map((data, index) =>
        <option value={data.chargingProfileId} >{data.chargingProfileName}</option>
      );
    }

    return (
      <>
        <main class="content-div" >
          <p> {t('Set Charging Profile')}
            <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">  {t('Set Charging Profile')}  </span>
            </div>
          </p>
          {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Get Composite Schedule </a></p> */}
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"><a>Operations > </a> <a>Set Charging Profile</a></div> */}
            <Alert color={this.state.colour} isOpen={this.state.sucess} > {this.state.message} </Alert>

            {/* <p>Get Composite Schedule</p> */}
            {/* <section className="dashboard">
        <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left">Set Charging Profile</h4>
                <div class="clearfix"></div>
            </div>
        </div>
        <section className="content_wbg">  */}

            <MDBRow className="mb-4 mt-3">
              <MDBCol sm="12">

                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="SetChargingProfile" ></ChargePointSelection>
                      <MDBCol md="12">
                        <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4" >
                        <select className="browser-default custom-select select_height selm" id="profileNameId" onChange={this.ProfileChange}>
                          <option value="">{t('charging_profile_name')} *</option>
                          {ChargingprofileData}

                        </select>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["emptycsChargingProfiles"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields.connectorID}
                          name="connectorID"
                          onInput={this.numberHandler.bind(this, "connectorID")}
                          type="text" pattern="[0-9]*"
                          id="connectorID"
                          label={t('connector_id_integer')}
                          required
                        >
                          <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} >{this.state.errors["emptyConnectorId"]}</span></span>
                          <div className="valid-feedback">{t('looks_good')}</div>
                          <small className="form-text text-muted">
                            {t('if_empty0charge_point')}
                          </small>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.transactionId}
                          name="ID of the Active Transaction"
                          onInput={this.numberHandler.bind(this, "transactionId")}
                          type="text" pattern="[0-9]*"
                          id="transactionId"
                          label={t('id_of_the_active_transaction')}
                          required
                        >
                          <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptytransactionId"]}</span></span>
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>
                    <button className="mt-5 refresh-btn" type="button" data-test="button" onClick={this.handleSubmit} >{t('perform')}</button>
                  </form>
                </div>
              </MDBCol>

            </MDBRow>
          </div>
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default withTranslation()(setChargingProfile);