import React from 'react';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import Sidebar from './sidebar';
import Headers from './header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from './header';
import {Link} from 'react-router-dom';
import * as baseUrl from './constants';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';
const history = createBrowserHistory();

class addChargingProfile extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
           errors:{},
           modal:false,  
           modalSuccess:false,  
           modalsession:false,
           validFromDate:"",
           validToDate:"",
           chargingProfileId:"",
           chargingScheduleData:[],
           disabledAttr:true,
           page_title:"Edit Charging Profile",
           fields:{
            "chargingProfileName":"",
            "stackLevel":null,
            "chargingProfilePurpose":"",
            "chargingProfileKind":"",
            "recurrencyKind":"",
            "chargingSchedule":"",
            "validFrom":"",
            "validTo":"",
            
          }
        }
        this.addNew = this.addNew.bind(this)
        this.backToList = this.backToList.bind(this)
        this.setValidFromDate = this.setValidFromDate.bind(this)
        this.setValidToDate = this.setValidToDate.bind(this)
        this.saveChargingProfile = this.saveChargingProfile.bind(this)
        this.togglesession = this.togglesession.bind(this);
        this.CloseSuccessPopup = this.CloseSuccessPopup.bind(this);
        this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
        this.toggle1 = this.toggle1.bind(this)
        this.toggle = this.toggle.bind(this);
}


toggle1(event){
  event.preventDefault();
  ;
  this.setState({
    modal1: !this.state.modal1
  });
}



//This is for adding the charge profile
addNew(){
  this.props.history.push('/chargingprofile');
}

//This is for taking to the charge profile list page
backToList(){
  this.props.history.push('/viewChargingProfile');
}

//This function for setting the date to state
setValidFromDate (date) {
  
  // if(date != null ){
  //   var currentDate = new Date(date);
  //   var dateNew =  this.formatDateandTime(currentDate)
  //   var fields = this.state.fields;
  //   fields["validFrom"] = dateNew
  //   this.setState({ 
  //     validFromDate:date ,
  //     fields
  //    })
  // }else{
  //   var fields = this.state.fields;
  //   fields["validFrom"] = ""
  //   this.setState({ 
  //     validFromDate:"" ,
  //     fields
  //    })
  // }
  
  var dateNew =  this.formatDateandTime(date)
  var fields = this.state.fields;
  fields["validFrom"] = dateNew
  this.setState({ 
    validFromDate:date ,
    fields
   })
   this.setState({disabledAttr:""});
}

changeHandlerSelect(e){
  var value = e.target.value;
  this.setState({disabledAttr:""});
}

//This function for setting the date to state
setValidToDate (date) {
  var dateNew =  this.formatDateandTime(date)
  var fields = this.state.fields;
  fields["validTo"] = dateNew
  this.setState({ 
    validToDate:date ,
    fields
   })
   this.setState({disabledAttr:""});
 
  // if(date != null ){
  // var currentDate = new Date(date);
  // var dateNew =  this.formatDateandTime(currentDate)
  // var fields = this.state.fields;
  // fields["validTo"] = dateNew
  // this.setState({ 
  //   validToDate:date ,
  //   fields
  //  })
  // }else{
  //   var fields = this.state.fields;
  //   fields["validTo"] = ""
  //   this.setState({ 
  //     validToDate:"" ,
  //     fields
  //    })
  // }
}


formatDateandTime(currentDate){
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  return createdDateTo;
}


// function to open popup
async toggle(){
  this.setState({
    modal: !this.state.modal
  });
}

async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}

sessiontagOk(){
  history.push('/');
  window.location.reload();
}

toggleAdd(){
  this.setState({
    modalSuccess: !this.state.modalSuccess
  });
}

CloseSuccessPopup(){
  this.setState({
    modalSuccess: !this.state.modalSuccess
  });
  this.props.history.push('/chargingProfile');
}


numberHandler(field, e){ 
  
    let fields = this.state.fields;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }
    this.setState({fields,disabledAttr:""});
}

componentDidMount(){

  var chargingProfileId = this.props.location.state;
   this.state.chargingProfileId = chargingProfileId;
  
  let url = baseUrl.URLPath + "chargingschedules/search"
  fetch(url, {
    method: 'GET', // or 'PUT'
     headers:{
      "content-type":"application/json",
      "Authorization":localStorage.getItem("Authorization"),
     }
    })
  .then(res => {
    if(res.status==401){
      this.togglesession();
      localStorage.clear();
    }else{

      return res.json();
    }
      }).then(response => {
      this.setState({
        chargingScheduleData:response
      });
  });
  this.getChargingProfile()
}

async getChargingProfile(){
  var timeZone=  localStorage.getItem("timezone");
  ;
  var url = baseUrl.URLPath + servicesUrl.getChargingprofiles+ "?profileId="+this.state.chargingProfileId;
  await fetch(url, {
      method: "GET",
          headers: {
            'Content-Type': 'application/json',
            "Authorization":localStorage.getItem("Authorization")
          }
      })
    .then((resp) => {
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==500){
        alert("internal server error")
      }
      if(resp.status==400){
        console.log(resp.status)
      }
      if(resp.status==200){
        return resp.json();
      } 
    }) 
    .then((response) =>{
      
      if(response!=undefined){
        this.setState({
          fields:response,
       })
       document.getElementById('chargingScheduleId').value = this.state.fields.chargingSchedule ;
       document.getElementById('chargingProfilePurposeId').value = this.state.fields.chargingProfilePurpose ;
       document.getElementById('chargingProfileKindId').value = this.state.fields.chargingProfileKind ;
       document.getElementById('recurrencyKindId').value = this.state.fields.recurrencyKind ;
      
       if(this.state.fields.validFrom != null){
        this.setState({
          validFromDate:new Date(this.state.fields.validFrom),
       }) 
       }
       if(this.state.fields.validTo!=null){
        this.setState({
          validToDate:new Date(this.state.fields.validTo),
       }) 
       }

      }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}

saveChargingProfile(event){
  
  event.preventDefault();
  this.setState({
    modal1: !this.state.modal1
  });
  let errors = {};
  var stackLevel= this.state.fields['stackLevel'];
  var chargingProfileName= this.state.fields['chargingProfileName'];
  var chargingProfilePurpose= document.getElementById("chargingProfilePurposeId").value;
  var chargingProfileKind =  document.getElementById("chargingProfileKindId").value;
  var recurrencyKind= document.getElementById("recurrencyKindId").value;
  var chargingSchedule= document.getElementById("chargingScheduleId").value;
  var validFrom= this.state.fields['validFrom'];
  var validTo= this.state.fields['validTo'];

  if(chargingProfilePurpose==''){
    errors["chargingProfilePurposeError"] = "Please select a charging profile purpose.";
  }
  if(chargingProfileKind==""){
    errors["chargingProfileKindError"] = "Please select a charging profile kind:";
  }
  if(stackLevel==''){
    errors["stackLevelError"] = "Please enter a Stack Level.";
  }
  if(chargingSchedule==''){
    errors["chargingScheduleError"] = "Please select a charging schedule period.";
  }
  if(errors.hasOwnProperty("chargingProfilePurposeError") && errors.hasOwnProperty("chargingProfileKindError") && errors.hasOwnProperty("stackLevelError") && errors.hasOwnProperty("chargingScheduleError") )
  {

  }else{
    let url = baseUrl.URLPath + servicesUrl.getChargingProfilesWId+ "?profileId="+this.state.chargingProfileId;
    let data = {"chargingProfileName": chargingProfileName, "stackLevel": stackLevel, "chargingProfilePurpose": chargingProfilePurpose, "chargingProfileKind":chargingProfileKind, "recurrencyKind": recurrencyKind, "chargingSchedule":chargingSchedule, "validFrom":validFrom, "validTo":validTo}; 
    fetch(url, {
      method: 'PUT', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
       headers:{
       'Content-Type': 'application/json',
       "Authorization":localStorage.getItem("Authorization")
       }
      }).then(resp =>{
         if(resp.status==401){
            this.togglesession();
          localStorage.clear();
         }else if(resp.status==500){
           alert("internal server error")
         }else if(resp.status==404){
           this.setState({errors: errors});
         }else if(resp.status==409){
           errors["chargingProfileNameError"] = "Charging profile name already exists."
           this.setState({errors: errors});
        }else if(resp.status==400){
        
        }else{
            if(resp.status==200){
              this.toggleAdd()
            }
          }
        }).catch(error => alert('Error:' + error));
        }
        this.setState({errors: errors});
  }
  

render(){
    let chargingScheduleItems ="";
    if(this.state.chargingScheduleData != undefined){
          chargingScheduleItems = this.state.chargingScheduleData.map((chargingSchedule,index) =>
          <option value={chargingSchedule.chargingScheduleName} > {chargingSchedule.chargingScheduleName} </option>
      );
    }
     
  return (
    <>
<main class="content-div" >
<p>Charging Profile
              <div className="breadcrumb_div">
              Charge Point &gt; <Link to="/chargingProfile" > <span className="">Charging Profile</span></Link>  &gt;  <Link  to={{
                  pathname:'/viewChargingProfile', 
                  state: this.state.chargingProfileId, 
                  }} > <span className=""> View </span></Link> <span className="breadcrumb_page">&gt; Edit</span>
              </div>
          </p>
    <form
        className='needs-validation'
        onSubmit={this.toggle1}
        noValidate
      >
        <div className="page-outerdiv">
          {/* <div className="breadcrumb_div">Charge Point > <Link to="/chargingProfile" > <span className=""> Charging Profile </span></Link> <Link  to={{
                  pathname:'/viewChargingProfile', 
                  state: this.state.chargingProfileId, 
                  }} > > <span className=""> View </span></Link> > Edit</div> */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pull-left">
                      <Link to={{
                  pathname:'/viewChargingProfile', 
                  state: this.state.chargingProfileId, 
                  }}><button className="btn_primary" onClick={this.backToList} type="button" data-test="button"><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
                  </Link>
                      </div>
                    </div>
                  </div>
          <div className="row mb-3 mt-3">
          <div className="col-md-6 md-form">
                <input name="chargingProfileName" onInput={this.numberHandler.bind(this,"chargingProfileName")} type="text" id="chargingProfileNameId" className="form-control text_lowercase" onChange={this.changeHandler} value={this.state.fields["chargingProfileName"]} />
                <label htmlFor="chargingProfileNameId" className={`${(this.state.fields['chargingProfileName']!="" && this.state.fields['chargingProfileName']!=null)? "active" : ""} ml-15`}>Charging Profile Name</label>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["chargingProfileNameError"]}</span></span>
            </div>
            <div className="col-md-6 md-form">
                <input pattern="[0-9]*" onInput={this.numberHandler.bind(this,"stackLevel")} name="stackLevel" type="text" id="stackLevelid" className="form-control text_lowercase" onChange={this.changeHandler} value={this.state.fields["stackLevel"]} />
                <label htmlFor="stackLevelid" className={`${(this.state.fields['stackLevel']!="" && this.state.fields['stackLevel']!=null)? "active" : ""} ml-15`}>Stack Level (Integer):</label>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["stackLevelError"]}</span></span>
            </div>
                {/* <MDBCol md="6">
                  <MDBInput
                     value={this.state.fields["stackLevel"] }
                     onInput={this.numberHandler.bind(this,"stackLevel")}
                     name="stackLevel"
                     onChange={this.changeHandler}
                     type="text"  
                     pattern="[0-9]*"
                     id="stackLevelid"
                     label="Stack Level (Integer):"
                  >
                  <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["stackLevelError"]}</span></span> 
                  </MDBInput>
                </MDBCol> */}
                {/* <MDBCol md="4">
                  <MDBInput
                       value={this.state.fields["transactionId"] }
                       onInput={this.numberHandler.bind(this,"transactionId")}
                       name="transactionId"
                       onChange={this.changeHandler}
                       type="text"  
                       pattern="[0-9]*"
                       id="transactionId"
                       label="Transaction Id"
                   >
                  </MDBInput>
                </MDBCol> */}
                <MDBCol md="6">

                <div className="w_f">
                <label className="pull-left pt-3 pl-1">Charging Profile Purpose: </label>
                  <select className="browser-default custom-select select_height w_60 pull-right" id="chargingProfilePurposeId" onChange={this.changeHandlerSelect}>
                      <option value="">Select Charging Profile Purpose</option>
                      <option value="ChargePointMaxProfile">ChargePointMaxProfile</option>
                      <option value="TxDefaultProfile">TxDefaultProfile</option>
                      <option value="TxProfile">TxProfile</option>
                  </select>
                </div>
                <span className="error_msg w3-animate-top mt-1"><span style={{color: "red"}}>{this.state.errors["chargingProfilePurposeError"]}</span></span> 
                
                </MDBCol>
                <MDBCol md="6">
                <div className="w_f">
                  <label className="pull-left pt-3 pl-1">Charging Profile Kind:</label>
                  <select className="browser-default custom-select select_height   w_60 pull-right" id="chargingProfileKindId" onChange={this.changeHandlerSelect}>
                      <option value="">Select Charging Profile Kind</option>
                      <option value="Absolute">Absolute</option>
                      <option value="Recurring">Recurring</option>
                      <option value="Relative">Relative</option>
                  </select>
                  </div>
                  <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["chargingProfileKindError"]}</span></span> 
                </MDBCol>
                <MDBCol md="6">
                <div className="w_f" >
                    <label className="pull-left pt-3 pl-1">Recurrency Kind:</label>
                    <select className="browser-default custom-select select_height  w_60 pull-right" id="recurrencyKindId" onChange={this.changeHandlerSelect} >
                        <option value="">Select Recurrency Kind</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                    </select>
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mt-2">
                  <div className="w_f">
                    <label className="pull-left pt-3 pl-1">Charging Schedule:</label>
                    <select className="browser-default custom-select select_height  w_60 pull-right" onChange={this.changeHandlerSelect} id="chargingScheduleId">
                        <option value="">Select Charging Schedule</option>
                        {chargingScheduleItems}
                    </select>
                  </div>
                  <span className="error_msg w3-animate-top cal_errormsg"><span style={{color: "red"}} >{this.state.errors["chargingScheduleError"]}</span></span>      	
                </MDBCol>
                 <MDBCol md="6" className="mt-2" style={{position:"relative"}} >
                 <span className="cal_label w-40 ">Valid From</span>
                      <div className="pull-left mt-2 ml-1" style={{width:"50%"}} >
                        <DatePicker
                          selected={this.state.validFromDate}
                          onChange={this.setValidFromDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          className="cal_margin tag_cal ml-0"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                          maxDate={this.state.validToDate}
                        />
                      </div>
                    <span className="error_msg w3-animate-top cal_errormsg"> <span style={{color: "red"}} >{this.state.errors["emptyscheduleDate"]}</span></span>      	
                 </MDBCol>
                 <MDBCol md="6" className="mt-2" style={{position:"relative"}} >
                    <span className="cal_label w-40">Valid To</span>
                      <div className="pull-left mt-2 ml-1" style={{width:"50%"}} >
                        <DatePicker
                          selected={this.state.validToDate}
                          onChange={this.setValidToDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          className="cal_margin tag_cal ml-0"
                          timeCaption="time"
                          dateFormat="dd-MMM-yyyy HH:mm"
                          minDate={this.state.validFromDate}
                        />
                      </div>
                    <span className="error_msg w3-animate-top cal_errormsg"> <span style={{color: "red"}} >{this.state.errors["emptyscheduleDate"]}</span></span>      	
                 </MDBCol>
                 
              <MDBCol md="12" className="text-right mt-3">
              <button className="mt-5 btn_primary mr-2" disabled={this.state.disabledAttr} onClick={this.toggle1} type="button" data-test="button">Save<i class="fas fa-save ml-2"></i></button>
              {/* <Link to={{
                  pathname:'/viewChargingProfile', 
                  state: this.state.chargingProfileId, 
                  }}><button className="mt-5 refresh-btn" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
                  </Link> */}
          </MDBCol>
      </div>
       </div>
        </form>
        
        <MDBModal isOpen={this.state.modalSuccess}  backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleAdd}>Success</MDBModalHeader>
              <MDBModalBody>
               Charging Profile successfully updated.
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.CloseSuccessPopup}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
             <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
            <MDBModalBody>
              Please login again.
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
        <MDBModalHeader toggle={this.toggle1}>Confirmation</MDBModalHeader>
        <MDBModalBody>
        Are you sure you want to save the changes?
        </MDBModalBody>
        <MDBModalFooter>
        <button type="button" data-test="button" className="btn_primary" onClick={this.toggle1}>Close</button>
        <button type="button" data-test="button" className="btn_primary" onClick={this.saveChargingProfile}>Yes</button>
        </MDBModalFooter>
        </MDBModal>

      </main>
    </>
  );
}
}

export default addChargingProfile;