import React from 'react';
import { MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import Pagination from "react-js-pagination";
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import * as baseUrl from './constants';
import $ from "jquery";
import moment from 'moment';
import { createBrowserHistory } from 'history';
import { dateformatinUTC } from './_methods';
import Tansactionstable from './tansactionsTable';
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';
import servicesUrl from '../common/servicesUrl';
import currencyFormatter from 'currency-formatter';

const history = createBrowserHistory();

 class transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isactiveEnergy: "",
      noOfRecords: '',
      data: new Map(),
      pageSize: 10,
      activePage: 1,
      pageNo: 1,
      errors: {},
      calendertoDisplay: "none",
      calenderfromDisplay: "none",
      disabled: true,
      rfidTag: "",
      sessionId: "",
      statusId: "",
      modalsession: false,
      startDate: new Date(),
      page_title: "Charging Session",
      scheduletoDate: '',
      startDate2: '',
      start_text: "Start",
      disabledRfid: false,
      startDate3: '',
      schedulefromDate: '',
      customerNumber: "",
      refNumber: "",
      chargePointId: '',
      calenderFrom: "none",
      calenderTo: "none",
      fields: {
        saveSelectedValue: "",
        dropDownIds: "",
        chargePointId: "",
      },
      dataArray: [],
      remoteStart: false,
      selectedRfid: "",
      rfidTagsList: [],
      note: "",
      showLoder: false,
      energyLevel: "",
      selectedcpId: "",
      selectedTransactionId: "",
      selectedStatus: "",
      selectedChargedEnergy: "",
      successTransaction: false,
      getOperationList: [],
      getDefaultFields: [
        this.props.t('transactionId'),
        this.props.t('chargePointId'),
        this.props.t('idTag'),
        this.props.t('startTime'),
        this.props.t('chargingDuration'),
        this.props.t('chargingSessionDuration'),
        this.props.t('chargedEnergy'),
        this.props.t('maximumChargingPower'),
        this.props.t('chargingSessionState'),
      ],
      getFieldsList: [],
      mileage: '',
      inventoryNumber: '',
      mileageNdInventory: false,
      transId: '',
      modalMileageNdInventory: false,
      exportBtn: false,
      selectedItems: [],
      prevSelectedData: [],
      availableWallBoxes: [],
      siteWallboxes: [],
      deletedWallboxes: [],
      errors: {},
      wallbox: '',
      successTbContent: false,
      tableCustomization: false,
      tableCustMsg: "Saved",
      exportError: false,
      rfidAcpid: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      fromDate: '',
      fromDateApi: '',
      toDate: '',
      toDateApi: ''
    }
    this.setStartDate = this.setStartDate.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.onChangeDropdown = this.onChangeDropdown.bind(this);
    this.schedulefromChange = this.schedulefromChange.bind(this);
    this.scheduletoChange = this.scheduletoChange.bind(this);
    this.dropDownChange = this.dropDownChange.bind(this);
    this.exportTransactionCSV = this.exportTransactionCSV.bind(this);
    this.showExportPopup = this.showExportPopup.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onClickRefreshbtn = this.onClickRefreshbtn.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
    this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);
    this.keyPress3 = this.keyPress3.bind(this);
    this.toggleRemoteStart = this.toggleRemoteStart.bind(this);
    this.beginRemoteStart = this.beginRemoteStart.bind(this);
    this.idTagChange = this.idTagChange.bind(this);
    this.getRfidTags = this.getRfidTags.bind(this);
    this.successTransaction = this.successTransaction.bind(this);
    this.getCustomization = this.getCustomization.bind(this);
    this.updateEnergyLevel = this.updateEnergyLevel.bind(this);
    this.saveMileageNdInventory = this.saveMileageNdInventory.bind(this);
    this.updateInventory = this.updateInventory.bind(this);
    this.updateMileage = this.updateMileage.bind(this);
    this.toggleMileageNdInventory = this.toggleMileageNdInventory.bind(this);
    this.togglesettingPopup = this.togglesettingPopup.bind(this);
    this.successTableContent = this.successTableContent.bind(this);
    this.toggleExportError = this.toggleExportError.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.saveTableField = this.saveTableField.bind(this)
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.displayCarIcon = this.displayCarIcon.bind(this);
    this.clearCPID = this.clearCPID.bind(this);
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  toggleExportError() {
    this.setState({
      exportError: !this.state.exportError
    });
  }

  isItemSelected(item) {
    const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
    return retVal;
  }

  settingAction = () => {
    this.setState({
      settingPopup: !this.state.settingPopup,
      selectedItems: this.state.prevSelectedData
    });
  }

  togglesettingPopup = () => {
    this.setState({
      settingPopup: !this.state.settingPopup
    });
  }

  successTableContent = () => {
    this.setState({
      successTbContent: !this.state.successTbContent
    });
  }

  toggleSaveMileageNdInventory = () => {
    this.setState({
      modalMileageNdInventory: !this.state.modalMileageNdInventory
    });
  }

  saveMileageNdInventory(mileage, inventoryNumber) {
    if (mileage == "" || mileage == null) {
      $('.error_mileage').text(this.props.t('mileage_not be_empty'));
    }
    if (inventoryNumber == "" || inventoryNumber == null) {
      $('.error_inventoryNumber').text(this.props.t('inventory_no_not_beempty'));
    }
    if (mileage != "" && mileage != null && mileage > 0 && mileage <= 999999) {
      $('.error_mileage').text("");
    } else {
      $('.error_mileage').text(this.props.t('milege_min_max_validation'));
    }
    if (inventoryNumber != "" && inventoryNumber != null && inventoryNumber.length <= 20 && inventoryNumber.length > 0) {
      $('.error_inventoryNumber').text("");
    } else {
      $('.error_inventoryNumber').text(this.props.t('inventory_no_have_min1and_max20_char'));
    }
    if (mileage != "" && mileage != null && inventoryNumber != "" && inventoryNumber != null && mileage > 0 && mileage <= 999999 && inventoryNumber.length <= 20 && inventoryNumber.length > 0) {
      let url = baseUrl.URLPath + "transactions/updateByTranId" + "?tranId=" + this.state.transId + "&mileage=" + this.state.mileage + "&vehicleInventoryNumber=" + this.state.inventoryNumber;

      fetch(url, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization")
        }
      }).then(resp => {
        return resp.json();
      }).then(response => {
        this.toggleMileageNdInventory();
        this.toggleSaveMileageNdInventory();
      })
    }
  }

  toggleMileageNdInventory() {
    this.setState({
      mileageNdInventory: !this.state.mileageNdInventory,
    });
  }

  updateMileage(e) {
    if (e.target.validity.valid) {
      this.setState({
        mileage: e.target.value
      });
      $('.error_mileage').text("");
    }
  }

  updateInventory(e) {
    if (e.target.validity.valid) {
      this.setState({
        inventoryNumber: e.target.value
      });
      $('.error_inventoryNumber').text("");
    }
  }

  updateEnergyLevel(e) {
    var val = e.target.value;
    if (e.target.validity.valid) {
      this.setState({
        energyLevel: e.target.value
      });
      $('.error_energyLevel').text("");
    }
  }

  getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.LoginPath + "/api/admin/v1.0/account/tenant/customization?orgName=" + tenant;
    //localhost:8080/api/preferences/security/api/admin/v1.0/account/tenant/customization
    fetch(url, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else {

        return resp.json();
      }
    }).then(response => {
      this.setState({ getOperationList: response })
    })
  }

  getRfidTags(cpid) {

    var url = baseUrl.URLPath + servicesUrl.getOcpptagList +"?cpId=" + cpid;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          if (cpid != "" && cpid != null && cpid != undefined && response.data.length == 0) {
            $('.error_rfid').text(this.props.t('rfid_found_for_charge_point'));
          } else {
            $('.error_rfid').text("");
          }
          this.setState({ rfidTagsList: response.data })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  idTagChange() {
    var x = document.getElementById("tagList").value;
    this.setState({ selectedRfid: x }, () => { $('.error_rfid').text(""); });
  }

  beginRemoteStart(cpId, transactionId) {
    var url = baseUrl.URLPath + "cs/operations/RemoteStartTransaction";
    let cpLists = [];
    cpLists.push(cpId);
    let energy = "";
    if (this.state.energyLevel != "" && this.state.energyLevel != null && this.state.energyLevel != undefined) {
      energy = this.state.energyLevel;
    }
    let data = {
      cplist: cpLists,
      payload: { idTag: this.state.selectedRfid, connectorId: 1, chargingProfile: "" },
      type: "RemoteStartTransaction",
      note: this.state.note,
      customerNumber: this.state.customerNumber,
      referenceNumber: this.state.refNumber,
      energyLevel: energy,
      transactionId: transactionId
    };
    let selectedrfid = "";
    if (this.state.selectedRfid != "" && this.state.selectedRfid != null && this.state.selectedRfid != undefined) {
      selectedrfid = this.state.selectedRfid.trim();
    }
    if (this.state.selectedRfid == "" || this.state.selectedRfid == null || this.state.selectedRfid == undefined || this.state.selectedRfid == "-- Select RFID Tag * --" || selectedrfid == "") {
      $('.error_rfid').text(this.props.t('rfid_not_be_empty'));
    } else if (this.state.isactiveEnergy && (this.state.energyLevel == "" || this.state.energyLevel == undefined || this.state.energyLevel == null)) {
      $('.error_energyLevel').text(this.props.t('energy_level_notbe_empty'));
    } else if (this.state.energyLevel != "" && this.state.energyLevel != null && this.state.energyLevel != undefined && (parseFloat(this.state.energyLevel) <= 0 || parseFloat(this.state.energyLevel) > 9999999999 || ((parseFloat(this.state.energyLevel) <= this.state.selectedChargedEnergy) && this.state.selectedStatus == "activeCharging"))) {
      if (parseFloat(this.state.energyLevel) <= 0) {
        $('.error_energyLevel').text(this.props.t('energy_level_begreater_than0'));
      }
      if (parseFloat(this.state.energyLevel) > 9999999999) {
        $('.error_energyLevel').text(this.props.t('energy_level_not_be_greater_than9999999999'));
      }
      if ((parseFloat(this.state.energyLevel) <= this.state.selectedChargedEnergy) && this.state.selectedStatus == "activeCharging") {
        $('.error_energyLevel').text(this.props.t('energy_level_begreater_than_charged_energy'));
      }
    } else {

      if (this.state.selectedStatus == "activeCharging") {
        this.updateRemoteStart(transactionId);
      } else {



        fetch(url, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }
        })
          .then((resp) => {
            if (resp.status == 401) {
              this.togglesession();
              localStorage.clear();
            }
            if (resp.status == 200) {
              return resp.json();
            }
          })
          .then((response) => {
            if (response != undefined) {
              if (response[2] == "Request is Processed Successfully") {
                this.toggleRemoteStart();
                this.successTransaction();
              }
            }
          })
          .catch((error) => {
            console.log(error, "catch the loop")
          })
      }
    }
  }

  updateRemoteStart(transactionId) {
    var url = baseUrl.URLPath + servicesUrl.getUpdateRemotestartTrans  +"?transactionId=" + transactionId + "&referenceNumber=" + this.state.refNumber + "&customerNumber=" + this.state.customerNumber + "&energyLimit=" + this.state.energyLevel;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.toggleRemoteStart();
          this.successTransaction();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  toggleRemoteStart(cpId, trans_id, energylevel, idtag, customerNumber, referenceNumber, status, chargedEnergy) {


    if (cpId != "" && cpId != undefined && cpId != null) {
      this.getRfidTags(cpId);
      if (status == "activeCharging" && idtag != "" && idtag != null && idtag != undefined) {
        this.setState({
          disabledRfid: true,
          start_text: "Update"
        })
      }
      if (status == "activePreparing") {
        this.setState({
          disabledRfid: false,
          start_text: "Start"
        })
      }
      let active = false;
      if (energylevel != "" && energylevel != null && energylevel != undefined && energylevel > 0) {
        active = true;
      } else {
        active = false;
      }
      this.setState({ remoteStart: !this.state.remoteStart, selectedcpId: cpId, selectedTransactionId: trans_id, energyLevel: energylevel, selectedRfid: idtag, isactiveEnergy: active, selectedStatus: status, selectedChargedEnergy: chargedEnergy, refNumber: referenceNumber, customerNumber: customerNumber });
    } else {
      this.setState({ remoteStart: !this.state.remoteStart });
    }
  }

  successTransaction() {
    this.setState({
      successTransaction: !this.state.successTransaction,
      energyLevel: "",
      note: ""
    });
  }

  changeScheduleFrom(e) {
    this.setState({ schedulefromDate: e.target.value });
  }

  changeScheduleTo(e) {
    this.setState({ scheduletoDate: e.target.value });
  }

  keyPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ schedulefromDate: e.target.value });
        var dateFormat = moment(this.state.schedulefromDate).format("YYYY-MM-DD");
        var timeFormat = new Date().toISOString().substr(0, 19) + "Z"
        var convertedTime = timeFormat.slice(10);
        this.state.startDate2 = moment(this.state.schedulefromDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS")
        // if (dateRangeValidate(this.state.startDate1,this.state.startDate2)==true){
        //   $('.date_range_error').text('');
        this.getTransactions(this.state.pageSize, 1);
        //  }else{
        //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        //  }
        $('.future_err1').text("");
      } else {
        this.setState({ schedulefromDate: "" });
        this.state.startDate2 = "";
        $('.future_err1').text(this.props.t('cannot_select_futuredate'));
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate2 = "";
      this.getTransactions(this.state.pageSize, 1);
      $('.future_err1').text("");
    }
  }

  keyPress2(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ scheduletoDate: e.target.value });
        var dateFormat = moment(this.state.scheduletoDate).format("YYYY-MM-DD");
        var timeFormat = new Date().toISOString().substr(0, 19) + "Z"
        var convertedTime = timeFormat.slice(10);
        this.state.startDate3 = moment(this.state.scheduletoDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS");
        // if (dateRangeValidate(this.state.startDate1,this.state.startDate2)==true){
        //   $('.date_range_error').text('');
        this.getTransactions(this.state.pageSize, 1);
        //  }else{
        //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        //  }
        $('.future_err2').text("");
      } else {
        this.setState({ scheduletoDate: "" });
        this.state.startDate3 = "";
        $('.future_err2').text(this.props.t('cannot_select_futuredate'));
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate3 = "";
      this.getTransactions(this.state.pageSize, 1);
      $('.future_err2').text("");
    }
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    let fromDateApi = '';
    if (this.state.fromDate != "" && this.state.fromDate != null && this.state.fromDate != undefined) {
      fromDateApi = dateformatinUTC(moment(this.state.fromDate));
    }
    let toDateApi = '';
    if (this.state.toDate != "" && this.state.toDate != null && this.state.toDate != undefined) {
      toDateApi = dateformatinUTC(moment(this.state.toDate));
    }

    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: fromDateApi,
      toDateApi: toDateApi
    }, () => {
      this.getTransactions(entries, 1);
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getTransactions(this.state.pageSize, pageNumber, "", false);
      });
    } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: dateformatinUTC(this.state.fromDate),
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getTransactions(this.state.pageSize, pageNumber, "", false);
      });
    }
  }

  // method to call onChange Operation Name
  dropDownChange() {
    var id = document.getElementById("selectDropdown").value;
    let fields = this.state.fields;
    fields["saveSelectedValue"] = id;
    this.setState({ saveSelectedValue: id })
    this.ascendingOrder = this.ascendingOrder.bind(this);
    this.descendingOrder = this.descendingOrder.bind(this);
    this.closeCalender = this.closeCalender.bind(this);


  }

  closeCalender(type, event) {
    if (type == "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }

  displayCalender(type, event) {
    var calenderFrom = "";
    var calenderTo = "";
    if (type == "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }
    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }

  // method to call onChange Operation Name
  dropDownChange() {
    var id = document.getElementById("selectDropdown").value;
    let fields = this.state.fields;
    fields["saveSelectedValue"] = id;
    this.setState({ saveSelectedValue: id })
    if (this.state.fields.saveSelectedValue != "") {
      this.state.disabled = false;
    }
    else {
      this.state.disabled = true;
    }
  }

  //to save selected table content 
  saveTableField = () => {

    this.setState({
      settingPopup: !this.state.settingPopup
    })
    var method;
    var url;
    method = "POST";
    url = baseUrl.URLPath + "transactions/saveFileteredColumns";

    let data = {
      transactionColumns: this.state.selectedItems
    };
    fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        console.log(response)
        if (response != undefined) {
          // if (response[2] == "Request is Processed Successfully") {
          this.successTableContent();
          //   this.successTransaction();
          // }
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })


  }

  //to get selected table content from api 
  getTableField = () => {

    var url = baseUrl.URLPath + "transactions/getFilteredColumns";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 404) {
          this.setState({
            // selectedItems:this.state.getDefaultFields,
            tableCustomization: false
          })
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
       // console.log("Responseeee:",response)
        if (response != undefined) {
          // if (response[2] == "Request is Processed Successfully") {
          var prevSelectedData = [];
          for (var i = 0; i < response.length; i++) {
            if (response[i].isSelected == true) {
              this.state.selectedItems.push(response[i].key)
              prevSelectedData.push(response[i].key)
            }
          }
          this.setState({
            getFieldsList: response,
            prevSelectedData: prevSelectedData,
            tableCustomization: true
          })
          //   this.successTransaction();
          // }
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }

  //change function for chargepoint status filter
  onChangeChargepoint(e) {
    let errors = {};
    if (this.state.fields.chargePointId == "") {
      errors['entercharge'] = this.props.t('select_charge_point')
      this.setState({
        errors
      });
    } else {
      errors['entercharge'] = '';

      this.setState({
        activePage: 1,
        errors
      }, () => {
        this.getTransactions(this.state.pageSize, this.state.activePage, 'click');
      });
    }
  }

  //change function for rfid, session and status filter
  onChangeDropdown() {
    var rfidStatus = document.getElementById("dropDownIds").value;
    this.setState({ dropDownIds: rfidStatus }, () => {
      if (rfidStatus) {
        this.getTransactions(this.state.pageSize, this.state.activePage);
      }
    });
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    $("#notexists").text("");
    this.setState({ fields, errors: {} });
  }

  keyPress3(e) {
    var key = e.key;

    if (e.keyCode == 13) {
      this.setState({ chargePointId: e.target.value, activePage: 1 });
      this.getTransactions(this.state.pageSize, 1, "enter");
    }

    if (key == "Backspace" || key == "Delete") {
      var x = e.target.value
      var delitem = x.slice(0, x.length - 1)
      if (delitem == "") {
        this.state.fields.chargePointId = delitem;
        this.state.activePage = 1;
        this.getTransactions(this.state.pageSize, 1)
      }
    }

    if (key == "Delete") {
      window.location.reload();
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  setStartDate(date) {
    this.setState({ startDate: date })
    this.getTransactions(date);
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  // get Date
  schedulefromChange = date => {
    var fdate = this.formatLocalCalDate(date);
    this.setState({ schedulefromDate: fdate });
    this.state.startDate2 = moment(date).utc().format("YYYY-MM-DDTHH:mm:ss:SSS");
    this.state.date1 = this.formateDate(date);

    this.getTransactions(this.state.pageSize, 1);
    var calenderFrom = "none";
    this.setState({
      calenderFrom: calenderFrom
    });
  }

  formateDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formateDate1(date) {
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }

  scheduletoChange = date => {
    var fdate = this.formatLocalCalDate(date);
    this.setState({ scheduletoDate: fdate });
    this.state.startDate3 = moment(date).utc().format("YYYY-MM-DDTHH:mm:ss:SSS");
    this.state.date2 = this.formateDate(date);

    this.getTransactions(this.state.pageSize, 1);
    var calenderTo = "none";
    this.setState({
      calenderTo: calenderTo
    })
  }

  formatCalDate(date) {
    var format = date.toISOString().substr(0, 19) + "Z";
    return format;
  }

  formatLocalCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    var monthname = d.toLocaleString('default', { month: 'short' });
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [day, monthname, year].join(' ');
  }

  // get transactions based on filter
  getTransactions(pageSize, pageNo, action, countValue) {
    document.getElementById("loader_image_div").style.display = "block";

    let chargePointId = this.state.fields.chargePointId;

    if (this.state.fields.saveSelectedValue == "RFID Tag") {
      this.state.rfidTag = this.state.fields.dropDownIds;
      this.state.sessionId = "";
      this.state.statusId = "";
    } else if (this.state.fields.saveSelectedValue == "Session ID") {
      this.state.sessionId = this.state.fields.dropDownIds;
      this.state.rfidTag = "";
      this.state.statusId = "";
    } else if (this.state.fields.saveSelectedValue == "Status") {
      this.state.statusId = this.state.fields.dropDownIds;
      this.state.sessionId = "";
      this.state.rfidTag = "";
    }

    let errors = {};

    var url = baseUrl.URLPath + "transactions?chargepointid=" + chargePointId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&rfid=" + this.state.rfidTag + "&sessionid=" + this.state.sessionId + "&status=" + this.state.statusId + "&pageNo=" + pageNo + "&pageSize=" + pageSize;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
          }, 100);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          if (response.count.count == 0) {
            if (action == "click" || action == "enter") {
              setTimeout(() => {
                document.getElementById("loader_image_div").style.display = "none";
                errors['notexists'] = this.props.t('charge_point_id_not_exists.');
                this.setState({
                  errors
                });
              }, 100);
            }
            setTimeout(() => {
              this.setState({
                dataArray: [],
                noOfRecords: 0
              }, () => {
                document.getElementById("loader_image_div").style.display = "none";
              })
            }, 100);
          } else {
            var totalcount = "";
            if (countValue == false) {
              totalcount = this.state.noOfRecords
            } else {
              totalcount = response.count.count
            }

            setTimeout(() => {
              this.setState({
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                dataArray: response.recordList,
                noOfRecords: totalcount,
                curPageFirstCreTime: moment(response.recordList[0].startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.recordList[response.recordList.length - 1].startTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              }, () => {
                document.getElementById("loader_image_div").style.display = "none";
              })
            }, 100);
            if (action == "click" || action == "enter") {
              setTimeout(() => {
                document.getElementById("loader_image_div").style.display = "none";
                errors['notexists'] = '';
                this.setState({
                  errors
                });
              }, 100);
            }
          }
        }
      })
      .catch((error) => {
        setTimeout(() => {
          console.log(error, "catch the loop")
          document.getElementById("loader_image_div").style.display = "none";
        }, 100);
      })
  }

  componentDidMount() {
    let fromDate = moment().subtract(1, 'd');
    let fromDateApi = dateformatinUTC(moment().subtract(1, 'd'));
    let toDate = new Date();
    let toDateApi = dateformatinUTC(moment(toDate));

    this.setState({
      fromDate: new Date(fromDate),
      fromDateApi: fromDateApi,
      toDate: toDate,
      toDateApi: toDateApi,
    }, () => {
      this.getTransactions(this.state.pageSize, this.state.activePage);
    });

    this.getRfidTags();
    this.getCustomization();
    this.getTableField();
    
  }

  //function for sorting table data on click
  ascendingOrder() {
    this.setState({ dataArray: this.state.dataArray.reverse() })
  }

  //function for sorting table data on click
  descendingOrder() {
    this.setState({ dataArray: this.state.dataArray.reverse() })
  }

  showExportPopup = () => {
    if (!this.state.exportBtn) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      exportBtn: !prevState.exportBtn
    }));
  }

  handleOutsideClick = e => {
    if (e.target != null && e.target != undefined) {
      if (!this.node.contains(e.target)) this.showExportPopup();
    }
  }

  exportTransactionCSV = (type) => {
    this.setState({
      showLoder: !this.state.showLoder
    });

    let fromDate = '';
    if (this.state.fromDate != '' && this.state.fromDate != undefined) {
      fromDate = dateformatinUTC(this.state.fromDate)
    }

    let toDate = '';
    if (this.state.toDate != '' && this.state.toDate != undefined) {
      toDate = dateformatinUTC(this.state.toDate)
    }

    var timezone = moment().tz(moment.tz.guess()).format('z');

    if (type == "csv") {
      var url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=csv&chargepointid=" + this.state.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate;
    } else {
      var url = baseUrl.URLPath + servicesUrl.downloadTransactions + "?fileType=xls&chargepointid=" + this.state.chargePointId + "&fromTime=" + fromDate + "&toTime=" + toDate;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": timezone
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
        if (resp.status == 500) {
          this.setState({
            showLoder: !this.state.showLoder
          })
        }
      })
      .then((response) => {
        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          if (type == "csv") {
            a.download = 'Charging_session_' + dformat + '.csv';
          } else {
            a.download = 'Charging_session_' + dformat + '.xlsx';
          }
          a.click();
          this.setState({
            exportBtn: !this.state.exportBtn,
            showLoder: !this.state.showLoder
          })
        }
      })
      .catch((error) => {
        this.setState({
          showLoder: !this.state.showLoder
        }, () => {
          this.toggleExportError();
        })
        console.log(error, "catch the loop")
      })
  }

  onClickRefreshbtn() {
    this.getTransactions(this.state.pageSize, this.state.activePage, "", false);
  }

  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedItems];
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    if (event.target.checked) {
      if (selectedItemIndex < 0) {
        allItems.push(item);
      }
    } else {
      const deletedWallboxIndex = this.state.siteWallboxes.findIndex(el => el === item);
      const isAlreadyDeleted = deletedWallboxes.findIndex(el => el === item) > -1;
      if (deletedWallboxIndex > -1 && !isAlreadyDeleted) {
        deletedWallboxes.push(item);
      }
      allItems.splice(selectedItemIndex, 1);
    }
    this.setState({ ...this.state, selectedItems: allItems, deletedWallboxes });
  }

  // new function 
  displayCarIcon(transId, mileage, inventoryNumber) {
    this.setState({ transId: transId, mileage: mileage, inventoryNumber: inventoryNumber });
    this.toggleMileageNdInventory();
  }

  // for from date calender 
  handleFromChange = date => {
    $('.future_err1').text("");
    let fromD = '';
    if (date != undefined && date != "" && date != null) {
      fromD = dateformatinUTC(date)
    }
    this.setState({
      fromDate: date,
      fromDateApi: fromD,
      activePage: 1
    }, () => {
      this.getTransactions(this.state.pageSize, 1);
    });
  }

  // for to date calender 
  handleToChange = date => {
    let toD = '';
    if (date != undefined && date != "" && date != null) {
      toD = dateformatinUTC(date)
    }

    this.setState({
      toDate: date,
      toDateApi: toD,
      activePage: 1
    }, () => {
      this.getTransactions(this.state.pageSize, 1)
    });
  }

  clearCPID(){
    let fields = this.state.fields;
    fields['chargePointId'] = '';
    let errors = {}

    if(this.state.errors["entercharge"] || this.state.errors["notexists"]){
      errors['entercharge'] = '';
      errors['notexists'] = '';
      this.setState({
        fields: fields,
        errors
      });
    } else {
      errors['entercharge'] = '';
      errors['notexists'] = '';
      this.setState({
        activePage: 1,
        fields: fields,
        errors
      }, () => {
        this.getTransactions(this.state.pageSize, this.state.activePage);
      });
    }
  }

  render() {
    const{t}=this.props
    const table = { field: [] };

    if (this.state.getDefaultFields !== undefined) {
      table.field = this.state.getDefaultFields;
    }

    let editAction = false;
    let carAction = false;

    this.state.getOperationList.map((Operation, index) => {
      if (Operation.operation == "Charging Session" && Operation.active == true) {
        Operation.subOperations.map((oper, i) => {
          if (oper.name == "Remote Start Transaction" && oper.access == true) {
            editAction = true;
          }
          if (oper.name == "Logbook" && oper.access == true) {
            carAction = true;
          }
        })
      }
    });

    const tagList = this.state.rfidTagsList.map((tag, index) => {
      return (
        <> {(tag.idTag.trim() != 0 || tag.idTag.trim() != "") ? <option value={tag.idTag}>{tag.idTag}</option> : null} </>
      )
    });

    return (
      <>
        <main class="content-div" >
          <p>{t('Charging Session')}</p>
          <div className="page-outerdiv">

            {/* start Search bar div */}
            <div className="row">
              {this.state.showLoder == true &&
                <div className="page_loader center" id="loader_image_div">
                  <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
              }

              <div className="col-md-3 mt-2">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onChange={this.changeHandler.bind(this, "chargePointId")} onKeyDown={this.keyPress3} class="form-control input-searchBox pad-2" placeholder={t('charge_point_id')} name="srch-term" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.clearCPID}>
                    <span aria-hidden="true">x</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint}>{t('find')}</button>
                  </div>
                </div>
                <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
              </div>

              <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">{t('from_time')}</span>
                <div className="pull-left mt-2"  >
                  <DatePicker
                    selected={this.state.fromDate}
                    onChange={this.handleFromChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12 datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
              </MDBCol>

              <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">{t('to_time')}</span>
                <div className="pull-left mt-2" >
                  <DatePicker
                    selected={this.state.toDate}
                    onChange={this.handleToChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    minDate={new Date(this.state.fromDate)}
                    maxDate={new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
              </MDBCol>
              <div className='col-md-12 date_range_error' style={{ color: "red", textAlign: "center" }}></div>

              <MDBCol md="3" className="mt-2" >
                <select className="browser-default custom-select select_height" id="selectDropdown" onChange={this.dropDownChange}>
                  <option value="">{t('select')}</option>
                  <option value="RFID Tag">{t('idTag')}</option>
                  <option value="Session ID">{t('chargingSesionId')}</option>
                  <option value="Status">{t('chargingSessionState')}</option>
                </select>
              </MDBCol>

              <div className="col-md-3 mt-3">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="dropDownIds" value={this.state.fields["dropDownIds"]} onChange={this.changeHandler.bind(this, "dropDownIds")} disabled={this.state.disabled} class="form-control input-searchBox pad-2" placeholder={t('rfid_session_id_status')} name="adminAddress" />
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeDropdown}>{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top" > <span style={{ color: "red" }}></span></span>
                </div>
              </div>

              <div className="col-md-3 mt-2 pt-1">
                <button className="pull-right refresh-btn" type="button" data-test="button" onClick={this.onClickRefreshbtn}>{t('refresh')} <i className="fas fa-refresh fa-sm ml-2"></i> </button>
                <div className='p-rel'>
                  <button type="button" data-test="button" className="btn_primary pull-right mr-2" onClick={this.showExportPopup}>
                    {t('export')} <i className="fas fa-file-export ml-2"></i>
                  </button>
                  {this.state.exportBtn &&
                    <div className="exportPopup" ref={node => {
                      this.node = node;
                    }}>
                      <div className="expDiv">
                        <label className='pr-2 mb-0' onClick={() => this.exportTransactionCSV('xls')}>{t('download_as_xls')}  </label>
                        <label className='pr-1 mb-0'><i className="fas fa-download ml-2"></i></label>
                      </div>
                      <div className="expDiv">
                        <label className='pr-1 mb-0' onClick={() => this.exportTransactionCSV('csv')}>{t('download_as_csv')}</label>
                        <label className='pr-1 mb-0'><i className="fas fa-download ml-2"></i></label>
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div className="col-md-3 mt-2 pt-1">
                <div className="pull-right">
                  <label className="sub-text pull-left" >{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* end search bar div */}

            {/* table start */}
            <div className="row">
              <div className="col-md-12 p-rel  " >
                {/* settings popup */}
                <div className='settingOuterDiv'>
                  <MDBModal isOpen={this.state.settingPopup} size="md" className="settingDiv" backdrop="static" data-backdrop="static" data-keyboard="false" >
                    <MDBModalHeader toggle={this.togglesettingPopup} >{t('table_content')}</MDBModalHeader>
                    <MDBModalBody className="mt-0">
                      <div className="row">
                        {this.state.getFieldsList.map((el, index) => {
                          if (editAction == false && (el.displayName == "Customer Number" || el.displayName == "Reference Number")) {
                          } else {
                            return (
                              <div className="col-md-6">
                                <Form.Check
                                  className=""
                                  custom
                                  id={`${el.key}_${index}`}
                                  type="checkbox"
                                  checked={this.isItemSelected(el.key)}
                                  onChange={(event) => this.updateCheckboxSelection(event, el.key)}
                                  label={this.props.t(el.key)}
                                />
                              </div>
                            )
                          }
                        })
                        }
                      </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <button type="button" data-test="button" className={this.state.selectedItems.length > 0 ? "btn_primary mr-2" : "btn_primary mr-2 btn_cancel"} onClick={this.saveTableField} disabled={this.state.selectedItems.length > 0 ? "" : "true"}>{t('save')}<i class="fas fa-save ml-2"></i></button>
                    </MDBModalFooter>
                  </MDBModal>
                </div>

                <div className='overflowx'>
                  <Tansactionstable
                  {...this.props}
                    sorting={this.ascendingOrder.bind(this)}
                    item={this.state.dataArray}
                    settingAction={this.settingAction.bind(this)}
                    tableField={this.state.getFieldsList}
                    editAction={editAction}
                    toggleRemoteStart={this.toggleRemoteStart}
                    carAction={carAction}
                    displayCarIcon={this.displayCarIcon}
                  >
                  </Tansactionstable>
                </div>
              </div>

              <div className="col-md-12 mt-2 mb-4 text-right" >
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                  prevPageText={t('prev')}
                  nextPageText={t('next')}
                  itemClassPrev="prevBtn"
                  pageRangeDisplayed="1"
                  activeClass="activeLi active"
                  itemClassNext="nextBtn"
                  hideFirstLastPages={true}
                  disableInitialCallback={true}
                  disabledClass="disabled disabledtextColor"
                />
              </div>
              {/* End table  */}
            </div>
          </div>

          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.successTransaction} toggle={this.successTransaction} size="md" className="model_top">
            <MDBModalHeader toggle={this.successTransaction}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('transaction')}  {this.state.start_text == "Update" ? "updated" : "started"} {t('successfully')}.
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={() => { this.successTransaction.bind(this); window.location.reload(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.remoteStart} toggle={this.toggleRemoteStart} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleRemoteStart} className="mt-3"><b><span style={{ fontSize: "15px", paddingLeft: "9px" }}>{t('remote_start')}</span></b></MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md="9">
                  <select className="browser-default custom-select select_height mb_8" id="tagList" onChange={this.idTagChange.bind(this)} value={this.state.selectedRfid} disabled={this.state.disabledRfid ? true : null}>
                    <option>-- {t('select_rfid_tag')} --</option>
                    {tagList}
                  </select>
                  <span className="error_msg w3-animate-top error_rfid" style={{ color: "red" }}></span>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="6">
                  <div className="md-form">
                    <input type="text" id="refNumber" className="form-control pl-0" onChange={(e) => { this.setState({ refNumber: e.target.value }) }} value={this.state.refNumber} maxLength="20" pattern="[A-Za-z0-9]*" />
                    <label htmlFor="refNumber" className={(this.state.refNumber != "" && this.state.refNumber != null) ? "active" : ""}><b>{t('referenceNumber')}</b></label>
                    <span className="error_Msg w3-animate-top error_refnumber" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
                <MDBCol md="6">
                  <div className="md-form">
                    <input type="text" id="customerNumber" className="form-control pl-0" onChange={(e) => { this.setState({ customerNumber: e.target.value }) }} value={this.state.customerNumber} maxLength="20" pattern="[A-Za-z0-9]*" />
                    <label htmlFor="customerNumber" className={(this.state.customerNumber != "" && this.state.customerNumber != null) ? "active" : ""}><b>{t('customerNumber')}</b></label>
                    <span className="error_Msg w3-animate-top error_customerNumber" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow id="customization" className="mt-3">
                <MDBCol md="12" className="energy_limitation pl-0">
                  <Form.Check
                    className="mt-3"
                    custom
                    value={(this.state.isactiveEnergy == true) ? true : false}
                    checked={(this.state.isactiveEnergy == true) ? true : false}
                    id={`energyLimit`}
                    type="checkbox"
                    label={<div><b>{t('energy_limitation')}</b></div>}
                    onChange={() => {
                      this.setState({ isactiveEnergy: !this.state.isactiveEnergy }, () => {
                        if (this.state.isactiveEnergy == false) {
                          this.setState({
                            energyLevel: ""
                          })
                        } else if (this.state.isactiveEnergy == true) {
                          this.setState({
                            energyLevel: ""
                          })
                        }
                      })
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="6">
                  <div className="md-form">
                    <input type="text" id="energyLevel" disabled={(this.state.isactiveEnergy != true) ? true : false} className="form-control pl-0" pattern="[0-9.]*" onChange={this.updateEnergyLevel} value={this.state.energyLevel} />
                    <label htmlFor="energyLevel" className={(this.state.energyLevel != "" && this.state.energyLevel != null) ? "active" : ""}><b>{t('charged_energy_to(kWh)')}</b></label>
                    <span className="error_Msg w3-animate-top error_energyLevel" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.beginRemoteStart.bind(this, this.state.selectedcpId, this.state.selectedTransactionId)}>{this.state.start_text}</button>
              <button type="button" className="btn_cancel" onClick={this.toggleRemoteStart.bind(this)}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.mileageNdInventory} toggle={this.toggleMileageNdInventory} size="md" className="model_top">
            <div class="modal-header row mx-0" ref={(node) => { if (node) { node.style.setProperty("border-bottom", "none", "important"); } }}>
              <h4 class="modal-title fw-800 mt-1 col-md-12 pl-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('mileage_and_inventory_num_to_chargingsession')}</h4>
            </div>
            <MDBModalBody>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="mileage" className="form-control pl-0" pattern="[0-9.]*" onChange={this.updateMileage.bind(this)} value={this.state.mileage} />
                    <label htmlFor="mileage" className={(this.state.mileage != "" && this.state.mileage != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>{t('current_mileage_of_vehicle')}</label>
                    <span className="error_Msg w3-animate-top error_mileage" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol md="8">
                  <div className="md-form">
                    <input type="text" id="inventoryNumber" className="form-control pl-0" onChange={this.updateInventory.bind(this)} value={this.state.inventoryNumber} pattern="[a-zA-Z0-9]*" />
                    <label htmlFor="inventoryNumber" className={(this.state.inventoryNumber != "" && this.state.inventoryNumber != null) ? "active fw-800" : "fw-800"} style={{ fontSize: "18px" }}>{t('inventory_num_of_vehicle')}</label>
                    <span className="error_Msg w3-animate-top error_inventoryNumber" style={{ color: "red" }}></span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <div class="modal-footer border border-0">
              <button type="button" className="btn_primary" onClick={this.saveMileageNdInventory.bind(this, this.state.mileage, this.state.inventoryNumber)}>{t('save')} <i class="fas fa-save ml-2"></i></button>
              <button type="button" className="btn_cancel" onClick={this.toggleMileageNdInventory}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
            </div>
          </MDBModal>

          <MDBModal isOpen={this.state.modalMileageNdInventory} toggle={this.toggleSaveMileageNdInventory} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleSaveMileageNdInventory}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('mileage_and_inventory_saved_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={() => { this.getTransactions(this.state.pageSize, this.state.activePage); this.toggleSaveMileageNdInventory(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* table content popup success  */}
          <MDBModal isOpen={this.state.successTbContent} toggle={this.successTableContent} size="md" className="model_top">
            <MDBModalHeader toggle={this.successTableContent}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('table_saved_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={() => { this.successTableContent.bind(this); window.location.reload(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* export popup failure  */}
          <MDBModal isOpen={this.state.exportError} toggle={this.toggleExportError} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleExportError}>{t('alert')}</MDBModalHeader>
            <MDBModalBody>
              {t('unable_to_download_contact_your_admin')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.toggleExportError}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}
export default withTranslation()(transactions);