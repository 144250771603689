import React from 'react';
import ReactDOM from "react-dom";
import { MDBContainer, MDBCol,MDBRow, MDBCard ,MDBCardBody, MDBBtn, MDBCardHeader,MDBCardTitle,MDBCardText,MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBMask ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import Dialog from 'react-bootstrap-dialog'
import { Line ,Bar, Doughnut, Polar  } from "react-chartjs-2";
import { Radar ,Pie } from "react-chartjs-2";
import  $ from "jquery";
import * as baseUrl from '../constants';
import Header from '../layout/header';
import { hasRole, hasPermission } from '../auth';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class StatusGraph extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                offlineCount:'',
                onlineCount:'',
                unknownCount:'',
                modalsession:false,
                 dataDoughnut : {
                    labels: [this.props.t('offline'), this.props.t('online'), this.props.t('unknown')],
                    dataLabels: {
                        enabled: false
                      },
                    datasets: [
                      {
                        data: [],
                        backgroundColor: ["#00529e", "#72C93E", "#FF9336"],
                        hoverBackgroundColor: [
                        ],
                      }
                    ],
                  },
                  statusData:{
                                "tenantId": '',
                                "onlineCPCount": '1',
                                "oflineCPCount": '1',
                                "unknownCPCount": '1'
                            }
            }
            this.togglesession = this.togglesession.bind(this);
            this.sessiontagOk  = this.sessiontagOk.bind(this);
        }

    async togglesession(){
        ;
        this.setState({
        modalsession: !this.state.modalsession
        });
    }

    sessiontagOk(){
        history.push('/');
        window.location.reload();
    }
      
  componentDidMount(){
   
    var url = baseUrl.URLPath + "dashboard/chargepoints/status/counts";
    fetch(url,{
      method: "GET",
      headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
      })
      .then((resp) => {
        ;
        if(resp.status==401){
          // alert("Access token is expired, please login again")
          this.togglesession();
          localStorage.clear();
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        
        if(response!=undefined){
            var total = response.oflineCPCount+response.onlineCPCount+response.unknownCPCount;
            if(total == 0){
            var perOnline =  0;
            var perOffline = 0;
            var perUnknown = 0;
            this.state.dataDoughnut.datasets[0].data = [perOffline,perOnline,perUnknown];
            this.setState({ 
                statusData:response,
                offlineCount:perOffline,
                onlineCount:perOnline,
                unknownCount:perUnknown,
             })
             }
          else{
           var perOnline =  (response.onlineCPCount/total)*100;
           var perOffline = (response.oflineCPCount/total)*100;
           var perUnknown = (response.unknownCPCount/total)*100;
           perOffline = perOffline.toFixed();
           perOnline = perOnline.toFixed();
           perUnknown = perUnknown.toFixed();
           this.state.dataDoughnut.datasets[0].data = [perOffline,perOnline,perUnknown];
            this.setState({ 
                statusData:response,
                offlineCount:perOffline,
                onlineCount:perOnline,
                unknownCount:perUnknown,
             })
            
        }
      }
        // this.getProducts();
      })
      
      .catch((error) => {
          console.log(error, "catch the loop")
      })
    }


        render() {
                return (
                    <>
                        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                            <div class="content-box">
                                <div class="dashboard-subtitle">
                                    {this.props.t('chargingSessionState')}
                                </div>
                                <div className="mt-3 mb-2 mt-3">
                                    <Doughnut data={this.state.dataDoughnut} options={{responsive: true , legend: { display: false } }} height="100%" width="168" />
                                </div>
                                <div className="row white-space mt-5">
                                    <div className="col-md-4 text-center green_text f-12">
                                        <span>{this.props.t('online')}</span><br></br>
                                        <span>{this.state.onlineCount}%</span>
                                    </div>
                                    <div className="col-md-4 text-center gblue_text  f-12">
                                        <span>{this.props.t('offline')}</span><br></br>
                                        <span>{this.state.offlineCount}%</span>
                                    </div>
                                    <div className="col-md-4 text-center yellow_text  f-12">
                                        <span>{this.props.t('unknown')}</span><br></br>
                                        <span>{this.state.unknownCount}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                            <MDBModalHeader toggle={this.togglesession}>{this.props.t('session_has_expired')}</MDBModalHeader>
                            <MDBModalBody>{this.props.t('please_login_again')}</MDBModalBody>
                            <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.sessiontagOk}>{this.props.t('ok')}</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </>
                )
            };
}

export default withTranslation()(StatusGraph);
