import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBCol, MDBModal } from "mdbreact";
import { Form } from 'react-bootstrap'
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import $ from "jquery";


class CsOperationsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple: false,
            errors: {},
            intial_data: [],
            options: [],
            saveSelectedCpid: "",
            selectFindStatus: false
        }
    }

    render() {
        const{t}=this.props
        const ref = React.createRef();
        var displayDiv = "none";
        if (this.props.cpList?.length > 0) {
            displayDiv = "table-cell"
        }
        $("#cpRightSide").height($("#cpLeftSide").height());
        const getChargePoints = this.props.data?.map((chargePoint, index) => {
            if (this.props.isCheckBox) {
                for (var i = 0; i < this.props.cpList?.length; i++) {
                    if (chargePoint.chargePointId == this.props.cpList[i]) {
                        return (
                            <li>
                                <Form.Check onChange={this.props.handleChange.bind(this, chargePoint.chargePointId)}
                                    custom
                                    type="checkbox"
                                    id={`custom-${chargePoint.chargePointId}`}
                                    label=""
                                    checked
                                />
                                {chargePoint.chargePointId}
                            </li>
                        )
                    }
                }
                return (
                    <li>
                        <Form.Check
                            onChange={this.props.handleChange.bind(this, chargePoint.chargePointId)}
                            custom
                            type="checkbox"
                            id={`custom-${chargePoint.chargePointId}`}
                            label=""
                            checked={false}
                        />
                        {chargePoint.chargePointId}
                    </li>
                )
            } else {
                for (var i = 0; i < this.props.cpList?.length; i++) {
                    if (chargePoint.chargePointId == this.props.cpList[i]) {
                        return (
                            <li><Form.Check
                                onChange={this.props.singleChargePointChange.bind(this, chargePoint.chargePointId)}
                                custom
                                type="radio"
                                id={`custom-${chargePoint.chargePointId}`}
                                label=""
                                name="chargepoint"
                                checked
                            />
                                {chargePoint.chargePointId}
                            </li>
                        )
                    }
                }
                return (
                    <li><Form.Check
                        onChange={this.props.singleChargePointChange.bind(this, chargePoint.chargePointId)}
                        custom
                        type="radio"
                        id={`custom-${chargePoint.chargePointId}`}
                        label=""
                        name="chargepoint"
                        checked={false}
                    />
                        {chargePoint.chargePointId}
                    </li>
                )
            }
        });

        const modelListRow = this.props.ModelList.map((model, index) => {
            return (
                <option value={model}>{model}</option>
            )
        });

        let isModel = false;

        if (this.props.operationName == "ChangeConfiguration" || this.props.operationName == "GetConfiguration") {
            isModel = true;
        }

        return (
            <MDBModal isOpen={this.props.isOpen} toggle={this.props.toggle} size="fluid" data-toggle="modal" backdrop="static" className="cpModel" data-backdrop="static" data-keyboard="false" style={{ width: '1024px !important' }}>
                <div className="cpLeft" id="cpLeftSide">
                    <div className='cpHeader'>
                        <div className='row'>
                            <label className='cptitle col-md-3'>{t('select_chargePoint')}</label>
                            <div className={`pl-2 cpSearchDiv ${(isModel ? '' : 'mr-auto')}`} style={{ position: 'relative', top: '-4px' , width:'327px'}}>
                                <div className="input-group">
                                    <i className="fas fa-search fa-sm input-searchIcon"></i>
                                    <Typeahead
                                        labelKey="name"
                                        className="w_70"
                                        multiple={this.state.multiple}
                                        options={this.props.options}
                                        id="rbt_id"
                                        minLength={3}
                                        placeholder={t('select_chargePoint')}
                                        onKeyDown={this.props.handleKeyDown.bind(this, ref)}
                                        onChange={this.props.chargepointIDChange.bind(this)}
                                        ref={ref}
                                    />
                                    <button type="button" className="close close_icon" style={{ left: '63%' }} id="clearInput" aria-label="Close" onClick={this.props.ClearChargepoint.bind(this, ref)}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div className="input-group-btn">
                                        <button className="input-searchBtn find-btn" type="submit" onClick={this.props.FindCpName.bind(this, ref)}>{t('find')}</button>
                                    </div>
                                    <span className="pull-left error_msg w3-animate-top mt-0" id="nochargeexists"><span style={{ color: "red" }}>{this.props.errors["chargePointNotExists"]}</span></span>
                                    <span className="pull-left error_msg w3-animate-top mt-0" id="selectcharge"><span style={{ color: "red" }}>{this.props.errors["selectChargepoint"]}</span></span>
                                </div>
                            </div>
                            <div className='mr-3 pull-right ' style={{flex:1}}>
                                {
                                    this.props.paginationHide == false && 
                                <div className="pull-right pl-2">
                                    <label className="pull-left sub-text">{t('show_entries')}</label>
                                    <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv p-rel" >
                                        <Form.Control as="select" className="showentry_sel custom_selectBox" id="cpshowEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                                            <option>10</option>
                                            <option>20</option>
                                            <option selected="selected">30</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                {
                                    (isModel) ?
                                        <div className=' pull-right'>
                                            <select className="browser-default custom-select modelSelect" id="modelList" onChange={this.props.modelOnChange.bind(this)} value={this.props.selectedModel}>
                                                <option value="">{t('none')}</option>
                                                {modelListRow}
                                            </select>
                                            <label className='modelLabel'>{t('select_model')}</label>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='cpbody cpbodym'>
                        <MDBCol md="12" className="chargepoint_listdiv">
                            <ul>{getChargePoints}</ul>
                        </MDBCol>
                    </div>
                    <div className='modelFooter cpFooter mt-2 cpFooterb'>
                        <div className='row'>
                            <div className="col-md-4 mt-1 mb-1 text-right">
                                {
                                    this.props.paginationHide == false && 
                                <Pagination
                                    activePage={this.props.cpactivePage}
                                    itemsCountPerPage={this.props.pageSize}
                                    totalItemsCount={this.props.noOfRecords}
                                    onChange={this.props.handlePageChange.bind(this)}
                                    prevPageText={t('prev')}
                                    nextPageText={t('next')}
                                    itemClassPrev="prevBtn"
                                    pageRangeDisplayed="1"
                                    activeClass="activeLi active"
                                    itemClassNext="nextBtn"
                                    disableInitialCallback={true}
                                    disabledClass="disabled disabledtextColor"
                                    hideFirstLastPages={true}
                                />
                                }
                            </div>
                            <div className='col-md-8 text-right'>
                                <button className="btn_cancel mr-2 ml-2" onClick={this.props.cancelFunction} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
                                <button className="btn_primary mr-4 ml-2" onClick={this.props.doneFunction} type="button" data-test="button">{t('done')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cpRight" id="cpRightSide" style={{ display: displayDiv }}>
                    <div className="cprightTitle">
                        {t('selected_charge_point')}
                    </div>
                    <div id='idStatus' className='p-3 cpmodelRightSide'>
                        {
                            this.props.cpList?.map((cpId, index) => {
                                return (
                                    <span class='cpChargePointSpan' id={`chargepoint_span ${this.props.cpList[index]}`}>
                                        {this.props.cpList[index]} <i class='fa fa-times' id={this.props.cpList[index]} onClick={this.props.removeCpId.bind(this, this.props.cpList[index])}></i>
                                    </span>
                                );
                            })
                        }
                    </div>
                </div>
            </MDBModal>
        )
    }
}

export default withTranslation()(CsOperationsModal);