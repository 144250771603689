import React from 'react';
import { MDBDataTable, MDBIcon, Select, MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter,MDBTabContent, MDBNav, MDBBtn ,MDBNavItem, MDBNavLink, MDBTabPane } from 'mdbreact';
import { Form, Table, Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import $ from "jquery";
import Header from './header';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { hasRole, hasPermission } from './auth';
import {dateformatinUTC,formatUTC,formatUTCDateandTime} from './_methods';
import { withTranslation } from 'react-i18next';


// import Pagination from "react-js-pagination";
import { Alert } from 'reactstrap';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();

 class builds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      buildStatus: "Active",
      popupStatus: "",
      build_id: "",
      startDate: new Date(),
      dataArray: [],
      page_title: "Builds",
      products: [],
      buildTypes: [],
      pageSize: "10",
      activePage: 1,
      sucess:false,
      colour:'sucess',
      data:new Map(),
      rollOutBuildId:"",
      noOfRecords: '',
      errors:{},
      message: "",
      modal3: false,
      modalw:false,
      addmodal:false,
      rollOutData:[],
      rollOutVersionNo:"",
      rollOutModelNo:"",
      validToDate:"",
      rolloutDate:"",
      activeItem:"1",
      user: { permissions: [] }
    }

    this.addBuild = this.addBuild.bind(this);
    this.backToList = this.backToList.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleArchive = this.toggleArchive.bind(this);
    this.toggleRollout = this.toggleRollout.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.AddArchive = this.AddArchive.bind(this);
    this.getBuildStatus = this.getBuildStatus.bind(this);
    this.onChangeBuildSatus = this.onChangeBuildSatus.bind(this);
    this.editBuild = this.editBuild.bind(this);
    this.editBuild = this.editBuild.bind(this);
    this.CloseArchive = this.CloseArchive.bind(this);
    this.setValidToDate = this.setValidToDate.bind(this)
    this.SaveFWRollOut = this.SaveFWRollOut.bind(this)
    this.tabToggle = this.tabToggle.bind(this)
    this.toggleRollout = this.toggleRollout.bind(this)
    this.cancelRollout = this.cancelRollout.bind(this)
    this.addtoggle = this.addtoggle.bind(this)
  }

  addtoggle (){
    this.setState({
      addmodal:!this.state.addmodal
    })
  }

  tabToggle(tab, event) {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  }
 
  //This function for setting the date to state
setValidToDate(date){
  let errors = {};
  var dateNew = formatUTCDateandTime(date)
  this.setState({ 
    validToDate:date,
    rolloutDate:dateNew,
    errors:errors
   })
}

  //change function for build status filter
  onChangeBuildSatus() {    
    var Status = document.getElementById('buildStatusId').value;
    this.setState({ buildStatus: Status, activePage:1}, () => {
      this.getBuildStatus(this.state.pageSize, 1);
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleRolloutWarning = () => {
    let error = {};
    if(this.state.validToDate =="" || this.state.validToDate == undefined ||  this.state.validToDate == null ){
      error['emptyscheduleDate'] = this.props.t('enter_date_and_time')
      this.setState({
        errors:error
      });
    }else{
      error['emptyscheduleDate'] = "";
      this.setState({
        modalw:!this.state.modalw,
        errors:error
      });
    }
   
  }

  backToList() {
    this.props.history.push('/productList')
  }
  editBuild() {
    
    this.props.history.push('/editBuild')
  }

  //toggle function for archive popup  
  toggleArchive = (build_type_id, text) => {
    this.setState({
      modal1: !this.state.modal1,
      build_id: build_type_id,
      popupStatus: text
    });
  }


 

  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        // this.getWallBox(pageNumber,this.state.pageSize);
        this.getBuilds(this.state.pageSize, pageNumber);
        this.getBuildStatus(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }
  getBuilds(pageSize, activePage) {
    var productId = document.getElementById("productId").value;
    //  var modal=document.getElementById("productId").value;
    // if(buildType==""){
    //   // buildType=0
    // }
    // if(productId!=""){
    var url = baseUrl.FOTAPath + "dashboard/builds?pageNo=" + activePage + "&pageSize=" + pageSize + "&status=" + this.state.buildStatus + "&model=" + productId;

    //devcpms.evprowebasto.com/cpms/api/dashboard/builds?pageNo=1&pageSize=10&status=all&model=OCPP
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        
        if (resp.status == 401) {
          this.toggle()
          // alert("Access token is expired, please login again")
          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {

          // alert(JSON.stringify(response));
          this.setState({
            dataArray: response.data,
            noOfRecords: response.count.count
          });
        }      // alert("data"+JSON.stringify(this.state.data));
        // this.setState({ total_charge_point:response.data.chargePointCount })

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    // }
  }


  getProducts(e) {
    // var orgId=document.getElementById("organization").value;

    // this.setState({orgId:orgId})
    

    var url = baseUrl.FOTAPath + "cpinventory/evmodel/";

    // else{
    //   var url = baseUrl.FOTAPath + "cpinventory/evmodel/organizations/"+{orgId};
    //   alert(url)
    // }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        
        if (resp.status == 401) {
          this.toggle();
          // alert("Access token is expired, please login again")
          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {

          // alert(JSON.stringify(response));
          this.setState({ products: response });
        }
        // alert("data"+JSON.stringify(this.state.data));
        // this.setState({ total_charge_point:response.data.chargePointCount })

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }




  componentDidMount() {
    this.state.user.permissions = localStorage.getItem("roleAccess");
	var rolloutEnableStatus = localStorage.getItem("isRollOutEnabled");
	//This condition is used to enable and disable the rollout functionality
	 if (rolloutEnableStatus == true || rolloutEnableStatus == "true") {
        this.state.isRollOutEnabled = "inline-block";
     } else {
        this.state.isRollOutEnabled = "none";
     }
    this.getProducts();
    this.getBuilds(this.state.pageSize, this.state.activePage);
  }

  CloseArchive = () => {
    this.setState({
      modal1: !this.state.modal1
    });
  }

  onShowAlert() {
    

    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });

  }


  tagOk() {
    history.push('/');
    window.location.reload();
  }
  // function to add in archive list 
  AddArchive() {
    
    this.setState({
      modal1: !this.state.modal1
    });
    var buildId = this.state.build_id;
    var isArchive_val = "";
    if (this.state.popupStatus == "Active") {
      isArchive_val = false;
    } else {
      isArchive_val = true;
    }

    var url = baseUrl.FOTAPath + servicesUrl.updateDashboardBuildArchive+ "?buildId=" + buildId + "&isArchive=" + isArchive_val;
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        
        if (resp.status == 401) {
          this.toggle()
          // alert("Access token is expired, please login again")
          localStorage.clear();

        }
        if (resp.status == 412) {
          this.setState({ modal2: false })
          this.setState({ message: this.props.t('failed_msg'), colour: 'danger' })
          this.onShowAlert();
          return resp.json();
        }
        if (resp.status == 200) {
          this.setState({ modal2: false })
          this.setState({ message: this.props.t('build_added') + this.state.popupStatus.toLowerCase() + " sucessfully.", colour: 'success' })
          this.onShowAlert();
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.getBuildStatus(this.state.pageSize, this.state.activePage);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getBuildStatus(pageSize, activePage) {
    var fds = this.props.history.state;
    var productId = "";
    var url = "";
    
    var productId = document.getElementById("productId").value;
    // var buildType=document.getElementById("buildTypeId").value;
    // if(buildType==""){
    //   // buildType = 0
    // }
    // if (productId == "") {
    //   // productId = 0
    // }
    // url = baseUrl.FOTAPath + "dashboard/builds/"+this.props.location.state+"&status="+this.state.buildStatus; - venu
    var url = baseUrl.FOTAPath + "dashboard/builds?pageNo=" + activePage + "&pageSize=" + pageSize + "&status=" + this.state.buildStatus + "&model=" + productId;

    this.setState({ productId: productId })
	
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.toggle()
          // alert("Access token is expired, please login again")
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          
          // alert(JSON.stringify(response));
          //this.setState({ dataArray: response.data,noOfRecords:response.count.count});
          this.state.noOfRecords = response.count.count;
          this.setState({
            dataArray: response.data,
            noOfRecords: response.count.count
          });

        }
        // alert("data"+JSON.stringify(this.state.data));
        // this.setState({ total_charge_point:response.data.chargePointCount })

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  addBuild() {
    
    this.props.history.push('/addBuild');
  }

  //function for sorting table data on click
  ascendingOrder() {
    this.setState({ dataArray: this.state.dataArray.reverse() })
  }


  cancelRollout (){
    this.setState({ 
      modal3: false,
      errors:""
     })
  }


  toggleRollout = (build_id, versionName, modelNo) => {
    
    // if(rolloutDate==null || rolloutDate==undefined || rolloutDate=="" )
    // {
    //   rolloutDate = new Date();
    //   utcDate     = moment(new Date()).format("M-D-YYYY HH:mm")
    // }else{
    //   rolloutDate = formatUTC(new Date(rolloutDate),true)
    //   utcDate =  moment(rolloutDate).utc().format("M-D-YYYY HH:mm");
    // }
    // rolloutDate:utcDate

    var rolloutDate = "";
    var utcDate     = moment(new Date()).format("M-D-YYYY HH:mm");
    this.setState({
      modal3: !this.state.modal3,
      rollOutBuildId:build_id,
      rollOutVersionNo:versionName,
      rollOutModelNo:modelNo,
      activeItem:"1",
      rollOutData:[],
      validToDate:rolloutDate,
      rolloutDate:utcDate
    }, () => {
      this.getRolloutTime();
    });


  }


  // Save FW update rollout

  SaveFWRollOut(){
    let errors = {}
    this.setState({
      modalw: !this.state.modalw
    });

    var url = baseUrl.FOTAPath +servicesUrl.updateDashboardBuildRollout + "?buildId=" + this.state.rollOutBuildId ;
    let data = {"rollOutDate": this.state.rolloutDate}; 

    if(this.state.rolloutDate == "" || this.state.rolloutDate==undefined || this.state.rolloutDate == null){
      errors['emptyscheduleDate'] = "Please enter rollout date"
    }else{
      fetch(url, {
        method: "PUT",
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
          
          if (resp.status == 401) {
            this.toggle()
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
          if (resp.status == 404) {
            errors['emptyscheduleDate'] = this.props.t('build_not_exist')
            return resp.json();
          }
          if (resp.status == 400) {
            return resp.json();
          }
          if (resp.status == 200) {
            return resp.json();
          }
		  if (resp.status == 503) {
			  errors['emptyscheduleDate'] = this.props.t('rollout_service_not_available')
            this.setState({
              errors:errors
            })
          }
        })
        .then((response) => {
          if(response.status==400){
            errors['emptyscheduleDate'] = this.props.t('rollout_date_not_less_current_date')
            this.setState({
              errors:errors
            })
          }else if(response.status==404){
            errors['emptyscheduleDate'] = this.props.t('build_not_exist')
              this.setState({
              errors:errors
            })
          }else if (response != undefined && response.id != ""){
            this.setState({ 
              modal3: false })
              this.getBuilds(this.state.pageSize, this.state.activePage);
              this.addtoggle();
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    }

    this.setState({
      errors:errors
    })
   
  }



  getRolloutTime(){
    var url = baseUrl.FOTAPath + servicesUrl.getDashboardBuildRolloutHistory + "?buildId=" + this.state.rollOutBuildId;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.toggle()
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({
            rollOutData:response,
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }



  render() {
    const{t}=this.props
    let user = this.state.user;

    const products = this.state.products.map((products, index) => {
      return (
        <>
          {
            (products.trim() != 0 || products.trim() != "") ? <option value={products}>{products}</option> : null
          }
        </>
      )
    })


    const buildTypes = this.state.buildTypes.map((dataArray, index) => {
      return (
        <option value={dataArray.id}>{dataArray.name}</option>
      )

    })


        const rolloutRows =  this.state.rollOutData.map((dataArray, index) => {

          let createdDate = "";
          let createdTime = "";
          let rollOutDateDate ="";
          let rollOutDateTime = "";


          if (dataArray.createdTime == null || dataArray.createdTime == "") {
             createdDate = "";
             createdTime = "";
          } else {
             createdDate = moment(dataArray.createdTime).format("DD MMM YYYY");
             createdTime = moment(dataArray.createdTime).format("HH:mm");
          }

          if (dataArray.rollOutDate == null || dataArray.rollOutDate == "") {
            rollOutDateDate = "";
            rollOutDateTime = "";
         } else {
              rollOutDateDate = moment(dataArray.rollOutDate).format("DD MMM YYYY");
              rollOutDateTime = moment(dataArray.rollOutDate).format("HH:mm");
            }
    
          return (
           <tr>
             <td className="wrap-word">{dataArray.createdTime && <><div className="nowrap pull-left pb-0 pr-2 "><i className="fas fa-calendar-alt fa-sm "></i> {createdDate}</div><div className="timespan pull-left pb-0 "><i className="far fa-clock fa-sm pt-1"></i> {createdTime} </div> </>}</td>
             <td className="wrap-word">{dataArray.rollOutDate && <><div className="nowrap pull-left pb-0 pr-2 "><i className="fas fa-calendar-alt fa-sm "></i> {rollOutDateDate}</div><div className="timespan pull-left pb-0 "><i className="far fa-clock fa-sm pt-1"></i> {rollOutDateTime} </div> </>}</td>
             <td className="wrap-word">{dataArray.status}</td>
           </tr>

          )

        })
                      


    const data = this.state.dataArray.map((dataArray, index) => {

      //alert(JSON.stringify(dataArray));
      var trsactionData = {
        date: this.state.startDate,
      }

      var archiveicon = "";
      var unarchiveicon = "";
      if (dataArray.isArchive == false) {
        archiveicon = "inline-block";
        unarchiveicon = "none";
      } else {
        archiveicon = "none";
        unarchiveicon = "inline-block";
      }

      if (dataArray.created_time == null || dataArray.created_time == "") {
        var date = "";
        var date1 = "";
      } else {
        var date = moment(dataArray.created_time).format("DD MMM YYYY");
        var date1 = moment(dataArray.created_time).format("HH:mm");
      }

      if (dataArray.modified_time == null || dataArray.modified_time == "") {
        var modified_date = "";
        var modified_date1 = "";
      } else {
        var modified_date = moment(dataArray.modified_time).format("DD MMM YYYY");
        var modified_date1 = moment(dataArray.modified_time).format("HH:mm");
      }

      return (
        <tr key={index}>
          <td>{dataArray.version_name}</td>
          <td>{dataArray.version_code}</td>
          <td>{dataArray.model}</td>
          <td className="wrap-word">{date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1} </div> </>}</td>
          <td className="wrap-word">{modified_date && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {modified_date}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {modified_date1} </div> </>}</td>
          <td>{dataArray.prerequisite}</td>
          <td>
            <a href={dataArray.download_url} download> <i className="fas fa-download action-icon" title="Download Firmware"></i></a>
            <a href={dataArray.releaseNotes_URL} download ><i className="fas fa-sticky-note pl-2 pr-2 action-icon" title="Release Notes"></i></a>
            {hasPermission(user, ['builds_W']) &&
              <Link to={{
                pathname: '/editBuild',
                state: dataArray.id,
              }} download style={{ display: archiveicon }}><i className="fas fa-pencil-alt pr-2 action-icon" title="Edit"></i></Link>
            }
            {hasPermission(user, ['builds_U']) && <i className="fas fa-pencil-alt pr-2 action-icon edit_archive_icon" style={{ display: unarchiveicon }} title="Edit"></i>}
            {hasPermission(user, ['builds_U']) && <i className="fas fa-archive action-icon cursor_defult" style={{ display: archiveicon }} title="Archive" onClick={this.toggleArchive.bind(this, dataArray.id, "Archive")}></i>}
            {hasPermission(user, ['builds_U']) && <label onClick={this.toggleArchive.bind(this, dataArray.id, "Active")} className="unarchive_icon mb-0 cursor_defult" title="Active" style={{ display: unarchiveicon }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.55 5.22l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.15.55L3.46 5.22C3.17 5.57 3 6.01 3 6.5V19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.49-.17-.93-.45-1.28zM12 9.5l5.5 5.5H14v2h-4v-2H6.5L12 9.5zM5.12 5l.82-1h12l.93 1H5.12z" /></svg></label>}
            {hasPermission(user, ['PublishNotification_R']) &&
              <Link to={{
                pathname: '/buildOrganization',
                state: { build: dataArray.version_name, model: dataArray.model }
              }}>
                <i className="fas fa-envelope action-icon cursor_defult pl-2" title="Notification"></i>
              </Link>
            }
            {hasPermission(user, ['builds_U']) && <i className="fas fa-clock-o action-icon cursor_defult pl-2" style={{ display: this.state.isRollOutEnabled }} onClick={this.toggleRollout.bind(this, dataArray.id, dataArray.version_name, dataArray.model, dataArray.rollOutDate)} title="Rollout"></i>}
          </td>
        </tr>
      )
    })
    return (
      <>
        <main className="content-div" >
          <p> {t('builds')}
            <div className="breadcrumb_div">
              {t('fota')} &gt; <span className="breadcrumb_page">{t('builds')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row menubar">
              <div className="col-md-12 ">
                <div>
                  {hasPermission(user, ['builds_W']) && <button><Link to={{ pathname: '/addBuild', state: this.state.productId, }} ><i className="fa fa-plus"></i> <span className="add-btn">{t('add')}</span></Link></button>}
                </div>
              </div>
              <div className=" col-md-3  pull-right mt-2">
                <select className="browser-default custom-select select_height mb_8" id="productId" onChange={this.onChangeBuildSatus}>
                  <option value="" >{t('select_model')}</option>
                  {products}
                </select>
              </div>
              <div className=" col-md-3  pull-right mt-2">
                <select className="browser-default custom-select select_height " id="buildStatusId" onChange={this.onChangeBuildSatus}>
                  <option value="">{t('select_status')}</option>
                  <option value="All">{t('all')}</option>
                  <option value="Active">{t('active')}</option>
                  <option value="Archive">{t('archive')}</option>
                </select>
              </div>
            </div>
            <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
            <div className="row mt-1 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>{t('version')}</th>
                      <th>{t('version_code')}</th>
                      <th>{t('model')}</th>
                      <th>{t('release_date')} <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('modified_date')} <i className="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('prerequisite')}</th>
                      <th>{t('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>
        </main>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* confirmation message popup for archive  */}
        <MDBModal isOpen={this.state.modal1} toggle={this.toggleArchive} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleArchive}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('do_you_want_to')} {this.state.popupStatus.toLowerCase()} {t('this_build')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.CloseArchive}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.AddArchive}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* rollout popup */}
        <MDBModal isOpen={this.state.modal3} toggle={this.toggleRollout} size="md" className="model_top rolloutmodel">
          <MDBModalHeader toggle={this.toggleRollout}>
            <div className="modal-title fw-800 mt-1 "> <span className="fs-16 pl-2">{t('rollout_fw_update')} </span></div>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="rolloutDiv pagerollout">
              <div className="mb-2">
              <MDBNav className=" mt-1 p-rel border-0">
              <MDBNavItem className="nav-item nav-first">
                    <MDBNavLink
                      to="#"
                      className={`b-75 ${
                        this.state.activeItem == "1" ? "activetab" : ""
                      }`}
                      onClick={this.tabToggle.bind(this, "1")}
                      role="tab"
                    >
                      {t('rollout_time')}
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem className="nav-item nav-last">
                    <MDBNavLink
                      to="#"
                      className={`b-75  ${
                        this.state.activeItem == "2" ? "activetab" : ""
                      }`}
                      onClick={this.tabToggle.bind(this, "2")}
                      role="tab"
                    >
                      {t('history')}
                    </MDBNavLink>
                </MDBNavItem>
                </MDBNav>
              </div>
              <MDBTabContent
                activeItem={this.state.activeItem}
                className=" p-rel border-0 p-0"
              >
              <MDBTabPane tabId="1" role="tabpanel">
              <div className="w-100 mt-3 ">
                <label className="pl-3"> {t('model')}: </label> <label>{this.state.rollOutModelNo}</label>
              </div>
              <div className="w-100">
                <label className="pl-3"> {t('fw_version')}: </label> <label>{this.state.rollOutVersionNo}</label>
              </div>
              <div className="w-100 ">
                <label className="pl-3">{t('rollout_time')}:</label>
                  <div style={{ position: "relative" , width: "68%", display: "inline-block"}} >
                    <div className="ml-1" >
                      <DatePicker
                        selected={this.state.validToDate}
                        onChange={this.setValidToDate}
                        showTimeSelect
                        placeholderText="Schedule date and time"
                        timeFormat="HH:mm"
                        timeIntervals={1}
                        className="cal_margin ml-0 rolloutCal"
                        timeCaption="time"
                        dateFormat="dd-MMM-yyyy HH:mm"
                        minDate={new Date()}
                      />
                      <div className="input-group-btn datepickerIcon"><i className="fas fa-calendar-alt fa-sm"></i></div>
                    </div>
                    <span className="error_msg w3-animate-top cal_errormsg"> <span style={{ color: "red" }} >{this.state.errors["emptyscheduleDate"]}</span></span>
                  </div>
              </div>
              </MDBTabPane>
              <MDBTabPane tabId="2" role="tabpanel">
                  <div className="row">
                  <div className="col-md-12 historyRoll">
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>{t('created_date')}</th>
                      <th>{t('rollout_date')}</th>
                      <th>{t('chargingSessionState')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolloutRows}
                  </tbody>
                </Table>
              </div>
                  </div>
              </MDBTabPane>
              </MDBTabContent>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.cancelRollout}>{t('cancel')} <i className="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.toggleRolloutWarning}>{t('save')}  <i className="fas fa-save ml-2"></i></button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.modalw} toggle={this.toggleRolloutWarning} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleRolloutWarning}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
              {t('sure_to_rollout_firmware_charging_station_for_model')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.toggleRolloutWarning}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.SaveFWRollOut}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.addmodal} toggle={this.addtoggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.addtoggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('rollout_fw_update_sucesfuly')}
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" data-test="button" className="btn_primary" onClick={this.addtoggle}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(builds);