import React from 'react';
import { MDBDataTable, MDBRow, MDBIcon, Select, MDBInput, MDBCol, MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table, Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import $, { get } from "jquery";
import Header from './header';
import { Alert } from 'reactstrap';
import { createBrowserHistory } from 'history'
import Pagination from "react-js-pagination";
import { hasRole, hasPermission } from './auth';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import 'moment-timezone';
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';



const history = createBrowserHistory();


class inventoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onSelectFindbtnStatus : false,
   
      onClickCheckBox : false,
      cpIdSorting: false,
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      selectFindStatus: false,
      saveSelectedCpid: "",
      inventoryDisplay: "",
      enableInventory: "true",
      saveSno: "",
      timeoutdisplay: "none",
      timeoutdisplayno: "none",
      buttonacessdisplay: "none",
      e_mail: [],
      commercial_Users: [],
      items: [],
      cp_id: [],
      intial_data: [],
      getList: [],
      options: [],
      multiple: false,
      hideActionBtn: "none", // show hide btn varible based on permission 
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      model: false,
      startDate: new Date(),
      pageNo: 1,
      list: [],
      list2: [],
      activeArray2: [],
      timeOutArray: [],
      selectDropdown: "none",
      modalView: false,
      modalInventory: false,

      dataArray: [

      ],
      orgArray: [],
      modal2: false,
      page_title: "Inventory",
      org_name: "",
      orgName: "",
      userName: "",
      fields: {
        materialNo:"",
        mesSerialNo:"",
        modelName:"",
        productName: "",
        description: "",
        buildType: "",
        productCode: "",
        serialnumber: "",
        siteAdmin: "",
        commercialUser: ""
      },
      orgId: "",
      sucess: false,
      colour: 'sucess',
      message: "",
      errors: {},
      noOfRecords: '',
      activePage: 1,
      data: new Map(),
      pageSize: "10",
      identifier: "",
      modalsession: false,
      activeArray: [],
      buttondisplay: "",
      reassigndisplay: "none",
      modal3: false,
      modalAdmin: false,
      user: {
        permissions: [

        ],
      }



    }

    this.getInventoryBasedOnorg = this.getInventoryBasedOnorg.bind(this);
    // this.onShowAlert=this.onShowAlert.bind(this);
    // this.sessiontoggle = this.sessiontoggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    // this.getPaginationcount = this.getPaginationcount.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.bulkImport = this.bulkImport.bind(this);
    this.getInventorybasedonchargepoint = this.getInventorybasedonchargepoint.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.saveChargePoint = this.saveChargePoint.bind(this);
    this.reAssignChargePoint = this.reAssignChargePoint.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.admintoggle = this.admintoggle.bind(this);
    this.warningModelOk = this.warningModelOk.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleadminsuccess = this.toggleadminsuccess.bind(this);
    this.tagOk1 = this.tagOk1.bind(this);
    this.releaseButton = this.releaseButton.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.deleteSucesstoggle = this.deleteSucesstoggle.bind(this);
    this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.selectedCpChange = this.selectedCpChange.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.onBlurEvent = this.onBlurEvent.bind(this);
    this.ClearCpName = this.ClearCpName.bind(this);
    this.ClearCpNames = this.ClearCpNames.bind(this);
    this.confirmtoggle = this.confirmtoggle.bind(this);
    this.releasetoggle = this.releasetoggle.bind(this);
    this.deletetoggle = this.deletetoggle.bind(this);
    this.getSiteAdminList = this.getSiteAdminList.bind(this);
    this.siteAdminChange = this.siteAdminChange.bind(this);
    this.assignSiteAdmin = this.assignSiteAdmin.bind(this);
    this.siteadmintoggle = this.siteadmintoggle.bind(this);
    this.getCommercialUsers = this.getCommercialUsers.bind(this);
    this.commercialUserChange = this.commercialUserChange.bind(this);
    this.assignCommercialUsers = this.assignCommercialUsers.bind(this);
    this.commercialusertoggle = this.commercialusertoggle.bind(this);

    this.deleteInventory = this.deleteInventory.bind(this);
    this.displayAccessCode = this.displayAccessCode.bind(this);
    this.exportInventoryCSV = this.exportInventoryCSV.bind(this);
    this.getTimeOutHinder = this.getTimeOutHinder.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.getReleaseData = this.getReleaseData.bind(this);
    this.toggleAuthentication = this.toggleAuthentication.bind(this);
    this.CloseSuccessauthentication = this.CloseSuccessauthentication.bind(this);
    this.toggleView1 = this.toggleView1.bind(this);
    this.deletetoggle1 = this.deletetoggle1.bind(this);
    this.releaseWarningtoggle = this.releaseWarningtoggle.bind(this);
    this.changeModelName = this.changeModelName.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.onChangeMesSno = this.onChangeMesSno.bind(this);
    this.onChangeModeName = this.onChangeModeName.bind(this);
    this.onChangeMatno = this.onChangeMatno.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
  }
   //change function for mes status filter
   onChangeMesSno(serialNumber) {


    var mes = document.getElementById("mesSerialNo").value;

    this.setState({ mesSerialNo: mes }, () => {
      // this.state.fields.materialNo = "";
      // this.state.fields.modelName = ""
      this.getInventoryBasedOnorg(10,1);
    });
    if(this.state.fields.mesSerialNo == ""){
      this.state.errors["mesSnoEmpty"] = this.props.t('enter_mes_serial_no');
    
    }
    else{
      this.state.errors["mesSnoEmpty"] = "";
    
      if (this.state.list2 != "") {
        this.state.list2=[]
     
   
        this.setState({
          activeArray2:this.state.list2
       
        });
     
      }
      $("input[type='checkbox']").prop("checked", false);
     
    }
  }
  onChangeMatno() {
 


    
    var matNo = document.getElementById("materialNo").value;
  
    this.setState({ materialNo: matNo }, () => {
      // this.state.fields.modelName = "";
      // this.state.fields.mesSerialNo = ""
      this.getInventoryBasedOnorg(this.state.pageSize,1);
    });
    if(this.state.fields.materialNo == ""){
      this.state.errors["matnoEmpty"] = this.props.t('enter_material_no');
     
    }
    else{
      this.state.errors["matnoEmpty"] = "";
   
      if (this.state.list2 != "") {
        this.state.list2=[]
    
    
        this.setState({
          activeArray2:this.state.list2
       
        });
    }
    $("input[type='checkbox']").prop("checked", false);
  
      
    }
  }
  onChangeModeName() {
    
    let errors = {};
  
     
    var model = document.getElementById("modelName").value;
    this.setState({ modelName: model }, () => {
      // this.state.fields.materialNo = "";
      // this.state.fields.mesSerialNo = ""
      this.getInventoryBasedOnorg(this.state.pageSize,1);
     
    });
    if(this.state.fields.modelName == ""){
      this.state.errors["modelEmpty"] = this.props.t('enter_model_name');
     
    }
    else{
      this.state.errors["modelEmpty"] = "";
    
      if (this.state.list2 != "") {

        this.state.list2=[]
    
    
        this.setState({
          activeArray2:this.state.list2
       
        });
        
    }
  
 $("input[type='checkbox']").prop("checked", false);
     
     
   
  
  }
}
  keyPress(e){
    var key = e.key;
    if(e.keyCode == 13){
        this.setState({ mesSerialNo: e.target.value });
        // this.state.fields.materialNo = "";
        // this.state.fields.modelName = ""
        this.getInventoryBasedOnorg(this.state.pageSize,1);
        this.state.activeArray2=[];
        $("input[type='checkbox']").attr('value', 'false');
      }
     if(key == "Delete" || key == "Backspace"){
       if(this.state.fields.mesSerialNo.length <= 1){
         this.state.fields.mesSerialNo = "";
        this.getInventoryBasedOnorg(this.state.pageSize,1);
       }
       else{
         if(key == "Delete" ){
         this.state.fields.mesSerialNo = "";
         this.getInventoryBasedOnorg(this.state.pageSize,1);
        }

       }
       

      }
      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
        break;
        default:
          var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
        
            e.preventDefault();
    
            return false;
         
           
        }
        break;
      
    }
    if(this.state.fields.mesSerialNo ==0){
      this.state.errors["mesSnoEmpty"] = " ";
    
    }
    
  }
  keyPress1(e){

    var key1 = e.key;

      if(e.keyCode == 13){
        this.setState({ modelName: e.target.value });
        // this.state.fields.materialNo = "";
        // this.state.fields.mesSerialNo = ""
        this.getInventoryBasedOnorg(this.state.pageSize,1);
        this.state.activeArray2=[];
        $("input[type='checkbox']").attr('value', 'false');
      }
   if(key1 == "Delete" || key1 == "Backspace"){
    if(this.state.fields.modelName.length <= 1){
      this.state.fields.modelName = "";
     this.getInventoryBasedOnorg(this.state.pageSize,1);
    }
    else{
      if(key1 == "Delete" ){
      this.state.fields.modelName = "";
      this.getInventoryBasedOnorg(this.state.pageSize,1);
     }

    }
      }
      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
        break;
        default:
          var regex = new RegExp("^[a-zA-Z0-9., -_]");
        var key = e.key;
        if (!regex.test(key)) {
        
            e.preventDefault();
    
            return false;
         
           
        }
        break;
      
    }
    if(this.state.fields.modelName == 0){
      this.state.errors["modelEmpty"] = " ";
     
    }
  }
  keyPress2(e){

    var key2 = e.key;
if(e.keyCode == 13){
        this.setState({ materialNo: e.target.value });
        // this.state.fields.modelName = "";
        // this.state.fields.mesSerialNo = ""
        this.getInventoryBasedOnorg(this.state.pageSize,1);
        this.state.activeArray2=[];
        $("input[type='checkbox']").attr('value', 'false');
      }
  
    if(key2 == "Delete" || key2 == "Backspace"){
      if(this.state.fields.materialNo.length <= 1){
        this.state.fields.materialNo = "";
       this.getInventoryBasedOnorg(this.state.pageSize,1);
      }
      else{
        if(key2 == "Delete" ){
        this.state.fields.materialNo = "";
        this.getInventoryBasedOnorg(this.state.pageSize,1);
       }
  
      }      }
      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
        break;
        default:
          var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
        
            e.preventDefault();
    
            return false;
         
           
        }
        break;
      
    }
    if(this.state.fields.materialNo == 0){
      this.state.errors["matnoEmpty"] = " ";
     
    }
  
  }
 
  


  releaseWarningtoggle() {
    this.setState({
      releaseWarning: !this.state.releaseWarning
    });
  }

  deletetoggle1(event) {
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
    // event.preventDefault();
    // let getcheckBoxStatus = localStorage.getItem("checkBoxStatus")
    if (this.state.activeArray2.length > 0) {



      this.setState({
        delete_msg: this.props.t('delete_confirmation'),
        deleteHeader: this.props.t('confirmation'),
        deleteButton: "",
        deleteText: this.props.t('close'),
        deletecolor: "danger"
      })
    } else {

      this.setState({
        delete_msg: this.props.t('select_the_charge_point'),
        deleteHeader: this.props.t('warning'),
        deleteButton: "d-none",
        deleteText: this.props.t('ok'),
        deletecolor: "primary"
      })
    }

  }
  toggleView1() {
    this.setState({
      modalView: !this.state.modalView
    });
  }
  CloseSuccessauthentication() {
    this.setState({
      modalSuccessauthentication: !this.state.modalSuccessauthentication
    });
    window.location.reload();

  }
  toggleAuthentication() {
    this.setState({
      modalSuccessauthentication: !this.state.modalSuccessauthentication
    });
  }
  toggleView(no_of_attempts, timeoutMinutes, serialNumber) {
    this.state.saveSno = serialNumber
    this.setState({ displayReq: no_of_attempts })
    this.setState({ displayRes: timeoutMinutes })
    this.setState({
      modalView: !this.state.modalView
    });
  }

  displayAccessCode(accessCode, id, index, status) {


    const newFile = this.state.orgArray.map((orgArray) => {
      return { ...orgArray, status: false };
    });

    newFile[index].status = true;
    this.setState({ orgArray: newFile });
    this.setState({ displayAccesCode: accessCode });
  }



  siteAdminChange() {
    var x = document.getElementById("siteAdmin").value;
    let fields = this.state.fields;
    fields["siteAdmin"] = x;
    this.setState({ fields });
  }

  commercialUserChange() {
    var x = document.getElementById("commercialUser").value;
    let fields = this.state.fields;
    fields["commercialUser"] = x;
    this.setState({ fields });
  }


  confirmtoggle(event) {
    event.preventDefault();
    var org = "";
  
    if (this.state.orgName == "") {
      org = document.getElementById("organization").value;
      this.setState({ orgName: org });
    }
    else {
      org = this.state.orgName;
    }
    if (this.state.activeArray != "" && org != "") {
      this.setState({
        confirmmodal: !this.state.confirmmodal
      });
    } else {
      this.toggle3();
    }
  }



  siteadmintoggle(event) {
    event.preventDefault();
    var userId = this.state.fields.siteAdmin;
    this.setState({ userName: userId });
    if (this.state.activeArray2 != "" && userId != "") {
      this.setState({
        siteadminmodal: !this.state.siteadminmodal
      });
    } else {
      this.admintoggle();
    }
  }

  commercialusertoggle(event) {
    event.preventDefault();

    this.setState({
      commercialusermodal: !this.state.commercialusermodal
    });
  }

  releasetoggle(event) {
    event.preventDefault();
  
    if (this.state.activeArray != "") {
      this.setState({
        releasemodal: !this.state.releasemodal
      });
    } else {
      this.setState({
        releaseWarning: !this.state.releaseWarning
      });
    }
  }

  deletetoggle(event) {
    event.preventDefault();
   
    this.setState({
      deletemodal: !this.state.deletemodal
    });
  }
  ClearCpNames(ref, event) {
    if(this.state.onClickCheckBox == true){
      
      this.handlePageChange(this.state.activePage);
     
      this.state.onClickCheckBox = false;
     }
     this.state.errors["entercharge"] = " ";
     $("#notexists").text(" ");
  }
  // method to clear search filter
  ClearCpName(ref, event) {

   
    this.state.activeArray2 = "";
    let errors = this.state.errors;
    errors["entercharge"] = "";
    $("#notexists").text("");
    this.state.saveSelectedCpid = "";
    this.state.selectFindStatus = false;
    // this.getPaginationcount();
    this.state.pageSize=10;
        this.state.activePage=1;
        document.getElementById("showEntries").value=10;
    this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    ref.current.clear();
    this.setState({
      options: [],
      errors: errors
    })
  }

  onBlurEvent(event) {
    var vendorName1 = event.target.value;
    this.setState({
      vendorName1: vendorName1
    })

    if (vendorName1.length == 0) {
      this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid)
      this.setState({
        orgArray: this.state.items,
      });
    }
    let errors = {};
    errors["chargePointNotExists"] = "";
    errors["selectChargepoint"] = "";

    this.setState({
      errors: errors
    });

  }

  FindCpName(ref, event) {
    this.state.activeArray2 = [];

    this.state.onSelectFindbtnStatus = true;
    let getcheckBoxStatus = localStorage.getItem("checkBoxStatus")
    if(getcheckBoxStatus == "true"){
   
    }
   this.state.list2 = [];
    this.state.saveSelectedCpid = ref.current.inputNode.value;
    this.getInventoryBasedOnorg(this.state.pageSize, 1, this.state.saveSelectedCpid);

    if (this.state.saveSelectedCpid == "") {
      this.state.errors["entercharge"] = this.props.t('select_the_charge_point');
      $("#notexists").text(" ");
    }
    else {
      this.state.errors["entercharge"] = " "
      this.getInventoryBasedOnorg(this.state.pageSize, 1,"click");
    }
  }

  // logic to get cpid based on search filter

  selectedCpChange(cpid) {
    this.state.saveSelectedCpid = cpid
    this.setState({ saveSelectedCpid: cpid })
   
    if (cpid.length == 0) {
      this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage)
      this.setState({
        orgArray: this.state.items,
      });
    } else {
   
      this.getInventorybasedonchargepoint(cpid);
    }
 
  }

  handleKeyDown(ref, e) {
   
    var serial_no = e.target.value;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
    var getList = this.state.intial_data;
    if(serial_no!=0){
      this.state.errors["selectChargepoint"] = "";
    }
    if( key == "Backspace" || key == "Delete" ){
      this.state.errors["selectChargepoint"] = "";
      $("#notexists").text("");
      var x=e.target.value
      var sno=x.slice(0,x.length-1)
      if(sno==""){
       
        this.state.saveSelectedCpid=sno;
  

        this.getInventoryBasedOnorg(this.state.pageSize, this.state.pageNo);
      }
    }
      var sno=serial_no.slice(0,serial_no.length-1)
      this.getSearchInventoryList(sno)


      if (e.keyCode == 13) {
        this.FindCpName(ref, e);
        $("#rbt_id").hide();
      }
      if ((e.keyCode == 86 && ctrl)) {
        this.setState({
          options: [' ']
        })
        this.getSearchInventoryList(serial_no)
      }
   
      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
        break;
        default:
          var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
        
            e.preventDefault();
    
            return false;
         
           
        }
        break;
      
    }
  
  }

  tagOk1() {
    window.location.reload();
  }

  toggle1() {
    this.setState({
      modal1: !this.state.modal1
    });
  }
  toggleadminsuccess() {
    this.setState({
      modaladminsuccess: !this.state.modaladminsuccess
    });
  }

  toggle2() {
    this.setState({
      modalr: !this.state.modalr
    });
  }
  deleteSucesstoggle() {
    this.setState({
      modalInventory: !this.state.modalInventory
    });
  }


  warningModelOk() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  warningMessage() {
    this.setState({
      modalAdmin: !this.state.modalAdmin
    });
  }

  toggle3() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  admintoggle() {
    this.setState({
      modalAdmin: !this.state.modalAdmin
    });
  }

  organizationChange(event) {
    var orgname = event.target.value;
    var x = document.getElementById("organization").value;

    if (orgname == "webasto") {
      this.state.buttondisplay = "block"
      this.state.reassigndisplay = "none"
    }
    else {
      this.state.buttondisplay = "none"
      this.state.reassigndisplay = "block"
    }
    this.state.identifier = x;
    this.setState({
      orgName: orgname
    })
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    // this.state.data.clear();
    // this.setState({ pageSize: entries });
    this.state.pageSize=entries;
    this.state.activePage=1;
    this.getInventoryBasedOnorg(entries, 1);
  }



  bulkImport() {
    this.props.history.push('/bulkCPImport')
  }
  handlePageChange(pageNumber) {

    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      var pageData = undefined;
      if (pageData == undefined) {

        var pageData = data.get(pageNumber);
        var pageData = undefined;
        if (pageData == undefined) {
          this.setState({ activePage: pageNumber });
          this.getInventoryBasedOnorg(this.state.pageSize, pageNumber);

        } else {
          this.setState({ activePage: pageNumber });
          this.setState({ orgArray: pageData })
        }
      }
    }
  }


  async togglesession() {
   
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  componentDidMount() {
    // this.getCommercialUsers();
    this.state.user.permissions = localStorage.getItem("roleAccess");
    
    let user = this.state.user;
    
    // permission related code
    if (this.state.writePermission == true) {
      this.setState({
        hideActionBtn: "inline-block"
      })
    }
    
    // api for get all the organization 
    var orgname = localStorage.getItem("tenant");
    if (orgname == "webasto") {
      this.setState({
        selectDropdown: "block"
      })
    }
    else {
      this.setState({
        selectDropdown: "none"
      })
    }
    this.setState({
      org_name: orgname
    })
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
     
        if (response != undefined) {

          this.setState({ dataArray: response.organisationDTOS })
          var org_name = response.organisationDTOS;


          for (var i = 0; i < org_name.length; i++) {
            if (this.state.org_name.toLowerCase == org_name[i].company.toLowerCase) {

              this.setState({ identifier: org_name[i].identifier })
            }
          }
        }
        this.getInventoryBasedOnorg(this.state.pageSize, this.state.pageNo);
        // this.getPaginationcount();

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

      if(hasPermission(user, ['assignInventory_R'])){
        this.getSiteAdminList();
      }
  }

  getSiteAdminList() {

    var url = baseUrl.URLPath + "reassignment/users";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
      
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
     

        if (response != undefined) {
          this.setState({ e_mail: response })

        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  handleChange(event) {
    this.state.identifier = event.target.value;
    this.getInventoryBasedOnorg(this.state.pageSize, this.state.pageNo);
    // this.getPaginationcount();
  }

  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.chargePointid = document.getElementById('serialnumber')

    this.setState({ fields });
  }
  changeModelName(field, e){

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.modelnm = document.getElementById('modelName')
     this.setState({ fields });
  }
  changeMes(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.mes = document.getElementById('mesSerialNo')
     this.setState({ fields });
  }
  changeMaterialNo(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.materialN = document.getElementById('materialNo')
     this.setState({ fields });
  }

  async getInventoryBasedOnorg(pageSize, pageNo, action) {
   
    if (this.state.selectFindStatus == true) {
      var url = baseUrl.URLPath + "cpinventory/organization?pageNo=" + 1 + "&pageSize=" + pageSize + "&chargePointId=" + this.state.saveSelectedCpid+"&mesSerialNo="+this.state.fields.mesSerialNo+"&modelName="+this.state.fields.modelName+"&materialNo="+this.state.fields.materialNo;
    }
    else {
      var url = baseUrl.URLPath + "cpinventory/organization?pageNo=" + pageNo + "&pageSize=" + pageSize + "&chargePointId=" + this.state.saveSelectedCpid+"&mesSerialNo="+this.state.fields.mesSerialNo+"&modelName="+this.state.fields.modelName+"&materialNo="+this.state.fields.materialNo;
    }

    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
   
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
     
        if (response != undefined) {
          if(response.count.count==0){
            if(action=="click" || action=="enter"){ 
              $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
                              }
                              this.setState({
                                orgArray: [],
                                noOfRecords: 0,
                              
                              })
          }else{
          this.setState({
            orgArray: response.cpInventories,
            noOfRecords: response.count.count,
          
          })
          if(action=="click" || action=="enter"){ 
            $("#notexists").text(" ");
                            }
         
          var data = this.state.data;
          data.set(pageNo, response.count.count)

          if(this.state.onSelectFindbtnStatus == true){
            var getSno = this.state.orgArray[0].serialNumber;
          
          this.setState({saveFindSno :this.state.orgArray[0].serialNumber})
          }
        }
      }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  

  getInventorybasedonchargepoint(cpid) {
    var errors = this.state.errors;;
    if (cpid == null || cpid == "") {
      errors["selectChargepoint"] = this.props.t('select_the_charge_point');
      this.setState({
        errors: errors
      });
    }
    else {
      this.state.selectFindStatus = true;
      this.getInventoryBasedOnorg(this.state.pageSize, this.state.activePage, this.state.saveSelectedCpid);
    }
  }

  //  function after clicking on checkbox to stored cpid in an array 
  saveChargePoint(id, cpid, serialNumber,index,event) {
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue == true) {
      localStorage.setItem("checkBoxStatus",checkedValue)
      this.state.onClickCheckBox = true;
      this.state.list.push(cpid);
      this.setState({
        activeArray: this.state.list
      });
      this.state.list2.push(serialNumber);
      this.setState({
        activeArray2: this.state.list2
      });
    

    } else {
     localStorage.setItem("checkBoxStatus",checkedValue)
      const index = this.state.list.indexOf(cpid);
      if (index > -1) {
        this.state.list.splice(index, 1);
      }
      this.setState({
        activeArray: this.state.list
      });


      const index2 = this.state.list2.indexOf(serialNumber);
      if (index2 > -1) {
        this.state.list2.splice(index2, 1);
      }
      this.setState({
        activeArray2: this.state.list2
      });
      

    }

  }

  // function get called when click on re_assign button
  reAssignChargePoint() {
    var org = "";
    this.setState({
      confirmmodal: !this.state.confirmmodal
    });

    if (this.state.orgName == "") {
      org = document.getElementById("organization").value;
      this.setState({ orgName: org });

    }
    else {
      org = this.state.orgName;
    }
    if (this.state.activeArray != "" && org != "") {
      var url = baseUrl.FOTAPath + "cpinventory/reassign";
      let data = { "tenantId": org, "inventoryIds": this.state.activeArray };

      fetch(url, {
        method: "PUT",
        body: JSON.stringify(data), 
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
         
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            this.toggle1();
            return resp.json();
          }
        })
        .then((response) => {
      
          if (response != undefined) {

          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    } else {
      this.toggle3();
    }
  }
  // Assign to site admin users
  assignSiteAdmin() {
    var userId = "";
    this.setState({
      siteadminmodal: !this.state.siteadminmodal
    });

    if (this.state.userName == "") {
      userId = document.getElementById("siteAdmin").value;
      this.setState({ userName: userId });

    }
    else {
      userId = this.state.userName;
    }
    if (this.state.activeArray2 != "" && userId != "") {
      var url = baseUrl.FOTAPath + servicesUrl.updateReassignmentUsers + "?userId=" + userId;
      let data = { "chargePoints": this.state.activeArray2 };

      fetch(url, {
        method: "PUT",
        body: JSON.stringify(data), 
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
         
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            this.toggleadminsuccess();
            return resp.json();
          }
        })
        .then((response) => {
    
          if (response != undefined) {

          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    } else {
      this.admintoggle();
    }
  }

  // Assign to commercial users
  assignCommercialUsers() {
    var userId = "";
    this.setState({
      commercialusermodal: !this.state.commercialusermodal
    });

    if (this.state.userName == "") {
      userId = document.getElementById("commercialUser").value;
      this.setState({ userName: userId });

    }
    else {
      userId = this.state.userName;
    }
    if (this.state.activeArray2 != "" && userId != "") {
      var url = baseUrl.FOTAPath + "?userId=" + userId;
      
      let data = { "chargePoints": this.state.activeArray2 };

      fetch(url, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            this.toggleadminsuccess();
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {

          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    } else {
      this.admintoggle();
    }
  }

  // function get called when click on release button
  releaseButton() {
  
    var org = "webasto";
    this.setState({
      releasemodal: !this.state.releasemodal
    });

    if (this.state.activeArray != "" && org != "") {
      var url = baseUrl.FOTAPath + "cpinventory/reassign";
      let data = { "tenantId": org, "inventoryIds": this.state.activeArray };
      fetch(url, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
        
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            this.toggle2();
            return resp.json();
          }
        })
        .then((response) => {
        
          if (response != undefined) {
          }

        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    }

    else {
      this.toggle3();
    }
  }


  getSearchInventoryList(cpid) {
    
    var cp_id = [];
    var url = baseUrl.FOTAPath + "cpinventory?cpid=" + cpid;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
      
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
    
        if (response != undefined) {
          var option = [];
          var data = [];

          for (var i = 0; i < response.length; i++) {
            data.push(response[i].serialNumber);
          }

          this.setState({
            intial_data: option,
            options: data
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }


  getCommercialUsers() {

    var url = baseUrl.URLPath + "reassignment/users/commercial";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
    
        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
      

        if (response != undefined) {
          this.setState({ commercial_Users: response })

        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }



  // Delete Inventory
  deleteInventory() {
    
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
 
if(this.state.onSelectFindbtnStatus == true){
    var getCheckedSNo = this.state.saveFindSno
  var convertedSNO =  getCheckedSNo.split(',');
this.state.onSelectFindbtnStatus = false;
this.state.activeArray2 =  convertedSNO ;
  }
 if (this.state.activeArray2 != "") {
      var url = baseUrl.FOTAPath + "cpinventory";

      let data = { "chargePointIds": this.state.activeArray2 };
      fetch(url, {
        method: "DELETE",
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
         
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            this.deleteSucesstoggle();
            return resp.json();
          }
          if (resp.status == 404) {
            return resp.json();
          }
        })
        .then((response) => {
        
          if (response != undefined) {
          }

        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    }
  }


  exportInventoryCSV() {
    var timezone = moment().tz(moment.tz.guess()).format('z');
    var url = baseUrl.URLPath + "cpinventory/download/CSV";

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": timezone
      }
    })
      .then((resp) => {
     
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
      })
      .then((response) => {
    
        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Inventory_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }


  getTimeOutHinder(serialNumber){
    var url = baseUrl.URLPath + servicesUrl.getTimeouthinderBycp + "?cpid=" + serialNumber;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
       
        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
       
        if (response != undefined) {
          this.setState({
            timeOutArray: response,

          })
          this.toggleView(this.state.timeOutArray.no_of_attempts, this.state.timeOutArray.timeoutMinutes, serialNumber);
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  getReleaseData(event) {
 
    event.preventDefault();
    this.setState({
      modalView: !this.state.modalView
    });
    let errors = {};
    var cpID = this.state.saveSno;


    let url = baseUrl.URLPath + "timeouthinder/minutes"

    var data = { "chargePointId": cpID, "no_of_attempts": 0, "timeoutMinutes": 0, "previousMinutes": 0 };

    fetch(url, {
      method: 'PUT', 
      body: JSON.stringify(data), 
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else if (resp.status == 500) {
        alert(this.props.t('internal_server_error'))
      } else if (resp.status == 404) {
        this.setState({ errors: errors });
      } else if (resp.status == 409) {
        this.setState({ errors: errors });
      } else if (resp.status == 400) {
        return resp.json();
      } else {
        if (resp.status == 200) {
          this.toggleAuthentication()
        }
      }
    })
      .then(resp => {
      })
      .catch(error => alert('Error:' + error));

    this.setState({ errors: errors });

  }
  //function for sorting table data on click
  ascendingOrder() {
    if(this.state.cpIdSorting == false){
      this.state.orgArray.sort(this.dynamicSort("serialNumber"));
      this.state.cpIdSorting = true;
         this.setState({orgArray : this.state.orgArray.sort(this.dynamicSort("serialNumber"))})
    }
     else{
      this.state.orgArray.sort(this.dynamicSort("serialNumber"));
      this.state.cpIdSorting = false;
         this.setState({orgArray : this.state.orgArray.reverse()});
     } 
  }

  dynamicSort(property) {
    var sortOrder = 1;
  
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
  
    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
  
            return a[property].localeCompare(b[property]);
        }        
    }
  }
  render() {
    const{t}=this.props
    let user = this.state.user;
    const ref = React.createRef();

    const userEmail = this.state.e_mail.map((e_mail, index) => {
      return (
        <option value={e_mail.id}>{e_mail.email} - {e_mail.lastName}</option>
      )
    })


    // const commercialUser = this.state.commercial_Users.map((commercial_Users, index) => {
    //   return (
    //     <option value={commercial_Users.id}>{commercial_Users.email} - {commercial_Users.lastName}</option>
    //   )

    // })


    const organisation = this.state.dataArray.map((dataArray, index) => {
      var setOrg = false;
      if (this.state.org_name.toLowerCase() == dataArray.company.toLowerCase()) {
        setOrg = true;
        this.state.identifier = dataArray.identifier;
      }

      if(this.state.org_name.toLowerCase() != dataArray.company.toLowerCase()){
        return (
          <option value={dataArray.identifier} selected={setOrg} >{dataArray.company}</option>
        )
      }else{
        return <></>
      }
      
    })
    // condition for edit and delete inventory
    let getInventoryStatus = localStorage.getItem("saveInventory")
    if (this.state.enableInventory == getInventoryStatus) {
      this.state.inventoryDisplay = "display:block";
    }
    else {
      this.state.inventoryDisplay = "none";

    }

    const inventory = this.state.orgArray.map((orgArray, index) => {

  

      if (this.state.orgArray[index].status == true) {
        this.state.buttonacessdisplay = "block";

      }
      else {
        this.state.buttonacessdisplay = "none";

      }
      var displayTimeOut = '';
      var hideTimeOut = '';
      if (orgArray.inTimeout == true) {
        displayTimeOut = "YES";
        this.state.timeoutdisplay = "block";
        this.state.timeoutdisplayno = "none";

      }
      else {
        hideTimeOut = "NO";
        this.state.timeoutdisplayno = "block";
        this.state.timeoutdisplay = "none";
      }

      if(orgArray.manufacturingDate==null || orgArray.manufacturingDate==""){
        var manufacturingDate = "";
        var manufacturingDate1="";
      }else{
        var manufacturingDate = moment(orgArray.manufacturingDate).format("DD MMM YYYY");
        var manufacturingDate1 = moment(orgArray.manufacturingDate).format("HH:mm");
      }

      if(orgArray.createdDate==null || orgArray.createdDate==""){
        var createdDate = "";
        var createdDate1="";
      }else{
        var createdDate = moment(orgArray.createdDate).format("DD MMM YYYY");
        var createdDate1 = moment(orgArray.createdDate).format("HH:mm");
      }

      if(orgArray.updatedDate==null || orgArray.updatedDate==""){
        var updatedDate = "";
        var updatedDate1="";
      }else{
        var updatedDate = moment(orgArray.updatedDate).format("DD MMM YYYY");
        var updatedDate1 = moment(orgArray.updatedDate).format("HH:mm");
      }

      return (

        <tr>
          {hasPermission(user, ['inventory_W']) &&
            <td className="text-center">
              <Form.Check
                custom
                type="checkbox"
                id={`custom-${orgArray.id}`}
            
                label=""
                onChange={this.saveChargePoint.bind(this, `custom-${orgArray.id}`, orgArray.id, orgArray.serialNumber,index)}
                key={orgArray.id}
              />
            </td>
          }
          <td>  <Link to={{
            pathname: '/viewInventory',
            state: orgArray.serialNumber,
            
          }}  >{orgArray.serialNumber}</Link></td>
          <td className="wrap-word">{ manufacturingDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {manufacturingDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {manufacturingDate1} </div> </>}</td>
          <td>{orgArray.modelName}</td>
          <td>{orgArray.partNumber}</td>
          <td> {orgArray.mesSerialNumber}</td>
          <td>{orgArray.materialNo}</td>
          <td>{orgArray.fwVersion}</td>
          <td>{orgArray.vendorCode}</td>
          <td className="wrap-word">{ createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>}</td>
          <td className="wrap-word">{ updatedDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {updatedDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {updatedDate1} </div> </>}</td>

          <td className="text-center">
            {this.state.orgArray[index].accessCode != null && (
              <a onClick={this.displayAccessCode.bind(this, orgArray.accessCode, orgArray.id, index, orgArray.status)}>Show</a>
            )
            }
            {this.state.orgArray[index].status == true && (
              <MDBBtn style={{ display: this.state.buttonacessdisplay }} color='' type="submit" className="tooltipped tooltipped-s tooltipped-align-right-1 border access_code"
              >
                {this.state.displayAccesCode}
                {/* {orgArray.accessCode} */}
              </MDBBtn>)

            }



          </td>
          <td className="text-center"><h style={{ display: this.state.timeoutdisplayno }}>{hideTimeOut}</h>
            <a style={{ display: this.state.timeoutdisplay }} onClick={this.getTimeOutHinder.bind(this, orgArray.serialNumber)}>{displayTimeOut}</a>
          </td>

          {hasPermission(user, ['inventory_U']) && <td style={{ display: this.state.inventoryDisplay }}> <Link to={{
            pathname: '/editInventory',
            state: orgArray.serialNumber,
          }}  ><i class="fas fa-pencil-alt   pr-2 action-icon edit_usericon" title="Edit"></i></Link></td>}
        </tr>
      )


    })


    // const data =this.state.dataArray.map((dataArray, index) => {
    //   return(
    //           <tr key={index}>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //               <td></td>
    //           </tr>
    //        )
    //   })

    return (
      <>
        <main class="content-div" >
        <p>{t('Inventory')}
              <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Inventory')}</span>
              </div>
           </p>
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"> Maintenance > Inventory</div> */}
            {/* end page title */}
            {/* page menu bar */}
            <div className="row menubar">
              <div className="col-md-12 ">
                <button style={{ "display": this.state.hideActionBtn }} onClick={this.bulkImport.bind(this, 0)} >
                  <i className="fas fa-file-import"></i>
                  <span>{t('bulk_import')}</span>
                </button>
                {hasPermission(user, ['inventory_CSV']) &&
                  <button color="primary accent-2" onClick={this.exportInventoryCSV} >
                    <i className="fas fa-file-export"></i>
                    <span>{t('export_all')}</span>
                  </button>
                }

                {hasPermission(user, ['inventory_D']) && <button style={{ display: this.state.inventoryDisplay }} onClick={this.deletetoggle1.bind(this)}>
                  <i className="fas fa-trash">
                  </i>
                  <span>{t('delete')}</span>
                </button>}
              
              <div className="pull-right">
              <label className="sub-text pull-left" >{t('show_entries')}</label>
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-4 pull-right mt-1">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    minLength={3}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    placeholder={t('choose_a_charge_point_id')}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onFocus={this.ClearCpNames.bind(this, ref)}
                    onChange={this.selectedCpChange.bind(this)}
                    // onBlur={this.onBlurEvent}
                    ref={ref}
                    id="rbt_id"
                  />
                  <button type="button" className="close close_icon" aria-label="Close" onClick={this.ClearCpName.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this, ref)} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{color: "red"}}>{this.state.errors["entercharge"]}</span></span> 
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
                </div>
              </div>
          
              <div className="col-md-3 pl-0" style={{ "display": this.state.selectDropdown }}>
                {hasPermission(user, ['inventory_W']) &&
                  <select className="browser-default custom-select select_height" id="organization" onChange={this.organizationChange.bind(this)}>
                    <option value="">{t('select_organization')}</option>
                    {organisation}
                  </select>
                }
              </div>
              <div class="col-md-1 pl-0">
                {hasPermission(user, ['inventory_W']) &&
                  <button type="submit" className="btn_primary mt-1 pull-left " style={{ display: this.state.reassigndisplay }} onClick={this.confirmtoggle}
                  >
                    {t('reassign')}
                  </button>
                }
                {hasPermission(user, ['inventory_W']) &&
                  <button type="submit" className=" btn_primary mt-1 pull-left" style={{ display: this.state.buttondisplay }} onClick={this.releasetoggle.bind(this)}
                  >
                    {t('release')}
                  </button>
                }
              </div>
              <div className="col-md-3 pl-0" >
                {hasPermission(user, ['assignInventory_R']) &&

                  <select className="browser-default custom-select select_height" id="siteAdmin" onChange={this.siteAdminChange.bind(this)}>
                    <option value="" >{t('select_site_admin')}</option>
                    {userEmail}
                  </select>
                }


              </div>
              <div class="col-md-1 pl-0">
                {hasPermission(user, ['assignInventory_R']) &&

                  <button  type="submit" className="btn_primary  mt-1 pull-left " onClick={this.siteadmintoggle.bind(this)}>
                    {t('assign')}
                  </button>
                }
              </div>
         
              {/* <div className="col-md-3 pull-right">

              
             {hasPermission(user, ['assignInventory_Commercial_R']) && 
             <select  className="browser-default custom-select select_height" id="commercialUser"  onChange={this.commercialUserChange.bind(this)}>
                        <option value="">Select Commercial User</option>
                        {commercialUser}                    
                        </select>
                         } 
                        </div> */}

              {/* <div class="col-md-1">
                                    {hasPermission(user, ['assignInventory_Commercial_R']) && 

                <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-left " onClick={this.commercialusertoggle.bind(this)}>
                      Assign
                    </MDBBtn>
                                         }

                    </div> */}
            </div>
            <div className = "row">
              <div className="col-md-3 md-form mt-2">
                <input name="adminAddress" type="text" id="mesSerialNo" className="form-control" onChange={this.changeMes.bind(this, "mesSerialNo")} value={this.state.fields["mesSerialNo"]} onKeyDown={this.keyPress.bind(this)} />
                <label htmlFor="mesSerialNo" className={`${(this.state.fields['mesSerialNo'] != "") ? "active" : ""} ml-16`}>{t('search_for_mes_serial_no')}</label>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["mesSnoEmpty"]}</span></span>
              </div>
            <MDBCol md="1" className="my-3 mt-4">
            <i class="fas fa-filter" onClick={this.onChangeMesSno}></i>
              </MDBCol>
              <div className="col-md-3 md-form mt-2">
                <input name="adminAddress" type="text" id="modelName" className="form-control" onChange={this.changeModelName.bind(this, "modelName")} value={this.state.fields["modelName"]} onKeyDown={this.keyPress1.bind(this)} />
                <label htmlFor="modelName" className={`${(this.state.fields['modelName'] != "") ? "active" : ""} ml-16`}>{t('Search for Model Name...')}</label>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["modelEmpty"]}</span></span>
              </div>
            <MDBCol md="1" className="my-3 mt-4">
            <i class="fas fa-filter"onClick={this.onChangeModeName}></i>
              </MDBCol>
              <div className="col-md-3 md-form mt-2">
                <input name="adminAddress" type="text" id="materialNo" className="form-control" onChange={this.changeMaterialNo.bind(this, "materialNo")} value={this.state.fields["materialNo"]} onKeyDown={this.keyPress2.bind(this)} />
                <label htmlFor="materialNo" className={`${(this.state.fields['materialNo'] != "") ? "active" : ""} ml-16`}>{t('search_for_material_no')}</label>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["matnoEmpty"]}</span></span>
              </div>
            <MDBCol md="1" className="my-3 mt-4">
            <i class="fas fa-filter" onClick={this.onChangeMatno}></i>
              </MDBCol>
</div>
            {/* end page menu bar  */}
            {/* table start */}
            <div className="row">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      {hasPermission(user, ['inventory_W']) &&
                        <th></th>
                      }
                      <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('mf_date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('model')}</th>
                      <th className="">{t('part_no')}</th>
                      <th className="">{t('mes_serial_no')}</th>
                      <th>{t('material_no')}</th>
                      <th className="">{t('fw_version')}</th>
                      <th className="">{t('vendor_code')}</th>
                      <th>{t('created_date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('Modified Date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('access_code')}</th>
                      <th className="">{t('in_Timeout')}</th>
                      {hasPermission(user, ['inventory_U']) && <th style={{ display: this.state.inventoryDisplay }}>{t('action')}</th>}

                    </tr>
                  </thead>
                  <tbody>
                    {inventory}
                  </tbody>
                </Table>
              </div>
              {/* End table  */}
              <div className="col-md-12 mt-2 mb-4 text-right">

                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>


          {/* popup for session expire */}

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
      </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* confirm reassign */}
          <MDBModal isOpen={this.state.confirmmodal} toggle={this.confirmtoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.confirmtoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_reassign_charge_point')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.confirmtoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.reAssignChargePoint.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.confirmtoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.reAssignChargePoint}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* confirm siteAdmin */}
          <MDBModal isOpen={this.state.siteadminmodal} toggle={this.siteadmintoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.siteadmintoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_assign_charge_point')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.siteadmintoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.assignSiteAdmin.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.siteadmintoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.assignSiteAdmin}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          {/* confirm commercilauser */}
          <MDBModal isOpen={this.state.commercialusermodal} toggle={this.commercialusertoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.commercialusertoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_assign_charge_point')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.commercialusertoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.assignCommercialUsers.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.commercialusertoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.assignCommercialUsers}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          {/* confirm release */}

          <MDBModal isOpen={this.state.releasemodal} toggle={this.releasetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.releasetoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_release_charge_point')}
  </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.releasetoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.releaseButton.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.releasetoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.releaseButton}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* confirm delete */}
          <MDBModal isOpen={this.state.deletemodal}  toggle={this.deletetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.deletetoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('sure_you_want_to_delete_the_inventory')}
</MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.deletetoggle.bind(this)}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.deleteInventory.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color="danger" onClick={this.deletetoggle}>Close</MDBBtn>
              <MDBBtn color="primary" onClick={this.deleteInventory}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charge_Point_reassigned_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modaladminsuccess} toggle={this.toggleadminsuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleadminsuccess}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charge_point_assigned_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalr} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charge_point_released_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalInventory} backdrop="static" data-backdrop="static" data-keyboard="false" className="model_top" size="md">

            <MDBModalHeader toggle={this.deleteSucesstoggle}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('inventory_deleted_successfully')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk1.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle3}>{t('warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_charge_point_id_and_org')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.warningModelOk.bind(this)}>{t('ok')}</button>
              {/* <MDBBtn color="primary" onClick={this.warningModelOk}>OK</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.deletemodal1} toggle={this.deletetoggle1} size="md" className="model_top" >
            <MDBModalHeader toggle={this.deletetoggle1}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.deletetoggle1.bind(this)}>{this.state.deleteText}</button>
            <button type="button" data-test="button" className={`btn_primary ${this.state.deleteButton}`} onClick={this.deleteInventory.bind(this)}>{t('yes')}</button>
              {/* <MDBBtn color={this.state.deletecolor} onClick={this.deletetoggle1}>{this.state.deleteText}</MDBBtn>
              <MDBBtn color="primary" className={this.state.deleteButton} onClick={this.deleteInventory}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modalAdmin} toggle={this.admintoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.admintoggle}>{t('warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_charge_point_id_and_site_admin')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.warningMessage.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* new added popup */}
          <MDBModal isOpen={this.state.releaseWarning} toggle={this.releaseWarningtoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.releaseWarningtoggle}>{t('warning')}</MDBModalHeader>
            <MDBModalBody>
              {t('select_charge_point_id')}
              </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.releaseWarningtoggle.bind(this)}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>


          {/* Popup for  View */}
          <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="md" className="model_top">
            {/* <MDBModalHeader toggle={this.toggleView}>View</MDBModalHeader> */}
            <MDBModalBody>
              <div className="row timeout_counter" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="12" className="text-center">
                  <label className="">{t('timeout_counter')}</label>&nbsp;<label className="">{this.state.displayReq}</label>

                </MDBCol>
                {/* <MDBCol md="1" >
    <label className="">{this.state.displayReq}</label>
   </MDBCol> */}
              </div>
              <div className="row timeout_counter" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="12" className="text-center" >
                  <label className=""> {t('current_timeout_in_minutes')}</label>&nbsp;<label className="">{this.state.displayRes}</label>
                </MDBCol>
                {/* <MDBCol md="1" >
    <label className="">{this.state.displayRes}</label>
   </MDBCol> */}
              </div>

            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.toggleView}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.getReleaseData}>{t('reset_timeout')}</button>
              {/* onClick={this.addBuildtype} */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalSuccessauthentication} backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false" size="md" >
            <MDBModalHeader toggle={this.toggleAuthentication}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('charger_released_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.CloseSuccessauthentication}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

//export default inventoryList;
export default withTranslation()(inventoryList);
