import React from 'react';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import CsOperationsModal from '../common/CsOperationsModal';

class changeAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectorID: "",
      fields: {
        connectorId: "",
        availabilityType: '',
        id: '',
      },
      sucess: false,
      colour: 'sucess',
      cpList: [],
      errors: {},
      page_title: "Change Availability",
      chargePoint: '',
      operationId: ''
    }
    this.onShowAlert = this.onShowAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSelectedCP = this.getSelectedCP.bind(this);
  }

  componentDidMount() {
    let propsdata = this.props.location.state;
    if (propsdata != undefined) {
      if (propsdata.chargePoint != undefined && propsdata.operationId != undefined) {
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  async handleSubmit() {
    var payload = this.getPayLoad();
    var errors = {};
    var connectorId = payload.connectorId;
    var type = payload.type;

    if (this.getCPList() == 0) {
      errors["emptyChargePoint"] = this.props.t('select_charge_point')
    }
    if (isNaN(connectorId)) {
      errors["emptyConnectorId"] = this.props.t('enter_connector_id')
    }
    if (type == '') {
      errors["emptyType"] = this.props.t('select_availability_type')
    }

    if (this.getCPList() != 0 && !isNaN(connectorId) && type != '') {
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
      if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
          this.onShowAlert();
        })
      } else {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
          this.onShowAlert();
        })
      }
    }

    this.setState({ errors: errors })
  }

  getCPList() {
    var cplist = [];
    var Id = this.state.operationId;
    if (Id != null && Id != "") {
      cplist = Id.split();
    } else {
      cplist = this.state.cpList;
    }
    return cplist;
  }

  getPayLoad() {
    var payload = {
      "connectorId": parseInt(this.state.fields.connectorId),
      "type": this.state.fields.availabilityType
    };
    return payload;
  }

  getMessageType() {
    return constants.CHANGE_AVILABILITY;
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    e.preventDefault();

    let fields = this.state.fields;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.state.errors["emptyConnectorId"] = "";
      this.setState({ fields: fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields: fields });
    }
  }

  availabilityChange(e) {
    var x = document.getElementById("availabilityType").value;
    let fields = this.state.fields;
    fields["availabilityType"] = x;
    this.state.errors["emptyType"] = "";
    this.setState({ fields: fields });
  }
  
  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  getSelectedCP(cpList) {
    if (cpList != "") {
      this.state.errors["emptyChargePoint"] = " "
    }
    this.setState({ cpList: cpList });
  }

  render() {
     const { t } = this.props;
    return (
        <main class="content-div" >
          <p>{t('Change Availability')}
            <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">{t('Change Availability')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName="ChangeAvailability"  ></ChargePointSelection>
                      <MDBCol md="12">
                        <span className="selection_charge_point_error w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyChargePoint"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.fields["connectorId"]}
                          name="ConnectorID"
                          onInput={this.numberHandler.bind(this, "connectorId")}
                          type="text" pattern="[0-9]*"
                          id="connectorID"
                          label={t('connector_id_integer')}
                          required
                        >
                          <span className="error_msg w3-animate-top">
                            <span style={{ color: "red" }}>{this.state.errors["emptyConnectorId"]}</span>
                          </span>
                          <small id="heartbeatinterval" className="form-text text-muted">
                            {/* if empty, 0 = charge point as a whole */}
                            {t('if_empty0charge_point')}
                          </small>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol md="4">
                        <select className="browser-default custom-select select_height mb_8" id="availabilityType" onChange={this.availabilityChange.bind(this)}>
                          <option value=""> -- {t('Select Availability Type')} *--</option>
                          <option value="Inoperative">{t('Inoperative')}</option>
                          <option value="Operative">{t('Operative')}</option>
                        </select>
                        <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["emptyType"]}</span></span>
                      </MDBCol>
                    </MDBRow>
                    <button onClick={this.handleSubmit} type="button" className="mt-5 refresh-btn">{t('perform')}</button>
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </main>
    );
  }
}
export default withTranslation()(changeAvailability);