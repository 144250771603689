import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Header from './header';

class dataTransfer extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          cpList:[],
          fields:{
            vendorId:"",
            messageId:"",
            data:"",
            
          },
          sucess:false,
          colour:'sucess',
          page_title:"Data Transfer",
          errors:{},
          chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);

}
onShowAlert(){
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
async handleSubmit(event) {
  
  var fds= this.getCPList();
  var payload=this.getPayLoad();
  

  var errors={};
  var vendorId=payload.vendorId;
if(this.getCPList()==0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
}
if(vendorId==''){
  errors["emptyvendorId"]=this.props.t('enter_vendor_id')
}



if(this.getCPList()!=0 && vendorId!=''){
   var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
  //alert(response);
  if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else {
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }
}
this.setState({errors:errors})
}
getCPList() {
  var Id = this.state.operationId;
 if(Id != null&& Id != ""){
   var cplist = Id.split();
 
  }
  else{
   var cplist = this.state.cpList;
 
 }
 return cplist;
}

getPayLoad() {
  var payload =
    {
    "vendorId": this.state.fields["vendorId"],
    "messageId": this.state.fields["messageId"],
    "data": this.state.fields["data"]
    };
   
  return payload;

}

getMessageType() {
  return constants.DATA_TRANSFOR;
} 


changeHandler(field, e){ 
  
  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields});
  if(fields[field]!=0){
  
    this.state.errors["emptyvendorId"]=" "
  
  }
 
  }

getSelectedCP(cpList){
  
  this.setState({cpList:cpList});
  if(cpList!=""){

    this.state.errors["emptyChargePoint"]=" "
  
  }

}
componentWillUnmount(){
  localStorage.setItem('chargePoint', '');
  localStorage.setItem('operationId', '');
}
componentDidMount(){
  let propsdata = this.props.location.state;
    if(propsdata!=undefined){
      if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }  
}
render() {
  const{t}=this.props
  return (
    <>

<main class="content-div" >
<p> {t('Data Transfer')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Data Transfer')}</span>
              </div>
           </p>
    {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Data Transfer </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Data Transfer </a></div> */}

          {/* <p>Data Transfer</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

      {/* <section className="dashboard">
        <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left" >Data Transfer</h4>
                <div class="clearfix"></div>
            </div>
        </div>
        <section className="content_wbg">  */}
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
          <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName ="DataTransfer" ></ChargePointSelection>
          <MDBCol md="12">
       <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
        </MDBCol>
          {/* <MDBCol md="12">
            <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              <button class="mr-2 select_btn btn_cursor" > Select All</button> <button class="mr-4 select_btn btn_cursor">Select None </button>
            </label>
            <select className="browser-default custom-select select_height1" id="confKeyList" name="confKeyList" multiple="multiple" size="14"></select>
             </div>
            </MDBCol> */}
          <MDBCol md="4">
              <MDBInput
                name="chargebox"
                o onChange={this.changeHandler.bind(this, "vendorId")}
                type="text"
                label={t('vendor_id_string')}
                required
              >
        <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyvendorId"]}</span></span>

                {/* <div className="valid-feedback">Looks good!</div> */}
               
              </MDBInput>
            </MDBCol>

            <MDBCol md="4">
            <MDBInput
                name="chargebox"
                onChange={this.changeHandler.bind(this, "messageId")}
                type="text"
                id="availabilityType"
                label={t('message_id_string')}
                required
              >
                <div className="valid-feedback">{t('looks_good')}</div>
               
              </MDBInput>
            	
         </MDBCol>
         <MDBCol md="4">
            <MDBInput
                name="chargebox"
                onChange={this.changeHandler.bind(this, "data")}
                type="text"
                label={t('data_text')}
                required
              >
                <div className="valid-feedback">{t('looks_good')}</div>
               
              </MDBInput>
            	
         </MDBCol>
         
          </MDBRow>
         

          <button type = "button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
          
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
        </div>
  
    </main>
    </>
  );
}
}

export default withTranslation()(dataTransfer);