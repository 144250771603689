import React from 'react';
import { MDBBtn,MDBRow,MDBCol, MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';

class bulkImport extends React.Component {  
    constructor (props){
        super(props);
        this.state = { // variables
          fields: { organisation:''},
            data:{},
            sucess:{},
            errors:{},
            docFile:"",
            identifier:"",
            downloadLink:"",
            model:"false",
            model1:"false",
            modelTile:"",
            modelBody:"",
            selectedFile:"",
            page_title:"Bulk Import",
            downloadUrl:"",
            dataArray:[],
            errors: {},
            modal: false,
            togglesession: false
        }
        this.handleSubmit = this.handleSubmit.bind(this) 
        this.startDownload = this.startDownload.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.organizationChange = this.organizationChange.bind(this)
        this.getOrglist = this.getOrglist.bind(this)
        this.togglesession = this.togglesession.bind(this);
        this.tagOk = this.tagOk.bind(this);
      }


      organizationChange(){
        let errors = {};
        
        var x = document.getElementById("organisation").value;
        this.setState({identifier:x});
        let fields = this.state.fields;
        fields["organisation"] = x;
        this.setState({fields});
        errors["notifyOrg"] = 'please ensure that the excel sheet organization id is "'+x+'" before uploading.';
        this.setState({errors: errors});
      
    }
    getOrglist(){

      var url = baseUrl.URLPath + "admin/v1.0/account/all";
   fetch(url,{
     method: "GET",
     headers: {
           "content-type":"application/json",
           "Authorization":localStorage.getItem("Authorization")
         }
     })
     .then((resp) => {
       
       if(resp.status==401){
         this.togglesession();
   
         localStorage.clear();
        
       }
       if(resp.status==200){
         return resp.json();
       }
     }) 
     .then((response) =>{
       
       
       if(response!=undefined){
     this.setState({ dataArray:response.organisationDTOS})
       }
     })
     .catch((error) => {
         console.log(error, "catch the loop")
     })
     
 }

  // functions call when click on download button for downloading status report
  startDownload(){
    document.getElementById("loader_image_div").style.display = "block";

     
    this.setState({ //close popup
      modal: !this.state.modal
    });
    
  
    // document.getElementById("loader_image_div").style.display = "block"; // loader images
    var errors={};
    var Url1 = baseUrl.URLPath + "cpinventory/bulkupload" //url
    //localhost:8080/cpms/api/cpinventory/bulkupload
    var data = new FormData();
    data.append("file",this.state.docFile);
    data.append("tenantId",this.state.identifier)
        fetch(Url1, {
        method: "POST",
        body: data,
        headers:{
          "Authorization":localStorage.getItem("Authorization"),
          }
        }).then(resp =>{
                 if(resp.status == 400){
                  document.getElementById("loader_image_div").style.display = "none";
                errors["wrongFile"] = "There is a problem in processing the file.";
                  this.setState({
                      errors: errors
                    });                  }
                  if(resp.status==500){
                    // errors["choosefile"]="Please choose a file.";
                    document.getElementById("loader_image_div").style.display = "none";
                    errors["internalServer"] = "Internal Server Error, Please contact admin.";
                    this.setState({
                        errors: errors
                      });
                  }else if(resp.status==200){
                    
                    return resp.json();
                  }else if(resp.status==401){
                    document.getElementById("loader_image_div").style.display = "none";
                    this.togglesession();
                    localStorage.clear();
                  }
                
                }).then((response) =>{
                    
                  if (response.status == "FAILURE") {
                            document.getElementById("loader_image_div").style.display = "none";
                            errors["choosefile"] = response.errorMessage;
                            this.setState({
                                errors: errors
                              }); 
                        }  
                        else if (response.status == "SUCCESS") {
                             setTimeout(() => {
                                document.getElementById("loader_image_div").style.display = "none";
                                this.setState({
                                    modal1: !this.state.modal1,
                                });
                            }, 2000)
                                this.setState({
                                    downloadLink: response.downloadLink
                                })
                        }   else  {
                            document.getElementById("loader_image_div").style.display = "none"; 
                            errors["choosefile"] = "There is a problem in processing the file.";
                            this.setState({
                                errors: errors
                              });
                    } 
                    });
               
  }
  handleChange(e){
    var errors={};
    var imagedata = document.querySelector('input[type="file"]').files[0];
     this.setState({
        docFile:imagedata
     })
     errors["choosefile"]="";
     this.setState({errors:errors});
  }

//toggle (show and hide) confirmation popup
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  togglesession(){
    this.setState({
      togglesession: !this.state.togglesession
    });
  }

//toggle (show and hide) success popup 
  toggleSuccess = (text) =>{
    this.setState({
      modal1: !this.state.modal1
    });
    this.props.history.push('/inventoryList')
  }

  //when click on save button
  handleSubmit(event){
    
    var errors={};
    var organisation= this.state.fields['organisation'];
    if(this.state.fields.organisation!=''){
      event.preventDefault()
     
      errors["organisationempty"]="";
      this.setState({errors:errors});
    }
    else{
      errors["organisationempty"]="Please select organization.";
      this.setState({errors:errors});
    }
    if(this.state.docFile=="" || this.state.docFile == undefined){
      event.preventDefault();
      errors["choosefile"]="Please choose a file.";
      this.setState({errors:errors});
    }
  else{
      errors["choosefile"]="";
      this.setState({errors:errors});
    }
    if(this.state.docFile!="" && this.state.fields.organisation!="" && this.state.docFile != undefined){
       this.setState({
        modal: !this.state.modal
      }); 
    }
   }

   componentWillMount(){
        var the_arr = baseUrl.URLPath.split('/');
        the_arr.pop();
        the_arr.pop();
        var newlink = the_arr.join('/') + "/";
        this.setState({
          downloadUrl:newlink
        })
   }
      
    componentDidMount(){     
      var url = baseUrl.LoginPath + "/cpinventory/download/template"

      fetch(url,{
        method: "GET",
        headers:{
          'Content-Type':'application/json',
          "Authorization":localStorage.getItem("Authorization")
          },
        })
        .then((resp) => {
          if(resp.status==401){
            this.togglesession();
            localStorage.clear();
          }else{
            return resp.json();
          }
        }) 
        .catch((error) => {
            console.log(error, "catch the loop")
        })
        this.getOrglist();
    }
    tagOk() {
      this.props.history.push('/');
      window.location.reload();
    }

    render() {

      const organisationDTOS =this.state.dataArray.map((dataArray, index) => {
        return(
          <option value={dataArray.identifier}>{dataArray.company}</option>
        )
       
      })
        return (
   <>
       <main class="content-div" >
       <p>Inventory
              <div className="breadcrumb_div">
              Maintenance &gt; <Link to="/inventoryList" > <span className="">Inventory</span></Link> &gt; <span className="breadcrumb_page">Bulk Import</span>
              </div>
           </p>
        <div className="page-outerdiv">
        <form
          className='needs-validation'
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBCol sm="12" >
            <table  id="table-to-xlsx" className="excel_visi">
              <thead>
                <th>
                 ChargePoint Id*
                </th>
                <th>
                Description
                </th>
                <th>HouseNO</th>
                <th>Street</th>
                <th>Zipcode</th>
                <th>City</th>
                <th>Country</th>
                <th>Comments</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                  </td>
                  <td>
                  </td>
                  <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                </tr>
              </tbody>
            </table>

            {/* </tableToExcel> */}
          </MDBCol>
          <MDBRow className="mb-4 mt-3" >
          <MDBCol sm="12" >
            <div class="md-form input-group mb-0" > 
              <a href = {this.state.downloadUrl +"cpinventory/download/template"} >
              <div class="input-group-prepend" className="fa fa-download download_input">
            </div>
           </a>
              <div class="input-group-append">
                <span class="input-group-text md-addon">Download Template</span>
              </div>
            </div>
          </MDBCol>
          <MDBCol sm="6"  className="mt-5" >
          <input type="file" accept=".xls,.xlsx" name="file" id="exampleFile"  onChange={this.handleChange.bind(this)}/>
          <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["choosefile"]}</span></span> 
          <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["wrongFile"]}</span></span>
          <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["internalServer"]}</span></span>
        </MDBCol>
        <MDBCol md="3" className="m_26" >
          <select className="browser-default custom-select select_height" id="organisation" onChange={this.organizationChange.bind(this)} >                          
            <option value="">-- Select Organization--</option>
            {organisationDTOS}
          </select> 
          <span className="error_msg w3-animate-top mt-2">  <span style={{color: "green"}}>{this.state.errors["notifyOrg"]}</span></span>
          <span className="error_msg w3-animate-top mt-1">  <span style={{color: "red"}}>{this.state.errors["organisationempty"]}</span></span> 
          </MDBCol>
        <MDBCol sm="3"  className="">
          <button type = "button" className="mt-5 btn_primary" onClick={this.handleSubmit}>Upload</button>
        </MDBCol>
        </MDBRow >
    </form>
      </div>
      </main>

      <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>Confirmation</MDBModalHeader>
            <MDBModalBody>
              Are you sure, do you want to start import ?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.toggle} >Cancel</MDBBtn>
              <MDBBtn color="primary" onClick={this.startDownload} >Start</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal1} toggle={this.toggleSuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleSuccess}>Success</MDBModalHeader>
            <MDBModalBody>
                Bulk import completed succesfully. Please select download button to downlaod the results.
            </MDBModalBody>
            <MDBModalFooter>
            <a style={{ color:'#fff'}} href={this.state.downloadUrl+ "cpinventory/download"} ><MDBBtn color="primary" onClick={this.toggleSuccess.bind(this,"Download")}>Download 
            </MDBBtn></a>
              <MDBBtn color="primary" onClick={this.toggleSuccess.bind(this,"ok")} >Ok</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

            <MDBModal isOpen={this.state.togglesession} toggle={this.togglesession} size="md" className="model_top">
              <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
              <MDBModalBody>
                Please login again.
              </MDBModalBody>
              <MDBModalFooter>
                <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>OK</button>
              </MDBModalFooter>
            </MDBModal>
          </>
      )
    }      

}

export default bulkImport;