import React from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from './constants';
import $ from "jquery";
import Datetime from 'react-datetime';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { hasRole, hasPermission } from './auth';
import moment from 'moment';
import CpSearch from '../common/chargePointSearch';
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import servicesUrl from '../common/servicesUrl';

class Ocppnewtag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideActionBtn: "none", // show hide btn varible based on permission 
      writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
      fields: { idTag: '', parentIdTag: '', expiryDate: '', inTransaction: '', note: '', blocked: '', tagType: '' },
      expdate: '',
      sucess: {},
      errors: {},
      disabled: "true",
      id: "",
      activeItem: "1",
      isChecked: "1",
      startDate1: "",
      startDate: new Date(),
      model1: "false",
      model2: "false",
      par_id_tag: "",
      multiple: false,
      options: [],
      page_title: "View OCPP Tag",
      user: {
        permissions: [

        ]
      },
      selectedItems: [],
      prevSelectedData: [],
      siteWallboxes: [],
      deletedWallboxes: [],
      allCPList: [],
      associatedCPList: [],
      isrfidSuccess: false,
      modalsession: false,
      wallbox: '',
      noWallboxExists: "",
      getOperationsList: [],
      isFreeChargeEnabled: false,
      disabledbtn: "true",
      fromDateApi: "",
      toDateApi: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      activePage: 1,
      pageSize: 30,
      // ass
      afromDateApi: "",
      atoDateApi: "",
      acurPageFirstCreTime: "",
      acurPageLastCreTime: "",
      aprevPageFirstCreTime: "",
      alistOfFirstCreatedtimes: [],
      aactivePage: 1,
      cpId: "",
      acpId: "",
      apageSize: 30,
      anoOfRecords: "",
      noOfRecords:"",
      a_wallbox: "",
    }
    this.deleteTag = this.deleteTag.bind(this)
    this.idTagChange = this.idTagChange.bind(this);
    this.togglerfidSuccess = this.togglerfidSuccess.bind(this);
    this.editTag = this.editTag.bind(this)
    this.redirectToListPage = this.redirectToListPage.bind(this)
    this.backToList = this.backToList.bind(this)
    this.toggleChange = this.toggleChange.bind(this)
    this.setValue = this.setValue.bind(this)
    // this.handleKeyDown = this.handleKeyDown.bind(this);
    // this.FindCpName = this.FindCpName.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.updateCheckboxSelection = this.updateCheckboxSelection.bind(this);
    this.handleWallboxChange = this.handleWallboxChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.handleWallboxPress = this.handleWallboxPress.bind(this);
    this.FindRfidCp = this.FindRfidCp.bind(this);
    this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.removeSelectedItem = this.removeSelectedItem.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this)
    this.ashowEntriesChange = this.ashowEntriesChange.bind(this)
    this.getRFIDCPlist = this.getRFIDCPlist.bind(this);
    this.getRFIDCPunassociatedlist = this.getRFIDCPunassociatedlist.bind(this);
  }


  handleWallboxPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ wallbox: e.target.value });
      this.getRFIDCPlist();
    }
    if ((key == "Backspace" || key == "Delete") && e.target.value.length == 1) {
      this.setState({
        wallbox: ""
      }, () => {
        this.clearWallboxerror();
        this.getRFIDCPlist();
      });
    }


    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9_]");
        var key = e.key;

        break;

    }
  }

  togglerfidSuccess() {
    this.setState({
      isrfidSuccess: !this.state.isrfidSuccess
    })
  }

  togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    })
  }


  updateCheckboxSelection(event, item) {
    const allItems = [...this.state.selectedItems];
    const selectedItemIndex = allItems.findIndex(el => el === item);
    $('.error_cpids').text("");
    if (event.target.checked) {
      allItems.push(item);
    } else {
      allItems.splice(selectedItemIndex, 1);
    }
    this.setState({ ...this.state, selectedItems: allItems, disabledbtn: "" });
  }

  // associated 
  aupdateCheckboxSelection(event, item) {
    const deletedWallboxes = [...this.state.deletedWallboxes];
    const selectedItemIndex = deletedWallboxes.findIndex(el => el === item);
    $('.error_cpids').text("");
    if (event.target.checked) {
      deletedWallboxes.splice(selectedItemIndex, 1);
    } else {
      deletedWallboxes.push(item);
    }
    this.setState({ ...this.state, deletedWallboxes, disabledbtn: "" });
  }

  isaItemSelected(item) {
    const retVal = this.state.deletedWallboxes.find(el => el === item) ? false : true;
    return retVal;
  }

  isItemSelected(item) {
    const retVal = this.state.selectedItems.find(el => el === item) ? true : false;
    return retVal;
  }

  //   getting Idtag based on search filter

  idTagChange(idTag) {
    this.state.selectFindStatus = true;

    this.state.saveSelectedIdTag = idTag;
    this.setState({ saveSelectedIdTag: idTag });

    if (idTag.length == 0) {

      this.getOCPPTags(this.state.pageSize, this.state.activePage)
      this.setState({
        dataArray: this.state.dataArray
      });
    } else {
      let errors = {};
      errors["idTagNotExists"] = "";
      //  errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      });
      // this.onClickFindbtn(idTag);
      this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag);

    }

  }

  getIdTags(event) {

    var idTags = event.target.value;
    this.setState({
      idTags: idTags
    })
    if (idTags.length == 0) {
      this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag)
      this.setState({
        dataArray: this.state.dataArray,
      });
    }
    var errors = {};
    errors["idTagNotExists"] = "";
    this.setState({ errors: errors });
  }

  backToList() {
    this.props.history.push('/Ocpptags');
  }


  setValue() {

  }


  handleChange = date => {
    this.setState({ startDate: date });
    //var tempDate = new Date(date);
    var currentDate = new Date(date);
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;
    this.setState({ startDate1: createdDateTo });
  };



  componentDidMount() {
    // permission related code
    localStorage.setItem("noWallboxExists","");
    this.state.user.permissions = localStorage.getItem("roleAccess");

    if (this.state.writePermission == true) {
      this.setState({
        hideActionBtn: "inline-block"
      })



    }
    this.getCustomization();
    this.getSecurityData();



    var timeZone = localStorage.getItem("timezone");
    var id_tag = this.props.location.state;
    // var array = url.split('/');
    this.state.id = id_tag;
    var url = baseUrl.URLPath + servicesUrl.getOcpptagById + "?id=" + this.state.id;
    //localhost:8080/cpms/ocpptags/{ocppTagId}
    document.getElementById("loader_image_div").style.display = "block";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),

        "timeZone": timeZone
      }
    })
      .then((resp) => {

        return resp.json();

      })
      .then((response) => {
        setTimeout(() => {
          ;
          var data1 = [];
          data1["idTag"] = response.idTag;
          if (response.parentIdTag != null) {
            data1["parentIdTag"] = response.parentIdTag.idTag;
          } else {
            data1["parentIdTag"] = "";
          }
          data1["expiryDate"] = response.expiryDate;
          data1["inTransaction"] = response.inTransaction;
          data1["blocked"] = response.blocked;
          data1["note"] = response.note;
          data1["tagType"] = response.tagType;
          //this.state.startDate = response.expiryDate;
          this.setState({
            fields: data1,
            // startDate:response.expiryDate
          })
          this.getRFIDCPlist(true);
          this.getRFIDCPunassociatedlist(true)
          this.setState({
            startDate1: this.state.fields.expiryDate
          })

          //this.state.startDate = this.state.fields.expiryDate;

          this.setState({ startDate: new Date(this.state.fields.expiryDate) })


          if (this.state.fields.blocked == "1") {

            this.state.fields.blocked = "Yes"
            // alert(this.state.fields.blocked)
            this.setState({
              blocked: this.state.fields.blocked
            })
          } else {
            this.state.fields.blocked = "No"
            this.setState({
              blocked: this.state.fields.blocked
            })
          }
          if (this.state.fields.inTransaction == "1") {
            this.state.fields.inTransaction = "Yes"
            this.setState({
              inTransaction: this.state.fields.inTransaction
            })
          } else {
            this.state.fields.inTransaction = "No"
            this.setState({
              inTransaction: this.state.fields.inTransaction
            })
          }

          document.getElementById("loader_image_div").style.display = "none";

        }, 5000);


      })
      .catch((error) => {
        alert(error);
        console.log(error, "catch the loop")
      })
    $(".rdt").addClass("cal_disable");

  }

  toggleChange = () => {
    var x = document.getElementById("myCheck").checked;
    if (x) {
      this.state.fields.blocked = "1";
    }
    else {
      this.state.fields.blocked = "0";
    }

  }

  /* To get SecurityData list*/
  getSecurityData() {

    let url = baseUrl.URLPath + "preferences/security";
    //localhost:8080/api/preferences/security
    fetch(url, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(response => {

      if (response != undefined) {
        this.setState({
          isFreeChargeEnabled: response.isFreeChargeEnabled
        })
      }
    })
      .catch((error) => {
        console.log(error, "get connector status catch the loop");
      })
  }


  transactionChange = () => {

    var y = document.getElementById("transcheck").checked;
    if (y) {
      this.state.fields.inTransaction = "1";
    }
    else {
      this.state.fields.inTransaction = "0";
    }

  }



  redirectToListPage() {
    this.props.history.push('/Ocpptags')
  }




  editTag() {

    this.props.history.push('./editTag');
  }

  // Delete tag 
  deleteTag() {
    this.setState({
      modal: !this.state.modal
    });
    let errors = {};
    let url = baseUrl.URLPath + "ocpptags/delete/" + this.state.id;
    fetch(url,
      {
        method: 'DELETE', // or 'PUT'
        body: JSON.stringify("NULL"), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),

        }
      }
    ).then(resp => {
      if (resp.status == 404) {
        this.setState({ errors: errors });
        return resp.json();
      }
      else if (resp.status == 500) {

        alert("internal server error")

      } else if (resp.status == 400) {

        alert("Bad request")

      } else {
        this.props.history.push('/Ocpptags');
        // $("#suc_msg").html(' <strong>Success!</strong>Charge Point Tag Id deleted successfully.').slideDown();
        // setTimeout(this.redirectToListPage, 3000);
      }
    })
      .catch(error => alert('Error:' + error));
      this.setState({ errors: errors });
  }

  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }
  getRFIDCPlist(countValue, cpid, text) {
    var cpId = "";
    
    if (cpid != "" && cpid != undefined && cpid != null) {
      cpId = cpid
      this.setState({
        aactivePage: 1,
        apageSize: this.state.apageSize,
        acpId: cpid
      })
    }
    if (text == "Clear") {
      this.setState({
        acpId: cpid
      })
    }
    document.getElementById("loader_image_div").style.display = "block";
    var errors = {};
    let url = baseUrl.URLPath + servicesUrl.getOcpptagsCPAssociated + "?ocppTagId=" + this.state.fields["idTag"] + "&cpid=" + cpId + "&fromTime=" + this.state.afromDateApi + "&toTime=" + this.state.atoDateApi + "&pageSize=" + this.state.apageSize;
    fetch(url,
      {
        method: 'GET', // or 'PUT'
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),

        }
      }
    ).then(resp => {
      if (resp.status == 404) {
        this.hideLoder();
        return resp.json();
      }
      else if (resp.status == 500) {
        this.hideLoder();
        alert("internal server error 500 error")
      } else if (resp.status == 400) {
        this.hideLoder();
        alert("Bad request 400 error")
      } else if (resp.status == 200) {
        return resp.json();
      }
    }).then((response) => {
      if (response != undefined) {
        var noWallboxExists1 = 1;
        if (cpId != "" && response.data.length == 0) {
          localStorage.setItem("noWallboxExists", response.count.count);
        }

        if (response.data.length == 0) {
          setTimeout(() => {
            this.setState({
              // associatedCPList: [],
              // anoOfRecords: response.count.count,
            }, () => {
              this.hideLoder();
            });
          }, 100);
        } else {
          var chargePoint;
          chargePoint = Array.from(Object.values(response.data), cp => cp.chargePointId)
          var totalcount = "";
          if (countValue == false) {
            totalcount = this.state.anoOfRecords
          } else {
            totalcount = response.count.count
          }
          setTimeout(() => {
            this.setState({
              anoOfRecords: totalcount,
              noWallboxExists: noWallboxExists1,
              aprevPageFirstCreTime: this.state.curPageFirstCreTime,
              acurPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              acurPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              associatedCPList: response.data,
            }, () => {
              this.hideLoder();
            });
          }, 100);


        }
      }
    })
      .catch(error =>
        setTimeout(() => {
          alert('Error:' + error)
          document.getElementById("loader_image_div").style.display = "none";
        }, 2000)
      );
  }

  getRFIDCPunassociatedlist(countValue, cpid, text) {
    var cpId = "";
    
    if (cpid != "" && cpid != undefined && cpid != null) {
      cpId = cpid
      this.setState({
        activePage: 1,
        pageSize: this.state.pageSize,
        cpId: cpid
      })
    }
    if (text == "Clear") {
      this.setState({
        cpId: cpid
      })
    }
    var errors = {};
    document.getElementById("loader_image_div").style.display = "block";

    let url = baseUrl.URLPath + servicesUrl.getOcpptagsCPUnassociated + "?ocppTagId=" + this.state.fields['idTag'] + "&cpid=" + cpId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&pageSize=" + this.state.pageSize;
    fetch(url,
      {
        method: 'GET', // or 'PUT'
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),

        }
      }
    ).then(resp => {
      if (resp.status == 404) {
        return resp.json();
      }
      else if (resp.status == 500) {
        alert("internal server error 500 error")


      } else if (resp.status == 400) {
        alert("Bad request 400 error")


      } else if (resp.status == 200) {
        return resp.json();
      }
    }).then((response) => {


      if (response != undefined) {

        var noWallboxExists1 = 1;
        if (cpId != "" && response.data.length == 0) {
          localStorage.setItem("noWallboxExists", response.count.count);
        }

        if (response.data.length == 0) {
          setTimeout(() => {
            this.setState({
              // associatedCPList: [],
              // anoOfRecords: response.count.count,
            }, () => {
              this.hideLoder();
            });
          }, 100);
        
        } else {
          var totalcount = "";
          if (countValue == false) {
            totalcount = this.state.noOfRecords
          } else {
            totalcount = response.count.count
          }
          setTimeout(() => {
            this.setState({
              allCPList: response.data,
              noOfRecords: totalcount,
              noWallboxExists: noWallboxExists1,
              prevPageFirstCreTime: this.state.curPageFirstCreTime,
              curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
            }, () => {
              this.hideLoder();
            });
          }, 100);
        
        }
      }
    })
      .catch(error =>
        setTimeout(() => {
          alert('Error:' + error)
          document.getElementById("loader_image_div").style.display = "none";
        }, 2000)
      );

  }





  saveRFIDCP() {

    let url = baseUrl.URLPath + servicesUrl.getOcpptagUpdateCPAssociation + "?ocppTagId=" + this.state.fields['idTag'];
    let data = { "associatedCPList": this.state.selectedItems, "deAssociatedCPList": this.state.deletedWallboxes };

    let valid = true;

    if ((this.state.RfidAccessVal == true && this.state.isFreeChargeEnabled == false) > 0) {
      if ((!this.state.selectedItems.length > 0 && (!this.state.deletedWallboxes.length > 0 && !this.state.associatedCPList.length > 0)) || (!this.state.selectedItems.length > 0 && this.state.associatedCPList.length == 1 && this.state.deletedWallboxes.length > 0)) {
        
        $('.error_cpids').text(this.props.t('select_atleast_onecharge_point'));
        valid = false;
      } else {
        valid = true;
        $('.error_cpids').text("");

      }
    }

    if (valid) {
      document.getElementById("loader_image_div").style.display = "block";
      fetch(url,
        {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }
        }
      ).then(resp => {
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
          }, 100);
        } else if (resp.status == 404) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            return resp.json();
          }, 100);
        }
        else if (resp.status == 500) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            alert("internal server error 500")
          }, 100);

        } else if (resp.status == 400) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            alert("Bad request")
          }, 100);
        } else if (resp.status == 200) {
          return resp.json();
        }
      }).then((response) => {
        ;
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          if (response != undefined) {
            this.togglerfidSuccess();
          }
        }, 100);

      })
        .catch(error =>
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            alert('Error:' + error)
          }, 100));
    }
  }


  getCustomization() {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.LoginPath + "/api/admin/v1.0/account/tenant/customization?orgName=" + tenant;
    //localhost:8080/api/preferences/security/api/admin/v1.0/account/tenant/customization
    fetch(url, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      return resp.json();
    }).then(response => {
      let accessVal = false;
      response.map((operation, index) => {
        if (operation.operation == 'Charging Session') {
          operation.subOperations.map((subOperations, index) => {
            if (subOperations.name == "RFID Access Control") {
              accessVal = subOperations.access;
            }
          })
        }
      })
      this.setState({
        getOperationsList: response,
        RfidAccessVal: accessVal
      })
    })
  }

  handleWallboxChange(e) {
    this.setState({
      wallbox: e.target.value
    }, () => {
      this.clearWallboxerror();
      if (this.state.wallbox.length == 0) {
        this.getRFIDCPlist();
      }
    });
  }

  clearWallboxerror() {
    this.setState({
      noWallboxExists: ''
    })
  }

  FindRfidCp(e) {
    e.preventDefault();

    if (this.state.wallbox == "") {
      this.setState({
        noWallboxExists: "Please enter the charge point."
      })
    } else {
      this.getRFIDCPlist();
      this.setState({
        noWallboxExists: ""
      })
    }
  }

  getIsAllSitesSelected() {
    const { selectedItems, allCPList } = this.state;
    let isAllChecked = false;
    if (allCPList && selectedItems) {
      for (let i = 0; i < allCPList.length; i++) {
        if (selectedItems.some(e => e == allCPList[i]) == true) {
          isAllChecked = true;
        } else {
          isAllChecked = false;
          break;
        }
      }
    } else {
      isAllChecked = false;
    }
    return isAllChecked;
  }

  onUpdateSiteSelectAll(event) {
    document.getElementById("loader_image_div").style.display = "block";
    const isChecked = event;
    $('.error_cpids').text("");
    if (isChecked) {
      var selectedItems = [];
      // this.state.allCPList.map((site,index)=>{
      selectedItems = [...this.state.allCPList];
      // });
      setTimeout(() => {
        this.setState({ selectedItems: selectedItems, disabledbtn: "", deletedWallboxes: [] }, () => {
          document.getElementById("loader_image_div").style.display = "none";
        });
      }, 2000);

    } else {
      var deletedWallboxes = [];
      // this.state.allCPList.map((site,index)=>{
      deletedWallboxes = [...this.state.allCPList];
      // });
      setTimeout(() => {
        this.setState({ selectedItems: [], deletedWallboxes: deletedWallboxes, disabledbtn: "" }, () => {
          document.getElementById("loader_image_div").style.display = "none";
        });
      }, 2000);

    }
  }


  // 
  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
        activePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getRFIDCPunassociatedlist(false);
      });
    } else {
      //next
      
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        activePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getRFIDCPunassociatedlist(false);
      });
    }
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  ahandlePageChange(pageNumber) {
    if (pageNumber < this.state.aactivePage) {
      //prev
      this.setState({
        aactivePage: pageNumber,
        afromDateApi: this.state.acurPageFirstCreTime,
        atoDateApi: this.state.alistOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getRFIDCPlist(false);
      });
    } else {
      //next
      
      let _datesArrayLength = this.state.alistOfFirstCreatedtimes.length;
      this.setState({
        aactivePage: pageNumber,
        afromDateApi: '',
        atoDateApi: this.state.acurPageLastCreTime,
        aprevPageFirstCreTime: this.state.acurPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.acreatedDatesArray(this.state.acurPageFirstCreTime);
        }
        this.getRFIDCPlist(false);
      });
    }
  }

  acreatedDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.alistOfFirstCreatedtimes, dateTime];
    this.setState({
      alistOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }



  removeSelectedItem(item, index) {
    const allItems = [...this.state.selectedItems];
    allItems.splice(index, 1);
    this.setState({ ...this.state, selectedItems: allItems });
  }

  removeUnSelectedItem(item, index) {
    const allItems = [...this.state.deletedWallboxes];
    allItems.splice(index, 1);
    this.setState({ ...this.state, deletedWallboxes: allItems });
  }

  //show entries change
  ashowEntriesChange() {
    var entries = document.getElementById("ashowEntries").value;
    this.setState({
      aactivePage: 1,
      apageSize: entries,
      afromDateApi: "",
      atoDateApi: ""
    }, () => {
      this.getRFIDCPlist(true, this.state.acpId);
    })
  }

  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: "",
      toDateApi: ""
    }, () => {
      this.getRFIDCPunassociatedlist(true, this.state.cpId);
    })
  }




  render() {
    const{t}=this.props
    let user = this.state.user;
    const ref = React.createRef();

    var yesterday = Datetime.moment().subtract(1, 'day');
    var valid = function (current) {
      return current.isAfter(yesterday);
    };
    return (
      <>
        <main class="content-div" >
          <p>{t('Authorization')}
            <div className="breadcrumb_div">
              {t('charge_point')} &gt; <Link to="/Ocpptags"><span>{t('Authorization')}</span></Link > &gt; <span className="breadcrumb_page">{t('view')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            {/* breadCrumb */}
            {/* <div className="breadcrumb_div"><a>Charge Point > <Link to="/Ocpptags" > <span className="">OCPP Tags</span></Link> ></a>View</div> */}
            {/* end breadCrumb */}
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button class="pull-right btn_primary" type="button" data-test="button" onClick={this.backToList} >
                    <i className="fas fa-angle-left mr-2"></i>  {t('back')}
                  </button>
                </div>
              </div>
            </div>
            <form
              className='needs-validation'
              onSubmit={this.toggle1}
              noValidate
            >
              <div className="row mb-4 mt-3">
                <div className="col-sm-6">
                  <div className="content-innerdiv">
                    <div className="" >
                      <label>
                        {t('idTag')}:
                      </label>
                      <label >
                        {this.state.fields["idTag"]}
                      </label>
                    </div>

                    <div className="">
                      <label>
                        {t('additional_note')}:
                      </label>
                      <label>
                        {this.state.fields["note"]}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="content-innerdiv">

                    <div className=" ">
                      <label>
                         {t('expiry_date_time')}:
                      </label>
                      <label>
                        {
                          (this.state.startDate1 != null && this.state.startDate1 != undefined && this.state.startDate1 != "") ?
                            <><i className="fas fa-calendar-alt fa-sm mr-1"></i> {moment(this.state.startDate1).format("DD MMM YYYY")} <i className="far fa-clock fa-sm mr-1"></i>{moment(this.state.startDate1).format("HH:mm")}</>
                            : ""
                        }
                      </label>
                    </div>
                    <div className=" ">
                      <label>
                        {t('rfid_tag_type')}:
                      </label>
                      <label>
                        {(this.state.fields.tagType != null && this.state.fields.tagType != "") ? this.state.fields.tagType : "other"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 text-right">
                  <Link to={{
                    pathname: '/editTag',
                    state: this.state.id,
                  }}>
                    {hasPermission(user, ['OCPPTags_W']) &&
                      <button className="refresh-btn mt-3 mr-1" style={{ "display": this.state.hideActionBtn }} onClick={this.editTag} id="editBtn" type="button" data-test="button">{t('edit')} <i class="fas fa-pencil-alt ml-2"></i></button>
                      //     <MDBBtn color="primary" className="mt-5 mr-3" style={{"display":this.state.hideActionBtn}} onClick={this.editTag}  id="editBtn" >
                      //    Edit
                      // </MDBBtn>
                    }
                  </Link>

                </div>


                {(this.state.RfidAccessVal == true && this.state.isFreeChargeEnabled == false) > 0 &&
                  <>
                    <div className="col-md-12 mb-3">
                      <span class=" form-title">{t('associated_charge_points')}:</span>
                    </div>
                    <div className="col-md-12" >
                      <div className='row'>
                        <div className="col-md-4 mt-1">
                          <CpSearch
                            getData={this.getRFIDCPlist}
                            pagename="associatedRfidTag"
                            id={this.state.fields['idTag']}
                            noWallboxExists={this.state.noWallboxExists}
                          >
                          </CpSearch>
                        </div>

                        <div className="col-md-8">
                          <div className="pull-right">
                            <label className="pull-left sub-text">{t('show_entries')}</label>
                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv p-rel cpselectdiv" >
                              <Form.Control as="select" className="showentry_sel custom_selectBox" id="ashowEntries" onChange={this.ashowEntriesChange}>
                                <option>10</option>
                                <option>20</option>
                                <option selected="selected">30</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </div>

                        {/* <div className="col-md-3 mt-2">
                          <div className="d-flex align-items-center">
                            {
                              this.getIsAllSitesSelected() ? <><i class="far fa-square" onClick={this.onUpdateSiteSelectAll.bind(this, false)}></i> <span className='ml-2'>Unselect All</span></> : <><i class="far fa-square" onClick={this.onUpdateSiteSelectAll.bind(this, true)}></i> <span className='ml-2'>Select All</span></>
                              //   <Form.Check
                              //   custom
                              //   type="checkbox"
                              //   id="siteAccessCheck"<i class="far fa-square"></i>
                              //   label={"Unselect All"}
                              //   className="ml-5"
                              //   checked={false}
                              //   onChange={this.onUpdateSiteSelectAll}
                              // />
                              // :
                              // <Form.Check
                              //   custom
                              //   type="checkbox"
                              //   id="siteAccessCheck"
                              //   label={"Select All"}
                              //   className="ml-5"
                              //   checked={false}
                              //   onChange={this.onUpdateSiteSelectAll}
                              // />
                            }

                          </div>
                        </div> */}

                      </div>
                    </div>
                    <div className="col-md-12 mt-1">
                      {/* <div className="mt-3">
                        <span className="error_msg w3-animate-top error_cpids" style={{ color: "red" }}></span></div> */}
                      <div className='content-innerdiv hauto'>
                        <div className='row rfidDiv'>
                          <div className="col-md-8 lightBlue">
                            <label><b>{t('rfid_tag_valid_for_charge_point')}:</b>
                            </label>
                            <div className='row'>
                              {this.state.associatedCPList.map((el, index) => {
                                return (
                                  <div className="col-md-3 pl-0">
                                    <Form.Check
                                      className=""
                                      custom
                                      id={`${el.chargePointId}_${index}`}
                                      type="checkbox"
                                      checked={this.isaItemSelected(el.chargePointId)}
                                      onChange={(event) => this.aupdateCheckboxSelection(event, el.chargePointId)}
                                      label={el.chargePointId}

                                    />
                                  </div>
                                )
                              })
                              }
                            </div>
                          </div>

                          {this.state.deletedWallboxes.length > 0 &&
                            <div className="col-md-4 lightBlue">
                              <label><b>{t('selected_charge_point')}:</b>
                              </label>
                              <div className="row">
                                <div className="col-md-12">
                                  {
                                    this.state.deletedWallboxes.map((el, index) =>
                                      <span key={index} className="cpChargePointSspan">
                                        {el} <i class="fa fa-times" onClick={() => this.removeUnSelectedItem(el, index)}></i>
                                      </span>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3 mb-1 text-right">
                          <Pagination
                            activePage={this.state.aactivePage}
                            itemsCountPerPage={this.state.apageSize}
                            totalItemsCount={this.state.anoOfRecords}
                            onChange={this.ahandlePageChange.bind(this)}
                            prevPageText={t('prev')}
                            nextPageText={t('next')}
                            itemClassPrev="prevBtn"
                            pageRangeDisplayed="1"
                            activeClass="activeLi active"
                            itemClassNext="nextBtn"
                            disableInitialCallback={true}
                            disabledClass="disabled disabledtextColor"
                            hideFirstLastPages={true}
                          />
                        </div>
                      </div>


                    </div>

                    <div className="col-md-12 mb-3 mt-3">
                      <span class=" form-title">{t('charge_points')}:</span>
                    </div>

                    <div className="col-md-12" >
                      <div className='row'>
                        <div className="col-md-4 mt-1">
                          <CpSearch
                            getData={this.getRFIDCPunassociatedlist}
                            pagename="deassociatedRfidTag"
                            id={this.state.fields['idTag']}
                            noWallboxExists={this.state.noWallboxExists}
                          >
                          </CpSearch>
                        </div>

                        <div className="col-md-8">
                          <div className="pull-right">
                            <label className="pull-left sub-text">{t('show_entries')}</label>
                            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv p-rel cpselectdiv" >
                              <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                                <option>10</option>
                                <option>20</option>
                                <option selected="selected">30</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </div>
                        {/* 
                        <div className="col-md-3 mt-2">
                          <div className="d-flex align-items-center">
                            {
                              this.getIsAllSitesSelected() ? <><i class="far fa-square" onClick={this.onUpdateSiteSelectAll.bind(this, false)}></i> <span className='ml-2'>Unselect All</span></> : <><i class="far fa-square" onClick={this.onUpdateSiteSelectAll.bind(this, true)}></i> <span className='ml-2'>Select All</span></>
                              //   <Form.Check
                              //   custom
                              //   type="checkbox"
                              //   id="siteAccessCheck"<i class="far fa-square"></i>
                              //   label={"Unselect All"}
                              //   className="ml-5"
                              //   checked={false}
                              //   onChange={this.onUpdateSiteSelectAll}
                              // />
                              // :
                              // <Form.Check
                              //   custom
                              //   type="checkbox"
                              //   id="siteAccessCheck"
                              //   label={"Select All"}
                              //   className="ml-5"
                              //   checked={false}
                              //   onChange={this.onUpdateSiteSelectAll}
                              // />
                            }

                          </div>
                        </div> */}

                      </div>
                    </div>
                    <div className="col-md-12 mt-1">
                      <div className="mt-3"><span className="error_msg w3-animate-top error_cpids" style={{ color: "red" }}></span></div>
                      <div className='content-innerdiv hauto'>
                        <div className='row rfidDiv'>
                          <div className="col-md-8 lightBlue">
                            <label><b>{t('charge_point')}:</b>
                            </label>
                            <div className="row">
                              {this.state.allCPList.map((el, index) => {
                                return (
                                  <div className="col-md-3 pl-0">
                                    <Form.Check
                                      className=""
                                      custom
                                      id={`${el.chargePointId}_${index}`}
                                      type="checkbox"
                                      checked={this.isItemSelected(el.chargePointId)}
                                      onChange={(event) => this.updateCheckboxSelection(event, el.chargePointId)}
                                      label={el.chargePointId}

                                    />
                                  </div>
                                )
                              })
                              }
                            </div>
                          </div>
                          {this.state.selectedItems.length > 0 &&
                            <div className="col-md-4 lightBlue">
                              <label>
                                {/* <b>Selected Charge Point:</b> */}
                                <b>{t('rfid_tag_valid_for_charge_point')}:</b>
                              </label>
                              <div className="row">
                                <div className="col-md-12">
                                  {
                                    this.state.selectedItems.map((el, index) =>
                                      <span key={index} className="cpChargePointSspan">
                                        {el} <i class="fa fa-times" onClick={() => this.removeSelectedItem(el, index)}></i>
                                      </span>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3 mb-1 text-right">
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.noOfRecords}
                            onChange={this.handlePageChange.bind(this)}
                            prevPageText="Prev"
                            nextPageText="Next"
                            itemClassPrev="prevBtn"
                            pageRangeDisplayed="1"
                            activeClass="activeLi active"
                            itemClassNext="nextBtn"
                            disableInitialCallback={true}
                            disabledClass="disabled disabledtextColor"
                            hideFirstLastPages={true}
                          />
                        </div>
                      </div>


                    </div>

                    <div className='col-md-12 mt-2'>
                      <button type="button" data-test="button" disabled={this.state.disabledbtn} onClick={this.saveRFIDCP.bind(this)} className={this.state.disabledbtn == "true" ? "btn_primary mr-1  pull-right btn_cancel" : "btn_primary mr-1 pull-right "} >
                        {t('save')} <i class="fas fa-save ml-2"></i>
                      </button>
                    </div>
                  </>
                }


              </div>

            </form>

          </div>
          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>
          <MDBModal isOpen={this.state.isrfidSuccess} toggle={this.togglerfidSuccess} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglerfidSuccess}>Success</MDBModalHeader>
            <MDBModalBody>
              {t('rfid_association_charge_points_updated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button className="btn_primary" data-test="button" type="button" onClick={() => { window.location.reload(); }}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal isOpen={this.state.modalsession} toggle={this.tagOk} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

        </main>
      </>
    );
  }
}

export default withTranslation()(Ocppnewtag);




