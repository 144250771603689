import React from 'react';
import ReactDOM from "react-dom";
import Chart from "react-apexcharts";
import * as baseUrl from '../constants';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import StatusNotificationDescription from './statusNotificationDescription';
import {Link} from 'react-router-dom';
import '../cpDashboard.css';
import servicesUrl from '../../common/servicesUrl';
class statusNotificationGraph extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
              userValue: [],
              user_group: [],
              user_value: [],
              startDate: null,
              descList: "",
              endDate: null,
              value: [null, null],
              series: [
                {
                  data: [],
                },
              ],
              descriptions: [],
              options: {
                chart: {
                  events: {
                    dataPointSelection: (event, chartContext, config) => {
                      localStorage.setItem("errorIndex",config.dataPointIndex);
                      localStorage.setItem("statusNotificationDesc",JSON.stringify(this.state.descList));
                      window.location.href = "/statusNotificationDescription";
                    }
                  },

                  width: "100%",
                  type: "bar",
                  height: 350,
                  toolbar: {
                    tools: {
                      download: true,
                      selection: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      reset: false,
                      pan: false,
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: "20%",
                    endingShape: "rounded",
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ["transparent"],
                  fill: "#00529e",
                },
                xaxis: {
                  categories: [],
                },
                fill: {
                  opacity: 1,
                },
              },
            };
          this.onChange = this.onChange.bind(this);
        }



        //  api to get charging session chart
        componentDidMount(){
           var today = new Date(),
           date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
           var preDate = new Date();
           preDate.setDate(preDate.getDate() - 7); // add -7 days to your date variable 
           this.state.startDate = moment(preDate).format("YYYY-MM-DD").toUpperCase();
           this.state.endDate = date; 
           var garphInitData = [this.state.startDate,this.state.endDate]
           this.setState({
             value:garphInitData
           })
           this.getStatusNotificationData(today,preDate);
        }

        calcDate(date1,date2) {
          var dateOne = new Date(date1); //Year, Month, Date    
          var dateTwo = new Date(date2); //Year, Month, Date    
          var diff = "";
          if (dateOne > dateTwo) {    
               diff = Math.floor(dateOne.getTime() - dateTwo.getTime()); 
           }else {    
               diff = Math.floor(dateTwo.getTime() - dateOne.getTime()); 
           }    
            var day = 1000 * 60 * 60 * 24;
            var days = Math.floor(diff/day);
            var months = Math.floor(days/31);
            var years = Math.floor(months/12);
            var dateArray = [days,months,years]
            return dateArray
          }
      

        getStatusNotificationData(sDate,eDate){
          
          var a = this.calcDate(sDate,eDate);
          var week = a[0]/7;
          week = Math.round(week); 
          var month = a[1];
          month = Math.round(month); 
          var apiDateVar = "";
          if(month==0){
            apiDateVar = week+"week";
          }else{
            apiDateVar = month+"month";
          }
          var user_group = [];
          var user_value = [];
          var descList=[];
         
          let chargePointId = localStorage.getItem("chargerId");
        
          var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPErrorGroup + "?cpid="+chargePointId+"&fromTime="+apiDateVar;
          //localhost:8080/api/home/chargepoint/{chargepointid}/errorgroup?fromTime={1week/2week....}
           fetch(url,{
            method: "GET",
            headers: {
                  "content-type":"application/json",
                  "Authorization":localStorage.getItem("Authorization")
                }
            })
            .then((resp) => {
              if(resp.status==401){
                this.togglesession();
          
                localStorage.clear();
               
              }
              if(resp.status==200){
                return resp.json();
              }
            }) 
            .then((response) =>{
              ;
              if(response!=undefined){
              this.state.descList=response;
              for(var i=0; i<response.length; i++){
                user_group.push(response[i]["group"])
                user_value.push(response[i]["count"])
                descList.push(response[i]["descList"])
              }
              this.state.series[0].data =  user_value;
              var data_options =  {
                    xaxis: {
                          categories:user_group
                        },
                        tooltip: {
                          fixed: {
                            enabled: true
                    
                          },
                       

                          custom: ({ dataPointIndex })=> {
                            let desc=this.state.descriptions[dataPointIndex];
                            return (
                              `<div id="tooltipheader">${user_group[dataPointIndex]}</div>
                              <div id="tooltipbody">${desc.map((value,index)=>{
                                if(index<3){
                                return `<span ><li>message=${value}</li></span>  `
                                }
                              })}</div>
                              
                             ${(desc.length!=undefined && desc.length!=null && desc.length>3)?
                              `<div id="tooltipfooter"><span className="tooltipdescription"> <b>Click on bar graph to view complete description...</b></span> </div>`:""
                          
                            
                            }
                           
                            
                             
                             
                         
                              `
                            );
                          }
                        } 
                      };
                  }
            this.setState({options:data_options,descriptions:descList})
  
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
        }

      


          // date picker onchange function
          onChange(value, event){
            if(value != null && value != ""){
                var startDate =  moment(value[0]).format("YYYY-MM-DD").toUpperCase();
                var endDate   =  moment(value[1]).format("YYYY-MM-DD").toUpperCase(); 
                this.setState({
                  value:value,
                })
                this.state.startDate = startDate;
                this.state.endDate   = endDate;
                this.getStatusNotificationData(value[0],value[1]);
            }else{
                  this.setState({
                    value:value
                  })
              }
        }
       
        render() {
                return (
                    <>
                    <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                        <div class="content-box">
                            <div class="dashboard-subtitle">
                                Status Notification Groups, Counts  
                            </div>
                            <div className="mt-2 mb-5">
                                <DateRangePicker
                                  onChange={this.onChange}
                                  value={this.state.value}
                                  selectRange = "true" 
                                  format = "dd-M-yyyy"
                                  dayPlaceholder = ""
                                  yearPlaceholder = ""
                                  monthPlaceholder = ""
                                  nativeInputAriaLabel ="Date"
                              />
                            </div>
                            <div className="mt-3 bottom-pos pb-3 pr-3 mr-15 w-100">
                                <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="bar"
                                    width="100%"
                                    height="350"
                                   />
                            </div>
                        </div>
                    </div>
                    </>
                )
            };
}

export default statusNotificationGraph;