import React from 'react';
import { MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Form,Table,Check } from 'react-bootstrap';
import Header from './header';

import {Link} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as baseUrl from './constants';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { hasRole, hasPermission } from './auth';
import {Typeahead} from 'react-bootstrap-typeahead';
import $, { get } from "jquery";
import { withTranslation } from 'react-i18next';
const history = createBrowserHistory();

class ChargingProfile extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          cpIdSorting: false,
          errors: {},
          selectFindStatus: false,
          intial_data:[],
          saveSelectedProfile:"",
          multiple:false,
          options:[],
          cpProfiles:"",
          selectDataArray:[],
          page_title:" Charging Profile",
          activePage: 1,
          noOfRecords:'',
          data:new Map(),
          pageSize:"10",
          pageNo:1,
          modalsession:false,
        

          user:{
            permissions: [
                 
                ]
            },
          items: [
                    {
                      "stackLevel": "",
                      "chargingProfilePurpose": "",
                      "chargingProfileKind":"",
                      "recurrencyKind":"",
                      "chargingSchedule":"",
                      "validFrom":"",
                      "validTo":"",
                      "transactionId":"",
                      "chargingProfileName":"",
                    }
                  ]
              }
          this.addNew = this.addNew.bind(this);
          this.togglesession = this.togglesession.bind(this);
          this.handlePageChange = this.handlePageChange.bind(this)
          this.showEntriesChange=this.showEntriesChange.bind(this);
          this.sessiontagOk = this.sessiontagOk.bind(this);
          this.chargingProfileChange=this.chargingProfileChange.bind(this);
          this.getChargingProfile = this.getChargingProfile.bind(this);
          this.handleKeyDown=this.handleKeyDown.bind(this);
          this.getprofileNames=this.getprofileNames.bind(this);
          this.FindCpName=this.FindCpName.bind(this);
          this.dynamicSort = this.dynamicSort.bind(this);






          
}
FindCpName(ref,event){
  
  this.state.saveSelectedProfile = ref.current.inputNode.value;
  this.state.selectFindStatus = true;

  this.getChargingProfile(this.state.pageSize,this.state.activePage,this.state.saveSelectedProfile);
  if(this.state.saveSelectedProfile == null || this.state.saveSelectedProfile ==""){
    this.state.errors["selectChargepoint"] = this.props.t('please_select_charging_profile');
    $("#notexists").text(" ");
      
  }else{
    this.state.errors["selectChargepoint"] = " ";
    this.getChargingProfile(this.state.pageSize,this.state.activePage,"click");
 
}
if(this.state.selectDataArray.length==0){
  if(this.state.saveSelectedProfile == null || this.state.saveSelectedProfile ==""){
  let errors={}
  errors["selectChargepoint"] = this.props.t('please_select_charging_profile');
 
  this.setState({errors:errors});
  }else{
    let errors={}
    errors["selectChargepoint"] = "";
   
    this.setState({errors:errors});
  }
}
}
getprofileNames(event){
  
  var crgProfiles = event.target.value;
  this.setState({
    cpProfiles:crgProfiles
  })
  if(crgProfiles.length==0){
     this.getChargingProfile(this.state.pageSize,this.state.activePage,this.state.saveSelectedProfile)
     
     this.setState({
       items:this.state.items,
     });
   }
   var errors={};
   errors["idTagNotExists"] = "";
   
   this.setState({errors:errors});
 }

async togglesession(){
 
  this.setState({
  modalsession: !this.state.modalsession
  });
}

sessiontagOk(){
  history.push('/');
  window.location.reload();
}

showEntriesChange(){
  var entries=document.getElementById("showEntries").value;
  // this.state.data.clear();
  // this.setState({pageSize:entries});
  this.state.pageSize=entries;
  this.state.activePage=1;
  this.getChargingProfile(entries,1);
}


sessiontagOk(){
  history.push('/');
  window.location.reload();
}


// function for add new chrge profile
addNew(){
  this.props.history.push('/addChargingProfile');
};

componentDidMount(){
  this.state.user.permissions = localStorage.getItem("roleAccess");
  // permission related code
  if(this.state.writePermission==true){
    this.setState({
       hideActionBtn:"inline-block"
    })
  }
   
    this.getChargingProfile(this.state.pageSize,1);
    $("#nochargeexists").hide();
}

handlePageChange(pageNumber) {
   if(pageNumber !=this.state.activePage){
    var data=this.state.data;
    var pageData = data.get(pageNumber);
    var pageData = undefined;
    if(pageData == undefined){

    var pageData=data.get(pageNumber);
    var pageData = undefined;
    if(pageData==undefined){
    this.setState({activePage: pageNumber});
    this.getChargingProfile(this.state.pageSize,pageNumber);

    }else{
      this.setState({activePage: pageNumber});
      this.setState({dataArray:pageData})
    }
  }
}
}

async getChargingProfile(pageSize,pageNo,action){
  var timeZone=  localStorage.getItem("timezone");
  var errors = {};
  
  if(this.state.selectFindStatus == true){
    var url = baseUrl.URLPath + "chargingprofiles?pageNo="+1+"&pageSize="+pageSize+"&chargingProfileName="+this.state.saveSelectedProfile;

  }
  else{
  var url = baseUrl.URLPath + "chargingprofiles?pageNo="+pageNo+"&pageSize="+pageSize+"&chargingProfileName="+this.state.saveSelectedProfile;

}
  await fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization":localStorage.getItem("Authorization")
    }
    })
    .then((resp) => {
    
      if(resp.status==401){
        this.togglesession();
        localStorage.clear();
      }
      if(resp.status==500){
        alert(this.props.t('internal_server_error'))
      }
      if(resp.status==200){
        return resp.json();
      } 
    }) 
    .then((response) =>{
  
      if(response!=undefined){
      
          if(response.count.count==0){
            if(action=="click" || action=="enter"){ 
              $("#notexists").text(this.props.t('charging_profile_not_exists'));
                              }
                              this.setState({
                                items:[],
                                noOfRecords:0,
                                selectDataArray:[]
                               });
          }else{
          this.setState({
           items:response.chargingProfiles,
           noOfRecords:response.count.count,
           selectDataArray:response.chargingProfiles
          })
          if(action=="click" || action=="enter"){ 
            $("#notexists").text(" ");
                            }
        var data= this.state.data;
        data.set(pageNo,response.count.count)

        var option = [];
        for(var i = 0; i < this.state.items.length; i++)
        {
          //alert(this.state.items[i].serial_number);
          option.push(this.state.items[i].chargingProfileName);
          
        }
        
        this.setState({options:option});
      }
    }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
}

chargingProfileChange(chargingProfileName){

  this.state.selectFindStatus = true;
  this.state.saveSelectedProfile = chargingProfileName;
  this.setState({saveSelectedProfile:chargingProfileName});
if(chargingProfileName.length==0){
  
  this.getChargingProfile(this.state.pageSize,this.state.activePage)
  this.setState({
    items:this.state.items
  });
}else{
  let errors = {};
  errors["idTagNotExists"] = "";
    this.setState({
        errors:errors
    }); 
    this.getChargingProfile(this.state.pageSize,this.state.activePage,this.state.saveSelectedProfile);
}
  
  }

 
      // search filter 
handleKeyDown(ref,e){

  var serial_no = e.target.value;
  var key = e.key;
  var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false); 
  var getList = this.state.intial_data;
  
if(serial_no!=0){
  this.state.errors["selectChargepoint"] = "";
}
  

  if( key == "Backspace" || key == "Delete" ){
    this.state.errors["selectChargepoint"] = "";
    $("#notexists").text("");
        var x=e.target.value
        var sno=x.slice(0,x.length-1)
        if(sno==""){
         
          this.state.saveSelectedProfile=sno;
    
          this.getChargingProfile(this.state.pageSize,this.state.activePage,this.state.saveSelectedProfile);
        }

    }
    var sno=serial_no.slice(0,serial_no.length-1)
    this.getChargingProfile(this.state.pageSize,this.state.activePage,this.state.saveSelectedProfile);
      if(e.keyCode == 13){
      
   
 
        this.FindCpName(ref,e);
        $("#rbt_id").hide();
      }
      if((e.keyCode == 86 && ctrl)){
      
        this.getChargingProfile(this.state.pageSize,this.state.activePage,this.state.saveSelectedProfile);
      }
    
     
      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
        break;
        default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
        
            e.preventDefault();
    
            return false;
      }
        break;
      
    }
   
  }
  ClearChargepoint(ref,event){

    this.state.selectFindStatus = false;

   let errors={}
   this.state.errors["selectChargepoint"] = "";
   $("#notexists").text("");
    this.state.saveSelectedProfile = "";
    this.state.pageSize=10;
        this.state.activePage=1;
        document.getElementById("showEntries").value=10;
     this.getChargingProfile(this.state.pageSize,this.state.activePage, this.state.saveSelectedProfile);
      this.setState({
        items:this.state.items,errors:errors
      });
      ref.current.clear()
      // this.typeahead.current.state.selected= []
      this.setState({
        idTags:""
      })
  }
      
//function for sorting table data on click
ascendingOrder(){
  // this.setState({items : this.state.items.reverse()})

  if(this.state.cpIdSorting == false){
  this.state.items.sort(this.dynamicSort("chargingProfileName"));
  this.state.cpIdSorting = true;
     this.setState({items : this.state.items.sort(this.dynamicSort("chargingProfileName"))})
}
 else{
  this.state.items.sort(this.dynamicSort("chargingProfileName"));
  this.state.cpIdSorting = false;
     this.setState({items : this.state.items.reverse()});
 } 
}
stackLevelSorting(){
  if(this.state.cpIdSorting == false){
    this.state.items.sort(function (a, b) {
      return a.stackLevel - b.stackLevel;
    });
    this.state.cpIdSorting = true;
this.setState({items:this.state.items})
    }
    else{
      this.state.cpIdSorting = false;
      this.setState({items : this.state.items.reverse()});
     }
}
dynamicSort(property) {
  var sortOrder = 1;

  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a,b) {
      if(sortOrder == -1){
          return b[property].localeCompare(a[property]);
      }else{

          return a[property].localeCompare(b[property]);
      }        
  }
}
render() {
  const{t}=this.props
  let user = this.state.user;
  const ref = React.createRef();

  return (
    <>
   
    <main class="content-div" >
    <p>{t('chargingProfileId')}
              <div className="breadcrumb_div">
              {t('charge_point')} &gt; <span className="breadcrumb_page">{t('chargingProfileId')}</span>
              </div>
          </p>
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div">Charge Point > Charging Profile</div> */}
          {/* page title */}
          {/* <p>Reservations</p> */}
          {/* end page title */}
          {/* page menu bar */}
          <div className="row menubar">
              <div className="col-md-12 ">
                  <div className="">
                  { this.props.ChargingProfile_W && 
                    <button onClick={this.addNew} type="button" data-test="button">
                      <i className="fa fa-plus">
                      </i>
                      <span>{t('add')}</span>
                   </button> 
                   }
<div className="pull-right">
<label className="sub-text pull-left">{t('show_entries')}</label>
            <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                <Form.Control as="select" className="showentry_sel custom_selectBox"  id="showEntries" onChange={this.showEntriesChange}> 
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                </Form.Control>
             </Form.Group>
             
             </div>

                    {/* <button onClick={this.toggle1}>
                      <i className="fas fa-trash">
                      </i>
                      <span>Delete</span>
                    </button>  */}
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-4  pull-right">
            <div class="input-group">
            <i className="fas fa-search fa-sm input-searchIcon"></i>
              <Typeahead
                 labelKey="name"
                 className="w_80"
                
                 multiple={this.state.multiple}
                 options={this.state.options}
                 minLength={3}
                 placeholder={t('choose_charging_profile')}
                 onKeyDown ={this.handleKeyDown.bind(this,ref)}
                  onChange={this.chargingProfileChange.bind(this)}
                  // onFocus={this.ClearChargepoint.bind(this,ref)} 
                  onBlur={this.getprofileNames}
                  id="rbt_id"
                  ref={ref}
              />
                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearChargepoint.bind(this,ref)}>
                  <span aria-hidden="true">×</span>
                </button>
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.FindCpName.bind(this,ref)} >{t('find')}</button>
                </div>
                {/* <MDBBtn color='primary' type="submit" className="btn btn-primary sm_btn btn-sm mt-1 pull-right" style={{color:"#fff"}} onClick={this.FindCpName.bind(this,ref)}
 >
                    Find
                </MDBBtn> */}
               <span className="pull-left error_msg w3-animate-top mt-1" id="notexists" style={{color: "red"}}><span style={{color: "red"}}>{this.state.errors["chargePointNotExists"]}</span></span> 
                <span className="pull-left error_msg w3-animate-top mt-1"><span style={{color: "red"}}>{this.state.errors["selectChargepoint"]}</span></span> 
              </div>
              </div>
          </div>
          
          {/* end page menu bar  */}
          
          {/* table start */}
         <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                <th className="">{t('name')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                {/* <th>Transaction Id</th> */}
                <th className="">{t('stack_level')} <i class="fas fa-sort sortIcon" onClick={this.stackLevelSorting.bind(this)}></i></th>
                <th className="">{t('purpose')}</th>
                <th className="">{t('kind')}</th>
                <th className="">{t('recurrency_kind')}</th>
                <th className="">{t('valid_from')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">{t('valid_to')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                <th className="">{t('Charging Schedule')}</th>
              </tr>
            </thead>
            <tbody>
           {this.state.items.map((items, index) => {
             if(items.validFrom==null || items.validFrom==""){
              var Fromdate = "";
              var Fromdate1="";
            }else{
              var Fromdate = moment(items.validFrom).format("DD MMM YYYY");
              var Fromdate1 = moment(items.validFrom).format("HH:mm");
          
            }
            if(items.validTo==null || items.validTo==""){
              var Todate = "";
              var Todate1="";
            }else{
              var Todate = moment(items.validTo).format("DD MMM YYYY");
              var Todate1 = moment(items.validTo).format("HH:mm");
          
            }

           
           return(
              <tr>
               <td className=""><Link to={{
                  pathname:'/viewChargingProfile', 
                  state:items.chargingProfileId, 
                  }}>{items.chargingProfileName}
                </Link>
                </td>
                {/* <td>{items.transactionId}</td> */}
                <td className="">{items.stackLevel}</td>
                <td className="">{items.chargingProfilePurpose}</td>
                <td className="">{items.chargingProfileKind}</td>
                <td className="">{items.recurrencyKind}</td>
                <td className="wrap-word">{ Fromdate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {Fromdate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {Fromdate1} </div> </>}</td>
                <td className="wrap-word">{ Todate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {Todate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {Todate1} </div> </>}</td>
                <td className=""><Link to={{
                  pathname:'/viewchargingSchedule', 
                  state:items.scheduleId, 
                  }}>{items.chargingSchedule}
                </Link>
                </td>
            </tr>);
           })
            }
            </tbody>
          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right">
           <Pagination
             hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
            />
            </div>
          {/* End table  */}
          </div>
          </div>
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
             <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
        </MDBModal>
      </main>
 </>
  );
}
}

//export default ChargingProfile;
export default withTranslation()(ChargingProfile)