import React from 'react';
import { Line, Bar, Doughnut, Polar } from "react-chartjs-2";
import moment from 'moment';
import DatePicker from 'react-date-picker';
import * as baseUrl from '../constants';
import { dateformatinUTC, dateformatinUTCwS } from '../_methods';
import { withTranslation } from 'react-i18next';

class comChargingPower extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMaxValues: {
        min: 0,
        suggestedMax: 10,
      },
      calendarValue: new Date(),
      startDate: "",
      fromDate: "",
      toDate: "",
      dataLine: {
        labels: [],
        dataLabels: {
          enabled: false
        },

        datasets: [
          {
            label: "kW",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(225, 204,230, .3)",
            borderColor: "#00529E",
            borderCapStyle: "butt",
            borderDash: [5, 5, 5, 5],
            borderDashOffset: 0.6,
            borderJoinStyle: "miter",
            pointBorderColor: "#00529E",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderWidth: 10,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(0, 0, 0)",
            pointHoverBorderColor: "rgba(220, 220, 220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: []
          }
        ],
        options: {
          legend: { display: false }, tooltips: {
            enabled: false
          }
        }
      },

    }
    this.onChange = this.onChange.bind(this);
    this.getComChargingValues = this.getComChargingValues.bind(this);

  }


  //api to get comcharging session chart

  getComChargingValues(startDate) {

    var url = baseUrl.URLPath + "dashboard/combinedpower?fromTime=" + this.state.fromDate + "&toTime=" + this.state.toDate;
    //localhost:8080/api/dashboard/combinedpower?date=2020-11-19
    var Date = moment(startDate).format("MM/DD/YYYY").toUpperCase();
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        ;
        if (response != undefined) {
          var comDuration =  response.map(a => moment.utc(a.fromTime).local().format("HH:mm") );
          var power = response.map(a => a.totalPower)
        
          this.state.dataLine = {
            labels: comDuration,
            datasets: [
              {
                label: "Count",
                data: power,
                label: "kW",
                fill: true,
                lineTension: 0.3,
                backgroundColor: "rgba(225, 204,230, .3)",
                borderColor: "#00529E",
                borderCapStyle: "butt",
                borderDash: [5, 5, 5, 5],
                borderDashOffset: 0.6,
                borderJoinStyle: "miter",
                pointBorderColor: "#00529E",
                pointBackgroundColor: "rgb(255, 255, 255)",
                pointBorderWidth: 10,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgb(0, 0, 0)",
                pointHoverBorderColor: "rgba(220, 220, 220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
              }
            ]
          }
        }

        this.setState({ datasets: response });

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }



  // date picker onchange function
  onChange(value, event) {
    let startDate = value;
    let fromdate = startDate;
    let oneDay = new Date(fromdate.getTime() + 60 * 60 * 24 * 1000);
    if (value != null && value != "") {
      this.setState({
        calendarValue: fromdate,
        fromDate: dateformatinUTCwS(fromdate),
        toDate: dateformatinUTCwS(oneDay)
      }, () => {
        this.getComChargingValues();
      })
    }
  }

  componentDidMount() {
    var today = new Date(),
      date = ('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + (today.getDate())).slice(-2) + '/' + today.getFullYear();
    var now = new Date();
    now.setDate(now.getDate() - 7); // add -7 days to your date variable 
    this.state.startDate = moment(today).format("MM/DD/YYYY").toUpperCase();
    var garphInitData = this.state.startDate
    this.setState({
      value: garphInitData,
      fromDate: dateformatinUTCwS(today.setHours(0, 0, 0, 0)),
      toDate: dateformatinUTCwS(new Date())
    }, () => {
      this.getComChargingValues(date);
    })
  }

  render() {
    return (
      <>
        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success" style={{ minHeight: "346px" }}>
          <div class="content-box" >
            <div class="dashboard-subtitle mt-3" >
              <span class="pull-left">{this.props.t('combined_charging_power')}</span>
              <div className=" mt-2 mb-1 pull-right">
                <DatePicker
                  onChange={this.onChange}
                  value={this.state.calendarValue}
                  dayPlaceholder=""
                  yearPlaceholder=""
                  monthPlaceholder=""
                  nativeInputAriaLabel="Date"
                  format="MM/d/y"
                />
              </div>
              <div className="mt-1 img-main-div "  >
                {/* <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/graph6.png"} alt="logo" className="w-100" /> */}
                <Line data={this.state.dataLine} height={"105px"}
                  options={
                    { responsive: true },
                    {
                      scales: {
                        yAxes: [{
                          scaleLabel: {
                            display: true,
                            labelString: 'kW'
                          },
                          ticks: this.state.displayMaxValues
                        }],
                        xAxes: [{
                          scaleLabel: {
                            display: true,
                          }
                        }]
                      }
                    }} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };
}

export default withTranslation()(comChargingPower);
