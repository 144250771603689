import React from 'react';
import ReactDOM from "react-dom";
import * as baseUrl from '../constants';
import Status from './status';
import ChargerConnector from './chargerConnector';
import ChargingPower from './chargingPower';
import ChargingSessions from './chargingSessions';
import ComChargingPower from './comChargingPower';
import ReservationStatus from './reservationStatus';

class Dashboard extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                chargingPowerFrequency:null
            }
        }

         //  api to get Reservation chart
         componentDidMount() {
             this.getChargingPowerData();
            this.timerID = setInterval(
              () => this.combinedPowerInterval(),
              1000*this.state.chargingPowerFrequency
            );
          }
          combinedPowerInterval(){
              ReactDOM.render(
                 <ComChargingPower></ComChargingPower>,document.getElementById('combinedChargingPower')
              );
          }
          componentWillUnmount() {
            clearInterval(this.timerID);
          }
          getChargingPowerData() {
    
            let url = baseUrl.URLPath + "dashboard/combinedpower/frequency";
        
            fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
              }
            }).then(resp => {
              if (resp.status == 200) {
                return resp.json();
              }
            }).then(response => {
              this.setState({
                chargingPowerFrequency: response
              });
            })
            .catch((error) => {
              console.log(error, "error in getting charging power data");
            })
          }
        render() {
                return (
                    <>
                        <main class="content-div" >
                             <div className="main-content-div">
                                 <div className="row">
                                       <div className="col-md-12 mb-5">
                                            <div className="row">
                                                <div class="col-md-3 pr-0" >
                                                    <Status></Status>
                                                </div>
                                                <div class="col-md-3 pr-0" >
                                                    <ChargingSessions></ChargingSessions>
                                                </div>
                                                <div class="col-md-3 pr-0" >
                                                    <ChargingPower></ChargingPower>
                                                </div>
                                                <div class="col-md-3 pr-0">
                                                    <ChargerConnector></ChargerConnector>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div class="col-md-9 pr-0 " >
                                                    <div id="combinedChargingPower"></div>
                                                </div>
                                                <div class="col-md-3 pr-0" >
                                                    <ReservationStatus></ReservationStatus>
                                                </div>
                                            </div>
                                        </div>
                                 </div>
                             </div>
                        </main>
                    </>
                )
            };
}

export default Dashboard;

