import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import apiCallUtility from './APICallUtility'
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Header from './header';


class unlockConnector extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          
            fields:{
              connectorID:'',
              },
          cpList:[],
          sucess:false,
          colour:'sucess',
          page_title:"Unlock Connector",
          errors:{},
          chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
         this.getSelectedCP=this.getSelectedCP.bind(this);
         this.onShowAlert=this.onShowAlert.bind(this);
}
componentDidMount(){
  let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }  
}
onShowAlert(){
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}

async handleSubmit(event) {
  
  var fdsfs=this.state;
  var payload=this.getPayLoad();
  var errors={};
  var connectorId=payload.connectorId;
if(this.getCPList()==0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
}
if(isNaN(connectorId)){
  errors["emptyConnectorId"]=this.props.t('enter_connector_id')
}
if(this.getCPList()!=0 && !isNaN(connectorId)){
  var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
  //alert(response);
  if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else {
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }

}
this.setState({errors:errors})
}

getCPList() {
  var Id = this.state.operationId;
  if(Id != null && Id!=""){
   var cplist = Id.split();
 
  }
  else{
   var cplist = this.state.cpList;
 
 }
 return cplist;
}

getPayLoad() {
  var payload =
  {
    // "connectorId": 1,
    "connectorId": parseInt(this.state.fields.connectorID),
  };
   
  return payload;

}

getMessageType() {
  return constants.UNLOCK_CONNECTOR;
}

numberHandler(field, e){ 

  let fields = this.state;
  if(e.target.validity.valid){
    fields[field] = e.target.value; 
    this.setState({fields});
    this.state.errors["emptyConnectorId"] = "";
   
  }else if(e.target.value==""){
    fields[field] = e.target.value; 
    this.setState({fields});
   
 
  }
 
}
changeHandler(field, e){ 

  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields});
  }
getSelectedCP(cpList){

  this.setState({cpList:cpList});
  if(cpList!=""){
 
    this.state.errors["emptyChargePoint"]=" "
    
  }
 
}
componentWillUnmount(){
  localStorage.setItem('chargePoint', '');
  localStorage.setItem('operationId', '');
}
render() {
  const{t}=this.props
  return (
    <>
   <main class="content-div" >
   <p>  {t('Unlock Connector')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Unlock Connector')}</span>
              </div>
           </p>
          {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Unlock Connector </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Unlock Connector </a></div> */}

          {/* <p>Unlock Connector</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>      
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>

          <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP}operationName = "UnlockConnector" ></ChargePointSelection>
          <MDBCol md="12">
          <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>
          </MDBCol>

          
            
            <MDBCol md="4">
            <MDBInput
              value={this.state.fields["connectorID"]}
              name="ConnectorID"
                onInput={this.numberHandler.bind(this,"connectorID")}
                type="text"  pattern="[0-9]*"
             
                id="connectorID"
                label={t('connector_id_integer')}
                required
              >
        <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyConnectorId"]}</span></span>

                {/* <div className="valid-feedback">Looks good!</div> */}
                <small id="heartbeatinterval" className="form-text text-muted">
                {t('if_empty0charge_point')}
                </small>
              </MDBInput>
         </MDBCol>
           
          </MDBRow>
         
          <button type = "button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
         </form>
         </div>
         </MDBCol>
        </MDBRow>
        </div>
    </main>
    </>
  );
}
}

export default withTranslation()(unlockConnector);