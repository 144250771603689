import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import  $ from "jquery";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import {dateRangeValidate,activeDateFormat,dateRangeValidateForWeek,getDateRange} from './_methods';

import './cpDashboard.css';

const history = createBrowserHistory();

class cpPingPong extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewdisplay: "",
      cpIdSorting:false,
      modalView: false,
      errors: {},
      modalsession: false,

      fields: {
        chargePointId: "",


      },
      startDate: '',
      toDate: '',
      startDate1: '',
      startDate2: '',
      page_title: "CPMS Logs",
      collapseID: "",
      items: [

      ],
      errorData: [],
      dataArray: [
        {

          chargepointid: '1',
          address: '',
          noofattempts: '',
        },
      ],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      calenderFrom:"none",
      calenderTo:"none",
    }
    this.GetFromDate = this.GetFromDate.bind(this);
    this.Logs = this.Logs.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.Focus = this.Focus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.exportCpPingPong = this.exportCpPingPong.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
    this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.keyPress1 = this.keyPress1.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);




  }
  changeScheduleFrom(e){ 
    this.setState({startDate: e.target.value});
    }
       changeScheduleTo(e){
         this.setState({toDate: e.target.value});
        }
   
    keyPress1(e) {
      var key = e.key;
      $('.future_err1').text("");
      $('.date_range_error').text('');
      if (e.keyCode == 13) {
        if(new Date() >= new Date(e.target.value)){
          this.setState({ startDate: e.target.value });
          var dateFormat = moment(this.state.startDate).format("YYYY-MM-DD");
          this.state.startDate1 = dateFormat;
          if(dateRangeValidateForWeek(this.state.startDate1,this.state.startDate2)==true){
            this.Logs(this.state.pageSize, 1);
          }else{
            $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
          }
        }else{
          this.setState({startDate: ""});
          this.state.startDate1 = "";
          $('.future_err1').text("You can't select future date");
        }
      }
      if (key == "Delete" || key == "Backspace") {
        this.state.startDate1 = "";
        this.Logs(this.state.pageSize, 1);
      }
    }
      keyPress2(e){
       var key = e.key;
       $('.future_err2').text("");
       $('.date_range_error').text('');
     if(e.keyCode == 13){
      if(new Date() >= new Date(e.target.value)){
        this.setState({toDate: e.target.value});
   var dateFormat = moment(this.state.toDate).format("YYYY-MM-DD");
   this.state.startDate2 = dateFormat;
   if(dateRangeValidateForWeek(this.state.startDate1,this.state.startDate2)==true){
    this.Logs(this.state.pageSize, 1);
  }else{
    $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
  }
      }else{
        this.setState({toDate: ""});
        this.state.startDate2 = "";
        $('.future_err2').text("You can't select future date");
      }
    
       }
       if(key == "Delete" || key == "Backspace"){
         this.state.startDate2 = "";
         this.Logs(this.state.pageSize,1);
         }
       
    }




  async togglesession() {
   
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  handleClick(){

   
    let fields = this.state.fields;
    fields.chargePointId='';

    this.setState({
  fields
    });
    this.state.pageSize=10;
        this.state.activePage=1;
        document.getElementById("showEntries").value=10;
    this.Logs(this.state.pageSize, 1);
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");
  }
  Focus(){
  
   
  //   let fields = this.state.fields;
  //   fields.chargePointId='';

  //   this.setState({
  // fields
  //   });

  //   this.Logs(this.state.pageSize, 1);
  }
  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.chargePointid = document.getElementById('chargePointId')

    this.setState({ fields });
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");

  }
  keyPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ chargePointId: e.target.value });
      this.Logs(this.state.pageSize, 1);
      if(this.state.fields.chargePointId == ""){
        this.state.errors["entercharge"] = "Please enter charge point id"
        $("#notexists").text(" ");
      
      
      }else{
        this.state.errors["entercharge"] = " "
        this.Logs(this.state.pageSize, 1, "enter");
      }
    }

    if( key == "Backspace" || key == "Delete" ){
      
      var x=e.target.value
      var sno=x.slice(0,x.length-1)
      if(sno==""){
      
        this.state.fields.chargePointId=sno;
  
       this.Logs(this.state.pageSize, 1);
      }
      }
      switch (e.keyCode) {
        case 8:  // Backspace
        case 9:  // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
        break;
        default:
          var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
        
            e.preventDefault();
    
            return false;
         
           
        }
        break;
      
    }
  }

  GetFromDate = date => {
    var fdate =this.formatCalDate1(date);

    this.setState({ startDate: fdate });
    this.state.startDate1 = this.formatCalDate(date);
    if(dateRangeValidateForWeek(this.state.startDate1,this.state.startDate2)==true){
      $('.date_range_error').text('');
      this.Logs(this.state.pageSize, 1);
      var calenderFrom =  "none";
      this.setState({
        calenderFrom:calenderFrom
      })
    }else{
      $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
      var calenderFrom =  "none";
      this.setState({
        calenderFrom:calenderFrom
      })
    }
    
  };

  dateChange = date => {
    var fdate =this.formatCalDate1(date);
    this.setState({ toDate: fdate });
    this.state.startDate2 = this.formatCalDate(date);
    if(dateRangeValidateForWeek(this.state.startDate1,this.state.startDate2)==true){
      $('.date_range_error').text('');
      this.Logs(this.state.pageSize, 1);
      var calenderTo = "none";
      this.setState({
        calenderTo: calenderTo
      })
    }else{
      $('.date_range_error').text(`Please select date range between ${localStorage.getItem('listLogDuration')} days`);
      var calenderTo = "none";
      this.setState({
        calenderTo: calenderTo
      })
    }
  };

  formatCalDate1(date){
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }
  // get date 
  formatCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return [year, month, day,].join('-');

  }


  formatData(date) {
    var currentDate = date;
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;
    var csFormatDate = currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":00Z";
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    // this.state.data.clear();
    // this.setState({ pageSize: entries });
    this.state.activePage=1;
    this.state.pageSize=entries;
    this.Logs(entries, 1);
  }


  handlePageChange(pageNumber) {
  

    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.Logs(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }



  Logs(pageSize, pageNo, action) {
    document.getElementById("loader_image_div").style.display = "block";
    let chargePoints = this.state.fields.chargePointId;
    var url = baseUrl.URLPath + "chargepoints/pingpong?cpid=" + chargePoints + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2 + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
    
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
        }, 100);
        }
        if (resp.status == 200) {
          return resp.json();
        }else{
          if(resp.status == 400){
            return resp.json();
          }
        }
      })
      .then((response) => {
        if (response != undefined) {
          if(response.status == 400){
            $('.date_range_error').text(response.message);
          } else if (response.count.count == 0) {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              if (action == "click" || action == "enter") {
                $("#notexists").text("Charge point id not exists.");
              }
              this.setState({
                items: [],
                noOfRecords: 0
              });
            }, 100);
          } else {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              this.setState({
                items: response.pingPongs,
                noOfRecords: response.count.count
              })
            }, 100);
            if (action == "click" || action == "enter") {
              $("#notexists").text(" ");
            }
        }
        }
      })
      .catch((error) => {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          console.log(error, "catch the loop")
        }, 100);
      })
    // });
  }


  componentDidMount() {
    let duration = localStorage.getItem('listLogDuration');
    duration = duration-1;
    let fromDate = moment().subtract(6,'d').format('YYYY-MM-DD');
  let toDate = moment().format('YYYY-MM-DD');

  let startdate = moment().subtract(6,'d');
  let enddate = moment();

  this.state.startDate = this.formatCalDate1(startdate);
  this.state.toDate = this.formatCalDate1(enddate);

  this.state.startDate1 = fromDate;
  this.state.startDate2 = toDate;

  this.setState({
    startDate1: fromDate
  });
getDateRange();
    this.Logs(this.state.pageSize, this.state.activePage);
  }

  //change function for chargepoint status filter
  onChangeChargepoint() {
    var chargePoint = document.getElementById("chargePointId").value;
    this.setState({ chargePointId: chargePoint }, () => {
      this.Logs(this.state.pageSize, 1);
    });
    if(this.state.fields.chargePointId == ""){
      this.state.errors["entercharge"] = "Please enter charge point id"
      $("#notexists").text(" ");
    
    }else{
      this.state.errors["entercharge"] = " "
      this.Logs(this.state.pageSize, 1,"click");
    }
  }
  exportCpPingPong() {
    var timezone = moment().tz(moment.tz.guess()).format('z');

    var url = baseUrl.URLPath + "chargepoints/pingpong/download/CSV?cpid=" + this.state.fields.chargePointId + "&fromTime=" + this.state.startDate1 + "&toTime=" + this.state.startDate2;
    //localhost:8080/api/chargepoints/pingpong/download/CSV?cpid={}&fromTime={yyyy-MM-dd}&toTime={yyyy-MM-dd}

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": timezone
      }
    })
      .then((resp) => {
     
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
      })
      .then((response) => {
   
        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'CP_PingPong_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }
//function to display calender popup
displayCalender(type,event){
  var calenderFrom = "";
  var calenderTo = "";
  if(type=="from"){
   calenderFrom =  "block";
   calenderTo =  "none";
  }else{
   calenderTo =  "block";
   calenderFrom =  "none";
  }
  this.setState({
   calenderFrom:calenderFrom,
   calenderTo:calenderTo
})
}

//function to close calender popup
closeCalender(type,event){
  if(type=="from"){
    this.setState({
     calenderFrom:"none"
    })
  }else{
   this.setState({
     calenderTo:"none"
    })
  }
}

//function for sorting table data on click
ascendingOrder() {
  // this.setState({ items: this.state.items.reverse() })
  if(this.state.cpIdSorting == false){
    this.state.items.sort(this.dynamicSort("chargePointId"));
    this.state.cpIdSorting = true;
       this.setState({items : this.state.items.sort(this.dynamicSort("chargePointId"))})
  }
   else{
    this.state.items.sort(this.dynamicSort("chargePointId"));
    this.state.cpIdSorting = false;
       this.setState({items : this.state.items.reverse()});
   } 
}
dynamicSort(property) {
  var sortOrder = 1;

  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }

  return function (a,b) {
      if(sortOrder == -1){
          return b[property].localeCompare(a[property]);
      }else{

          return a[property].localeCompare(b[property]);
      }        
  }
}

  render() {

    

    const errorData = this.state.items.map((item, index) => {
      if(item.pingTime==null || item.pingTime==""){
        var pingTime = "";
        var pingTime1="";
      }else{
        var pingTime = moment(item.pingTime).format("DD MMM YYYY");
        var pingTime1 = moment(item.pingTime).format("HH:mm");
      }
  
      if(item.pongTime==null || item.pongTime==""){
        var pongTime = "";
        var pongTime1="";
      }else{
        var pongTime = moment(item.pongTime).format("DD MMM YYYY");
        var pongTime1 = moment(item.pongTime).format("HH:mm");
      }
  
      return (
        <tr>
          <td className="">{item.chargePointId}</td>
          <td className="wrap-word">{ pingTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {pingTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {pingTime1} </div> </>}</td>
          <td className="wrap-word">{ pongTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {pongTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {pongTime1} </div> </>}</td>
        </tr>
      )
    });


    return (
      <>
        <main class="content-div" >
          <p>CP PingPong
              <div className="breadcrumb_div">
              Maintenance &gt; <span className="breadcrumb_page">CP PingPong</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row ">
              <div className="col-md-3 t-8">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onKeyDown={this.keyPress} onChange={this.changeHandler.bind(this, "chargePointId")} onFocus={this.Focus}  class="form-control input-searchBox pad-2" placeholder="Charge Point ID" name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick} >
                  <span aria-hidden="true">×</span>
                </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >Find</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{color: "red"}}>{this.state.errors["entercharge"]}</span></span> 
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
                </div>
              </div>
              <div className="col-md-3 p-rel mt-2">
             <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder="From Time" name="srch-term" id="srch-term" value={this.state.startDate}onChange={this.changeScheduleFrom}  onKeyDown={this.keyPress1} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.startDate}
                    onChange={date => this.GetFromDate(date)}
                    value={activeDateFormat(this.state.startDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'from')}
                    maxDate={(this.state.toDate!=""&&this.state.toDate!=null)? new Date(this.state.toDate):new Date()}
                  />
                </div>
                <span className="pull-left future_err1 w3-animate-top mt-1" style={{color: "red"}}></span>
             </div>

             <div className="col-md-3 p-rel mt-2">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder="To Time" name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeScheduleTo}  onKeyDown={this.keyPress2}/>
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.toDate}
                    onChange={date => this.dateChange(date)}
                    value={activeDateFormat(this.state.toDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(this.state.startDate)}
                          maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{color: "red"}}></span>
              </div>

              <MDBCol md="3" className="" >
                {/* <button  className="btn_primary mt-2 pull-right " onClick={this.exportCpPingPong} >
                  Export
                  <i className="fas fa-file-export ml-2"></i>
                </button> */}
              </MDBCol>
              <div className='col-md-3'></div>
              <div className='col-md-9 date_range_error' style={{ color: "red", textAlign:"left" }}></div>
              <div className="col-md-12 pt-1">
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="pull-right sub-text">Show entries</label>
              </div>

            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped  hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="">Charge Point ID <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>Ping Time <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>Pong Time <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorData}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right" >
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
              {/* End table  */}
            </div>
          </div>

          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
            <MDBModalBody>
              Please login again.
          </MDBModalBody>
            <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default cpPingPong;