import React from 'react';
import { Select, MDBCol, MDBRow, MDBDataTable, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import { Form, Table, Pagination, Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import * as constants from './constants';
import { Alert } from 'reactstrap';
import apiCallUtility from './APICallUtility'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import $ from "jquery";
import Header from './header';
import moment from 'moment';
import { Line, Bar, Doughnut, Polar } from "react-chartjs-2";
import { Radar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import Dialog from 'react-bootstrap-dialog';
import { Typeahead } from 'react-bootstrap-typeahead';
import ChargingSessions from './dashboard/chargingSessions';
import StatusNotification from './dashboard/statusNotification';
import { withTranslation } from 'react-i18next';

import HeartbeatStatus from './dashboard/heartbeatStatus';
import './cpDashboard.css';
import { createBrowserHistory } from 'history'
import servicesUrl from '../common/servicesUrl';
const history = createBrowserHistory();
class reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_week: [],
      user_group: [],
      sucess: false,
      resetCPID: "",
      colour: 'sucess',
      firmwareFields:
      {
        latestVersion: "",
        currentVersion: '',
      },
      fields2: {
        status: "",
      },
      startDate: new Date(),
      options: [],
      users: [],
      serial_No: "",
      intial_data: [],
      multiple: false,
      yearmonthName: [],
      allUserMonth: [],
      allUserValue: [],
      alluserStep: {
        min: 0,
        suggestedMax: 10,
      },
      userStep: {
        min: 0,
        suggestedMax: 10
      },
      userData: [],
      userValue: [],
      dataBar: [],
      allUser: [],
      dataLine: '',
      page_title: "CP Dashboard",
      products: [],
      modal: false,
      userStep: {
        min: 0,
        suggestedMax: 10
      },


      userGroup: [],
      userGroupCount: [],
      dataBarGroup: [],
      allUserGroup: [],
      userStepGroup: {
        min: 0,
        suggestedMax: 10
      },




      dataLine: {
        labels: ["", "", "", "", "", "", ""],
        datasets: [
          {
            label: "HeartBeat Growth",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "rgba(225, 204,230, .3)",
            borderColor: "rgb(205, 130, 158)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgb(205, 130,1 58)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderWidth: 10,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(0, 0, 0)",
            pointHoverBorderColor: "rgba(220, 220, 220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
          }
        ]
      },
    }
    this.toggle = this.toggle.bind(this);
    this.tagOk = this.tagOk.bind(this);
    this.getChargingSessionChart = this.getChargingSessionChart.bind(this);
    this.getNotificationChart = this.getNotificationChart.bind(this);
    this.getFirmwareStatus = this.getFirmwareStatus.bind(this);
    this.getWallboxStatus = this.getWallboxStatus.bind(this);
    this.backToList = this.backToList.bind(this);
    this.Reset = this.Reset.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  onShowAlert() {

    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  tagOk() {
    history.push('/');
    window.location.reload();

  }
  async toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }












  backToList() {
    this.props.history.push("/csrLanding");
  }




  componentDidMount() {
    this.getChargingSessionChart();
    this.getNotificationChart();
    this.getFirmwareStatus();
    this.getWallboxStatus();

    if (this.props.location.state == null) {
      var data = JSON.parse(localStorage.getItem("cpview"));
      //  var data= JSON.parse(localStorage.getItem("cpview"));
      this.state.id = data.id;
      this.state.chargePointId = data.chargePointId;
    } else {
      var fdfd = this.state.id;

      this.state.id = this.props.location.state.id;
      this.state.chargePointId = this.props.location.state.chargePointId;


      var data = {
        id: this.state.id,
        chargePointId: this.state.chargePointId
      }
      localStorage.setItem("cpview", JSON.stringify(data));
    }




    var url = baseUrl.URLPath + servicesUrl.getChargePointDetails + "?id=" + this.state.id;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else {
          return resp.json();
        }
      })
      .then((response) => {


        if (response.data.address === null) {
          var fields1 = [];
          fields1.chargePointId = response.data.chargePointId
          fields1.id = response.data.id
          fields1.description = response.data.description
          fields1.description = response.data.description
          fields1.chargePointVendor = response.data.chargePointVendor
          fields1.chargePointModel = response.data.chargePointModel
          fields1.address = {
            addressId: '',
            street: "",
            houseNumber: "",
            zipCode: "",
            city: "",
            country: "",
            createdTime: "",
            modifiedTime: "",
            locationLatitude: "",
            locationLongitude: "",
          }
          this.setState({ fields: fields1 })
        } else {
          this.setState({ fields: response.data })
          if (this.state.fields.address.country != null && this.state.fields.address.country != "") {
            this.state.fields.address.country = "country"
          }
        }




      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    this.setState({ id: this.props.location.state })

  }

  //  api to get charging session chart
  getChargingSessionChart() {
    var user_week = [];
    var user_value = [];


    let chargePointId = localStorage.getItem("chargerId");

    var url = baseUrl.URLPath + servicesUrl.getHomeCPChargingsession + "?cpid=" + chargePointId + "&fromTime=" + "1week";

    //localhost:8080/api/home/chargepoint/{chargepointid}/chargingsession?fromTime={1week/2week....}
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {

          for (var i = 0; i < response.length; i++) {
            user_week.push(response[i]["date"])
            user_value.push(response[i]["count"])

          }


          this.state.userValue = user_value;
          var max_val = Math.max(...user_value);

          if (max_val < 10) {


            this.state.userStep = {
              min: 0,
              suggestedMax: 10,

            }
          } else {


            this.state.userStep = {
              min: 0,
              suggestedMax: max_val,
            }
          }
          this.state.dataBar = {
            labels: user_week,
            datasets: [
              {

                label: "Count",
                data: user_value,
                backgroundColor: "rgba(255, 134,159,0.4)",
                borderWidth: 2,
                borderColor: "rgba(255, 134, 159, 1)"
              }
            ]
          }


        }
        this.setState({ usersData: response })

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }



  //  api to get notification group  chart
  getNotificationChart() {
    var user_group = [];
    var user_value = [];


    let chargePointId = localStorage.getItem("chargerId");

    var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPErrorGroup + "?cpid=" + chargePointId + "&fromTime=" + "1week";
    //fordtestcpms.evprowebasto.com/cpms/api/customerservice/chargepoints/1900064444/errorgroup?fromTime=1week
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {

          for (var i = 0; i < response.length; i++) {
            user_group.push(response[i]["group"])
            user_value.push(response[i]["count"])

          }
          this.state.userGroupCount = user_value;
          var max_val = Math.max(...user_value);

          if (max_val < 10) {
            this.state.userStepGroup = {
              min: 0,
              suggestedMax: 10,

            }
          } else {
            this.state.userStepGroup = {
              min: 0,
              suggestedMax: max_val,
            }
          }
          this.state.dataBarGroup = {
            labels: user_group,
            datasets: [
              {
                label: "Count",
                data: user_value,
                backgroundColor: "rgba(255, 134,159,0.4)",
                borderWidth: 2,
                borderColor: "rgba(255, 134, 159, 1)",
              }
            ]
          }
        }
        this.setState({ usersData: response })

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  // api to get firmware status
  getFirmwareStatus() {

    let chargePointId = localStorage.getItem("chargerId");

    var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPfirmwareStatus + "?cpid=" + chargePointId;
    //  /api/customerservice/chargepoints/{cpid}/firmwarestatus   
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {
          this.setState({
            firmwareFields: response,
          })


        }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  // api to get wallBox status
  getWallboxStatus() {

    let chargePointId = localStorage.getItem("chargerId");

    var url = baseUrl.URLPath + servicesUrl.getCustomerserviceCPStatus + "?cpid=" + chargePointId;
    // /api/customerservice/chargepoints/{cpid}/status
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {
          this.setState({
            fields2: response,
          })


        }

      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getPayLoad() {
    var payload = {
      "type": "Hard"
    };
    return payload;

  }
  getMessageType() {
    return constants.RESET;
  }
  async Reset() {
    var payload = this.getPayLoad();
    var errors = {};
    var type = payload.type;

    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), [this.state.chargePointId]);
    //alert(response);
    if (response == "Unable to process your request, please try after some time.") {
      this.setState({ message: this.props.t(response), colour: 'danger' })
      this.onShowAlert();
    } else {
      this.setState({ message: this.props.t(response), colour: 'success' })
      this.onShowAlert();
    }

  }

  render() {

    localStorage.setItem("saveDetails", true);

    var page_title1 = "";
    if (this.props.location.state == null) {
      var data1 = JSON.parse(localStorage.getItem("cpview"));
      page_title1 = data1.chargePointId;

    } else {
      page_title1 = this.props.location.state.chargePointId;
      localStorage.setItem("chargerId", page_title1);

    }



    if (this.state.fields2.status == "0" || this.state.fields2.status == "Inactive") {
      this.state.fields2.status = "Inactive";
    } else {
      if (this.state.fields2.status != "") {
        this.state.fields2.status = "Active";
      }
    }





    return (
      <>
        <main class="content-div" >
          <p>{this.props.t('CSR')}
            <div className="breadcrumb_div">
              <Link to="/csrLanding"><span>{this.props.t('CSR')}</span></Link > &gt; <span className="breadcrumb_page">{this.props.t('cp_dashboard')}</span>
            </div>
          </p>
          {/* <p className="breadcrumb_div">Maintenance > FOTA</p> */}
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div"><Link to="/csrLanding" > <span className="">CSR</span></Link> > CP Dashboard  */}
              
            <div  className="pull-right ">  
          <button class="refresh-btn" type="button" data-test="button" onClick={this.backToList} ><i className="fas fa-angle-left mr-2"></i> {this.props.t('back')}
          </button></div> 
          
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
              {/* page title */}
              {/* <p>Builds</p> */}
              {/* end page title */}
              {/* page menu bar */}
              <div className="row menubar mt-1" >
              <div className="col-md-12">
                <div className="" >
                </div>
              </div>




            </div>
            <label className="mt-0 status_Color" ><h5 className="mr-2">{this.props.t('wallbox')}&nbsp;: </h5> </label><label className="mt-0 status_Color" ><h5>{page_title1}</h5> </label>


            {/* end page menu bar  */}
            <MDBRow className="mt-2">

              <div className="col-lg-2 col-md-6 mb-4 mt-2" id="graph3">
              <label className="mt-3" ><h5 className="mb-0 status_Color">{this.props.t('Firmware Status')} </h5> </label> 

               <table class="table page-table table-sm table-striped table-hover">
               <thead>
                 <tr>
                    <th>{this.props.t('current')}</th>
                    <th>{this.props.t('build_repo_latest')}</th>
                 </tr>
            </thead>
        <tbody>
        <td>{this.state.firmwareFields["currentVersion"]}</td>
        <td>{this.state.firmwareFields["latestVersion"]}</td>
        
        </tbody></table>



       
        <label className="mt-3" ><h5 className="mb-0 status_Color">{this.props.t('wallBox_status')}</h5> </label> 

        <table class="table page-table table-sm table-striped table-bordered table-hover">
            <thead>
            <tr>
            </tr>

           </thead>
        <tbody className="p-style">
        <td><b>{this.props.t('chargingSessionState')}</b></td>
        <td>{this.state.fields2["status"]}</td>
        </tbody></table>


        {/* </div> */}
    </div> 
            <div className="col-lg-6 col-md-6 mb-1 mt-2 pr-0" id="graph3" > 
               <HeartbeatStatus></HeartbeatStatus>
            </div>    
            {/* Charging Session chart */}
            <div className="col-lg-4 col-md-6 mb-1 mt-2"  id="graph4">
              <ChargingSessions></ChargingSessions>
            </div>  
        
        </MDBRow>     

        <MDBRow >

<div className="col-lg-2 col-md-6 mb-2 " id="graph3">


  <div><label className="mt-3" ><h5 className="mb-0 status_Color">{this.props.t('flash_code_display')} </h5> </label> </div>

  <div><img src={process.env.PUBLIC_URL + "/assets/img/flashcode2.png"} className="flashcode_icon" alt="" /></div>

  <div  className="">  
    <button class="refresh-btn m-0 mt-4 pl-3 pr-3" type="button" data-test="button" onClick={this.Reset}> {this.props.t('restart')}
    </button>
  </div> 

</div> 
  
<div className="col-lg-10 col-md-6 mb-4"  id="graph5" style={{height:500}}>
    <StatusNotification></StatusNotification>
</div>  
</MDBRow>  
      </div>
    <Dialog ref={(el)=> { this.dialog = el }} size="md" />
 </main>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top" >
      <MDBModalHeader toggle={this.toggle}>{this.props.t('session_has_expired')}</MDBModalHeader>
      <MDBModalBody>
       {this.props.t('please_login_again')}
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{this.props.t('ok')}</button>
      </MDBModalFooter>
      </MDBModal>
     </>
      );
    }
}

export default withTranslation()(reports);