import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import * as baseUrl from './constants';
import * as Roles from './roles';
import * as GeneralUser from './GeneralUser';
import * as PlatformUser from './PlatformUser';
import * as TenantUser from './TenantUser';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import $, { data } from "jquery";

const history = createBrowserHistory();

class editUserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sucess1: false,
      colour: 'sucess',
      message: "",
      fields: { id: '', active: '', email: '', firstName: '', lastName: '', mobileNumber: '', organisation: '', role: '', employeeId: '', status: '', rfidTag: '', aliasName: '', language: '' },
      sucess: {},
      errors: {},
      activeItem: "1",
      model: "false",
      dataArray: [],
      modalsession: false,
      roles: [],
      disabledAttr: true,
      multipleRfidArray: [
        {
          rfidTag: '', aliasName: '', tagType: 'other'
        }
      ],
      multipleRfidArrayAlias: [],
      availableSites: [],
      siteManagementsIds: [],
      selectedSites: [],
      listOfLanguages: []
    }
    this.getLanguages = this.getLanguages.bind(this);
    this.toggle = this.toggle.bind(this)
    this.tagOk = this.tagOk.bind(this)
    this.addNew = this.addNew.bind(this)
    this.backToList = this.backToList.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.roleidChange = this.roleidChange.bind(this)
    this.organizationChange = this.organizationChange.bind(this)
    this.activeChange = this.activeChange.bind(this)
    this.togglesession = this.togglesession.bind(this)
    this.getRoles = this.getRoles.bind(this)
    this.backToList = this.backToList.bind(this)
    this.confirmtoggle1 = this.confirmtoggle1.bind(this)
    this.addMultipleRfid = this.addMultipleRfid.bind(this)
    this.removeRfid = this.removeRfid.bind(this)
    this.viewUserDetails = this.viewUserDetails.bind(this)
    this.changeRfid = this.changeRfid.bind(this)
    this.onShowAlert = this.onShowAlert.bind(this)
    this.getIsAllSitesSelected = this.getIsAllSitesSelected.bind(this);
    this.onUpdateSiteSelectAll = this.onUpdateSiteSelectAll.bind(this);
    this.languageChange = this.languageChange.bind(this);
  }

  getLanguages() {
    let url = baseUrl.URLPath + 'admin/v1.0/account/languages/preferred';

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    }).then(response => {
      if (response.status == 200) {
        return response.json();
      }
    }).then(result => {
      if (result) {
        this.setState({
          listOfLanguages: result
        })
      }
    }).catch(err => {
      console.warn('get preferred lanuages api failed', err)
    })
  }

  languageChange(){
    let selectedLanguage = document.getElementById('language').value;
    let fields = this.state.fields;
    fields['language'] = selectedLanguage;

    this.setState({
      fields
    })
  }

  changeRfid(index, field, e) {
    let tempArray = [...this.state.multipleRfidArrayAlias];
    tempArray[index][field] = e.target.value;
    this.setState({ multipleRfidArrayAlias: tempArray });
    console.log(this.state.multipleRfidArrayAlias);
    this.setState({ disabledAttr: "" });
    if (field == "rfidTag") {
      $(`.rfidTag_${index}`).text("");
      $('.rfidexists').text("");
    } else if (field == "aliasName") {
      $(`.aliasName_${index}`).text("");
    }
    $('.empty_rfids').text("");
  }

  onShowAlert() {
    this.setState({ sucess1: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess1: false })
      }, 5000)
    });
  }

  addMultipleRfid() {
    this.setState({
      multipleRfidArrayAlias: [...this.state.multipleRfidArrayAlias, { rfidTag: "", aliasName: "", tagType: "other" }]
    })
  }

  removeRfid(index) {
    let data = this.state.multipleRfidArrayAlias

    // remove object
    data.splice(index, 1);
    this.setState({
      multipleRfidArrayAlias: data,
      disabledAttr: ""
    });

    let lookup = this.state.multipleRfidArrayAlias.reduce((a, e) => {
      a[e.rfidTag] = ++a[e.rfidTag] || 0;
      return a;
    }, {});

    let duplicateCount = this.state.multipleRfidArrayAlias.filter(e => lookup[e.rfidTag]);

    if (duplicateCount.length == 0) {
      $('.rfidexists').text("");
    }
  }

  confirmtoggle1() {
    this.setState({
      confirmmodal1: !this.state.confirmmodal1
    });
  }

  backToList() {
    this.props.history.push('/userManagementController');
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  roleidChange() {
    var x = document.getElementById("role").value;
    let fields = this.state.fields;
    fields["role"] = x;
    this.setState({ fields });
  }

  tagOk() {
    this.props.history.push('/userManagementController');
    window.location.reload();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  addNew() {
    this.props.history.push('/Userlist');
  }

  // checking the format of number
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    if (field == "employeeId") {
      $('.employeeidexists').text("");
    }
    this.setState({ fields, disabledAttr: "" });
  }

  activeChange = () => {
    var x = document.getElementById("myCheck").checked;
    let fields = this.state.fields;
    if (x) {
      fields.status = true;
    } else {
      fields.status = false;
    }
    this.setState({ fields, disabledAttr: "" });
  }

  isHaveAlphabetic(string) {
    if (/[a-zA-Z]/.test(string) == true) {
      return true;
    } else {
      return false;
    }
  }

  isLengthAllowed(string) {
    if (string.length <= 20) {
      return true;
    } else {
      return false;
    }
  }

  isLengthAllowed128(string) {
    if (string.length <= 128) {
      return true;
    } else {
      return false;
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    let errors = {};
    let validFreeCharging = 0;
    this.setState({
      confirmmodal1: !this.state.confirmmodal1
    });
    this.state.multipleRfidArrayAlias.forEach((element, index) => {
      if (element.rfidTag != "" && element.rfidTag != null) {
        if (!element.rfidTag.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) {
          errors["rfidempty"] = this.props.t('please_enter_valid_rfid_tag');
          $(`.rfidTag_${index}`).text(this.props.t('please_enter_valid_rfid_tag'));
        } else {
          $(`.rfidTag_${index}`).text("");
        }
      } else {
        $(`.rfidTag_${index}`).text(this.props.t('please_enter_rfid_tag'))
      }

      if (element.rfidTag == "0815" && element.rfidTag != null && element.rfidTag != "") {
        $(`.rfidTag_${index}`).text(this.props.t('rfid_tag_reserved_for_free_charging'));
        validFreeCharging = 1;
      }

      if (element.aliasName != "" && element.aliasName != null) {
        if (!element.aliasName.match(/^[0-9a-zA-Z_@,.!#%&*]{0,20}$/)) { }
        else {
          $(`.aliasName_${index}`).text("");
        }
      } else {
        $(`.aliasName_${index}`).text(this.props.t('please_enter_alias_name'))
      }
    })

    var email = this.state.fields['email'];
    var organisation = this.state.fields['organisation'];
    var firstName = this.state.fields['firstName'];
    var lastName = this.state.fields['lastName'];
    var mobileNumber = this.state.fields['mobileNumber'];
    var employeeId = this.state.fields['employeeId'];
    var role = this.state.fields['role'];
    var rfid = this.state.fields['rfid'];
    const siteManagementsIds = this.state.selectedSites;

    if (email == '') {
      errors["emailempty"] = this.props.t('enter_email');
    }

    if (email != '') {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/)) {
        errors["entervalidemail"] = this.props.t('enter_valid_email_id');
      }
    }

    if (organisation == '') {
      errors["organisationempty"] = this.props.t('please_select_organization');
    }

    if (firstName == '') {
      errors["firstName"] = this.props.t('enter_first_name');
    }

    if (lastName == '') {
      errors["lastName"] = this.props.t('enter_last_name');
    }

    if (role == "") {
      errors["role"] = this.props.t('please_select_role');
    }

    if (role == Roles.WEBASTO_END_USER_COMMERCIAL && rfid == "") {
      errors["rfidempty"] = this.props.t('please_enter_rfid_tag')
    }

    if (validFreeCharging == 1 || errors.hasOwnProperty("emailempty") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("lastName") || errors.hasOwnProperty("firstName") || errors.hasOwnProperty("role") || errors.hasOwnProperty("employeeId") || errors.hasOwnProperty("entervalidemail") || errors.hasOwnProperty("rfidempty")) { }
    else {
      let userRole = localStorage.getItem('role');
      var url = "";
      if (userRole == Roles.WEBASTO_CUSTOMER_ADMIN || userRole == Roles.WEBASTO_SITE_ADMIN) {
        url = TenantUser.UPDATE_USER + this.state.fields.id;
      } else if (userRole == Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || userRole == Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
        url = PlatformUser.UPDATE_USER + this.state.fields.id;
      } else if (userRole == Roles.WEBASTO_END_USER_RESIDENTIAL || userRole == Roles.WEBASTO_END_USER_COMMERCIAL) {
        url = GeneralUser.UPDATE_USER + this.state.fields.id;
      }

      let data = { "email": this.state.fields.email, "firstName": this.state.fields.firstName, "lastName": this.state.fields.lastName, "mobileNumber": this.state.fields.mobileNumber, "role": this.state.fields.role, "employeeId": this.state.fields.employeeId, "organisation": this.state.fields.organisation, "status": this.state.fields.status, "rfids": this.state.multipleRfidArrayAlias, siteManagementsIds, "preferredLanguage": this.state.fields.language };
      if (role == Roles.WEBASTO_END_USER_COMMERCIAL && this.state.multipleRfidArrayAlias.length == 0) {
        $(".empty_rfids").text(this.props.t('commercial_atleast_one_rfid_be_present'));
      } else {
        document.getElementById("loader_image_div").style.display = "block";
        fetch(url, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }
        }).then(resp => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
            this.hideLoder();
          } else if (resp.status == 404) {
            this.setState({ errors: errors });
            this.hideLoder();
          } else if (resp.status == 500) {

          } else if (resp.status == 409) {
            this.setState({
              errors: errors
            });
            this.hideLoder();
          } else if (resp.status == 400) {
          } else if (resp.status == 200) {
            return resp.json();
          }
        }).then(response => {
          this.hideLoder();
          if (response.status == this.props.t('succees_condition')) {
            this.toggle();
            if (localStorage.getItem('email') == this.state.fields.email) {
              localStorage.setItem('user', this.state.fields.firstName);
            }
            $('.employeeidexists').text("");
            if(localStorage.userId == this.state.fields.id){
              if(this.state.fields.language == "French"){
                this.props.i18n.changeLanguage('fr');
                localStorage.preferredLanguage = 'French'
              } else if(this.state.fields.language == "English"){
                this.props.i18n.changeLanguage('en')
                localStorage.preferredLanguage = 'English'
              }
              localStorage.setItem("localSelectedLng","");
            }
          } else if (response.status == this.props.t('failure_condition') && response.errorCode == "3104" && response.errorMessage == 'user_already_exists') {
            errors["userexists"] = this.props.t('user_already_exists_with_email')
            this.setState({
              errors: errors
            });
          } else if (response.status == this.props.t('failure_condition') && response.errorCode == "3123" && response.errorMessage == 'rfid_tag_issue') {
            var rfids = response.rfids[0];
            var errorRfid = rfids.rfidTag;
            var errorrfidMessage = rfids.errorReason;
            if (rfids.errorReason == 'rfid_is_already_assigned') {
              this.setState({ message: errorRfid + this.props.t('is_already_assigned'), colour: 'danger' })
              this.onShowAlert();
            }
            $('.rfidexists').text(this.props.t('duplicate_rfidds_not_allowed'));
            if (rfids.errorReason == "alias_name_should_not_be_empty" || rfids.errorReason == "rfid_tag_should_not_be_empty") {
              $('.rfidexists').text("");
            }
          } else if (response.status == this.props.t('failure_condition') && response.errorCode == "3124" && response.errorMessage == this.props.t('employeeid_is_already_exists')) {
            $('.employeeidexists').text(this.props.t('employeeId_already_exists'));
          } else { }

          this.setState({
            errors: errors
          });
        })
          .catch(error => alert('Error:' + error));
      }
    }
    this.setState({ errors: errors });
  }

  hideLoder() {
    document.getElementById("loader_image_div").style.display = "none";
  }

  organizationChange() {
    var x = document.getElementById("organisation").value;
    let fields = this.state.fields;
    fields["organisation"] = x;
    this.setState({ fields });
  }

  getEditUserData() {
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response.organisationDTOS })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  componentDidMount() {
    document.getElementById("myCheck").checked = true;
    this.getRoles();
    this.getEditUserData();
    this.viewUserDetails();
    this.getLanguages();
  }

  viewUserDetails() {
    var userDetails = this.props.location.state;
    let role = localStorage.getItem('role');

    var url = "";
    if (role == Roles.WEBASTO_CUSTOMER_ADMIN || role == Roles.WEBASTO_SITE_ADMIN) {
      url = TenantUser.GET_USER_DETAILS + userDetails;
    } else if (role == Roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role == Roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
      url = PlatformUser.GET_USER_DETAILS + userDetails;
    } else if (role == Roles.WEBASTO_END_USER_RESIDENTIAL || role == Roles.WEBASTO_END_USER_COMMERCIAL) {
      url = GeneralUser.GET_USER_DETAILS + userDetails;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          var data1 = response.userDTOS;
          var data2 = response.rfids;
          let fields = this.state.fields;
          fields = data1[0];
          fields['language'] = data1[0].preferredLanguage;
          this.setState({ fields, multipleRfidArrayAlias: data2, siteManagementsIds: data1[0].siteManagementsIds, }, () => {
            this.getAllAvailableSites();
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getRoles() {
    var url = baseUrl.URLPath + "roles/";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        let fields = this.state.fields;
        fields.status = true;
        if (response != undefined) {
          this.setState({ roles: response.roles })
        }
        document.getElementById("role").value = this.state.fields.role;
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  getIsAllSitesSelected() {
    const { availableSites, selectedSites } = this.state;
    let isAllChecked = false;
    if (availableSites && selectedSites) {
      for (let i = 0; i < availableSites.length; i++) {
        if (selectedSites.some(e => e == availableSites[i].id) == true) {
          isAllChecked = true;
        } else {
          isAllChecked = false;
          break;
        }
      }
    } else {
      isAllChecked = false;
    }
    return isAllChecked;
  }
  onUpdateSiteSelectAll(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      var managementsIds = [];
      this.state.availableSites.map((site, index) => {
        managementsIds = [...managementsIds, site.id];
      });
      this.setState({ selectedSites: managementsIds });
    } else {
      this.setState({ selectedSites: [] });
    }
  }
  onSelectSite(event, el) {
    const isChecked = event.target.checked;
    var managementsIds = [];
    managementsIds = this.state.selectedSites;
    if (isChecked) {
      managementsIds = [...managementsIds, el.id];
    } else {
      const index = managementsIds.indexOf(el.id);
      if (index > -1) {
        managementsIds.splice(index, 1);
      }
    }
    this.setState({ selectedSites: managementsIds });
  }
  getAllAvailableSites() {
    let url = `${baseUrl.URLPath}sitemanagement/all`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      } else if (resp.status == 200) {
        return resp.json();
      }
    }).then((response) => {
      let beforeSort = response;
      let afterSort = [];
      let names = [];
      beforeSort.map((item, index) => {
        names.push(item.name);
      });
      names = names.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      names.map((site, index) => {
        beforeSort.map((itm, index) => {
          if (itm.name == site) {
            afterSort.push(itm);
          }
        })
      });

      let selectedsites = this.state.selectedSites;
      afterSort.map((sit, ii) => {
        this.state.siteManagementsIds.map((Id, j) => {
          if (sit.id == Id) {
            selectedsites.push(Id);
          }
        })
      })

      this.setState({ ...this.state, availableSites: afterSort, selectedSites: selectedsites }, () => {
        console.log("selected: " + this.state.selectedSites);
      });
    }).catch((error) => {
      console.log(error, "error in getting sites list");
    });
  }
  render() {
    const{t}=this.props
    var getRole = localStorage.getItem("role");

    let editCommercialAndResidential = false;
    let isCommercialAndResidential = false;

    let editCommercialAndResidentialAndSiteAdmin = false;
    let isCommercialAndResidentialAndSiteAdmin = false;

    if (getRole == "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole == "WEBASTO_CUSTOMER_ADMIN" ||
      getRole == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE" ||
      getRole == "WEBASTO_SITE_ADMIN") {
      editCommercialAndResidential = true;
    }

    if (this.state.fields.role == "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role == "WEBASTO_END_USER_RESIDENTIAL") {
      isCommercialAndResidential = true;
    }

    if (getRole == "WEBASTO_SUPER_ADMIN_DEVOPS" ||
      getRole == "WEBASTO_CUSTOMER_ADMIN" ||
      getRole == "WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE") {
      editCommercialAndResidentialAndSiteAdmin = true;
    }

    if (this.state.fields.role == "WEBASTO_END_USER_COMMERCIAL" || this.state.fields.role == "WEBASTO_END_USER_RESIDENTIAL" || this.state.fields.role == "WEBASTO_SITE_ADMIN") {
      isCommercialAndResidentialAndSiteAdmin = true;
    }

    return (
      <main className="content-div">
        <p>{t('edit_user')}
          <div className="breadcrumb_div">
            {t('Settings')} &gt; <Link to="/userManagementController"><span>{t('Users')} </span></Link> &gt; {t('edit_user')}
          </div>
        </p>
        <div className="page-outerdiv">
          <MDBRow className="mb-2">
            <MDBCol sm="12">
              <button type="button" className="btn_primary mt-1" onClick={this.backToList}><i className="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="12">
              <div>
                <form
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                >
                  <MDBRow>
                    <div className="col-md-12 mb-3">
                      <span className="form-title">{t('user_details')}</span>
                    </div>
                    <MDBCol md="4">
                      <MDBInput disabled
                        value={this.state.fields["email"]}
                        onChange={this.changeHandler.bind(this, "email")}
                        type="text"
                        id="email"
                        name="Email"
                        label={t('e_mail')}
                        className="text_lowercase"
                      >
                        <div className="invalid-feedback">
                          {t('provide_valid_email')}
                        </div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["validemail"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emailempty"]}</span></span>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["entervalidemail"]}</span></span>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.fields["firstName"]}
                        onChange={this.changeHandler.bind(this, "firstName")}
                        type="text"
                        id="firstName"
                        name="firstName"
                        label={t('firstName')}
                      >
                        <div className="invalid-feedback">
                          {t('provide_valid_email')}
                        </div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["firstName"]}</span></span>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4">
                      <MDBInput
                        value={this.state.fields["lastName"]}
                        onChange={this.changeHandler.bind(this, "lastName")}
                        type="text"
                        id="lastName"
                        name="lastName"
                        label={t('lastName')}
                      >
                        <div className="invalid-feedback">
                        {t('provide_valid_email')}
                        </div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["lastName"]}</span></span>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4" className="mt-2" >
                      <MDBInput
                        value={this.state.fields["mobileNumber"]}
                        onChange={this.changeHandler.bind(this, "mobileNumber")}
                        type="text"
                        id="mobileNumber"
                        name="mobileNumber"
                        label={t('mobile_no')}
                      >
                        <div className="invalid-feedback">
                        {t('provide_valid_email')}
                        </div>
                      </MDBInput>
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["mobileNumber"]}</span></span>
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["validphoneNo"]}</span></span>
                    </MDBCol>
                    <MDBCol md="4" className="mt-2"  >
                      <select disabled className="browser-default custom-select select_height mb_8" id="role" onChange={this.roleidChange.bind(this)} value={this.state.fields.role}>
                        <option value=""> -- {t('select_role')} --</option>
                        <option value={this.state.fields.role}>{this.state.fields.role}</option>
                      </select>
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["role"]}</span></span>
                    </MDBCol>
                    <MDBCol md="4" className="mt-2"  >
                      <select className="browser-default custom-select select_height mb_8" id="language" onChange={this.languageChange.bind(this)} value={this.state.fields.language} disabled={!(localStorage.userId == this.state.fields.id)}>
                        <option value=""> -- {t('select_language')} --</option>
                        {
                          this.state.listOfLanguages?.map((language, index) => {
                            return <option key={index}>{language}</option>
                          })
                        }
                      </select>
                    </MDBCol>
                    <MDBCol md="4" className="mt-2" >
                      <MDBInput
                        type="text"
                        value={this.state.fields["employeeId"]}
                        onChange={this.changeHandler.bind(this, "employeeId")}
                        name="employeeId"
                        label="Employee number (optional)"
                        id={t('employeeIdNo')}
                      >
                        <div className="invalid-feedback">
                          {t('provide_valid_email')}
                        </div>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol md="4" className="mt-4">
                      <Form.Check
                        custom
                        value={this.state.fields.status}
                        onChange={this.activeChange}

                        type="checkbox"
                        id="myCheck"
                        label={t('active')}
                      />
                    </MDBCol>
                    {
                      ((isCommercialAndResidential && editCommercialAndResidential) || (isCommercialAndResidentialAndSiteAdmin && editCommercialAndResidentialAndSiteAdmin)) && this.state.availableSites && this.state.availableSites.length > 0 &&
                      <><div className="col-md-12 mt-2">
                        <div className="d-flex align-items-center">
                          <span className="form-title border-0 mt-0">{t('group_access')}</span>
                          <Form.Check
                            custom
                            type="checkbox"
                            id="siteAccessCheck"
                            label={t('all')}
                            className="ml-5"
                            checked={this.getIsAllSitesSelected()}
                            onChange={this.onUpdateSiteSelectAll}
                          />
                        </div>
                      </div>
                        <MDBCol md="6">
                          <MDBRow>
                            {
                              this.state.availableSites?.map((el, index) => {
                                let disabledChk = "";
                                let checked = false;
                                if (this.state.selectedSites.some(e => e == el.id) == true) {
                                  checked = true
                                }

                                if (el.users.length != 0) {
                                  el.users.map((user, index) => {
                                    if (user.id == el.createdBy) {
                                      disabledChk = "true"
                                    }
                                  })
                                }

                                return (
                                  <MDBCol md="6" key={index}>
                                    <Form.Check
                                      custom
                                      type="checkbox"
                                      id={`siteAccess_siteName_${el.id}`}
                                      label={el.name}
                                      checked={checked}
                                      disabled={disabledChk}
                                      onChange={(event) => this.onSelectSite(event, el)}
                                    />
                                  </MDBCol>
                                );
                              }
                              )
                            }
                          </MDBRow>
                          <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["siteAccess"]}</span></span>
                        </MDBCol>
                      </>
                    }

                    <MDBCol md="12" className="mt-4">
                      <i className="fa fa-plus-circle mr-2" aria-hidden="true" onClick={this.addMultipleRfid} style={{ fontSize: "18px" }}></i><h>{t('additional_rfid_tag')}</h>

                    </MDBCol>
                    {
                      this.state.multipleRfidArrayAlias.map((multipleRfidArrayAlias, index) => {
                        return (
                          <>
                            <MDBCol md="3" className="mt-3">
                              <MDBInput
                                id={`rfid_${index}`}
                                name={`rfid_${index}`}
                                type="text"
                                label={t('rfid_tag_optional')}
                                value={multipleRfidArrayAlias.rfidTag}
                                onChange={this.changeRfid.bind(this, index, "rfidTag")}
                              >
                              </MDBInput>
                              <span className={`rfidTag_${index}`} style={{ color: "red", fontSize: "13px" }}></span>



                            </MDBCol>
                            <MDBCol md="3" className="mt-3">
                              <MDBInput
                                id={`aliasName_${index}`}
                                name={`aliasName_${index}`}
                                type="text"
                                label={t('rfid_tag_alias')}
                                value={multipleRfidArrayAlias.aliasName}
                                onChange={this.changeRfid.bind(this, index, "aliasName")}
                              >
                              </MDBInput>
                              <span className={`aliasName_${index}`} style={{ color: "red", fontSize: "13px" }}></span>
                            </MDBCol>
                            <MDBCol md="5" className="form_margin">
                              <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">{t('choose_rfid_tag_type')} <span className="custom_tooltip"><i className="fa fa-info-circle" aria-hidden="true"></i>
                                <div className="custom_tooltiptext">
                                  <div className="custom_tooltip_header">{t('rfid_tag_type')}</div>
                                  <div className="custom_tooltip_body">
                                    <div>{t('type_of_rfid_tag')}</div>
                                    <div><span className="font-bold">{t('private')}: </span>{t('rfid_tag_intended_for_private_use')}</div>
                                    <div><span className="font-bold">{t('company')}: </span>{t('rfid_tag_was_issued_by_company')}</div>
                                    <div><span className="font-bold">{t('other')}: </span>{t('default_setting_no_further_logic_involved')}</div>
                                  </div>
                                </div>
                              </span></label>
                              <select className="browser-default custom-select select_height w_53 indent mt-1" id="rfidTagType" onChange={this.changeRfid.bind(this, index, "tagType")} value={multipleRfidArrayAlias.tagType}>
                                <option value="other">{t('other')}</option>
                                <option value="private">{t('private')}</option>
                                <option value="company">{t('company')}</option>
                              </select>
                              <div className="mt-3"><span className={`tagType_${index}`} style={{ color: "red", fontSize: "13px" }}></span></div>
                            </MDBCol>
                            <MDBCol md="1" className="my-3 mt-3">
                              <i class="fas fa-times-circle mt-3" onClick={this.removeRfid.bind(this, index)}></i>
                            </MDBCol>
                          </>
                        )
                      })
                    }

                  </MDBRow>
                  <div>
                    <span className="empty_rfids" style={{ color: "red", fontSize: "13px" }}></span>
                  </div>
                  <div className="mt-3">
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} className="rfidexists"></span></span>
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} className="employeeidexists"></span></span>
                  </div>
                  <MDBCol md="12" className="text-right">
                    <button type="button" className="refresh-btn mt-5 mr-3" onClick={this.confirmtoggle1}><i className="fas fa-save mr-2"></i> {t('save')}</button>
                  </MDBCol>
                </form>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
        <MDBModal isOpen={this.state.confirmmodal1} toggle={this.confirmtoggle1} size="md" className="model_top">
          <MDBModalHeader toggle={this.confirmtoggle1}>{t('confirmation')}</MDBModalHeader>
          <MDBModalBody>
            {t('sure_you_want_to_save_changes')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.confirmtoggle1}>{t('close')}</button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.handleSubmit}>{t('yes')}</button>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('user_details_updated_sucessfully')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </main>
    );
  }
}

export default withTranslation()(editUserManagement);