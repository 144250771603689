import React from 'react';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import Sidebar from './sidebar';
import Headers from './header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Form} from 'react-bootstrap';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';
import Header from './header';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import $, { get } from "jquery";
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();



class viewInventory extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          saveMfgDate:"",
          fields: {masterPassword:'',chargePointName:'',startSchedule:'',serialNumber: '', manufacturingDate:'',mfgDate: '',modelName:'', partNumber:'', mesSerialNumber:'', fwVersion:'', ocppPassword:'', accessCode:'',vendorCode:''},
          sucess:{},
          errors: {},
          activeItem: "1",
          model:"false",
          page_title:"Edit Inventory",
          dataArray:[],
          modalsession:false,
          roles:[],
          disabledAttr:true,
        
        }
        this.toggle = this.toggle.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.addNew = this.addNew.bind(this);
      
        
        this.togglesession = this.togglesession.bind(this);
        this.backToList = this.backToList.bind(this);
        this.confirmtoggle1 = this.confirmtoggle1.bind(this);
        



}

formatDateandTime(currentDate){
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" + currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
   this.setState({saveMfgDate:createdDateTo});
  // if(createdDateTo == "01-01-1970 05:30"){
  //   this.state.saveMfgDate = "";
  // }
   
}

confirmtoggle1(event){
  // event.preventDefault();
  ;
  this.setState({
    confirmmodal1: !this.state.confirmmodal1
  });
}
backToList(){
  this.props.history.push('/inventoryList');
}

async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}

tagOk(){
  this.props.history.push('/inventoryList');
}
toggle = () => {
  this.setState({
  modal: !this.state.modal
  });
}




addNew(){
  this.props.history.push('/Userlist');
}





   
    componentDidMount(){
      ;
      var timeZone=  localStorage.getItem("timezone");
        var cpid = this.props.location.state;
        this.state.serialNumber = cpid;
         var url = baseUrl.URLPath + servicesUrl.getCpInventory+ "?cpid=" + cpid;
         //localhost:8080/cpms/api/cpinventory/{cpid}
        fetch(url,{
            method: "GET",
            headers: {
              "content-type":"application/json",
              "Authorization":localStorage.getItem("Authorization")
             }
            })
            .then((resp) => {
              if(resp.status==401){
                // alert("Access token is expired, please login again")
                this.togglesession();
          
                localStorage.clear();
               
              }
              if(resp.status==200){
                return resp.json();
              }
            }) 
            .then((response) =>{
              if(response!=undefined){
              this.setState({
              fields:response,
              })
             
              if(this.state.fields.manufacturingDate!=null && this.state.fields.manufacturingDate!=""){
                this.setState({
                  saveMfgDate:new Date(this.state.fields.manufacturingDate),
               }) 
            //    $("#calendericon").show();
            //    $("#clockicon").show();
               }
               if(this.state.fields.manufacturingDate==null || this.state.fields.manufacturingDate==""){
                this.setState({
                  saveMfgDate:"",
               }) 
              $("#timecalender").hide();
            //    $("#clockicon").hide();
               }
              }
           
             
              })
            .catch((error) => {
              console.log(error, "catch the loop")
         })
      } 
  




render() {
  const{t}=this.props
  const organisationDTOS =this.state.dataArray.map((dataArray, index) => {
    return(
      <option value={dataArray.id}>{dataArray.company}</option>
    )
   
  })
  const roles =this.state.roles.map((role, index) => {
    return(
      <option value={role.identifier}>{role.displayName}</option>
    )
   
  })



  return (
    <>
    <main class="content-div" >
          <p>{t('Inventory')}
              <div className="breadcrumb_div">
                {t('Maintenance')} &gt; <Link to="/inventoryList"> <span className="">{t('Inventory')} &gt; </span></Link> <span className="breadcrumb_page">{t('view')}</span>
              </div>
           </p>
         {/* <Header page_title={this.state.page_title}/> */}
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div">Maintenance ><Link to="/inventoryList"> <span className="">Inventory > </span></Link>  Edit Inventory</div> */}
        <div className="row">
          <div className="col-md-12">
            <div className="pull-left">
            <button className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> {t('back')}</button>
            </div>
          </div>
        </div>
        <form className="needs-validation" >
          <div className="row mb-4 mt-3">
            <div className="col-sm-6">
              <div className="content-innerdiv">
              <div className="" >
              <label>{t('charge_point_id')} : </label>
               <label>{this.state.fields["serialNumber"]}</label>

               </div>
               <div className=" ">
               <label>{t('mf_date')} : </label>
                
                <label id="timecalender">  <i className="fas fa-calendar-alt fa-sm mr-1" id="calendericon"></i> {moment(this.state.saveMfgDate).format("DD MMM YYYY")} <i className="far fa-clock fa-sm mr-1" id="clockicon"></i>{moment(this.state.saveMfgDate).format("HH:mm")}
                       </label>
               </div>
               <div className="">
               <label>{t('model')} : </label>
            <label>{this.state.fields["modelName"]} </label>
               </div>
               <div className="">
               <label>{t('part_no')} : </label>
            <label>{this.state.fields["partNumber"]}</label>
               </div>
               <div className="">
               <label>{t('mes_serial_no')} * : </label>
            <label>{this.state.fields["mesSerialNumber"]}</label>
            
               </div>
               <div className="">
               <label>{t('fw_version')} : </label>
            <label>{this.state.fields["fwVersion"]}</label>
               </div>
              </div>
            </div>
            <div className="col-sm-6">
            <div className="content-innerdiv">
            <div className="">
            <label>{t('ocpp_password')} : </label>
            <label>{this.state.fields["ocppPassword"]}</label>
                           
               </div>
               <div className="">
               <label>{t('vendor_code')} : </label>
            <label>{this.state.fields["vendorCode"]}</label>
                           
               </div>
             
               <div className=" ">
               <label>{t('access_code')} : </label>
            <label>{this.state.fields["accessCode"]}</label>
             
               </div>
               <div className="">
               <label>{t('charge_point_name')} : </label>
            <label>{this.state.fields["chargePointName"]}</label>
              
               </div>
               <div className="">
               <label>{t('master_password')} : </label>
            <label>{this.state.fields["masterPassword"]}</label>
             
              
               </div>
             
            </div>
            </div>
          {/* <div className="col-md-12 text-right">
          <button type="button" className="btn_primary mt-5"><Link to={{
            pathname: '/editInventory',
            
          }}  > Edit <i class="fas fa-pencil-alt   pr-2 action-icon edit_usericon" title="Edit"></i></Link></button>
   </div> */}
   </div>
        </form>
</div>


       
      
 
    </main>


    </>
  );
}
}

//export default viewInventory;
export default withTranslation()(viewInventory);