import React from 'react';
import { MDBCol,MDBModal,MDBModalHeader,MDBModalBody,MDBRow,MDBModalFooter} from 'mdbreact';
import {Form,Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import $ from 'jquery';
import {dateRangeValidate1,activeDateFormat} from './_methods';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class executeHistoryLogs extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          viewdisplay:"",

          displayError:"",
          apiResponse:"",
          modalView: false,
          calenderFrom: "none",
          calenderTo: "none",
          modalsession:false,

          fields:{
            serviceName:"",
            statusNameC:"",
          },
          startDate:'',
          toDate:'',
          startDate1:'',
          startDate2:'',
            page_title:"CPMS Logs",
            collapseID: "",
              items: [
              
              ],
            executionData:[],
            dataArray: [
              {
              
                address: '',
                noofattempts:'',
            },
          ],
          noOfRecords:'',
          data:new Map(),
          pageSize:"10",
          activePage: 1,
          pageNo:1,
      }
      this.GetFromDate = this.GetFromDate.bind(this);
      this.getExecutionHistory = this.getExecutionHistory.bind(this);
      this.changeHandler = this.changeHandler.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.showEntriesChange=this.showEntriesChange.bind(this);
      this.togglesession = this.togglesession.bind(this);
      this.sessiontagOk = this.sessiontagOk.bind(this);
      this.statusChange = this.statusChange.bind(this);
      this.onChangeChargepoint=this.onChangeChargepoint.bind(this);
      this.toggleView = this.toggleView.bind(this);
      this.toggleView1 = this.toggleView1.bind(this);
      this.changeFromdate=this.changeFromdate.bind(this);
      this.changeTodate=this.changeTodate.bind(this);
      this.displayCalender=this.displayCalender.bind(this);
      this.closeCalender=this.closeCalender.bind(this);
      this.formatCalDate1=this.formatCalDate1.bind(this);
      this.keyPress1=this.keyPress1.bind(this);
      this.keyPress2=this.keyPress2.bind(this);
}
keyPress1(e){
  var key = e.key;
  $('.future_err1').text("");
if(e.keyCode == 13){
  if (new Date() >= new Date(e.target.value)) {
    this.setState({ startDate: e.target.value });
    var dateFormat = moment(this.state.startDate).format("YYYY-MM-DD");
    this.state.startDate1 = dateFormat;
    this.getExecutionHistory(this.state.pageSize, 1);
  } else {
    this.setState({ startDate: "" });
    this.state.startDate1 = "";
    $('.future_err1').text(this.props.t('you_not_select_future_date'));
  }
  }
  if(key == "Delete" || key == "Backspace"){
    this.state.startDate1 = "";
    this.getExecutionHistory(this.state.pageSize,1);
    }
  
}
keyPress2(e){
var key = e.key;
$('.future_err2').text("");
if(e.keyCode == 13){
  if (new Date() >= new Date(e.target.value)) {
    this.setState({ toDate: e.target.value });
    var dateFormat = moment(this.state.toDate).format("YYYY-MM-DD");
    this.state.startDate2 = dateFormat;
    this.getExecutionHistory(this.state.pageSize, 1);
  } else {
    this.setState({ toDate: "" });
    this.state.startDate2 = "";
    $('.future_err2').text(this.props.t('cannot_select_futuredate'));
  }
}
if(key == "Delete" || key == "Backspace"){
  this.state.startDate2 = "";
  this.getExecutionHistory(this.state.pageSize,1);
  }

}
changeFromdate(e){ 
  this.setState({startDate: e.target.value});
  }
changeTodate(e){
  this.setState({toDate: e.target.value});
 }
displayCalender(type, event) {
  var calenderFrom = "";
  var calenderTo = "";
  if (type == "from") {
    calenderFrom = "block";
    calenderTo = "none";
  } else {
    calenderTo = "block";
    calenderFrom = "none";
  }
  this.setState({
    calenderFrom: calenderFrom,
    calenderTo: calenderTo
  })
}
closeCalender(type, event) {
  if (type == "from") {
    this.setState({
      calenderFrom: "none"
    })
  } else {
    this.setState({
      calenderTo: "none"
    })
  }
}
toggleView(apiResponse){
  ;
  this.setState({displayError:apiResponse})
  this.setState({
    modalView: !this.state.modalView
  });
}

toggleView1(){
  this.setState({
    modalView: !this.state.modalView
  }); 
}

 //change function for service type status filter
 onChangeChargepoint() {
  var serviceType = document.getElementById("serviceName").value;
  this.setState({ serviceName: serviceType }, () => {
      this.getExecutionHistory(this.state.pageSize,1);
    });
}




   // method to call onChange status 
   statusChange(){
  var id = document.getElementById("statusName").value;
  let fields = this.state.fields;
  fields["statusNameC"] = id;
  this.setState({ statusNameC: id }, () => {
    this.getExecutionHistory(this.state.pageSize,this.state.activePage);

   });}




async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}


changeHandler(field, e){ 
  ;
  let fields = this.state.fields;
  fields[field] = e.target.value; 
  fields.servicename = document.getElementById('serviceName')

  this.setState({fields});
  }
  formatCalDate1(date){
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }
  GetFromDate = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ startDate: fdate });
    this.state.startDate1 = this.formatCalDate(date);
    // if(dateRangeValidate1(this.state.startDate1, this.state.startDate2)==true){
    //   $('.date_range_error').text('');
      this.getExecutionHistory(this.state.pageSize, 1);
      var calenderFrom = "none";
      this.setState({
        calenderFrom: calenderFrom
      });
    // }else{
    //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var calenderFrom = "none";
    //   this.setState({
    //     calenderFrom: calenderFrom
    //   });
    // }
  };

dateChange = date => {
  var tdate = this.formatCalDate1(date);
  this.setState({toDate:tdate});
  this.state.startDate2 = this.formatCalDate(date);
  // if(dateRangeValidate1(this.state.startDate1, this.state.startDate2)==true){
  //   $('.date_range_error').text('');
    this.getExecutionHistory(this.state.pageSize, 1);
    var calenderTo = "none";
    this.setState({
      calenderTo: calenderTo
    });
  // }else{
  //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
  //   var calenderTo = "none";
  //   this.setState({
  //     calenderTo: calenderTo
  //   });
  // }
};
// get date 
formatCalDate(date){
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  // return [year, month, day].join('-');
  return [year, month, day].join('-');

}


formatData(date){
  var currentDate = date;
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  var csFormatDate = currentDate.getFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":00Z";
}
showEntriesChange(){
  var entries=document.getElementById("showEntries").value;
  // this.state.data.clear();
  // this.setState({pageSize:entries});
  this.state.pageSize=entries;
  this.state.activePage=1;
  this.getExecutionHistory(entries,1);
}

  
handlePageChange(pageNumber) {
  

  if(pageNumber !=this.state.activePage){
    var data=this.state.data;
    var pageData=data.get(pageNumber);
    if(pageData==undefined){
  this.setState({activePage: pageNumber});
  this.getExecutionHistory(this.state.pageSize,pageNumber);
    }else{
      this.setState({activePage: pageNumber});
    }
  }
}



getExecutionHistory(pageSize,pageNo){
 ;
  let serviceTypes = this.state.fields.serviceName;
  let statusField = this.state.fields.statusNameC; 


  var url = baseUrl.URLPath + "executionhistory/log?serviceType="+serviceTypes+"&status="+statusField+"&formdate="+this.state.startDate1+"&todate="+this.state.startDate2+"&pageNo="+pageNo+"&pageSize="+pageSize;
  //localhost:8080/api/executionhistory/log?formdate=12-18-2020&todate=12-19-2020&pageNo={}&pageSize={}
   fetch(url,{
    method: "GET",
    headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
    })
    .then((resp) => {
      ;
      if(resp.status==401){
        // alert("Access token is expired, please login again")
        this.togglesession();
  
        localStorage.clear();
       
      }
      if(resp.status==200){
        return resp.json();
      }
    }) 
    .then((response) =>{
      if(response!=undefined){
      this.setState({
          items:response.executionHistory,
          noOfRecords:response.count.count,
      })
    }
    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
  // });
}


  componentDidMount(){
    let fromDate = moment().subtract(29,'d').format('YYYY-MM-DD');
  let toDate = moment().format('YYYY-MM-DD');

  let startdate = moment().subtract(29,'d');
  let enddate = moment();

  this.state.startDate = this.formatCalDate1(startdate);
  this.state.toDate = this.formatCalDate1(enddate);

  this.state.startDate1 = fromDate;
  this.state.startDate2 = toDate;
  this.setState({
    startDate1: fromDate
  });
    this.getExecutionHistory(this.state.pageSize,this.state.activePage);
  }




render() {
  
  const{t}=this.props
  const executionData=this.state.items.map((item, index) => {    
      
    if(item.apiResponse == null || item.apiResponse == ""){
      this.state.viewdisplay = "none";
    }else{
      this.state.viewdisplay = "block";
    }

    if(item.batchStartTime==null || item.batchStartTime==""){
      var batchStartTime = "";
      var batchStartTime1="";
    }else{
      var batchStartTime = moment(item.batchStartTime).format("DD MMM YYYY");
      var batchStartTime1 = moment(item.batchStartTime).format("HH:mm");
    }

    if(item.batchEndTime==null || item.batchEndTime==""){
      var batchEndTime = "";
      var batchEndTime1="";
    }else{
      var batchEndTime = moment(item.batchEndTime).format("DD MMM YYYY");
      var batchEndTime1 = moment(item.batchEndTime).format("HH:mm");
    }

      return(             
        <tr>
          <td>{item.jobName}</td>
          <td className="wrap-word">{ batchStartTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {batchStartTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {batchStartTime1} </div> </>} </td>
          <td className="wrap-word">{ batchEndTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {batchEndTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {batchEndTime1} </div> </>} </td>
          <td>{item.jobStatus}</td>
          <td>{item.batchCount}</td>
          <td>{item.initiatedFrom}</td>
          <td className="text-center"><i class="fas fa-eye action-icon cursor_defult" style={{display:this.state.viewdisplay}} title="View"  onClick={this.toggleView.bind(this,item.apiResponse)} ></i></td>


      </tr>
      )
  });


  return (
    <>
 <main class="content-div" >
 <p>{t('Execution History Logs')}
              <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Execution History Logs')}</span>
              </div>
           </p>
        <div className="page-outerdiv">
          {/* page title */}
          {/* <p>Unknown Charge Points</p> */}
          {/* end page title */}
          {/* page menu bar */}
          {/* <div className="breadcrumb_div">Maintenance > Execution History Logs</div> */}
          {/* end page menu bar  */}
          <div className="row mt-4">
            <div className="col-md-3 mt-2">
              <div class="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <input type="text" id="serviceName" value={this.state.fields["serviceName"]} onChange={this.changeHandler.bind(this, "serviceName")}
                  class="form-control input-searchBox pad-2" placeholder={t('service_type_input')} name="adminAddress" />
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                </div>
              </div>
              <span className="error_msg w3-animate-top"> <span style={{color:"red"}}></span></span>
            </div>
            <div className="col-md-3 p-rel mt-2">
              <div class="input-group">
                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeFromdate} onKeyDown={this.keyPress1} />
                <div class="input-group-btn">
                  <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                </div>
              </div>
              <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                <Calendar
                  selected={this.state.startDate}
                  onChange={date => this.GetFromDate(date)}
                  value={activeDateFormat(this.state.startDate)}
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  showTimeSelect
                  //className="cal_margin tag_cal"
                  timeCaption="time"
                  dateFormat="dd-MMM-yyyy"
                  onBlur={this.closeCalender.bind(this, 'from')}
                  // maxDate={new Date()}
                  maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                />
              </div>
              <span className="pull-left future_err1 w3-animate-top mt-1" style={{color: "red"}}></span>
            </div>
                <div className="col-md-3 p-rel mt-2">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeTodate}  onKeyDown={this.keyPress2} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.toDate}
                    onChange={date => this.dateChange(date)}
                    value={activeDateFormat(this.state.toDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    //className="cal_margin tag_cal"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(this.state.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{color: "red"}}></span>
              </div>
              <MDBCol md="3" >
          <select className="browser-default custom-select select_height mb_8" id="statusName"  onChange={this.statusChange} > 
                 <option value="">{t('select_status')}</option>
                  <option value="FAILED">{t('failed')}</option>
                  <option value="SUCCESS">{t('success')}</option>
          </select>
     </MDBCol>
              <div className='col-md-12 date_range_error' style={{ color: "red", textAlign:"center" }}></div>
                {/* <MDBCol md="3" className="mt-2 pl-0"  >
                <div>
                <span className="cal_label">To</span>
                <div className="pull-left mt-2" style={{width:"82%"}} >
                        <DatePicker
                        selected={this.state.toDate}
                        onChange={date => this.dateChange(date)}
                        timeFormat="HH:mm"
                        timeIntervals={1}
                        className="cal_margin tag_cal"
                        timeCaption="time"
                        dateFormat="dd-MMM-yyyy"
                        />
                  </div>
                 </div>
                </MDBCol> */}
            

                <div className="col-md-12 mt-3 pt-1">
                <div className="pull-right">
                  <label className="pull-left sub-text">{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                </div>
          </div>
          
          {/* table start */}
          <div className="row ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                <th>{t('job_name')}</th>
                <th>{t('batch_start_time')}</th> 
                <th>{t('batch_end_time')}</th>
                <th>{t('job_status')}</th>
                <th>{t('batch_count')}</th>
                <th>{t('initiated_from')}</th>
                <th>{t('view')}</th>
             </tr>
            </thead>
            <tbody>
            {executionData}
            </tbody>
          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right" >
            <Pagination
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
            />
          </div> 
          {/* End table  */}
          </div>
          </div>
        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
              {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" className="btn_primary mt-5" onClick={this.sessiontagOk}>{t('ok')}</button>
          {/* <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn> */}
          </MDBModalFooter>
      </MDBModal>


       {/* Popup for  View */}
       <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="xl" className="model_quickview" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
       <div data-test="modal-header" class="modal-header">
        <div class="modal-title fw-800 mt-1" ref={(node)=>{if(node){node.style.setProperty("font-size","18px","important");}}}>{t('view')}</div>
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
        {/* <MDBModalHeader toggle={this.toggleView}>View</MDBModalHeader> */}
        <MDBModalBody className = "popupScroll">
        <MDBRow className="mb-4 mt-2" >
       
       <MDBCol sm="12">
       <div className="view-page-row view-bg-row" >
            <label className="">
              {t('API Response:')}
            </label>
            <label className="wrap-word">
            {JSON.stringify(this.state.displayError)}
            
            </label>
          </div>
          </MDBCol>
          </MDBRow>
          {/* <MDBRow className="mb-4 mt-3" >

          <MDBCol sm="">
       <div className="view-page-row view-bg-row">
            <label className="w-40">
               Response Message:
            </label>
            <label className="wrap-word">
              
            { this.state.displayRes}
            </label>               
          </div>
          </MDBCol>
         </MDBRow> */}
        </MDBModalBody>
        <MDBModalFooter>
        <button type="button" className="btn_primary mt-2 mb-2" onClick={this.toggleView1}>{t('close')}</button>
          {/* <MDBBtn color="danger" onClick={this.toggleView1}>Close</MDBBtn> */}
          {/* <MDBBtn color="primary">Save</MDBBtn> */}
          {/* onClick={this.addBuildtype} */}
        </MDBModalFooter>
      </MDBModal>

     
      </main>
 </>
  );
}
}

export default withTranslation()(executeHistoryLogs);