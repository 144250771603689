import React from 'react';
import {Link} from 'react-router-dom';
import { MDBBtn, MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import * as baseUrl from '../constants';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import  $ from "jquery";

const history = createBrowserHistory();

class ChargerConnectors extends React.Component {
        constructor(props) {
            super(props);
            this.state = {

                currentPage: 1,
                todosPerPage: 4,
                upperPageBound: 3,
                lowerPageBound: 0,
                isPrevBtnActive: 'disabled',
                isNextBtnActive: '',
                pageBound: 3,

                chargepointid:'',
                chargePointData:[{
                    "tenantId": "",
                    "chargePointId": "",
                    "connectorId": '',
                    "status": ""
                }],
                errors: {},
                modalsession:false,
            }
            this.handleChange = this.handleChange.bind(this);
            this.keyPress = this.keyPress.bind(this);
            this.togglesession = this.togglesession.bind(this);
            this.sessiontagOk  = this.sessiontagOk.bind(this);

            this.handleClick = this.handleClick.bind(this);
            this.btnDecrementClick = this.btnDecrementClick.bind(this);
            this.btnIncrementClick = this.btnIncrementClick.bind(this);
            this.btnNextClick = this.btnNextClick.bind(this);
            this.btnPrevClick = this.btnPrevClick.bind(this);
            this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
        }
            
            async togglesession(){
                ;
                this.setState({
                   modalsession: !this.state.modalsession
                });
            }
        
            sessiontagOk(){
                history.push('/');
                window.location.reload();
            }
    
            handleChange(e) {
                this.setState({ chargepointid: e.target.value });
                if(e.target.value==""){
                    this.setState({ errors: {}, chargePointData:[] });
                }
             }
          
             keyPress(e){
                var key = e.key;
                if(e.keyCode == 13){
                    this.setState({ chargepointid: e.target.value });
                    this.getChargerConnector();
                }

                if(key == "Delete"){
                    window.location.reload();
                  }
             }

             getChargerConnector(){                 
                let errors = {};
                var chargepointid = this.state.chargepointid;
                var url = baseUrl.URLPath + "dashboard/chargepoints/connectors/status?cpid="+chargepointid;

                fetch(url,{
                  method: "GET",
                  headers: {
                        "content-type":"application/json",
                        "Authorization":localStorage.getItem("Authorization")
                      }
                  })
                  .then((resp) => {
                    if(resp.status==401){
                      this.togglesession();
                      localStorage.clear();
                    }
                    if(resp.status==404){
                        errors["chargepointdoesNotExist"] = this.props.t('charge_point_not_exists');
                        this.setState({
                            errors:errors,
                            chargePointData:[]
                        })
                      }
                    
                    if(resp.status==200){
                      return resp.json();
                    }
                  }) 
                  .then((response) =>{
                    
                    if(response!=undefined){
                        this.setState({
                            chargePointData:response,
                            errors:{}
                        })
                    }
                    // this.getProducts();
                  })
                  
                  .catch((error) => {
                      console.log(error, "catch the loop")
                  })              
            
            
            }

            
        

            componentDidMount(){
                
                
                var url = baseUrl.URLPath + "dashboard/chargepoints/connectors/status";
               //localhost:8080/api/dashboard/chargepoints/connectors/status
                fetch(url,{
                  method: "GET",
                  headers: {
                        "content-type":"application/json",
                        "Authorization":localStorage.getItem("Authorization")
                      }
                  })
                  .then((resp) => {
                    ;
                    if(resp.status==401){
                      this.togglesession();
                      localStorage.clear();
                    }
                    if(resp.status==404){
                        // errors["chargepointdoesNotExist"] = "Charge point not exists.";
                        // this.setState({
                        //     errors:errors,
                        //     chargePointData:[]
                        // })
                      }
                    
                    if(resp.status==200){
                      return resp.json();
                    }
                  }) 
                  .then((response) =>{
                    
                    if(response!=undefined){
                        this.setState({
                            chargePointData:response,
                        })
                    }
                    // this.getProducts();
                  })
                  
                  .catch((error) => {
                      console.log(error, "catch the loop")
                  }) 

                  $(".cc_pagination li.active").removeClass('active');
                  $('.cc_pagination li#'+this.state.currentPage).addClass('active');
            }



            handleClick(event) {
                let listid = Number(event.target.id);
                this.setState({
                  currentPage: listid
                });
                $(".cc_pagination li.active").removeClass('active');
                $('.cc_pagination li#'+listid).addClass('active');
                this.setPrevAndNextBtnClass(listid);
              }
              setPrevAndNextBtnClass(listid) {
                let totalPage = Math.ceil(this.state.chargePointData.length / this.state.todosPerPage);
                this.setState({isNextBtnActive: 'disabled'});
                this.setState({isPrevBtnActive: 'disabled'});
                if(totalPage === listid && totalPage > 1){
                    this.setState({isPrevBtnActive: ''});
                }
                else if(listid === 1 && totalPage > 1){
                    this.setState({isNextBtnActive: ''});
                }
                else if(totalPage > 1){
                    this.setState({isNextBtnActive: ''});
                    this.setState({isPrevBtnActive: ''});
                }
              }
              
              btnIncrementClick() {
                  this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
                  this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
                  let listid = this.state.upperPageBound + 1;
                  this.setState({ currentPage: listid});
                  this.setPrevAndNextBtnClass(listid);
              }
              
              btnDecrementClick() {
                this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
                this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
                let listid = this.state.upperPageBound - this.state.pageBound;
                this.setState({ currentPage: listid});
                this.setPrevAndNextBtnClass(listid);
              }
              
              btnPrevClick() {
                if((this.state.currentPage -1)%this.state.pageBound === 0 ){
                    this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
                    this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
                }
                let listid = this.state.currentPage - 1;
                this.setState({ currentPage : listid});
                this.setPrevAndNextBtnClass(listid);
              }
              
              btnNextClick() {
                if((this.state.currentPage +1) > this.state.upperPageBound ){
                    this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
                    this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
                }
                let listid = this.state.currentPage + 1;
                this.setState({ currentPage : listid});
                this.setPrevAndNextBtnClass(listid);
              }
              
          

        render() {

            const { todos, currentPage, todosPerPage,upperPageBound,lowerPageBound,isPrevBtnActive,isNextBtnActive } = this.state;
            // Logic for displaying current todos
            const indexOfLastTodo = currentPage * todosPerPage;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
            const currentTodos =   this.state.chargePointData.slice(indexOfFirstTodo, indexOfLastTodo);
          
            const renderTodos = currentTodos.map((todo, index) => {
                if(index<3){
                    return <li key={index}>{todo}</li>;
                }
            });


             const dataRow = currentTodos.map((dataArray, index) => {
                 var status_bg = "";
                    switch(dataArray.status) {
                        case "Available":
                             status_bg = "status-lgreen"; break
                        case "Preparing":
                             status_bg = "status-orange"; break
                        case "Charging":
                             status_bg = "status-blue";break
                        case "Finishing":
                             status_bg = "status-green";break
                        case "Reserved":
                             status_bg = "status-yellow";break
                        case "Unavailable":
                             status_bg = "status-gray";break
                        case "Faulted":
                             status_bg = "status-red";break
                        case "SuspendedEV":
                            status_bg = "status-purple";break
                      }


          
                 return(
                    <div className="dash-row" >
                        <label className="">
                            {dataArray.chargePointId}
                        </label>
                        <label className="">
                            {dataArray.connectorId}
                        </label>
                        <label className="text-right">
                            <span className={`status-div ${status_bg}`}>
                                {dataArray.status}
                            </span>
                        </label>
                    </div>
                 )
             })

                                    // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(this.state.chargePointData.length / todosPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map(number => {
      if(number === 1 && currentPage === 1){
          return(
              <li key={number} className='active' id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
          )
      }
      else if((number < upperPageBound + 1) && number > lowerPageBound){
          return(
              <li key={number} id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
          )
      }
  });
  let pageIncrementBtn = null;
  if(pageNumbers.length > upperPageBound){
      pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip; </a></li>
  }
  let pageDecrementBtn = null;
  if(lowerPageBound >= 1){
      pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
  }
  let renderPrevBtn = null;
  if(isPrevBtnActive === 'disabled') {
      renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev"> « </span></li>
  }
  else{
      renderPrevBtn = <li className={isPrevBtnActive}><a href='#' id="btnPrev" onClick={this.btnPrevClick}> « </a></li>
  }
  let renderNextBtn = null;
  if(isNextBtnActive === 'disabled') {
      renderNextBtn = <li className={isNextBtnActive}><span id="btnNext"> » </span></li>
  }
  else{
      renderNextBtn = <li className={isNextBtnActive}><a href='#' id="btnNext" onClick={this.btnNextClick}> » </a></li>
  }
      

                return (
                    <>
                        <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success dashboard-height">
                            <div class="content-box p-rel">
                                <div class="dashboard-subtitle">
                                    {this.props.t('charger_connectors')}
                                    <span className="pull-right">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/sidebar-menu/Settings.png"}  style={{ width:"15px" }} alt="" />
                                    </span>
                                </div>
                                <div class="input-group input-search mt-2">
                                    <i class="fas fa-search fa-sm"></i>
                                    <input type="text" class="border-0 small" placeholder={this.props.t('search_chargepoint')} onKeyDown={this.keyPress} onChange={this.handleChange} value={this.state.chargepointid} />
                                    <span className="error_msg w3-animate-top mt-1"> <span style={{color: "red"}}>{this.state.errors["chargepointdoesNotExist"]}</span></span> 
                                </div>
                                <div className="mt-3" >
                                    <div className="table-hrbg mr-15 pt-1 mb-2" >
                                        <label class="dashboard-space">{this.props.t('chargePointId')}</label>
                                        <label>{this.props.t('connector')}</label>
                                        <label className="pull-right pr-3">{this.props.t('chargingSessionState')}</label>
                                    </div>
                                    <div className="pull-left w-100 charger_con">{dataRow}</div>
                                    <div className="connectorSilder w-100 text-center">
                                        <ul className="pagination cc_pagination">{renderPageNumbers}</ul>
                                    </div>
                                    <Link to="./connectorStatus"><label className="more-btn">{this.props.t('more')}</label></Link>
                                </div>
                            </div>
                        </div>

                        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                            <MDBModalHeader toggle={this.togglesession}>{this.props.t('session_has_expired')}</MDBModalHeader>
                            <MDBModalBody>{this.props.t('please_login_again')}</MDBModalBody>
                            <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.sessiontagOk}>{this.props.t('ok')}</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </>
                )
            };
}

export default withTranslation()(ChargerConnectors);
