import React from 'react';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import Sidebar from './sidebar';
import Headers from './header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Form} from 'react-bootstrap';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';
import Header from './header';


class addUser extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          fields:{ password: '', confirmPassword:''},
          errors:{},
          id:'',
          page_title:"Change Password",
        }
        this.toggle1 = this.toggle1.bind(this)
        this.toggle2 = this.toggle2.bind(this)
        this.tagOk1 = this.tagOk1.bind(this)
        this.tagOk2 = this.tagOk2.bind(this)
        this.backToList = this.backToList.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
}


backToList(){
  this.props.history.push('/oauthUsers');
}
toggle1(event){
  // event.preventDefault();
  ;
  this.setState({
    modal1: !this.state.modal1
  });
}
toggle2 = () => {
  this.setState({
    modal2: !this.state.modal2
  });
}
tagOk1(){
   
  //this.props.history.push('/Ocpptags');
}
tagOk2(){
  //alert("gvjk");
   this.props.history.push('/Userlist');
 }
 componentDidMount(){
   this.setState({id:this.props.location.state})
 }

// checking the format of number

changeHandler(field, e){ 
  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields});
  }
  async handleSubmit(event){
      
      event.preventDefault();
      this.setState({
        modal1: !this.state.modal1
      });
        let errors = {};
          let url = baseUrl.URLPath + "system/authentication/changepassword";
          let data = {"id":this.state.id,"password": this.state.fields.password, "confirmPassword": this.state.fields.confirmPassword}; 
          
       
        // alert(JSON.stringify(data));
        await fetch(url, {
          method: 'PUT', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
           headers:{
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization"),
           
           }
          }).then(resp =>{
            
            if(resp.status==404){
              this.setState({errors: errors});
              return resp.json();
            } else if(resp.status==500){
               alert("internal server error")
            } else if(resp.status==400){
              return resp.json();
            } else if(resp.status==409){
              alert("User is already exists with this email id")
            } else{
              this.toggle2()
            }
          })
          .then(response=>{
            if(response !=null && response.hasOwnProperty("errorList")){
              if(response.errorList[0] === "Password length should not less then 6 characters"){
                errors["validpwd"] = "Password length should not less than 6 characters.";
              }
              if(response.errorList[0] == "User does not exists"){
                errors["user"] = "User does not exists.";
              }
              if(response.errorList[0] == "Password and confirm password is not matched"){
                errors["confirmpwd"] = "Password and confirm password does not match."
              }
             }
            
            this.setState({
              errors: errors
            });
          })
          .catch(error => alert('Error:' + error));
          this.setState({errors:errors});
    }

render() {
  return (
    <>
      <main class="content-div" >
      <p>Change Password</p>       
       <div className="page-outerdiv">


       <div className="row">
          <div className="col-md-12">
            <div className="pull-left">
              <button class="pull-right btn_primary" type="button" data-test="button" onClick={this.backToList} >
                <i className="fas fa-angle-left mr-2"></i>  Back
              </button>
            </div>
          </div>
        </div>
        
          {/* <p>Change Password</p> */}
      <MDBRow className="mb-4 mt-3">
            <MDBCol sm="12">
               {/* <p>Address</p> */}
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
{/* 
          <div className="col-md-12 mb-3">
          
          <span class=" form-title">User Details:</span> 
         
       </div> */}
         
             <MDBCol md="4">
              <MDBInput  className="pl-1"
              value={this.state.fields["password"]}
               onChange={this.changeHandler.bind(this, "password")}
                type="password"
                id="password"
                name="Password"
                label="Password"
              >
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["validpwd"]}</span></span> 
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["user"]}</span></span> 

                <div className="invalid-feedback">
                  Please provide a valid Email.
                </div>
                <div className="valid-feedback">Looks good!</div>
              </MDBInput>
            </MDBCol>
            <MDBCol md="4">
              <MDBInput  className="pl-1"
               value={this.state.fields["confirmPassword"]}
               onChange={this.changeHandler.bind(this, "confirmPassword")}
                type="password"
                id="confirmpassword"
                name="confirmpassword"
                label="Confirm Password"
              >
          <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["confirmpwd"]}</span></span> 

              </MDBInput>
            </MDBCol>
          </MDBRow>
         

          <MDBCol md="12" className="text-right mt-3">
              <button className="btn_primary mt-5 mr-2" type="button" data-test="button" onClick={this.toggle1}> Save <i class="fas fa-save ml-2"></i></button>
            
          </MDBCol>
          {/* onClick={this.addNew} */}
        </form>
      </div>
         </MDBCol>
        </MDBRow>
        </div>
        <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle1}>Confirmation</MDBModalHeader>
<MDBModalBody>
 Are you sure you want to save the changes?
</MDBModalBody>
<MDBModalFooter>
<MDBBtn color="danger" onClick={this.toggle1}>Close</MDBBtn>
<MDBBtn color="primary" onClick={this.handleSubmit}>YES</MDBBtn>
</MDBModalFooter>
</MDBModal>


<MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle2}>Success</MDBModalHeader>
<MDBModalBody>
Password updated successfully.
</MDBModalBody>
<MDBModalFooter>
{/* <MDBBtn color="danger" onClick={this.toggle2}>Close</MDBBtn> */}
<MDBBtn color="primary" onClick={this.tagOk2}>OK</MDBBtn>
</MDBModalFooter>
</MDBModal>
    </main>
   
    </>
  );
}
}

export default addUser;