//react & third party imports
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';

//component imports
import Sidebar from './views/layout/sidebar';
import Header from './views/layout/header';
import Dashboard from './views/dashboard/dashboard';
import StatusNotificationDescription from './views/dashboard/statusNotificationDescription';
import Login from './views/login';
import ChargePoint from './Controllers/ChargePointOverview';
import addChargePoint from './views/addChargePoint';
import Setting from './views/settings';
import inviteUser from './views/site-management/inviteUser';
import ChangeAvailability from './views/changeAvailability';
import ChangeConfiguration from './views/changeConfiguration';
import ChargingProfile from './views/chargingProfile';
import ClearCatch from './views/clearCatch';
import GetDiagnostics from './views/getDiagnostics';
import RemoteStartTransaction from './views/remoteStartTransaction';
import RemoteStopTransaction from './views/remoteStopTransaction';
import Reset from './views/reset';
import UnlockConnector from './views/unlockConnector';
import UpdateFirmware from './views/updateFirmware';
import ReserveNow from './views/reserveNow';
import CancelReservation from './views/cancelReservation';
import DataTransfer from './views/dataTransfer';
import GetConfiguration from './views/getConfiguration';
import GetLocalListVersion from './views/getLocalListVersion';
import SendLocalList from './views/sendLocalList';
import TriggerMessage from './views/triggerMessage';
import GetCompositeSchedule from './views/getCompositeSchedule';
import ClearChargingProfile from './views/clearChargingProfile';
import SetChargingProfile from './views/setChargingProfile';
import Unknowncp from './views/unknowncp';
import AddChargingProfile from './views/addChargingProfile';
import ConnectorStatus from './Controllers/ConnectorStatus';
import Reservations from './views/reservations';
import Transactions from './views/transactions';
import forgotPassword from './views/forgotPassword';
import Ocpptags from './views/ocpptags';
import Authorization from './Controllers/Authorization';
import ocppNewTag from './views/ocppnewtag';
import addstatusnotificationlist from './views/addstatusnotificationlist';
import ViewTag from './views/viewTag';
import bulkCPImport from './views/bulkCPImport';
import BulkFCPImport from './views/bulkFCPImport';
import ViewChargePoint from './views/viewChargePoint';
import logs from './views/logs';
import meterValues from './views/meterValues';
import Footer from './views/layout/footer';
import chargePointLogs from './views/chargePointLogs';
import viewUser from './views/viewUser';
import changePassword from './views/changePassword';
import Builds from './views/builds';
import addBuild from './views/addBuild';
import cpNotifications from './views/cpNotifications';
import editTag from './views/editTag';
import editChargePoint from './views/editChargepoint';
import EditUser from './views/editUser';
import editBuild from './views/editBuild';
import resetPassword from './views/resetpassword';
import UserManagementController from './views/userManagementController';
import AddUserManagement from './views/addUserManagement';
import OrganisationList from './views/organisationList';
import organisationAdd from './views/organisationAdd';
import organisationView from './views/organisationView';
import OrganisationEdit from './views/organisationEdit';
import updatePassword from './views/updatePassword';
import buildNotification from './views/buildNotification';
import InventoryList from './views/inventoryList';
import buildOrganization from './views/buildOrganization';
import uploadDiagnostics from './views/uploadDiagnostics';
import showDiagnostics from './views/showDiagnostics';
import log from './views/log';
import cpmsLogs from './views/cpmsLogs';
import { hasPermission } from './views/auth';
import ChargingSchedule from './views/chargingSchedule';
import AddChargingSchedule from './views/addChargingSchedule';
import ViewChargingProfile from './views/viewChargingProfile';
import ViewChargingSchedule from './views/viewChargingSchedule';
import EditChargingSchedule from './views/editChargingSchedule';
import EditChargingProfile from './views/editChargingProfile';
import statusnotificationlist from './views/statusnotificationlist';
import Preferences from './views/preferences';
import OwtChargePointList from './views/owtChargePointList';
import vehicles from './views/vehicles';
import CsrLanding from './Controllers/CsrLanding'
import viewDetails from './views/viewDetails';
import cpDashboard from './views/cpDashboard';
import vendor from './views/vendor';
import executeHistoryLogs from './views/executeHistoryLogs';
import errorLogs from './views/errorLogs';
import editInventory from './views/editInventory';
import viewInventory from './views/viewInventory';
import auditMail from './views/auditMail';
import cpPingPong from './views/cpPingPong';
import csCpLogs from './views/csCpLogs';
import userLoginHistory from './views/userLoginHistory';
import Inventory from './views/inventory';
import Notifications from './views/notifications';
import NotificationDetails from './views/NotificationDetails';
import oauthUsers from './views/oauthUsers';
import addOauthUser from './views/addOauthUser';
import EditStatusNotificationList from './views/editStatusNotificationList';
import SiteManagement from './views/site-management/siteManagement';
import * as baseUrl from './views/constants';
import FirmwareStatus from './views/firmwareStatus';
import Heartbeat from './views/Heartbeat';
import WebSocketMessage from './Socket/WebSocket';
import cpPingPongLogs from './views/cpPingPongLogs';

//css imports
import './App.css';

const history = require('history').createBrowserHistory;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: { value: "" },
      user: true,
      page_name: "",
      count: 0,
      getOperationList: [],
      modalsession: false
    }

    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
  }

  UNSAFE_componentWillMount() {
    var auth_token = localStorage.getItem("Authorization");
    if (auth_token !== "" && auth_token !== null) {
      localStorage.setItem("choosen_tenant", "none");
    }

    this.setState({
      page_name: window.location.href
    });

    var location = window.location.href;
    var id = location.split("/");

    if ((id.length == 4 && id[3] == "") || (id[4] == "resetpassword" || id[4] == "invite")) {
      this.setState({
        value: "1"
      })
    } else {
      var user = localStorage.getItem("user");
      if (user == null) {
        window.location.replace('/');
        this.setState({
          user: false
        })
      } else {
        this.getCustomization();
      }
    }
  }
  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  getCustomization = () => {
    let tenant = localStorage.getItem('tenant');
    let url = baseUrl.LoginPath + "/api/admin/v1.0/account/tenant/customization?orgName=" + tenant;
    
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then(resp => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(response => {
      this.setState({ getOperationList: response }, () => {
        console.log(this.state.getOperationList);
      })
    })
  }
  sessiontagOk() {
    window.location.replace('/');
    window.location.reload();
  }
  componentDidMount() {
    
  }
  
  render() {    
    if (this.state.value === "1") {
      return (
        // without header and sidebar pages 
        <>
          <BrowserRouter history={history} basename="/">
            <Route exact path="/" component={Login} />
            <Route exact path="/forgotPassword" component={forgotPassword} />
            <Route path="/:token/resetpassword" component={resetPassword} />
            <Route exact path="/updatePassword" component={updatePassword} />
            <Route path="/:token/invite" component={inviteUser} />
          </BrowserRouter>
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
            <MDBModalBody>
              Please login again.
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
            </MDBModalFooter>
          </MDBModal>
        </>
      );
    } else if (this.state.user) {
      const user = {
        permissions: []
      };
      if (localStorage.getItem("roleAccess") !== undefined) {
        user.permissions = localStorage.getItem("roleAccess");
      }

      return (
        <>
          <BrowserRouter history={history} basename="/">
            <div className="root">
              <div id="header">
                <Header {...this.props} />
              </div>
              <WebSocketMessage />
              <Sidebar user={user} />
              {/* Landing page */}
              <Route exact path="/dashboard" component={Dashboard} />
              <Route path="/preferences" render={(props) => <Preferences {...props} deeplink_R={hasPermission(user, ['Deeplink_Credentials_R'])} deeplink_W={hasPermission(user, ['Deeplink_Credentials_W'])} />} />
              {
                this.state.getOperationList?.map((operationObject, index) => {
                  if (operationObject.operation == "Dashboard") {
                    return operationObject.subOperations.map((operation, i) => {
                      if (operation.name == "Inventory" && operation.access == true) {
                        return <Route path="/inventory" component={Inventory} />
                      }
                    })
                  } else if (operationObject.operation == "ChargePoints" && operationObject.active == true) {
                    return operationObject.subOperations.map((operation, i) => {
                      if (operation.name == "Overview" && operation.access == true) {
                        return <>
                          {/* Chargepoints */}
                          {hasPermission(user, ['ChargePoint_R']) && <Route path="/chargePoint" render={(props) => <ChargePoint ChargePoint_W={hasPermission(user, ['ChargePoint_W'])} {...props} />} />}
                          {hasPermission(user, ['ChargePoint_W']) && <Route path="/addChargePoint" component={addChargePoint} />}
                          {hasPermission(user, ['ChargePoint_U']) && <Route path="/editChargePoint" component={editChargePoint} />}
                          {hasPermission(user, ['ChargePoint_R']) && <Route path="/viewChargePoint" render={(props) => <ViewChargePoint ChargePoint_W={hasPermission(user, ['ChargePoint_U'])} {...props} />} />}
                          {hasPermission(user, ['ChargePoint_R']) && <Route path="/bulkFCPImport" render={(props) => <BulkFCPImport {...props} />} />}
                        </>
                      } else if (operation.name == "Authorization" && operation.access == true) {
                        return <>
                          {/* OCPP TAgs */}
                          {hasPermission(user, ['OCPPTags_R']) && <Route path="/Ocpptags" render={(props) => <Authorization ChargePoint_W={hasPermission(user, ['OCPPTags_W'])} {...props} />} />}
                          {hasPermission(user, ['OCPPTags_W']) && <Route path="/ocppNewTag" component={ocppNewTag} />}
                          {hasPermission(user, ['OCPPTags_R']) && <Route path="/viewTag" render={(props) => <ViewTag ChargePoint_W={hasPermission(user, ['OCPPTags_W'])} {...props} />} />}
                          {hasPermission(user, ['OCPPTags_W']) && <Route path="/editTag" component={editTag} />}
                        </>
                      } else if (operation.name == "Connectors" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ConnectorStatus_R']) && <Route path="/connectorStatus" component={ConnectorStatus} />}
                        </>
                      } else if (operation.name == "Charging Profile" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ChargingProfile_R']) && <Route path="/chargingProfile" render={(props) => <ChargingProfile ChargingProfile_W={hasPermission(user, ['ChargingProfile_W'])} {...props} />} />}
                          {hasPermission(user, ['ChargingProfile_W']) && <Route path="/addChargingProfile" component={AddChargingProfile} />}
                          {hasPermission(user, ['ChargingProfile_V']) && <Route path="/viewChargingProfile" component={ViewChargingProfile} />}
                          {hasPermission(user, ['ChargingSchedule_U']) && <Route path="/editChargingProfile" component={EditChargingProfile} />}
                        </>
                      } else if (operation.name == "Charging Schedule" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ChargingSchedule_R']) && <Route path="/chargingSchedule" render={(props) => <ChargingSchedule ChargingSchedule_W={hasPermission(user, ['ChargingSchedule_W'])} {...props} />} />}
                          {hasPermission(user, ['ChargingSchedule_W']) && <Route path="/addChargingSchedule" component={AddChargingSchedule} />}
                          {hasPermission(user, ['ChargingSchedule_V']) && <Route path="/viewChargingSchedule" component={ViewChargingSchedule} />}
                          {hasPermission(user, ['ChargingProfile_U']) && <Route path="/editChargingSchedule" component={EditChargingSchedule} />}
                        </>
                      }
                    })
                  } else if (operationObject.operation == "Unknown Charge Points" && operationObject.active == true) {
                    return <>
                      {hasPermission(user, ['unknown_R']) && <Route path="/unknowncp" component={Unknowncp} />}
                    </>
                  } else if (operationObject.operation == "Reservations" && operationObject.active == true) {
                    return <>
                      {hasPermission(user, ['Resevations_R']) && <Route path="/reservations" component={Reservations} />}
                    </>
                  } else if (operationObject.operation == "Charging Session" && operationObject.active == true) {
                    return <>
                      {hasPermission(user, ['Transactions_R']) && <Route path="/transactions" component={Transactions} />}
                      <Route path="/meterValues" component={meterValues} />
                    </>
                  } else if (operationObject.operation == "Settings") {
                    return operationObject.subOperations.map((operation, i) => {
                      if (operation.name == "OCPP" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['settings_W']) && <Route path="/settings" component={Setting} />}
                        </>
                      } else if (operation.name == "Users" && operation.access == true) {
                        return <>
                          {/* UserManagement Pages */}
                          {hasPermission(user, ['Users_R']) && <Route path="/userManagementController" component={UserManagementController} />}
                          {hasPermission(user, ['Users_R']) && <Route path="/addUserManagement" render={(props) => <AddUserManagement {...props} />} />}
                          {hasPermission(user, ['Users_R']) && <Route path="/editUser" render={(props) => <EditUser {...props} />} />}
                          <Route path="/userLoginHistory" component={userLoginHistory} />
                        </>
                      } else if (operation.name == "OAuth Users" && operation.access == true) {
                        return <>
                          <Route path="/oauthUsers" component={oauthUsers} />
                          <Route path="/addOauthUser" component={addOauthUser} />
                        </>
                      } else if (operation.name == "Organization" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['Organizations_R']) && <Route path="/organisationList" render={(props) => <OrganisationList ChargePoint_W={hasPermission(user, ['Organizations_W'])} {...props} />} />}
                          {hasPermission(user, ['Organizations_W']) && <Route path="/organisationAdd" component={organisationAdd} />}
                          {hasPermission(user, ['Organizations_R']) && <Route path="/organisationView" component={organisationView} />}
                          {hasPermission(user, ['Organizations_R']) && <Route path="/organisationEdit" render={(props) => <OrganisationEdit ChargePoint_W={hasPermission(user, ['Organizations_W'])} {...props} />} />}
                        </>
                      } else if (operation.name == "Build Notification" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['BuildNotification_R']) && <Route path="/buildNotification" component={buildNotification} />}
                        </>
                      } else if (operation.name == "Status Notification List" && operation.access == true) {
                        return <>
                        {hasPermission(user, ['StatusNotifications_R']) && <Route path="/statusnotificationlist" component={statusnotificationlist} />}
                        {hasPermission(user, ['StatusNotifications_R']) && <Route path="/addstatusnotificationlist" component={addstatusnotificationlist} />}
                        {hasPermission(user, ['StatusNotifications_R']) && <Route path="/editStatusNotification" component={EditStatusNotificationList} />}
                        </>
                      }
                    })
                  } else if (operationObject.operation == "Operations" && operationObject.active == true) {
                    return operationObject.subOperations.map((operation, i) => {
                      if (operation.name == "Logs" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['CSOprMsg_R']) && <Route path="/logs" component={logs} />}
                        </>
                      } else if (operation.name == "CPMS Logs" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['CSOprMsg_R']) && <Route path="/cpmsLogs" component={cpmsLogs} />}
                        </>
                      } else if (operation.name == "Change Availability" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ChangeAvailability_W']) && <Route path="/changeAvailability" component={ChangeAvailability} />}
                        </>
                      } else if (operation.name == "Change Configuration" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ChangeConfiguration_W']) && <Route path="/changeConfiguration" component={ChangeConfiguration} />}
                        </>
                      } else if (operation.name == "Clear Cache" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ClearCache_W']) && <Route path="/clearCatch" component={ClearCatch} />}
                        </>
                      } else if (operation.name == "Get Diagnostics" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['GetDiagnostics_W']) && <Route path="/getDiagnostics" component={GetDiagnostics} />}
                        </>
                      } else if (operation.name == "Remote Start Transaction" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['RemoteStartTransaction_W']) && <Route path="/remoteStartTransaction" component={RemoteStartTransaction} />}
                        </>
                      } else if (operation.name == "Remote Stop Transaction" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['RemoteStopTransaction_W']) && <Route path="/remoteStopTransaction" component={RemoteStopTransaction} />}
                        </>
                      } else if (operation.name == "Reset" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['Reset_W']) && <Route path="/reset" component={Reset} />}
                        </>
                      } else if (operation.name == "Unlock Connector" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['UnlockConnector_W']) && <Route path="/unlockConnector" component={UnlockConnector} />}
                        </>
                      } else if (operation.name == "Update Firmware" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['UpdateFirmware_W']) && <Route path="/updateFirmware" component={UpdateFirmware} />}
                        </>
                      } else if (operation.name == "Reserve Now" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ReserveNow_W']) && <Route path="/reserveNow" component={ReserveNow} />}
                        </>
                      } else if (operation.name == "Cancel Reservation" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['CancelReservation_W']) && <Route path="/cancelReservation" component={CancelReservation} />}
                        </>
                      } else if (operation.name == "Data Transfer" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['DataTransfer_W']) && <Route path="/dataTransfer" component={DataTransfer} />}
                        </>
                      } else if (operation.name == "Get Configuration" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['GetConfiguration_W']) && <Route path="/getConfiguration" component={GetConfiguration} />}
                        </>
                      } else if (operation.name == "Get Local List Version" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['GetLocalListVersion_W']) && <Route path="/getLocalListVersion" component={GetLocalListVersion} />}
                        </>
                      } else if (operation.name == "Send Local List" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['SendLocalList_W']) && <Route path="/sendLocalList" component={SendLocalList} />}
                        </>
                      } else if (operation.name == "Trigger Message" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['TriggerMessage_W']) && <Route path="/triggerMessage" component={TriggerMessage} />}
                        </>
                      } else if (operation.name == "Get Composite Schedule" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['GetCompositeSchedule_W']) && <Route path="/getCompositeSchedule" component={GetCompositeSchedule} />}
                        </>
                      } else if (operation.name == "Clear Charging Profile" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['ClearChargingProfile_W']) && <Route path="/clearChargingProfile" component={ClearChargingProfile} />}
                        </>
                      } else if (operation.name == "Set Charging Profile" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['SetChargingProfile_W']) && <Route path="/setChargingProfile" component={SetChargingProfile} />}
                        </>
                      }
                    })
                  } else if (operationObject.operation == "Maintenance" && operationObject.active == true) {
                    return operationObject.subOperations.map((operation, i) => {
                      if (operation.name == "Execution History Logs" && operation.access == true) {
                        return <>
                          <Route path="/executeHistoryLogs" component={executeHistoryLogs} />
                        </>
                      } else if (operation.name == "Error Logs" && operation.access == true) {
                        return <>
                          <Route path="/errorLogs" component={errorLogs} />
                        </>
                      } else if (operation.name == "Audit Mail" && operation.access == true) {
                        return <>
                          <Route path="/auditMail" component={auditMail} />
                        </>
                      } else if (operation.name == "OWT Charge Points" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['OWTInventory_R']) && <Route path="/owtChargePointList" component={OwtChargePointList} />}
                        </>
                      } else if (operation.name == "Inventory" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['Inventory_R']) && <Route path="/inventoryList" render={(props) => <InventoryList ChargePoint_W={hasPermission(user, ['BulkImport_W'])} {...props} />} />}
                          {hasPermission(user, ['Inventory_R']) && <Route path="/bulkCPImport" component={bulkCPImport} />}
                          <Route path="/editInventory" component={editInventory} />
                          <Route path="/viewInventory" component={viewInventory} />
                        </>
                      } else if (operation.name == "CP PingPong Old" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['cp_pingpong_R']) && <Route path="/cpPingPong" component={cpPingPong} />}
                        </>
                      } else if (operation.name == "CP PingPong" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['cp_pingpong_new_R']) && <Route path="/cpPingPongLogs" component={cpPingPongLogs} />}
                        </>
                      } else if (operation.name == "Heartbeat Logs" && operation.access == true) {
                        return <>
                          <Route path="/heartbeatLogs" component={Heartbeat} />
                        </>
                      }
                      else if (operation.name == "Firmware Status" && operation.access == true) {
                        return <>
                          <Route path="/firmwareStatus" component={FirmwareStatus} />
                        </>
                      } else if (operation.name == "Show Diagnostics" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['showDiagnostics_R']) && <Route path="/showDiagnostics" component={showDiagnostics} />}
                        </>
                      } else if (operation.name == "Upload Diagnostics" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['uploadDiagnostics_W']) && <Route path="/uploadDiagnostics" component={uploadDiagnostics} />}
                        </>
                      } else if (operation.name == "CP Logs" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['CPOprMsg_R']) && <Route path="/log" component={log} />}
                        </>
                      } else if (operation.name == "CS-CP Logs" && operation.access == true) {
                        return <>
                          <Route path="/csCpLogs" component={csCpLogs} />
                        </>
                      } else if (operation.name == "Charge Point Operation Messages" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['CPOprMsg_R']) && <Route path="/chargePointLogs" component={chargePointLogs} />}
                        </>
                      } else if (operation.name == "FOTA - Builds" && operation.access == true) {
                        return <>
                          {/* Build Page */}
                          {hasPermission(user, ['builds_R']) && <Route path="/builds" render={(props) => <Builds ChargePoint_W={hasPermission(user, ['builds_R'])} {...props} />} />}
                          {hasPermission(user, ['builds_W']) && <Route path="/addBuild" component={addBuild} />}
                          {hasPermission(user, ['builds_U']) && <Route path="/editBuild" component={editBuild} />}
                          {hasPermission(user, ['PublishNotification_R']) && <Route path="/buildOrganization" component={buildOrganization} />}
                        </>
                      } else if (operation.name == "Charge Point Notifications" && operation.access == true) {
                        return <>
                          {hasPermission(user, ['CP_Notification_R']) && <Route path="/cpNotifications" component={cpNotifications} />}
                        </>
                      }
                    })
                  } else if (operationObject.operation == "Market Place" && operationObject.active == true) {
                    return operationObject.subOperations.map((operation, i) => {
                      if (operation.name == "Vendor" && operation.access == true) {
                        return <>
                          <Route path="/vendor" component={vendor} />
                        </>
                      }
                    })
                  } else if (operationObject.operation == "Vehicles" && operationObject.active == true) {
                    return <>
                      <Route path="/vehicles" component={vehicles} />
                    </>
                  } else if (operationObject.operation == "CSR" && operationObject.active == true) {
                    return <>
                      {hasPermission(user, ['CSR_R']) && <Route path="/csrLanding" component={CsrLanding} />}
                      {hasPermission(user, ['CSR_R']) && <Route path="/viewDetails" component={viewDetails} />}
                      {hasPermission(user, ['CSR_R']) && <Route path="/cpDashboard" component={cpDashboard} />}
                      <Route path="/statusNotificationDescription" component={StatusNotificationDescription} />
                    </>
                  } else if (operationObject.operation == "Notifications" && operationObject.active == true) {
                    return <>
                      {hasPermission(user, ['PushNotifications_R']) && <Route path="/notifications" component={Notifications} />}
                      <Route path="/notification" component={NotificationDetails} />
                    </>
                  } else if (operationObject.operation == "Group Management" && operationObject.active == true) {
                    return <>
                      {hasPermission(user, ['Sitemanagement_R']) && <Route path="/sitemanagement" render={(props) => <SiteManagement {...props} Sitemanagement_W={hasPermission(user, ['Sitemanagement_W'])} />} />}
                    </>
                  }
                })
              }
              <Route path="/changePassword" component={changePassword} />
              <Footer />
            </div>
          </BrowserRouter>

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
            <MDBModalBody>
              Please login again.
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
            </MDBModalFooter>
          </MDBModal>
        </>
      )
    }
  }
}

export default App;
