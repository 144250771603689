import React from 'react';
import { MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();

 class EditStatusNotificationList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            fields:{ category:'', type:'', model:'', errorCode:'', info:'', description:'', pushId:'' },
            errors:{},
            categoryList:[],
            notificationType:[],
            models:[],
            modal2: false,
            modal1: false,
            errorStatus:'',
            modalsession: false
        }
        this.handleSubmit=this.handleSubmit.bind(this);
        this.toggle1=this.toggle1.bind(this);
        this.CloseSuccessPopup=this.CloseSuccessPopup.bind(this);
        this.changeHandler=this.changeHandler.bind(this);
        this.getCategoryList=this.getCategoryList.bind(this);
        this.getNotificationType=this.getNotificationType.bind(this);
        this.getModels=this.getModels.bind(this);
        this.getNotificationDetails=this.getNotificationDetails.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
    }
    componentDidMount(){
        this.getCategoryList();
        this.getNotificationType();
        this.getModels();
        this.getNotificationDetails();
    }
    async handleSubmit(event) {
        let errors = {};
        event.preventDefault();

        var category = this.state.fields['category'];
        var type = this.state.fields['type'];
        var model = this.state.fields['model'];
        var errorCode = this.state.fields['errorCode'];
        var info = this.state.fields['info'];
        var description = this.state.fields['description'];
        var pushid=this.state.fields["pushId"];

        if (category == '' || category == undefined) {
            $('.error_category').text(this.props.t('please_select_category'));
        }
        if (type == "" || type == undefined) {
            $('.error_type').text(this.props.t('select_type'));
        }
        if (model == "" || model == undefined) {
            $('.error_model').text(this.props.t('select_type'));
        }
        if (info == "" || info == undefined) {
            $('.error_info').text(this.props.t('information_not_be_empty'));
        }
        if (description == "" || description == undefined) {
            $('.error_description').text(this.props.t('description_not_be_empty'));
        }
        if(pushid == "" || pushid == undefined){
            $('.error_pushId').text(this.props.t('push_id_not_be_empty'));
        }
        if(category == "FW Update" || category == "Guest User" || category == "General"){
            ;
            if(category!="" && type!="" && model!="" && info!="" && description!="" && pushid!="" && category!=undefined && type!=undefined && model!=undefined && info!=undefined && description!=undefined && pushid!=undefined){
                let url = baseUrl.URLPath + "pushnotifications/update";

                let data = { "category": this.state.fields.category, "type": this.state.fields.type, "model": this.state.fields.model, "errorCode": this.state.fields.errorCode, "info": this.state.fields.info, "description": this.state.fields.description, "pushId": this.state.fields.pushId, "id": this.props.location.id};
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(data), // data can be `string` or {object}!
                    headers: {
                        "content-type": "application/json",
                        "Authorization": localStorage.getItem("Authorization"),
                    }
                }).then(resp => {
                    if (resp.status == 401) {
                        this.togglesession();
                        localStorage.clear();
                    }else{
                        return resp.json();
                    }
                })
                    .then((resp) => {
                        if (resp != undefined) {
                            if (resp.status == 200 || resp.status == this.props.t('succees_condition')) {
                                this.toggle1();
                            } else if (resp.status == 409 || resp.status == 400) {
                                this.setState({
                                    errorStatus: this.props.t(resp.message)
                                });
                                this.toggle2();
                            }
                        }
                    })
            }
        }else{
            ;
            if (errorCode == "" || errorCode == undefined) {
                $('.error_errorCode').text(this.props.t('error_code_not_be_empty'));
            }
            if (category != "" && type != "" && model != "" && errorCode != "" && info != "" && description != "" && pushid != "" && category != undefined && type != undefined && model != undefined && errorCode != undefined && info != undefined && description != undefined && pushid != undefined) {
                let url = baseUrl.URLPath + "pushnotifications/update";

                let data = { "category": this.state.fields.category, "type": this.state.fields.type, "model": this.state.fields.model, "errorCode": this.state.fields.errorCode, "info": this.state.fields.info, "description": this.state.fields.description, "pushId": this.state.fields.pushId, "id": this.props.location.id};
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(data), // data can be `string` or {object}!
                    headers: {
                        "content-type": "application/json",
                        "Authorization": localStorage.getItem("Authorization"),
                    }
                }).then(resp => {
                    if (resp.status == 401) {
                        this.togglesession();
                        localStorage.clear();
                    }else{
                        return resp.json();
                    }
                })
                    .then((resp) => {
                        if (resp != undefined) {
                            if (resp.status == 200 || resp.status == this.props.t('succees_condition')) {
                                this.toggle1();
                            } else if (resp.status == 409 || resp.status == 400) {
                                this.setState({
                                    errorStatus: this.props.t(resp.message)
                                });
                                this.toggle2();
                            }
                        }
                    })
            }
        }
        
        this.setState({ errors: errors });
    }
    toggle1 = () => {
        this.setState({
            modal1: !this.state.modal1
        });
    }
    toggle2 = () => {
        this.setState({
            modal2: !this.state.modal2
        });
    }
    CloseSuccessPopup() {
        this.props.history.push('/statusnotificationlist');
    }
    changeHandler(field, e) {
        let fields = this.state.fields;
        if(field=="category"){
            let target_value=e.target.value;
            if(target_value == "FW Update" || target_value == "Guest User" || target_value == "General"){
                this.state.fields.model = "All products";
            }else{
                this.state.fields.model = "";
            }
            if(this.state.fields.category == target_value){

            }else{
                // fields["description"]="";
                // fields["errorCode"]="";
                // fields["info"]="";
                // fields["type"]="";
                fields["category"] = target_value;
            }
        }else if(field=="pushId"){
            if(e.target.validity.valid){
                fields[field] = e.target.value;
                $(`.error_${field}`).text("");
            }
        }else{
            fields[field] = e.target.value;
            $(`.error_${field}`).text("");
        }

        this.setState({ fields: fields });
    }
    getCategoryList(){
        let url = baseUrl.URLPath + "pushnotifications/search/category";
        fetch(url, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else{
                return resp.json();
            }
        })
        .then((resp) => {
            if (resp != undefined) {
                this.setState({
                    categoryList: resp
                });
            }
        })
    }
    getNotificationType(){
        let url = baseUrl.URLPath + "pushnotifications/search/type";
        fetch(url, {
          method: 'GET',
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }
          }).then(resp =>{
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else{
                return resp.json();
            }
          })
          .then((resp) =>{
            if(resp!=undefined){
              this.setState({
                notificationType: resp
              });
            }
          })    
    }
    getModels(){
        var url = baseUrl.FOTAPath + "cpinventory/evmodel/";
        fetch(url, {
          method: 'GET',
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization"),
          }
          }).then(resp =>{
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else{
                return resp.json();
            }
          })
          .then((resp) =>{
            if(resp!=undefined){
              this.setState({
                models: resp
              });
            }
          })    
    }
    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }
    getNotificationDetails(){
        let url = baseUrl.URLPath + servicesUrl.getPushnotificationsDetails +  "?id="+this.props.location.id;
        fetch(url, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }else{
                return resp.json();
            }
        })
        .then((resp) => {
            if (resp != undefined) {
                if(resp.status == "SUCCESS"){
                    let fields = this.state.fields;
                    fields["category"] = resp.organisationDTOS[0].category;
                    fields["description"] = resp.organisationDTOS[0].description;
                    fields["errorCode"] = resp.organisationDTOS[0].errorCode;
                    fields["info"] = resp.organisationDTOS[0].info;
                    fields["model"] = resp.organisationDTOS[0].model;
                    fields["pushId"] = resp.organisationDTOS[0].pushId;
                    fields["type"] = resp.organisationDTOS[0].type;
                    this.setState({
                        fields: fields
                    });
                }
            }
        })
    }
    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }
    render(){
        const{t}=this.props
        let categories = this.state.categoryList.map((category, index) => {
            return (
                <option value={this.state.categoryList[index]}>{this.state.categoryList[index]}</option>
            );
        });

        let notificationType = this.state.notificationType.map((type, index) => {
            if (this.state.notificationType[index] != null) {
                return (
                    <option value={this.state.notificationType[index]}>{this.state.notificationType[index]}</option>
                );
            }
        });
        
        let models = this.state.models.map((model, index) => {
            if(this.state.fields.category == "Received Statusnotification from charger"){
                return (
                    <option value={this.state.models[index]}>{this.state.models[index]}</option>
                  );
            }
        });

        if(this.state.fields.category != "Received Statusnotification from charger"){
          models= <option value="All products">All products</option>;
        }
        
        return(
            <main class="content-div">
                <p>{t('statusnotificationlist')}
                    <div className="breadcrumb_div">
                        {t('Settings')} &gt; <Link to="/statusnotificationlist" > <span className="">{t('statusnotificationlist')}</span></Link>  &gt;<span className="breadcrumb_page">Edit</span>
                    </div>
                </p>
                <div className="page-outerdiv">
                    <form className='needs-validation' noValidate>
                        <MDBRow>
                            <MDBCol md="4">
                                <MDBInput
                                    name="pushId"
                                    value={this.state.fields["pushId"]}
                                    onChange={this.changeHandler.bind(this, "pushId")}
                                    type="text"
                                    id="pushId"
                                    label={t('push_id*')}
                                    pattern="[0-9]*"
                                />
                                <span className="error_msg w3-animate-top error_pushId" style={{ color: "red" }}></span>
                            </MDBCol>
                            <MDBCol md="4">
                                <div className="w_f">
                                    <select className="browser-default custom-select select_height " id="category" onChange={this.changeHandler.bind(this, "category")} value={this.state.fields.category}>
                                        <option value="">--{t('select_category')}*--</option>
                                        {categories}
                                    </select>
                                </div>
                                <span className="error_msg w3-animate-top error_category" style={{ color: "red" }}></span>
                            </MDBCol>
                            <MDBCol md="4">
                                <div className="w_f">
                                    <select className="browser-default custom-select select_height" id="type_status" onChange={this.changeHandler.bind(this,"type")} value={this.state.fields.type}>
                                        <option value="">--{t('select_notificationtype')}*--</option>
                                        {notificationType}
                                    </select>
                                </div>
                                <span className="error_msg w3-animate-top error_type" style={{ color: "red" }}></span>
                            </MDBCol>
                            <MDBCol md="4" className="mt-3">
                                <div className="w_f">
                                    <select className="browser-default custom-select select_height " id="model" onChange={this.changeHandler.bind(this,"model")} value={this.state.fields.model}>
                                        <option value="">--{t('select_model')}*--</option>
                                        {models}
                                    </select>
                                </div>
                                <span className="error_msg w3-animate-top error_model" style={{ color: "red" }}></span>
                            </MDBCol>
                            <MDBCol md="4" className="mt-3">
                                <MDBInput
                                    name="errorCode"
                                    value={this.state.fields["errorCode"]}
                                    onChange={this.changeHandler.bind(this, "errorCode")}
                                    type="text"
                                    id="errorCode"
                                    label={`Error Code${(this.state.fields.category=="FW Update" || this.state.fields.category=="Guest User" || this.state.fields.category=="General")? "":"*"}`}
                                />
                                <span className="error_msg w3-animate-top error_errorCode" style={{ color: "red" }}></span>
                            </MDBCol>
                            <MDBCol md="4" className="mt-3">
                                <MDBInput
                                    name="info"
                                    value={this.state.fields["info"]}
                                    onChange={this.changeHandler.bind(this, "info")}
                                    type="text"
                                    id="info"
                                    label={t('Information*')}
                                />
                                <span className="error_msg w3-animate-top error_info" style={{ color: "red" }}></span>
                            </MDBCol>
                            <MDBCol md="4" className="mt-3">
                                <MDBInput
                                    name="description"
                                    value={this.state.fields["description"]}
                                    onChange={this.changeHandler.bind(this, "description")}
                                    type="text"
                                    id="description"
                                    label={t('description_for_customer*')}
                                >
                                </MDBInput>
                                <span className="error_msg w3-animate-top error_description" style={{ color: "red" }}></span>
                            </MDBCol>
                            <MDBCol md="12" className="text-right">
                                <button className="mt-5 mr-3 btn_primary" onClick={this.handleSubmit} id="saveBtn" type="button" data-test="button">
                                    {t('update')}
                                </button>
                                <Link to="/statusnotificationlist">
                                    <button className="mt-5 btn_cancel" type="button" data-test="button">
                                        {t('cancel')} <i class="fas fa-times ml-2"></i>
                                    </button>
                                </Link>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </div>
                <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
                    <MDBModalBody>
                        {t('status_updated_successfully')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.CloseSuccessPopup} >{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
                    <MDBModalHeader toggle={this.toggle2}>{t('error')}</MDBModalHeader>
                    <MDBModalBody>
                        {this.state.errorStatus}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.toggle2} >{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>

                {/* popup for session expire */}
                <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                    <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
                    <MDBModalBody>
                        {t('please_login_again')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" className="btn_primary mt-2 mb-2" onClick={this.sessiontagOk}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
            </main>
        );
    }
}

export default withTranslation()(EditStatusNotificationList);