import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter } from 'mdbreact';
import {Card,Table,Pagination,Check,Accordion} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as baseUrl from './constants';
import  $ from "jquery";
import Header from './header';
import NotificationBody from './notificationBody';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
//import CustomToggle from './customToggle'

class cpNotications extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          modalsession:false,
            startDate:new Date(),
            collapseID: "",
            page_title:"Charge Point Notifications",
            items: [
             
            ],
            operationData:[]
          
        }
        this.setStartDate = this.setStartDate.bind(this);
        this.getOperationLog=this.getOperationLog.bind(this);
        this.increaseDate = this.increaseDate.bind(this);
        this.decreaseDate = this.decreaseDate.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.togglesession = this.togglesession.bind(this);
      //  this.getData=this.getData.bind(this);
    }

    async togglesession(){
      ;
      this.setState({
      modalsession: !this.state.modalsession
      });
    }
    
    
    sessiontagOk(){
      // this.props.history.push('/');
      history.push('/');
      window.location.reload();
    }
    
    increaseDate(){
      ;
     var incrementdate= this.state.startDate;
     var currentDate = new Date();
     currentDate.setHours(0,0,0,0);
     incrementdate.setHours(0,0,0,0);
    if(incrementdate.getTime() == currentDate.getTime()){
       $('#decreasedicon').addClass('icon_disable');
       
     }
      else {
   //alert(document.getElementById('fadeout').className);
     incrementdate.setDate(incrementdate.getDate()+1);
     this.setState({startDate:incrementdate});
     this.getOperationLog(incrementdate);
    }
     }
       decreaseDate(){
        var decrementdate= this.state.startDate;
        // alert("b"+decrementdate);
        decrementdate.setDate(decrementdate.getDate()-1);
        this.setState({startDate:decrementdate});
        this.getOperationLog(decrementdate);
        // alert("a"+decrementdate);
        $('#decreasedicon').removeClass('icon_disable');

      }
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }
  componentDidMount(){
    this.getOperationLog(this.state.startDate);
  }
  
  onRefresh(){
    this.getOperationLog(this.state.startDate);
  }

    getOperationLog(date){
      ;
      var timeZone=  localStorage.getItem("timezone");
     var date= this.formatDate(date);
     var url = baseUrl.URLPath + "cp/notifications?date="+date;
    //  http://devcpms.evprowebasto.com/cpms/cs/operations
      fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
        })
        .then((resp) => {
          ;
          if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          // alert(JSON.stringify(response));
          
          if(response!=undefined){
        this.setState({ items:response.data});
        // alert("data"+JSON.stringify(this.state.data));
        // this.setState({ total_charge_point:response.data.chargePointCount })
          }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    
    setStartDate(date){
      
        this.setState({ startDate: date })
     
        this.getOperationLog(date);
      
        
      }
      getData(id,e){
        ;
        var timeZone=  localStorage.getItem("timezone");

        var date=this.formatDate(this.state.startDate);
        var url = baseUrl.URLPath + "cs/operations/log/search/"+id;
      fetch(url,{
        method: "GET",
        headers: {
          "content-type":"application/json",
          "timeZone":timeZone
        }
        })
        .then((resp) => {
          ;
          if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          // alert(JSON.stringify(response));
          
          var txt="";
          for(var i=0;i<response.length;i++){
            var time=response[i].createdTime.split(" ");
            //<tr><td>"+response[i].id+"</td><td>"+response[i].type+"</td>
          txt+="<tr><td>"+response[i].msg+"</td><td>"+time[1]+"</td></tr>";
      
     
          }
          document.getElementById(id).innerHTML = txt;

          if(response!=undefined){
        this.setState({ operationData:response});
        // alert("data"+JSON.stringify(this.state.data));
        // this.setState({ total_charge_point:response.data.chargePointCount })
          }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
      }
    

      toggleCollapse(collapseID){
      
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }))
      } 
      
      
  
        


      // buttonChange(name,e){
      //   ;
      // // var button=  document.getElementById(name);
      // //   if (button.text() == " Show") {
      // //     button.html('<i class="fa fa-eye-slash"></i> Hide');
      // //   } else {
      // //     button.html('<i class="fa fa-eye"></i> Show');
      // //   }
      // }
    render() {
      const { collapseID } = this.state;
    const operationData=this.state.items.map((item, index) => {    
        var time=item.createdTime.split(" ");
       
          
 return(             
        
             <Card className="logs_row">
                 <Accordion.Toggle as={Card.Header} eventKey={index} id={item.id} onClick={this.toggleCollapse.bind(this,`custom-${index}`)}>
                  <div className="row">  <div class="col-md-4">{item.notification}  </div>
                  <div className="col-md-8 accordion_angle ">({time[1]}) <i className={ collapseID===`custom-${index}` ? "fa fa-minus" : "fa fa-plus"  } /></div>  </div>
                    {/* <label className="pull-right mb-0">{items.timestamp}</label> */}
                 </Accordion.Toggle>
                 <Accordion.Collapse eventKey={index} id={`custom-${index}`} >
        <Card.Body>
             <NotificationBody data={item}></NotificationBody>
          </Card.Body>
                 </Accordion.Collapse>
             </Card>
             
            
      )
    });

      return (
        <>
       <main class="content-div" >
            {/* <p className="breadcrumb_div">Operations > Logs</p> */}
            <p>Notifications
              <div className="breadcrumb_div">
                Maintenance &gt; <span className="breadcrumb_page">Notifications</span>
              </div>
            </p>
            <div className="page-outerdiv">
            {/* <div className="breadcrumb_div">Maintenance > Notifications</div> */}

              {/* page title */}
              {/* <p>CPMS Operations Logs</p> */}
              {/* end page title */}
              {/* page menu bar */}
              <div className="row">
                    <div className="col-md-12 ">
                    <button class="refresh-btn pull-right" type="button" onClick={this.onRefresh}>
{/* <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
   Refresh <i className="fas fa-refresh fa-sm"></i>
</button>
                      {/* <div  id="refreshbtn">  
                          <button class="btn btn-primary" type="button" onClick={this.onRefresh}>
                            Refresh
                          </button>
                      </div> */}
                        <div className="">
                          <div className="row">
                            <div className="col-md-4">
                           </div>
                            <div className="col-md-12 text-center"> <lable className="dated"></lable>  <span id="decreased" onClick={this.decreaseDate}><a><i class="fas fa-angle-left" aria-hidden="true"></i></a></span>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={date => this.setStartDate(date)}
                                timeIntervals={1}
                                className="cal_margin b-none cal_w"
                                timeCaption="time"
                                dateFormat="dd-MMM-yyyy"
                                maxDate={new Date()}

                            />
                        <span id="increased" onClick={this.increaseDate}><a><i class="fa fa-angle-right"id="decreasedicon" aria-hidden="true"></i></a></span>
                       </div></div>
                        </div>
                    </div>
                </div>
              {/* <div className="row menubar mt-4">
              <div className="col-md-12 ">
                <div className="border_bottom">
                  <button >
                  <span>Operation Name</span>

                  </button>
                  <button >
                  <Link to="./allLogs" className="waves-effect"><i class="fas fa-clipboard-list"></i><span>By Date</span></Link>

                  </button>
                  </div>
                  </div>
                
                  </div> */}
                
              
              {/* end page menu bar  */}
              
              {/* table start */}
             <div className="row mt-3 ">
               <div className="col-md-12">



              <lable className="log_title">
                <div className="row">
                <div className="col-md-4">
                
                <span className="operation-text">  Notification Name </span></div> <div className="col-md-8" id="righttext">   <span className="operation-text"> Time(GMT 24hrs)</span></div>
                </div>
                </lable>
                <div className="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true"></div>
                <Accordion defaultActiveKey="0">
                 {operationData}
                </Accordion>
              </div>
              <div className="col-md-12 mt-4">
                {/* <Pagination className="pull-right">
                  <Pagination.First />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item active>{12}</Pagination.Item>
                  <Pagination.Item>{13}</Pagination.Item>
                  <Pagination.Item disabled>{14}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Last />
                </Pagination> */}
              </div>
              
              {/* End table  */}
              </div>
              </div>

              {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
      </MDBModalFooter>
      </MDBModal>
          </main>
     </>
      );
    }
}

export default cpNotications;