import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import Sidebar from './sidebar';
import Headers from './header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import * as baseUrl from './constants';
import { Link } from 'react-router-dom';
import Header from './header';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();



class buildNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledAttr: true,

      fields: { id: '', buildId: '', sentTO: '', sentCC: '', sentDate: '', status: '', organization: '' },
      sucess: {},
      errors: {},
      activeItem: "1",
      model: "false",
      page_title: "Build Notification",
      dataArray: [],
      identifier: '',
      disabled: "true",
      modalsession: false,
    }
    this.changeHandler = this.changeHandler.bind(this);
    this.organizationChange = this.organizationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPublicnotifications = this.getPublicnotifications.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglesession = this.togglesession.bind(this)


  }

  tagOk() {
    window.location.reload();
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  async togglesession() {
    
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }


  // checking the format of number
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  organizationChange(event) {
    this.state.identifier = event.target.value;
    var x = document.getElementById("organization").value;
    this.state.identifier = x;

    // alert(x)
    let fields = this.state.fields;
    fields["organization"] = x;
    this.setState({ fields });
    this.getPublicnotifications();
    this.setState({ disabledAttr: "" });


  }
  componentDidMount() {

    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        
        if (response != undefined) {
          this.setState({ dataArray: response.organisationDTOS })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }


  getPublicnotifications() {

    var url = baseUrl.URLPath + servicesUrl.getPublishnotificationsByOrg + "?organization=" + this.state.identifier;
    // /api/publicnotifications/getbyorg/{organization}
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        
        if (response != undefined) {
          this.setState({
            fields: response
          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  async handleSubmit(event) {
    event.preventDefault();
    
    let errors = {};

    let url = baseUrl.URLPath + "publishnotifications";
    //  /api/publishnotifications
    let data = { "tenantId": this.state.identifier, "buildId": this.state.fields.buildId, "sentTO": this.state.fields.sentTO, "sentCC": this.state.fields.sentCC, "sentDate": this.state.fields.sentDate, "status": this.state.fields.status };
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }

    }).then(resp => {
      
      if (resp.status == 401) {
        this.togglesession();
        // alert("Access token is expired, please login again")
        localStorage.clear();
      }
      else if (resp.status == 404) {
        this.setState({ errors: errors });

      } else if (resp.status == 500) {
      }

      else if (resp.status == 409) {
        this.setState({
          errors: errors
        });
      }
      else if (resp.status == 400) {
      } else {
        this.toggle()

      }
    })
      .catch(error => alert('Error:' + error));

    this.setState({ errors: errors });
  }

  checkBoxchange() {
    var y = document.getElementById("checkByemail").checked;
    if (y) {
      this.setState({ disabled: "" })
    }
    else {
      this.setState({ disabled: "true" })
    }
    this.setState({ disabledAttr: "" });

  }

  render() {
    const{t}=this.props
    const organisation = this.state.dataArray.map((dataArray, index) => {

      return (
        <option value={dataArray.identifier}  >{dataArray.company}</option>
      )
    })

    return (
      <>
        <main class="content-div" >
          <p>{t('Build Notification')}
              <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page">{t('Build Notification')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>
                      <MDBCol md="4" >
                        <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.organizationChange.bind(this)}>
                          <option value="">-- {t('select_organization')}--</option>
                          {organisation}
                        </select>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["organisationempty"]}</span></span>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="mb-2">
                      <MDBCol md="4">
                        <div class=" form-title">
                          <Form.Check
                            custom
                            value={this.state.status}

                            onChange={this.checkBoxchange.bind(this, "checkByemail")}
                            type="checkbox"
                            id="checkByemail"
                            label={t('notify_by_email')}
                          />

                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="">

                      <MDBCol md="1" className="text-center">
                        <label for="email" className="pt-3">{t('to')} </label>
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBInput
                          disabled={this.state.disabled}
                          value={(this.state.fields["sentTO"] ? this.state.fields["sentTO"] : "")}
                          onChange={this.changeHandler.bind(this, "sentTO")}
                          type="text"
                          id="sentTO"
                          name="Email"
                          label={t('e-mail')}
                        >
                          <div className="invalid-feedback">
                            {t('provide_valid_email')}
                </div>
                        </MDBInput>
                      </MDBCol>

                    </MDBRow>

                    <MDBRow className="mb-2">
                      <MDBCol md="1" className="text-center">
                        <label for="emailcc" className="pt-3">{t('cc')} </label>
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBInput
                          disabled={this.state.disabled}
                          value={(this.state.fields["sentCC"] ? this.state.fields["sentCC"] : "")}
                          onChange={this.changeHandler.bind(this, "sentCC")}
                          type="text"
                          id="sentCC"
                          name="Email"
                          label={t('e-mail')}
                        // className="text_lowercase"
                        >
                          <div className="invalid-feedback">
                          {t('provide_valid_email')}
                </div>
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>
                    {/* <MDBBtn color="primary" type="submit" className="mt-5" style={{color:"#fff"}} onClick={this.backToList}> 
               fvf
          </MDBBtn> */}
                    <button type="button" data-test="button" className="btn_primary mt-5" onClick={this.handleSubmit}>{t('save')} <i class="fas fa-save ml-2"></i></button>
                    {/* <MDBBtn color="primary" className="mt-5" onClick={this.handleSubmit}>Save</MDBBtn> */}
                  </form>
                </div>
              </MDBCol>
            </MDBRow>
          </div>

        </main>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('build_Notification_saved')}
      </MDBModalBody>
          <MDBModalFooter>
          <button className="btn_primary"  data-test="button" type="button" onClick={this.tagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}

        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
      </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
            <button className="btn_primary"  data-test="button" type="button" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>

      </>
    );
  }
}

export default withTranslation()(buildNotification);