import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Header from './header';

class clearCatch extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
         cpList:[],
           fields:{

           },
           sucess:false,
           colour:'sucess',
           errors:{},
           page_title:"Clear Cache",
           chargePoint:'',
           operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
}
onShowAlert(){
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
async handleSubmit(event) {
  
  var fdsfs=this.state;
var errors={};
if(this.getCPList()==0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
}

if(this.getCPList()!=0){
  var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
 // alert(response);
 if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
  this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
    this.onShowAlert();
  })
} else {
  this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
    this.onShowAlert();
  })
}
}
this.setState({errors:errors})


}

getCPList() {
  var Id = this.state.operationId;
 if(Id != null && Id!=""){
  var cplist = Id.split();

 }
 else{
  var cplist = this.state.cpList;

}
return cplist;
}

getPayLoad() {
  var payload ={
   
  };
  
  return payload;

}

getMessageType() {
  return constants.CLEAR_CACHE;
}
getSelectedCP(cpList){
  ;
  this.setState({cpList:cpList});
}
componentDidMount(){
  let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
}
componentWillUnmount(){
  localStorage.setItem('chargePoint', '');
  localStorage.setItem('operationId', '');
}
render() {
  const {t}=this.props
  return (
    <>
   
   <main class="content-div" >
   <p>{t('Clear Cache')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">{t('Clear Cache')}</span>
              </div>
           </p>
         {/* <p className="breadcrumb_div"><a>Operations ></a> <a> Clear Cache </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations ></a> <a> Clear Cache </a></div> */}

          {/* <p>Clear Cache</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
              
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
            <MDBRow>
         <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName = "ClearCache" ></ChargePointSelection>
         <MDBCol md="12">
         <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
         </MDBCol>
          </MDBRow> 
         
          <button onClick={this.handleSubmit} type="button" data-test-="button" className="mt-5 refresh-btn">{t('perform')}</button>
          
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
        
        </div>
   
    </main>
    </>
  );
}
}

export default withTranslation()(clearCatch);