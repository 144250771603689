import React from 'react';
import SockJsClient from 'react-stomp';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from '../views/constants';

const SOCKET_URL = baseUrl.LoginPath + '/ws-message';

export default class WebSocketMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promoted: [],
            demoted: [],
            _GroupName: '',
            modalsession: false,
            alert: false,
            isPromoted: false
        }
        this.onConnected = this.onConnected.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.updatePermissions = this.updatePermissions.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.togglesession = this.togglesession.bind(this);
    }

    onConnected() {
        console.log("Websocket Connected!")
    }

    onMessageReceived(response) {
        console.log("Received the message");
        this.setState({
            promoted: response.promotedList,
            demoted: response.demotedList
        },()=>{
            if(this.state.promoted && this.state.promoted.includes(localStorage.getItem('userId')) || 
               this.state.demoted && this.state.demoted.includes(localStorage.getItem('userId'))){
                this.updatePermissions();
            }
        })
    }

    updatePermissions(){
        const url = `${baseUrl.URLPath}v1.0/user/permissions/refresh`;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        }).then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }
            if (resp.status == 500) {
                console.log("internal server error")
            }
            if (resp.status == 200) {
                return resp.json();
            }
        }).then((response) => {
            if(response?.roleAccess){
                localStorage.setItem('roleAccess', response?.roleAccess);
                window.location.replace('/dashboard')
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        });
    }

    tagOk(){
        window.location.reload();
    }

    refreshOk(){
        window.location.replace('/dashboard');
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    render() {
        return(
            <>
                <SockJsClient
                    url={SOCKET_URL}
                    topics={['/topic/message']}
                    onConnect={this.onConnected}
                    onDisconnect={console.log("Disconnected!")}
                    onMessage={msg => this.onMessageReceived(msg)}
                    debug={false}
                />

                <MDBModal isOpen={this.state.modalsession} size="md" className="model_top">
                    <MDBModalHeader>Session has expired</MDBModalHeader>
                    <MDBModalBody>
                        Please login again
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>OK</button>
                    </MDBModalFooter>
                </MDBModal>

                <MDBModal isOpen={this.state.alert} size="md" className="model_top">
                    <MDBModalHeader>Alert</MDBModalHeader>
                    <MDBModalBody>
                        {this.state._GroupName}: Your Role is {this.state.isPromoted? 'promoted': 'demoted'} as {this.state.isPromoted? 'WEBASTO_SITE_ADMIN': localStorage.getItem('role')}. So portal will reflect those changes accordingly.
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.refreshOk}>OK</button>
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}