export const displaySelectedChargePoints = (allChargePoints) => {
    for (var i = 0; i < allChargePoints.length; i++) {
      var txt = document.getElementById("idStatus").innerHTML;
      txt += "<span class='cpChargePointSpan' id=chargepoint_span" + allChargePoints[i] + ">" +
      allChargePoints[i] + "<i class='fa fa-times pl-1 pr-1' id=" + allChargePoints[i] + " )}></i></span>";
      document.getElementById("idStatus").innerHTML = txt;
    }
}

export const storedSelectedCP =  (id, cpList, finalStatus,optionsdropdown) => {
    
    var cpLength = cpList.length;
    var cp_data  =  cpList.indexOf(id)
    let isChecked = document.getElementById(`custom-${id}`).checked;
    var disabledAttr = false;
    // setter
    localStorage.setItem('chargePointId', id);
    if (isChecked) {
      var array1 = cpList;
      var array2 = finalStatus;
      var array3 = array1.concat(array2);
      cpList = array1;
      document.getElementById("cpRightSide").style.display = "table-cell";
      var txt = document.getElementById("idStatus").innerHTML;
      cpList.push(id);
      if (optionsdropdown.length == 1 && finalStatus.length == 1) {
         disabledAttr = true;
      }
    }
    else {
      var idList = cpList;
      if (idList.includes(id) == true) {
        var index = cpList.indexOf(id);
        idList.splice(index, 1);
        cpList = idList 
        // document.getElementById("chargepoint_span" + id).remove();
      }
      if (optionsdropdown.length == 1 && finalStatus.length == 1) {
          disabledAttr = false;
      }
      if(cpList.length==0){
        document.getElementById("cpRightSide").style.display = "none";
      }
    }
    return {cpList , disabledAttr}
  }


 