import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility';
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import Header from './header';
import { withTranslation } from 'react-i18next';

class triggerMessage extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
           fields:{
              requestedMessage:'BootNotification',
              connectorId:""

            },
          cpList:[],
          sucess:false,
          colour:'sucess',
          page_title:"Trigger Message",
          errors:{},
          chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);

}
onShowAlert(){
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
//This is for making user should enter only numbers
numberHandler(field, e){ 
    let fields = this.state.fields;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }

  async handleSubmit(event) {
    
    var fdsfs=this.state;
    var fdfd=this.getPayLoad();
    var dfdff= this.getMessageType();
    var payload=this.getPayLoad();
    var requestedMessage=payload.requestedMessage;
    var trigger =document.getElementById("requestedMessage").value;
  //  alert(requestedMessage);
  //  alert(document.getElementById("requestedMessage").value);
  
    var errors={};
  if(this.getCPList()==0){
    errors["emptyChargePoint"]=this.props.t('select_charge_point')
  }
  
  if(trigger==`-- ${this.props.t('select_trigger_message')} --`){
      errors["emptytriggermessage"]=this.props.t('please_select_trigger_msg')
  }
  if(this.getCPList()!=0 && trigger!=`-- ${this.props.t('select_trigger_message')} --`){
    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
    if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
      this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
        this.onShowAlert();
      })
    } else {
      this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
        this.onShowAlert();
      })
    }
    }
    this.setState({errors:errors})
  }
  getCPList() {
    var Id = this.state.operationId;
 if(Id != null&& Id != ""){
     var cplist = Id.split();
   
    }
    else{
     var cplist = this.state.cpList;
   
   }
   return cplist;
  }
  
  getPayLoad() {
    var payload =
    
    { 
     "requestedMessage": this.state.fields.requestedMessage,
     "connectorId": parseInt(this.state.fields.connectorId),
    
    };
    if(isNaN(payload.connectorId)){
      delete payload.connectorId;
    }

  //  or
  //   {
  
  //     "requestedMessage": "BootNotification",
  //     "connectorId": 1
  //   }
     
    return payload;
  
  }
  
  getMessageType() {
    return constants.TRIGGER_MESSAGE;
  } 
  requestedMessageChange(){
  
    var x = document.getElementById("requestedMessage").value;
    let fields = this.state.fields;
    fields["requestedMessage"] = x;
    this.setState({fields});
    if(x!=`-- ${this.props.t('select_trigger_message')} --`){
   
      this.state.errors["emptytriggermessage"]=" "
   
    }
   
  }
  getSelectedCP(cpList){

    this.setState({cpList:cpList});
    if(cpList!=""){
     
      this.state.errors["emptyChargePoint"]=" "
     
    }
 
  }
  componentDidMount(){
    let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
  }
  componentWillUnmount(){
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }
  componentDidMount(){
    let propsdata = this.props.location.state;
    if(propsdata!=undefined){
      if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }  
  }
render() {
  const{t}=this.props
  return (
    <>
    <main class="content-div" >
    <p>  {t('Trigger Message')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Trigger Message')} </span>
              </div>
           </p>
        {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Trigger Message </a></p> */}
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Trigger Message </a></div> */}

          {/* <p>Trigger Message</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

      {/* <section className="dashboard"> */}
        {/* <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left">Trigger Message </h4>
                <div class="clearfix"></div>
            </div>
        </div> */}
        {/* <section className="content_wbg">  */}
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
              
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
            <MDBRow>
              {/* <MDBCol md="12">
              <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">
                <button class="mr-2 select_btn btn_cursor" > Select All</button> <button class="mr-4 select_btn btn_cursor">Select None </button>
              </label>
              <select className="browser-default custom-select select_height1" id="confKeyList" name="confKeyList" multiple="multiple" size="14"></select>
              </div>
              </MDBCol> */}
            <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP}operationName ="TriggerMessage" ></ChargePointSelection>
            <MDBCol md="12">
       <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
        </MDBCol>
              <MDBCol md="4">
              <select className="browser-default custom-select select_height mb_8" id="requestedMessage" onChange={this.requestedMessageChange.bind(this)}>
              <option >-- {t('select_trigger_message')} --</option>
              <option value="BootNotification">{t('boot_notification')}</option>
              <option value="DiagnosticsStatusNotification">{t('diagnostics_notification')}</option>
              <option value="FirmwareStatusNotification">{t('firmware_notification')}</option>
              <option value="Heartbeat">{t('heart_beat')}</option>
              <option value="MeterValues">{t('meter_values')}</option>
              <option value="StatusNotification">{t('status_notification')}</option>
              </select>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptytriggermessage"]}</span></span>
                
              </MDBCol>

              <MDBCol md="4">
            <MDBInput
              value={this.state.fields["connectorId"]}
              name="ConnectorID"
                onInput={this.numberHandler.bind(this,"connectorId")}
                type="text"  pattern="[0-9]*"
             
                id="connectorID"
                label={t('connector_id_integer')}
                required
              >
 <small id="heartbeatinterval" className="form-text text-muted">
                {t('if_empty0charge_point')}
                </small>
                <div className="valid-feedback">{t('looks_good')}</div>
               
              </MDBInput>
         </MDBCol>
         </MDBRow> 
         <button className="mt-5 refresh-btn" type="button" data-test="button" onClick={this.handleSubmit} >{t('perform')}</button>
        </form>
      </div>
         </MDBCol>
        </MDBRow>
        </div>
    </main>
    </>
  );
}
}

export default withTranslation()(triggerMessage);