import React from 'react';
import { MDBDataTable, MDBIcon, Select, MDBCol, MDBInput, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class FirmwareStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageSize: "10",
      noOfRecords: '',
      pageNo: 1,
      errors: {},
      fields: {
        chargePointId: ''
      },
      modalsession: false,
      firmwareUpdates: [],
      fromDateApi: '',
      toDateApi: '',
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: []
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.getFirmwareUpdateStatus = this.getFirmwareUpdateStatus.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
        listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
}

  componentDidMount() {
    this.getFirmwareUpdateStatus(this.state.pageSize, this.state.pageNo);
  }

  getFirmwareUpdateStatus(pageSize, pageNo, countValue) {
    let cpid = this.state.fields.chargePointId;
    var url = baseUrl.URLPath + "updateFirmwareStatus?cpId=" + cpid + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((result) => {
        if (result.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (result.status == 200 || result.status == 201) {
          return result.json();
        }
        if (result.status == 500) {

        }
        if (result.status == 404) {

        }
      })
      .then((response) => {
        ;
        if (response != undefined) {
          if (response.count == 0) {
            this.setState({
              firmwareUpdates: [],
              noOfRecords: 0
            });
          } else {
            if(countValue == false){
              this.setState({
                firmwareUpdates: response.firmwareUpdates,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                curPageFirstCreTime: moment(response.firmwareUpdates[0].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.firmwareUpdates[response.firmwareUpdates.length - 1].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              });
            }else{
              this.setState({
                firmwareUpdates: response.firmwareUpdates,
                noOfRecords: response.count,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                curPageFirstCreTime: moment(response.firmwareUpdates[0].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.firmwareUpdates[response.firmwareUpdates.length - 1].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              });
            }
          }
        } else {
          this.setState({
            firmwareUpdates: [],
            noOfRecords: 0
          });
        }
      })
      .catch((error) => {
        console.log("Firmware Update Status api failed");
      })
  }
  
  handlePageChange(pageNumber) {
    ;
    if (pageNumber < this.state.activePage) {
        //prev
        this.setState({
            activePage: pageNumber,
            fromDateApi: this.state.curPageFirstCreTime,
            toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
        }, () => {
          this.getFirmwareUpdateStatus(this.state.pageSize, pageNumber, false);
        });
    } else {
        //next
        
        let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
        this.setState({
            activePage: pageNumber,
            fromDateApi: '',
            toDateApi: this.state.curPageLastCreTime,
            prevPageFirstCreTime: this.state.curPageFirstCreTime,
        }, () => {
            if (pageNumber > _datesArrayLength + 1) {
                this.createdDatesArray(this.state.curPageFirstCreTime);
            }
            this.getFirmwareUpdateStatus(this.state.pageSize, pageNumber, false);
        });
    }
}

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.activePage = 1;
    this.state.pageSize = entries;
    this.getFirmwareUpdateStatus(entries, 1);
  }
  onChangeChargepoint() {
    var chargePoint = document.getElementById("chargePointId").value;

    this.setState({ fields: { chargePointId: chargePoint } }, () => {
      this.getFirmwareUpdateStatus(this.state.pageSize, 1);
    });


    if (this.state.fields.chargePointId == "") {
      this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
      $("#notexists").text(" ");

    } else {
      this.state.errors["entercharge"] = " "
      this.getFirmwareUpdateStatus(this.state.pageSize, 1, "click");
    }

  }
  keyPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ fields: { chargePointId: e.target.value } });
      this.getFirmwareUpdateStatus(this.state.pageSize, 1);
      if (this.state.fields.chargePointId == "") {
        this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
        $("#notexists").text(" ");
      } else {
        this.state.errors["entercharge"] = " "
        this.getFirmwareUpdateStatus(this.state.pageSize, 1, "enter");
      }
    }
    if (this.state.fields.chargePointId.length == 0) {
      this.getFirmwareUpdateStatus(this.state.pageSize, 1);
    }
    if (key == "Delete") {
      window.location.reload();
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }
  togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  sessiontagOk() {
    this.props.history.push('/');
    window.location.reload();
  }
  handleClick() {
    let fields = this.state.fields;
    fields.chargePointId = '';
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");
    this.setState({
        fields
    });
    this.state.pageSize = 10;
    this.state.activePage = 1;
    document.getElementById("showEntries").value = 10;
    this.getFirmwareUpdateStatus(this.state.pageSize, this.state.activePage);
}
  render() {
   const{t}=this.props
    return (
      <>
        <main class="content-div" >
          {/* page title */}
          <p>{t('Firmware Status')}
            <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Firmware Status')}</span>
            </div>
          </p>
          {/* end page title */}
          <div className="page-outerdiv">
            {/* page menu bar */}
            {/* end page menu bar  */}
            <div className="row mt-2">
              <div className="col-md-3 t-8">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onKeyDown={this.keyPress} onChange={(e) => {
                    this.setState({ fields: { chargePointId: e.target.value } }, () => {
                      if (this.state.fields.chargePointId.length == 0) {
                        this.state.activePage = 1;
                        this.getFirmwareUpdateStatus(this.state.pageSize, 1);
                      }
                    })
                  }} class="form-control input-searchBox pad-2" placeholder={t('charge_point_id')} name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint}>{t('find')}</button>
                  </div>
                </div>
                <span className="error_msg w3-animate-top mt-2"><span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}><span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
              </div>
              <div className="col-md-9 mt-2 pt-1">
                {/* <MDBCol md="3"  className="mt-3 pl-0"> */}
                <div className="pull-right">
                  <label className="sub-text pull-left">{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange} value={this.state.pageSize}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* table start */}
            <div className="row">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>{t('created_date_time')}</th>
                      <th>{t('charge_point_id')}</th>
                      <th>{t('location')}</th>
                      <th>{t('modified_time')}</th>
                      <th>{t('retries')}</th>
                      <th>{t('retry_interval')}</th>
                      <th>{t('chargingSessionState')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.firmwareUpdates.map((firmware, index) => {
                        if (firmware.createdTime == null || firmware.createdTime == "") {
                          var createdTime = "";
                          var createdTime1 = "";
                        } else {
                          var createdTime = moment(firmware.createdTime).format("DD MMM YYYY");
                          var createdTime1 = moment(firmware.createdTime).format("HH:mm");
                        }

                        if (firmware.modifiedTime == null || firmware.modifiedTime == "") {
                          var modifiedTime = "";
                          var modifiedTime1 = "";
                        } else {
                          var modifiedTime = moment(firmware.modifiedTime).format("DD MMM YYYY");
                          var modifiedTime1 = moment(firmware.modifiedTime).format("HH:mm");
                        }
                        return (
                          <tr>
                            <td className="wrap-word">{createdTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdTime1} </div> </>} </td>
                            <td>{firmware.chargePointId}</td>
                            <td>{firmware.location}</td>
                            <td className="wrap-word">{modifiedTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {modifiedTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {modifiedTime1} </div> </>} </td>
                            <td>{firmware.retries}</td>
                            <td>{firmware.retryInterval}</td>
                            <td>{firmware.status}</td>
                          </tr>
                        );
                      })
                    }
                    {
                      (this.state.firmwareUpdates.length == 0) ? <td colSpan={7} style={{ textAlign: "center" }}>{t('no_data_for_firmware_status')}</td> : null
                    }
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                  prevPageText={t('prev')}
                  nextPageText={t('next')}
                  itemClassPrev="prevBtn"
                  pageRangeDisplayed="1"
                  activeClass="activeLi active"
                  itemClassNext="nextBtn"
                  hideFirstLastPages={true}
                  disableInitialCallback={true}
                  disabledClass="disabled disabledtextColor"
                />
              </div>
              {/* End table  */}
            </div>
          </div>
        </main>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(FirmwareStatus);