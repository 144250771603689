import React from 'react';
import { MDBCol,MDBModal,MDBModalHeader,MDBModalBody,MDBRow,MDBModalFooter} from 'mdbreact';
import {Form,Table} from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import $ from 'jquery';
import {dateRangeValidate1,activeDateFormat} from './_methods';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class auditMail extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          viewdisplay:"",

          modalView: false,

          modalsession:false,

          fields:{
            chargePointId:"",
            serviceNameC:"",
            statusName:"",

          },
          startDate:'',
          toDate:'',
          startDate1:'',
          startDate2:'',
            page_title:"CPMS Logs",
            collapseID: "",
              items: [
              
              ],
              errorData:[],
            dataArray: [
              {
              
                chargepointid: '1',
                address: '',
                noofattempts:'',
            },
          ],
          noOfRecords:'',
          data:new Map(),
          pageSize:"10",
          activePage: 1,
          pageNo:1,
          calenderFrom: "none",
          calenderTo: "none"
      }
      this.GetFromDate = this.GetFromDate.bind(this);
      this.statusChange = this.statusChange.bind(this);
      this.getAuditMail = this.getAuditMail.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.showEntriesChange=this.showEntriesChange.bind(this);
      this.togglesession = this.togglesession.bind(this);
      this.sessiontagOk = this.sessiontagOk.bind(this);
      this.toggleView = this.toggleView.bind(this);
      this.toggleView1 = this.toggleView1.bind(this);
      this.onChangeStatusCode = this.onChangeStatusCode.bind(this);
      this.serviceNameChange = this.serviceNameChange.bind(this);
      this.changeFromdate=this.changeFromdate.bind(this);
      this.changeTodate=this.changeTodate.bind(this);
      this.keyPress1=this.keyPress1.bind(this);
      this.keyPress2=this.keyPress2.bind(this);
      this.displayCalender=this.displayCalender.bind(this);
      this.closeCalender=this.closeCalender.bind(this);
      this.formatCalDate1=this.formatCalDate1.bind(this);
}

// method to call onChange Service name
serviceNameChange(){
  var id = document.getElementById("serviceName").value;
  let fields = this.state.fields;
  fields["serviceNameC"] = id;
  this.setState({ serviceNameC: id }, () => {
    this.getAuditMail(this.state.pageSize,this.state.activePage);

   });}


 // method to call onChange status 
 statusChange(field,e){
  e.preventDefault();
  let fields = this.state.fields;
  if(e.target.validity.valid){
    fields[field] = e.target.value; 
    this.setState({fields:fields});
    fields.statusname = document.getElementById('statusName')
  }else if(e.target.value==""){
    fields[field] = e.target.value; 
    this.setState({fields:fields});
  }
}
formatCalDate1(date){
  var format = date.toISOString();
  var xyz = moment(format).format("DD MMM YYYY")
  return xyz;
}
changeFromdate(e){ 
  this.setState({startDate: e.target.value});
  }
changeTodate(e){
  this.setState({toDate: e.target.value});
  }
  keyPress1(e){
    var key = e.key;
    $('.future_err1').text("");
  if(e.keyCode == 13){
    if (new Date() >= new Date(e.target.value)) {
      this.setState({ startDate: e.target.value });
      var dateFormat = moment(this.state.startDate).format("YYYY-MM-DD");
      this.state.startDate1 = dateFormat;
      this.getAuditMail(this.state.pageSize, 1);
    } else {
      this.setState({ startDate: "" });
      this.state.startDate1 = "";
      $('.future_err1').text(this.props.t('cannot_select_futuredate'));
    }
    }
    if(key == "Delete" || key == "Backspace"){
      this.state.startDate1 = "";
      this.getAuditMail(this.state.pageSize,1);
      }
    
  }
  keyPress2(e){
  var key = e.key;
  $('.future_err2').text("");
  if(e.keyCode == 13){
    if (new Date() >= new Date(e.target.value)) {
      this.setState({ toDate: e.target.value });
      var dateFormat = moment(this.state.toDate).format("YYYY-MM-DD");
      this.state.startDate2 = dateFormat;
      this.getAuditMail(this.state.pageSize, 1);
    } else {
      this.setState({ toDate: "" });
      this.state.startDate2 = "";
      $('.future_err2').text(this.props.t('cannot_select_futuredate'));
    }
  }
  if(key == "Delete" || key == "Backspace"){
    this.state.startDate2 = "";
    this.getAuditMail(this.state.pageSize,1);
    }
  
  }
  displayCalender(type, event) {
    var calenderFrom = "";
    var calenderTo = "";
    if (type == "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }
    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }
  closeCalender(type, event) {
    if (type == "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }
GetFromDate = date => {
  var fdate = this.formatCalDate1(date);
    this.setState({ startDate: fdate });
    this.state.startDate1 = this.formatCalDate(date);
    // if(dateRangeValidate1(this.state.startDate1, this.state.startDate2)==true){
    //   $('.date_range_error').text('');
      this.getAuditMail(this.state.pageSize, 1);
      var calenderFrom = "none";
      this.setState({
        calenderFrom: calenderFrom
      });
    // }else{
    //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var calenderFrom = "none";
    //   this.setState({
    //     calenderFrom: calenderFrom
    //   });
    // }
    
};


dateChange = date => {
  var tdate = this.formatCalDate1(date);
  this.setState({toDate:tdate});
  this.state.startDate2 = this.formatCalDate(date);
  // if(dateRangeValidate1(this.state.startDate1, this.state.startDate2)==true){
  //   $('.date_range_error').text('');
    this.getAuditMail(this.state.pageSize,1);
    var calenderTo = "none";
    this.setState({
      calenderTo: calenderTo
    });
  // }else{
  //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
  //   var calenderTo = "none";
  //   this.setState({
  //     calenderTo: calenderTo
  //   });
  // }
  
};

// get date 
formatCalDate(date){
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  // return [year, month, day].join('-');
  return [month, day, year].join('-');

}

 //change function for chargepoint status filter
 onChangeStatusCode() {
  var statusCode = document.getElementById("statusName").value;
  this.setState({ statusName: statusCode }, () => {
      this.getAuditMail(this.state.pageSize,1);
    });
}




toggleView(errorMessage){
  this.setState({displayError:errorMessage})
  this.setState({
    modalView: !this.state.modalView
  });
}

toggleView1(){
  this.setState({
    modalView: !this.state.modalView
  }); 
}
  




async togglesession(){
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  history.push('/');
  window.location.reload();
}



showEntriesChange(){
  var entries=document.getElementById("showEntries").value;
  // this.state.data.clear();
  // this.setState({pageSize:entries});
  this.state.activePage=1;
  this.state.pageSize=entries;
  this.getAuditMail(entries,1);
}

  
handlePageChange(pageNumber) {
  if(pageNumber !=this.state.activePage){
    var data=this.state.data;
    var pageData=data.get(pageNumber);
    if(pageData==undefined){
  this.setState({activePage: pageNumber});
  this.getAuditMail(this.state.pageSize,pageNumber);
    }else{
      this.setState({activePage: pageNumber});
    }
  }
}



getAuditMail(pageSize,pageNo){
  let serviceTypes = this.state.fields.serviceNameC;
  let statusCode = this.state.fields.statusName; 

 var url = baseUrl.URLPath + "auditmail?serviceType="+serviceTypes+"&statusCode="+statusCode+"&formdate="+this.state.startDate1+"&todate="+this.state.startDate2+"&pageNo="+pageNo+"&pageSize="+pageSize;
//localhost:8080/api/auditmail?serviceType=WALLBOX_MESSAGE&statusCode=200&formdate=11-21-2021&todate=11-21-2021&pageNo=1&pageSize=10
   fetch(url,{
    method: "GET",
    headers: {
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
    })
    .then((resp) => {
      if(resp.status==401){
        // alert("Access token is expired, please login again")
        this.togglesession();
  
        localStorage.clear();
       
      }
      if(resp.status==200){
        return resp.json();
      }
    }) 
    .then((response) =>{
      if(response!=undefined){
      this.setState({
          items:response.auditMails,
          noOfRecords:response.count.count,
          
      })

    }

    })
    .catch((error) => {
        console.log(error, "catch the loop")
    })
 
}


  componentDidMount(){
    let fromDate = moment().subtract(29,'d').format('YYYY-MM-DD');
  let toDate = moment().format('YYYY-MM-DD');

  let startdate = moment().subtract(29,'d');
  let enddate = moment();

  this.state.startDate = this.formatCalDate1(startdate);
  this.state.toDate = this.formatCalDate1(enddate);

  this.state.startDate1 = fromDate;
  this.state.startDate2 = toDate;
  this.setState({
    startDate1: fromDate
  });
    this.getAuditMail(this.state.pageSize,this.state.activePage);
  }




render() {
   const{t}=this.props
  const errorData=this.state.items.map((item, index) => {    
    
    if(item.errorMessage == null || item.errorMessage == ""){
      this.state.viewdisplay = "none";

    }
    else{
      this.state.viewdisplay = "block";

    }
    if(item.createdDate==null || item.createdDate==""){
      var createdDate = "";
      var createdDate1="";
    }else{
      var createdDate = moment(item.createdDate).format("DD MMM YYYY");
      var createdDate1 = moment(item.createdDate).format("HH:mm");
    }

    if(item.mailSendDate==null || item.mailSendDate==""){
      var mailSendDate = "";
      var mailSendDate1="";
    }else{
      var mailSendDate = moment(item.mailSendDate).format("DD MMM YYYY");
      var mailSendDate1 = moment(item.mailSendDate).format("HH:mm");
    }

      return(             
        <tr>
          <td>{item.serviceName}</td>
          <td>{item.statusCode}</td>
          <td className="wrap-word">{ createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>} </td>
          <td className="wrap-word">{ mailSendDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {mailSendDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {mailSendDate1} </div> </>} </td>
          <td className="text-center"><i class="fas fa-eye action-icon cursor_defult"  style={{display:this.state.viewdisplay}} title="View"  onClick={this.toggleView.bind(this,item.errorMessage)} ></i></td>
      </tr>
      )
  });


  return (
    <>
 <main class="content-div" >
 <p>{t('Audit Mail')}
              <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Audit Mail')}</span>
              </div>
           </p>
        <div className="page-outerdiv">
          <div className="row mt-4">
          <MDBCol md="3" >
          <select className="browser-default custom-select select_height mb_8" id="serviceName"  onChange={this.serviceNameChange} > 
                 <option value="">{t('select_service_type')}</option>
                 {
                  (localStorage.getItem('tenant') == 'webasto') ?
                    <>
                      <option value="ONBOARD_API_NEXT">{t('onboard_api_next')}</option>
                    </>
                    : (localStorage.getItem('tenant') == 'ford') ?
                      <>
                        <option value="DIAGNOSTICS_DOWNLOAD">{t('diagnostics_download')}</option>
                        <option value="INVENTORY_UPLOAD">{t('inventory_upload')}</option>
                        <option value="INVENTORY_IMPORT">{t('inventory_import')}</option>
                        <option value="WALLBOX_MESSAGE">{t('wallbox_message')}</option>
                        <option value="ONBOARD_CHARGER">{t('onboard_charger')}</option>
                        <option value="VENDOR_SUPPORT">{t('vendor_support')}</option>
                      </> : <></>
                }
                  
     </select>
     </MDBCol>
            <div className="col-md-3 p-rel mt-2">
              <div class="input-group">
                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="srch-term" value={this.state.startDate} onChange={this.changeFromdate} onKeyDown={this.keyPress1} />
                <div class="input-group-btn">
                  <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                </div>
              </div>
              <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                <Calendar
                  selected={this.state.startDate}
                  onChange={date => this.GetFromDate(date)}
                  value={activeDateFormat(this.state.startDate)}
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  showTimeSelect
                  //className="cal_margin tag_cal"
                  timeCaption="time"
                  dateFormat="dd-MMM-yyyy"
                  onBlur={this.closeCalender.bind(this, 'from')}
                  // maxDate={new Date()}
                  maxDate={(this.state.toDate != "" && this.state.toDate != null) ? new Date(this.state.toDate) : new Date()}
                />
              </div>
              <span className="pull-left future_err1 w3-animate-top mt-1" style={{color: "red"}}></span>
            </div>
            <div className="col-md-3 p-rel mt-2">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="srch-term" value={this.state.toDate} onChange={this.changeTodate}  onKeyDown={this.keyPress2} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.toDate}
                    onChange={date => this.dateChange(date)}
                    value={activeDateFormat(this.state.toDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    //className="cal_margin tag_cal"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(this.state.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{color: "red"}}></span>
              </div>
            <div className="col-md-3 mt-2">
              <div class="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <input type="text" id="statusName" pattern="[0-9]*" value={this.state.fields["statusName"]} onChange={this.statusChange.bind(this, "statusName")}
                  class="form-control input-searchBox pad-2" placeholder={t('status_code')} name="statusName" />
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeStatusCode} >{t('find')}</button>
                </div>
              </div>
              <span className="error_msg w3-animate-top"> <span style={{ color: "red" }}></span></span>
            </div>
            <div className='col-md-12 date_range_error' style={{ color: "red", textAlign:"center" }}></div>
          <div className="col-md-12 mt-3 pt-1">
          <div className="pull-right">
                  <label className="pull-left sub-text">{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
          </div>
          </div>
          
          {/* table start */}
          <div className="row ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">
            <thead>
              <tr>
                <th>{t('service_name')}</th>
                <th>{t('status_code')}</th>
                <th>{t('created_date')}</th> 
                <th>{t('mail_send_date')}</th>
                <th className="text-center" >{t('view')}</th>
             </tr>
            </thead>
            <tbody>
            {errorData}
            </tbody>
          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right" >
             <Pagination
               hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
             />
          </div> 
          {/* End table  */}
          </div>
          </div>
        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
              {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
          <button type="button" className="btn_primary mt-2 mb-2" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
      </MDBModal>
        {/* Popup for  View */}
        <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="xl" className="model_quickview" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
        <div data-test="modal-header" class="modal-header">
        <div class="modal-title fw-800 mt-1" ref={(node)=>{if(node){node.style.setProperty("font-size","18px","important");}}}>{t('view')}</div>
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
        {/* <MDBModalHeader toggle={this.toggleView}>View</MDBModalHeader> */}
        <MDBModalBody className = "popupScroll">
        <MDBRow className="mb-4 mt-3" >
       <MDBCol sm="12">
       <div className="view-page-row view-bg-row" >
            <label className="">
              {t('error_message')}
            </label>
            <label className="wrap-word">
              {JSON.stringify(this.state.displayError)}
           </label>
          </div>
          </MDBCol>
          </MDBRow>
          <MDBRow className="mb-4 mt-3" >
         </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <button type="button" className="btn_primary mt-2 mb-2" onClick={this.toggleView1}>{t('close')}</button>
        </MDBModalFooter>
      </MDBModal>
      </main>
 </>
  );
}
}

export default withTranslation()(auditMail);