import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import $ from "jquery";
import { createBrowserHistory } from 'history';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getDateRange, dateRangeValidateForCpPingPongLogs, dateformatinUTC , timeformatinUTC} from './_methods';
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();
 class cpPingPongLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewdisplay: "",
      cpIdSorting: false,
      modalView: false,
      errors: {},
      modalsession: false,
      fields: { chargePointId: "" },
      fromDate: '',
      toDate: '',
      fromDateApi: '',
      toDateApi: '',
      page_title: "CPMS Logs",
      collapseID: "",
      items: [],
      errorData: [],
      dataArray: [],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      calenderFrom: "none",
      calenderTo: "none",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      displayTimes:[]
    }
    this.GetFromDate = this.GetFromDate.bind(this);
    this.getPingPongLogs = this.getPingPongLogs.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.exportCpPingPong = this.exportCpPingPong.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.getPingPongLogsdates = this.getPingPongLogsdates.bind(this);
    this.closetoggleView = this.closetoggleView.bind(this);
    this.toggleView = this.toggleView.bind(this);
  }

  toggleView(id,type, createdTime) {
    this.setState({
      modalView: !this.state.modalView,
      viewTitle: (type == "PING")? "CP Ping Times" : "CP Pong Times"
    },()=>{
      this.getPingPongLogsdates(id, createdTime)
    });
  }

  closetoggleView(){
    this.setState({
      modalView: !this.state.modalView
    })
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  handleClick() {
    if($("#notexists").text()!=''){
      let fields = this.state.fields;
      fields.chargePointId = '';
      this.setState({
        fields
      });
      this.state.errors["entercharge"] = " "
      $("#notexists").text(" ");
    }else{
      let fields = this.state.fields;
      fields.chargePointId = '';
      this.setState({
        fields
      });
      this.state.pageSize = 10;
      this.state.activePage = 1;
      document.getElementById("showEntries").value = 10;
      this.getPingPongLogs(this.state.pageSize, 1);
      this.state.errors["entercharge"] = " "
      $("#notexists").text(" ");
    }
    
  }
  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.chargePointid = document.getElementById('chargePointId')

    this.setState({ fields }, ()=>{
      if (this.state.fields.chargePointId.length == 0) {
        this.state.activePage = 1;
        this.getPingPongLogs(this.state.pageSize, 1);
      }
    });
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");

  }
  keyPress(e) {
    
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ chargePointId: e.target.value });
      if (this.state.fields.chargePointId == "") {
        this.state.errors["entercharge"] = this.props.t('enter_charge_point_id');
        $("#notexists").text("");
      } else {
        this.state.errors["entercharge"] = "";
        this.getPingPongLogs(this.state.pageSize, 1, "enter");
      }
    }

    if (key == "Backspace" || key == "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {
        this.state.fields.chargePointId = sno;
        this.getPingPongLogs(this.state.pageSize, 1);
      }
    }

    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  GetFromDate = date => {
    $('.future_err1').text("");
        this.setState({ fromDate: date });
        this.setState({
            fromDateApi: dateformatinUTC(date),
            activePage: 1
        }, () => {
            if (dateRangeValidateForCpPingPongLogs(this.state.fromDate, this.state.toDate) == true) {
                $('.date_range_error').text('');
                this.getPingPongLogs(this.state.pageSize, 1);
            } else {
                $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('pingPongDuration')} ${this.props.t('days')}`);
            }
        });
        this.setState({ disabledAttr: "" });
  };

  dateChange = date => {
    $('.future_err2').text("");
        this.setState({ toDate: date });
        this.setState({
            toDateApi: dateformatinUTC(date),
            activePage: 1
        }, () => {
            if (dateRangeValidateForCpPingPongLogs(this.state.fromDate, this.state.toDate) == true) {
                $('.date_range_error').text('');
                this.getPingPongLogs(this.state.pageSize, 1);
            } else {
                $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('pingPongDuration')} ${this.props.t('days')}`);
            }
        });
        this.setState({ disabledAttr: "" });
  };

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    let fromDateApi = dateformatinUTC(moment(this.state.fromDate));
    let toDateApi = dateformatinUTC(moment(this.state.toDate));

    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: fromDateApi,
      toDateApi: toDateApi
    },()=>{
      this.getPingPongLogs(entries, 1);
    });
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      this.setState({
          activePage: pageNumber,
          fromDateApi: this.state.curPageFirstCreTime,
          toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
          this.getPingPongLogs(this.state.pageSize, pageNumber, "", false);
      })
  } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
          activePage: pageNumber,
          fromDateApi: dateformatinUTC(this.state.fromDate),
          toDateApi: this.state.curPageLastCreTime,
          prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
          if (pageNumber > _datesArrayLength + 1) {
              this.createdDatesArray(this.state.curPageFirstCreTime);
          }
          this.getPingPongLogs(this.state.pageSize, pageNumber, "", false);
      })
  }
  }

  getPingPongLogs(pageSize, pageNo, action, countValue) {
    document.getElementById("loader_image_div").style.display = "block";
    let chargePoints = this.state.fields.chargePointId;
    var url = baseUrl.URLPath + "pingpong?chargePointId=" + chargePoints + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
          }, 100);
        } else if (resp.status == 200) {
          return resp.json();
        } else if (resp.status == 400) {
            return resp.json();
        }else if (resp.status == 404) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            if (action == "click" || action == "enter") {
              $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
            }
          }, 100);
        }else {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          
          if (response.status == 400) {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              $('.date_range_error').text(response.message);
            }, 100);
          } else if (response.count.count == 0) {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              if (action == "click" || action == "enter") {
                $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
              }
              this.setState({
                items: [],
                noOfRecords: 0
              });
            }, 100);
          } else {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              var totalcount = "";
            if (countValue == false) {
              totalcount = this.state.noOfRecords
            } else {
              totalcount = response.count.count
            }
            this.setState({
              prevPageFirstCreTime: this.state.curPageFirstCreTime,
              items: response.data,
              noOfRecords: totalcount,
              curPageFirstCreTime: moment(response.data[0].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.data[response.data.length - 1].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
            })
            if (action == "click" || action == "enter") {
              $("#notexists").text(" ");
              $("#messageid").text(" ");
            }
            }, 100);
          }
        }
      })
      .catch((error) => {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          console.log(error, "catch the loop")
        }, 100);
      })
  }

  getPingPongLogsdates(_id,_createdTime) {
    // document.getElementById("loader_image_div").style.display = "block";
    var url = baseUrl.URLPath + servicesUrl.getPingpong+"?pingPongId="+_id;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        } else {
          if (resp.status == 400) {
            return resp.json();
          }
        }
      })
      .then((response) => {
        if (response != undefined) {
          let _dates = [];
          response.map((value,index)=>{
            ;
            let createdTime = _createdTime;

            let date = createdTime.split('T')[0]; //2022-02-26
            let timezone = createdTime.split('.')[1]; //000+0000

            let _date = date + "T" + response[index] + "." + timezone;
 
            _dates.push(moment(new Date(_date)).format("HH:mm:ss"));
          });
          this.setState({
            displayTimes: _dates
          });
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      });
  }

  componentDidMount() {
    let duration = localStorage.getItem('pingPongDuration');
    duration = duration - 1;

    let fromDate = moment().subtract(duration, 'd');
    let fromDateApi = dateformatinUTC(moment().subtract(duration, 'd'));
    let toDate = new Date();
    let toDateApi = dateformatinUTC(moment(toDate));
    this.setState({
      fromDate: new Date(fromDate),
      fromDateApi: fromDateApi,
      toDate: toDate,
      toDateApi: toDateApi,
    }, () => {
      getDateRange();
      this.getPingPongLogs(this.state.pageSize, this.state.activePage);
    })
  }

  //change function for chargepoint status filter
  onChangeChargepoint() {
    var chargePoint = document.getElementById("chargePointId").value;
    this.setState({ chargePointId: chargePoint }, () => {
      this.getPingPongLogs(this.state.pageSize, 1);
    });
    if (this.state.fields.chargePointId == "") {
      this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
      $("#notexists").text(" ");

    } else {
      this.state.errors["entercharge"] = " "
      this.getPingPongLogs(this.state.pageSize, 1, "click");
    }
  }
  exportCpPingPong() {
    var timezone = moment().tz(moment.tz.guess()).format('z');

    var url = baseUrl.URLPath + "pingpong/download/CSV?chargePointId=" + this.state.fields.chargePointId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": timezone
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
      })
      .then((response) => {

        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'CP_PingPong_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }

  //function for sorting table data on click
  ascendingOrder() {
    // this.setState({ items: this.state.items.reverse() })
    if (this.state.cpIdSorting == false) {
      this.state.items.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = true;
      this.setState({ items: this.state.items.sort(this.dynamicSort("chargePointId")) })
    }
    else {
      this.state.items.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = false;
      this.setState({ items: this.state.items.reverse() });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {

        return a[property].localeCompare(b[property]);
      }
    }
  }

  render() {
    const{t}=this.props
    const errorData = this.state.items.map((item, index) => {
      if (item.createdTime == null || item.createdTime == "") {
        var createdTime = "";
        var createdTime1 = "";
      } else {
        ;
        var createdTime = moment(item.createdTime).format("DD MMM YYYY");
        var createdTime1 = moment(item.createdTime).format("HH:mm");
      }

      if (item.modifiedTime == null || item.modifiedTime == "") {
        var modifiedTime = "";
        var modifiedTime1 = "";
      } else {
        var modifiedTime = moment(item.modifiedTime).format("DD MMM YYYY");
        var modifiedTime1 = moment(item.modifiedTime).format("HH:mm");
      }

      return (
        <tr>
          <td className="">{item.chargePointId}</td>
          <td className="wrap-word">{createdTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdTime}</div></>}</td>
          <td className="wrap-word">{modifiedTime && <><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {modifiedTime1} </div> </>}</td>
          <td>{item.type}</td>
          <td className=""><i class="fas fa-eye action-icon cursor_defult" title="View" onClick={this.toggleView.bind(this,item.id, item.type, item.createdTime)} ></i></td>
        </tr>
      )
    });


    return (
      <>
        <main class="content-div" >
          <p>{t('cp_ping_pong_logs')}
            <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('cp_ping_pong_logs')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row ">
              <div className="col-md-3 t-8">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onKeyDown={this.keyPress} onChange={this.changeHandler.bind(this, "chargePointId")} class="form-control input-searchBox pad-2" placeholder="Charge Point ID" name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>{this.state.errors["notexists"]}</span>
                </div>
              </div>
              <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">{t('from_time')}</span>
                <div className="pull-left mt-2" style={{ width: "68%" }} >
                  <DatePicker
                    selected={this.state.fromDate}
                    onChange={this.GetFromDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    maxDate={(this.state.toDate!=""&&this.state.toDate!=null)? new Date(this.state.toDate):new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12 future_err1 pl-0" style={{ color: "red" }}></div>
              </MDBCol>

              <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">{t('to_time')}</span>
                <div className="pull-left mt-2" style={{ width: "68%" }} >
                  <DatePicker
                    selected={this.state.toDate}
                    onChange={this.dateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    minDate={new Date(this.state.fromDate)}
                    maxDate={new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12 future_err2 pl-0" style={{ color: "red" }}></div>
              </MDBCol>

              <MDBCol md="2" className="" >
                {/* <button className="btn_primary mt-2 pull-right " onClick={this.exportCpPingPong}>
                  Export <i className="fas fa-file-export ml-2"></i>
                </button> */}
              </MDBCol>
              <div className='col-md-3'></div>
              <div className='col-md-9 date_range_error' style={{ color: "red", textAlign: "left" }}></div>
              <div className="col-md-12 pt-1">
                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="pull-right sub-text">{t('show_entries')}</label>
              </div>

            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th>{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('created_date')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('modified_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th>{t('type')}</th>
                      <th>{t('view')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorData}
                    {
                      (this.state.items.length == 0) ? <td colSpan={5} style={{ textAlign: "center" }}>{t('no_data_for_cp_ping_Pong_logs')}</td> : null
                    }
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right" >
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                  prevPageText="Prev"
                  nextPageText="Next"
                  itemClassPrev="prevBtn"
                  pageRangeDisplayed="1"
                  activeClass="activeLi active"
                  itemClassNext="nextBtn"
                  hideFirstLastPages={true}
                />
              </div>
              {/* End table  */}
            </div>
          </div>

          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

           {/* Popup for  View */}
           <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="lg" className="model_top">
            <div data-test="modal-header" class="modal-header">
              <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{this.state.viewTitle}</div>
            </div>
            <MDBModalBody>
              <MDBRow className="mb-4 mt-3 chargepoint_listdiv" >
                <MDBCol sm="12">
                  <div className="view-page-row view-bg-row" >
                    <span className="wrap-word">
                      {this.state.displayTimes.join(', ')}
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter className="border-top-0 p-3">
              <button className="refresh-btn" onClick={this.closetoggleView}>{t('close')}</button>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default withTranslation()(cpPingPongLogs);