import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import {Card,Table,Pagination,Check,Accordion} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as baseUrl from './constants';
import  $ from "jquery";
import Header from './header';

//import CustomToggle from './customToggle'

class cpNoticationBody extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
            startDate:new Date(),
            collapseID: "",
            page_title:"CPMS Operations Logs",
            item: {},
            operationData:[]
          
        }
     
    }


componentWillMount(){
    this.setState({item:this.props.data})
}
     
    render() {
        
      var data= "";
      if(this.state.item.data ==null){
        data="";
      }else{
        data=this.state.item.data;
      }
      var status= "";
      if(this.state.item.notificationStatus ==null){
        status="";
      }else{
        status=this.state.item.notificationStatus;
      }

      var error= "";
      if(this.state.item.errorMessage ==null){
        error="";
      }else{
        error=this.state.item.errorMessage;
      }
            return (
                <>
        <MDBRow >
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row charge_pointlogs">
                 <label>
                Charge Point ID :
                 </label>
                 <label>
                 {this.state.item.chargePointID}
                 </label>
               </div>
            </MDBCol>
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row charge_pointlogs">
                 <label>
                Status :
                 </label>
                 <label>
                 {status}
                 </label>
               </div>
            </MDBCol> 
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row charge_pointlogs">
                 <label>
                Created Time :
                 </label>
                 <label>
                 {this.state.item.createdTime}
                 </label>
               </div>
            </MDBCol>  
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row charge_pointlogs">
                 <label>
                Connector ID :
                 </label>
                 <label>
                 {this.state.item.connectorID}
                 </label>
               </div>
            </MDBCol>  
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row charge_pointlogs">
                 <label>
                Data :
                 </label>
                 <label>
                 {data}
                 </label>
               </div>
            </MDBCol>  
            <MDBCol sm="6">
            <div className="view-page-row view-bg-row charge_pointlogs">
                 <label>
                Error :
                 </label>
                 <label>
                 {error}
                 </label>
               </div>
            </MDBCol>  
            
                 
              
        </MDBRow>
                
             </>
              );
        
        
     
    }
}

export default cpNoticationBody;