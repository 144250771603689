import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility';
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import Header from './header';
import * as baseUrl from './constants';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';

import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class sendLocalList extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          cpList:[],
          tagList:[],
          fields:{
            listVersion: "",
          },
            tagId:"",
            updateType:"",
            sucess:false,
            colour:'sucess',
            page_title:"Send Local List",
            errors:{},
            modalsession:false,
            chargePoint:'',
          operationId:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.idTagChange=this.idTagChange.bind(this);
        this.onChangeUpdateType=this.onChangeUpdateType.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
        this.togglesession = this.togglesession.bind(this);

}
onShowAlert(){
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}


async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}
//This is for making user should enter only numbers
numberHandler(field, e){ 
  ;
    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }
  async handleSubmit(event) {
    
    var sds= this.getCPList();
    var fdf=this.state;
    var payload=this.getPayLoad();
    var listVersion=payload.listVersion;
    var updateType=payload.updateType;
    var errors={};
    if(this.getCPList()==0){
      errors["emptyChargePoint"]=this.props.t('select_charge_point')
    }
    if(isNaN(listVersion)){
      errors["emptylistversion"]=this.props.t('enter_list_version')
    }
    if(payload.updateType=='' || payload.updateType == this.props.t('update_type_select')){
      errors["emptyupdatetype"]=this.props.t('select_update_type')
    }
    if(this.getCPList()!=0 && !isNaN(listVersion) && updateType!='' && updateType!=this.props.t('update_type_select')){
    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
     //alert(response);
     if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
      this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
        this.onShowAlert();
      })
    } else {
      this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
        this.onShowAlert();
      })
    }
    }
    this.setState({errors:errors})
  }
  getCPList() {
    var Id = this.state.operationId;
 if(Id != null&& Id != ""){
     var cplist = Id.split();
   
    }
    else{
     var cplist = this.state.cpList;
   
   }
   return cplist;
  }
  
  onChangeUpdateType(){
   
    this.setState({updateType: document.getElementById("updateType").value})
    var x=document.getElementById("updateType").value
    if(x!=this.props.t('update_type_select')){
    
      this.state.errors["emptyupdatetype"]=" "
    
    }
  
  }
  getPayLoad() {
    var sfsf=this.state.tagId;
    var payload =
    {
      "listVersion": parseInt(this.state.fields.listVersion),
   
      "updateType": this.state.updateType
    };
   
    return payload;
  
  }
  
  getMessageType() {
    return constants.SEND_LOCAL_LIST;
  }
  componentDidMount(){
    let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }

    var url = constants.URLPath + "ocpptags"
  
    fetch(url,{
      method: "GET",
      headers: {
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization"),
       
      }
      })
      .then((resp) => {
        ;
        if(resp.status==401){
          // alert("Access token is expired, please login again")
          this.togglesession();
    
          localStorage.clear();
         
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        
        if(response!=undefined){
      this.setState({ tagList:response.data })
      this.setSelectedStatus();
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      }) 
  } 
  getSelectedCP(cpList){
    
 
    
    this.setState({cpList:cpList});
    if(cpList!=""){
  
      this.state.errors["emptyChargePoint"]=" "
     // this.state.errors["emptylistversion"]=" "
     
    }
   
   
    var url = baseUrl.URLPath +servicesUrl.getChargePointlocalListversion+ "?cpId="+cpList;
    fetch(url,{
      method: "GET",
      headers: {
       "content-type":"application/json",
       "Authorization":localStorage.getItem("Authorization"),
      
      }
      })
      .then((resp) => {
        
        return resp.json();
      }) 
    
      .then((response) =>{
        
        if(response.listVersion==null){
     response.listVersion='0'
        }
   
      this.setState(state => (state.fields.listVersion = response.listVersion, state))
    })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }
  idTagChange(){
    
    this.setState({tagId:document.getElementById("tagList").value});
  
}
componentWillUnmount(){
  localStorage.setItem('chargePoint', '');
  localStorage.setItem('operationId', '');
}
render() {
  const{t}=this.props
  const tagList =this.state.tagList.map((tag, index) =>{
    return(
      <>
    
         <option value={tag.idTag}>{tag.idTag}</option>
    </>
    )
         });
  return (
    <>

<main class="content-div" >
<p>  {t('Send Local List')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Send Local List')}  </span>
              </div>
           </p>
    {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Send Local List </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Send Local List </a></div> */}

          {/* <p>Send Local List</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
      {/* <section className="dashboard">
        <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left" > Send Local List </h4>
                <div class="clearfix"></div>
            </div>
        </div>
        <section className="content_wbg">  */}
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
              
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
            <MDBRow>
            <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP}operationName ="SendLocalList" ></ChargePointSelection>
            <MDBCol md="12">
         <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
         </MDBCol>
           
            
         
        
               
         <MDBCol md="4">
            <MDBInput
                 value={this.state.fields.listVersion}
                 name="listVersion"
                 onInput={this.numberHandler.bind(this,"listVersion")}
                 type="text" pattern="[0-9]*"
                 id="listVersionId"
                 label={t('list_version_integer')}
                 disabled="true"
                 required
              >
          <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptylistversion"]}</span></span>

                <div className="valid-feedback">{t('looks_good')} </div>
              </MDBInput>
         </MDBCol>
         
          

          <MDBCol md="4">
          <select className="browser-default custom-select select_height mb_8" id="updateType" onChange={this.onChangeUpdateType}>
                <option> 	{t('update_type')}</option>
                <option value="Full">{t('full')}</option>
                <option value="Differential">{t('differential')}</option>
            </select>
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyupdatetype"]}</span></span>

         </MDBCol>

         
        
        	
         </MDBRow> 
         
         <button className="mt-5 refresh-btn" type="button" data-test="button" onClick={this.handleSubmit} >{t('perform')}</button>
          
        </form>
      </div>
         </MDBCol>
          
        </MDBRow>
        </div>
  


        {/* popup for session expire */}

<MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
     <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
     <MDBModalBody>
      {t('please_login_again')}
     </MDBModalBody>
     <MDBModalFooter>
     {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
     <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
     </MDBModalFooter>
     </MDBModal>
    </main>
    </>
  );
}
}

export default withTranslation()(sendLocalList);