import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import moment from 'moment';
import * as baseUrl from './constants';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { displaySelectedChargePoints, storedSelectedCP } from '../Controllers/ChargePointModel';
import { Typeahead } from 'react-bootstrap-typeahead';
import $ from "jquery";
import ChargePointModel from '../common/ChargePointModel'
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();
class vendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorNameSorting: false,
      assignAction: "",
      assignSuccessName: "",
      style: "block",
      finalStatus: [],
      sucess: false,
      colour: 'sucess',
      message: '',
      finalArray1: [],
      jsonArray: [],
      isCheckBox: "false",
      matchedPoints: [],
      cpList: [],
      allChargePoints: [],
      warningmodal: false,
      optionsdropdown: [],
      vendorList: [],
      chargePointIdList: [],
      getSelectedName: [],
      vendorActiveArray: [],
      activeArray: [],
      getVendorData: [],
      list: [],
      name: "",
      options: [],
      multiple: false,
      intial_data: [],
      addCPIDInURL: true,
      activateBroker: true,
      inputdisplay: "none",
      noWallboxExists:"",
      checkBoxDisplay: "none",
      getVendors: [],
      deletemodal1: false,
      modalsession: false,
      confirmassignmodal: false,
      fromDate: "",
      toDate: "",
      fromDateApi: "",
      toDateApi: "",
      modalView: false,
      selectedData: [],
      data: new Map(),
      noOfRecords: '',
      errors: {},
      items: [],
      disabledAttr: false,
      prevPageFirstCreTime: "",
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      id: '',
      page_title: "Vendor",
      vendorArray: [],
      fields: { id: '', active: '', certificateChain: '', contextPath: '', description: '', ocppProtocol: '', tlsCommunication: '', ocppServerURL: '', privateCertificate: '', publicCertificate: '', vendorName: '', vendorSite: '' },
      cpactivePage: 1,
      cppageSize: "30",
      cppageNo: 1,
      cpnoOfRecords: "",
      listOfFirstCreatedtimes: []
    }
    this.ocppProtocolChange = this.ocppProtocolChange.bind(this);
    this.brokerEdit = this.brokerEdit.bind(this);
    this.brokerDelete = this.brokerDelete.bind(this);
    this.energytypeChange = this.energytypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addtoggle = this.addtoggle.bind(this);
    this.addtoggle1 = this.addtoggle1.bind(this);
    this.deletetoggle = this.deletetoggle.bind(this);
    this.saveeditdata = this.saveeditdata.bind(this);
    this.deletetoggleOk = this.deletetoggleOk.bind(this);
    this.deletetoggle1 = this.deletetoggle1.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.toggleedit = this.toggleedit.bind(this);
    this.activeBroker = this.activeBroker.bind(this);
    this.cpidUrl = this.cpidUrl.bind(this);
    this.getVendorList = this.getVendorList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getSearchVendorList = this.getSearchVendorList.bind(this);
    this.vendorNameChange = this.vendorNameChange.bind(this);
    this.getSelectedVendorName = this.getSelectedVendorName.bind(this);
    this.FindVendorName = this.FindVendorName.bind(this);
    this.FindVendorName1 = this.FindVendorName1.bind(this);
    this.getSelectedVendorName1 = this.getSelectedVendorName1.bind(this);
    this.ClearVendorName = this.ClearVendorName.bind(this);
    this.onBlurEvent = this.onBlurEvent.bind(this);
    this.getApiChargePoints = this.getApiChargePoints.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.assignVendor = this.assignVendor.bind(this);
    this.confirmassigntoggle = this.confirmassigntoggle.bind(this);
    this.assignsuccesstoggle = this.assignsuccesstoggle.bind(this);
    this.warningtoggle = this.warningtoggle.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.viewAssignedVendors = this.viewAssignedVendors.bind(this);
    this.toggleView1 = this.toggleView1.bind(this);
    this.comparingChargePoints = this.comparingChargePoints.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.assignclosetoggle = this.assignclosetoggle.bind(this);
    this.subassignclosetoggle = this.subassignclosetoggle.bind(this);
    this.closetoggle = this.closetoggle.bind(this);
    this.displayAssignChargePoints = this.displayAssignChargePoints.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.cpshowEntriesChange = this.cpshowEntriesChange.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.cphandlePageChange = this.cphandlePageChange.bind(this);
    this.getVendorList = this.getVendorList.bind(this);
    this.confirmOk = this.confirmOk.bind(this);
    this.removeCpId=   this.removeCpId.bind(this);

  }

  removeCpId(id){
    var idList = this.state.cpList;
    var index = this.state.cpList.indexOf(id);;
    idList.splice(index,1);
    
    this.setState({cpList:idList})
  }

  closetoggle = () => {
    let errors = {};
    this.setState({
    modal: !this.state.modal,
    activateBroker:true,
    errors:errors
    });
    
  
   this.state.fields['vendorName'] ="";
   this.state.fields['ocppServerURL'] ="";
   document.getElementById("ocppProtocol").value ="";
   this.state.fields.vendorSite = "";
   this.state.fields.description = "";
   this.state.fields.contextPath ="";
   
  }

  onShowAlert() {
    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });
  }

  toggleView1() {
    this.setState({
      modalView: !this.state.modalView
    });
  }

  // method called when click on view icon
  toggleView = (id, vendorName) => {
    this.setState({ selectedVendorId: id })
    this.setState({ selectedVendorName: vendorName })
    localStorage.setItem("getId", id);
    this.getApiChargePoints(id, true);
    this.setState({
      modalView: !this.state.modalView
    });

    if (this.state.modalView) {
      if (this.state.modelView != undefined) {
        const { getChargePoints } = this.props;
        getChargePoints(this.state.cpList);
      }
      else {
        // window.location.reload();
      }
    }
  }

  warningModelOk() {
    this.setState({
      warningmodal: !this.state.warningmodal
    });
  }

  warningtoggle() {
    this.setState({
      warningmodal: !this.state.warningmodal
    });
  }

  assignsuccesstoggle() {
    this.setState({
      assignsuccessmodal: !this.state.assignsuccessmodal
    });
  }

  confirmassigntoggle(event) {
    if (event != undefined) {
      var duplicateArray1 = Array.from(new Set(this.state.cpList));
      if (duplicateArray1.length == 0 && this.state.assignAction == "Assign") {
        this.setState({ message: "Please Select Charge Point Id.", colour: 'danger' }, ()=>{
          this.onShowAlert();
        })
      } else {
        let errors = {};
        errors["chargePointNotExists"] = "";
        errors["selectChargepoint"]="";
        
        this.setState({ //close popup
          modalView: !this.state.modalView,
          confirmassignmodal: !this.state.confirmassignmodal,
          cppageSize:30,
          cpactivePage:1,
          fromDateApi: "",
          toDateApi: "",
          errors: errors
        });
      }
    }
  }

  // method to clear search filter
  ClearVendorName(ref, event) {
    let errors = {};
    errors["vehicleNameNotexists"] = "";
    errors["selectVendorName"] = "";
    this.setState({
      errors: errors
    });

    this.state.pageSize = 10;
    this.state.activePage = 1;
    document.getElementById('showEntries').value = 10;
    this.getVendorList(this.state.pageSize, 1);
    ref.current.clear();
    this.setState({
      options: [],
      errors: {}
    })
  }

  onBlurEvent(event) {
    var vendorName1 = event.target.value;
    this.setState({
      vendorName1: vendorName1
    })

    if (vendorName1.length == 0) {
      this.getVendorList(this.state.pageSize, this.state.activePage)
      this.setState({
        getVendors: this.state.items,
      });
    }
    let errors = {};
    errors["vehicleNameNotexists"] = "";
    errors["selectVendorName"] = "";
    this.setState({
      errors: errors
    });
  }

  FindVendorName(ref, event) {
    var name = ref.current.inputNode.value;
    this.getSelectedVendorName(name);
  }

  FindVendorName1(ref, event) {
    var name = ref.current.inputNode.value;
    this.getSelectedVendorName1(name);
  }

  //api to get selected vehicle name
  getSelectedVendorName1(name) {
    let errors = {};
    if (name == null || name == "") {
      errors["selectVendorName"] = this.props.t('enter_vendor_name');
      this.setState({
        errors: errors
      });
    }
    else {
      let url = baseUrl.URLPath + servicesUrl.getBrokerVendorName;
      let data = { "vendorName": name };
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      }).then(resp => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        else if (resp.status == 404) {
          this.setState({ errors: errors });

        } else if (resp.status == 500) {
        }

        else if (resp.status == 409) {
          this.setState({
            errors: errors
          });
        }
        else if (resp.status == 400) {
        } else if (resp.status == 200) {
          return resp.json();

        }
      }).then(response => {
        
        var getVendorData = [];
        getVendorData = response.data["vendorDTOS"]


        if (response.data.status == "FAILURE") {
          errors["vehicleNameNotexists"] = this.props.t('vendor_name_not_exists');
          this.setState({
            errors: errors
          });

        }
        else {
          this.setState({
            getVendors: getVendorData
          })
        }


        this.setState({
          errors: errors
        });

      })
        .catch(error => alert('Error:' + error));

      this.setState({ errors: errors });

    }
  }

  // logic to get vendorName based on search filter
  vendorNameChange(name) {
    
    if (name.length == 0) {
      this.getVendorList(this.state.pageSize, this.state.activePage)
      this.setState({
        getVendors: this.state.items,
      });
    } else {
      let errors = {};
      errors["vehicleNameNotexists"] = "";
      errors["selectVendorName"] = "";
      this.setState({
        errors: errors
      });
      this.getSelectedVendorName(name);
    }
  }

  //api to get selected vehicle name
  getSelectedVendorName(name) {
    
    let errors = {};
    if (name == null || name == "") {
      errors["selectVendorName"] = this.props.t('enter_vendor_name');
      this.setState({
        errors: errors
      });
    }
    else {
      // let name1 = name[0]
      var newArray = name.join();

      let url = baseUrl.URLPath + "broker/vendor/get/name";
      let data = { "vendorName": newArray };
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }

      }).then(resp => {
        
        if (resp.status == 401) {
          this.togglesession();
          // alert("Access token is expired, please login again")
          localStorage.clear();
        }
        else if (resp.status == 404) {
          this.setState({ errors: errors });

        } else if (resp.status == 500) {
        }

        else if (resp.status == 409) {
          this.setState({
            errors: errors
          });
        }
        else if (resp.status == 400) {
        } else if (resp.status == 200) {
          return resp.json();

        }
      }).then(response => {
        
        var getVendorData = [];
        getVendorData = response.data["vendorDTOS"]


        if (response.data.status == "FAILURE") {
          errors["vehicleNameNotexists"] = this.props.t('vendor_name_not_exists');
          this.setState({
            errors: errors
          });

        }
        else {
          this.setState({
            getVendors: getVendorData
          })
        }


        this.setState({
          errors: errors
        });

      })
        .catch(error => alert('Error:' + error));

      this.setState({ errors: errors });

    }
  }


  handleKeyDown(ref, e) {
    var serial_no = e.target.value;
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);

    var list = this.state.intial_data;
    var x = e.target.value
    var sno = x.slice(0, x.length - 1)
    if (sno == "") {
      this.state.name = sno;

      this.getVendorList(this.state.pageSize, this.state.activePage)
    }
    var sno = serial_no.slice(0, serial_no.length - 1)
    this.getSearchVendorList(sno)

    if (e.keyCode == 13) {
      this.FindVendorName1(ref, e);
      $("#rbt_id").hide();
    }
    if ((e.keyCode == 86 && ctrl)) {
      this.setState({
        options: [' ']
      })
      this.getSearchVendorList(serial_no)
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;


        }
        break;

    }
  }

  getSearchVendorList(name) {
    
    var url = baseUrl.FOTAPath + servicesUrl.getBrokerVendorSearchmatchedname +"?name=" + name;
    var header = {
    }
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          var option = [];
          var data = response;
          this.setState({
            intial_data: option,
            options: data
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop");
      })
  }

  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.activePage = 1;
    this.state.pageSize = entries;
    this.getVendorList(entries, 1);
  }

  //show entries change
  cpshowEntriesChange() {
    var entries = document.getElementById("cpshowEntries").value;
    
    this.setState({
      cpactivePage: 1,
      cppageSize: entries,
      fromDateApi: "",
      toDateApi: ""
    }, () => {
      this.getApiChargePoints(this.state.selectedVendorId, true, false);
    })
  }

  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getVendorList(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
        this.setState({ dataArray: pageData })
      }
    }
  }

  cphandlePageChange(pageNumber) {
    
    if (pageNumber < this.state.cpactivePage) {
      //prev
      this.setState({
        cpactivePage: pageNumber,
        fromDateApi: this.state.curPageFirstCreTime,
        toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
      }, () => {
        this.getApiChargePoints(this.state.selectedVendorId, false, false);
      });
    } else {
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({
        cpactivePage: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        if (pageNumber > _datesArrayLength + 1) {
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getApiChargePoints(this.state.selectedVendorId, false, false);
      });
    }
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  }

  activeBroker = () => {
    var x = document.getElementById("myCheck1").checked;
    if (x) {
      this.state.activateBroker = true;
      this.setState({ activateBroker: true })
    }
    else {
      this.state.activateBroker = false;
      this.setState({ activateBroker: false })
    }
  }

  cpidUrl = () => {
    var x = document.getElementById("myCheck2").checked;
    if (x) {
      this.state.addCPIDInURL = true;
      this.setState({ addCPIDInURL: true })
    }
    else {
      this.state.addCPIDInURL = false;
      this.setState({ addCPIDInURL: false })
    }

  }

  checkBoxchange() {
    var y = document.getElementById("checkBytls").checked;
    if (y) {
      this.state.fields.tlsCommunication = true;
      this.setState({ tlsCommunication: true })
      this.state.inputdisplay = "block"
      this.setState({ inputdisplay: "block" })
    }
    else {
      this.state.fields.tlsCommunication = false;
      this.setState({ tlsCommunication: false })
      this.state.inputdisplay = "none"
      this.setState({ inputdisplay: "none" })
    }
  }

  async togglesession() {
    
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  componentDidMount() {
    localStorage.setItem("vennoWallboxExists","");
    let url = baseUrl.URLPath + "broker/vendor/search/count";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ noOfRecords: response.count });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    this.getVendorList(this.state.pageSize, this.state.activePage);
  }

  // to get vendor list
  getVendorList(pageSize, pageNo) {
    var timeZone = localStorage.getItem("timezone");

    var url = baseUrl.URLPath +servicesUrl.getBrokerVendorSearch + "?pageNo=" + pageNo + "&pageSize=" + pageSize;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "timeZone": timeZone,
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ getVendors: response })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  confirmOk() {
    this.getVendorList(this.state.pageSize, this.state.activePage);
    this.deletetoggle();
  }

  toggle = () => {
    var errors = {};
    this.setState({
      modal: !this.state.modal,
      activateBroker:true,
      errors:errors
    });
    this.state.fields['vendorName'] ="";
    this.state.fields['ocppServerURL'] ="";
    // document.getElementById("ocppProtocol").value ="";
    this.state.fields.vendorSite = "";
    this.state.fields.description = "";
    this.state.fields.contextPath ="";
  }

  subassignclosetoggle = () => {
    this.setState({
      confirmassignmodal: !this.state.confirmassignmodal,
      
    });
    window.location.reload();

  }

  assignclosetoggle = () => {
    let errors = {};
    errors["chargePointNotExists"] = "";
    errors["selectChargepoint"]="";
    this.setState({
      modalView:!this.state.modalView,
      cppageSize:30,
      fromDateApi:"",
      toDateApi:"",
      cpactivePage:1,
      cpList:[],
      errors: errors
    });
  }

  toggleedit() {
    this.setState({
      editmodal: !this.state.editmodal
    });
  }

  closetoggleedit = () => {
    this.setState({
      editmodal: !this.state.editmodal
    });
  }

  addtoggle = () => {
    this.setState({
      addmodal: !this.state.addmodal
    });
  }

  addtoggle1 = () => {
    this.setState({
      addmodal2: !this.state.addmodal2
    });
  }

  closeaddtoggle = () => {
    this.setState({
      addmodal: false
    });
    window.location.reload();
  }

  closeaddtoggle1 = () => {
    this.setState({
      addmodal2: false
    });
  }

  updatetoggle = () => {
    this.setState({
      updatemodal: !this.state.updatemodal,
      errors:{}
    });
  }

  closeupdatetoggle = () => {
    this.setState({
      updatemodal: false
    });
    window.location.reload();
  }

  deletetoggle = () => {
    this.setState({
      deletemodal: !this.state.deletemodal
    });
  }

  deletetoggle1 = () => {
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
  }

  closedeletetoggle = () => {
    this.setState({
      deletemodal1: !this.state.deletemodal1
    });
  }

  /* Edit Get*/
  brokerEdit(id) {
    let url = baseUrl.URLPath + servicesUrl.getVendorBroker +"?id=" + id;
    
    fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 500) {
          alert("internal server error")
        }
        if (resp.status == 400) {
          console.log(resp.status)
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({
            fields: response,
          })
          console.log('edit', response)
          this.toggleedit();
          document.getElementById('ocppProtocol').value = this.state.fields.ocppProtocol;
          document.getElementById("myCheck1").checked = this.state.activateBroker;
          document.getElementById("myCheck2").checked = this.state.addCPIDInURL;

          if (this.state.fields.tlsCommunication == true) {
            this.state.inputdisplay = "block"
            this.setState({ inputdisplay: "block" })
            document.getElementById("checkBytls").checked = true;
          } else {
            if (this.state.fields.certificateChain != "" || this.state.fields.publicCertificate != "" || this.state.fields.privateCertificate != "") {
              this.state.inputdisplay = "block"
              this.setState({ inputdisplay: "block" })
              document.getElementById("checkBytls").checked = true;
            }
          }
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  /* Edit Put */
  async saveeditdata(event) {
    let errors = {};
    var vendorName = this.state.fields['vendorName'];
    var ocppServerURL = this.state.fields['ocppServerURL'];
    var ocppProtocol = document.getElementById("ocppProtocol").value;
    if (ocppProtocol == '') {
      errors["ocppProtocolEmpty"] = this.props.t('please_select_ocppProtocol');
    }

    if (vendorName == '') {
      errors["vendorNameempty"] = this.props.t('enter_vendor_name');
    }
    if (ocppServerURL == '') {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }
    if (this.validateURL(ocppServerURL) == false) {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }


    if (errors.hasOwnProperty("vendorNameempty") || errors.hasOwnProperty("urlempty") || errors.hasOwnProperty("ocppProtocolEmpty")) { }


    else {
      let url = baseUrl.URLPath + "broker/vendor/";
      let data = { "id": this.state.fields.id, "vendorName": this.state.fields.vendorName, "ocppServerURL": this.state.fields.ocppServerURL, "ocppProtocol": document.getElementById('ocppProtocol').value, "activateBroker": this.state.activateBroker, "addCPIDInURL": this.state.addCPIDInURL, "certificateChain": this.state.fields.certificateChain, "contextPath": this.state.fields.contextPath, "description": this.state.fields.description, "privateCertificate": this.state.fields.privateCertificate, "publicCertificate": this.state.fields.publicCertificate, "vendorSite": this.state.fields.vendorSite, "tlsCommunication": this.state.fields.tlsCommunication };
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((response) => {
          if (response.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (response.status == 200) {
            return response.json();
          }
        })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.updatetoggle();
            this.setState({
              updatestatus: "SUCCESS",
              updatemsg: "Vendor updated successfully"
            });
          }
          else {
            if (response.data.status === "FAILURE") {
              this.updatetoggle();
              this.setState({
                updatestatus: "FAILURE",
                updatemsg: data.errorMessage
              });
            }
          }
        })
        .catch(error => alert('Error:' + error));
    }
    this.setState({ errors: errors })
  }

  brokerDelete(id) {
    this.setState({ deleteId: id })
    this.setState({
      deletemodal1: false,
    });
    this.deletetoggle1();
  }

  /* Delete */
  deletetoggleOk() {
    let url = baseUrl.URLPath + "broker/vendor/remove/id";
    let data = { "id": this.state.deleteId, "vendorName": this.state.fields.vendorName, "ocppServerURL": this.state.fields.ocppServerURL, "ocppProtocol": this.state.fields.ocppProtocol, "activateBroker": this.state.activateBroker, "addCPIDInURL": this.state.addCPIDInURL, "certificateChain": this.state.fields.certificateChain, "contextPath": this.state.fields.contextPath, "description": this.state.fields.description, "privateCertificate": this.state.fields.privateCertificate, "publicCertificate": this.state.fields.publicCertificate, "vendorSite": this.state.fields.vendorSite, "tlsCommunication": this.state.fields.tlsCommunication };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 500) {
          alert("internal server error")
        }
        if (resp.status == 400) {
          console.log(resp.status)
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.deletetoggle();
          this.setState({
            deletemodal1: false,
            deletestatus: "SUCCESS",
            deletemsg: "Vendor deleted successfully"
          });
        }
        else {
          if (response.data.status === "FAILURE") {
            this.deletetoggle();
            this.setState({
              deletestatus: "FAILURE",
              deletemsg: data.errorMessage,
              deletemodal1: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  validateURL(textval) {
    var urlregex = new RegExp("^(ws|wss)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}|[a-zA-Z]{3}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
    var var_name = urlregex.test(textval);
    return var_name;
  }
  /* Add */
  async handleSubmit(event) {
    event.preventDefault();
    let errors = {};
    var vendorName = this.state.fields['vendorName'];
    var ocppServerURL = this.state.fields['ocppServerURL'];
    var ocppProtocol = document.getElementById("ocppProtocol").value;
    if (ocppProtocol == '') {
      errors["ocppProtocolEmpty"] = this.props.t('please_select_ocppProtocol');
    }

    if (vendorName == '') {
      errors["vendorNameempty"] = this.props.t('enter_vendor_name')
    }
    if (ocppServerURL == '') {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }
    if (this.validateURL(ocppServerURL) == false) {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }


    if (errors.hasOwnProperty("vendorNameempty") || errors.hasOwnProperty("urlempty") || errors.hasOwnProperty("ocppProtocolEmpty")) { }

    else {
      let requestPayload = { "vendorName": this.state.fields.vendorName, "ocppServerURL": this.state.fields.ocppServerURL, "ocppProtocol": document.getElementById('ocppProtocol').value, "activateBroker": this.state.activateBroker, "addCPIDInURL": this.state.addCPIDInURL, "certificateChain": this.state.fields.certificateChain, "contextPath": this.state.fields.contextPath, "description": this.state.fields.description, "privateCertificate": this.state.fields.privateCertificate, "publicCertificate": this.state.fields.publicCertificate, "vendorSite": this.state.fields.vendorSite, "tlsCommunication": this.state.fields.tlsCommunication };
      const requestOptions = {
        method: 'POST',
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        },
        body: JSON.stringify(requestPayload)
      };
      fetch(baseUrl.URLPath + "broker/vendor/", requestOptions)
        .then((response) => {
          if (response.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (response.status == 200) {
            return response.json();
            console.log(response.json())
          }
        })
        .then((response) => {

          if (response.data.status == "SUCCESS") {
            this.addtoggle();
            this.setState({
              addstatus: "SUCCESS",
              addmsg: "Vendor added successfully"
            });
          }
          else {

            if (response.data.status == "FAILURE") {
              this.addtoggle1();
              this.setState({
                addstatus: "FAILURE",
                addmsg: response.data.errorMessage
              });

            }
          }
        })
    }
    this.setState({ errors: errors });
  }

  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  ocppProtocolChange() {
    var x = document.getElementById("ocppProtocol").value;
    let fields = this.state.fields;
    fields["ocppProtocol"] = x;
    this.setState({ fields });
  }

  energytypeChange() {
    var x = document.getElementById("energyType").value;
    let fields = this.state.fields;
    fields["energyType"] = x;
    this.setState({ fields });
  }

  onSelect(selectedList, selectedItem) {
    var chargePointId = selectedItem.chargePointId;
    this.state.chargePointIdList.push(chargePointId);
    this.setState({
      activeArray: this.state.chargePointIdList
    });
  }

  async getApiChargePoints(id, countValue, cpdisplay, cpid) {
    var cpId = "";
    
    if (cpid != "" && cpid != undefined && cpid != null) {
      cpId = cpid
      this.setState({
        cpactivePage:1,
        fromDateApi:"",
        toDateApi:"",
      })
    }
    var fdfd = this.state.cpList;
    var id = localStorage.getItem("getId");
    var url = baseUrl.URLPath + servicesUrl.getBrokerVendorMapperMapCharger + "?vendorId="+ id + "&pageNo=" + this.state.cpactivePage + "&pageSize=" + this.state.cppageSize + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi + "&chargePointId=" + cpId;
    
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {
        var fsd = response;    
       
        if (cpId != "" && response.count.count == 0) {
          localStorage.setItem("vennoWallboxExists", response.count.count);
        }
        if (response == 0 || response == "") {
          this.state.assignAction = "Assign";
          this.state.disabledAttr = "true";
          this.setState({ message: "Charge Points are not available for the selected vendor.", colour: 'danger' })
          this.onShowAlert();
        }
        var chargePoint;
        chargePoint = Array.from(Object.values(response.data), cp => cp.chargePointId)
        var totalcount = "";
        if (countValue == false) {
          totalcount = this.state.cpnoOfRecords
        } else {
          totalcount = response.count.count
        }
        var noWallboxExists1 ="";
        if (cpId != "" && response.data.length == 0) {
          noWallboxExists1 = 0;
        }else{
           noWallboxExists1 = "";
        }
    
        this.setState({
          optionsdropdown:chargePoint,
          noWallboxExists:noWallboxExists1,
          selectedData:response.data,
          cpnoOfRecords:totalcount,
          prevPageFirstCreTime: this.state.curPageFirstCreTime,
          curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
          curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
        })
        if (this.state.optionsdropdown.length != 0) {
          this.viewAssignedVendors(id, cpdisplay,cpid);
        }
      })

      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }

  singleVendorIdChange(id, cpid, event) {
    
    let arr = [];
    var newlist = [];
    var checkedValue = document.getElementById(id).checked;

    if (checkedValue == true) {
      if (this.state.vendorList[0] != "" && this.state.vendorList[0] != undefined) {
        document.getElementById("custom-" + this.state.vendorList[0]).checked = false;
        document.getElementById(id).checked = true;
      }
      this.state.vendorList[0] = cpid;
      this.setState({
        vendorActiveArray: this.state.vendorList
      });
    }

    if (checkedValue == false) {
      this.state.list[0] = "";
      document.getElementById(id).checked = false;
    }
    
    this.state.activeDeactiveArray = cpid;

  }

  // function get called when click on assign button
  assignVendor() {
    this.setState({
      confirmassignmodal: !this.state.confirmassignmodal
    });

    if (this.state.selectedVendorId != "") {
      var url = baseUrl.FOTAPath + "broker/vendor/mapper/map";
   
      var duplicateArray = Array.from(new Set(this.state.cpList));
      if (duplicateArray.length == 0) {
        var data = { "vendorBrokerId": this.state.selectedVendorId, "chargePointIds": [""] };
      }
      else {
        var data = { "vendorBrokerId": this.state.selectedVendorId, "chargePointIds": duplicateArray };

      }
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      })
        .then((resp) => {
          
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            this.assignsuccesstoggle();
            return resp.json();
          }
        })
        .then((response) => {
          
          if (response != undefined) {


          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })

    } else {
      
    }
  }

  viewAssignedVendors(id, countValue,cpid) {
    if (id != "undefined") {
      let errors = {};
      let url = baseUrl.URLPath + "broker/vendor/mapper/by/vendor";
      let data = { "vendorBrokerId": id };
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }

      }).then(resp => {
        
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        else if (resp.status == 404) {
          this.setState({ errors: errors });

        } else if (resp.status == 500) {
        }

        else if (resp.status == 409) {
          this.setState({
            errors: errors
          });
        }
        else if (resp.status == 400) {
        } else if (resp.status == 200) {
          return resp.json();
        }
      }).then(response => {
        
        var getAssignedVendors = [];
        var getChargePointIds = [];
        getAssignedVendors = response.data["vendorCSMapDTOS"]
        for (var i = 0; i < getAssignedVendors.length; i++) {
          getChargePointIds.push(getAssignedVendors[i]["chargePointId"]);
        }


        if (response.data.status == "FAILURE") {
          errors["vehicleNameNotexists"] = this.props.t('vendor_name_not_exists');
          this.setState({
            errors: errors
          });

        }
        else {
          this.setState({
            allChargePoints: getChargePointIds
          })
          this.comparingChargePoints(countValue,cpid);
        }
        this.setState({
          errors: errors
        });
      })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
      this.setState({ errors: errors });
    }
  }

  displayAssignChargePoints() {
    var allChargePoints = this.state.allChargePoints;
    this.setState({ cpList: allChargePoints});
    // displaySelectedChargePoints(this.state.allChargePoints);
  }

  // method on check and uncheck the checkbox
  handleChange(id, e) {
    this.setState({ check_type: "Checkbox" });
    var cpValue = storedSelectedCP(id, this.state.cpList, this.state.finalStatus, this.state.optionsdropdown);
    this.setState({
      cpList: cpValue.cpList,
      disabledAttr: cpValue.disabledAttr
    })
  }

  addSelectedCp() {
    let map = [];
    let allChargePointsArray = this.state.optionsdropdown;
    let matchedArray = this.state.allChargePoints;
    allChargePointsArray.forEach(i => map[i] = false);
    matchedArray.forEach(i => map[i] === false && (map[i] = true));
    let comparingPoints = Object.keys(map).map(k => ({ matchedChargepointId:k, matched:map[k] }));
  }

  comparingChargePoints(countValue,cpid) {

    let allChargePointsArray = this.state.optionsdropdown;
    let matchedArray = [];

    if (countValue == false) {
      matchedArray = this.state.cpList;
    } else {
      matchedArray = this.state.allChargePoints;
    }

    if (matchedArray.length == 0) {
      this.state.assignAction = "Assign"
      this.state.assignSuccessName = "assigned"
    }
    else if (matchedArray.length == 1 && allChargePointsArray.length == 1 && cpid =="") {
      this.state.assignAction = "Remove"
      this.state.assignSuccessName = "removed"
      this.state.disabledAttr = true;

    }
    else if (matchedArray.length >= 1 && allChargePointsArray.length >= 1) {
      this.state.assignAction = "Reassign"
      this.state.assignSuccessName = "reassigned"

    }
    else if (matchedArray.length > 1) {
      this.state.assignAction = "Reassign"
      this.state.assignSuccessName = "reassigned"

    }

    else {

    }

    let map = {};
    allChargePointsArray.forEach(i => map[i] = false);
    matchedArray.forEach(i => map[i] === false && (map[i] = true));
    let comparingPoints = Object.keys(map).map(k => ({ matchedChargepointId: k, matched: map[k] }));
    var finalArray = [];
    var finalArray1 = [];
    for (var i = 0; i < comparingPoints.length; i++) {
      finalArray.push(comparingPoints[i]);
    }
    var i;
    var selectChargePointIds = []
    for (i = 0; i < finalArray.length; i++) {
      if (finalArray[i]["matched"] == true) {
        var cpIds = finalArray[i]["matchedChargepointId"];
        selectChargePointIds.push(cpIds);
      }

    }

    this.setState({ finalStatus: selectChargePointIds })
    this.setState({ jsonArray: finalArray })
    if (countValue == false) {
    } else {
      this.displayAssignChargePoints();
    }
  }

  //function for sorting table data on click
  ascendingOrder() {
    if (this.state.vendorNameSorting == false) {
      this.state.getVendors.sort(this.dynamicSort("vendorName"));
      this.state.vendorNameSorting = true;
      this.setState({ getVendors: this.state.getVendors.sort(this.dynamicSort("vendorName")) })
    }
    else {
      this.state.getVendors.sort(this.dynamicSort("vendorName"));
      this.state.vendorNameSorting = false;
      this.setState({ getVendors: this.state.getVendors.reverse() });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {

        return a[property].localeCompare(b[property]);
      }
    }
  }

  render() {
    const{t}=this.props
    const ref = React.createRef();

    const cpStatus = this.state.cpList.map((cpId, index) => { });

    const data = this.state.getVendors.map((getVendors, index) => {
      return (
        <tr>
          <td className="text-rowcen">{getVendors.vendorName}</td>
          <td className="text-rowcen"> {getVendors.vendorSite}</td>
          <td className="text-rowcen">{getVendors.ocppProtocol}</td>
          <td className="wrap-word text-rowcen">{getVendors.ocppServerURL}</td>
          <td className="text-rowcen">&nbsp;<i className="fas fa-pencil-alt" title="Edit" style={{ cursor: 'pointer' }} onClick={this.brokerEdit.bind(this, getVendors.id)} ></i>  &nbsp;
            <i className="far fa-trash-alt" title="Delete" style={{ cursor: 'pointer' }} onClick={this.brokerDelete.bind(this, getVendors.id)}></i>
          </td>
          <td className="text-rowcen"><i className="fas fa-eye action-icon  mt-1 pl-4" title="View" style={{ cursor: 'pointer' }} onClick={this.toggleView.bind(this, getVendors.id, getVendors.vendorName)}
          ></i></td>
        </tr>
      )
    })

    return (
      <>
        <main class="content-div" >
          <p>{t('Vendor')}
            <div className="breadcrumb_div">
              {t('Market Place')} &gt; <span className="breadcrumb_page"> {t('Vendor')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <div className="row menubar">
              <div className="col-md-2 ">
                <div className="">
                  <button onClick={this.toggle} type="button" data-test="button">
                    <i className="fa fa-plus"></i>
                    <span>{t('add')}</span>
                  </button>
                </div>
              </div>
              <div className="col-md-10">
                <div className="pull-right">
                  <label className="pull-left sub-text">{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4  pull-right">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <Typeahead
                    labelKey="name"
                    className="w_80"
                    multiple={this.state.multiple}
                    options={this.state.options}
                    minLength={3}
                    placeholder={t('choose_vendor_nam')}
                    onKeyDown={this.handleKeyDown.bind(this, ref)}
                    onChange={this.vendorNameChange.bind(this)}
                    onBlur={this.onBlurEvent}
                    ref={ref}
                  />
                  <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.ClearVendorName.bind(this, ref)}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.FindVendorName1.bind(this, ref)} >{t('find')}</button>
                  </div>
                  <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["vehicleNameNotexists"]}</span></span>
                  <span className="pull-left error_msg w3-animate-top mt-1"><span style={{ color: "red" }}>{this.state.errors["selectVendorName"]}</span></span>
                </div>
              </div>
              <div className="col-md-3 pl-0"></div>
              <div class="col-md-2"></div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="text-rowcen">{t('vendor_nam')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="text-rowcen">{t('vendor_site')}</th>
                      <th className="text-rowcen">{t('ocpp_protocol')}</th>
                      <th className="text-rowcen">{t('ocpp_server_url')}</th>
                      <th className="text-rowcen">{t('action')}</th>
                      <th className="text-rowcen">{t('mapping')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>

          <MDBModal isOpen={this.state.modal} toggle={this.toggle} className="model_quickview" size="xl" backdrop={true} wrapClassName="custom_backdropclass">
            <div data-test="modal-header" class="modal-header">
              <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('insert_your_data')}</div>
              <button type="button" data-test="button" class="close" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <MDBModalBody className="popupScroll">
              <MDBRow className="mb-4 mt-3">
                <MDBCol sm="12">
                  <div class="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{ display: "none" }}></div>
                  <div>
                    <form
                      className='needs-validation'
                      onSubmit={this.handleSubmit}
                      noValidate
                    >
                      <MDBRow>
                        <MDBCol md="4" className="" >
                          <MDBInput
                            value={this.state.fields["vendorName"]}
                            name="vendorName"
                            onChange={this.changeHandler.bind(this, "vendorName")}
                            type="text"
                            id="vendorName"
                            label="Vendor Name*"
                          >
                            <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["vendorNameempty"]}</span></span>
                          </MDBInput>
                        </MDBCol>
                        <MDBCol md="4">
                          <MDBInput
                            name="contextPath"
                            value={this.state.fields["contextPath"]}
                            onChange={this.changeHandler.bind(this, "contextPath")}
                            type="text"
                            id="contextPath"
                            label="Context Path"
                          >
                          </MDBInput>
                        </MDBCol>
                        <MDBCol md="4" className="mt-3">
                          <Form.Check
                            custom
                            value={this.state.activateBroker}
                            checked={this.state.activateBroker}
                            onChange={this.activeBroker}
                            type="checkbox"
                            id="myCheck1"
                            label="Activate Broker"
                          />
                        </MDBCol>
                        <MDBCol md="4" className="form_margin" >
                          <MDBInput
                            value={this.state.fields["description"]}
                            name="description"
                            onChange={this.changeHandler.bind(this, "description")}
                            type="text"
                            id="description"
                            label={t('description')}
                          />
                        </MDBCol>
                        <MDBCol md="4" className="form_margin" >
                          <select className="browser-default custom-select select_height mb_8" id="ocppProtocol" onChange={this.ocppProtocolChange.bind(this)}  >
                            <option value=""> -- {t('select_ocpp_protocol')} --</option>
                            <option value="OCPP2.0">{t('ocpp20')}</option>
                            <option value="OCPP1.6">{t('ocpp16')}</option>
                          </select>
                          <span className="error_msg w3-animate-top">
                            <span style={{ color: "red" }}>{this.state.errors["ocppProtocolEmpty"]}</span>
                          </span>
                        </MDBCol>
                        <MDBCol md="4" className="mt-3" id="chkbox-down" style={{ display: this.state.checkBoxDisplay }}>
                          <Form.Check
                            custom
                            value={this.state.addCPIDInURL}
                            onChange={this.cpidUrl}
                            checked={this.state.addCPIDInURL}
                            type="checkbox"
                            id="myCheck2"
                            label={t('Add CPID in URL')}
                          />
                        </MDBCol>
                        <MDBCol md="4" className="form_margin" >
                          <MDBInput
                            value={this.state.fields["ocppServerURL"]}
                            name="ocppServerURL"
                            onChange={this.changeHandler.bind(this, "ocppServerURL")}
                            type="text"
                            id="ocppServerURL"
                            label={t('ocpp_server_url*')}
                          >
                            <span className="error_msg w3-animate-top">
                              <span style={{ color: "red" }}>{this.state.errors["urlempty"]}</span>
                            </span>
                          </MDBInput>
                        </MDBCol>
                        <MDBCol md="4" className="form_margin" >
                          <MDBInput
                            value={this.state.fields["vendorSite"]}
                            name="noteId"
                            onChange={this.changeHandler.bind(this, "vendorSite")}
                            type="text"
                            id="noteId"
                            label={t('vendor_site')}
                          />
                        </MDBCol>
                      </MDBRow>
                    </form>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter className="border-top-0 p-3">
              <button onClick={this.closetoggle} className="btn_cancel" type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
              <button onClick={this.handleSubmit} className="btn_primary" type="button" data-test="button"> {t('save')} <i class="fas fa-save ml-2"></i></button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.editmodal} toggle={this.toggleedit} className="model_quickview" size="xl" backdrop="static" data-backdrop="static" data-keyboard="false" wrapClassName="custom_backdropclass">
            <div data-test="modal-header" class="modal-header">
              <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('edit_your_data')}</div>
              <button type="button" data-test="button" class="close" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <MDBModalBody className="popupScroll" >
              <form
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
              >
                <MDBRow>
                  <MDBCol md="4" className="" >
                    <MDBInput
                      value={this.state.fields["vendorName"]}
                      name="vendorName"
                      onChange={this.changeHandler.bind(this, "vendorName")}
                      type="text"
                      id="vendorName"
                      label={t('vendor_name*')}
                    >
                      <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["vendorNameempty"]}</span></span>
                    </MDBInput>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBInput
                      name="contextPath"
                      value={this.state.fields["contextPath"]}
                      onChange={this.changeHandler.bind(this, "contextPath")}
                      type="text"
                      id="contextPath"
                      label={t('context_Path')}
                    />
                  </MDBCol>
                  <MDBCol md="4" className="mt-3">
                    <Form.Check
                      custom
                      value={this.state.activateBroker}
                      checked={this.state.activateBroker}
                      onChange={this.activeBroker}
                      type="checkbox"
                      id="myCheck1"
                      label={t('activate_broker')}
                    />
                  </MDBCol>
                  <MDBCol md="4" className="form_margin" >
                    <MDBInput
                      value={this.state.fields["description"]}
                      name="description"
                      onChange={this.changeHandler.bind(this, "description")}
                      type="text"
                      id="description"
                      label={t('description')}
                    />
                  </MDBCol>
                  <MDBCol md="4" className="form_margin" >
                    <select className="browser-default custom-select select_height mb_8" id="ocppProtocol" onChange={this.ocppProtocolChange.bind(this)}  >
                      <option value=""> -- {t('select_ocpp_protocol')} --</option>
                      <option value="OCPP2.0">{t('ocpp20')}</option>
                      <option value="OCPP1.6">{t('ocpp16')}</option>
                    </select>
                    <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["ocppProtocolEmpty"]}</span></span>
                  </MDBCol>
                  <MDBCol md="4" className="mt-3" id="chkbox-down" style={{ display: this.state.checkBoxDisplay }}>
                    <Form.Check
                      custom
                      value={this.state.addCPIDInURL}
                      onChange={this.cpidUrl}
                      checked={this.state.addCPIDInURL}
                      type="checkbox"
                      id="myCheck2"
                      label={t('add_cpid_in_url')}
                    />
                  </MDBCol>
                  <MDBCol md="4" className="form_margin" >
                    <MDBInput
                      value={this.state.fields["ocppServerURL"]}
                      name="ocppServerURL"
                      onChange={this.changeHandler.bind(this, "ocppServerURL")}
                      type="text"
                      id="ocppServerURL"
                      label={t('ocpp_server_url*')}
                    >
                      <span className="error_msg w3-animate-top"> <span style={{ color: "red" }} >{this.state.errors["urlempty"]}</span></span>
                    </MDBInput>
                  </MDBCol>
                  <MDBCol md="4" className="form_margin" >
                    <MDBInput
                      value={this.state.fields["vendorSite"]}
                      name="noteId"
                      onChange={this.changeHandler.bind(this, "vendorSite")}
                      type="text"
                      id="noteId"
                      label={t('vendor_site')}
                    />
                  </MDBCol>
                </MDBRow>
              </form>
            </MDBModalBody>
            <MDBModalFooter className="border-top-0 mt-2">
              <button className="btn_cancel" onClick={this.closetoggleedit} type="button" data-test="button"> {t('cancel')} <i class="fas fa-times ml-2"></i></button>
              <button className="btn_primary" onClick={this.saveeditdata.bind(this)} type="button" data-test="button">{t('update')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.addmodal} toggle={this.addtoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.addtoggle}>{this.state.addstatus}</MDBModalHeader>
            <MDBModalBody>
              {this.state.addmsg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.closeaddtoggle}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.addmodal2} toggle={this.addtoggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.addtoggle1}>{this.state.addstatus}</MDBModalHeader>
            <MDBModalBody>
              {this.state.addmsg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.closeaddtoggle1}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.updatemodal} toggle={this.updatetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.updatetoggle}>{this.state.updatestatus}</MDBModalHeader>
            <MDBModalBody>
              {this.state.updatemsg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.closeupdatetoggle}>{t('close')}</button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.closeupdatetoggle}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.deletemodal1} toggle={this.deletetoggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.deletetoggle1}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('are_you_want_to_delete')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_cancel" onClick={this.closedeletetoggle}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.deletetoggleOk}>{t('yes')}</button>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.deletemodal} toggle={this.deletetoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.deletetoggle}>{this.state.deletestatus}</MDBModalHeader>
            <MDBModalBody>
              {this.state.deletemsg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.confirmOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
             {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* confirm assign */}
          <MDBModal isOpen={this.state.confirmassignmodal} toggle={this.confirmassigntoggle} size="md" className="model_top">
            <MDBModalHeader toggle={this.confirmassigntoggle}>{t('confirmation')}</MDBModalHeader>
            <MDBModalBody>
              {t('are_you_want_to')} {this.state.assignAction} {t('the_vendor')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_cancel" onClick={this.subassignclosetoggle}>{t('cancel')} <i class="fas fa-times ml-2"></i></button>
              <button type="button" data-test="button" className="btn_primary" onClick={this.assignVendor}>{t('yes')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* assignSuccess */}
          <MDBModal isOpen={this.state.assignsuccessmodal} size="md" className="model_top">
            <MDBModalHeader toggle={this.assignsuccesstoggle}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('Vendor')} {this.state.assignSuccessName} {t('successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" data-test="button" className="btn_primary" onClick={this.assignsuccesstoggle}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* vendorassignment */}
          <ChargePointModel
            pageName="Vendor"
            data={this.state.jsonArray}
            cpList={this.state.cpList}
            isOpen={this.state.modalView}
            toggle={this.toggleView}
            selectedVendorName={this.state.selectedVendorName}
            cpStatus={cpStatus}
            cancelFunction={this.assignclosetoggle}
            doneFunction={this.confirmassigntoggle}
            btnDisable={this.state.disabledAttr}
            btnText={this.state.assignAction}
            cpactivePage={this.state.cpactivePage}
            itemsCountPerPage={this.state.cppageSize}
            noOfRecords={this.state.cpnoOfRecords}
            optionsdropdown={this.state.optionsdropdown}
            finalStatus={this.state.finalStatus}
            isCheckBox={this.state.isCheckBox}
            handlePageChange={this.cphandlePageChange}
            handleChange={this.handleChange}
            showEntriesChange={this.cpshowEntriesChange}
            getData={this.getApiChargePoints}
            vid={this.state.selectedVendorId}
            noWallboxExists = {this.state.noWallboxExists}
            removeCpId = {this.removeCpId}
          >

          </ChargePointModel>
        </main>
      </>
    );
  }
}


export default withTranslation()(vendor);