import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import Header from './header';
import * as baseUrl from './constants';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

class changeAvailability extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
            connectorID:"",
            fields:{
              connectorId:"",
              availabilityType:''
          },
          sucess:false,
          colour:'sucess',
          cpList:[],
          errors:{},
          page_title:"Upload Diagnostics",
          file:"",
          EVSN:"",
          chargePointId:"",
          id:"",
          modalsession:false,
          chargePoint:'',
          operationId:''
        }
        this.onShowAlert=this.onShowAlert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.fileChange = this.fileChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
}

async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}
onShowAlert(){
  
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
fileChange(e){

  var imagedata = document.getElementById("buildFile").files[0];
  var simple_file_upload = document.getElementsByClassName('simple_file_upload')[0];
  simple_file_upload.nextElementSibling.setAttribute('data-file', simple_file_upload.files[0].name);
  simple_file_upload.addEventListener('focus', function(){
  simple_file_upload.classList.add('focus');
  });
  simple_file_upload.addEventListener('blur', function(){
  simple_file_upload.classList.remove('focus');
  });
  this.setState({
  file:imagedata
  })
  if(imagedata!=""){
  
    this.state.errors["nofileError"]=" "
   
  }
 
}


async handleSubmit(event) {
  
  var fdsfs=this.state;
  // var payload=this.getPayLoad();
var errors={};
// var connectorId=payload.connectorId;
// var type=payload.type;
if(this.getCPList()==0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
}
if(this.state.file=="" || this.state.file==undefined){
  errors["nofileError"]=this.props.t('select_diagnostics')
}



if(this.getCPList()!=0 && this.file!=''&&this.state.file!= undefined){
  var EVSN;
  // this.state.chargePointId = event.target.value;
  
 var chargePointId = localStorage.getItem('chargePointId');
 
  var url = baseUrl.FOTAPath + servicesUrl.getDiagnosticsSN+"?EVSN="+chargePointId;
  // getter
  // POST /api/diagnostics/SN/{EVSN}
  this.setState({errors:errors})             
  var data = new FormData();   
  data.append("file",this.state.file);
  data.append("EVSN",this.state.chargePointId);
 

                                                                             
    fetch(url,{
      method: "PUT",
      body:data,
      headers: {
        "Authorization":localStorage.getItem("Authorization"),      }
      })
      .then((resp) => {
        
        if(resp.status==200){
          
          this.setState({message:this.propst('diagnostics_uploaded'),colour:'success'})
          this.onShowAlert();
        }
        if(resp.status==401){
          this.togglesession();
          // alert("Access token is expired, please login again")
          localStorage.clear();
        }
        if(resp.status== 400){
          // this.setState({modal2:false})
          // this.setState({message:"File name should start with serial number.",colour:'danger'})
          // this.onShowAlert();
          // return resp.json();
       }
       if(resp.status== 409){
        // this.setState({modal2:false})
        this.setState({message:this.props.t('notification_exists'),colour:'danger'})
        this.onShowAlert();
        return resp.json();
     }
     if(resp.status== 404){
      // this.setState({modal2:false})
      this.setState({message:this.props.t('diagnostics_not_exist'),colour:'danger'})
      this.onShowAlert();
      return resp.json();
   }
        
        else{
          return resp.json();
        }
      }) 
      .then((response) =>{
        
        this.setState({message:response[0].recommendation,colour:'danger'})
        this.onShowAlert();
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
}else{
this.setState({errors:errors})


}
}

getCPList() {
  var cplist = this.state.cpList;
  return cplist;
}

// getPayLoad() {
// var connectorId=this.state.fields.connectorId;
// var type=this.state.fields.availabilityType;

//   var payload = {
//     "connectorId": parseInt(this.state.fields.connectorId),
//     "type": this.state.fields.availabilityType
//   };
//   return payload;

// }

// getMessageType() {
//   return constants.CHANGE_AVILABILITY;
// }



//This is for making user should enter only numbers
numberHandler(field, e){ 
  e.preventDefault();
    let fields = this.state.fields;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields:fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields:fields});
    }

  }
  availabilityChange(){
    
    var x = document.getElementById("availabilityType").value;
    let fields = this.state.fields;
    fields["availabilityType"] = x;
    this.setState({fields:fields});
  }
  // connectorIdChange(id){
  //   // var x = document.getElementById("connectorId").value;
  //   alert(id);
  //   let fields = this.state.fields;
  //   fields["connectorId"] = id;
  //   this.setState({fields});
  // }
  changeHandler(field, e){ 

    let fields = this.state.fields;
    fields[field] = e.target.value; 
    this.setState({fields});
    }
  getSelectedCP(cpList){

    this.setState({cpList:cpList});
    if(cpList!=""){
    
      this.state.errors["emptyChargePoint"]=" "
      
  }
 
}
componentDidMount(){
  let propsdata = this.props.location.state;
    if(propsdata!=undefined){
      if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }  
}
render() {
  const{t}=this.props
  return (
    <>
   
   <main class="content-div" >
            <p>{t('Upload Diagnostics')}
              <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('Upload Diagnostics')}</span>
              </div>
          </p>
         {/* <p className="breadcrumb_div"><a>Operations ></a> <a>Change Availability </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Maintenance ></a> <a>Upload Diagnostics</a></div> */}

          {/* <p>Change Availability</p> */}
  <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
        
        <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
          <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} ></ChargePointSelection>
          <MDBCol md="12">
          <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>
          </MDBCol>
          <MDBCol md="4">
            <label className="mt-1" >
              <input type="file" class="simple_file_upload"  id="buildFile" onChange={this.fileChange.bind(this)} />
              <span data-file="No file choosen">{t('choose_diagnostics')}</span>
            </label>
             

             <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["nofileError"]}</span></span> 
           {/* </MDBInput> */}
          </MDBCol>
          </MDBRow>        
          <button type = "button" onClick={this.handleSubmit} className="mt-5 refresh-btn">{t('perform')}</button>          
        </form>
      </div>
         </MDBCol>          
        </MDBRow>
      
        </div>
        {/* popup for session expire */}

<MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
     <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
     <MDBModalBody>
      {t('please_login_again')}
     </MDBModalBody>
     <MDBModalFooter>
     {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
     <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
     </MDBModalFooter>
     </MDBModal>
   </main>
    </>
  );
}
}

export default withTranslation()(changeAvailability);