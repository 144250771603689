import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import DatePicker from "react-datepicker";
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import "react-datepicker/dist/react-datepicker.css";
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import Header from './header';
import { withTranslation } from 'react-i18next';
import * as baseUrl from './constants';

class GetDiagnostics extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
         
          startDate1:'',
          startDate2:'',
          startDate:'',
          startDate_in:'',
          cpList:[],
          fields:{
            retries: "",
            retryInterval:"",
           
          },
          location:"",
          sucess:false,
          colour:'sucess',
          page_title:"Get Diagnostics",
          errors:{},
          chargePoint:'',
          operationId:''
        }
        // this.setStartDate1 = this.setStartDate1.bind(this)
        // this.setStartDate2 = this.setStartDate2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.onShowAlert=this.onShowAlert.bind(this);

}
onShowAlert(){
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
changeHandler(field, e){ 
  ;
  e.preventDefault();
 var fdfd= this.state.fields[field];
  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields});
  }

//This is for making user should enter only numbers
numberHandler(field, e){ 
  e.preventDefault();

    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }
  async handleSubmit(event) {
    var fdf=this.state;
    console.log(fdf);
    var payload= this.getPayLoad();
    console.log(payload)

    var errors={};
    if(this.getCPList()==0){
      errors["emptyChargePoint"]=this.props.t('select_charge_point')
    }
    if(payload.location==""){
      errors["emptyLocation"]=this.props.t('enter_location')
    }
    if(this.getCPList()!=0 && payload.location!=""){
      var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
      //alert(response);
      if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
        this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
          this.onShowAlert();
        })
      } else {
        this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
          this.onShowAlert();
        })
      }
    }
    this.setState({errors:errors})
  }
  
  getCPList() {
    
    var Id = this.state.operationId;
    if(Id != null && Id!=""){
     var cplist = Id.split();
   
    }
    else{
     var cplist = this.state.cpList;
   
   }
   return cplist;
  }
  locationChange(location){
    this.setState({location:location.target.value})
  }
  getPayLoad() {
    var payload ={
      "location": this.state.location,
      "startTime": this.state.startDate1,
      "stopTime":  this.state.startDate2,
      "retries": parseInt(this.state.fields.retries),
      "retryInterval": parseInt(this.state.fields.retryInterval)
  };
  if(isNaN(payload.retries)){
    delete payload.retries;
  }
  if(isNaN(payload.retryInterval)){
    delete payload.retryInterval;
  }

    return payload;
  
  }
  
  getMessageType() {
    return constants.GET_DIAGNOSTICS;
  }
  getSelectedCP(cpList){
    ;
    this.setState({cpList:cpList});
  }
  handleChange = date => {
    ;
    this.setState({startDate:date});
    //var tempDate = new Date(date);
  
  
    var currentDate = new Date(date);
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  var csFormatDate=currentDate.getFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":00Z";

    // var date1 = (tempDate.getMonth()+1)  + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() 
   
    this.setState({startDate1:csFormatDate});
  };
  dateChange = date => {
  this.setState({startDate_in:date});
  //var tempDate = new Date(date);
    var currentDate = new Date(date);
  var twoDigitMonth=((currentDate.getMonth()+1)>=10)? (currentDate.getMonth()+1) : '0' + (currentDate.getMonth()+1);  
  var twoDigitDate=((currentDate.getDate())>=10)? (currentDate.getDate()) : '0' + (currentDate.getDate());
  var twominDate=((currentDate.getMinutes())>=10)? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
  var twohoursDate=((currentDate.getHours())>=10)? (currentDate.getHours()) : '0' + (currentDate.getHours());
  var createdDateTo =   twoDigitMonth + "-" + twoDigitDate+ "-" +currentDate.getFullYear() +' '+ twohoursDate +':'+ twominDate;
  
  var csFormatDate=currentDate.getFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":00Z";
    // var date1 = (tempDate.getMonth()+1)  + '-' + tempDate.getDate() + '-' + tempDate.getFullYear() 
   
    this.setState({startDate2:csFormatDate});
  };

  componentDidMount(){
    this.setState({
      location: baseUrl.FOTAPath + "diagnostics/SN/{cpId}"
    });
    let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
  }
  componentWillUnmount(){
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }
render() {
  const{t}=this.props
  return (
    <>

<main class="content-div" >
<p> {t('Get Diagnostics')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Get Diagnostics')}</span>
              </div>
           </p>
          {/* <p className="breadcrumb_div"><a>Operations ></a> <a> Get Diagnostics </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations ></a> <a> Get Diagnostics </a></div> */}

           
          {/* <p>Get Diagnostics</p> */}
       
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
        <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
          <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName = "GetDiagnostics"></ChargePointSelection>
          <MDBCol md="12">
         <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
         </MDBCol>
         <MDBCol md="4">
            <MDBInput
                value={this.state.location}
                name="location"
                disabled="true"
                onChange={this.locationChange.bind(this)}
                type="text"
                label={t('location_directory_uri')}
                required
              >
      <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyLocation"]}</span></span>

                <div className="valid-feedback">{t('looks_good')}</div>
               
              </MDBInput>
            	
         </MDBCol>
         <MDBCol md="4">
            <MDBInput
                 value={this.state.fields["retries"]}
                name="retries"
                onInput={this.numberHandler.bind(this,"retries")}
                type="text" pattern="[0-9]*"
                id="retriesId"
                label={t('retries_integer')}
                required
              >
                <div className="valid-feedback">{t('looks_good')} </div>
              </MDBInput>
         </MDBCol>
         <MDBCol md="4">
            <MDBInput
                value={this.state.fields["retryInterval"]}
                name="RetryInterval"
                onInput={this.numberHandler.bind(this,"retryInterval")}
                type="text" pattern="[0-9]*"
                id="retryIntervalId"
                label={t('retry_interval_integer')}
                required
              >
                <div className="valid-feedback">{t('looks_good')} </div>
              </MDBInput>
         </MDBCol>
         <MDBCol md="4" className="mt-2">
          <span className="cal_label">{t('start_date_time')}</span>
          <div className="pull-left mt-2" style={{width:"55%"}} >
              <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  className="cal_margin tag_cal"
                  timeCaption="time"
                  dateFormat="dd-MMM-yyyy HH:mm"
                />
                  {/* <Datetime name="expirydate" id="expiryDate"   value={this.state.fields["expiryDate"]}  dateFormat="MM/DD/YYYY" timeFormat="HH:mm:ss" onChange={this.setStartDate1.bind(this, "expiryDate")} /> */}
              </div>

                 </MDBCol>
      
          <MDBCol md="4" className="mt-2" style={{position:"relative"}} >
               <span className="cal_label">{t('stop_date_time')} </span>
               <div className="pull-left mt-2" style={{width:"55%"}} >
              <DatePicker
                  selected={this.state.startDate_in}
                  onChange={this.dateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  className="cal_margin tag_cal"
                  timeCaption="time"
                  dateFormat="dd-MMM-yyyy HH:mm"
                />
                  {/* <Datetime name="expirydate" id="expiryDate"   value={this.state.fields["expiryDate"]}  dateFormat="MM/DD/YYYY" timeFormat="HH:mm:ss" onChange={this.setStartDate1.bind(this, "expiryDate")} /> */}
              </div>
               </MDBCol>

          </MDBRow>

          <button onClick={this.handleSubmit} type="button" className="mt-5 refresh-btn">{t('perform')}</button>
          
        </form>
      </div>
         </MDBCol>

        </MDBRow>
      
        </div>
    
    </main>
    </>
  );
}
}

export default withTranslation()(GetDiagnostics);