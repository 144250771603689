const servicesUrl = {
    "getChargePointOperations" : "chargepoints/chargeroperations?id=",
    "getAllCplistForNewRfid" : "ocpptags/all/cplist",
    "getCustomizationWithTenant" : "admin/v1.0/account/tenant/customization?orgName=",
    "getSecurityPreferences" : "preferences/security",
    "getChargePointConfiguration" : "chargepoints/configuration",
    "getChargePointCplistByModelOrCPId":"chargepoints/cplistByModelOrCPId",
    "getChargePointCplistWithModels":"chargepoints/cplistWithModels",
    "getChargePointbycp":"chargepoints/getbycp",
    "getChargePointlocalListversion":"chargepoints/localListversion",
    "getChargePointConnectorSearch":"chargepoints/search/connector/search",
    "getChargePointTransactionSearch":"chargepoints/search/transaction",
    "getChargePointDetails":"chargepoints/details",
    "getChargePointsLatestfirmware":"chargepoints/latestfirmware",
    "getChargepointsPartilaSearch":"chargepoints/search/partial",
    "getCPattachmentBySerialnumber":"chargepoints/attachment/byserialnumber",
    "getCPchargepointids":"chargepoints/chargepointids",
    "getCPchargepointidsPartial":"chargepoints/chargepointids/partial",
    "getCPConfigurationKeys":"chargepoints/configurationKeys",
    "getPingpong":"pingpong/id",
    "getChargingprofilesByprofileId":"chargingprofiles/profileId",
    "getChargingprofiles":"chargingprofiles/profileId",
    "getChargingProfilesWId":"chargingprofiles",
    "getchargingschedules":"chargingschedules",
    "getchargingschedulesByscheduleid":"chargingschedules/scheduleid",
    "getCpInventoryOrgByModel":"cpinventory/orgbymodel",
    "updateCpInventory":"cpinventory/update",
    "getCpInventoryEvmodelOrg":"cpinventory/evmodel/organizations",
    "getCpInventory":"cpinventory/bycpId",
    "getCpOperationsLogs":"cp/operations/logs/byDate",
    "getCsOperationsLogs":"cs/operations/logs/byDate",
    "getOwtinventoriesSearchByCp":"owtinventories/search/bychargepointId",
    "getOwtinventoriesBychargepointId":"owtinventories/bychargepointId",
    "getCustomerServiceCP":"customerservice/chargepoints",
    "getCustomerserviceCPErrorGroup":"customerservice/chargepoints/errorgroup",
    "getCustomerserviceCPfirmwareStatus":"customerservice/chargepoints/firmwarestatus",
    "getCustomerserviceCPStatus":"customerservice/chargepoints/status",
    "getCustomerserviceCPLogs":"customerservice/chargepoints/logs",
    "getCustomerserviceCPHeartbeats":"customerservice/chargepoints/heartbeats",
    "getDiagnosticsSN":"diagnostics/SN",
    "getDiagnosticsCP":"diagnostics/chargepoints",
    "getDiagnosticsCPDetails":"diagnostics/chargepoints/details",
    "getDiagnosticsArchive":"diagnostics/archive",
    "updateDashboardBuildArchive":"dashboard/build/archive",
    "updateDashboardBuildRollout":"dashboard/build/rollout",
    "getDashboardBuild":"dashboard/build",
    "getDashboardBuildRolloutHistory":"dashboard/build/rollout/history",
    "getHomeCPChargingsession":"home/chargepoint/chargingsession",
    "getOcpptagsByIdtag":"ocpptags/getbyidtag",
    "getOcpptagById":"ocpptags/id",
    "getOcpptagList":"ocpptags/list",
    "getOcpptagUpdateCPAssociation":"ocpptags/updatecpassociation",
    "getOcpptagsAssociatedPartialSearch":"ocpptags/associated/search/partial",
    "getOcpptagsUnassociatedPartialSearch":"ocpptags/unassociated/search/partial",
    "getOcpptagsCPAssociated":"ocpptags/charger/associated",
    "getOcpptagsCPUnassociated":"ocpptags/charger/unassociated",
    "getOcpptag":"ocpptags",
    "getOcppTagsPartialSearch":"ocpptags/search/partial",
    "getOcppTagsSearch":"ocpptags/search",
    "activateAdminAccount":"admin/v1.0/account/activate",
    "deactivateAdminAccount":"admin/v1.0/account/deactivate",
    "getAdminAccountByid":"admin/v1.0/account/id",
    "getPublishnotificationsByOrg":"publishnotifications/getbyorg",
    "getPublishnotificationslogsOrg":"publishnotifications/logs/organization",
    "getPushnotificationsDetails":"pushnotifications/getDetails",
    "updateReassignmentUsers":"reassignment/users",
    "updateSitemanagementWallbox":"sitemanagement/wallbox",
    "getSitemanagementWallbox":"sitemanagement/wallbox/siteid",
    "getSitemanagement":"sitemanagement",
    "getSitemanagementUser":"sitemanagement/user",
    "getTenantConfigurationServiceType":"tenantconfiguration/serviceType",
    "getTimeouthinderBycp":"timeouthinder/getbycp",
    "downloadTransactions":"transactions/download",
    "getTransactionsByTransId":"transactions/getbytranId",
    "getTranBusinessErrorsByTransId":"transactions/tranid/businesserrors",
    "updateByTranId":"transactions/updateByTranId",
    "getUpdateRemotestartTrans":"transactions/updateRemotestart",
    "getTransMeterValue":"transactions/metervalues",
    "getVehiclesByname":"v1.0/vehicles/all/getbyname",
    "getVehiclesBynameById":"v1.0/vehicles",
    "getVehicles":"v1.0/vehicles",
    "getBrokerVendorMapperPartialSearch":"broker/vendor/mapper/search/partial",
    "getBrokerVendorMapperMapCharger":"broker/vendor/mapper/mapCharger/",
    "getBrokerVendorName":"broker/vendor/get/name",
    "getBrokerVendorSearchmatchedname":"broker/vendor/search/getmatchedname",
    "getBrokerVendorSearch":"broker/vendor/search",
    "getTransactionsMetervalueBytransid":"transactions/metervalue/bytransid",
    "getVendorBroker":"broker/vendor/"
}

export default servicesUrl;
