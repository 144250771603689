import React from 'react';
import { Form, Table } from 'react-bootstrap';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';


class NewSiteUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: {
                notexists: this.props.emailError
            },
            inviteEmail: ''
        }
    }
    componentWillUnmount() {
        this.props.hideinvite();
    }
    render() {
       const{t}=this.props
        return (
            <div style={{ marginTop: "10px" }}>
                <div className="row ">
                    <div className="col-md-6"></div>
                    <div className="col-md-5">
                        <div class="input-group">
                            <i className="fas fa-search fa-sm input-searchIcon"></i>
                            <input
                                type="text"
                                id="chargePointId"
                                value={this.props.email}
                                onChange={this.props.handleEmailChange}
                                onKeyDown={this.props.onpress}
                                class="form-control input-searchBox pad-2"
                                placeholder={t('search_and_invite_users')}
                                name="srch-term"
                            />
                            <div class="input-group-btn">
                                <button class="input-searchBtn find-btn" type="submit" onClick={()=>{
                                    if(this.props.email == null || this.props.email == ""){
                                        $("#empty_users").text(t('please_enter_email_name'))
                                    }else{
                                        $("#empty_users").text("")
                                        this.props.onChangeInvitemail();
                                        this.props.emailSearch(this.props.id, this.props.email)
                                    }
                                    }}>{t('find')}</button>
                            </div>
                        </div>
                        {
                            (this.props.displayInvite == 'none') ?
                                <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.props.emailError}</span></span> : <></>
                        }
                        <span className="error_msg w3-animate-top mt-2" id="empty_users" style={{ color: "red" }}></span>
                        {/* <div className="site-invite-triangle-bg">▲</div> */}
                        <div class="triangle-with-shadow" style={{ display: this.props.displayInvite }}></div>
                        <div className='site-invite-bg' style={{ display: this.props.displayInvite, cursor: 'pointer' }} onClick={() => { this.props.inviteEmail(this.props.id, this.props.inviteemail) }}>
                            <div className="site-invite-btn">
                                 {t('invite')} <input disabled type="text" name="" id="" value={this.props.inviteemail} style={{ width: "75%", border: "none", backgroundColor: "#fff" }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    {/* <div className="col-md-12">
                    <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.props.emailError}</span></span>
                    </div> */}
                    <div className="col-md-12" style={{ maxHeight:"245px", overflowY:"auto" }}>
                        <Table striped hover size="sm" className="page-table">
                            <thead>
                                <tr>
                                    <th>{t('e_mail')}</th>
                                    <th>{t('user_level')}</th>
                                    <th>{t('access')}</th>
                                    <th>{t('site_admin')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.users?.map((user, index) => {

                                        if(user.id == localStorage.getItem('userId') || this.props.isCreatedBy == user.id ){
                                            return (
                                                <tr key={index}>
                                                    <td>{user.email}</td>
                                                    <td>{user.role}</td>
                                                    <td className="text-center">
                                                        <Form.Check disabled id={`access_check_${index}`} custom type="checkbox" label="" checked={this.props.isAccessChecked(user)} onChange={(event) => this.props.onChangeAccessCheckbox(event, user)}></Form.Check>
                                                    </td>
                                                    <td className="text-center">
                                                        <Form.Check disabled id={`site_admin_check_${index}`} custom type="checkbox" label="" checked={this.props.isAdminChecked(user)} onChange={(event) => this.props.onChangeAdminCheckbox(event, user)}></Form.Check>
                                                    </td>
                                                </tr>
                                            );
                                        }else{
                                            return (
                                                <tr key={index}>
                                                    <td>{user.email}</td>
                                                    <td>{user.role}</td>
                                                    <td className="text-center">
                                                        <Form.Check id={`access_check_${index}`} custom type="checkbox" label="" checked={this.props.isAccessChecked(user)} onChange={(event) => this.props.onChangeAccessCheckbox(event, user)}></Form.Check>
                                                    </td>
                                                    <td className="text-center">
                                                        <Form.Check id={`site_admin_check_${index}`} custom type="checkbox" label="" checked={this.props.isAdminChecked(user)} onChange={(event) => this.props.onChangeAdminCheckbox(event, user)}></Form.Check>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        
                                    }
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="text-right">
                    <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" onClick={() => { this.props.updateExistingSite(this.props.id) }}>{t('update')}</button>
                    <button className="mt-5 btn_cancel" type="button" data-test="button" onClick={this.props.cancel}>
                        {t('cancel')} <i class="fas fa-times ml-2"></i>
                    </button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NewSiteUsers);