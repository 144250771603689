import React from 'react';
import CsrLandingTemplate from '../Screens/CsrLandingTemplate';
import * as baseUrl from '../views/constants';
import $ from 'jquery';
import moment from 'moment';
import servicesUrl from '../common/servicesUrl';
import { withTranslation } from 'react-i18next';

 class CsrLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            multiple: false,
            options: [],
            selectFindStatus: false,
            saveSelectedCpid: '',
            dataArray: [],
            selectDataArray: [],
            pageSize: 10,
            activePage: 1,
            noOfRecords: '',
            errors: {},
            modalsession: false,
            intial_data: [],
            chargePoint: '',
            fields: {},
            cpIdSorting: false,
            modalView: false,
            fromDateApi: '',
            toDateApi: '',
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            prevPageFirstCreTime: "",
            listOfFirstCreatedtimes: []
        }
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.getCsrListing = this.getCsrListing.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.getInventorybasedonchargepoint = this.getInventorybasedonchargepoint.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.getChargePoint = this.getChargePoint.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
        this.colorCodeChange = this.colorCodeChange.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.ascendingOrder = this.ascendingOrder.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.getQuickViewList = this.getQuickViewList.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    }

    chargepointIDChange(cpid) {
        this.setState({ selectFindStatus: true, saveSelectedCpid: cpid }, () => {
            if (cpid.length == 0) {
                this.getCsrListing(this.state.pageSize, this.state.activePage)
            } else {
                let errors = {};
                errors["chargePointNotExists"] = "";
                errors["selectChargepoint"] = "";
                this.setState({
                    errors: errors
                });
                this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage, cpid);
            }
        });
    }

    // api to get csr listing
    async getCsrListing(pageSize, pageNo, countValue) {
        document.getElementById("loader_image_div").style.display = "block";

        if (this.state.selectFindStatus == true) {
            var url = baseUrl.URLPath + "chargepoints/csr?pageSize=" + pageSize + "&pageNo=" + 1 + "&chargePointId=" + this.state.saveSelectedCpid + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;
        }
        else {
            var url = baseUrl.URLPath + "chargepoints/csr?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;
        }

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        })
        .then((resp) => {
            if (resp.status == 401) {
                setTimeout(() => {
                    document.getElementById("loader_image_div").style.display = "none";
                    this.togglesession();
                    localStorage.clear();
                }, 100);
            }
            if (resp.status == 200) {
                return resp.json();
            }
        })
        .then((response) => {
            if (response != undefined) {
                if(countValue == false){
                    setTimeout(() => {
                        this.setState({
                            dataArray: response.cpList,
                            selectDataArray: response.cpList,
                            prevPageFirstCreTime: this.state.curPageFirstCreTime,
                            curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                        },()=>{
                            document.getElementById("loader_image_div").style.display = "none";
                        });
                    }, 100);
                }else{
                    setTimeout(() => {
                        this.setState({
                            dataArray: response.cpList,
                            selectDataArray: response.cpList,
                            noOfRecords: response.count.count,
                            prevPageFirstCreTime: this.state.curPageFirstCreTime,
                            curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                        },()=>{
                            document.getElementById("loader_image_div").style.display = "none";
                        });
                    }, 100);
                }
            }
        })
        .catch((error) => {
            setTimeout(() => {
                console.log(error, "catch the loop")
                document.getElementById("loader_image_div").style.display = "none";
            }, 100);
        })
    }

    componentDidMount() {
        this.getCsrListing(this.state.pageSize, this.state.activePage);
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    getInventorybasedonchargepoint(pageSize, pageNo, action) {
        var errors = {};

        if (this.state.selectFindStatus == true) {
            var url = baseUrl.URLPath + "chargepoints/csr?pageSize=" + pageSize + "&pageNo=" + 1 + "&chargePointId=" + this.state.saveSelectedCpid;
        }
        else {
            var url = baseUrl.URLPath + "chargepoints/csr?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
        }

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 404) {
                    errors["chargePointNotExists"] = this.props.t('charge_point_not_exists')
                    this.setState({
                        errors: errors
                    });
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    if (response.count.count == 0) {
                        if (action == "click" || action == "enter") {
                            $("#notexists").text(this.props.t('charge_point_not_exists'));
                        }
                        this.setState({
                            selectDataArray: [],
                            noOfRecords: 0
                        });
                    } else {
                        if (action == "click" || action == "enter") {
                            $("#notexists").text(" ");
                        }
                        this.setState({
                            selectDataArray: response.cpList,
                            noOfRecords: response.count.count
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    handleKeyDown(ref, e) {
        var serial_no = e.target.value;

        if (serial_no != 0) {
            this.state.errors["selectChargepoint"] = "";
        }

        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);

        if (key == "Backspace" || key == "Delete") {
            this.state.errors["selectChargepoint"] = "";
            $("#notexists").text("");
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno == "") {
                this.setState({
                    saveSelectedCpid: sno
                }, () => {
                    this.getCsrListing(this.state.pageSize, this.state.activePage);
                });
            }
        }

        var sno = serial_no.slice(0, serial_no.length - 1);
        this.getSearchInventoryList(sno);

        if (e.keyCode == 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        }
        if ((e.keyCode == 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        }
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    getSearchInventoryList(cpid) {
        var url = baseUrl.URLPath + servicesUrl.getChargepointsPartilaSearch+"?cpid=" + cpid;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    this.setState({
                        options: response,
                        intial_data: response
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop");
            })
    }

    FindCpName(ref, event) {
    

        this.setState({
            saveSelectedCpid: ref.current.inputNode.value
        }, () => {
            if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
                let errors = this.state.errors;
                errors['selectChargepoint'] = this.props.t('select_the_charge_point')
                this.setState({
                    errors: errors
                }, () => {
                    $("#notexists").text(" ");
                })
            } else {
                let errors = this.state.errors;
                errors['selectChargepoint'] = "";
                this.setState({
                    errors: errors
                }, () => {
                    this.getInventorybasedonchargepoint(this.state.pageSize, this.state.activePage, "click");
                })
            }
        })
    }

    getChargePoint(event) {
        var chargePoint = event.target.value;
        let errors = {};
        errors["chargePointNotExists"] = "";

        this.setState({
            chargePoint: chargePoint,
            errors: errors
        }, () => {
            if (chargePoint.length == 0) {
                this.getCsrListing(this.state.pageSize, this.state.activePage);
            }
        });
    }

    ClearChargepoint(ref, event) {
        let errors = this.state.errors;
        errors["selectChargepoint"] = "";
        this.setState({
            selectFindStatus: false,
            errors: errors,
            saveSelectedCpid: '',
            pageSize: 10,
            activePage: 1,
            chargePoint: ''
        }, () => {
            $("#notexists").text("");
            this.getCsrListing(this.state.pageSize, this.state.activePage);
        });
        ref.current.clear();

    }

    colorCodeChange() {
        var id = document.getElementById("selectedColor").value;
        let fields = this.state.fields;
        fields["codeId"] = id;
        this.setState({ fields: fields });
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.state.activePage = 1;
        this.state.pageSize = entries;
        this.setState({
            activePage: 1,
            pageSize: entries
        }, () => {
            this.getCsrListing(entries, 1);
        })
    }

    //function for sorting table data on click
    ascendingOrder() {
        if (this.state.cpIdSorting == false) {
            this.setState({ cpIdSorting: true, selectDataArray: this.state.selectDataArray.sort(this.dynamicSort("chargePointId")) })
        }
        else {
            this.state.selectDataArray.sort(this.dynamicSort("chargePointId"));
            this.setState({ cpIdSorting: false, selectDataArray: this.state.selectDataArray.reverse() });
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder == -1) {
                return b[property].localeCompare(a[property]);
            } else {

                return a[property].localeCompare(b[property]);
            }
        }
    }

    getQuickViewList(chargepointid) {
        var url = baseUrl.URLPath + servicesUrl.getCustomerServiceCP + "?cpid=" + chargepointid;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        })
        .then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }
            if (resp.status == 500) {
                alert("internal server error")
            }
            if (resp.status == 400) {
                console.log(resp.status)
            }
            if (resp.status == 200) {
                return resp.json();
            }
        })
        .then((response) => {
            if (response != undefined) {
                this.setState({
                    fields: response,
                },()=>{
                    this.toggleView();
                })
            }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    toggleView() {
        this.setState({
            modalView: !this.state.modalView
        });
    }

    handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
            //prev
            this.setState({
                activePage: pageNumber,
                fromDateApi: this.state.curPageFirstCreTime,
                toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
            }, () => {
                this.getCsrListing(this.state.pageSize, pageNumber, false);
            });
        } else {
            //next
            let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
            this.setState({
                activePage: pageNumber,
                fromDateApi: '',
                toDateApi: this.state.curPageLastCreTime,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
            }, () => {
                if (pageNumber > _datesArrayLength + 1) {
                    this.createdDatesArray(this.state.curPageFirstCreTime);
                }
                this.getCsrListing(this.state.pageSize, pageNumber, false);
            });
        }
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    render() {
        const{t}=this.props
        const ref = React.createRef();
        return (
            <>
                <CsrLandingTemplate
                    modalsession={this.state.modalsession}
                    modalView={this.state.modalView}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    selectDataArray={this.state.selectDataArray}
                    fields={this.state.fields}
                    pageSize={this.state.pageSize}
                    activePage={this.state.activePage}
                    noOfRecords={this.state.noOfRecords}
                    errors={this.state.errors}
                    sessiontagOk={this.sessiontagOk}
                    chargepointIDChange={this.chargepointIDChange}
                    handleKeyDown={this.handleKeyDown}
                    getChargePoint={this.getChargePoint}
                    clearChargepoint={this.ClearChargepoint}
                    FindCpName={this.FindCpName}
                    colorCodeChange={this.colorCodeChange}
                    showEntriesChange={this.showEntriesChange}
                    ascendingOrder={this.ascendingOrder}
                    getQuickViewList={this.getQuickViewList}
                    handlePageChange={this.handlePageChange}
                    toggleView={this.toggleView}
                />

                {/* Loader */}
                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </>
        )
    }
}

export default withTranslation()(CsrLanding);