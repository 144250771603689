import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import apiCallUtility from './APICallUtility';
import * as constants from './constants';
import ChargePointSelection from './chargePointSelection';
import Header from './header';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';


class getCompositeSchedule extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          duration: "",
          cpList:[],
          errors:{},
          chargeRateUnit:"",
          sucess:false,
          colour:'sucess',
          fields:{
            duration:'',
            connectorID:'',
          },
          page_title:"Get Composite Schedule",
          chargePoint:'',
          operationId:''
        }
        this.onShowAlert=this.onShowAlert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
         this.connectorIdChange=this.connectorIdChange.bind(this);
        this.chargeRateUnit=this.chargeRateUnit.bind(this);
        this.changeHandler=this.changeHandler.bind(this);
}
//This is for making user should enter only numbers
numberHandler(field, e){ 

    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
      this.state.errors["emptyConnectorId"]=" "
     
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    
    
    }
   
  }
  onShowAlert(){
    
    
   this.setState({sucess:true},()=>{
     window.setTimeout(()=>{
       this.setState({sucess:false})
     },5000)
   });
  
  }
  changeHandler(field, e){ 

    let fields = this.state.fields;
    fields[field] = e.target.value; 
    this.setState({fields});
    }

  async handleSubmit(event) {
    
    var fdsfs=this.state;
    var payload=this.getPayLoad();
  var errors={};
  var connectorId=payload.connectorId;
  var type=payload.type;
  if(this.getCPList()==0){
    errors["emptyChargePoint"]=this.props.t('select_charge_point')
  }
  if(isNaN(connectorId)){
    errors["emptyConnectorId"]=this.props.t('enter_connector_id')
  }
  
  if(this.getCPList()!=0 && !isNaN(connectorId)){
    var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
   // alert(response);
   if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
    this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
      this.onShowAlert();
    })
  } else {
    this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
      this.onShowAlert();
    })
  }
  }
  this.setState({errors:errors})
  
  }

  getCPList() {
    var Id = this.state.operationId;
    if(Id != null&& Id != ""){
     var cplist = Id.split();
   
    }
    else{
     var cplist = this.state.cpList;
   
   }
   return cplist;
  }
  
  getPayLoad() {
    var payload =
    {
      "connectorId":parseInt(this.state.fields.connectorID),
      "duration":parseInt(this.state.fields.duration),
      "chargingRateUnit":this.state.chargeRateUnit
    };
    return payload;
  
  }
  connectorIdChange(){
    this.setState({connectorId:document.getElementById("connectorId").value});
  }
  getMessageType() {
    return constants.GET_COMPOSITE_SCHEDULE_REQUEST;
  } 
  getSelectedCP(cpList){

    this.setState({cpList:cpList});
    if(cpList!=""){
 
      this.state.errors["emptyChargePoint"]=" "
      
    }
   
  }
  chargeRateUnit(){
    this.setState({chargeRateUnit:document.getElementById("chargeRateUnit").value});
  }
componentDidMount(){
  let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
}
  componentWillUnmount(){
    localStorage.setItem('chargePoint', '');
    localStorage.setItem('operationId', '');
  }
  componentDidMount(){
    let propsdata = this.props.location.state;
    if(propsdata!=undefined){
      if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }  
  }
render() {
  const{t}=this.props
  return (
    <>
    
    <main class="content-div" >
    <p>  {t('Get Composite Schedule')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">  {t('Get Composite Schedule')} </span>
              </div>
           </p>
    {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Get Composite Schedule </a></p> */}
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Get Composite Schedule </a></div> */}
        <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>

          {/* <p>Get Composite Schedule</p> */}
      {/* <section className="dashboard">
        <div class="col-12 breadcrumbrow">
            <div class="page-title-box">
                <h4 class="page-title float-left" >Get Composite Schedule</h4>
                <div class="clearfix"></div>
            </div>
        </div>
        <section className="content_wbg"> 
        */}
           


        <MDBRow className="mb-4">
            <MDBCol sm="12">
              
               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
            <MDBRow>
            {/* <MDBCol md="12">
            <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              <button class="mr-2 select_btn btn_cursor">Select All</button> <button class="mr-4 select_btn btn_cursor">Select None </button>
            </label>
            <select className="browser-default custom-select select_height1" id="confKeyList" name="confKeyList" multiple="multiple" size="14"></select>
             </div>
            </MDBCol> */}
              <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP}operationName ="GetCompositeSchedule" ></ChargePointSelection>
              <MDBCol md="12">
          <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>
          </MDBCol>
              <MDBCol md="4">
                      {/* <select className="browser-default custom-select select_height" id="connectorId" onChange={this.connectorIdChange.bind(this)}>
                          <option>Connector ID	</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                      </select> */}
                         <MDBInput
              value={this.state.fields["connectorID"]}
              name="ConnectorID"
                onInput={this.numberHandler.bind(this,"connectorID")}
                type="text"  pattern="[0-9]*"
              //  onChange={this.changeHandler.bind(this, "connectorId")}
                id="connectorID"
                label={t('connector_id_integer')}
                required
              >
                    <span className="error_msg w3-animate-top">  <span style={{color: "red"}} >{this.state.errors["emptyConnectorId"]}</span></span>

                <div className="valid-feedback">{t('looks_good')}</div>
                <small id="heartbeatinterval" className="form-text text-muted">
                {t('if_empty0charge_point')}
                </small>
              </MDBInput>
              </MDBCol>
            <MDBCol md="4">
              <MDBInput
                value={this.state.fields.duration}
                name="duration"
                onInput={this.numberHandler.bind(this,"duration")}
                type="text" pattern="[0-9]*"
                id="durationId"
                label={t('duration_in_seconds')}
                required
              >
                <div className="valid-feedback">{t('looks_good')}</div>
               
              </MDBInput>
            </MDBCol>
            <MDBCol md="4">
            <select className="browser-default custom-select select_height" id="chargeRateUnit" onChange={this.chargeRateUnit}>
            	
            <option value="">{t('select_charging_rate_unit')}</option>
            <option value="W">{t('w')}</option>
            <option value="A">{t('a')}</option>
            </select>
            </MDBCol>
         </MDBRow> 
         <button className="mt-5 refresh-btn" onClick={this.handleSubmit} type="button" data-test="button">{t('perform')}</button>
        </form>
      </div>
         </MDBCol>
        </MDBRow>
        </div>
    </main>
    </>
  );
}
}

export default withTranslation()(getCompositeSchedule);