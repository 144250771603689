import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import DatePicker from "react-datepicker";
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import "react-datepicker/dist/react-datepicker.css";
import ChargePointSelection from './chargePointSelection';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';


class updateFirmware extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          retries: "",
          cpList:[],
          retryInterval: "",
          zip: "",
          startDate1:"",
          location:'',
          sucess:false,
          colour:'sucess',
          page_title:"Update Firmware",
          errors:{},
          chargePoint:'',
          operationId:''
        }
        this.setStartDate1 = this.setStartDate1.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
}
onShowAlert(){

 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
 
async handleSubmit(event) {

//  var fs= this.getCPList(); 
 var fdf=this.state;
 console.log(fdf);
 var payload=this.getPayLoad();

 var errors={};
//  alert(this.getCPList())
 if(this.getCPList()==0){
   errors["emptyChargePoint"]=this.props.t('select_charge_point')
 }
//  if(payload.location==""){
//    errors["emptyLocation"]="Please enter location."
//  }
 if(payload.retrieveDate==""){
   errors["emptyretrievedate"]=this.props.t('enter_retrieve_date/time')
 }
 if(this.getCPList()!=0 && payload.retrieveDate!=""){
 var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
//  alert(response);
if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
  this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
    this.onShowAlert();
  })
} else {
  this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
    this.onShowAlert();
  })
}
}
this.setState({errors:errors})
}
componentDidMount(){
  let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }
}
getCPList() {
  var Id = this.state.operationId;
  if(Id != null && Id!=""){
   var cplist = Id.split();
 
  }
  else{
   var cplist = this.state.cpList;
 
 }
 return cplist;
}
onChangeLocation(e){
 
  var x=e.target.value
  
  this.setState({location:e.target.value})
  if(x!=0){
 
    this.state.errors["emptyLocation"]=" "
   
  }

}

getPayLoad() {
  var payload =
  
  {
    "retrieveDate": this.state.startDate1,
    "location": this.state.location,
    "retries": parseInt(this.state.retries),
    "retryInterval": parseInt(this.state.retryInterval)
  };
   if(isNaN(payload.retries)){
    delete payload.retries;
  }
  if(isNaN(payload.retryInterval)){
    delete payload.retryInterval;
  }
  
  return payload;

}

getMessageType() {
  return constants.UPDATE_FIRMWARE;
}

setStartDate1(date){
  this.setState({ startDate1: date })
}
//This is for making user should enter only numbers
numberHandler(field, e){ 
    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }
  getSelectedCP(cpList){

    this.setState({cpList:cpList});
    if(cpList!=""){
    
      this.state.errors["emptyChargePoint"]=" "
     
    }
  
  }
  handleChange = date => {
  
    this.setState({startDate:date});
    //var tempDate = new Date(date);

    var currentDate = new Date(date);
  
    var twoDigitMonth=((currentDate.getUTCMonth()+1)>=10)? (currentDate.getUTCMonth()+1) : '0' + (currentDate.getUTCMonth()+1);  
    var twoDigitDate=((currentDate.getUTCDate())>=10)? (currentDate.getUTCDate()) : '0' + (currentDate.getUTCDate());
    var twominDate=((currentDate.getUTCMinutes())>=10)? (currentDate.getUTCMinutes()) : '0' + (currentDate.getUTCMinutes());
    var twohoursDate=((currentDate.getUTCHours())>=10)? (currentDate.getUTCHours()) : '0' + (currentDate.getUTCHours());
    var csFormatDate=currentDate.getUTCFullYear()+"-"+twoDigitMonth+"-"+twoDigitDate+"T"+twohoursDate+":"+twominDate+":00Z";

  
    this.setState({startDate1:csFormatDate});
     
  if(date!=null){
   
    this.state.errors["emptyretrievedate"]=" "
    
  }
  
  };
  componentDidMount(){
    let propsdata = this.props.location.state;
    if(propsdata!=undefined){
      if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
        this.setState({
          chargePoint: propsdata.chargePoint,
          operationId: propsdata.operationId
        });
      }
    }  
  }
render() {
  const{t}=this.props
  return (
    <>
 <main class="content-div" >
 <p>  {t('Update Firmware')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page"> {t('Update Firmware')} </span>
              </div>
           </p>
          {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Update Firmware </a></p> */}
         <div className="page-outerdiv">
         {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Update Firmware </a></div> */}

          {/* <p>Update Firmware</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
        
        <MDBRow className="mb-4">
        <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP}operationName = "UpdateFirmware" ></ChargePointSelection>
        <MDBCol md="12">
         <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>      	
      </MDBCol>
        
            <MDBCol sm="12">

               <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
          
         <MDBCol md="4" className="">
            <span className="cal_label">{t('retrieve_date')}</span>
            <div className="pull-left mt-2" style={{width:"55%"}} >
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    className="cal_margin tag_cal"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                  />
                </div>
                <span className="error_msg w3-animate-top cal_errormsg">  <span style={{color: "red"}} >{this.state.errors["emptyretrievedate"]}</span></span>
  
                   </MDBCol>
         <MDBCol md="4">
            <MDBInput
                value={this.state.retries}
                name="retries"  pattern="[0-9]*"
                onInput={this.numberHandler.bind(this,"retries")}
                type="text"
                id="retriesid"
                label={t('retries_integer')}
                required
              >
                <div className="valid-feedback">{t('looks_good')}</div>
               
              </MDBInput>
            	
         </MDBCol>
         <MDBCol md="4">
            <MDBInput
                value={this.state.retryInterval}
                name="retryInterval"
                onInput={this.numberHandler.bind(this,"retryInterval")}
                type="text" pattern="[0-9]*"
                id="retryIntervalId"
                label={t('retry_interval_integer')}
                required
              >
                <div className="valid-feedback">{t('looks_good')} </div>
              </MDBInput>
         </MDBCol>

         
                 

          </MDBRow>
          <button type = "button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
        </form>
      </div>
         </MDBCol>
        </MDBRow>
        </div>
    </main>
    </>
  );
}
}

export default withTranslation()(updateFirmware);