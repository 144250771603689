import React from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import CustomModal from '../common/CustomModal';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

 class AuthorizationTemplate extends React.Component {
    render() {
        const{t}=this.props
        const ref = React.createRef();

        const data = this.props.selectDataArray.map((dataArray, index) => {

            var date = "";
            var date1 = "";

            if (dataArray.expiryDate == null || dataArray.expiryDate == "" || dataArray.expiryDate == undefined) {
                date = "";
                date1 = "";
            } else {
                date = moment(dataArray.expiryDate).format("DD MMM YYYY");
                date1 = moment(dataArray.expiryDate).format("HH:mm");
            }

            return (
                <tr>
                    <td className="text-center">
                        <Form.Check
                            custom
                            type="checkbox"
                            id={`custom-${dataArray.id}`}
                            onChange={this.props.handleChange.bind(this, `custom-${dataArray.id}`, dataArray.id)}
                            label=""
                        />
                    </td>
                    <td>
                        <Link to={{ pathname: '/viewTag', state: dataArray.id }}>{dataArray.idTag}</Link>
                    </td>
                    <td className="wrap-word">
                        {date && <>
                            <div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {date}</div>
                            <div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {date1}</div>
                        </>}
                    </td>
                </tr>
            )
        });

        return (
            <main className="content-div" >
                <p> {t('Authorization')}
                    <div className="breadcrumb_div">
                        {t('charge_point')} &gt; <span className="breadcrumb_page">{t('Authorization')}</span>
                    </div>
                </p>
                <div className="page-outerdiv">
                    <div className="row menubar">
                        <div className="col-md-12 ">
                            {
                                this.props.ChargePoint_W &&
                                <button type="button" data-test="button" onClick={this.props.addAuthorization}>
                                    <i className="fa fa-plus"></i> <span>{t('add')}</span>
                                </button>
                            }
                            {
                                this.props.ChargePoint_W &&
                                <button type="button" data-test="button" onClick={this.props.toggle1}>
                                    <i className="fas fa-trash"></i> <span>{t('delete')}</span>
                                </button>
                            }

                            <div className="pull-right">
                                <label className="sub-text pull-left">{t('show_entries')}</label>
                                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.props.showEntriesChange} value={this.props.pageSize}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4  pull-right">
                            <div className="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <Typeahead
                                    labelKey="name"
                                    className="w_80"
                                    id="rbt_id"
                                    minLength={3}
                                    multiple={this.props.multiple}
                                    options={this.props.options}
                                    placeholder={t('choose_authorization_tag')}
                                    onKeyDown={this.props.handleKeyDown.bind(this, ref)}
                                    onChange={this.props.idTagChange}
                                    onBlur={this.props.getIdTags}
                                    ref={ref}
                                />
                                <button type="button" data-test="button" className="close close_icon" aria-label="Close" onClick={this.props.clearChargepoint.bind(this, ref)}>
                                    <span aria-hidden="true">x</span>
                                </button>
                                <div className="input-group-btn">
                                    <button className="input-searchBtn find-btn" type="submit" onClick={this.props.FindCpName.bind(this, ref)} >{t('find')}</button>
                                </div>
                                <span className="error_msg w3-animate-top mt-2">
                                    <span style={{ color: "red" }}>{this.props.errors["entercharge"]}</span>
                                </span>
                                <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>
                                    <span style={{ color: "red" }}>{this.props.errors["notexists"]}</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* table start */}
                    <div className="row ">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th className="text-center"></th>
                                        <th>{t('idTag')} <i className="fas fa-sort sortIcon" onClick={this.props.ascendingOrder}></i></th>
                                        <th>{t('expiry_time')} <i className="fas fa-sort sortIcon" onClick={this.props.ascendingOrder}></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data}
                                </tbody>
                            </Table>
                        </div>

                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                // hideDisabled
                                activePage={this.props.activePage}
                                itemsCountPerPage={this.props.pageSize}
                                totalItemsCount={this.props.noOfRecords}
                                onChange={this.props.handlePageChange}
                                prevPageText={t('prev')}
                                nextPageText={t('next')}
                                itemClassPrev="prevBtn"
                                pageRangeDisplayed="1"
                                activeClass="activeLi active"
                                itemClassNext="nextBtn"
                                hideFirstLastPages={true}
                                disableInitialCallback={true}
                                disabledClass="disabled disabledtextColor"
                            />
                        </div>
                    </div>
                </div>

                {/* popup for ocpp tag deletion alert by Lakshmikanth */}
                <CustomModal
                    open={this.props.modal1}
                    title={this.props.deleteHeader}
                    bodyMessage={this.props.delete_msg}
                    PrimaryButtonText={this.props.deleteText}
                    PrimaryButtonOperation={this.props.toggle1}
                    AdditionalButtonText={t('yes')}
                    AdditionalButtonOperation = {this.props.handleDelete}
                    AdditionalButtonStyle = {this.props.deleteButton}
                />

                {/* popup for ocpp tag deletion success alert by Lakshmikanth */}
                <CustomModal
                    open={this.props.modal2}
                    title={t('success')}
                    bodyMessage={t('id_tag_successfully_deleted')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.tagOk2}
                />

                {/* popup for ocpp tag deletion alert by Lakshmikanth */}
                <CustomModal
                    open={this.props.modal3}
                    title={t('warning')}
                    bodyMessage={this.props.error400}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.tagOk2}
                />

                {/* popup for session expire */}
                <CustomModal
                    open={this.props.modalsession}
                    title={t('session_has_expired')}
                    bodyMessage={t('please_login_again')}
                    PrimaryButtonText={t('ok')}
                    PrimaryButtonOperation={this.props.sessiontagOk}
                />
            </main>
        );
    }
}

export default withTranslation()(AuthorizationTemplate);