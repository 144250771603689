import React from 'react';
import {Link,withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime';
import { MDBBtn,MDBRow,MDBCol, MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter,MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink} from 'mdbreact';
import * as baseUrl from './constants';
import  $ from "jquery";
import '../App.css';
import { Alert } from 'reactstrap';
import {Form,Table,Pagination,Check } from 'react-bootstrap';
import moment from 'moment';
import Header from './header';
import servicesUrl from '../common/servicesUrl';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class editChargePoint extends React.Component{  
    constructor (props){
        super(props);
        this.state = {
          disabled:'true',
          activeItem: "1",
          page_title:"Edit",
          fields:
                {
                    id:"",
                  chargePointId:'', 
                  description:'',
                  address: {
                        addressId: '',
                        street: "",
                        houseNumber: "" ,
                        zipCode :"" ,
                        city :"" ,
                        country : "" ,
                        createdTime :"",
                        modifiedTime :"",
                        locationLatitude:"",
                        locationLongitude:"",
                      },
                      active:"",
                      installationSite:""
              },
          model1:"false" ,
          model2:"false",
          errors: {},
          activeItem: "1",
          transactions:[],
          startDate:new Date(),
          chargePointId:'',
          connectorStatus:[],
          sucess:false,
          colour:'sucess',
          metervalues:[],
          connectorId:'',
          transctionId:'',
          dataArray:[],
          disabledAttr:true,
          modalsession:false
        }
        this.addNew = this.addNew.bind(this)
        this.backToList = this.backToList.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        // this.editChargePoint = this.editChargePoint.bind(this)
        this.deleteChargePoint = this.deleteChargePoint.bind(this)
        this.redirectToListPage = this.redirectToListPage.bind(this)
        this.tagOk1 = this.tagOk1.bind(this)
        this.toggle2 = this.toggle2.bind(this)
        this.tagOk2 = this.tagOk2.bind(this)
        this.toggle1 = this.toggle1.bind(this)
        this.tabToggle = this.tabToggle.bind(this)
        this.increaseDate = this.increaseDate.bind(this);
        this.decreaseDate = this.decreaseDate.bind(this);
        this.getTransactionData=this.getTransactionData.bind(this);
        this.getConnectorStatus=this.getConnectorStatus.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.installationSiteChange=this.installationSiteChange.bind(this);
}

 //  function to change installationSite type
  installationSiteChange() {
    var id = document.getElementById("installationSite").value;
    let fields = this.state.fields;
    fields["installationSite"] = id;
    this.setState({ fields: fields, disabledAttr:"" });
    $('.error_installationSite').text("");
  }
async togglesession(){
  ;
  this.setState({
  modalsession: !this.state.modalsession
  });
}


sessiontagOk(){
  // this.props.history.push('/');
  history.push('/');
  window.location.reload();
}
onShowAlert(){
  
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
setStartDate(date){
  
    this.setState({ startDate: date })
    this.getTransactionData(date);
  }
redirectToListPage(){
  this.props.history.push('/chargepoint')
}

toggle1(event){
  event.preventDefault();
  ;
  this.setState({
    modal1: !this.state.modal1
  });
}

toggle2 = () => {
  this.setState({
    modal2: !this.state.modal2
  });
}

tagOk1(){
  this.props.history.push('/chargepoint');
}

tagOk2(){
  //alert("gvjk");
 //  this.props.history.push('/chargepoint');
 }

// displaying value in all the fields
componentDidMount(){

   
    // alert(JSON.stringify(this.props.location.state));
    
    if(this.props.location.state==null){
    
        var data= JSON.parse(localStorage.getItem("cpview"));
        this.state.id =data.id;
        this.state.chargePointId=data.chargePointId;
       }else{
         var fdfd= this.state.id;
         this.state.id = this.props.location.state.id;
         this.state.chargePointId=this.props.location.state.chargePointId;
         var data={
           id:this.state.id,
           chargePointId:this.state.chargePointId
         }
         localStorage.setItem("cpview",JSON.stringify(data));
       }
       
      //  alert(this.state.id);
      //  alert(this.state.chargePointId);
       
       //this.props.history.state=data;
       //this.props.location.state=data;
        var url = baseUrl.URLPath + servicesUrl.getChargePointDetails +"?id=" + this.state.id;
       //localhost:8080/cpms/chargepoints/{cpid}
       // alert(url);
       fetch(url,{
           method: "GET",
           headers: {
             "content-type":"application/json",
             "Authorization":localStorage.getItem("Authorization")
            }
           })
           .then((resp) => {
            if(resp.status==401){
              // alert("Access token is expired, please login again")
              this.togglesession();
        
              localStorage.clear();
             
            }else{

              return resp.json();
            }
           }) 
           .then((response) =>{
            
             if(response.data.address === null) {
               var fields1  = [];
               fields1.id = response.data.id;
               fields1.chargePointId = response.data.chargePointId
               fields1.description = response.data.description
               fields1.chargePointModel=response.data.chargePointModel
               fields1.chargePointVendor = response.data.chargePointVendor
               fields1.installationSite=response.data.installationSite;
               fields1.address = {
                 addressId: '',
                 street: "",
                 houseNumber: "" ,
                 zipCode :"" ,
                 city :"" ,
                 country : "" ,
                 createdTime :"",
                 modifiedTime :"",
                 locationLatitude:"",
                 locationLongitude:"",
               }
              this.setState({fields:fields1})
             }
            //  else{
            //   if(this.state.fields.address.country !=null && this.state.fields.address.country!="" ){
            //     document.getElementById('countryId').getElementsByTagName('option')[this.state.fields.address.country ].selected= 'selected'
            //     this.setState({fields:response.data})

            //   }
            //  }

             if(response.data.address!= null) {

              var fields1  = [];
              fields1.id = response.data.id;
              fields1.chargePointId = response.data.chargePointId
              fields1.description = response.data.description
              fields1.chargePointModel=response.data.chargePointModel
              fields1.chargePointVendor = response.data.chargePointVendor;
              fields1.installationSite=response.data.installationSite;
              var addr=response.data.address;
              fields1.address = {
                addressId: addr.id,
                 street: addr.street,
                 houseNumber: addr.houseNumber ,
                 zipCode :addr.zipCode ,
                 city :addr.city ,
                 country :addr.country,
                 createdTime :addr.createdTime,
                 modifiedTime :addr.modifiedTime,
                 locationLatitude:addr.locationLatitude,
                 locationLongitude:addr.locationLongitude,
              }

              if(addr.country != null && addr.country != ""){
                //  document.getElementById('countryId').getElementsByTagName('option')[addr.country].selected= 'selected';
                 try {
                  document.getElementById('countryId').getElementsByTagName('option')[addr.country].selected= 'selected';

              } catch (error) {
                  // alert("Error: " + error);
              }
              }
              this.setState({fields:fields1})
              
            }
            
          

           
           })
           .catch((error) => {
          //   alert(error);
             console.log(error, "catch the loop")
        })
      
        ;
 // document.getElementById('productId').getElementsByTagName('option')[this.state.fields.chargePointModel].selected= 'selected';

   
}

// tab view click
tabToggle(tab,event){
  
  var data={
    id:this.state.id,
    chargePointId:this.state.chargePointId
  }
  //this.props.history.state=data;
  this.props.location.state=data;
  if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab
    });
    if(tab==2){      
      var x= document.getElementById("metervalues")
      if (x.style.display === "block") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
      
    this.getTransactionData(this.state.startDate);
    }else if(tab==3){
this.getConnectorStatus();
    }


  }

};
/* To get the connector status of selected charge point
*/
getConnectorStatus(){
  var timeZone = localStorage.getItem("timezone");

  let url = baseUrl.URLPath + "chargepoint/search/"+this.state.chargePointId+"/connector/search";
  fetch(url, {
    method: 'GET', // or 'PUT'
    
     headers:{
     'Content-Type':'application/json',
     "Authorization":localStorage.getItem("Authorization")
     }
    }).then(resp =>{
      if(resp.status==401){
        // alert("Access token is expired, please login again")
        this.togglesession();
  
        localStorage.clear();
       
      }
      if(resp.status==200){
        return resp.json();
      }
    }).then(response =>{
      
      if(response!=undefined){
        this.setState({connectorStatus:response.data})
      }
    })
    .catch((error) => {
      console.log(error, "get connector status catch the loop")
  })
}
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) 
      month = '0' + month;
    if (day.length < 2) 
      day = '0' + day;
  return [year, month, day].join('-');
}
increaseDate(){
  var incrementdate= this.state.startDate;
 var currentDate = new Date();
 currentDate.setHours(0,0,0,0);
 incrementdate.setHours(0,0,0,0);
if(incrementdate.getTime() == currentDate.getTime()){
  $('#decreasedicon').addClass('icon_disable');

}
  else {
 incrementdate.setDate(incrementdate.getDate()+1);
 this.setState({startDate:incrementdate});
 this.getTransactionData(incrementdate);
 }
}

// decrease the date when click right arrow of datetime
 decreaseDate(){
  var decrementdate= this.state.startDate;
  // alert("b"+decrementdate);
  decrementdate.setDate(decrementdate.getDate()-1);
  this.setState({startDate:decrementdate});
  this.getTransactionData(decrementdate);
  $('#decreasedicon').removeClass('icon_disable');

  // alert("a"+decrementdate);
}
/* To get the transactions of selected charge point
*/
getTransactionData(date){
  var date= this.formatDate(date);
  
  var timeZone=  localStorage.getItem("timezone");

  let url = baseUrl.URLPath + servicesUrl.getChargePointTransactionSearch +"?chargePointId="+this.state.chargePointId+"&date"+date;
      fetch(url, {
        method: 'GET', // or 'PUT'
        
         headers:{
         'Content-Type':'application/json',
         "Authorization":localStorage.getItem("Authorization")
         }
        }).then(resp =>{
          if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }).then(response =>{
          
          if(response!=undefined){
            this.setState({transactions:response.data})
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
      })
}

addNew(){
  this.props.history.push('/chargePoint');
}

// back button click
backToList(){
  this.props.history.push('/viewChargePoint');
}

 //This is for making user should enter only numbers
 numberHandler(field, e){ 
    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }
 }
 
//  when click on submit button
  async handleSubmit(event){
    
    event.preventDefault();
    this.setState({
      modal1: !this.state.modal1
    });
      let errors = {};
      var zipCode= this.state.fields.address['zipCode'];
      var locationLatitude= ""+this.state.fields.address['locationLatitude'];
      var locationLongitude=""+this.state.fields.address['locationLongitude'];
      ;
      // if(zipCode!=null&&zipCode!==''){
      // if(!zipCode.match(/^[0-9]{5,8}$/)){
      //    errors["entervalidzipcode"] = "Please enter a valid Zip Code.";
      //    }
      //   }
         if(locationLatitude !== "null" && locationLatitude!==''){
          // alert("lat"+locationLatitude)
              if(!locationLatitude.match(/^[+-]?(([1-8]?[0-9])(\.[0-9]{1,6})?|90(\.0{1,6})?)$/)){
                errors["entervalidlat"] = "Please enter a valid latitude.";
               }
              }
        if(locationLongitude !== "null" && locationLongitude!==''){
        // alert("lon"+locationLongitude)
        if(!locationLongitude.match(/^[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,6})?)|180(\.0{1,6})?)$/)){
          errors["entervalidlong"] = "Please enter a valid longitude.";
         }
        }
        if(this.state.fields.installationSite!="private" && this.state.fields.installationSite!="company"){
          $('.error_installationSite').text("Installation site should be private or company only");
          errors["installationSite"] = "Installation site should be private or company only";
        }else{
          $('.error_installationSite').text("");
          delete errors.installationSite;
        }
         if(errors.hasOwnProperty("entervalidzipcode") || errors.hasOwnProperty("entervalidlat")|| errors.hasOwnProperty("entervalidlong") || errors.hasOwnProperty("installationSite"))
      {}
      else{
      var url = baseUrl.URLPath + "chargepoints";
      //localhost:8080/cpms/chargepoints/{cpid}
      let data = {"chargePointVendor":this.state.fields.chargePointVendor,"id":this.state.fields.id,"chargePointModel":this.state.fields.chargePointModel,"chargePointId": this.state.fields.chargePointId, "description":this.state.fields.description, "address":{"street":this.state.fields.address.street, "houseNumber":this.state.fields.address.houseNumber, "zipCode":this.state.fields.address.zipCode, "city":this.state.fields.address.city, "country":this.state.fields.address.country, "locationLatitude":this.state.fields.address.locationLatitude, "locationLongitude":this.state.fields.address.locationLongitude},"installationSite":this.state.fields.installationSite}; 
      fetch(url, {
        method: 'PUT', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
         headers:{
         'Content-Type':'application/json',
         "Authorization":localStorage.getItem("Authorization")
         }
        }).then(resp =>{
          if(resp.status==401){
            this.togglesession();
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
            else if(resp.status==404){
                errors["entervalidID"]="Please enter ID."
                this.setState({errors: errors});
                return resp.json();
                }else if(resp.status==500){
                    // alert("internal server error")
                } else if(resp.status==400){
                    // alert("Bad request")
                }else{
                  // alert("Charge Point is edited successfully.")
                  // $("#suc_msg").html(' <strong>Success!</strong> Charge point Edited successfully.').slideDown();
                  // setTimeout(this.redirectToListPage, 1000);
                //  this.toggle2() 
                
                if(resp.status=="FAILURE"){
                  // alert(resp.errorMessage);
                }else{
                  // this.setState({message:"Charge Point updated successfully.",colour:'success'})
                  // this.onShowAlert();
                  this.toggle2() 
                }

              }
        })
        .catch(error => alert('Error:' + error));
    
}
        this.setState({errors: errors});
      
}

changeHandler(field, e){ 
  let fields = this.state.fields;
  if(field == "chargePointId" || field =="description"){
    fields[field] = e.target.value; 
  }else{
    fields['address'][field] = e.target.value; 
  }
  this.setState({fields});
  this.setState({fields,disabledAttr:""});
}

  countryChange(default_cal){
    var x = document.getElementById("countryId").value;
    let fields = this.state.fields;
    fields['address']['country'] = default_cal.target.value; 
    this.setState({fields});
    this.setState({disabledAttr:""});
}
//  Enable all the inputs for editing onclick of edit button 
// editChargePoint(){
//   this.setState({disabled:false})
//   document.getElementById("editBtn").style.display = "none";
//   document.getElementById("saveBtn").style.visibility = "visible";
// }

// Delete tag 
deleteChargePoint(){
  this.setState({
    modal: !this.state.modal
  });
  let errors = {};
  let url = baseUrl.URLPath + "chargepoint/delete/" + this.state.id; 
  fetch(url, 
        {
          method: 'DELETE', // or 'PUT'
          body: JSON.stringify("NULL"), // data can be `string` or {object}!
          headers:{
          'Content-Type':'application/json',
          "Authorization":localStorage.getItem("Authorization")
          }
        }
      ).then(resp =>{
          if(resp.status==404){
            this.setState({errors: errors});
            return resp.json();
          }
          else if(resp.status==500){

            // alert("internal server error")

          } else if(resp.status==400){

            // alert("Bad request")

          }else if(resp.status==401){
            // alert("Access token is expired, please login again")
            this.togglesession();
      
            localStorage.clear();
           
          }
    })
     .catch(error => alert('Error:' + error));
     this.setState({errors: errors});
}
showTrasactions(){
  var x= document.getElementById("transactions")
 
    x.style.display = "block";
 
  
  var metervalues= document.getElementById("metervalues")
 
    metervalues.style.display = "none";
  
}
viewMeterValues(transaction){
  ;
  this.state.connectorId=transaction.connectorId;
  this.state.transactionId=transaction.transactionId;
  this.getMeterVaules(transaction.transactionId)
 var x= document.getElementById("transactions")
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
  
  var x= document.getElementById("metervalues")
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}
getMeterVaules(id){
  var timeZone=  localStorage.getItem("timezone");

  var url =  baseUrl.URLPath + "transaction/search/"+id+"/metervalue";
  
  fetch(url,{
      method: "GET",
        headers:{
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization")
        }
      })
      .then((resp) => {
        if(resp.status==401){
          // alert("Access token is expired, please login again")
          this.togglesession();
    
          localStorage.clear();
         
        }else{

          return resp.json();
        }
      }) 
      .then((response) => {
        
        this.setState({ metervalues: response.data})
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
}
componentDidUpdate() {
  
 // document.getElementById('productId').getElementsByTagName('option')[this.state.fields.chargePointModel].selected= 'selected';

}
// componentWillMount(){
//   var url = baseUrl.FOTAPath + "dashboard/1/products";
//   fetch(url,{
//     method: "GET",
//     headers: {
//       "content-type":"application/json",
//       "accesstoken":localStorage.getItem("accessToken"),
//       "oid":1,
//     }
//     })
//     .then((resp) => {
//       ;
//       if(resp.status==401){
//         // this.toggle();
//        // alert("Access token is expired, please login again")
//        localStorage.clear();
    
//      }
//      if(resp.status==200){
//        return resp.json();
//      }      }) 
//     .then((response) =>{
//       if(response!=undefined){
//           
//         // alert(JSON.stringify(response));
//        this.setState({ dataArray: response});
//     //   document.getElementById('productId').getElementsByTagName('option')[this.state.fields.chargePointModel].selected= 'selected';

//          } 
//     // alert("data"+JSON.stringify(this.state.data));
//     // this.setState({ total_charge_point:response.data.chargePointCount })

//     })
//     .catch((error) => {
//         console.log(error, "catch the loop")
//     })
// }
render() {
  
  const products =this.state.dataArray.map((dataArray, index) => {
    
  //  var dfd= document.getElementById('productId').getElementsByTagName('option')[this.state.fields.chargePointModel];

    return(
   <option value={dataArray.product_name}>{dataArray.product_name}</option>
  )

      })
 
  var page_title1 = "";
  if(this.props.location.state==null){
    var data1= JSON.parse(localStorage.getItem("cpview"));
    page_title1 = data1.chargePointId;
   }else{
    page_title1 = this.props.location.state.chargePointId;
   }
  const data =this.state.metervalues.map((dataArray, index) => {
    ;
    var valueTime = moment(dataArray.valueTime).format("DD-MMM-YYYY HH:mm").toUpperCase();

  
      return(
        <tr>
        <td>{dataArray.value}</td>
        <td>{valueTime}</td>
      
        <td>{dataArray.unit}</td> 
       
       </tr>
        )
       
    })
  const transactions =this.state.transactions.map((transaction, index) => {
    // var startTime = moment(transaction.startTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    // var stopTime = moment(transaction.stopTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    if(transaction.startTime==null){
      var startTime = "";
    }else{
      var startTime = moment(transaction.startTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    }
    if(transaction.stopTime==null){
      var stopTime = "";
    }else{
      var stopTime = moment(transaction.stopTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    }
    
      return(
        <tr>
  <td ><span className="underline btn_cursor"  onClick={this.viewMeterValues.bind(this,transaction)}>{transaction.transactionId}</span></td>
          <td>{transaction.connectorId}</td>
          <td>{transaction.idTag}</td>
          <td>{startTime}</td>
          <td>{stopTime}</td>
          <td>{transaction.stopReason}</td>
          </tr>
        )
    });
    const connectorStatus =this.state.connectorStatus.map((connectorStatus, index) => {
      
      // var createdTime = moment(connectorStatus.createdTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
       
      if(connectorStatus.createdTime==null){
        var createdTime = "";
      }else{
        var createdTime = moment(connectorStatus.createdTime).format("DD-MMM-YYYY HH:mm").toUpperCase();
    
      }
        return(
          <tr>
           
            <td>{connectorStatus.connectorId}</td>
            <td>{connectorStatus.status}</td>
            <td>{createdTime}</td>
            </tr>
          )
      });
  return (
    <>
<main class="content-div" >
<p>Overview
              <div className="breadcrumb_div">
              Charge Point &gt; <Link to="/chargePoint" > <span className="">Overview</span></Link>  &gt;  <Link to={{
                   pathname:'/viewChargePoint', 
                   state:{
                    chargePointId:this.state.fields.chargePointId,id:this.state.fields.id
                  }, 
                   }}> View </Link> <span className="breadcrumb_page"> &gt; Edit</span>
              </div>
          </p>
           {/* <p className="breadcrumb_div">Charge Point > <Link to="/chargePoint" > <span className="">Overview</span></Link> >  View</p> */}
      <form
          className='needs-validation'
          onSubmit={this.toggle1}
          noValidate
        >
      <div className="page-outerdiv">
      {/* <div className="breadcrumb_div">Charge Point > <Link to="/chargePoint" > <span className="">Overview</span></Link> >  <Link to={{
                   pathname:'/viewChargePoint', 
                   state:{
                    chargePointId:this.state.fields.chargePointId,id:this.state.fields.id
                  }, 
                   }}> View ></Link> Edit</div> */}

        {/* page title */}
        {/* <p> {this.state.chargePointId} </p> */}
          {/* <MDBBtn color="primary" size="sm" className="pull-right" onClick={this.editChargePoint}  id="editBtn">
          <i class="fas fa-pencil-alt mr-1"></i> Edit
        </MDBBtn> */}
       

        {/* end page title */}
      <MDBRow>
      <MDBCol md="12" className="">
       <MDBNav  className="nav-tabs border-0">
          <MDBNavItem>
            {/* <MDBNavLink to="#" className={this.state.activeItem == "1" ? "activetab" : ""} onClick={this.tabToggle.bind(this,"1")} role="tab" >
              Details
            </MDBNavLink> */}
          </MDBNavItem>
          {/* <MDBNavItem>
            <MDBNavLink to="#" className={this.state.activeItem == "2" ? "activetab" : ""} onClick={this.tabToggle.bind(this,"2")} role="tab" >
              Transactions
            </MDBNavLink>
          </MDBNavItem> */}
          {/* <MDBNavItem>
            <MDBNavLink to="#" className={this.state.activeItem == "3" ? "activetab" : ""} onClick={this.tabToggle.bind(this,"3")} role="tab" >
              Connectors
            </MDBNavLink>
          </MDBNavItem> */}
        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem} className="pt-2 border-0" >
          {/* details */}
        <MDBTabPane tabId="1" role="tabpanel">
        <div className="row">

          {/* sub title */}
          <div className="col-md-12 mb-3">
             <div className="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{display:"none"}} ></div>
             <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
             <span className="form-title">Details:</span> 
             
          </div>
          <MDBCol md="4">
                <MDBInput
                  name="organization"
                 
                  value={this.state.fields.chargePointVendor}
                  disabled="true"
                  type="text"
                  id="organization"
                  label="Organization *"
                  autoComplete="off"
                >

                 <div className='invalid-feedback ml-3 pl-3'>
                  Please provide a valid city.
                </div>
                <div className='valid-feedback ml-3 pl-3'>Looks good!</div>
                  
               </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  name="chargePoint"
                
                  value={this.state.fields.chargePointModel}
                  disabled="true"
                  type="text"
                  id="chargePointModel"
                  label="Model *"
                  autoComplete="off"
                >

                 <div className='invalid-feedback ml-3 pl-3'>
                  Please provide a valid city.
                </div>
                <div className='valid-feedback ml-3 pl-3'>Looks good!</div>
                  
               </MDBInput>
              </MDBCol>
           <MDBCol md="4">
                <MDBInput
                  name="chargePoint"
                  onChange={this.changeHandler.bind(this,"chargePointId")}
                  value={this.state.fields.chargePointId}
                  disabled={true}
                  type="text"
                  id="chargePointId"
                  label="Charge Point ID *"
                  autoComplete="off"
                >

                 <div className='invalid-feedback ml-3 pl-3'>
                  Please provide a valid city.
                </div>
                <div className='valid-feedback ml-3 pl-3'>Looks good!</div>
                  
               </MDBInput>
              </MDBCol>

              <MDBCol md="8">
              <MDBInput
            //    disabled={this.state.disabled}
                value={this.state.fields.description}
                onChange={this.changeHandler.bind(this, "description")}
                type="text"
                name="description"
                label="Description"
              >
                <div className="invalid-feedback">
                  Please provide a valid zip.
                </div>
                <div className="valid-feedback">Please provide!</div>
              </MDBInput>
            </MDBCol>
            <MDBCol md="4">
              <label className="pull-left mr-1 mt-2 pt-1 code_clr pl-2">
                <b style={{fontSize:"14px"}}>Installation site * <span className="custom_tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                <div className="custom_tooltiptext">
                  <div className="custom_tooltip_header">Installation site</div>
                  <div className="custom_tooltip_body">
                    <div>Installation Location of the chargingstation.</div>
                    <div><span className="font-bold">Private: </span>The chargingstation is installed in a private place</div>
                    <div><span className="font-bold">Company: </span>The chargingstation is installed at a company location</div>
                    <div><span className="font-bold">Other: </span>The chargingstation is not installed at a company location or at a private location</div>
                  </div>
                </div>
                </span></b> </label>
              <select className="browser-default custom-select select_height w_59 indent" id="installationSite" onChange={this.installationSiteChange} value={this.state.fields.installationSite}>
                <option value="other">Other</option>
                <option value="company">Company</option>
                <option value="private">Private</option>
              </select>
              <div className="mt-3"><span className="error_msg w3-animate-top error_installationSite" style={{color: "red"}}></span></div>
            </MDBCol>
        </div>

        <div className="row">

          {/* sub title */}
          <div className="col-md-12 mb-3">
             <span className="form-title" >Address:</span> 
          </div>
          {/* end sub title */}

            
             <MDBCol md="4" >
                <MDBInput
                  name="houseNumber"
                //   disabled={this.state.disabled}
                  onChange={this.changeHandler.bind(this, "houseNumber")}
                  value={this.state.fields.address.houseNumber}
                  min="0"
                  pattern="[0-9]*"
                  id="houseNumberId"
                  label="House Number"
                  type="text"
                
                >
                </MDBInput>
              </MDBCol>
               <MDBCol md="4">
                <MDBInput
                //   disabled={this.state.disabled}
                  name="street"
                  value={this.state.fields.address.street}
                  onChange={this.changeHandler.bind(this, "street")}
                  type="text"
                  id="streetId"
                  label="Street"
                  autoComplete="off"
                >
                </MDBInput>
              </MDBCol>

              <MDBCol md="4">
                <MDBInput
                //  disabled={this.state.disabled}
                     value={this.state.fields.address.city}
                     onChange={this.changeHandler.bind(this, "city")}
                     type="text"
                     id="cityId"
                     name="city"
                     label="City"
                >
                </MDBInput>
              </MDBCol>

              <MDBCol md="4">
                <MDBInput
                //   disabled={this.state.disabled}
                  value={this.state.fields.address.zipCode}
                  onChange={this.changeHandler.bind(this, "zipCode")}
                  onInput={this.numberHandler.bind(this,"zipCode")}
                  type="text"   pattern="[0-9]*"
                  id="zipcodeId"
                  name="zipcode"
                  label="Zip Code"
                >
                </MDBInput>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidzipcode"]}</span></span> 
              </MDBCol>
              
              <MDBCol md="4">
                    <select className="browser-default custom-select select_height"   id="countryId"  onChange={this.countryChange.bind(this)} >
                    <option id="Country" value="">Select Country</option>
                    <option id="Afghanistan" value="Afghanistan">Afghanistan</option>
                    <option id="Åland Islands" value="Åland Islands">Åland Islands</option>
                    <option id="Albania" value="Albania">Albania</option>
                    <option id="Algeria" value="Algeria">Algeria</option>
                    <option id="American Samoa" value="American Samoa">American Samoa</option>
                    <option id="Andorra" value="Andorra">Andorra</option>
                    <option id="Angola" value="Angola">Angola</option>
                    <option id="Anguilla" value="Anguilla">Anguilla</option>
                    <option id="Antarctica" value="Antarctica">Antarctica</option>
                    <option id="Antigua and Barbuda" value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option id="Argentina" value="Argentina">Argentina</option>
                    <option id="Armenia" value="Armenia">Armenia</option>
                    <option id="Aruba" value="Aruba">Aruba</option>
                    <option id="Australia" value="Australia">Australia</option>
                    <option id="Austria" value="Austria">Austria</option>
                    <option id="Azerbaijan" value="Azerbaijan">Azerbaijan</option>
                    <option id="Bahamas" value="Bahamas">Bahamas</option>
                    <option id="Bahrain" value="Bahrain">Bahrain</option>
                    <option id="Bangladesh" value="Bangladesh">Bangladesh</option>
                    <option id="Barbados" value="Barbados">Barbados</option>
                    <option id="Belarus" value="Belarus">Belarus</option>
                    <option id="Belgium" value="Belgium">Belgium</option>
                    <option id="Belize" value="Belize">Belize</option>
                    <option id="Benin" value="Benin">Benin</option>
                    <option id="ermuda" value="Bermuda">Bermuda</option>
                    <option id="Bhutan" value="Bhutan">Bhutan</option>
                    <option id="Bolivia" value="Bolivia">Bolivia</option>
                    <option id="Bosnia and Herzegovina" value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option id="Botswana" value="Botswana">Botswana</option>
                    <option id="Bouvet Island" value="Bouvet Island">Bouvet Island</option>
                    <option id="Brazil" value="Brazil">Brazil</option>
                    <option id="British Indian Ocean Territory" value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    <option id="Brunei Darussalam" value="Brunei Darussalam">Brunei Darussalam</option>
                    <option id="Bulgaria" value="Bulgaria">Bulgaria</option>
                    <option id="Burkina Faso" value="Burkina Faso">Burkina Faso</option>
                    <option id="Cambodia" value="Cambodia">Cambodia</option>
                    <option id="Cameroon"value="Cameroon">Cameroon</option>
                    <option id="Canada"value="Canada">Canada</option>
                    <option id="Cape Verde" value="Cape Verde">Cape Verde</option>
                    <option id="Cayman Islands" value="Cayman Islands">Cayman Islands</option>
                    <option id="Central African Republic" value="Central African Republic">Central African Republic</option>
                    <option id="Chad" value="Chad">Chad</option>
                    <option id="Chile" value="Chile">Chile</option>
                    <option id="China" value="China">China</option>
                    <option  id="Christmas Island"value="Christmas Island">Christmas Island</option>
                    <option id="Cocos (Keeling) Islands" value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option id="Colombia" value="Colombia">Colombia</option>
                    <option id="Comoros" value="Comoros">Comoros</option>
                    <option id="Congo" value="Congo">Congo</option>
                    <option id="Congo, The Democratic Republic of The" value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                    <option id="Cook Islands" value="Cook Islands">Cook Islands</option>
                    <option id="Costa Rica" value="Costa Rica">Costa Rica</option>
                    <option id="Cote D'ivoire" value="Cote D'ivoire">Cote D'ivoire</option>
                    <option id="Croatia" value="Croatia">Croatia</option>
                    <option id="Cuba" value="Cuba">Cuba</option>
                    <option  id="Cyprus"value="Cyprus">Cyprus</option>
                    <option id="Czech Republic" value="Czech Republic">Czech Republic</option>
                    <option id="Denmark" value="Denmark">Denmark</option>
                    <option id="Djibouti" value="Djibouti">Djibouti</option>
                    <option  id="Dominica"value="Dominica">Dominica</option>
                    <option id="Dominican Republic" value="Dominican Republic">Dominican Republic</option>
                    <option id="Ecuador" value="Ecuador">Ecuador</option>
                    <option id="Egypt" value="Egypt">Egypt</option>
                    <option id="El Salvador" value="El Salvador">El Salvador</option>
                    <option id="Equatorial Guinea" value="Equatorial Guinea">Equatorial Guinea</option>
                    <option  id="Eritrea"value="Eritrea">Eritrea</option>
                    <option id="Estonia" value="Estonia">Estonia</option>
                    <option id="Ethiopia" value="Ethiopia">Ethiopia</option>
                    <option  id="Falkland Islands (Malvinas)"value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                    <option id="Faroe Islands" value="Faroe Islands">Faroe Islands</option>
                    <option  id="Fiji"value="Fiji">Fiji</option>
                    <option  id="Finland"value="Finland">Finland</option>
                    <option  id="France"value="France">France</option>
                    <option id="French Guiana" value="French Guiana">French Guiana</option>
                    <option id="French Polynesia" value="French Polynesia">French Polynesia</option>
                    <option  id="French Southern Territories"value="French Southern Territories">French Southern Territories</option>
                    <option id="Gabon" value="Gabon">Gabon</option>
                    <option id="Gambia" value="Gambia">Gambia</option>
                    <option id="Georgia" value="Georgia">Georgia</option>
                    <option id="Germany" value="Germany">Germany</option>
                    <option id="Ghana" value="Ghana">Ghana</option>
                    <option id="Gibraltar" value="Gibraltar">Gibraltar</option>
                    <option id="Greece" value="Greece">Greece</option>
                    <option id="Greenland" value="Greenland">Greenland</option>
                    <option id="Grenada" value="Grenada">Grenada</option>
                    <option id="Guadeloupe" value="Guadeloupe">Guadeloupe</option>
                    <option id="Guam" value="Guam">Guam</option>
                    <option id="Guatemala" value="Guatemala">Guatemala</option>
                    <option id="Guernsey" value="Guernsey">Guernsey</option>
                    <option id="Guinea" value="Guinea">Guinea</option>
                    <option  id="Guinea-bissau"value="Guinea-bissau">Guinea-bissau</option>
                    <option  id="Guyana"value="Guyana">Guyana</option>
                    <option  id="Haiti" value="Haiti">Haiti</option>
                    <option id="Heard Island and Mcdonald Islands" value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option id="Holy See (Vatican City State)" value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option id="Honduras" value="Honduras">Honduras</option>
                    <option id="Hong Kong" value="Hong Kong">Hong Kong</option>
                    <option id="Hungary" value="Hungary">Hungary</option>
                    <option id="Iceland" value="Iceland">Iceland</option>
                    <option id="India" value="India">India</option>
                    <option id="Indonesia" value="Indonesia">Indonesia</option>
                    <option id="Iran, Islamic Republic of" value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                    <option id="Iraq" value="Iraq">Iraq</option>
                    <option id="Ireland" value="Ireland">Ireland</option>
                    <option id="Isle of Man" value="Isle of Man">Isle of Man</option>
                    <option id="Israel" value="Israel">Israel</option>
                    <option id="Italy" value="Italy">Italy</option>
                    <option id="Jamaica" value="Jamaica">Jamaica</option>
                    <option id="Japan" value="Japan">Japan</option>
                    <option id="Jersey" value="Jersey">Jersey</option>
                    <option id="Jordan" value="Jordan">Jordan</option>
                    <option id="Kazakhstan" value="Kazakhstan">Kazakhstan</option>
                    <option id="Kenya" value="Kenya">Kenya</option>
                    <option  id="Kiribati"value="Kiribati">Kiribati</option>
                    <option id="Korea, Democratic People's Republic of" value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                    <option id="Korea, Republic of" value="Korea, Republic of">Korea, Republic of</option>
                    <option id="Kuwait" value="Kuwait">Kuwait</option>
                    <option id="Kyrgyzstan" value="Kyrgyzstan">Kyrgyzstan</option>
                    <option id="Lao People's Democratic Republic" value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                    <option id="Latvia" value="Latvia">Latvia</option>
                    <option id="Lebanon" value="Lebanon">Lebanon</option>
                    <option id="Lesotho" value="Lesotho">Lesotho</option>
                    <option id="Liberia" value="Liberia">Liberia</option>
                    <option id="Libyan Arab Jamahiriya" value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option id="Liechtenstein" value="Liechtenstein">Liechtenstein</option>
                    <option id="Lithuania" value="Lithuania">Lithuania</option>
                    <option id="Luxembourg" value="Luxembourg">Luxembourg</option>
                    <option id="Macao" value="Macao">Macao</option>
                    <option id="Macedonia, The Former Yugoslav Republic of" value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                    <option  id="Madagascar"value="Madagascar">Madagascar</option>
                    <option id="Malawi" value="Malawi">Malawi</option>
                    <option id="Malaysia" value="Malaysia">Malaysia</option>
                    <option id="Maldives" value="Maldives">Maldives</option>
                    <option id="Mali" value="Mali">Mali</option>
                    <option id="Malta" value="Malta">Malta</option>
                    <option id="Marshall Islands" value="Marshall Islands">Marshall Islands</option>
                    <option id="Martinique" value="Martinique">Martinique</option>
                    <option id="Mauritania" value="Mauritania">Mauritania</option>
                    <option id="Mauritius" value="Mauritius">Mauritius</option>
                    <option id="Mayotte" value="Mayotte">Mayotte</option>
                    <option id="Mexico" value="Mexico">Mexico</option>
                    <option id="Micronesia, Federated States of" value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                    <option id="Moldova, Republic of" value="Moldova, Republic of">Moldova, Republic of</option>
                    <option id="Monaco" value="Monaco">Monaco</option>
                    <option id="Mongolia" value="Mongolia">Mongolia</option>
                    <option id="Montenegro" value="Montenegro">Montenegro</option>
                    <option id="Montserrat" value="Montserrat">Montserrat</option>
                    <option id="Morocco" value="Morocco">Morocco</option>
                    <option id="Mozambique" value="Mozambique">Mozambique</option>
                    <option id="Myanmar" value="Myanmar">Myanmar</option>
                    <option id="Namibia" value="Namibia">Namibia</option>
                    <option  id="Nauru" value="Nauru">Nauru</option>
                    <option id="Nepal" value="Nepal">Nepal</option>
                    <option  id="Netherlands" value="Netherlands">Netherlands</option>
                    <option id="Netherlands Antilles" value="Netherlands Antilles">Netherlands Antilles</option>
                    <option id="New Caledonia" value="New Caledonia">New Caledonia</option>
                    <option id="New Zealand"value="New Zealand">New Zealand</option>
                    <option id="Nicaragua" value="Nicaragua">Nicaragua</option>
                    <option id="Niger" value="Niger">Niger</option>
                    <option id="Nigeria" value="Nigeria">Nigeria</option>
                    <option id="Niue" value="Niue">Niue</option>
                    <option id="Norfolk Island" value="Norfolk Island">Norfolk Island</option>
                    <option id="Northern Mariana Islands" value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option id="Norway" value="Norway">Norway</option>
                    <option id="Oman"  value="Oman">Oman</option>
                    <option id="Pakistan" value="Pakistan">Pakistan</option>
                    <option id="Palau" value="Palau">Palau</option>
                    <option id="Palestinian Territory, Occupied" value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                    <option  id="Panama"value="Panama">Panama</option>
                    <option  id="Papua New Guinea"value="Papua New Guinea">Papua New Guinea</option>
                    <option id="Paraguay" value="Paraguay">Paraguay</option>
                    <option  id="Peru"value="Peru">Peru</option>
                    <option id="Philippines" value="Philippines">Philippines</option>
                    <option id="Pitcairn" value="Pitcairn">Pitcairn</option>
                    <option id="Poland" value="Poland">Poland</option>
                    <option id="Portugal" value="Portugal">Portugal</option>
                    <option  id="Puerto Rico"value="Puerto Rico">Puerto Rico</option>
                    <option id="Qatar" value="Qatar">Qatar</option>
                    <option id="Reunion" value="Reunion">Reunion</option>
                    <option id="Romania" value="Romania">Romania</option>
                    <option id="Russian Federation" value="Russian Federation">Russian Federation</option>
                    <option id="Rwanda" value="Rwanda">Rwanda</option>
                    <option id="Saint Helena" value="Saint Helena">Saint Helena</option>
                    <option id="Saint Kitts and Nevis" value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option id="Saint Lucia" value="Saint Lucia">Saint Lucia</option>
                    <option id="Saint Pierre and Miquelon" value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option id="Saint Vincent and The Grenadines" value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option id="Samoa" value="Samoa">Samoa</option>
                    <option id="San Marino" value="San Marino">San Marino</option>
                    <option id="Sao Tome and Principe" value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option id="Saudi Arabia" value="Saudi Arabia">Saudi Arabia</option>
                    <option id="Senegal" value="Senegal">Senegal</option>
                    <option id="Serbia" value="c">Serbia</option>
                    <option id="Seychelles" value="Seychelles">Seychelles</option>
                    <option id="Sierra Leone" value="Sierra Leone">Sierra Leone</option>
                    <option id="Singapore" value="Singapore">Singapore</option>
                    <option id="Slovakia" value="Slovakia">Slovakia</option>
                    <option id="Slovenia" value="Slovenia">Slovenia</option>
                    <option id="Solomon Islands" value="Solomon Islands">Solomon Islands</option>
                    <option id="Somalia" value="Somalia">Somalia</option>
                    <option id="South Africa" value="South Africa">South Africa</option>
                    <option id="South Georgia and The South Sandwich Islands" value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                    <option id="Spain" value="Spain">Spain</option>
                    <option id="Spain"  value="Spain">Spain</option>
                    <option id="Spain"value="Spain">Spain</option>
                    <option id="Spain" value="Spain">Spain</option>
                    <option id="Spain" value="Spain">Spain</option>
                    <option  id="Sri Lanka"value="Sri Lanka">Sri Lanka</option>
                    <option id="Sudan" value="Sudan">Sudan</option>
                    <option id="Suriname" value="Suriname">Suriname</option>
                    <option  id="Svalbard and Jan Mayen"value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option id="Swaziland" value="Swaziland">Swaziland</option>
                    <option id="Sweden" value="Sweden">Sweden</option>
                    <option id="Switzerland" value="Switzerland">Switzerland</option>
                    <option id="Syrian Arab Republicr" value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option id="Taiwan, Province of China" value="Taiwan, Province of China">Taiwan, Province of China</option>
                    <option id="Tajikistan" value="Tajikistan">Tajikistan</option>
                    <option id="Tanzania, United Republic of" value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                    <option id="Thailand" value="Thailand">Thailand</option>
                    <option id="Timor-leste" value="Timor-leste">Timor-leste</option>
                    <option id="Togo" value="Togo">Togo</option>
                    <option id="Tokelau" value="Tokelau">Tokelau</option>
                    <option id="Tonga" value="Tonga">Tonga</option>
                    <option id="Trinidad and Tobago" value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option id="Tunisia" value="Tunisia">Tunisia</option>
                    <option id="Turkey" value="Turkey">Turkey</option>
                    <option  id="Turkmenistan"value="Turkmenistan">Turkmenistan</option>
                    <option id="Turks and Caicos Islands" value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option id="Tuvalu" value="Tuvalu">Tuvalu</option>
                    <option id="Uganda" value="Uganda">Uganda</option>
                    <option id="Ukraine" value="Ukraine">Ukraine</option>
                    <option id="United Arab Emirates"value="United Arab Emirates">United Arab Emirates</option>
                    <option id="United Kingdom" value="United Kingdom">United Kingdom</option>
                    <option id="United States" value="United States">United States</option>
                    <option id="United States Minor Outlying Islands" value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option id="Uruguay" value="Uruguay">Uruguay</option>
                    <option id="Uzbekistan" value="Uzbekistan">Uzbekistan</option>
                    <option id="Vanuatu" value="Vanuatu">Vanuatu</option>
                    <option id="Venezuela" value="Venezuela">Venezuela</option>
                    <option  id="Viet Nam"value="Viet Nam">Viet Nam</option>
                    <option id="Virgin Islands, British" value="Virgin Islands, British">Virgin Islands, British</option>
                    <option id="Virgin Islands, U.S." value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                    <option id="Wallis and Futuna"value="Wallis and Futuna">Wallis and Futuna</option>
                    <option id="Western Sahara"value="Western Sahara">Western Sahara</option>
                    <option  id="Yemen"value="Yemen">Yemen</option>
                    <option id="Zambia" value="Zambia">Zambia</option>
                    <option id="Zimbabwe" value="Zimbabwe">Zimbabwe</option>

                  </select>
              </MDBCol>

              {/* <MDBCol md="4">
                  <MDBInput 
                //   disabled={this.state.disabled}
                  value={this.state.fields.address.locationLatitude}
                  onChange={this.changeHandler.bind(this, "locationLatitude")}
                  type="text"
                  name="adminAddress"
                  label="Latitude"
                  >
                    <div className="invalid-feedback">
                      Latitude:	
                    </div>
                    <div className="valid-feedback" > Looks good! </div>
                    <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidlat"]}</span></span> 
                </MDBInput>
            </MDBCol> */}
            {/* <MDBCol md="4" >
              <MDBInput
              value={this.state.fields.address.locationLongitude}
            //   disabled={this.state.disabled}
               onChange={this.changeHandler.bind(this, "locationLongitude")}
                type="text"
                id="longitudeId"
                name="Longitude"
                label="Longitude"
                className="mt-1"
              >
                <div className="invalid-feedback">
                  :	
                </div>
                <div className="valid-feedback">Looks good!</div>
              </MDBInput>
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidlong"]}</span></span> 
            </MDBCol>   */}
            <MDBCol md="12" className="text-right">
          
            {/* <MDBBtn color='primary' type='submit' onClick={this.toggle1} className="mt-5 mr-3 v-hiiden" id="saveBtn" >
              Save
            </MDBBtn> */}
            <button type='submit' className="mt-5 mr-3 refresh-btn" disabled={this.state.disabledAttr} onClick={this.toggle1}  id="editBtn" >
            <i class="fas fa-save mr-2"></i> Save
        </button>
            {/* <MDBBtn color='red ' onClick={this.toggle} className="mt-5 mr-3">
             <span style={{color:"#fff"}}>Delete</span> 
            </MDBBtn> */}
             <Link to={{
                   pathname:'/viewChargePoint', 
                   state:{
                    chargePointId:this.state.fields.chargePointId,id:this.state.fields.id
                  }, 
                   }}>
            <button className="mt-5 refresh-btn text-secondary" onClick={this.backToList} style={{borderColor:"grey"}}>
            <i class="fas fa-times mr-2"></i> Cancel  
            </button>
            </Link>
            </MDBCol>  
      </div>
        </MDBTabPane>
        {/* end details */}

        {/* transactions */}
        <MDBTabPane tabId="2" role="tabpanel">

          <div id="transactions">
          <div className="row  mt-4">
                    <div className="col-md-12 ">
                        <div className="">
                          <div className="row">
                            <div className="col-md-12 text-center"> <lable className="dated"></lable>  <span id="decreased" onClick={this.decreaseDate}><a><i class="fas fa-angle-left" aria-hidden="true"></i></a></span>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={date => this.setStartDate(date)}
                                timeIntervals={1}
                                className="cal_margin b-none cal_w"
                                timeCaption="time"
                                dateFormat="dd-MMM-yyyy"
                                maxDate={new Date()}

                            />
                        <span id="increased" onClick={this.increaseDate}><a><i class="fa fa-angle-right" aria-hidden="true" id="decreasedicon"></i></a></span>
                      </div>
                      </div>
                        </div>
                    </div>
                </div>        <div class="row mt-2 "><div class="col-md-12">
          <table class="table page-table table-sm table-striped table-bordered table-hover">
            <thead>
              <tr>
              <th>Transaction ID</th>
              <th>Connector ID</th>
              <th>ID Tag</th>
              <th>Start Time(GMT 24hrs)</th>
              <th>Stop Time(GMT 24hrs)</th>
              <th>Stop Reason</th>
              </tr>
           </thead>
        <tbody>
          {/* <tr>
          <td><a href="/meterValues">44</a></td>
          <td>1</td>
          <td>Q009</td>
          <td>09-25-2019 11:16</td>
          <td>09-25-2019 11:18</td>
          <td></td>
          </tr> */}
          {transactions}
        </tbody></table></div><div class="col-md-12 mt-4"></div></div>
          </div>
          <div id="metervalues">
          {/* page title */}
          <span class="form-title pb-2"> <label className="pt-2">Meter Values:</label>
          <div  className="pull-right ">
          <button class="btn btn-primary m-0" type="button" onClick={this.showTrasactions} >
            {/* <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
              Back
          </button>
          </div>
          </span>
        
          {/* end page title */}
          {/* page menu bar */}
          <div className="row trans_lable">
            
             {/* <div className="col-md-4">
            <b>Charge Point ID:</b> {this.state.chargePointId}
            </div> */}
           <div className="col-md-4 pt-3">
            <b>Connector ID:</b>  {this.state.connectorId}
            </div>
            <div className="col-md-4 pt-3">
               <b>Transaction ID:</b> {this.state.transactionId}
            </div>
          </div>
          
        
           <div className="row menubar mt-2">
             <div className="col-md-12 ">
             
             
  
               </div>
               </div>
          
          {/* end page menu bar  */}
          
          {/* table start */}
         <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped bordered hover size="sm" className="page-table">

            <thead>
              <tr>
                <th>Value</th>
                <th>Value Time(GMT 24hrs)</th>
                <th>Unit</th>
               
             </tr>
            </thead>
            <tbody>
            {data}
            </tbody>
          </Table>
          </div>
          {/* <div className="col-md-12 mt-4">
            <Pagination className="pull-right">
              <Pagination.First />
              <Pagination.Item>{1}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{10}</Pagination.Item>
              <Pagination.Item>{11}</Pagination.Item>
              <Pagination.Item active>{12}</Pagination.Item>
              <Pagination.Item>{13}</Pagination.Item>
              <Pagination.Item disabled>{14}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{20}</Pagination.Item>
              <Pagination.Last />
            </Pagination>
          </div> */}
          
          {/* End table  */}
          </div>
         
          </div>
                 </MDBTabPane>
           {/* end transactions */}
           {/*  connectors */}
          <MDBTabPane tabId="3" role="tabpanel">
          <div class="row  mt-4"><div class="col-md-12 "><div id="refreshbtn">  <button class="btn btn-primary" type="button" onClick={this.getConnectorStatus}>Refresh</button></div></div></div>
          <div class="row mt-2 "><div class="col-md-12"><table class="table page-table table-sm table-striped table-bordered table-hover"><thead><tr><th>Connector ID</th><th>Status</th><th>Timestamp(GMT 24hrs)</th></tr></thead>
          <tbody>
            {connectorStatus}
            </tbody></table></div></div>
          </MDBTabPane>
             {/*  end connectors */}
         
        </MDBTabContent>
      </MDBCol>
      {/* end section of address */}
      </MDBRow>
    </div>
    </form>
    <MDBModal isOpen={this.state.modal1} toggle={this.toggle1} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle1}>Confirmation</MDBModalHeader>
<MDBModalBody>
 Are you sure you want to save the changes?
</MDBModalBody>
<MDBModalFooter>
<MDBBtn color="danger" onClick={this.toggle1}>Close</MDBBtn>
<MDBBtn color="primary" onClick={this.handleSubmit}>YES</MDBBtn>
</MDBModalFooter>
</MDBModal>


<MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
<MDBModalHeader toggle={this.toggle2}>Success</MDBModalHeader>
<MDBModalBody>
 Charge Point updated successfully.
</MDBModalBody>
<MDBModalFooter>
{/* <MDBBtn color="danger" onClick={this.toggle2}>Close</MDBBtn> */}
<MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn>
</MDBModalFooter>
</MDBModal>


{/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>
    </main>
    </>
  );
}
}

export default  withRouter(editChargePoint)