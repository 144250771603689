import React from 'react';
import AuthorizationTemplate from '../Screens/AuthorizationTemplate';
import * as baseUrl from '../views/constants';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import servicesUrl from '../common/servicesUrl';

class Authorization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataArray: [],
            selectDataArray: [],
            fromDateApi: '',
            toDateApi: '',
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            prevPageFirstCreTime: "",
            listOfFirstCreatedtimes: [],
            activePage: 1,
            pageSize: 10,
            noOfRecords: '',
            saveSelectedIdTag: '',
            selectFindStatus: false,
            idTags: '',
            options: [],
            multiple: false,
            idTagSorting: false,
            list: [],
            delete_msg: '',
            deleteHeader: '',
            deleteButton: '',
            deleteText: '',
            deletecolor: '',
            errors: {},
            user: {
                permissions: []
            },
            modal1: false,
            modal2: false,
            modal3: false,
            modalsession: false,
            activeArray: "",
            error400: ''
        }
        this.addAuthorization = this.addAuthorization.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.getOCPPTags = this.getOCPPTags.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.idTagChange = this.idTagChange.bind(this);
        this.getIdTags = this.getIdTags.bind(this);
        this.clearChargepoint = this.clearChargepoint.bind(this);
        this.ascendingOrder = this.ascendingOrder.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tagOk2 = this.tagOk2.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
    }

    addAuthorization() {
        this.props.history.push('/ocppNewTag');
    }

    toggle1() {
        this.setState({
            modal1: !this.state.modal1
        });
        if (this.state.list[0] != "" && this.state.list[0] != undefined) {
            this.setState({
                delete_msg: this.props.t('are_you_want_to_delete'),
                deleteHeader: this.props.t('confirmation'),
                deleteButton: "",
                deleteText: this.props.t('close'),
                deletecolor: "danger"
            })
        } else {
            this.setState({
                delete_msg: this.props.t('select_ocpp_id_tag'),
                deleteHeader: this.props.t('warning'),
                deleteButton: "d-none",
                deleteText: this.props.t('ok'),
                deletecolor: "primary"
            })
        }
    }

    toggle2(){
        this.setState({
            modal2: !this.state.modal2
        });
    }

    toggle3(message){
        this.setState({
            modal3: !this.state.modal3,
            error400: message
        });
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.setState({
            activePage: 1,
            pageSize: entries,
            fromDateApi: '',
            toDateApi: ''
        },()=>{
            this.getOCPPTags(this.state.pageSize, this.state.activePage);
        });
    }

    getOCPPTags(pageSize, pageNo, action, countValue) {

        document.getElementById("loader_image_div").style.display = "block";
        
        var url = baseUrl.URLPath +servicesUrl.getOcppTagsSearch+ "?pageSize=" + pageSize + "&pageNo=" + pageNo + "&idTag=" + this.state.saveSelectedIdTag + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi;
        
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
        .then((resp) => {
            if (resp.status == 401) {
                setTimeout(() => {
                    document.getElementById("loader_image_div").style.display = "none";
                    this.togglesession();
                    localStorage.clear();
                }, 100);
            }
            if (resp.status == 200) {
                return resp.json();
            }
        })
        .then((response) => {
            if (response != undefined) {
                if (response.count.count == 0) {
                    if (action == "click" || action == "enter") {
                        setTimeout(() => {
                            document.getElementById("loader_image_div").style.display = "none";
                            $("#notexists").text(this.props.t('ocpp_tag_not_exists'));
                        }, 100);
                    }else{
                        setTimeout(() => {
                            this.setState({
                                // dataArray: response.ocppTagList,
                                // selectDataArray: response.ocppTagList,
                            },()=>{
                                document.getElementById("loader_image_div").style.display = "none";
                            });
                        }, 100);
                    }
                } else {
                    this.state.saveSelectedIdTag = "";
                    if(countValue == false){
                        setTimeout(() => {
                            this.setState({
                                dataArray: response.ocppTagList,
                                selectDataArray: response.ocppTagList,
                                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                                curPageFirstCreTime: moment(response.ocppTagList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                                curPageLastCreTime: moment(response.ocppTagList[response.ocppTagList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            },()=>{
                                document.getElementById("loader_image_div").style.display = "none";
                            });
                        }, 100);
                    }else{
                        setTimeout(() => {
                            this.setState({
                                dataArray: response.ocppTagList,
                                selectDataArray: response.ocppTagList,
                                noOfRecords: response.count.count,
                                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                                curPageFirstCreTime: moment(response.ocppTagList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                                curPageLastCreTime: moment(response.ocppTagList[response.ocppTagList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                            },()=>{
                                document.getElementById("loader_image_div").style.display = "none";
                            });
                        }, 100);
                    }

                    if (action == "click" || action == "enter") {
                        setTimeout(() => {
                            $("#notexists").text("");
                            document.getElementById("loader_image_div").style.display = "none";
                        }, 100);
                    }

                    var option = [];
                    for (var i = 0; i < this.state.dataArray.length; i++) {
                        option.push(this.state.dataArray[i].idTag);
                    }

                    this.setState({ options: option });
                }
            }
        })
        .catch((error) => {
            setTimeout(() => {
                console.log(error, "catch the loop")
                document.getElementById("loader_image_div").style.display = "none";
            }, 100);
        })
    }

    componentDidMount() {
        this.state.user.permissions = localStorage.getItem("roleAccess");
        this.getOCPPTags(this.state.pageSize, this.state.activePage);
        $("#nochargeexists").hide();
    }

    // search filter 
    handleKeyDown(ref, e) {
        var serial_no = e.target.value;
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        if (serial_no != 0) {
            this.state.errors["entercharge"] = "";
        }

        if (key == "Backspace" || key == "Delete") {
            this.state.errors["entercharge"] = "";
            $("#notexists").text("");
            var x = e.target.value
            var sno = x.slice(0, x.length - 1)
            if (sno == "") {
                this.state.saveSelectedIdTag = sno;
                this.getOCPPTags(this.state.pageSize, this.state.activePage)
            }
        }
        var sno = serial_no.slice(0, serial_no.length - 1)
        this.getSearchInventoryList(sno);
        if (e.keyCode == 13) {
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        }
        if ((e.keyCode == 86 && ctrl)) {
            this.getSearchInventoryList(serial_no);
        }
        if (serial_no.length >= 0) {
            if (serial_no.length > 1) {
                this.getSearchInventoryList(serial_no)
            }
        }
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9., -]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    }

    getSearchInventoryList(Idtag) {

        var url = baseUrl.URLPath + servicesUrl.getOcppTagsPartialSearch +"?idTag=" + Idtag;

        fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            "Authorization": localStorage.getItem("Authorization")
          }
        })
        .then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }
            if (resp.status == 200) {
                return resp.json();
            }
        })
        .then((response) => {
            if (response != undefined) {
                this.setState({
                    options: response,
                    intial_data: response
                });
            }
        })
        .catch((error) => {
            console.log(error, "catch the loop");
        });
    }

    FindCpName(ref, event) {
        
        event.preventDefault();
        this.state.saveSelectedIdTag = ref.current.inputNode.value;
        var errors = {};
        if (this.state.saveSelectedIdTag == null || this.state.saveSelectedIdTag == "") {
                errors['entercharge'] = this.props.t('select_ocpp_tags');
                $("#notexists").text("");
            this.setState({
                errors: errors
            });
        } else {
            errors['entercharge'] = "";
            this.setState({
                errors: errors
            });
            this.getOCPPTags(this.state.pageSize, this.state.activePage, "click", "true");
        }
    
     
    }
    idTagChange(idTag) {
        
        this.setState({ saveSelectedIdTag: idTag, selectFindStatus: true }, () => {
            if (idTag.length == 0) {
                this.getOCPPTags(this.state.pageSize, this.state.activePage)
            } else {
                let errors = {};
                errors["idTagNotExists"] = "";
                this.setState({
                    errors: errors
                }, () => {
                    this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag);
                });
            }
        });
    }

    getIdTags(event) {
        var errors = {};
        errors["idTagNotExists"] = "";
        this.setState({
            idTags: event.target.value,
            errors: errors
        },()=>{
            if(this.state.idTags == 0){
                this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag)
            }
        });
    }

    clearChargepoint(ref, event) {
        var errors = {}
        errors["entercharge"] = "";

        this.setState({
            selectFindStatus: false,
            saveSelectedIdTag: '',
            // pageSize: 10,
            // activePage: 1,
            idTags: '',
            errors: errors
        }, ()=>{
            this.getOCPPTags(this.state.pageSize, this.state.activePage, this.state.saveSelectedIdTag)
            $("#notexists").text("");
        });
        ref.current.clear();
    }

    //function for sorting table data on click
    ascendingOrder() {
        if (this.state.idTagSorting == false) {
            this.setState({
                selectDataArray: this.state.selectDataArray.sort(this.dynamicSort("idTag")),
                idTagSorting: true
            });
        }
        else {
            this.state.selectDataArray.sort(this.dynamicSort("idTag"));
            this.setState({
                selectDataArray: this.state.selectDataArray.reverse(),
                idTagSorting: false
            });
        }
    }

    dynamicSort(property) {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder == -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        }
    }

    handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
            //prev
            if (pageNumber == 1) {
                this.setState({
                    activePage: pageNumber,
                    pageNo: pageNumber,
                    fromDateApi: '',
                    toDateApi: ''
                }, () => {
                    this.getOCPPTags(this.state.pageSize, pageNumber, "", false);
                });
            } else {
                this.setState({
                    activePage: pageNumber,
                    pageNo: pageNumber,
                    fromDateApi: this.state.curPageFirstCreTime,
                    toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1],
                }, () => {
                    this.getOCPPTags(this.state.pageSize, pageNumber, "", false);
                });
            }
        } else {
            //next
            this.setState({
                activePage: pageNumber,
                pageNo: pageNumber,
                fromDateApi: '',
                toDateApi: this.state.curPageLastCreTime,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
                saveSelectedIdTag:""
            }, () => {
                let listOfFirstCreatedtimes = this.state.listOfFirstCreatedtimes;
                listOfFirstCreatedtimes[pageNumber - 2] = this.state.curPageFirstCreTime;
                this.setState({
                    listOfFirstCreatedtimes: listOfFirstCreatedtimes
                }, () => {
                    this.getOCPPTags(this.state.pageSize, pageNumber, "", false);
                });
            });
        }
    }

    handleChange(id, cpid, event) {
        var checkedValue = document.getElementById(id).checked;

        if (checkedValue == true) {
            if (this.state.list[0] != "" && this.state.list[0] != undefined) {
                document.getElementById("custom-" + this.state.list[0]).checked = false;
                document.getElementById(id).checked = true;
            }

            let list = this.state.list;
            list[0] = cpid;

            this.setState({
                activeArray: cpid
            });
        }

        if (checkedValue == false) {
            this.state.list[0] = "";
            document.getElementById(id).checked = false;
        }
    }

    tagOk2() {
        setTimeout(window.location.reload(), 3000);
        this.props.history.push('/Ocpptags');
    }

    handleDelete(event) {
        event.preventDefault();

        this.setState({
            modal1: !this.state.modal1
        });

        var url = baseUrl.URLPath + servicesUrl.getOcpptag;
        var data = this.state.activeArray;

        fetch(url, {
            method: "DELETE",
            body: JSON.stringify({"id": this.state.activeArray}),
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        })
        .then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else if (resp.status == 400) {
                return resp.json();
            } else if(resp.status === 500){
                alert('Internal Server Error - 500')
            } else {
                this.toggle2();
            }
        })
        .then(response => {
            if(response.status===400){
                this.toggle3(this.props.t(response.message));
            }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }

    render() {
        return (
            <>
                <AuthorizationTemplate
                    ChargePoint_W = {this.props.ChargePoint_W}
                    selectDataArray = {this.state.selectDataArray}
                    addAuthorization = {this.addAuthorization}
                    showEntriesChange = {this.showEntriesChange}
                    multiple = {false}
                    options = {this.state.options}
                    handleKeyDown = {this.handleKeyDown}
                    idTagChange = {this.idTagChange}
                    getIdTags = {this.getIdTags}
                    clearChargepoint = {this.clearChargepoint}
                    FindCpName = {this.FindCpName}
                    errors = {this.state.errors}
                    ascendingOrder = {this.ascendingOrder}
                    pageSize = {this.state.pageSize}
                    activePage = {this.state.activePage}
                    noOfRecords = {this.state.noOfRecords}
                    handlePageChange = {this.handlePageChange}
                    handleChange = {this.handleChange}
                    modal1 = {this.state.modal1}
                    toggle1 = {this.toggle1}
                    deleteHeader = {this.state.deleteHeader}
                    delete_msg = {this.state.delete_msg}
                    deleteText = {this.state.deleteText}
                    deleteButton = {this.state.deleteButton}
                    handleDelete = {this.handleDelete}
                    modal2 = {this.state.modal2}
                    tagOk2 = {this.tagOk2}
                    modal3 = {this.state.modal3}
                    modalsession = {this.state.modalsession}
                    sessiontagOk = {this.sessiontagOk}
                    error400 = {this.state.error400}
                />

                {/* Loader */}
                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </>
        )
    }
}

export default withTranslation()(Authorization);