import React from 'react';
import { Link } from 'react-router-dom';
import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from './constants';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();

class editOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        id: '',
        active: '',
        city: '',
        company: '',
        country: '',
        createdBy: '',
        createdDate: '',
        identifier: '',
        latitude: '',
        longitude: '',
        officeAddress: '',
        organisationType: '',
        root: '',
        state: '',
        updatedBy: '',
        updatedDate: '',
        url: ''
      },
      sucess: {},
      errors: {},
      activeItem: 1,
      modal: false,
      dataArray: [],
      disabledAttr: true,
      modalsession: false,
      disabled: true,
    }

    this.backToList = this.backToList.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.redirectToListPage = this.redirectToListPage.bind(this)
    this.toggle = this.toggle.bind(this)
    this.tagOk = this.tagOk.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.togglesession = this.togglesession.bind(this);
  }

  tagOk() {
    this.props.history.push('/organisationList');
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }

  //This is for making user should enter only numbers
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  organizationChange() {
    this.getProducts(this);
  }

  getProducts(e) {
    var orgId = document.getElementById("organization").value;
    this.setState({ orgId: orgId });
    if (orgId != '') {
      var url = baseUrl.FOTAPath + "dashboard/" + orgId + "/products";
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
          "tenant": localStorage.getItem("tenant")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((response) => {
          if (response != undefined) {
            this.setState({ dataArray: response });
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else {
      this.setState({ dataArray: [] });
    }
  }

  redirectToListPage() {
    this.props.history.push('/chargepoint')
  }

  validateURL(textval) {
    var urlregex = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
    var var_name = urlregex.test(textval);
    return var_name;
  }

  async handleSubmit(event) {
    event.preventDefault();

    let errors = {};
    var company = this.state.fields['company'];
    var identifier = this.state.fields['identifier'];
    var officeAddress = this.state.fields['officeAddress'];
    var city = this.state.fields['city'];
    var state = this.state.fields['state'];
    var country = this.state.fields['country'];

    var orgurl = this.state.fields['url'];
    if (company == '') {
      errors["companyempty"] = this.props.t('enter_a_organization');
    }
    if (city == '') {
      errors["cityempty"] = this.props.t('enter_a_city');
    }
    if (state == '') {
      errors["stateempty"] = this.props.t('enter_a_state');
    }
    if (orgurl == '') {
      errors["urlempty"] = this.props.t('enter_a_url');
    }
    if (this.validateURL(orgurl) == false) {
      errors["urlempty"] = this.props.t('enter_a_valid_url');
    }
    if (identifier == "") {
      errors["identifierempty"] = this.props.t('enter_identifier');
    }
    if (officeAddress == "") {
      errors["officeAddressempty"] = this.props.t('enter_a_office_address');
    }
    if (identifier !== '') {
      if (!identifier.match(/^[a-zA-Z]+$/)) {
        errors["identifierempty"] = this.props.t('enter_valid_identifier');
      }
    }
    if (country == 'Country') {
      errors["countryempty"] = this.props.t('country_validation');
    }

    if (errors.hasOwnProperty("companyempty") || errors.hasOwnProperty("cityempty") || errors.hasOwnProperty("stateempty") || errors.hasOwnProperty("urlempty") || errors.hasOwnProperty("identifierempty") || errors.hasOwnProperty("officeAddressempty") || errors.hasOwnProperty("countryempty")) { }

    else {
      let url = baseUrl.URLPath + servicesUrl.getAdminAccountByid + "?id=" + this.state.id;

      let data = { "company": company.toLowerCase(), "identifier": identifier, "officeAddress": officeAddress, "city": city, "state": state, "country": country, "url": orgurl };
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      }).then(resp => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else if (resp.status == 404) {
          this.setState({ errors: errors });
        } else if (resp.status == 500) { }
        else if (resp.status == 409) {
          this.setState({
            errors: errors
          });
        }
        else if (resp.status == 400) {

        } else if (resp.status == 200) {
          this.toggle()
        }
      })
      .catch(error => alert('Error:' + error));
    }
    this.setState({ errors: errors });
  }

  changeHandler(field, e) {
    var company = document.getElementById("companyId").value;
    var officeAddress = document.getElementById("officeAddressIid").value;
    var city = document.getElementById("cityId").value;
    var state = document.getElementById("stateId").value;
    var url = document.getElementById("urlId").value;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields, disabledAttr: "" });
    if (fields["companyId"] = company) {
      this.state.errors["companyempty"] = " ";
    }

    if (fields["officeAddressIid"] = officeAddress) {
      this.state.errors["officeAddressempty"] = " ";
    }
    if (fields["cityId"] = city) {
      this.state.errors["cityempty"] = " ";
    }
    if (fields["stateId"] = state) {
      this.state.errors["stateempty"] = " ";
    }
    if (fields["urlId"] = url) {
      this.state.errors["urlempty"] = " ";
    }
  }

  countryChange() {
    var x = document.getElementById("countryId").value;
    let fields = this.state.fields;
    fields["country"] = x;
    if (x != "Country") {
      this.state.errors["countryempty"] = " ";
    }
    this.setState({ fields });
    this.setState({ disabledAttr: "" });
  }

  backToList() {
    this.props.history.push({
      pathname: '/organisationView',
      state: this.state.id,
    });
  }

  componentDidMount() {
    var orgid = this.props.location.state;

    this.state.id = orgid;
    var url = baseUrl.URLPath +  servicesUrl.getAdminAccountByid + "?id="  + orgid;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        var data1 = [];
        data1 = response.organisationDTOS;
        this.setState({
          fields: data1[0]
        })
        if (this.state.fields.country != null && this.state.fields.country != "") {
          document.getElementById('countryId').getElementsByTagName('option')[this.state.fields.country].selected = 'selected';
        }
        console.log("data1" + data1)
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  render() {
    const{t}=this.props
    return (
      <>
        <main className="content-div" >
          <p> {t('Organization')}
            <div className="breadcrumb_div">
              {t('Settings')} &gt; <Link to="/organisationList"><span>{t('Organization')}</span></Link > &gt; <Link to={{
                pathname: '/organisationView',
                state: this.state.id,
              }}><span>{t('view')}</span></Link>&gt; <span className="breadcrumb_page">{t('edit')}</span>
            </div>
          </p>
          <form
            className='needs-validation'
            onSubmit={this.submitHandler}
            noValidate
          >
            <div className="page-outerdiv">
              <div className="row mb-3 mt-3">
                <MDBCol md="4">
                  <MDBInput
                    name="company"
                    value={this.state.fields["company"]}
                    onChange={this.changeHandler.bind(this, "company")}
                    type="text"
                    id="companyId"
                    label={`${t('Organization')} *`}
                    autoComplete="off"
                    className="text_lowercase"
                  >
                    <span className="error_msg w3-animate-top">
                      <span style={{ color: "red" }}>{this.state.errors["companyempty"]}</span>
                    </span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4">
                  <MDBInput
                    name="identifier"
                    value={this.state.fields["identifier"]}
                    onChange={this.changeHandler.bind(this, "identifier")}
                    type="text"
                    id="identifierId"
                    label={`${t('identifier_label')} *`}
                    autoComplete="off"
                    disabled={true}
                  >
                    <span className="error_msg w3-animate-top">
                      <span style={{ color: "red" }}>{this.state.errors["identifierempty"]}</span>
                    </span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4" >
                  <MDBInput
                    name="officeAddress"
                    value={this.state.fields["officeAddress"]}
                    onChange={this.changeHandler.bind(this, "officeAddress")}
                    min="0"
                    id="officeAddressIid"
                    label={`${t('office_add')} *`} 
                    autoComplete="off"
                  >
                    <span className="error_msg w3-animate-top">
                      <span style={{ color: "red" }}>{this.state.errors["officeAddressempty"]}</span>
                    </span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4" className="mt-2">
                  <MDBInput
                    value={this.state.fields["city"]}
                    onChange={this.changeHandler.bind(this, "city")}
                    type="text"
                    id="cityId"
                    name="city"
                    label={`${t('city')} *`}
                  >
                    <span className="error_msg w3-animate-top">
                      <span style={{ color: "red" }}>{this.state.errors["cityempty"]}</span>
                    </span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4" className="mt-2">
                  <MDBInput
                    value={this.state.fields["state"]}
                    onChange={this.changeHandler.bind(this, "state")}
                    onInput={this.numberHandler.bind(this, "state")}
                    type="text"
                    id="stateId"
                    name="state"
                    label={`${t('state*')}`} 
                  >
                    <span className="error_msg w3-animate-top">
                      <span style={{ color: "red" }}>{this.state.errors["stateempty"]}</span>
                    </span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4" className="mt-2">
                <select className="browser-default custom-select select_height" id="country" onChange={this.countryChange.bind(this)}>
                    <option value="Country">{t('country')} *</option>
                    <option value="Afghanistan">{t('Afghanistan')}</option>
                    <option value="Åland Islands">{t('Åland_islands')}</option>
                    <option value="Albania">{t('Albania')}</option>
                    <option value="Algeria">{t('Algeria')}</option>
                    <option value="American Samoa">{t('American_samoa')}</option>
                    <option value="Andorra">{t('Andorra')}</option>
                    <option value="Angola">{t('Angola')}</option>
                    <option value="Anguilla">{t('Anguilla')}</option>
                    <option value="Antarctica">{t('Antarctica')}</option>
                    <option value="Antigua and Barbuda">{t('Antigua_and_barbuda')}</option>
                    <option value="Argentina">{t('Argentina')}</option>
                    <option value="Armenia">{t('Armenia')}</option>
                    <option value="Aruba">{t('Aruba')}</option>
                    <option value="Australia">{t('Australia')}</option>
                    <option value="Austria">{t('Austria')}</option>
                    <option value="Azerbaijan">{t('Azerbaijan')}</option>
                    <option value="Bahamas">{t('Bahamas')}</option>
                    <option value="Bahrain">{t('Bahrain')}</option>
                    <option value="Bangladesh">{t('Bangladesh')}</option>
                    <option value="Barbados">{t('Barbados')}</option>
                    <option value="Belarus">{t('Belarus')}</option>
                    <option value="Belgium">{t('Belgium')}</option>
                    <option value="Belize">{t('Belize')}</option>
                    <option value="Benin">{t('Benin')}</option>
                    <option value="Bermuda">{t('Bermuda')}</option>
                    <option value="Bhutan">{t('Bhutan')}</option>
                    <option value="Bolivia">{t('Bolivia')}</option>
                    <option value="Bosnia and Herzegovina">{t('Bosnia_and_Herzegovina')}</option>
                    <option value="Botswana">{t('Botswana')}</option>
                    <option value="Bouvet Island">{t('Bouvet_island')}</option>
                    <option value="Brazil">{t('Brazil')}</option>
                    <option value="British Indian Ocean Territory">{t('British_indian_ocean_territory')}</option>
                    <option value="Brunei Darussalam">{t('Brunei_darussalam')}</option>
                    <option value="Bulgaria">{t('Bulgaria')}</option>
                    <option value="Burkina Faso">{t('Burkina_faso')}</option>
                    <option value="Burundi">{t('Burundi')}</option>
                    <option value="Cambodia">{t('Cambodia')}</option>
                    <option value="Cameroon">{t('Cameroon')}</option>
                    <option value="Canada">{t('Canada')}</option>
                    <option value="Cape Verde">{t('Cape_verde')}</option>
                    <option value="Cayman Islands">{t('Cayman_islands')}</option>
                    <option value="Central African Republic">{t('Central_african_republic')}</option>
                    <option value="Chad">{t('Chad')}</option>
                    <option value="Chile">{t('Chile')}</option>
                    <option value="China">{t('China')}</option>
                    <option value="Christmas Island">{t('Christmas_island')}</option>
                    <option value="Cocos (Keeling) Islands">{t('Cocos_keeling_islands')}</option>
                    <option value="Colombia">{t('Colombia')}</option>
                    <option value="Comoros">{t('Comoros')}</option>
                    <option value="Congo">{t('Congo')}</option>
                    <option value="Congo, The Democratic Republic of The">{t('Congo_the_democratic_republic_of_the')}</option>
                    <option value="Cook Islands">{t('Cook_islands')}</option>
                    <option value="Costa Rica">{t('Costa_rica')}</option>
                    <option value="Cote D'ivoire">{t('Cote_divoire')}</option>
                    <option value="Croatia">{t('Croatia')}</option>
                    <option value="Cuba">{t('Cuba')}</option>
                    <option value="Cyprus">{t('Cyprus')}</option>
                    <option value="Czech Republic">{t('Czech_republic')}</option>
                    <option value="Denmark">{t('Denmark')}</option>
                    <option value="Djibouti">{t('Djibouti')}</option>
                    <option value="Dominica">{t('Dominica')}</option>
                    <option value="Dominican Republic">{t('Dominican_republic')}</option>
                    <option value="Ecuador">{t('Ecuador')}</option>
                    <option value="Egypt">{t('Egypt')}</option>
                    <option value="El Salvador">{t('El_salvador')}</option>
                    <option value="Equatorial Guinea">{t('Equatorial_guinea')}</option>
                    <option value="Eritrea">{t('Eritrea')}</option>
                    <option value="Estonia">{t('Estonia')}</option>
                    <option value="Ethiopia">{t('Ethiopia')}</option>
                    <option value="Falkland Islands (Malvinas)">{t('Falkland_islands_malvinas')}</option>
                    <option value="Faroe Islands">{t('Faroe_islands')}</option>
                    <option value="Fiji">{t('Fiji')}</option>
                    <option value="Finland">{t('Finland')}</option>
                    <option value="France">{t('France')}</option>
                    <option value="French Guiana">{t('French_guiana')}</option>
                    <option value="French Polynesia">{t('French_polynesia')}</option>
                    <option value="French Southern Territories">{t('French_southern_territories')}</option>
                    <option value="Gabon">{t('Gabon')}</option>
                    <option value="Gambia">{t('Gambia')}</option>
                    <option value="Georgia">{t('Georgia')}</option>
                    <option value="Germany">{t('Germany')}</option>
                    <option value="Ghana">{t('Ghana')}</option>
                    <option value="Gibraltar">{t('Gibraltar')}</option>
                    <option value="Greece">{t('Greece')}</option>
                    <option value="Greenland">{t('Greenland')}</option>
                    <option value="Grenada">{t('Grenada')}</option>
                    <option value="Guadeloupe">{t('Guadeloupe')}</option>
                    <option value="Guam">{t('Guam')}</option>
                    <option value="Guatemala">{t('Guatemala')}</option>
                    <option value="Guernsey">{t('Guernsey')}</option>
                    <option value="Guinea">{t('Guinea')}</option>
                    <option value="Guinea-bissau">{t('Guinea_bissau')}</option>
                    <option value="Guyana">{t('Guyana')}</option>
                    <option value="Haiti">{t('Haiti')}</option>
                    <option value="Heard Island and Mcdonald Islands">{t('Heard_island_and_mcdonald_islands')}</option>
                    <option value="Holy See (Vatican City State)">{t('Holy_see_vatican_city_state')}</option>
                    <option value="Honduras">{t('Honduras')}</option>
                    <option value="Hong Kong">{t('Hong_kong')}</option>
                    <option value="Hungary">{t('Hungary')}</option>
                    <option value="Iceland">{t('Iceland')}</option>
                    <option value="India">{t('India')}</option>
                    <option value="Indonesia">{t('Indonesia')}</option>
                    <option value="Iran, Islamic Republic of">{t('Iran_islamic_republic_of')}</option>
                    <option value="Iraq">{t('Iraq')}</option>
                    <option value="Ireland">{t('Ireland')}</option>
                    <option value="Isle of Man">{t('Isle_of_man')}</option>
                    <option value="Israel">{t('Israel')}</option>
                    <option value="Italy">{t('Italy')}</option>
                    <option value="Jamaica">{t('Jamaica')}</option>
                    <option value="Japan">{t('Japan')}</option>
                    <option value="Jersey">{t('Jersey')}</option>
                    <option value="Jordan">{t('Jordan')}</option>
                    <option value="Kazakhstan">{t('Kazakhstan')}</option>
                    <option value="Kenya">{t('Kenya')}</option>
                    <option value="Kiribati">{t('Kiribati')}</option>
                    <option value="Korea, Democratic People's Republic of">{t('Korea_democratic_peoples_republic_of')}</option>
                    <option value="Korea, Republic of">{t('Korea_republic_of')}</option>
                    <option value="Kuwait">{t('Kuwait')}</option>
                    <option value="Kyrgyzstan">{t('Kyrgyzstan')}</option>
                    <option value="Lao People's Democratic Republic">{t('Lao_peoples_democratic_republic')}</option>
                    <option value="Latvia">{t('Latvia')}</option>
                    <option value="Lebanon">{t('Lebanon')}</option>
                    <option value="Lesotho">{t('Lesotho')}</option>
                    <option value="Liberia">{t('Liberia')}</option>
                    <option value="Libyan Arab Jamahiriya">{t('Libyan_arab_jamahiriya')}</option>
                    <option value="Liechtenstein">{t('Liechtenstein')}</option>
                    <option value="Lithuania">{t('Lithuania')}</option>
                    <option value="Luxembourg">{t('Luxembourg')}</option>
                    <option value="Macao">{t('Macao')}</option>
                    <option value="Macedonia, The Former Yugoslav Republic of">{t('Macedonia_the_former_yugoslav_republic_of')}</option>
                    <option value="Madagascar">{t('Madagascar')}</option>
                    <option value="Malawi">{t('Malawi')}</option>
                    <option value="Malaysia">{t('Malaysia')}</option>
                    <option value="Maldives">{t('Maldives')}</option>
                    <option value="Mali">{t('Mali')}</option>
                    <option value="Malta">{t('Malta')}</option>
                    <option value="Marshall Islands">{t('Marshall_islands')}</option>
                    <option value="Martinique">{t('Martinique')}</option>
                    <option value="Mauritania">{t('Mauritania')}</option>
                    <option value="Mauritius">{t('Mauritius')}</option>
                    <option value="Mayotte">{t('Mayotte')}</option>
                    <option value="Mexico">{t('Mexico')}</option>
                    <option value="Micronesia, Federated States of">{t('Micronesia_federated_states_of')}</option>
                    <option value="Moldova, Republic of">{t('Moldova_republic_of')}</option>
                    <option value="Monaco">{t('Monaco')}</option>
                    <option value="Mongolia">{t('Mongolia')}</option>
                    <option value="Montenegro">{t('Montenegro')}</option>
                    <option value="Montserrat">{t('Montserrat')}</option>
                    <option value="Morocco">{t('Morocco')}</option>
                    <option value="Mozambique">{t('Mozambique')}</option>
                    <option value="Myanmar">{t('Myanmar')}</option>
                    <option value="Namibia">{t('Namibia')}</option>
                    <option value="Nauru">{t('Nauru')}</option>
                    <option value="Nepal">{t('Nepal')}</option>
                    <option value="Netherlands">{t('Netherlands')}</option>
                    <option value="Netherlands Antilles">{t('Netherlands_antilles')}</option>
                    <option value="New Caledonia">{t('New_caledonia')}</option>
                    <option value="New Zealand">{t('New_zealand')}</option>
                    <option value="Nicaragua">{t('Nicaragua')}</option>
                    <option value="Niger">{t('Niger')}</option>
                    <option value="Nigeria">{t('Nigeria')}</option>
                    <option value="Niue">{t('Niue')}</option>
                    <option value="Norfolk Island">{t('Norfolk_island')}</option>
                    <option value="Northern Mariana Islands">{t('Northern_mariana_islands')}</option>
                    <option value="Norway">{t('Norway')}</option>
                    <option value="Oman">{t('Oman')}</option>
                    <option value="Pakistan">{t('Pakistan')}</option>
                    <option value="Palau">{t('Palau')}</option>
                    <option value="Palestinian Territory, Occupied">{t('Palestinian_territory_occupied')}</option>
                    <option value="Panama">{t('Panama')}</option>
                    <option value="Papua New Guinea">{t('Papua_new_guinea')}</option>
                    <option value="Paraguay">{t('Paraguay')}</option>
                    <option value="Peru">{t('Peru')}</option>
                    <option value="Philippines">{t('Philippines')}</option>
                    <option value="Pitcairn">{t('Pitcairn')}</option>
                    <option value="Poland">{t('Poland')}</option>
                    <option value="Portugal">{t('Portugal')}</option>
                    <option value="Puerto Rico">{t('Puerto_Rico')}</option>
                    <option value="Qatar">{t('Qatar')}</option>
                    <option value="Reunion">{t('Reunion')}</option>
                    <option value="Romania">{t('Romania')}</option>
                    <option value="Russian Federation">{t('Russian_federation')}</option>
                    <option value="Rwanda">{t('Rwanda')}</option>
                    <option value="Saint Helena">{t('Saint_helena')}</option>
                    <option value="Saint Kitts and Nevis">{t('Saint_kitts_and_nevis')}</option>
                    <option value="Saint Lucia">{t('Saint_lucia')}</option>
                    <option value="Saint Pierre and Miquelon">{t('Saint_pierre_and_miquelon')}</option>
                    <option value="Saint Vincent and The Grenadines">{t('Saint_vincent_and_the_grenadines')}</option>
                    <option value="Samoa">{t('Samoa')}</option>
                    <option value="San Marino">{t('San_marino')}</option>
                    <option value="Sao Tome and Principe">{t('Sao_tome_and_principe')}</option>
                    <option value="Saudi Arabia">{t('Saudi_arabia')}</option>
                    <option value="Senegal">{t('Senegal')}</option>
                    <option value="Serbia">{t('Serbia')}</option>
                    <option value="Seychelles">{t('Seychelles')}</option>
                    <option value="Sierra Leone">{t('Sierra_leone')}</option>
                    <option value="Singapore">{t('Singapore')}</option>
                    <option value="Slovakia">{t('Slovakia')}</option>
                    <option value="Slovenia">{t('Slovenia')}</option>
                    <option value="Solomon Islands">{t('Solomon_islands')}</option>
                    <option value="Somalia">{t('Somalia')}</option>
                    <option value="South Africa">{t('South_africa')}</option>
                    <option value="South Georgia and The South Sandwich Islands">{t('South_georgia_and_the_south_sandwich_islands')}</option>
                    <option value="Spain">{t('Spain')}</option>
                    <option value="Sri Lanka">{t('Sri_lanka')}</option>
                    <option value="Sudan">{t('Sudan')}</option>
                    <option value="Suriname">{t('Suriname')}</option>
                    <option value="Svalbard and Jan Mayen">{t('Svalbard_and_jan_mayen')}</option>
                    <option value="Swaziland">{t('Swaziland')}</option>
                    <option value="Sweden">{t('Sweden')}</option>
                    <option value="Switzerland">{t('Switzerland')}</option>
                    <option value="Syrian Arab Republic">{t('Syrian_arab_republic')}</option>
                    <option value="Taiwan, Province of China">{t('Taiwan_province_of_china')}</option>
                    <option value="Tajikistan">{t('Tajikistan')}</option>
                    <option value="Tanzania, United Republic of">{t('Tanzania_united_republic_of')}</option>
                    <option value="Thailand">{t('Thailand')}</option>
                    <option value="Timor-leste">{t('Timor_leste')}</option>
                    <option value="Togo">{t('Togo')}</option>
                    <option value="Tokelau">{t('Tokelau')}</option>
                    <option value="Tonga">{t('Tonga')}</option>
                    <option value="Trinidad and Tobago">{t('Trinidad_and_tobago')}</option>
                    <option value="Tunisia">{t('Tunisia')}</option>
                    <option value="Turkey">{t('Turkey')}</option>
                    <option value="Turkmenistan">{t('Turkmenistan')}</option>
                    <option value="Turks and Caicos Islands">{t('Turks_and_caicos_islands')}</option>
                    <option value="Tuvalu">{t('Tuvalu')}</option>
                    <option value="Uganda">{t('Uganda')}</option>
                    <option value="Ukraine">{t('Ukraine')}</option>
                    <option value="United Arab Emirates">{t('United_arab_emirates')}</option>
                    <option value="United Kingdom">{t('United_kingdom')}</option>
                    <option value="United States">{t('United_states')}</option>
                    <option value="United States Minor Outlying Islands">{t('United_states_minor_outlying_islands')}</option>
                    <option value="Uruguay">{t('Uruguay')}</option>
                    <option value="Uzbekistan">{t('Uzbekistan')}</option>
                    <option value="Vanuatu">{t('Vanuatu')}</option>
                    <option value="Venezuela">{t('Venezuela')}</option>
                    <option value="Viet Nam">{t('Viet_nam')}</option>
                    <option value="Virgin Islands, British">{t('Virgin_islands_british')}</option>
                    <option value="Virgin Islands, U.S.">{t('Virgin_islands_us')}</option>
                    <option value="Wallis and Futuna">{t('Wallis_and_futuna')}</option>
                    <option value="Western Sahara">{t('Western_sahara')}</option>
                    <option value="Yemen">{t('Yemen')}</option>
                    <option value="Zambia">{t('Zambia')}</option>
                    <option value="Zimbabwe">{t('Zimbabwe')}</option>
                  </select>
                  <span className="error_msg w3-animate-top mt-1">
                    <span style={{ color: "red" }}>{this.state.errors["countryempty"]}</span>
                  </span>
                </MDBCol>
                <MDBCol md="4" className="mt-2">
                  <MDBInput
                    value={this.state.fields["url"]}
                    onChange={this.changeHandler.bind(this, "url")}
                    onInput={this.numberHandler.bind(this, "url")}
                    type="text"
                    id="urlId"
                    name="url"
                    label={t('url*')}
                  >
                    <span className="error_msg w3-animate-top">
                      <span style={{ color: "red" }}>{this.state.errors["urlempty"]}</span>
                    </span>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="12" className="text-right mt-3">
                  <button type='submit' disabled={this.state.disabledAttr} onClick={this.handleSubmit} className="btn_primary mr-2">
                    {t('update')}
                  </button>
                  <button onClick={this.backToList} className="btn_cancel">
                    {t('cancel')} <i className="fas fa-times ml-2"></i>
                  </button>
                </MDBCol>
              </div>
            </div>
          </form>
        </main>
        
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
          <MDBModalBody>
            {t('organization_add')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk}>{t('ok')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
          {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(editOrganization);