//React Imports
import React from 'react';
import { createBrowserHistory } from 'history';
//3rd Party Imports
import $ from "jquery";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
//constants imports
import * as baseUrl from '../views/constants';
import { hasPermission } from '../views/auth';
import servicesUrl from '../common/servicesUrl';

//Component Imports
import ChargePointOverview from '../Screens/ChargePointOverview';

const history = createBrowserHistory();

class chargePoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationHide: false,
            onClickCheckBox: false,
            cpIdSorting: false,
            selectFindStatus: false,
            chargePoint: "",
            saveSelectedCpid: "",
            options: [],
            multiple: false,
            hideActionBtn: "none", // show hide btn varible based on permission 
            writePermission: this.props.ChargePoint_W, // ChargePoint_W conndition
            updatePermission: "", // ChargePoint_U conndition
            activePage: 1,
            active: "active",
            activeArray: [],
            checked: false,
            activeDeactiveArray: '',
            list: [],
            cpid1: '',
            filterData: [],
            dataArray: [],
            selectDataArray: [],
            noOfRecords: '',
            data: new Map(),
            intial_data: [],
            pageSize: "10",
            pageNo: 1,
            modalsession: false,
            hideUpdateActionBtn: "none",
            backendConnection: "",
            textdisplay: "",
            connectionoutTime: "10h",
            errors: {},
            lostBackendConnectionTime: "",
            isSitemanagementAvailable: false,
            modal1: false,
            modal2: false,
            modal3: false,
            fwVersion: '',
            _backendConnection: false,
            selectedModel: '',
            onboardDate: '',
            onboardDate1: '',
            calenderOpen: 'none',
            export500: false,
            fromDateApi: '',
            toDateApi: '',
            curPageFirstCreTime: "",
            curPageLastCreTime: "",
            prevPageFirstCreTime: "",
            listOfFirstCreatedtimes: []
        }
        this.showEntriesChange = this.showEntriesChange.bind(this);
        this.getChargePoints = this.getChargePoints.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.activeChargePoint = this.activeChargePoint.bind(this);
        this.tagOk1 = this.tagOk1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.tagOk2 = this.tagOk2.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.bulkImport = this.bulkImport.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.chargepointIDChange = this.chargepointIDChange.bind(this);
        this.getSearchInventoryList = this.getSearchInventoryList.bind(this);
        this.FindCpName = this.FindCpName.bind(this);
        this.ClearChargepoint = this.ClearChargepoint.bind(this);
        this.ClearChargepoints = this.ClearChargepoints.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.dynamicSort = this.dynamicSort.bind(this);
        this.getCustomization = this.getCustomization.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.exportChargePoints = this.exportChargePoints.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.clearFWVersion = this.clearFWVersion.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.changeOnboardDate = this.changeOnboardDate.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.displayCalender = this.displayCalender.bind(this);
        this.closeCalender = this.closeCalender.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.clearModel = this.clearModel.bind(this);
        this.keyPressForFWVersion = this.keyPressForFWVersion.bind(this);
        this.keyPressForModel = this.keyPressForModel.bind(this);
        this.toggleexport500 = this.toggleexport500.bind(this);
        this.createdDatesArray = this.createdDatesArray.bind(this);
    }

    getCustomization() {
        let tenant = localStorage.getItem('tenant');
        let url = baseUrl.LoginPath + "/api/admin/v1.0/account/tenant/customization?orgName=" + tenant;
        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else {
                return resp.json();
            }
        }).then(response => {
            if (response && Array.isArray(response)) {
                const siteManagementObj = response.find(el => el.operation === "Group Management");
                if (siteManagementObj) {
                    this.setState({ ...this.state, isSitemanagementAvailable: siteManagementObj.active });
                } else {
                    this.setState({ ...this.state, isSitemanagementAvailable: false });
                }
            }
        })
    }

    ClearChargepoints(ref, event) {
        if (this.state.onClickCheckBox == true) {
            this.handlePageChange(this.state.activePage);
            window.location.reload();
            this.state.onClickCheckBox = false;
        }
    }

    ClearChargepoint(ref, event) {
        if (this.state.saveSelectedCpid != "") {
            this.state.activeArray = "";
            this.state.saveSelectedCpid = "";
            let errors = this.state.errors;
            if(errors["chargePointNotExists"]!=""){
              errors["chargePointNotExists"] = "";
              errors["selectChargepoint"] = "";
            }else{
              this.state.activePage = 1;
              this.state.pageNo = 1;
              this.state.fromDateApi = '';
              this.state.toDateApi = '';
              errors["chargePointNotExists"] = "";
              errors["selectChargepoint"] = "";
            }
            this.setState({
              errors: errors
            }, () => {
                this.getChargePoints();
            });
            ref.current.clear();
          } else {
            let errors = this.state.errors;
            errors["chargePointNotExists"] = "";
            errors["selectChargepoint"] = "";
            this.setState({
              errors: errors
            })
            ref.current.clear();
          }
    }

    chargepointIDChange(cpid) {
        this.setState({ saveSelectedCpid: cpid }, () => {
            if (cpid.length == 0) {
              this.getChargePoints(cpid, "true", "no");
            } else {
              let errors = {};
              errors["chargePointNotExists"] = "";
              errors["selectChargepoint"] = "";
              this.setState({
                errors: errors
              });
              this.getChargePoints(cpid, "true", "no");
            }
          })
    }

    getInventorybasedonchargepoint(pageSize, pageNo, saveSelectedCpid) {

        var errors = {};
        if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
            errors["selectChargepoint"] = this.props.t('please_enter_chargepoint');
            this.setState({
                errors: errors
            });
        }
        else {
            if (this.state.selectFindStatus == true) {
                var url = baseUrl.URLPath + "chargepoints/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
            }
            else {
                var url = baseUrl.URLPath + "chargepoints/search?pageSize=" + pageSize + "&pageNo=" + pageNo + "&chargePointId=" + this.state.saveSelectedCpid;
            }

            fetch(url, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": localStorage.getItem("Authorization")
                }
            })
                .then((resp) => {

                    if (resp.status == 401) {
                        this.togglesession();
                        localStorage.clear();
                    }
                    if (resp.status == 404) {
                        errors["chargePointNotExists"] = this.props.t('chargepoint_not_exists');
                        this.setState({
                            errors: errors
                        });
                    }
                    if (resp.status == 200) {
                        return resp.json();
                    }
                })
                .then((response) => {

                    if (response != undefined) {
                        if (response.count.count == 0) {
                            errors["chargePointNotExists"] = this.props.t('chargepoint_not_exists');
                            this.setState({
                                errors: errors
                            });
                            $("#nochargeexists").show();
                        } else {
                            this.state.activeArray = "";
                            $("#nochargeexists").hide();
                            this.setState({
                                selectDataArray: response.cpList,
                                noOfRecords: response.count.count
                            });

                            var data = this.state.data;
                            data.set(pageNo, response.count.count)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
        }
    }

    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }

    createdDatesArray(dateTime) {
        let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
        this.setState({
            listOfFirstCreatedtimes: listOfFirstCreatedtimes
        });
    }

    handlePageChange(pageNumber) {
        if (pageNumber < this.state.activePage) {
            //prev
            if (pageNumber == 1) {
                this.setState({
                    activePage: pageNumber,
                    pageNo: pageNumber,
                    fromDateApi: '',
                    toDateApi: ''
                }, () => {
                    this.getChargePoints("", false, "");
                });
            } else {
                this.setState({
                    activePage: pageNumber,
                    pageNo: pageNumber,
                    fromDateApi: this.state.curPageFirstCreTime,
                    toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
                }, () => {
                    this.getChargePoints("", false, "");
                });
            }
        } else {
            //next
            this.setState({
                activePage: pageNumber,
                pageNo: pageNumber,
                fromDateApi: '',
                toDateApi: this.state.curPageLastCreTime,
                prevPageFirstCreTime: this.state.curPageFirstCreTime,
            }, () => {
                let listOfFirstCreatedtimes = this.state.listOfFirstCreatedtimes;
                listOfFirstCreatedtimes[pageNumber - 2] = this.state.curPageFirstCreTime;
                this.setState({
                    listOfFirstCreatedtimes: listOfFirstCreatedtimes
                }, () => {
                    this.getChargePoints("", false, "");
                });
            });
        }
    }

    bulkImport() {
        this.props.history.push('/bulkFCPImport')
    }

    toggle1() {
        this.setState({
            modal1: !this.state.modal1
        });
    }

    toggle2 = () => {
        this.setState({
            modal2: !this.state.modal2
        });
    }

    toggle3 = () => {
        this.setState({
            modal3: !this.state.modal3
        });
    }

    tagOk1() {
        // this.props.history.push('/chargepoint');
        window.location.reload();
    }

    tagOk2() {
        //  this.props.history.push('/chargepoint');
        window.location.reload();
    }

    showEntriesChange() {
        var entries = document.getElementById("showEntries").value;
        this.setState({
            activePage: 1,
            pageSize: entries,
            fromDateApi: '',
            toDateApi: ''
        },()=>{
            this.getChargePoints("",false,"");
        })
    }

    async getChargePoints(sno, countValue, dates, type) {
        document.getElementById("loader_image_div").style.display = "block";
        let fromTime = this.state.fromDateApi;
        let toTime = this.state.toDateApi;
        let pageNo = this.state.pageNo;

        if (dates == "no") {
            fromTime = '';
            toTime = '';
        }

        if (type == "search") {
            pageNo = 1;
        }

        let cpId = '';
        if (sno != undefined) {
            cpId = sno
        }

        var url = baseUrl.URLPath + "chargepoints/search?pageSize=" + this.state.pageSize + "&pageNo=" + pageNo + "&chargePointId=" + cpId + "&fwVersion=" + this.state.fwVersion + "&backendConnection=" + this.state._backendConnection + "&model=" + this.state.selectedModel + "&onboardDate=" + this.state.onboardDate1 + "&fromTime=" + fromTime + "&toTime=" + toTime;
        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    setTimeout(() => {
                        document.getElementById("loader_image_div").style.display = "none";
                        this.togglesession();
                        localStorage.clear();
                    }, 100);
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {
                if (response != undefined) {
                    if (response.count.count == 0) {
                        let errors = {};
                        if(cpId!=''){
                            errors["chargePointNotExists"] = this.props.t('chargepoint_not_exists');
                        }
                        if(this.state.fwVersion!=''){
                            this.setState({
                                dataArray: [],
                                noOfRecords: 0,
                                selectDataArray: []
                            })
                        }
                        if(this.state.selectedModel!=""){
                            this.setState({
                                dataArray: [],
                                noOfRecords: 0,
                                selectDataArray: []
                            })
                        }
                        if(this.state._backendConnection!=false){
                            this.setState({
                                dataArray: [],
                                noOfRecords: 0,
                                selectDataArray: []
                            })
                        }
                        if(this.state.onboardDate1!=''){
                            this.setState({
                                dataArray: [],
                                noOfRecords: 0,
                                selectDataArray: []
                            })
                        }
                        setTimeout(() => {
                            this.setState({
                                errors: errors
                            }, () => {
                                document.getElementById("loader_image_div").style.display = "none";
                            });
                        }, 100);
                    } else {
                        let hide = false
                        if (type == "search") {
                            hide = true
                        } else {
                            hide = false
                        }
                        if (countValue == false) {
                            setTimeout(() => {
                                this.setState({
                                    dataArray: response.cpList,
                                    selectDataArray: response.cpList,
                                    paginationHide: hide,
                                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                                    curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                                    curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                                }, () => {
                                    document.getElementById("loader_image_div").style.display = "none";
                                });
                            }, 100);
                        } else {
                            setTimeout(() => {
                                this.setState({
                                    dataArray: response.cpList,
                                    selectDataArray: response.cpList,
                                    noOfRecords: response.count.count,
                                    paginationHide: hide,
                                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                                    curPageFirstCreTime: moment(response.cpList[0].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                                    curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].updatedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                                }, () => {
                                    document.getElementById("loader_image_div").style.display = "none";
                                });
                            }, 100);
                        }
                    }
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    document.getElementById("loader_image_div").style.display = "none";
                    console.log(error, "catch the loop")
                }, 100);
            })
    }

    componentDidMount() {
        var user = {
            permissions: []
        }
        user.permissions = localStorage.getItem("roleAccess");
        var tenantName = localStorage.getItem("tenant");
        var fsd = localStorage.getItem("roleAccess");
        this.state.updatePermission = hasPermission(user, ['updatePermission']);
        if (this.state.updatePermission == true) {
            this.setState({
                hideUpdateActionBtn: "inline-block"
            })
        }

        // permission related code
        if (this.state.writePermission == true) {
            this.setState({
                hideActionBtn: "inline-block"
            })
        }
        this.getChargePoints();
        this.getCustomization();
    }

    //  function after clicking on checkbox to stored in an array 
    handleChange(id, cpid, event) {
        var fdf = this.state.activeArray;
        var checkedValue = document.getElementById(id).checked;
        if (checkedValue == true) {
            this.state.onClickCheckBox = true;
            this.state.list.push(cpid);
            this.setState({
                activeArray: this.state.list
            });
        } else {
            var fdf = this.state.list.index;
            const index = this.state.list.indexOf(cpid);
            if (index > -1) {
                this.state.list.splice(index, 1);
            }
            this.setState({
                activeArray: this.state.list
            });
        }
    }

    //  function after clicking on active and deactive button
    activeChargePoint(status) {
        if (this.state.activeArray != "" && this.state.activeArray.length != "0") {
            var url = baseUrl.URLPath + "chargepoints/bulkactivate";
            let data = { "id": this.state.activeArray, "active": status };
            fetch(url, {
                method: "PUT",
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    "content-type": "application/json",
                    "Authorization": localStorage.getItem("Authorization")
                }
            })
                .then((resp) => {
                    if (resp.status == 401) {
                        this.togglesession();
                        localStorage.clear();
                    }else{
                        return resp.json();
                    }
                })
                .then((response) => {
                    if (status == 0) {
                        this.toggle1()

                    } else {
                        this.toggle2()
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the loop")
                })
        } else if (this.state.activeArray == "" && this.state.activeArray.length == "0") {
            this.toggle3()
        }
    }

    // search filter 
    handleKeyDown(ref, e) {
        var serial_no = e.target.value;
        let errors = this.state.errors;
        errors["selectChargepoint"] = "";
        errors["chargePointNotExists"] = "";

        this.setState({
            errors: errors
        })
        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }
        var key = e.key;
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);
        var sno = serial_no.slice(0, serial_no.length - 1)

        if (key == "Backspace" || key == "Delete") {
            if(serial_no.length > 0){
                if (sno == "") {
                    this.state.saveSelectedCpid = sno;
                    this.getChargePoints("",true,"")
                }
            }
        } else if (e.keyCode == 13) {
            $("#selectcharge").hide();
            this.FindCpName(ref, e);
            $("#rbt_id").hide();
        } else if ((e.keyCode == 86 && ctrl)) {
            $("#selectcharge").hide();
            this.getSearchInventoryList(serial_no)
        } else {
            this.getSearchInventoryList(sno)
        }
        switch (e.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 37: // Left
            case 38: // Up
            case 39: // Right
            case 40: // Down
                break;
            default:
                var regex = new RegExp("^[a-zA-Z0-9 -]");
                var key = e.key;
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
                break;
        }
    }

    //function for sorting table data on click
    ascendingOrder() {
        if (this.state.cpIdSorting == false) {
            this.state.selectDataArray.sort(this.dynamicSort("chargePointId"));
            this.state.cpIdSorting = true;
            this.setState({ selectDataArray: this.state.selectDataArray.sort(this.dynamicSort("chargePointId")) })
        }
        else {
            this.state.selectDataArray.sort(this.dynamicSort("chargePointId"));
            this.state.cpIdSorting = false;
            this.setState({ selectDataArray: this.state.selectDataArray.reverse() });
        }

    }

    dynamicSort(property) {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder == -1) {
                return b[property].localeCompare(a[property]);
            } else {

                return a[property].localeCompare(b[property]);
            }
        }
    }

    getSearchInventoryList(cpid) {

        var url = baseUrl.URLPath + servicesUrl.getChargepointsPartilaSearch + "?cpid=" + cpid;

        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {

                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 200) {
                    return resp.json();
                }
            })
            .then((response) => {

                if (response != undefined) {
                    var option = [];
                    var data = [];
                    this.setState({
                        options: response,
                        intial_data: response
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop");
            })
    }

    FindCpName(ref, event) {
        event.preventDefault();
        this.state.saveSelectedCpid = ref.current.inputNode.value;
        var errors = {};
        if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
            errors["selectChargepoint"] = this.props.t('please_enter_chargepoint');
            this.setState({
                errors: errors
            });
        } else {
            errors["selectChargepoint"] = "";
            this.getChargePoints(this.state.saveSelectedCpid, "true", "no",'search');
        }

        var serial_no = event.target.value;
        if (serial_no.length > 1) {
            $("#selectcharge").hide();
        } else {
            $("#selectcharge").show();
        }
    }

    //export chargepoints data by Lakshmikanth
    exportChargePoints() {
        let url = baseUrl.URLPath + "chargepoints/download/xls?chargePointId=" + this.state.saveSelectedCpid + "&fwVersion=" + this.state.fwVersion + "&backendConnection=" + this.state._backendConnection + "&model=" + this.state.selectedModel + "&onboardDate=" + this.state.onboardDate;
        document.getElementById("loader_image_div").style.display = "block";
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                if (resp.status == 401) {
                    setTimeout(() => {
                        document.getElementById("loader_image_div").style.display = "none";
                        this.togglesession();
                        localStorage.clear();
                    }, 100);
                } else if (resp.status == 200) {
                    return resp.blob();
                } else if (resp.status == 500) {
                    this.toggleexport500();
                }
            })
            .then((response) => {
                setTimeout(() => {

                    if (response != undefined) {
                        let d = new Date();
                        let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                        // var data = new Blob([response], {type: 'application/csv'});
                        let url = window.URL.createObjectURL(response);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'ChargePoints' + dformat + '.xls';
                        document.getElementById("loader_image_div").style.display = "none";
                        a.click();
                    }
                }, 100);
            })
            .catch((error) => {
                setTimeout(() => {
                    document.getElementById("loader_image_div").style.display = "none";
                    console.error(error, "charpoints exports functionality failed")
                }, 100);
            })
    }

    changeHandler(field, e) {
        if (field == 'fwVersion') {
            if (e.target.validity.valid) {
                this.setState({ [field]: e.target.value }, () => {
                    if (this.state.fwVersion.length == 0) {
                        this.getChargePoints("",true,"")
                    }
                });
                $('.fwVersion_noexists').text("");
            }

        } else if (field == 'selectedModel') {
            this.setState({ [field]: e.target.value }, () => {
                if (this.state.selectedModel.length == 0) {
                    this.getChargePoints("",true,"")
                }
            });
            $('.selectedModel_noexists').text("");
        }
    }

    clearFWVersion() {
        if (this.state.fwVersion != "") {
            this.state.activeArray = "";
            if ($('.fwVersion_noexists').text() != "") {
                $('.fwVersion_noexists').text('')
            } else {
                this.state.activePage = 1;
                this.state.pageNo = 1;
                this.state.fromDateApi = '';
                this.state.toDateApi = '';
                $('.fwVersion_noexists').text('')
            }
            this.setState({
                fwVersion: ''
            }, () => {
                this.getChargePoints("", true, "");
            });
        } else {
            $('.fwVersion_noexists').text('')
        }
    }

    clearModel() {
        if (this.state.selectedModel != "") {
            this.state.activeArray = "";
            if ($('.selectedModel_noexists').text() != "") {
                $('.selectedModel_noexists').text('')
            } else {
                this.state.activePage = 1;
                this.state.pageNo = 1;
                this.state.fromDateApi = '';
                this.state.toDateApi = '';
                $('.selectedModel_noexists').text('')
            }
            this.setState({
                selectedModel: ''
            }, () => {
                this.getChargePoints("", true, "");
            });
        } else {
            $('.selectedModel_noexists').text('')
        }
    }

    handleChecked() {
        this.setState({
            _backendConnection: !this.state._backendConnection,
            activePage: 1,
            fromDateApi: '',
            toDateApi: ''
        }, () => {
            this.getChargePoints("",true,"")
        })
    }

    changeOnboardDate(e) {
        this.setState({ onboardDate: e.target.value });
    }

    keyPress(e) {
        var key = e.key;
        let date = e.target.value;

        $('.future_err').text("");
        if (e.keyCode == 13) {
            if (new Date() >= new Date(e.target.value)) {
                this.setState({ onboardDate: e.target.value });
                var dateFormat = moment(this.state.onboardDate).format("YYYY-MM-DD");
                this.state.onboardDate1 = dateFormat;
                this.getChargePoints("",true,"");
            } else {
                this.setState({ onboardDate: "" });
                this.state.onboardDate1 = "";
                $('.future_err').text(this.props.t('cannot_select_futuredate'));
            }
        }
        if (key == "Delete" || key == "Backspace") {
            if(date.length==1){
                this.state.onboardDate1 = "";
                this.getChargePoints("",true,"");
            }
        }
    }

    displayCalender() {
        this.setState({
            calenderOpen: "block"
        });
    }

    closeCalender() {
        this.setState({
            calenderOpen: "none"
        });
    }

    selectDate = date => {
        var fdate = this.formatCalDate1(date);
        this.setState({ onboardDate: fdate });
        this.state.onboardDate1 = this.formatCalDate(date);
        this.getChargePoints("",true,"");
        this.setState({
            calenderOpen: "none"
        });
    };

    formatCalDate1(date) {
        var format = date.toISOString();
        var xyz = moment(format).format("DD MMM YYYY")
        return xyz;
    }

    formatCalDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        // return [year, month, day].join('-');
        return [year, month, day].join('-');
    }

    keyPressForFWVersion(e) {
        var key = e.key;

        if (e.keyCode == 13) {
            if (this.state.fwVersion == "" || this.state.fwVersion == undefined || this.state.fwVersion == null) {
                $('.fwVersion_noexists').text(this.props.t('enter_fwversion'));
            } else {
                this.setState({ fwVersion: e.target.value });
                this.getChargePoints("",true,"");
            }
        }
        if (key == "Backspace" || key == "Delete") {
            var x = e.target.value;
            var delitem = x.slice(0, x.length - 1);
            if (delitem == "") {
                this.state.fwVersion = delitem;
                this.getChargePoints("",true,"");
            }
        }
    }

    keyPressForModel(e) {
        var key = e.key;

        if (e.keyCode == 13) {
            if (this.state.selectedModel == "" || this.state.selectedModel == undefined || this.state.selectedModel == null) {
                $('.selectedModel_noexists').text(this.props.t('enter_model_name'));
            } else {
                this.setState({ selectedModel: e.target.value });
                this.getChargePoints("",true,"");
            }
        }
        if (key == "Backspace" || key == "Delete") {
            var x = e.target.value;
            var delitem = x.slice(0, x.length - 1);
            if (delitem == "") {
                this.state.selectedModel = delitem;
                this.getChargePoints("",true,"");
            }
        }
    }

    toggleexport500() {
        this.setState({
            export500: !this.state.export500
        });
    }

    render() {
        return (
            <>
                <ChargePointOverview
                    selectDataArray={this.state.selectDataArray}
                    handleChange={this.handleChange}
                    isSitemanagementAvailable={this.state.isSitemanagementAvailable}
                    hideActionBtn={this.state.hideActionBtn}
                    activeChargePoint={this.activeChargePoint}
                    bulkImport={this.bulkImport}
                    multiple={this.state.multiple}
                    options={this.state.options}
                    handleKeyDown={this.handleKeyDown}
                    chargepointIDChange={this.chargepointIDChange}
                    ClearChargepoints={this.ClearChargepoints}
                    ClearChargepoint={this.ClearChargepoint}
                    FindCpName={this.FindCpName}
                    errors={this.state.errors}
                    ascendingOrder={this.ascendingOrder}
                    showEntriesChange={this.showEntriesChange}
                    pageSize={this.state.pageSize}
                    activePage={this.state.activePage}
                    noOfRecords={this.state.noOfRecords}
                    handlePageChange={this.handlePageChange}
                    modal1={this.state.modal1}
                    toggle1={this.toggle1}
                    tagOk1={this.tagOk1}
                    modal2={this.state.modal2}
                    toggle2={this.toggle2}
                    tagOk2={this.tagOk2}
                    modal3={this.state.modal3}
                    toggle3={this.toggle3}
                    modalsession={this.state.modalsession}
                    togglesession={this.togglesession}
                    sessiontagOk={this.sessiontagOk}
                    exportChargePoints={this.exportChargePoints}
                    fwVersion={this.state.fwVersion}
                    changeHandler={this.changeHandler}
                    clearFWVersion={this.clearFWVersion}
                    getChargePoints={this.getChargePoints}
                    _backendConnection={this.state._backendConnection}
                    handleChecked={this.handleChecked}
                    onboardDate={this.state.onboardDate}
                    onboardDate1={this.state.onboardDate1}
                    changeOnboardDate={this.changeOnboardDate}
                    keyPress={this.keyPress}
                    displayCalender={this.displayCalender}
                    closeCalender={this.closeCalender}
                    calenderOpen={this.state.calenderOpen}
                    selectDate={this.selectDate}
                    selectedModel={this.state.selectedModel}
                    clearModel={this.clearModel}
                    keyPressForFWVersion={this.keyPressForFWVersion}
                    keyPressForModel={this.keyPressForModel}
                    export500={this.state.export500}
                    toggleexport500={this.toggleexport500}
                    paginationHide = {this.state.paginationHide}
                />

                {/* Loader */}
                <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
                </div>
            </>
        );
    }
}

export default withTranslation()(chargePoint);
