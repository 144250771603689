import React from 'react';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput, MDBSelect } from 'mdbreact';
import Sidebar from './sidebar';
import Headers from './header';
import ChargePointSelection from './chargePointSelection';
import apiCallUtility from './APICallUtility'
import * as constants from './constants';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Header from './header';

class reset extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
           type:'',
           cpList:[],
           sucess:false,
           colour:'sucess',
           page_title:"Reset",
           errors:{},
           chargePoint:'',
          operationId:''
        }
        this.getSelectedCP=this.getSelectedCP.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.onShowAlert=this.onShowAlert.bind(this);
}
onShowAlert(){
  
 this.setState({sucess:true},()=>{
   window.setTimeout(()=>{
     this.setState({sucess:false})
   },5000)
 });

}
async handleSubmit(event) {
  
  var fdsfs=this.state;
  var payload=this.getPayLoad();
  var errors={};
  var type=payload.type;
 if(this.getCPList()==0){
  errors["emptyChargePoint"]=this.props.t('select_charge_point')
  }
 if(type==''){
  errors["emptyType"]=this.props.t('select_reset_type')
  }

  if(this.getCPList()!=0 && type!=''){
 var response = await apiCallUtility.cpmsProcessMessages(this.getMessageType(), this.getPayLoad(), this.getCPList());
 //alert(response);
 if (response == this.props.t('unable_toprocess_request_try_after_some_time')) {
  this.setState({ message: this.props.t('unable_toprocess_request_try_after_some_time'), colour: 'danger' }, ()=>{
    this.onShowAlert();
  })
} else {
  this.setState({ message: this.props.t('request_processed_successfully'), colour: 'success' }, ()=>{
    this.onShowAlert();
  })
}
}
this.setState({errors:errors})

}

getCPList() {
  var Id = this.state.operationId;
  if(Id != null && Id!=""){
   var cplist = Id.split();
 
  }
  else{
   var cplist = this.state.cpList;
 
 }
 return cplist;
}

getPayLoad() {
  var payload ={
    "type": this.state.type
  };

  //OR
  // var payload ={
  //   "type": "Soft"
  // };
   
  return payload;

}

getMessageType() {
  return constants.RESET;
}
getSelectedCP(cpList){
  
  this.setState({cpList:cpList});
  if(cpList!=""){
    this.state.errors["emptyChargePoint"]="  "
  
 
   
  }
  
}
handleChange(){
  var x=document.getElementById("type").value;
 
  this.setState({type:document.getElementById("type").value})
  if(x!=""){
  
    this.state.errors["emptyType"]=" "
   
  }
 
}
componentWillUnmount(){
  localStorage.setItem('chargePoint', '');
  localStorage.setItem('operationId', '');
}
componentDidMount(){
  let propsdata = this.props.location.state;
  if(propsdata!=undefined){
    if(propsdata.chargePoint!=undefined && propsdata.operationId!=undefined){
      this.setState({
        chargePoint: propsdata.chargePoint,
        operationId: propsdata.operationId
      });
    }
  }  
}
render() {
  const{t}=this.props
  return (
    <>
<main class="content-div" >
<p> {t('Reset')}
              <div className="breadcrumb_div">
              {t('Operations')} &gt; <span className="breadcrumb_page">  {t('Reset')}  </span>
              </div>
           </p>
        {/* <p className="breadcrumb_div"><a>Operations > </a> <a> Reset </a></p> */}
        <div className="page-outerdiv">
        {/* <div className="breadcrumb_div"><a>Operations > </a> <a> Reset </a></div> */}

          {/* <p>Reset</p> */}
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <MDBRow className="mb-4">
            <MDBCol sm="12">
               <div>
                <form
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                >
           <MDBRow>
           <ChargePointSelection {...this.props} opData={this.state.operationId} cpid={this.state.chargePoint} data={this.getSelectedCP} operationName = "Reset"></ChargePointSelection>
           <MDBCol md="12">
          <span className="selection_charge_point_error w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyChargePoint"]}</span></span>
          </MDBCol>

          {/* <MDBCol md="12">
            <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              <button class="mr-2 select_btn btn_cursor" > Select All</button> <button class="mr-4 select_btn btn_cursor">Select None </button>
            </label>
            <select className="browser-default custom-select select_height1" id="confKeyList" name="confKeyList" multiple="multiple" size="14"></select>
             </div>
            </MDBCol> */}

          <div className="pl-3 pr-3">
              <label className="input_label pt-2">{t('reset_type')}</label>
              </div>
          <MDBCol md="4">
            <select className="browser-default custom-select select_height mb_8" id ="type"onChange={this.handleChange}>
            <option value="">-- {t('select_reset_type*')} --</option>
                <option value="Hard">{t('hard')}</option>
                <option value="Soft">{t('soft')}</option>
            </select>

    <span className="error_msg w3-animate-top"> <span style={{color: "red"}} >{this.state.errors["emptyType"]}</span></span>      	

            </MDBCol>
          
           
          </MDBRow>
         
          {/* <MDBBtn color="primary" className="mt-5" onClick={this.handleSubmit}>Perform</MDBBtn> */}
          <button type = "button" className="mt-5 refresh-btn" onClick={this.handleSubmit} >{t('perform')}</button>
        </form>
      </div>
         </MDBCol>
        </MDBRow>
        </div>
    </main>
    </>
    );
  }
}

export default withTranslation()(reset);