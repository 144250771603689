import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'
import * as baseUrl from '../constants';
import { withTranslation } from 'react-i18next';
class NewSiteSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            modalsession: false
        };
        this.onSaveSite = this.onSaveSite.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.tagOk = this.tagOk.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
    }

    isHaveAlphabetic(string) {
        if (/[a-zA-Z]/.test(string) == true) {
            return true;
        } else {
            return false;
        }
    }

    isZipLengthAllowed(string){
        if (string.length <= 20 && string.length >=5) {
            return true;
        } else {
            return false;
        }
    }

    isLengthAllowed(string) {
        if (string.length <= 128) {
            return true;
        } else {
            return false;
        }
    }
    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }
    sessiontagOk() {
        this.props.history.push('/');
        window.location.reload();
    }
    onSaveSite() {
        let errors = {};
        let address = this.props.address;
        let city = this.props.city;
        let zipcode = this.props.zipcode;
        let name = this.props.name;

        if (name != "" && name != null && name != undefined) {
            name = name.trim();
            name = name.toLowerCase();
        }
        if (city != "" && city != null && city != undefined) {
            city = city.trim();
            city = city.toLowerCase();
        }
        if (zipcode != "" && zipcode != null && zipcode != undefined) {
            zipcode = zipcode.trim();
            zipcode = zipcode.toLowerCase();
        }
        if (address != "" && address != null && address != undefined) {
            address = address.trim();
            address = address.toLowerCase();
        }
        if (name == '') {
            errors["name"] =this.props.t('name_is_required');
        } else if (name != '' && this.isHaveAlphabetic(name) == false) {
            errors["name"] = this.props.t('name_must_contain_one_alpha_letter');
        } else if (name != '' && /[`!$%&*()+\=\{};':"\\|,.\[\]<>\/?~#@^]/.test(name) == true) {
            errors["name"] = this.props.t('name_not_contain_special_char_other_than');
        } else if (name != '' && this.isLengthAllowed(name) == false) {
            errors["name"] = this.props.t('max_leng_should128_char');
        }

        if (city == '') {
            errors["city"] = this.props.t('city_is_required');
        } else if (city != '' && this.isHaveAlphabetic(city) == false) {
            errors["city"] = this.props.t('city_nam_contain_one_alpha_letter');
        } else if (city != '' && /[`!$%&*()+\=\{};':"\\|,.<>\/?~#@_^]/.test(city) == true) {
            errors["city"] = this.props.t('city_name_not_contain_special_char_other_than');
        } else if (city != '' && this.isLengthAllowed(city) == false) {
            errors["city"] =this.props.t('max_leng_should128_char');
        }

        if (zipcode == '') {
            errors["zipcode"] = this.props.t('zcode_is_required');
        } else if (zipcode != '' && /[`!$%&*()+\=\{};':"\\|,.\[\]<>\/?~#@_^]/.test(zipcode) == true) {
            errors["zipcode"] = this.props.t('zcode_not_contain_special_char_other_than');
        } else if (zipcode != '' && this.isZipLengthAllowed(zipcode) == false) {
            errors["zipcode"] = this.props.t('zcode_leng_min5_char_and_max20_char');
        }

        if (address == "") {
            errors["address"] = this.props.t('addr_is_required');
        } else if (address != '' && this.isHaveAlphabetic(address) == false) {
            errors["address"] = this.props.t('addr_contain_one_alpha_letter');
        } else if (address != '' && /[!$%*+\=\{}\[\];"\\|<>?~#@_^]/.test(address) == true) {
            errors["address"] = this.props.t('addr_not_contain_special_char_other_than');
        } else if (address != '' && this.isLengthAllowed(address) == false) {
            errors["address"] = this.props.t('max_leng_should128_char');
        }

        this.setState({ ...this.state, settings: { name, city, zipcode, address } });
        if (Object.keys(errors).length > 0) {
            this.setState({ ...this.state, errors, settings: { name, city, zipcode, address } });
            return;
        }

        const data = {
            address, city, zipcode, name,
            tenant: localStorage.getItem('tenant'),
        };
        let url = this.props.selectedSite ? `${baseUrl.URLPath}sitemanagement?siteid=${this.props.id}` : `${baseUrl.URLPath}sitemanagement`;
        const method = this.props.selectedSite ? 'PUT' : 'POST';
        fetch(url, {
            method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('Authorization')
            }
        }).then(resp => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            }
            return resp.json();
        }).then((response) => {
            const errors = {};
            if (response.status === 409) {
                if (response.message === this.props.t('group_name_already_exists')) {
                    errors.name = this.props.t('group_name_already_exists');
                }
            } else if (!response.status) {
                if (this.props.onSiteAdded) {
                    this.props.onSiteAdded(response);
                }
            }
            if (Object.keys(errors).length > 0) {
                this.setState({ ...this.state, errors });
            }
        }).catch((error) => {
            console.log(error, 'error in creating the site');
        });
    }
    tagOk() {
            window.location.reload();
    }
    render() {
        const{t}=this.props
        return (
            <div>
                <form>
                    <div className="row mb-3 mt-3">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12 md-form">
                                    <input name="name" type="text" id="name" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.name} />
                                    <label htmlFor="name" className={`${(this.props.name != "" && this.props.name != null) ? "active" : ""} ml-20`}>{t('name')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["name"]}</span></span>
                                </div>
                                <div className="col-md-12 md-form">
                                    <input name="city" type="text" id="city" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.city} />
                                    <label htmlFor="city" className={`${(this.props.city != "" && this.props.city != null) ? "active" : ""} ml-20`}>{t('city')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["city"]}</span></span>
                                </div>
                                <div className="col-md-12 md-form">
                                    <input name="zipcode" type="text" id="zipcode" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.zipcode} />
                                    <label htmlFor="zipcode" className={`${(this.props.zipcode != "" && this.props.zipcode != null) ? "active" : ""} ml-20`}>{t('zipcode')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["zipcode"]}</span></span>
                                </div>
                                <div className="col-md-12 md-form">
                                    <input name="address" type="text" id="address" className="form-control" onChange={this.props.updateSiteSettings} value={this.props.address} />
                                    <label htmlFor="address" className={`${(this.props.address != "" && this.props.address != null) ? "active" : ""} ml-20`}>{t('address')}*</label>
                                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["address"]}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="text-right">
                    {
                        (this.props.isNewSite) ?
                            <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" onClick={this.props.saveNewSite}>
                                {t('save')} <i class="fas fa-save ml-2"></i>
                            </button> :
                            <button type="button" data-test="button" class="btn_primary mr-3 mb-2 Op-modal-close" onClick={()=>{this.props.updateExistingSite(this.props.id)}}>
                                {t('update')}
                            </button>
                    }
                    <button className="mt-5 btn_cancel" type="button" data-test="button" onClick={this.props.cancel}>
                       {t('cancel')} <i class="fas fa-times ml-2"></i>
                    </button>
                </div>
                <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                    <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
                    <MDBModalBody>
                       {t('please_login_again')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        );
    }
}

export default withTranslation()(NewSiteSettings);