import React from 'react';
import { MDBDataTable,MDBIcon,Select,MDBCol,MDBInput ,MDBBtn,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Form,Table,Check } from 'react-bootstrap'
import * as baseUrl from './constants';

class Loader extends React.Component {  
    constructor (props) {
            super(props);
            this.state = {
              loading: this.props.loader,
            }
        }  

render() {
  if(this.state.loading=="true"){
    return (
      <>
      <div className="loader center">
        {/* <i className="fa fa-cog fa-spin" /> */}
        <img  src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
      </div>
      </>
    );
  }
}
}

export default Loader;