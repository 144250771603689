import React from 'react';
import Datetime from 'react-datetime';
import {Link} from 'react-router-dom';
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import  $ from "jquery";
import * as baseUrl from './constants';
import GeoLocation from './googleMap';
import Header from './header';
import { createBrowserHistory } from 'history';
import servicesUrl from '../common/servicesUrl';

const history = createBrowserHistory();


class AddChargePoint extends React.Component{  
    constructor (props){
        super(props);
        this.state = {
        
          fields: {chargePointId: '', description:'', street:'', houseNumber:'', zipCode:'', city:'', country:'',locationLatitude:'', locationLongitude:''},
          sucess:{},
          errors: {},
          activeItem: "1",
          model:"false",
          page_title:"Add Charge Point",
          dataArray:[],
          orgArray:[],
          identifier:"",
          modalsession:false,
 
        }
        this.addNew = this.addNew.bind(this)
        this.backToList = this.backToList.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.redirectToListPage = this.redirectToListPage.bind(this)
        this.toggle = this.toggle.bind(this)
        this.tagOk = this.tagOk.bind(this);
        this.getProducts=this.getProducts.bind(this);
        this.togglesession = this.togglesession.bind(this);
}

addNew(){
  this.props.history.push('/chargePoint');
}
tagOk(){
  this.props.history.push('/chargePoint');
}
toggle = () => {
  this.setState({
  modal: !this.state.modal
  });
  }
  async togglesession(){
    ;
    this.setState({
    modalsession: !this.state.modalsession
    });
  }
  
  
  sessiontagOk(){
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
backToList(){
  this.props.history.push('/chargePoint');
}

 //This is for making user should enter only numbers
 numberHandler(field, e){ 
    let fields = this.state;
    if(e.target.validity.valid){
      fields[field] = e.target.value; 
      this.setState({fields});
    }else if(e.target.value==""){
      fields[field] = e.target.value; 
      this.setState({fields});
    }

  }
  organizationChange(event){
    this.state.identifier = event.target.value;
    this.getProducts();

  }

  componentDidMount(){
      
    // api for get all the organization 
   
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url,{
      method: "GET",
      headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
      })
      .then((resp) => {
        ;
        if(resp.status==401){
          // alert("Access token is expired, please login again")
          this.togglesession();
    
          localStorage.clear();
         
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) =>{
        
        if(response!=undefined){
      this.setState({ orgArray:response.organisationDTOS })
        }
        // this.getProducts();
      })
      
      .catch((error) => {
          console.log(error, "catch the loop")
      })
}
getProducts(e){
  
    var url = baseUrl.URLPath + servicesUrl.getCpInventoryEvmodelOrg + "?organization="+this.state.identifier;
    // alert(url)
    fetch(url,{
      method: "GET",
      headers: {
        "content-type":"application/json",
        "Authorization":localStorage.getItem("Authorization"),      }
      })
      .then((resp) => {
        ;
        if(resp.status==401){
           this.togglesession();
         // alert("Access token is expired, please login again")
         localStorage.clear();
      
       }
       if(resp.status==200){
         return resp.json();
       }      }) 
      .then((response) =>{
        if(response!=undefined){
            
          // alert(JSON.stringify(response));
         this.setState({ dataArray: response});
           } 
      // alert("data"+JSON.stringify(this.state.data));
      // this.setState({ total_charge_point:response.data.chargePointCount })

      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
    
  }
 
 
  redirectToListPage(){
    this.props.history.push('/chargepoint')
  }
  ;
  async handleSubmit(event) {

    
    event.preventDefault();
        ;
        let errors = {};
        var chargePointId= this.state.fields['chargePointId'];
        var zipCode= this.state.fields['zipCode'];
        var locationLatitude = this.state.fields['locationLatitude'];
        var locationLongitude= this.state.fields['locationLongitude'];
        if(chargePointId==''){
          errors["chargepointidempty"] = "Please enter a charge point id.";
           }
           var organization=document.getElementById("organization").value;
           var productId=document.getElementById("productId").value;
           if(organization==""){
            errors["organizationempty"] = "Please select the organization.";
           }
           if(productId==""){
             errors["productidempty"] = "Please select the product.";
           }
        if(zipCode!==''){
        if(!zipCode.match(/^[0-9]{1,10}$/)){
            errors["entervalidzipcode"] = "Please enter a valid Zip Code.";
           }
          }
         if(locationLatitude!==''){
          if(!locationLatitude.match(/^[+-]?(([1-8]?[0-9])(\.[0-9]{1,6})?|90(\.0{1,6})?)$/)){
            errors["entervalidlat"] = "Please enter a valid latitude.";
           }
          }
         if(locationLongitude!==''){
          if(!locationLongitude.match(/^[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,6})?)|180(\.0{1,6})?)$/)){
            errors["entervalidlong"] = "Please enter a valid longitude.";
           }
          }
           if(errors.hasOwnProperty("productidempty")||errors.hasOwnProperty("organizationempty")||errors.hasOwnProperty("entervalidzipcode") || errors.hasOwnProperty("entervalidlat")|| errors.hasOwnProperty("entervalidlong")|| errors.hasOwnProperty("chargepointidempty"))
           {}
          
         else{
      // alert("hello");
    
      let url = baseUrl.URLPath + "chargepoints"
     //localhost:8080/cpms/chargepoints
      
      let data = {"chargePointVendor":"webasto","chargePointModel":productId,"chargePointId": this.state.fields.chargePointId, "description":this.state.fields.description, "address":{"street":this.state.fields.street, "houseNumber":this.state.fields.houseNumber, "zipCode":this.state.fields.zipCode, "city":this.state.fields.city, "country":this.state.fields.country, "locationLatitude":this.state.fields.locationLatitude, "locationLongitude":this.state.fields.locationLongitude}}; 
     
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
         headers:{
         'Content-Type': 'application/json',
         "Authorization":localStorage.getItem("Authorization")
         }
        }).then(resp =>{
          
          if(resp.status==401){
            this.togglesession();
            // alert("Access token is expired, please login again")
            localStorage.clear();
          }
         else if(resp.status==404){
              errors["entervalidID"]="Please enter a valid charge point id."
            this.setState({errors: errors});
            
           }else if(resp.status==500){
             alert("internal server error")
            }
          //   else if(resp.status==409){
          //     alert("hhh")
          //     errors["entervalidID"] = "Charge Point is already exists."
          //  }
         else if(resp.status==409){
            errors["chargepointexist"]="Charge Point already exists."
            this.setState({
              errors: errors
              });
              
           }
           else if(resp.status==400){
           alert("Bad request")
        }else{
          this.toggle()
          //  alert("Charge point added successfully");
          // $("#suc_msg").html(' <strong>Success!</strong> Charge point added successfully.').slideDown();
            //  setTimeout(this.redirectToListPage, 1000);
          }
        })
        .catch(error => alert('Error:' + error));
  }
    this.setState({errors: errors});
}

  changeHandler(field, e){ 

  let fields = this.state.fields;
  fields[field] = e.target.value; 
  this.setState({fields});
  }

  countryChange(){
    var x = document.getElementById("country").value;
    let fields = this.state.fields;
    fields["country"] = x; 
    this.setState({fields});
  }

  render() {

    const organisation =this.state.orgArray.map((orgArray, index) => {
   
      return(
        <option value={orgArray.identifier}>{orgArray.company}</option>
      )
  })
    const products =this.state.dataArray.map((dataArray, index) => {
      return(
     <option value={dataArray.modelName}>{dataArray}</option>
    )
        })

  return (
    <>
   <main class="content-div" >
   <p> Charge Point
              <div className="breadcrumb_div">
                 Charge Point &gt; <Link to="/chargePoint">Overview</Link > &gt; <span className="breadcrumb_page">Add</span>
              </div>
          </p>
    {/* <p className="breadcrumb_div">Charge Point > <Link to="/chargePoint" > <span className="">Overview</span></Link> >  Add</p> */}
    <form
          className='needs-validation'
          onSubmit={this.submitHandler}
          noValidate
        >
      <div className="page-outerdiv">
      {/* <div className="breadcrumb_div">Charge Point > <Link to="/chargePoint" > <span className="">Overview</span></Link> >  Add</div> */}

        <div className="row">
          <div className="col-md-12 mb-3">
          <div class="alert alert-success alert-dismissible mt-2" id="suc_msg" style={{display:"none"}} >
             
              {/* <button type="button" class="close" data-dismiss="alert">&times;</button> */}
          </div>
             <span class=" form-title">Details:</span> 
          </div>
          <MDBCol md="4">
             <div className="md-form">
              <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.organizationChange.bind(this)}>
                <option value="">Select Organization *</option>
                {organisation}
                </select>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["organizationempty"]}</span></span> 
                </div>
                </MDBCol>
                <MDBCol md="4">
                <div className="md-form">
              <select className="browser-default custom-select select_height mb_8" id="productId" OnChange={this.getProducts.bind(this)}>
                <option value="">Select Product *</option>
                {products}
                </select>
                <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["productidempty"]}</span></span> 
                </div>
                </MDBCol>
           <MDBCol md="4">
                <MDBInput
                  name="chargebox"
                  value={this.state.fields["chargePointId"]}
                  // onChange={this.changeHandler}
                  onChange={this.changeHandler.bind(this, "chargePointId")}
                  type="text"
                  id="chargeboxId"
                  label="Charge Point ID *"
                  autoComplete="off"
                  required
                >
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["chargepointidempty"]}</span></span> 
              <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["chargepointexist"]}</span></span> 
          </MDBInput>

              </MDBCol>

              <MDBCol md="8">
              <MDBInput
                value={this.state.fields["description"]}
                onChange={this.changeHandler.bind(this, "description")}
                type="text"
                name="Description"
                label="Description"
              >
           </MDBInput>
          </MDBCol>
           
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
          
             <span class=" form-title">Address:</span> 
            
          </div>
            
          <MDBCol md="4" >
                <MDBInput
                  name="houseNumber"
                  value={this.state.fields["houseNumber"]}
                  onChange={this.changeHandler.bind(this, "houseNumber")}
                  min="0"
                  pattern="[0-9]*"
                  id="houseNumberId"
                  label="House Number"
                  autoComplete="off"
                >
                  
                </MDBInput>
              </MDBCol>

              <MDBCol md="4">
                <MDBInput
                  name="street"
                  value={this.state.fields["street"]}
                  onChange={this.changeHandler.bind(this, "street")}
                  type="text"
                  id="streetId"
                  label="Street"
                  autoComplete="off"
                >
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  value={this.state.fields["city"]}
                  onChange={this.changeHandler.bind(this, "city")}
                  type="text"
                  id="cityId"
                  name="city"
                  label="City"
                >
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  value={this.state.fields["zipCode"]}
                  onChange={this.changeHandler.bind(this, "zipCode")}
                  onInput={this.numberHandler.bind(this,"zipcode")}
                  type="text"   pattern="[0-9]*"
                  id="zipcodeId"
                  name="zipcode"
                  label="Zip Code"
                >
               <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidzipcode"]}</span></span> 
             </MDBInput>
             </MDBCol>
              
              <MDBCol md="4">
              <select className="browser-default custom-select select_height" id="country" onChange={this.countryChange.bind(this)}>
                <option value="">Country</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>

              </select>
              </MDBCol>
              <MDBCol md="4">
              <MDBInput
                value={this.state.fields["locationLatitude"]}
                onChange={this.changeHandler.bind(this, "locationLatitude")}
                type="text"
                name="adminAddress"
                label="Latitude"
              >
            <span className="error_msg w3-animate-top">  <span style={{color: "red"}}>{this.state.errors["entervalidlat"]}</span></span> 
              </MDBInput>
              </MDBCol>
            <MDBCol md="4" className="mt-1" >
              <MDBInput
                value={this.state.fields["locationLongitude"]}
                onChange={this.changeHandler.bind(this, "locationLongitude")}
                type="text"
                id="longitudeId"
                name="Longitude"
                label="Longitude"
                
              >
            </MDBInput>
    <span className="error_msg w3-animate-top"><span style={{color: "red"}}>{this.state.errors["entervalidlong"]}</span></span> 
 </MDBCol>  
  {/* <MDBCol md="12" className="mt-4">
    <GeoLocation google={this.props.google} center={{lat:18.520,lng:73.8567}} height='300px' zoom={15} />
  </MDBCol>  */}
     <MDBCol md="12" className="text-right mt-3">
      <MDBBtn color='primary' type='submit'  onClick={this.handleSubmit}>
        Save
      </MDBBtn>
      <MDBBtn color='primary' onClick={this.backToList} >
        <span style={{color:"#fff"}}> Back </span>   
      </MDBBtn>
    </MDBCol> 
</div>


    </div>
      
    </form>
    </main>
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
      <MDBModalBody>
      Charge point successfully added.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <button type="button" data-test="button" className="btn_primary" onClick={this.tagOk}>OK</button>
      </MDBModalFooter>
      </MDBModal>

      {/* popup for session expire */}

 <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
      <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
      <MDBModalBody>
       Please login again.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
      <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>OK</button>
      </MDBModalFooter>
      </MDBModal>
    </>
  );
}
}
 export default AddChargePoint;


