import React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";

export default class CustomModal extends React.Component {
    render() {
        let header = <></>;
        if(this.props.customHeader!="" && (this.props.title == undefined || this.props.title == "" || this.props.title == null)){
            header = this.props.customHeader
        }else{
            header = <MDBModalHeader>{this.props.title}</MDBModalHeader>
        }

        return (
            <MDBModal isOpen={this.props.open} size={(this.props.size!="")? this.props.size : "md"} data-toggle="modal" backdrop="static" className={(this.props.ModalClass!="" && this.props.ModalClass!=undefined && this.props.ModalClass!=null)? this.props.ModalClass:"model_top"} data-backdrop="static" data-keyboard="false">
                {header}
                <MDBModalBody>{this.props.bodyMessage}</MDBModalBody>
                <MDBModalFooter>
                    {this.props.PrimaryButtonText && <button type="button" data-test="button" className="btn_primary" onClick={this.props.PrimaryButtonOperation}>{this.props.PrimaryButtonText}</button>}
                    {this.props.AdditionalButtonText && <button type="button" data-test="button" className={`btn_primary ${this.props.AdditionalButtonStyle}`} onClick={this.props.AdditionalButtonOperation}>{this.props.AdditionalButtonText}</button>}
                </MDBModalFooter>
            </MDBModal>
        );
    }
}