import React from 'react';
import { MDBCol,MDBRow, MDBModal ,MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import {Form,Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import * as baseUrl from './constants';
import $ from 'jquery';
import moment from 'moment';
import {dateRangeValidate,activeDateFormat} from './_methods';
import { withTranslation } from 'react-i18next';

const history = createBrowserHistory();

 class Notifications extends React.Component{
    constructor(props){
        super(props);
        this.state={
            modalsession:false,
            chargePointId:"",
            fromDate:"",
            toDate:"",
            date1:"",
            date2:"",
            calenderFrom:"none",
            calenderTo:"none",
            pageSize:10,
            pageNo:1,
            model:"",
            fwVersion:"",
            notificationId:"",
            notificationsList:[],
            saveSelectedValue:"",
            disabled: true,
            dropdownValue:"",
            noOfRecords:0,
            activePage:1,
            viewsession:false,
            reqMessage:"",
            resMessage:""
        }
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
        this.changeDateTo=this.changeDateTo.bind(this);
        this.changeDateFrom=this.changeDateFrom.bind(this);
        this.displayCalender=this.displayCalender.bind(this);
        this.closeCalender=this.closeCalender.bind(this);
        this.fromChange=this.fromChange.bind(this);
        this.toChange=this.toChange.bind(this);
        this.viewModal=this.viewModal.bind(this);
        this.viewsession=this.viewsession.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClick1 = this.handleClick1.bind(this);
        this.keyPress1=this.keyPress1.bind(this);
        this.keyPress2 = this.keyPress2.bind(this);
    }
    handlePageChange(pageNumber) {
        if (pageNumber != this.state.activePage) {
            this.state.activePage=pageNumber;
            this.state.pageNo=pageNumber;
            this.getNotifications(this.state.pageSize,this.state.pageNo)
        }
    }
    componentDidMount(){
        let fromDate = this.formatLocalCalDate(moment().subtract(29,'d'));
    let toDate =  this.formatLocalCalDate(moment());

  let startdate = moment().subtract(29,'d');
  let enddate = moment();

  this.state.date1 = this.formatCalDate(startdate);
  this.state.dateOne = this.formateDate(startdate);

  this.state.date2 = this.formatCalDate(enddate);
  this.state.dateTwo = this.formateDate(enddate);

  this.state.fromDate = fromDate;
  this.state.toDate = toDate;
  this.setState({
    fromDate: fromDate
  })
  
        this.getNotifications(10,1,"");
    }
    keyPress1(e){
        var key = e.key;
        $('.date_range_error').text('');
        $('.future_err1').text("");
        if (e.keyCode == 13) {
          if (new Date() >= new Date(e.target.value)) {
              this.state.fromDate = new Date(e.target.value);
              this.state.date1 = this.formatCalDate(this.state.fromDate);
              this.setState({ fromDate: e.target.value });
            this.getNotifications(this.state.pageSize, 1);
          } else {
            this.setState({ fromDate: "" });
            this.state.date1 = "";
            $('.future_err1').text(this.props.t('you_not_select_future_date'));
          }
        }
        if (key == "Delete" || key == "Backspace") {
          this.state.date1 = "";
          this.getNotifications(this.state.pageSize, 1);
        }
     }
     keyPress2(e){
        var key = e.key;
        $('.date_range_error').text('');
        $('.future_err2').text("");
        if (e.keyCode == 13) {
          if (new Date() >= new Date(e.target.value)) {
              this.state.toDate = new Date(e.target.value);
              this.state.date2 = this.formatCalDate(this.state.toDate);
              this.setState({ toDate: e.target.value });
            this.getNotifications(this.state.pageSize, 1);
          } else {
            this.setState({ toDate: "" });
            this.state.date2 = "";
            $('.future_err2').text(this.props.t('you_not_select_future_date'));
          }
        }
        if (key == "Delete" || key == "Backspace") {
          this.state.date2 = "";
          this.getNotifications(this.state.pageSize, 1);
        }
     }
    async togglesession() {
        this.setState({
            modalsession: !this.state.modalsession
        });
    }
    async viewsession(){
        this.setState({
            viewsession: !this.state.viewsession
        });
    }
    viewModal(reqMessage,resMessage){
        this.state.reqMessage=reqMessage;
        this.state.resMessage=resMessage;
        this.viewsession();
    }
    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }
    changeHandler(field, e){
        this.setState({[field]: e.target.value});
        if(field=="chargePointId"){
            $('.noexists').text("");
        }
    }
    getNotifications(pageSize,pageNo,action){
        
        if((this.state.chargePointId=="" || this.state.chargePointId==undefined || this.state.chargePointId==null) && action=="find"){
            $('.noexists').text("Please Enter Chargepoint");
        }else{
        let url=baseUrl.URLPath+"pushnotifications/search/pushNotification?pageSize="+pageSize+"&pageNo="+pageNo+"&model="+this.state.model+"&fwVersion="+this.state.fwVersion+"&notificationId="+this.state.notificationId+"&fromTime="+this.state.date1+"&toTime="+this.state.date2+"&cpId="+this.state.chargePointId;
        fetch(url,{
            method:"GET",
            headers:{
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization")
            }
        }).then((resp)=>{
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else {
                return resp.json();
            }
        }).then((response)=>{
            if(response!=undefined){
                if(response.pnList!=null && response.pnList!=undefined && response.pnList.length>0){
                    this.setState({
                        notificationsList: response.pnList,
                        noOfRecords: response.count.count
                    });
                    if(action=="find"){
                        $('.noexists').text("");
                    }
                }else{
                    this.setState({
                        notificationsList: [],
                        noOfRecords:0
                    });
                    if(action=="find"){
                        $('.noexists').text(this.props.t('chargepoint_not_exists'));
                    }
                }
            }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
        }
    }
    changeDateFrom(e) {
        this.setState({ fromDate: e.target.value });
    }
    changeDateTo(e) {
        this.setState({ toDate: e.target.value });
    }
    displayCalender(type,event){
        var calenderFrom = "";
        var calenderTo = "";
        if(type=="from"){
         calenderFrom =  "block";
         calenderTo =  "none";
        }else{
         calenderTo =  "block";
         calenderFrom =  "none";
        }
        this.setState({
         calenderFrom:calenderFrom,
         calenderTo:calenderTo
   })
      }
    closeCalender(type,event){
    if(type=="from"){
        this.setState({
        calenderFrom:"none"
        })
    }else{
        this.setState({
        calenderTo:"none"
        })
    }
    }
    formateDate(date){
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [year, month, day].join('-');
      }
    fromChange = date => {
        ;
        var fdate =this.formatLocalCalDate(date);
        this.setState({fromDate:fdate});
        this.state.date1 = this.formatCalDate(date);
        this.state.dateOne = this.formateDate(date);
        this.state.pageNo=1;
        this.state.pageSize=10;
        // if(dateRangeValidate(this.state.dateOne, this.state.dateTwo)==true){
        //     $('.date_range_error').text('');
            this.getNotifications(this.state.pageSize,1);
            var calenderFrom =  "none";
            this.setState({
              calenderFrom:calenderFrom
            })
        // }else{
        //     $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        //     var calenderFrom =  "none";
        //     this.setState({
        //       calenderFrom:calenderFrom
        //     })
        // }
      };
      toChange = date => {
        var fdate = this.formatLocalCalDate(date);
        this.setState({toDate:fdate});
         this.state.date2 = this.formatCalDate(date);
         this.state.dateTwo = this.formateDate(date);
         this.state.pageNo=1;
        this.state.pageSize=10;
        // if(dateRangeValidate(this.state.dateOne,this.state.dateTwo)==true){
        //     $('.date_range_error').text('');
            this.getNotifications(this.state.pageSize,1);
            var calenderTo =  "none";
            this.setState({
              calenderTo:calenderTo
            })
        // }else{
        //     $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        //     var calenderTo =  "none";
        //     this.setState({
        //       calenderTo:calenderTo
        //     })
        // }
        
      };
      formatLocalCalDate(date){
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        var monthname = d.toLocaleString('default', { month: 'short' });
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [day, monthname, year].join(' ');
      }
      formatCalDate(date){
        var format = date.toISOString().substr(0, 19)+"Z";
        return format;
      }
      // method to call onChange Operation Name
   dropDownChange=()=>{
       
    var id = document.getElementById("selectDropdown").value;
    if(id!="" && id!=undefined){
        this.setState({
            saveSelectedValue: id,
            disabled: false
        })
    }else{
        this.setState({
            saveSelectedValue: "",
            disabled: true
        })
    }
}
handleClick(){
   this.state.chargePointId=""
    this.setState({
        chargePointId:this.state.chargePointId
          });
          this.getNotifications(10,1,"");
          $('.noexists').text("");
         
}
handleClick1(){
this.state.dropdownValue=""
this.state.fwVersion=""
this.state.notificationId=""
this.state.model=""
    this.setState({
        dropdownValue:this.state.dropdownValue,
        fwVersion:this.state.fwVersion,
        notificationId:this.state.notificationId,
        model:this.state.model

          });
          this.getNotifications(10,1,"");
          
}
    onchangeDropdown=(e)=>{
        if(this.state.saveSelectedValue=="FW Version"){
            this.state.fwVersion=e.target.value;
            this.state.model="";
            this.state.notificationId="";
        }else if(this.state.saveSelectedValue=="Notificationid"){
            this.state.fwVersion="";
            this.state.model="";
            this.state.notificationId=e.target.value;
        }else if(this.state.saveSelectedValue=="Model"){
            this.state.fwVersion="";
            this.state.model=e.target.value;
            this.state.notificationId="";
        }
        this.setState({dropdownValue:e.target.value});
    }
    showEntriesChange=()=>{
        var entries=document.getElementById("showEntries").value;
        this.state.activePage=1;
        this.state.pageSize=entries;
        this.state.pageNo=1;
        this.getNotifications(entries,1);
    }
    csvExport=()=> {
        let url = baseUrl.URLPath + "pushnotifications/download/CSV?model="+this.state.model+"&fwVersion="+this.state.fwVersion+"&notificationId="+this.state.notificationId+"&fromTime="+this.state.date1+"&toTime="+this.state.date2+"&cpId="+this.state.chargePointId+"&timeZone="+localStorage.getItem('timeZone');
        fetch(url, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization")
            }
        }).then((resp) => {
            if (resp.status == 401) {
                this.togglesession();
                localStorage.clear();
            } else {
                return resp.blob();
            }
        }).then((response) => {
            if (response != undefined) {
                let d = new Date();
                let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Notifications_' + dformat + '.csv';
                a.click();
              }
        }).catch((error) => {
            console.log(error, "catch the loop")
        })
    }
    render(){
        const{t}=this.props
        return(
            <main className="content-div">
                <p>{t('Notifications')}</p>
                <div className="page-outerdiv">
                    <div className="row menubar">
                        <div className="col-md-12">
                            <button color="primary accent-2" onClick={this.csvExport}>
                                <i className="fas fa-file-export"></i>
                                <span>{t('export_all')}</span>
                            </button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <input
                                    type="text"
                                    id="chargePointId"
                                    value={this.state.chargePointId}
                                    onChange={this.changeHandler.bind(this, "chargePointId")}
                                    onKeyDown=""
                                    class="form-control input-searchBox pad-2"
                                    placeholder="Charge Point ID"
                                    name="srch-term" 
                                />
                                 <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick} >
                  <span aria-hidden="true">×</span>
                </button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.getNotifications.bind(this,10,1,"find")}>{t('find')}</button>
                                </div>
                            </div>
                            <span className="error_msg w3-animate-top mt-2 noexists" style={{color:"red"}}></span>
                        </div>
                        <div className="col-md-3 p-rel">
                            <div class="input-group">
                                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('from_time')} name="srch-term" id="fromDate" value={this.state.fromDate} onChange={this.changeDateFrom}  onKeyDown={this.keyPress1} /> 
                                <div class="input-group-btn">
                                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this,"from")}><i className="fas fa-calendar-alt fa-sm"></i></button>
                                </div>
                            </div>
                            <div style={{display:this.state.calenderFrom}} className="calenderContainer">
                                <div onClick={this.closeCalender.bind(this,"from")}><i class="far fa-times-circle"></i></div>
                                <Calendar
                                    selected={this.state.fromDate}
                                    onChange={date => this.fromChange(date)}
                                    value={activeDateFormat(this.state.fromDate)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    onBlur=""
                                    maxDate={(this.state.toDate!=""&&this.state.toDate!=null)? new Date(this.state.toDate):new Date()}
                                />
                            </div>
                            <span className="pull-left future_err1 w3-animate-top mt-1" style={{color: "red"}}></span>
                        </div>
                        <div className="col-md-3 p-rel">
                            <div class="input-group">
                                <input type="text" class="form-control input-searchBox pl-3" placeholder={t('to_time')} name="srch-term" id="srch-term" onKeyDown={this.keyPress2} onChange={this.changeDateTo} value={this.state.toDate} />
                                <div class="input-group-btn">
                                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this,"to")}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                            </div>
                            <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                                <div onClick={this.closeCalender.bind(this,"to")}><i class="far fa-times-circle"></i></div>
                                <Calendar
                                    selected={this.state.toDate}
                                    onChange={date=>this.toChange(date)}
                                    value={activeDateFormat(this.state.toDate)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="dd-MMM-yyyy HH:mm"
                                    minDate={new Date(this.state.fromDate)}
                                    maxDate={new Date()}
                                />
                            </div>
                            <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
                        </div>
                        <div className='col-md-12 date_range_error' style={{ color: "red", textAlign:"center" }}></div>
                    </div>
                    <div className="row mt-1">
                        <MDBCol md="3" className="mt-1" >
                            <select className="browser-default custom-select select_height" id="selectDropdown" onChange={this.dropDownChange}>
                                <option value=""> {t('select')} </option>
                                <option value="FW Version">{t('fw_version')}</option>
                                <option value="Notificationid">{t('notification_id')}</option>
                                <option value="Model">{t('model')}</option>
                            </select>
                        </MDBCol>
                        <div className="col-md-4 mt-2 pt-1 custom_input_adjust">
                            <div class="input-group">
                                <i className="fas fa-search fa-sm input-searchIcon"></i>
                                <input type="text" id="dropDownIds" value={this.state.dropdownValue} onChange={this.onchangeDropdown} disabled={this.state.disabled} class="form-control input-searchBox pad-2" placeholder={t('fw_version_notificationid')} name="adminAddress" />
                                <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick1} >
                  <span aria-hidden="true">×</span></button>
                                <div class="input-group-btn">
                                    <button class="input-searchBtn find-btn" type="submit" onClick={this.getNotifications.bind(this,10,1)}>{t('find')}</button>
                                </div>
                                <span className="error_msg w3-animate-top"><span style={{color:"red"}}></span></span>
                            </div>
                        </div>
                        <div className="col-md-5 mt-2 pt-1 custom_input_adjust2">
                            <div className="pull-right">
                                <label className="sub-text pull-left" >{t('show_entries')}</label>
                                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        {/* <th style={{width: "5%"}}></th> */}
                                        <th style={{width: "11%"}}>{t('charge_point_id')}</th>
                                        <th style={{width: "11%"}}>{t('model')}</th>
                                        <th style={{width: "10%"}}>{t('fw_version')}</th>
                                        <th style={{width: "8%"}}>{t('notification_id')}</th>
                                        <th style={{width: "40%"}}>{t('notification_description')}</th>
                                        <th style={{width: "10%"}}>{t('created_date')}</th>
                                        <th style={{width: "5%"}}>{t('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.notificationsList.map((notification,index)=>{
                                            if(notification.createdDate==null || notification.createdDate==""){
                                                var createdDate = "";
                                                var createdDate1="";
                                              }else{
                                                var createdDate = moment(notification.createdDate).format("DD MMM YYYY");
                                                var createdDate1 = moment(notification.createdDate).format("HH:mm");
                                              }
                                            return(
                                                <tr>
                                                    {/* <td className="text-center">
                                                        <Form.Check
                                                            custom
                                                            type="checkbox"
                                                            id={`custom-${index}`}
                                                            label=""
                                                            onChange=""
                                                            key={`custom-${index}`}
                                                        />
                                                    </td> */}
                                                    <td>{notification.chargePointId}</td>
                                                    <td>{notification.model}</td>
                                                    <td>{notification.fwVersion}</td>
                                                    <td>{notification.notificationId}</td>
                                                    <td>{notification.notificationDescription}</td>
                                                   <td className="wrap-word">{ createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>}</td>
                                                    <td>
                                                        <i className="fas fa-eye action-icon mt-1 ml-2" title="View" style={{ cursor: 'pointer' }} onClick={this.viewModal.bind(this,notification.requestMessage,notification.responseMessage)}></i>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-12 mt-2 mb-4 text-right">
                            <Pagination
                                hideDisabled
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.pageSize}
                                totalItemsCount={this.state.noOfRecords}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                    <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
                    <MDBModalBody>
                       {t('please_login_again')}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button onClick={this.sessiontagOk} type="button" data-test="button" className="btn_primary">{t('ok')}</button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.viewsession} toggle={this.viewsession} size="lg" className="model_top">
                    <MDBModalBody>
                        <MDBRow className="mb-4 mt-3" >
                            <MDBCol sm="12">
                                <div className="view-bg-row" >
                                    <label className=""><b>{t('request_message')}: </b></label>
                                    <label className="wrap-word">{this.state.reqMessage}</label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-4 mt-3" >
                            <MDBCol sm="12">
                                <div className="view-bg-row" >
                                    <label><b>{t('response_message')}: </b></label>
                                    <label className="wrap-word">{this.state.resMessage}</label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button onClick={this.viewsession} type="button" data-test="button" className="btn_primary">{t('close')}</button>
                    </MDBModalFooter>
                </MDBModal>
            </main>
        );
    }
}

export default withTranslation()(Notifications);