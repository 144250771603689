import React from 'react';
import { MDBCol } from 'mdbreact';
import { Form, Table } from 'react-bootstrap';
import * as baseUrl from './constants';
import Pagination from "react-js-pagination";
import moment from 'moment';
import 'moment-timezone';
import $ from "jquery";
import Calendar from 'react-calendar';
import { withTranslation } from 'react-i18next';
import { dateRangeValidate, activeDateFormat } from './_methods';
import servicesUrl from '../common/servicesUrl';

class reservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minRequetsDate: '',
      minScheduleDate: '',
      cpIdSorting: false,
      rfIdSorting: false,
      schedulecalenderTo: "none",
      schedulecalenderFrom: "none",
      calenderFrom: "none",
      calenderTo: "none",
      tagList: [],
      startDate1: '',
      startDate2: '',
      startDate3: '',
      startDate4: '',
      requesttoDate: '',
      requestfromDate: '',
      scheduletoDate: '',
      schedulefromDate: '',

      page_title: "Reservations",
      items: [],
      fields: {

        chargePointId: ""
      },
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      id: '',
      errors: {},

    }
    this.getReservations = this.getReservations.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.idTagChange = this.idTagChange.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.exportReservationCSV = this.exportReservationCSV.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.closeCalender = this.closeCalender.bind(this);
    this.changeRequestFrom = this.changeRequestFrom.bind(this);
    this.changeRequestTo = this.changeRequestTo.bind(this);
    this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
    this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.keyPress1 = this.keyPress1.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);
    this.keyPress3 = this.keyPress3.bind(this);
    this.keyPress4 = this.keyPress4.bind(this);







  }
  keyPress1(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ requestfromDate: e.target.value });
        var dateFormat = moment(this.state.requestfromDate).format("YYYY-MM-DD");
        this.state.startDate1 = dateFormat;
        // if (dateRangeValidate(this.state.startDate1,this.state.startDate2)==true){
        //   $('.date_range_error').text('');
          this.getReservations(this.state.pageSize, 1);
        // }else{
        //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        // }
        $('.future_err1').text("");
      } else {
        this.setState({ requestfromDate: "" });
        this.state.startDate1 = "";
        $('.future_err1').text(this.props.t('cannot_select_futuredate'));
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate1 = "";
      this.getReservations(this.state.pageSize, 1);
      $('.future_err1').text("");
    }

  }
  keyPress2(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ requesttoDate: e.target.value });
        var dateFormat = moment(this.state.requesttoDate).format("YYYY-MM-DD");
        this.state.startDate2 = dateFormat
        // if (dateRangeValidate(this.state.startDate1,this.state.startDate2)==true){
        //   $('.date_range_error').text('');
          this.getReservations(this.state.pageSize, 1);
        //  }else{
        //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        //  }
         $('.future_err2').text("");
      } else {
        this.setState({ requesttoDate: "" });
        this.state.startDate2 = "";
        $('.future_err2').text(this.props.t('cannot_select_futuredate'));
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate2 = "";
      this.getReservations(this.state.pageSize, 1);
      $('.future_err2').text("");
    }

  }
  keyPress3(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >=  new Date(e.target.value)) {
        this.setState({ schedulefromDate: e.target.value });
        var dateFormat = moment(this.state.schedulefromDate).format("YYYY-MM-DD");
        this.state.startDate3 = dateFormat;
        // if (dateRangeValidate(this.state.startDate3,this.state.startDate4)==true){
        //   $('.date_range_error1').text('');
          this.getReservations(this.state.pageSize, 1);
        //  }else{
        //   $('.date_range_error1').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        //  }
         $('.future_err3').text("");
      } else {
        this.setState({ schedulefromDate: "" });
        this.state.startDate3 = "";
        $('.future_err3').text(this.props.t('cannot_select_futuredate'));
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate3 = "";
      this.getReservations(this.state.pageSize, 1);
      $('.future_err3').text("");
    }

  }
  keyPress4(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ scheduletoDate: e.target.value });
        var dateFormat = moment(this.state.scheduletoDate).format("YYYY-MM-DD");
        this.state.startDate4 = dateFormat
        // if (dateRangeValidate(this.state.startDate3,this.state.startDate4)==true){
        //   $('.date_range_error1').text('');
          this.getReservations(this.state.pageSize, 1);
        //  }else{
        //   $('.date_range_error1').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
        //  }
         $('.future_err4').text("");
      } else {
        this.setState({ scheduletoDate: "" });
        this.state.startDate4 = "";
        $('.future_err4').text(this.props.t('cannot_select_futuredate'));
      }
    }
    if (key == "Delete" || key == "Backspace") {
      this.state.startDate4 = "";
      this.getReservations(this.state.pageSize, 1);
      $('.future_err4').text("");
    }

  }
  changeRequestFrom(e) {
    this.setState({ requestfromDate: e.target.value });
  }
  changeRequestTo(e) {
    this.setState({ requesttoDate: e.target.value });
  }
  changeScheduleFrom(e) {
    this.setState({ schedulefromDate: e.target.value });
  }
  changeScheduleTo(e) {
    this.setState({ scheduletoDate: e.target.value });
  }
  closeCalender(type, event) {
    if (type == "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else if (type == "to") {
      this.setState({
        calenderTo: "none"
      })
    } else if (type == "schedulefrom") {
      this.setState({
        schedulecalenderFrom: "none"
      })
    } else if (type == "scheduleto") {
      this.setState({
        schedulecalenderTo: "none"
      })
    }
  }
  displayCalender(type, event) {
    var calenderFrom = "";
    var calenderTo = "";
    if (type == "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }
    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }
  displayScheduleCalender(type, event) {
    var schedulecalenderFrom = "";
    var schedulecalenderTo = "";
    if (type == "schefrom") {
      schedulecalenderFrom = "block";
      schedulecalenderTo = "none";
    } else {
      schedulecalenderTo = "block";
      schedulecalenderFrom = "none";
    }
    this.setState({
      schedulecalenderFrom: schedulecalenderFrom,
      schedulecalenderTo: schedulecalenderTo
    })
  }
  //change function for chargepoint status filter
  onChangeChargepoint() {
    
    var chargePoint = document.getElementById("chargePointId").value;

    this.setState({ chargePointId: chargePoint }, () => {
      this.getReservations(this.state.pageSize, 1);
    });


    if (this.state.fields.chargePointId == "") {
      this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
      $("#notexists").text(" ");

    } else {
      this.state.errors["entercharge"] = " "
      this.getReservations(this.state.pageSize, 1, "click");
    }

  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.activePage = 1;
    this.state.pageSize = entries;
    this.getReservations(entries, 1);
  }
  handlePageChange(pageNumber) {
    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getReservations(this.state.pageSize, pageNumber);
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }
  getReservations(pageSize, pageNo, action) {
    let chargePointId = this.state.fields.chargePointId

    var url = baseUrl.URLPath + "reservations?chargePointId=" + chargePointId + "&idTag=" + this.state.id + "&reqStDate=" + this.state.startDate1 + "&reqEndDate=" + this.state.startDate2 + "&scheStDate=" + this.state.startDate3 + "&scheEndDate=" + this.state.startDate4 + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    //localhost:8080/api/reservations?chargePointId={}&idTag={}&reqStDate={}&reqEndDate={}&scheStDate={}&scheEndDate={}&pageNo={}&pageSize={}


    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {
          if (response.count.count == 0) {
            if (action == "click" || action == "enter") {
              $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
            }
            this.setState({
              items: [],
              noOfRecords: 0
            })
          } else {
            this.setState({
              items: response.reservations,
              noOfRecords: response.count.count

            })
            if (action == "click" || action == "enter") {
              $("#notexists").text(" ");
            }
          }
        }


      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    // });
  }
  componentDidMount() {
    var url = baseUrl.URLPath + "ocpptags/all"

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
      
        if (response != undefined) {
          this.setState({ tagList: response.data })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

    let reqfromDate = moment().subtract(29, 'd').format('YYYY-MM-DD');
    let reqtoDate = moment().format('YYYY-MM-DD');

    let startdate = moment().subtract(29, 'd');
    let enddate = moment();

    this.state.requestfromDate = this.formatCalDate1(startdate);
    this.state.requesttoDate = this.formatCalDate1(enddate);

    // this.state.schedulefromDate = this.formatCalDate1(startdate);
    // this.state.scheduletoDate = this.formatCalDate1(enddate);

    this.state.startDate1 = reqfromDate;
    this.state.startDate2 = reqtoDate;
    // this.state.startDate3 = reqfromDate;
    // this.state.startDate4 = reqtoDate;
    this.setState({
      startDate1: reqfromDate
    })

    this.getReservations(this.state.pageSize, this.state.activePage);
  }
  idTagChange() {

    var id = document.getElementById("tagList").value;
    let fields = this.state.fields;
    fields["idTag"] = id;

    this.setState({ id: id }, () => {
      this.getReservations(this.state.pageSize, 1);
    });




  }
  // get date 
  requestfromdateChange = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ requestfromDate: fdate });
    this.state.startDate1 = this.formatCalDate(date);
    // if (dateRangeValidate(this.state.startDate1, this.state.startDate2) == true) {
    //   $('.date_range_error').text('');
      this.getReservations(this.state.pageSize, 1);
      var calenderFrom = "none";
      this.setState({
        calenderFrom: calenderFrom
      })
      var xyz = this.state.requestfromDate;
      this.setState({ minRequetsDate: xyz });
    // } else {
    //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var calenderFrom = "none";
    //   this.setState({
    //     calenderFrom: calenderFrom
    //   })
    // }
  };
  requesttodateChange = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ requesttoDate: fdate });
    this.state.startDate2 = this.formatCalDate(date);
    // if (dateRangeValidate(this.state.startDate1, this.state.startDate2) == true) {
    //   $('.date_range_error').text('');
      this.getReservations(this.state.pageSize, 1);
      var calenderTo = "none";
      this.setState({
        calenderTo: calenderTo
      })
    // } else {
    //   $('.date_range_error').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var calenderTo = "none";
    //   this.setState({
    //     calenderTo: calenderTo
    //   })
    // }
  };
  schedulefromChange = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ schedulefromDate: fdate });
    this.state.startDate3 = this.formatCalDate(date);
    // if (dateRangeValidate(this.state.startDate3, this.state.startDate4) == true) {
    //   $('.date_range_error1').text('');
      this.getReservations(this.state.pageSize, 1);
      var schedulecalenderFrom = "none";
      this.setState({
        schedulecalenderFrom: schedulecalenderFrom
      })
      var saveSchedulData = this.state.schedulefromDate;
      this.setState({ minScheduleDate: saveSchedulData });
    // } else {
    //   $('.date_range_error1').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var schedulecalenderFrom = "none";
    //   this.setState({
    //     schedulecalenderFrom: schedulecalenderFrom
    //   })
    // }

  };
  scheduletoChange = date => {
    var fdate = this.formatCalDate1(date);
    this.setState({ scheduletoDate: fdate });
    this.state.startDate4 = this.formatCalDate(date);
    // if (dateRangeValidate(this.state.startDate3, this.state.startDate4) == true) {
    //   $('.date_range_error1').text('');
      this.getReservations(this.state.pageSize, 1);
      var schedulecalenderTo = "none";
      this.setState({
        schedulecalenderTo: schedulecalenderTo
      })
    // } else {
    //   $('.date_range_error1').text(`Please select date range between ${localStorage.getItem('dateRangeDuration')} days`);
    //   var schedulecalenderTo = "none";
    //   this.setState({
    //     schedulecalenderTo: schedulecalenderTo
    //   })
    // }

  };
  formatCalDate1(date) {
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY")
    return xyz;
  }

  formatCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }


  formatData(date) {
    var currentDate = date;
    var twoDigitMonth = ((currentDate.getMonth() + 1) >= 10) ? (currentDate.getMonth() + 1) : '0' + (currentDate.getMonth() + 1);
    var twoDigitDate = ((currentDate.getDate()) >= 10) ? (currentDate.getDate()) : '0' + (currentDate.getDate());
    var twominDate = ((currentDate.getMinutes()) >= 10) ? (currentDate.getMinutes()) : '0' + (currentDate.getMinutes());
    var twohoursDate = ((currentDate.getHours()) >= 10) ? (currentDate.getHours()) : '0' + (currentDate.getHours());
    var createdDateTo = twoDigitMonth + "-" + twoDigitDate + "-" + currentDate.getFullYear() + ' ' + twohoursDate + ':' + twominDate;
    var csFormatDate = currentDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate + "T" + twohoursDate + ":" + twominDate + ":00Z";
  }

  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.chargePointid = document.getElementById('chargePointId')
    this.setState({ fields });
    if (e.target.value == "") {
      this.getReservations(this.state.pageSize, 1);
    }
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");

  }
  keyPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ chargePointId: e.target.value });
      this.getReservations(this.state.pageSize, 1);

      if (this.state.fields.chargePointId == "") {
        this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
        $("#notexists").text(" ");


      } else {
        this.state.errors["entercharge"] = " "
        this.getReservations(this.state.pageSize, 1, "enter");
      }
    }

    if (key == "Delete") {
      window.location.reload();
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9., -]");
        var key = e.key;
        if (!regex.test(key)) {

          e.preventDefault();

          return false;


        }
        break;

    }

  }


  exportReservationCSV() {
    var timezone = moment().tz(moment.tz.guess()).format('z');
    var url = baseUrl.URLPath + "reservations/download/CSV?chargePointId=" + this.state.fields.chargePointId + "&idTag=" + this.state.id + "&reqStDate=" + this.state.startDate1 + "&reqEndDate=" + this.state.startDate2 + "&scheStDate=" + this.state.startDate3 + "&scheEndDate=" + this.state.startDate4;
    //localhost:80/cpms/api/reservations/download/CSV?chargePointId=&idTag=&reqStDate=&reqEndDate=&scheStDate=&scheEndDate=
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": timezone
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.blob();
        }
      })
      .then((response) => {

        if (response != undefined) {
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Reservations_' + dformat + '.csv';
          a.click();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })

  }

  //function for sorting table data on click
  ascendingOrder() {
    // this.setState({items : this.state.items.reverse()})

    if (this.state.cpIdSorting == false) {
      this.state.items.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = true;
      this.setState({ items: this.state.items.sort(this.dynamicSort("chargePointId")) })
    }
    else {
      this.state.items.sort(this.dynamicSort("chargePointId"));
      this.state.cpIdSorting = false;
      this.setState({ items: this.state.items.reverse() });
    }
  }
  rfidSorting() {

    if (this.state.rfIdSorting == false) {
      this.state.items.sort(this.dynamicSort("idTag"));
      this.state.rfIdSorting = true;
      this.setState({ items: this.state.items.sort(this.dynamicSort("idTag")) })
    }
    else {
      this.state.items.sort(this.dynamicSort("idTag"));
      this.state.rfIdSorting = false;
      this.setState({ items: this.state.items.reverse() });
    }
  }
  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {

        return a[property].localeCompare(b[property]);
      }
    }
  }
  render() {


    const{t}=this.props
    const idtagList = this.state.tagList.map((tag, index) => {

      return (
        <>
          {
            (tag.trim() != 0 || tag.trim() != "") ? <option value={tag}>{tag}</option> : null
          }

        </>
      )

    });


    const reservationData = this.state.items.map((item, index) => {
      // var time = item.createdTime.split(" ");
      var status_value = "Rejected";

      if (item.createdDate == null || item.createdDate == "") {
        var createdDate = "";
        var createdDate1 = "";
      } else {
        var createdDate = moment(item.createdDate).format("DD MMM YYYY");
        var createdDate1 = moment(item.createdDate).format("HH:mm");
      }

      if (item.scheduleDate == null || item.scheduleDate == "") {
        var scheduleDate = "";
        var scheduleDate1 = "";
      } else {
        var scheduleDate = moment(item.scheduleDate).format("DD MMM YYYY");
        var scheduleDate1 = moment(item.scheduleDate).format("HH:mm");
      }

      if (item.expiryTime == null || item.expiryTime == "") {
        var expiryTime = "";
        var expiryTime1 = "";
      } else {
        var expiryTime = moment(item.expiryTime).format("DD MMM YYYY");
        var expiryTime1 = moment(item.expiryTime).format("HH:mm");
      }

      return (
        <tr>
          <td className="">{item.reservationId}</td>
          <td className="">{item.idTag}</td>
          <td className="">{item.chargePointId}</td>
          <td className="">{item.connectorId}</td>
          <td className="wrap-word">{createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>}</td>
          <td className="wrap-word">{scheduleDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {scheduleDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {scheduleDate1} </div> </>}</td>
          <td className="wrap-word">{expiryTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {expiryTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {expiryTime1} </div> </>}</td>
          <td className="">{item.reservationStatus}</td>

        </tr>
      )
    });

    return (
      <>


        <main class="content-div" >
          <p> {t('Reservations')}</p>
          <div className="page-outerdiv">
            {/* table start */}
            <div className="row mt-1 ">
              <div className="col-md-3">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onChange={this.changeHandler.bind(this, "chargePointId")} onKeyDown={this.keyPress} class="form-control input-searchBox pad-2" placeholder={t('charge_point_id')} name="srch-term" />
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                  </div>
                </div>
                <span className="error_msg w3-animate-top mt-2">  <span style={{ color: "red" }}>{this.state.errors["entercharge"]}</span></span>
                <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
              </div>
              <div className="col-md-3 p-rel">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('request_from')} name="srch-term" id="requestfromDate" value={this.state.requestfromDate} onChange={this.changeRequestFrom} onKeyDown={this.keyPress1} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'from')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.calenderFrom }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'from')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.requestfromDate}
                    onChange={date => this.requestfromdateChange(date)}
                    value={activeDateFormat(this.state.requestfromDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    onBlur={this.closeCalender.bind(this, 'from')}
                    // maxDate={new Date()}
                    maxDate={(this.state.requesttoDate != "" && this.state.requesttoDate != null) ? new Date(this.state.requesttoDate) : new Date()}
                  />
                </div>
                <span className="pull-left future_err1 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>

              <div className="col-md-3 p-rel">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('request_to')} name="srch-term" id="srch-term" value={this.state.requesttoDate} onChange={this.changeRequestTo} onKeyDown={this.keyPress2} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayCalender.bind(this, 'to')}> <i className="fas fa-calendar-alt fa-sm"></i></button>                      </div>
                </div>
                <div style={{ display: this.state.calenderTo }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'to')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.requesttoDate}
                    onChange={date => this.requesttodateChange(date)}
                    value={activeDateFormat(this.state.requesttoDate)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(this.state.requestfromDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err2 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>
              <div className="col-md-3">
                <button className="mt-2 btn_primary pull-right" onClick={this.exportReservationCSV} type="button" data-set="button">
                  {t('export')}
                  <i className="fas fa-file-export ml-2"></i>
                </button>
              </div>
              <div className='col-md-12 date_range_error' style={{ color: "red", textAlign: "center" }}></div>
              <MDBCol md="3" className="mt-2" >
                <select className="browser-default custom-select select_height mb_8" id="tagList" onChange={this.idTagChange.bind(this)}>
                  <option value="">-- {t('select_rfid')} --</option>
                  {idtagList}
                </select>
                <span className="error_msg w3-animate-top">  <span style={{ color: "red" }} ></span></span>
              </MDBCol>

              <div className="col-md-3 p-rel mt-3">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('schedule_from')} name="srch-term" id="srch-term" value={this.state.schedulefromDate} onChange={this.changeScheduleFrom} onKeyDown={this.keyPress3} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayScheduleCalender.bind(this, 'schefrom')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.schedulecalenderFrom }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'schedulefrom')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.schedulefromDate}
                    onChange={date => this.schedulefromChange(date)}
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeIntervals={1}
                    //className="cal_margin tag_cal"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    maxDate={(this.state.scheduletoDate != "" && this.state.scheduletoDate != null) ? new Date(this.state.scheduletoDate) : new Date()}
                  />
                </div>
                <span className="pull-left future_err3 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>

              <div className="col-md-3 p-rel mt-3">
                <div class="input-group">
                  <input type="text" class="form-control input-searchBox pl-3" placeholder={t('schedule_to')} name="srch-term" id="srch-term" value={this.state.scheduletoDate} onChange={this.changeScheduleTo} onKeyDown={this.keyPress4} />
                  <div class="input-group-btn">
                    <button class="input-searchBtn" type="submit" onClick={this.displayScheduleCalender.bind(this, 'scheto')} ><i className="fas fa-calendar-alt fa-sm"></i></button>
                  </div>
                </div>
                <div style={{ display: this.state.schedulecalenderTo }} className="calenderContainer">
                  <div onClick={this.closeCalender.bind(this, 'scheduleto')}><i class="far fa-times-circle"></i></div>
                  <Calendar
                    selected={this.state.scheduletoDate}
                    onChange={date => this.scheduletoChange(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    //className="cal_margin tag_cal"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(this.state.schedulefromDate)}
                    maxDate={new Date()}
                  />
                </div>
                <span className="pull-left future_err4 w3-animate-top mt-1" style={{ color: "red" }}></span>
              </div>

              <div className="col-md-3 mt-3">

                <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-right ml-0 selectdiv" >
                  <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
                <label className="sub-text pull-right" >{t('show_entries')}</label>
              </div>
              <div className='col-md-12 date_range_error1' style={{ color: "red", textAlign: "center" }}></div>
            </div>
            <div className="row">

              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">

                  <thead>
                    <tr>
                      <th className="">{t('reservationId')}</th>
                      <th className="">{t('idTag')} <i class="fas fa-sort sortIcon" onClick={this.rfidSorting.bind(this)}></i></th>
                      <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('connector_id')}</th>
                      <th className="">{t('request_date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('schedule_date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('expiry_date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('chargingSessionState')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reservationData}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
            {/* End table  */}
          </div>

        </main>
      </>
    );
  }
}

export default withTranslation()(reservations);