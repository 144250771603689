import React from 'react';
import {createBrowserHistory} from 'history';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import {Form,Table,Check } from 'react-bootstrap';
import moment from "moment";

const history=createBrowserHistory();

export default class NotificationDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={
            notificationsList:[],
            reqMessage:'',
            resMessage:'',
            viewsession: false
        }
        this.viewModal=this.viewModal.bind(this);
        this.viewsession=this.viewsession.bind(this);
    }
    componentDidMount(){
        let dataObject=JSON.parse(localStorage.getItem("notificationObject"));
        let data=[];
        data.push(dataObject);
        this.setState({
            notificationsList: data
        });
    }
    viewModal(reqMessage,resMessage){
        this.state.reqMessage=reqMessage;
        this.state.resMessage=resMessage;
        this.viewsession();
    }
    async viewsession(){
        this.setState({
            viewsession: !this.state.viewsession
        });
    }
    backToList(){
        window.location.replace("/notifications");
        localStorage.setItem("notificationObject",JSON.stringify({}));
    }
    render(){
        return(
            <main className="content-div">
                <p>Notification</p>
                <div className="page-outerdiv">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pull-left">
                                <button className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <Table striped hover size="sm" className="page-table">
                                <thead>
                                    <tr>
                                        <th style={{width: "5%"}}></th>
                                        <th style={{width: "11%"}}>Charge Point ID</th>
                                        <th style={{width: "11%"}}>Model</th>
                                        <th style={{width: "10%"}}>FW Version</th>
                                        <th style={{width: "8%"}}>Notification Id</th>
                                        <th style={{width: "40%"}}>Notification Description</th>
                                        <th style={{width: "10%"}}>Created Date</th>
                                        <th style={{width: "5%"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.notificationsList.map((notification,index)=>{
                                            if(notification.createdDate==null || notification.createdDate==""){
                                                var createdDate = "";
                                                var createdDate1="";
                                              }else{
                                                var createdDate = moment(notification.createdDate).format("DD MMM YYYY");
                                                var createdDate1 = moment(notification.createdDate).format("HH:mm");
                                              }
                                            return(
                                                <tr>
                                                    <td className="text-center">
                                                        <Form.Check
                                                            custom
                                                            type="checkbox"
                                                            id={`custom-${index}`}
                                                            label=""
                                                            onChange=""
                                                            key={`custom-${index}`}
                                                        />
                                                    </td>
                                                    <td>{notification.chargePointId}</td>
                                                    <td>{notification.model}</td>
                                                    <td>{notification.fwVersion}</td>
                                                    <td>{notification.notificationId}</td>
                                                    <td>{notification.notificationDescription}</td>
                                                   <td className="wrap-word">{ createdDate && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdDate}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdDate1} </div> </>}</td>
                                                    <td>
                                                        <i className="fas fa-eye action-icon mt-1 ml-2" title="View" style={{ cursor: 'pointer' }} onClick={this.viewModal.bind(this,notification.requestMessage,notification.responseMessage)}></i>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

                <MDBModal isOpen={this.state.viewsession} toggle={this.viewsession} size="lg" className="model_top">
                    <MDBModalBody>
                        <MDBRow className="mb-4 mt-3" >
                            <MDBCol sm="12">
                                <div className="view-bg-row" >
                                    <label className=""><b>Request Message: </b></label>
                                    <label className="wrap-word">{this.state.reqMessage}</label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-4 mt-3" >
                            <MDBCol sm="12">
                                <div className="view-bg-row" >
                                    <label><b>Response Message: </b></label>
                                    <label className="wrap-word">{this.state.resMessage}</label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button onClick={this.viewsession} type="button" data-test="button" className="btn_primary">Close</button>
                    </MDBModalFooter>
                </MDBModal>

            </main>
        );
    }
}