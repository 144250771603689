import React from 'react';
import { MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import * as baseUrl from './constants';
import moment from 'moment';
import $ from "jquery";
import CsOperationsModal from '../common/CsOperationsModal';
import servicesUrl from '../common/servicesUrl';

import { withTranslation } from 'react-i18next';

class chargePointSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      dataArray: [],
      cpList: [],
      empty: [],
      sucess: false,
      paginationHide: false,
      colour: '',
      message: 'Multiple charge points not supported for this operation.',
      isCheckBox: true,
      style: "block",
      check_type: "",
      buttondisplay: "",
      multiple: false,
      errors: {},
      intial_data: [],
      options: [],
      saveSelectedCpid: "",
      selectFindStatus: false,
      pageSize: 30,
      pageNo: 1,
      prevPageFirstCreTime: '',
      curPageFirstCreTime: '',
      curPageLastCreTime: '',
      activePage: 1,
      noOfRecords: '',
      modalsession: false,
      selectedCpIds: [],
      fromDateApi: '',
      toDateApi: '',
      listOfFirstCreatedtimes: [],
      ModelList: [],
      selectedModel: ''
    }
    this.onShowAlert = this.onShowAlert.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeCpId = this.removeCpId.bind(this);
    this.getChargePoints = this.getChargePoints.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.chargepointIDChange = this.chargepointIDChange.bind(this);
    this.doneFunction = this.doneFunction.bind(this);
    this.singleChargePointChange = this.singleChargePointChange.bind(this);
    this.getChargePointsForPartialSearch = this.getChargePointsForPartialSearch.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.ClearChargepoint = this.ClearChargepoint.bind(this);
    this.FindCpName = this.FindCpName.bind(this);
    this.modelOnChange = this.modelOnChange.bind(this);
    this.getModelsData = this.getModelsData.bind(this);
    this.getConfigKey = this.getConfigKey.bind(this);
  }

  onShowAlert() {


    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 5000)
    });

  }

  componentWillMount() {

    var location = window.location.href;
    var page = location.split("/")[3];
    if (page == 'setChargingProfile' ||page == 'remoteStartTransaction' || page == "remoteStopTransaction" || page == "reserveNow" || page == "cancelReservation" || page == "unlockConnector" || page == "sendLocalList") {
      this.setState({ isCheckBox: false, style: "none" });
    } else {
      this.setState({ style: "block" });
    }

  }

  setSelectedStatus() {
    var location = window.location.href;
    var page = location.split("/")[3];
    if (page == 'remoteStartTransaction' || page == "remoteStopTransaction" || page == "reserveNow" || page == "cancelReservation" || page == "unlockConnector" || page == "sendLocalList") {
      if (this.state.cpList.length > 0) {
        document.getElementById('custom-' + this.state.cpList[0]).checked = true;
      }


    } else {
      for (var i = 0; i < this.state.cpList.length; i++) {
        var fdfdd = this.state.cpList[i];
        var txt = document.getElementById("idStatus").innerHTML;
        txt += "<span class='chargepoint_span' id=chargepoint_span" + this.state.cpList[i] + ">" +
          this.state.cpList[i] + "<i class='fa fa-times' id=" + this.state.cpList[i] + " )}></i></span>";
        document.getElementById("idStatus").innerHTML = txt;
        //  document.getElementById(this.state.cpList[i]).onclick = function() {this.myfun()};
      }
    }

  }

  singleChargePointChange(id, e) {
    this.setState({ cpList: [id], check_type: "Radio" });
  }

  handleChange(id, e) {
    let isChecked = document.getElementById(`custom-${id}`).checked;

    // setter
    localStorage.setItem('chargePointId', id);

    if (isChecked) {
      let cpList = this.state.cpList;
      cpList.push(id);

      this.setState({
        ...this.state,
        cpList: cpList
      });
    } else {
      var idList = this.state.cpList;
      var index = this.state.cpList.indexOf(id);;

      idList.splice(index, 1);
      this.setState({ cpList: idList });
    }
  }

  removeCpId(id) {
    var idList = this.state.cpList;
    var index = this.state.cpList.indexOf(id);;

    idList.splice(index, 1);
    this.setState({ cpList: idList });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    }, () => {
      if (this.state.selectedModel != "") {
        this.getModelsData();
      } else {
        this.getChargePoints();
      }
    });
  }

  doneFunction() {
    if (this.props.operationName == "ChangeConfiguration") {
      this.getConfigKey(this.state.cpList)
    } else {
      let errors = {};
      errors["chargePointNotExists"] = "";
      const { data } = this.props;
      data(this.state.cpList);
      this.setState({
        modal: !this.state.modal,
        activePage: 1,
        pageSize: 30,
        noOfRecords: '',
        fromDateApi: '',
        toDateApi: '',
        saveSelectedCpid: '',
        listOfFirstCreatedtimes: [],
        errors: errors
      });
    }
  }

  getConfigKey(cpList) {
    let errors = {};
    errors["chargePointNotExists"] = "";

    var url = baseUrl.URLPath + "chargepoints/keylist";
    var data = { "cpList": cpList }

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        if (response.status == 404) {
          this.props.toggleConfigError(response.message);
        } else if (response != undefined && response.length > 0) {
          this.props.updateConfigurekeys(response);
          const { data } = this.props;
          data(this.state.cpList);
          this.setState({
            modal: !this.state.modal,
            activePage: 1,
            pageSize: 30,
            noOfRecords: '',
            fromDateApi: '',
            toDateApi: '',
            saveSelectedCpid: '',
            listOfFirstCreatedtimes: [],
            errors: errors
          });
        } else {
          this.props.updateConfigurekeys([]);
          const { data } = this.props;
          data(this.state.cpList);
          this.setState({
            modal: !this.state.modal,
            activePage: 1,
            pageSize: 30,
            noOfRecords: '',
            fromDateApi: '',
            toDateApi: '',
            saveSelectedCpid: '',
            listOfFirstCreatedtimes: [],
            errors: errors
          });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  closetoggle = () => {
    let errors = {};
    errors["chargePointNotExists"] = "";
    this.setState({
      modal: !this.state.modal,
      cpList: [],
      activePage: 1,
      pageSize: 30,
      noOfRecords: '',
      fromDateApi: '',
      toDateApi: '',
      saveSelectedCpid: '',
      listOfFirstCreatedtimes: [],
      errors: errors,
      selectedModel: ''
    },()=>{
      const { data } = this.props;
      data(this.state.cpList);
    });
  }

  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }

  getChargePoints(sno, countValue, dates, type) {
    document.getElementById("loader_image_div").style.display = "block";
    var opeName = this.props.operationName;
    let fromTime = this.state.fromDateApi;
    let toTime = this.state.toDateApi;
    let pageNo = this.state.pageNo;

    if (dates == "no") {
      fromTime = '';
      toTime = '';
    }

    if (type == "search") {
      pageNo = 1;
    }

    let cpId = '';
    if (sno != undefined) {
      cpId = sno
    }

    //ChangeConfiguration, GetConfiguration

    let url = "";

    if (opeName == "ChangeConfiguration" | opeName == "GetConfiguration") {
      url = baseUrl.URLPath + servicesUrl.getChargePointCplistWithModels+"?operationName=" + opeName + "&pageSize=" + this.state.pageSize + "&chargePointId=" + cpId + "&pageNo=" + pageNo + "&fromTime=" + fromTime + "&toTime=" + toTime;
    } else {
      url = baseUrl.URLPath + servicesUrl.getCPchargepointids + "?operationName="+ opeName +"&pageSize=" + this.state.pageSize + "&chargePointId=" + cpId + "&pageNo=" + pageNo+ "&fromTime=" + fromTime + "&toTime=" + toTime;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
          }, 100);
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          if (response.count.count == 0) {
            let errors = {};
            errors["chargePointNotExists"] = this.props.t('charge_point_not_exists');
            setTimeout(() => {
              this.setState({
                errors: errors
              }, () => {
                document.getElementById("loader_image_div").style.display = "none";
              });
            }, 100);

          } else {
            let hide = false
            if (type == "search") {
              hide = true
            }else{
              hide = false
            }
            if (opeName == "ChangeConfiguration" | opeName == "GetConfiguration") {
              if (countValue == false) {
                setTimeout(() => {
                  this.setState({
                    dataArray: response.cpList,
                    paginationHide: hide,
                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                    curPageFirstCreTime: moment(response.cpList[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                    curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                  }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                  });
                }, 100);
              } else {
                setTimeout(() => {
                  this.setState({
                    dataArray: response.cpList,
                    ModelList: response.modelList,
                    paginationHide: hide,
                    noOfRecords: response.count.count,
                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                    curPageFirstCreTime: moment(response.cpList[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                    curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                  }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                  });
                }, 100);
              }
            } else {
              if (countValue == false) {
                setTimeout(() => {
                  this.setState({
                    dataArray: response.data,
                    paginationHide: hide,
                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                    curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                    curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                  }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                  });
                }, 100);
              } else {
                setTimeout(() => {
                  this.setState({
                    dataArray: response.data,
                    noOfRecords: response.count.count,
                    paginationHide: hide,
                    prevPageFirstCreTime: this.state.curPageFirstCreTime,
                    curPageFirstCreTime: moment(response.data[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                    curPageLastCreTime: moment(response.data[response.data.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                  }, () => {
                    document.getElementById("loader_image_div").style.display = "none";
                  });
                }, 100);
              }
            }

            var option = [];
            for (var i = 0; i < this.state.dataArray.length; i++) {
              option.push(this.state.dataArray[i].chargePointId);
            }

            this.setState({ options: option });
          }
        }
      })
      .catch((error) => {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          console.log(error, "catch the loop")
        }, 100);
      })
  }

  // search filter 
  handleKeyDown(ref, e) {
    var serial_no = e.target.value;

    let errors = this.state.errors;
    errors["selectChargepoint"] = "";
    errors["chargePointNotExists"] = "";

    this.setState({
      errors: errors
    })
    
    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();
    }
    var sno = serial_no.slice(0, serial_no.length - 1)
    var key = e.key;
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key == 17) ? true : false);

    this.getChargePointsForPartialSearch(sno)
    if (key == "Backspace" || key == "Delete") {

      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {
        this.setState({
          saveSelectedCpid: sno,
          pageNo: 1
        },()=>{
          this.getChargePointsForPartialSearch(sno);
          this.getChargePoints();
        })
      }
    }
    if (e.keyCode == 13) {
      $("#selectcharge").hide();
      this.FindCpName(ref, e);
      $("#rbt_id").hide();
    }
    if ((e.keyCode == 86 && ctrl)) {
      $("#selectcharge").hide();
      this.getChargePointsForPartialSearch(serial_no)
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9 -]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }

  FindCpName(ref, event) {
    event.preventDefault();
    this.state.saveSelectedCpid = ref.current.inputNode.value;
    var errors = {};
    if (this.state.saveSelectedCpid == null || this.state.saveSelectedCpid == "") {
      errors["selectChargepoint"] = this.props.t('select_the_charge_point');
      this.setState({
        errors: errors
      });
    } else {
      errors["selectChargepoint"] = "";
      this.state.selectFindStatus = true;
      this.getChargePoints(this.state.saveSelectedCpid, "true", "no",'search');
    }

    var serial_no = event.target.value;
    if (serial_no.length > 1) {
      $("#selectcharge").hide();
    } else {
      $("#selectcharge").show();
    }

  }

  chargepointIDChange(cpid) {
    this.state.selectFindStatus = true;
    this.setState({ saveSelectedCpid: cpid }, () => {
      if (cpid.length == 0) {
        this.getChargePoints(cpid, "true", "no");
      } else {
        let errors = {};
        errors["chargePointNotExists"] = "";
        errors["selectChargepoint"] = "";
        this.setState({
          errors: errors
        });
        this.getChargePoints(cpid, "true", "no");
      }
    })
  }

  getChargePointsForPartialSearch(cpid) {
    var opeName = this.props.operationName;

    let cpId = '';
    if (cpid != undefined) {
      cpId = cpid
    }

    var url = baseUrl.URLPath + servicesUrl.getCPchargepointidsPartial + "?operationName="+ opeName +"&chargePointId=" + cpId;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        
        this.setState({ options: response }, () => {
          if (response.length == 0) {
            let errors = {};
            // errors["chargePointNotExists"] = "Charge Point Not Exists";
            this.setState({
              errors: errors
            });
          }
        });
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  ClearChargepoint(ref, event) {
    if (this.state.saveSelectedCpid != "") {
      this.state.selectFindStatus = false;
      this.state.activeArray = "";
      this.state.saveSelectedCpid = "";
      let errors = this.state.errors;
      if(errors["chargePointNotExists"]!="" || errors["selectChargepoint"]!= ""){
        errors["chargePointNotExists"] = "";
        errors["selectChargepoint"] = "";
        this.setState({
          errors: errors
        });
      }else{
        this.state.activePage = 1;
        this.state.pageNo = 1;
        this.state.fromDateApi = '';
        this.state.toDateApi = '';
        errors["chargePointNotExists"] = "";
        errors["selectChargepoint"] = "";
        this.setState({
          errors: errors
        }, () => {
          if (this.state.selectedModel != "") {
            this.getModelsData();
          } else {
            this.getChargePoints();
          }
        });
      }
      
      ref.current.clear();
    } else {
      let errors = this.state.errors;
      errors["chargePointNotExists"] = "";
      errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      })
      ref.current.clear();
    }
  }

  //show entries change
  showEntriesChange() {
    var entries = document.getElementById("cpshowEntries").value;
    this.state.activePage = 1;
    this.state.pageSize = entries;
    this.state.pageNo = 1;
    if (this.state.selectedModel != "") {
      this.getModelsData(this.state.saveSelectedCpid);
    } else {
      this.getChargePoints(this.state.saveSelectedCpid,true,'no');
    }
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    }, () => {
      console.log(this.state.listOfFirstCreatedtimes);
      if (this.state.selectedModel != "") {
        this.getModelsData("", false);
      } else {
        this.getChargePoints("", false, "");
      }
    });
  }

  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
      //prev
      if (pageNumber == 1) {
        this.setState({
          activePage: pageNumber,
          pageNo: pageNumber,
          fromDateApi: '',
          toDateApi: ''
        }, () => {
          if (this.state.selectedModel != "") {
            this.getModelsData("", false);
          } else {
            this.getChargePoints("", false, "");
          }
        });
      } else {
        this.setState({
          activePage: pageNumber,
          pageNo: pageNumber,
          fromDateApi: this.state.curPageFirstCreTime,
          toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
        }, () => {
          if (this.state.selectedModel != "") {
            this.getModelsData("", false);
          } else {
            this.getChargePoints("", false, "");
          }
        });
      }
    } else {
      //next
      this.setState({
        activePage: pageNumber,
        pageNo: pageNumber,
        fromDateApi: '',
        toDateApi: this.state.curPageLastCreTime,
        prevPageFirstCreTime: this.state.curPageFirstCreTime,
      }, () => {
        let listOfFirstCreatedtimes = this.state.listOfFirstCreatedtimes;
        listOfFirstCreatedtimes[pageNumber - 2] = this.state.curPageFirstCreTime;
        this.setState({
          listOfFirstCreatedtimes: listOfFirstCreatedtimes
        }, () => {
          console.log(this.state.listOfFirstCreatedtimes);
          if (this.state.selectedModel != "") {
            this.getModelsData("", false);
          } else {
            this.getChargePoints("", false, "");
          }
        });
      });
    }
  }

  sessiontagOk() {
    this.props.history.push('/');
    window.location.reload();
  }

  getModelsData(sno, countValue, dates) {
    document.getElementById("loader_image_div").style.display = "block";
    var opeName = this.props.operationName;
    let fromTime = this.state.fromDateApi;
    let toTime = this.state.toDateApi;

    if (dates == "no") {
      fromTime = '';
      toTime = '';
    }

    let cpId = '';
    if (sno != undefined) {
      cpId = sno
    }

    let url = "";

    url = baseUrl.URLPath +servicesUrl.getChargePointCplistByModelOrCPId+ "?operationName="  + opeName + "&pageSize=" + this.state.pageSize + "&chargePointId=" + cpId + "&pageNo=" + this.state.pageNo + "&fromTime=" + fromTime + "&toTime=" + toTime + "&model=" + this.state.selectedModel;

    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        } else {
          return resp.json();
        }
      })
      .then((response) => {
        if (countValue == false) {
          setTimeout(() => {
            this.setState({
              dataArray: response.cpList,
              prevPageFirstCreTime: this.state.curPageFirstCreTime,
              curPageFirstCreTime: moment(response.cpList[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
            }, () => {
              var option = [];
              for (var i = 0; i < this.state.dataArray.length; i++) {
                option.push(this.state.dataArray[i].chargePointId);
              }
              this.setState({ options: option });
              document.getElementById("loader_image_div").style.display = "none";
            });
          }, 100);
        } else {
          setTimeout(() => {
            this.setState({
              dataArray: response.cpList,
              noOfRecords: response.count.count,
              prevPageFirstCreTime: this.state.curPageFirstCreTime,
              curPageFirstCreTime: moment(response.cpList[0].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              curPageLastCreTime: moment(response.cpList[response.cpList.length - 1].modifiedDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
            }, () => {
              var option = [];
              for (var i = 0; i < this.state.dataArray.length; i++) {
                option.push(this.state.dataArray[i].chargePointId);
              }

              this.setState({ options: option });
              document.getElementById("loader_image_div").style.display = "none";
            });
          }, 100);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          console.log(error, "catch the loop");
          document.getElementById("loader_image_div").style.display = "none";
        }, 100);
      })
  }

  modelOnChange() {
    var x = document.getElementById("modelList").value;
    var selectedModel = "";

    if (x != "") {
      selectedModel = x;
      this.setState({ cpList: [], selectedModel: x }, () => {
        this.state.activePage = 1;
        this.getModelsData("", true, "no")
      })

      const { data } = this.props;
      data([]);
    } else {
      selectedModel = null;
      const { data } = this.props;
      data([]);
      this.setState({ cpList: [], selectedModel: '' }, () => {
        this.state.activePage = 1;
        this.getChargePoints("", true, "no");
      })
    }
  }

  render() {
    const{t}=this.props
    // condition for functionality of operations uder viewcp page
    var viewCp = this.props.cpid;
    if (viewCp == "chargePointId") {
      var Id = this.props.opData;
      this.state.buttondisplay = "none"
    } else {

    }

    var operationToggle = localStorage.getItem("operation")
    if (operationToggle == "operationMenu") {
      localStorage.removeItem("operationId")
      this.state.buttondisplay = "block"
    }

    const selectedCPId = this.state.cpList.map((cpList, index) => {
      return <span>{cpList}{(this.state.check_type == "Radio")? "":","}</span>
    });

    return (
      <>
        <MDBCol md="12" className="mb-4" >
          <span className="form-title mt-0 pb-4">{t('selected_charge_point')} *:  <button data-test="button" type="button" id="btn_image_div" style={{ display: this.state.buttondisplay }} onClick={this.toggle} className="ml-0 btn_primary pull-right" size="md">{t('selected_charge_point')}</button> </span>
          <div class="chargepoint_listdiv_out" >{selectedCPId}{Id}</div>
        </MDBCol>
        <CsOperationsModal
          operationName={this.props.operationName}
          isOpen={this.state.modal}
          cancelFunction={this.closetoggle}
          data={this.state.dataArray}
          isCheckBox={this.state.isCheckBox}
          cpList={this.state.cpList}
          cpactivePage={this.state.activePage}
          pageSize={this.state.pageSize}
          noOfRecords={this.state.noOfRecords}
          handleChange={this.handleChange}
          removeCpId={this.removeCpId}
          handlePageChange={this.handlePageChange}
          showEntriesChange={this.showEntriesChange}
          chargepointIDChange={this.chargepointIDChange}
          options={this.state.options}
          doneFunction={this.doneFunction}
          singleChargePointChange={this.singleChargePointChange}
          handleKeyDown={this.handleKeyDown}
          ClearChargepoint={this.ClearChargepoint}
          FindCpName={this.FindCpName}
          ModelList={this.state.ModelList}
          modelOnChange={this.modelOnChange}
          selectedModel={this.state.selectedModel}
          errors={this.state.errors}
          paginationHide = {this.state.paginationHide}
        />

        {/* Loader */}
        <div className="operations_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>

        {/* popup for session expire */}
        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
          <MDBModalBody>
            {t('please_login_again')}
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" data-test="button" className="btn_primary" onClick={this.sessiontagOk}>{t('ok')}</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(chargePointSelection);