export const getheaders = () => {
    var headers = {
        'Content-Type': 'application/json'
    }
    return headers;
}

export const getSecurityHeaders = () => {
    var headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("Authorization")
    }
    return headers;
}

export const getSecurityHeadersWithTimezone = () => {
    var headers = {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
        "timeZone": localStorage.getItem("timezone")
    }
    return headers;
}