import React from 'react';
import { MDBCol, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow } from 'mdbreact';
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import moment from 'moment';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import Calendar from 'react-calendar';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import $ from "jquery";
import {dateRangeValidate,activeDateFormat,getDateRange,dateRangeValidateForCPlog, dateformatinUTC} from './_methods';
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';

const history = createBrowserHistory();

class logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isCheckboxReadonly:false,
      checked:false,
      cpIdSorting:false,
      modalView: false,
      modalsession: false,
      calenderFrom: "none",
      calenderTo: "none",
      fields: {
        OperationNameC: "",

        chargePointId: ""
      },
      page_title: "CP Logs",
      collapseID: "",
      items: [

      ],
      operationData: [],
      dataArray: [
        {

          chargepointid: '1',
          address: '',
          noofattempts: '',
        },
      ],
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      messageId:'',
      fromDate:"",
      toDate:"",
      fromDateApi:"",
      toDateApi:"",
      calDate:"",
      curPageFirstCreTime:"",
      curPageLastCreTime:"",
      prevPageFirstCreTime:"",
      listOfFirstCreatedtimes:[]
    }
    this.GetFromDate = this.GetFromDate.bind(this);
    this.getOperationLog = this.getOperationLog.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClick1 = this.handleClick1.bind(this);
    this.Focus = this.Focus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.onChangeChargepoint = this.onChangeChargepoint.bind(this);
    this.togglesession = this.togglesession.bind(this);
    this.sessiontagOk = this.sessiontagOk.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.OperationNameChange = this.OperationNameChange.bind(this);
    this.toggleView1 = this.toggleView1.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.closeCalender = this.closeCalender.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.keyPress1 = this.keyPress1.bind(this);
    this.keyPress2 = this.keyPress2.bind(this);
    // this.changeScheduleFrom = this.changeScheduleFrom.bind(this);
    // this.changeScheduleTo = this.changeScheduleTo.bind(this);
    this.handleChecked=this.handleChecked.bind(this);
    this.onChangeMessageId=this.onChangeMessageId.bind(this);
    this.keyPress3 = this.keyPress3.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
  }

 
  changeScheduleFrom(e){ 
    this.setState({startDate: e.target.value});
    }
       changeScheduleTo(e){
         this.setState({toDate: e.target.value});
        }
   
       keyPress1(e){
         var key = e.key;
         $('.future_err1').text("");
         $('.date_range_error').text('');
       if(e.keyCode == 13){
        if (new Date() >= new Date(e.target.value)) {
          this.setState({ startDate: e.target.value });
          var dateFormat = moment(this.state.startDate).format("YYYY-MM-DD");
          this.state.startDate1 = dateFormat;
          if (dateRangeValidate(this.state.startDate1, this.state.startDate2) == true) {
            this.getOperationLog(this.state.pageSize, 1);
          }else{
            $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('dateRangeDuration')} ${this.props.t('days')}`);
          }
        } else {
          this.setState({ startDate: "" });
          this.state.startDate1 = "";
          $('.future_err1').text(this.props.t('cannot_select_futuredate'));
        }
         }
         if(key == "Delete" || key == "Backspace"){
           this.state.startDate1 = "";
           this.getOperationLog(this.state.pageSize,1);
         }
      }
      keyPress2(e){
       var key = e.key;
       $('.future_err2').text("");
       $('.date_range_error').text('');
     if(e.keyCode == 13){
      if (new Date() >= new Date(e.target.value)) {
        this.setState({ toDate: e.target.value });
        var dateFormat = moment(this.state.toDate).format("YYYY-MM-DD");
        this.state.startDate2 = dateFormat;
        if (dateRangeValidate(this.state.startDate1, this.state.startDate2) == true) {
          this.getOperationLog(this.state.pageSize, 1);
        }else{
          $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('dateRangeDuration')} ${this.props.t('days')}`);
        }
      } else {
        this.setState({ toDate: "" });
        this.state.startDate2 = "";
        $('.future_err2').text(this.props.t('cannot_select_futuredate'));
      }
       }
       if(key == "Delete" || key == "Backspace"){
         this.state.startDate2 = "";
         this.getOperationLog(this.state.pageSize,1);
         }
    }

  closeCalender(type, event) {
    if (type == "from") {
      this.setState({
        calenderFrom: "none"
      })
    } else {
      this.setState({
        calenderTo: "none"
      })
    }
  }

  displayCalender(type, event) {
    var calenderFrom = "";
    var calenderTo = "";
    if (type == "from") {
      calenderFrom = "block";
      calenderTo = "none";
    } else {
      calenderTo = "block";
      calenderFrom = "none";
    }
    this.setState({
      calenderFrom: calenderFrom,
      calenderTo: calenderTo
    })
  }
  async togglesession() {
 
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }
  // method to call onChange Operation Name
  OperationNameChange() {
    var id = document.getElementById("operationName").value;
    if(id=="Heartbeat"){
      this.state.isCheckboxReadonly=true;
    }else{
      this.state.isCheckboxReadonly=false;
    }
    let fields = this.state.fields;
    fields["OperationNameC"] = id;
    this.setState({ OperationNameC: id }, () => {
      this.getOperationLog(this.state.pageSize, this.state.activePage);

    });
  }

  toggleView(messageId, requestMessage, responseMessage) {
    this.setState({ displaymessage: messageId })
    this.setState({ displayReq: requestMessage })
    this.setState({ displayRes: responseMessage })
    this.setState({
      modalView: !this.state.modalView
    });
  }

  toggleView1() {
    this.setState({
      modalView: !this.state.modalView
    });
  }
  handleClick(){

    let fields = this.state.fields;
    fields.chargePointId='';
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");
    this.setState({
  fields
    });

    this.state.pageSize=10;
    this.state.activePage=1;
    document.getElementById("showEntries").value=10;
    this.getOperationLog(this.state.pageSize, this.state.activePage);
  }
  handleClick1(){
    
    this.state.messageId=""
   this.state.pageSize=10;
       this.state.activePage=1;
       document.getElementById("showEntries").value=10;
       this.getOperationLog(this.state.pageSize, 1);
       $(".error_emptymessageid").text("");
       $(".error_nomessageid").text("");
     }
  Focus(){

   
  //   let fields = this.state.fields;
  //   fields.chargePointId='';

  //   this.setState({
  // fields
  //   });

  //   this.getOperationLog(this.state.pageSize, this.state.activePage);
  }
  onChangeMessageId() {
 
    var messageId = document.getElementById("messageId").value;
    this.setState({ messageId: messageId }, () => {
      if(this.state.messageId == ""){
        $(".error_emptymessageid").text(this.props.t('enter_message_id'));
      }else{
        $(".error_emptymessageid").text("");
        this.getOperationLog(10,1,"messageClick");
      }
        
      });
      
  }
  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.chargePointid = document.getElementById('chargePointId')
    this.state.errors["entercharge"] = " "
    $("#notexists").text(" ");
    this.setState({ fields }, ()=>{
      if (this.state.fields.chargePointId.length == 0) {
        this.state.activePage = 1;
        this.getOperationLog(this.state.pageSize, 1);
      }
    });
  }
  keyPress(e) {
   
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ chargePointId: e.target.value });
      this.getOperationLog(this.state.pageSize, 1);
      if(this.state.fields.chargePointId == ""){
        this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
        $("#notexists").text(" ");
      
      
      }else{
        this.state.errors["entercharge"] = " "
        this.getOperationLog(this.state.pageSize,1,"enter");
      }
    }

   
      if( key == "Backspace" || key == "Delete" ){
      
        var x=e.target.value
        var sno=x.slice(0,x.length-1)
        if(sno==""){
          
          this.state.fields.chargePointId=sno;
    
         this.getOperationLog(this.state.pageSize, 1);

        }
        }
        switch (e.keyCode) {
          case 8:  // Backspace
          case 9:  // Tab
          case 13: // Enter
          case 37: // Left
          case 38: // Up
          case 39: // Right
          case 40: // Down
          break;
          default:
            var regex = new RegExp("^[a-zA-Z0-9., -]");
          var key = e.key;
          if (!regex.test(key)) {
          
              e.preventDefault();
      
              return false;
           
             
          }
          break;
        
      }
    }
  

  GetFromDate = date => {
    $('.future_err1').text("");
    var fdate =this.formatCalDate1(date);

    this.setState({ startDate: fdate });
    this.state.startDate1 = this.formatCalDate(date);
    if (dateRangeValidate(this.state.startDate1, this.state.startDate2) == true) {
      $('.date_range_error').text('');
      this.getOperationLog(this.state.pageSize, 1);
      var calenderFrom = "none";
      this.setState({
        calenderFrom: calenderFrom
      });
    }else{
      $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('dateRangeDuration')} ${this.props.t('days')}`);
      var calenderFrom = "none";
      this.setState({
        calenderFrom: calenderFrom
      });
    }
  };
  dateChange = date => {
    $('.future_err2').text("");
    var fdate =this.formatCalDate1(date);
  
    this.setState({toDate:fdate});
    this.state.startDate2 = this.formatCalDate(date);
  if(dateRangeValidate(this.state.startDate1, this.state.startDate2) == true){
    $('.date_range_error').text('');
    this.getOperationLog(this.state.pageSize,1);
    var calenderTo =  "none";
    this.setState({
      calenderTo:calenderTo
    })
  }else{
    $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('dateRangeDuration')} ${this.props.t('days')}`);
    var calenderTo =  "none";
    this.setState({
      calenderTo:calenderTo
    });
  }
  }

  formatCalDate1(date){
    var format = date.toISOString();
    var xyz = moment(format).format("DD MMM YYYY HH:mm")
    return xyz;
  }

  // get date 
  formatCalDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;

    let fromDateApi = dateformatinUTC(moment(this.state.fromDate));
    let toDateApi = dateformatinUTC(moment(this.state.toDate));

    this.setState({
      activePage: 1,
      pageSize: entries,
      fromDateApi: fromDateApi,
      toDateApi: toDateApi
    },()=>{
      this.getOperationLog(entries, 1);
    })
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
      listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
  };


  handlePageChange(pageNumber) {
    
    if (pageNumber < this.state.activePage) {
      // alert("prev")  
      //prev
        this.setState({ 
          activePage:pageNumber, 
          fromDateApi:this.state.curPageFirstCreTime,
          toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
        }, ()=> {
          this.getOperationLog(this.state.pageSize, pageNumber,"", false);
      })
        
    }else{
      //next
      let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
      this.setState({ 
        activePage:pageNumber, 
        fromDateApi:dateformatinUTC(this.state.fromDate),
        toDateApi:this.state.curPageLastCreTime,
        prevPageFirstCreTime:this.state.curPageFirstCreTime,
      }, ()=> {
        if(pageNumber > _datesArrayLength + 1){
          this.createdDatesArray(this.state.curPageFirstCreTime);
        }
        this.getOperationLog(this.state.pageSize, pageNumber,"", false);
    })
    }
    console.log(this.state.listOfFirstCreatedtimes)
  }

  keyPress3(e){
    
    var key = e.key;
   
   
    if( key == "Backspace" || key == "Delete" ){
      $(".error_emptymessageid").text("");
    }
    if(e.keyCode == 13){
        this.setState({ messageId: e.target.value }, ()=>{
          if(this.state.messageId == ""){
            $(".error_emptymessageid").text(this.props.t('enter_message_id'));
          }else{
            $(".error_emptymessageid").text("");
            this.getOperationLog(10,1,"messageClick");
          }
        });
        
      }
  }


  getOperationLog(pageSize, pageNo, action , countValue) {
    document.getElementById("loader_image_div").style.display = "block";
    let opeName = this.state.OperationNameC;
    let chargePointId = this.state.fields.chargePointId
    let messageId=this.state.messageId;

    if (opeName == undefined) {
      var url = baseUrl.URLPath + "cp/operations/logs?charge_point_id=" + chargePointId + "&start_date=" + this.state.fromDateApi + "&end_date=" + this.state.toDateApi + "&pageNo=" + pageNo + "&pageSize=" + pageSize+"&messageId=" +messageId+"&isHeartbeatChecked="+this.state.checked;
    }
    else {
      var url = baseUrl.URLPath + "cp/operations/logs?charge_point_id=" + chargePointId + "&start_date=" + this.state.fromDateApi + "&end_date=" + this.state.toDateApi + "&pageNo=" + pageNo + "&pageSize=" + pageSize +"&messageId=" +messageId+ "&name=" + this.state.OperationNameC+"&isHeartbeatChecked="+this.state.checked;
    }
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
          }, 100);
        }
        if (resp.status == 200) {
          return resp.json();
        }else if(resp.status == 400){
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          if(response.status==400){
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              $('.date_range_error').text(response.message);
            }, 100);
          }else if(response.count.count==0){
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
                if(action=="messageClick"){
                    $('.error_nomessageid').text(this.props.t('msg_id_not_exists'));
                }
                if(action=="click" || action=="enter"){ 
                    $("#notexists").text(this.props.t('charge_point_id_not_exists.'));
                  }
                this.setState({
                  items: [],
                  noOfRecords: 0
                })
              }, 100);
          }else{
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              var totalcount = "";
              if(countValue==false){
                totalcount = this.state.noOfRecords
              }else{
                totalcount = response.count.count
              }
              this.setState({
                prevPageFirstCreTime:this.state.curPageFirstCreTime,
                items:response.logs,
                noOfRecords:totalcount,
                curPageFirstCreTime: moment(response.logs[0].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                curPageLastCreTime: moment(response.logs[response.logs.length-1].createdTime).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"), 
              })
              if(action=="click" || action=="enter"){ 
                $("#notexists").text(" ");
                $("#messageid").text(" ");
                }
            }, 100);
        }
      }
      })
      .catch((error) => {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          console.log(error, "catch the loop")
        }, 100);
      })

  }


  componentDidMount() {
  let duration = localStorage.getItem('listLogDuration');
  duration = duration -1;   
  let fromDate = moment().subtract(duration,'d');
  let fromDateApi = dateformatinUTC(moment().subtract(duration,'d'));
  let toDate = new Date();
  let toDateApi = dateformatinUTC(moment(toDate));
  this.setState({
    fromDate:new Date(fromDate),
    fromDateApi:fromDateApi,
    toDate:toDate,
    toDateApi:toDateApi,
  },()=>{
    getDateRange();
    this.getOperationLog(this.state.pageSize, this.state.activePage);
  })
  }


  //change function for chargepoint status filter
  onChangeChargepoint() {
    var chargePoint = document.getElementById("chargePointId").value;
    this.setState({ chargePointId: chargePoint }, () => {
      this.getOperationLog(this.state.pageSize, 1);
    });
    
    if(this.state.fields.chargePointId == ""){
      this.state.errors["entercharge"] = this.props.t('enter_charge_point_id')
      $("#notexists").text(" ");
    
    }else{
      this.state.errors["entercharge"] = " "
      this.getOperationLog(this.state.pageSize, 1,"click");
    }
  }

  //function for sorting table data on click
  ascendingOrder() {
    // this.setState({ items: this.state.items.reverse() })

    if(this.state.cpIdSorting == false){
      this.state.items.sort(this.dynamicSort("chargePonintId"));
      this.state.cpIdSorting = true;
         this.setState({items : this.state.items.sort(this.dynamicSort("chargePonintId"))})
    }
     else{
      this.state.items.sort(this.dynamicSort("chargePonintId"));
      this.state.cpIdSorting = false;
         this.setState({items : this.state.items.reverse()});
     } 
  }
  dynamicSort(property) {
    var sortOrder = 1;
  
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
  
    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
  
            return a[property].localeCompare(b[property]);
        }        
    }
  }
  handleChecked(){
    this.state.checked = !this.state.checked;
    this.state.activePage=1;
    this.getOperationLog(this.state.pageSize,this.state.activePage);
  }

  // for from date calender 
  handleFromChange = date => {
    $('.future_err1').text("");
    this.setState({fromDate:date});
    this.setState({
       fromDateApi: dateformatinUTC(date),
       activePage:1
  }, ()=> {
   
    if(dateRangeValidateForCPlog(this.state.fromDate, this.state.toDate) == true){
      $('.date_range_error').text('');
      this.getOperationLog(this.state.pageSize,1);
  }else{
      $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('listLogDuration')} ${this.props.t('days')}`);
  }
    
  });
    this.setState({ disabledAttr: "" });
  };

  // for to date calender 
  handleToChange = date => {
    this.setState({ toDate:date });
    this.setState({
      toDateApi:dateformatinUTC(date),
      activePage:1
   }, ()=> {
        if(dateRangeValidateForCPlog(this.state.fromDate, this.state.toDate) == true){
        $('.date_range_error').text('');
        this.getOperationLog(this.state.pageSize,1);
        }else{
        $('.date_range_error').text(`${this.props.t('select_date_range_between')} ${localStorage.getItem('listLogDuration')} ${this.props.t('days')}`);
        }
    });
    this.setState({disabledAttr: ""});
  };

  render() {
    const{t}=this.props
    const operationData = this.state.items.map((item, index) => {
      // var time = item.createdTime.split(" ");
      var status_value = "Rejected";

      if (item.responseMessage != "null" || item.responseMessage != "") {



      }
      if (item.createdTime == null || item.createdTime == "") {
        var createdTime = "";
        var createdTime1 = "";
      } else {
        var createdTime = moment(item.createdTime).format("DD MMM YYYY");
        var createdTime1 = moment(item.createdTime).format("HH:mm:ss");
      }

      if (item.responseTime == null || item.responseTime == "") {
        var responseTime = "";
        var responseTime1 = "";
      } else {
        var responseTime = moment(item.responseTime).format("DD MMM YYYY");
        var responseTime1 = moment(item.responseTime).format("HH:mm:ss");
      }

      return (
        <tr>
          <td className="wrap-word">{createdTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {createdTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {createdTime1} </div> </>}</td>
          <td className="">{item.chargePonintId}</td>
          <td className="">{item.name}</td>
          <td className="wrap-word">{responseTime && <><div className="nowrap pull-left pb-0 w-100"><i className="fas fa-calendar-alt fa-sm"></i> {responseTime}</div><div className="timespan pull-left pb-0"><i className="far fa-clock fa-sm"></i> {responseTime1} </div> </>}</td>
          <td className=""><i class="fas fa-eye action-icon cursor_defult" title="View" onClick={this.toggleView.bind(this,item.messageId, item.requestMessage, item.responseMessage)} ></i></td>
        </tr>
      )
    });

    return (
      <>
        <main class="content-div" >
          <p> {t('CP Logs')}
              <div className="breadcrumb_div">
              {t('Maintenance')} &gt; <span className="breadcrumb_page">{t('CP Logs')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
       
            <div className="row mt-2">
              <div className="col-md-3 pull-right">
                <div class="input-group mt-2">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="chargePointId" value={this.state.fields["chargePointId"]} onChange={this.changeHandler.bind(this, "chargePointId")} onKeyDown = {this.keyPress}
                    class="form-control input-searchBox pad-2" placeholder={t('charge_point_id')} name="srch-term" onFocus={this.Focus} />
                      <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick} >
                  <span aria-hidden="true">×</span>
                </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeChargepoint} >{t('find')}</button>
                  </div>
                </div>
                <span className="error_msg w3-animate-top mt-2">  <span style={{color: "red"}}>{this.state.errors["entercharge"]}</span></span> 
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
              </div>

              <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">{t('from_time')}</span>
                <div className="pull-left mt-2" style={{width:"68%" }} >
                  <DatePicker
                    selected={this.state.fromDate}
                    onChange={this.handleFromChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    maxDate={(this.state.toDate!=""&&this.state.toDate!=null)? new Date(this.state.toDate):new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
              </MDBCol>

              <MDBCol md="4" className='pr-0 calWidth'>
                <span className="cal_label mr-2">{t('to_time')}</span>
                <div className="pull-left mt-2" style={{ width: "70%" }} >
                  <DatePicker
                    selected={this.state.toDate}
                    onChange={this.handleToChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    id="expiryDateId"
                    className="cal_margin tag_callogs"
                    timeCaption="time"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    minDate={new Date(this.state.fromDate)}
                    maxDate={new Date()}
                  />
                </div>
                <div className="error_msg w3-animate-top col-md-12  datetimeerror pl-0">  <span style={{ color: "red" }}>{this.state.errors["expirydate"]}</span></div>
              </MDBCol>
              <div className='col-md-3'></div>
              <div className='col-md-9 date_range_error' style={{ color: "red" }}></div>
              </div>
              <div className="row">
              <div className="col-md-3 mt-2">
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id = "messageId" value={this.state.messageId}  onChange={(e)=>{this.setState({messageId: e.target.value});$('.error_nomessageid').text("");$(".error_emptymessageid").text("");}}  onKeyDown = {this.keyPress3}
                    class="form-control input-searchBox pad-2" placeholder={t('message_Id')} name="srch-term"  />
                      <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick1} >
                  <span aria-hidden="true">×</span>
                </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeMessageId} >{t('find')}</button>
                  </div>
                </div>
                <span className="pull-left error_msg w3-animate-top mt-1 error_nomessageid" style={{color: "red"}}></span>
                <span className="pull-left error_msg w3-animate-top mt-1 error_emptymessageid" style={{color: "red"}}></span>
              </div>
              <MDBCol md="3" >
                <select className="browser-default custom-select select_height mb_8" id="operationName" onChange={this.OperationNameChange} >
                  <option value="">{t('select_operation_name')}</option>
                  <option value="Authorize">{t('authorize')}</option>
                  <option value="StartTransaction">{t('start_transaction')}</option>
                  <option value="StopTransaction">{t('stop_transaction')}</option>
                  <option value="StatusNotification">{t('status_notification')}</option>
                  <option value="MeterValues">{t('meter_values')}</option>
                  <option value="BootNotification">{t('boot_notification')}</option>
                  <option value="SetChargingProfile">{t('Set Charging Profile')}</option>
                  <option value="ClearChargingProfile">{t('Clear Charging Profile')}</option>
                  <option value="Heartbeat">{t('heartbeat')}</option>
                  <option value="FirmwareStatusNotification">{t('firmware_notification')}</option>


                </select>
              </MDBCol>
              <div className="col-md-6 pt-1">
                <div className="pull-right">
                  <label className="pull-left sub-text">{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>

            {/* table start */}
            <div className="row ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="">{t('created_date_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('charge_point_id')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('operation_name')}</th>
                      <th className="">{t('response_time')} <i class="fas fa-sort sortIcon" onClick={this.ascendingOrder.bind(this)}></i></th>
                      <th className="">{t('view')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {operationData}
                    {
                      (this.state.items.length == 0) ? <td colSpan={5} style={{ textAlign: "center" }}>{t('no_data_available_for_cp_logs')}</td> : null
                    }
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                  prevPageText ={t('prev')}
                  nextPageText = {t('next')}
                  itemClassPrev ="prevBtn"
                  pageRangeDisplayed = "1"
                  activeClass = "activeLi active"
                  itemClassNext ="nextBtn"
                  hideFirstLastPages = {true}
                />
              </div>
              {/* End table  */}
            </div>
          </div>

          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
      </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.sessiontagOk}>{t('ok')}</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* Popup for  View */}
          <MDBModal isOpen={this.state.modalView} toggle={this.toggleView} size="lg" className="model_top">
            <div data-test="modal-header" class="modal-header">
              <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>{t('view')}</div>
            </div>
            {/* <MDBModalHeader toggle={this.toggleView}>View</MDBModalHeader> */}
            <MDBModalBody>
            <MDBRow className="mb-4 mt-3" >

<MDBCol sm="12">
  <div className="view-page-row view-bg-row" >
   
  <b>{t('message_id')}</b>  <span className="wrap-word">
    {this.state.displaymessage}
  
    </span>

   
  </div>
</MDBCol>
</MDBRow>
              <MDBRow className="mb-4 mt-3" >

                <MDBCol sm="12">
                  <div className="view-page-row view-bg-row" >
                    <label className="">
                      {t('request_message')}
            </label>
                    <label className="wrap-word">
                      {this.state.displayReq}

                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-4 mt-3" >

                <MDBCol sm="">
                  <div className="view-page-row view-bg-row">
                    <label className="w-40">
                      {t('response_message')}
            </label>
                    <label className="wrap-word">

                      {this.state.displayRes}
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter className="border-top-0 p-3">
              {/* <MDBBtn color="danger" onClick={this.toggleView1}>Close</MDBBtn> */}
              <button onClick={this.toggleView1} className="refresh-btn">{t('close')}</button>
              {/* <MDBBtn color="primary">Save</MDBBtn> */}
              {/* onClick={this.addBuildtype} */}
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default withTranslation()(logs);