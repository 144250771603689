import React from 'react';
import * as baseUrl from './constants';

export class APICallUtility extends React.Component {
    //common method for commn messgaes oprations
    static async cpmsProcessMessages(type, payload, cplist) {
        var data = {
            "type": type,
            "payload": payload,
            "cplist": cplist
        };

        var timeZone=  localStorage.getItem("timezone");
        let url = baseUrl.URLPath + "cs/operations/"+type;
        let responseFromAPI = "";

        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization"),
                "timeZone":timeZone
            }
        }).then(resp => {
            if (resp.status === 200) {
                return resp.json();
            } else {
                responseFromAPI = "Bad request";
            }
        }).then(response => {
            if (response[0] === "3") {
                responseFromAPI = "Request is processed Successfully";
            }
            else {
                responseFromAPI = response[3];
            }
        }
        ).catch(error => {
            responseFromAPI = error;
        });
        return responseFromAPI;
    }
}

export default APICallUtility;