import React from 'react';
import { MDBCol, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form, Table } from 'react-bootstrap'
import * as baseUrl from './constants';
import * as roles from './roles';
import * as GeneralUser from './GeneralUser';
import * as PlatformUser from './PlatformUser';
import * as TenantUser from './TenantUser';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import $ from "jquery";
import { createBrowserHistory } from 'history';
import { hasPermission } from './auth';
import Pagination from "react-js-pagination";
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import './cpDashboard.css';

const history = createBrowserHistory();

class userManagementController extends React.Component {
  constructor(props) {
    super(props);
    let selectedSite;
    try {
      selectedSite = JSON.parse(localStorage.getItem('SelectedSite'));
    } catch (error) {
      selectedSite = null;
    }
    this.state = {
      fields: {
        emailId: ""
      },
      errors: {
        selectChargepoint: "",
        notexists: ""
      },
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      actiondisplay: "",
      activeArray: [],
      checked: false,
      activeDeactiveArray: '',
      list: [],
      page_title: "Users",
      sucess: false,
      userArray: [],
      model1: "false",
      model2: "false",
      id: "",
      dataArray: [],
      org_name: '',
      modalsession: false,
      user: {
        permissions: [],
      },
      fromDateApi: '',
      toDateApi: '',
      curPageFirstCreTime: "",
      curPageLastCreTime: "",
      prevPageFirstCreTime: "",
      listOfFirstCreatedtimes: [],
      roles: [],
      selectedSite
    }
    this.addNew = this.addNew.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.getUserslist = this.getUserslist.bind(this)
    this.activeUsers = this.activeUsers.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.tagOk2 = this.tagOk2.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.closeOkPopup = this.closeOkPopup.bind(this)
    this.toggle = this.toggle.bind(this)
    this.togglesession = this.togglesession.bind(this)
    this.sessiontagOk = this.sessiontagOk.bind(this)
    this.getNonWebastoUsers = this.getNonWebastoUsers.bind(this);
    this.goLoginHistory = this.goLoginHistory.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onChangeOrg = this.onChangeOrg.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.createdDatesArray = this.createdDatesArray.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.saveFilterDetails = this.saveFilterDetails.bind(this);
    this.removeFilterDetails = this.removeFilterDetails.bind(this);
    this.initialRender = this.initialRender.bind(this);
  }

  createdDatesArray(dateTime) {
    let listOfFirstCreatedtimes = [...this.state.listOfFirstCreatedtimes, dateTime];
    this.setState({
        listOfFirstCreatedtimes: listOfFirstCreatedtimes
    });
}

  keyPress(e) {
    var key = e.key;
    if (e.keyCode == 13) {
      this.setState({ emailId: e.target.value });
      this.getUserslist(this.state.id, this.state.pageSize, 1);
      if (this.state.fields.emailId == "") {
        this.state.errors["selectChargepoint"] = this.props.t('please_enter_email')
        $("#notexists").text(" ");
      } else {
        this.state.errors["selectChargepoint"] = " "
        this.getUserslist(this.state.id, this.state.pageSize, 1, "enter");
      }
    }

    if (key == "Backspace" || key == "Delete") {
      var x = e.target.value
      var sno = x.slice(0, x.length - 1)
      if (sno == "") {
        this.state.fields.emailId = sno;
        this.getUserslist(this.state.id, this.state.pageSize, 1);
      }
    }
    switch (e.keyCode) {
      case 8:  // Backspace
      case 9:  // Tab
      case 13: // Enter
      case 37: // Left
      case 38: // Up
      case 39: // Right
      case 40: // Down
        break;
      default:
        var regex = new RegExp("^[a-zA-Z0-9!#$%&'*+-/=_`{|}~.@]");
        var key = e.key;
        if (!regex.test(key)) {
          e.preventDefault();
          return false;
        }
        break;
    }
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    this.state.pageSize = entries;
    this.state.activePage = 1;
    this.state.fromDateApi = '';
    this.state.toDateApi = '';
    this.getUserslist(this.state.id, entries, 1);
  }
  handlePageChange(pageNumber) {
    if (pageNumber < this.state.activePage) {
        //prev
        this.setState({
            activePage: pageNumber,
            fromDateApi: this.state.curPageFirstCreTime,
            toDateApi: this.state.listOfFirstCreatedtimes[pageNumber - 1]
        }, () => {
          this.getUserslist(this.state.id,this.state.pageSize, pageNumber,"",false);
        });
    } else {
        //next
        
        let _datesArrayLength = this.state.listOfFirstCreatedtimes.length;
        this.setState({
            activePage: pageNumber,
            fromDateApi: '',
            toDateApi: this.state.curPageLastCreTime,
            prevPageFirstCreTime: this.state.curPageFirstCreTime,
        }, () => {
            if (pageNumber > _datesArrayLength + 1) {
                this.createdDatesArray(this.state.curPageFirstCreTime);
            }
            this.getUserslist(this.state.id,this.state.pageSize, pageNumber,"",false);
        });
    }
}
  handleClick() {
    if(this.state.errors["selectChargepoint"]!="" || $("#notexists").text()!=""){
      let fields = this.state.fields;
      fields.emailId = '';
      this.state.errors["selectChargepoint"] = "";
      $("#notexists").text("");
      this.setState({
        fields
      });
    }else{
      let fields = this.state.fields;
      fields.emailId = '';
      this.state.errors["selectChargepoint"] = "";
      $("#notexists").text("");
      this.setState({
        fields
      });
      this.state.activePage = 1;
      this.getUserslist(this.state.id, this.state.pageSize, this.state.activePage);
    }
  }
  changeHandler(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.emailid = document.getElementById('emailId')
    this.state.errors["selectChargepoint"] =this.props.t('please_enter_email')
    this.setState({ fields });
    this.state.errors["selectChargepoint"] = ""
    $("#notexists").text(" ");
  }
  //change function for user  filter
  onChangeEmail() {
    // var email = document.getElementById("emailId").value;
    // this.setState({ emailId: email }, () => {
    //   this.getUserslist(this.state.id, this.state.pageSize, 1);
    // });
    debugger
    let errors = {}
    if (this.state.fields.emailId == "") {
      errors["selectChargepoint"] = this.props.t('please_enter_email')
      this.setState({
        errors: errors
      });
      $("#notexists").text(" ");
    } else {
      this.state.errors["selectChargepoint"] = "";
      this.setState({
        errors: errors
      },()=>{
        this.getUserslist(this.state.id, this.state.pageSize, 1, "click");
      });
    }
  }
  onChangeOrg() {
    this.state.activePage = 1;
    this.state.fromDateApi = '';
    this.state.toDateApi = '';
    this.getUserslist(this.state.id, this.state.pageSize, 1);
  }
  async goLoginHistory() {
    this.props.history.push('/userLoginHistory');
  }
  async togglesession() {
    this.setState({
      modalsession: !this.state.modalsession
    });
  }
  sessiontagOk() {
    history.push('/');
    window.location.reload();
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleConfirmation(event) {
    this.setState({
      modal1: !this.state.modal1
    });
    if (this.state.list[0] != "" && this.state.list[0] != undefined) {
      this.setState({
        delete_msg:this.props.t('are_you_want_to_delete'),
        deleteHeader:this.props.t('confirmation'),
        deleteButton: "",
        deleteText:this.props.t('close'),
        deletecolor: "danger"
      })
    } else {
      this.setState({
        delete_msg:this.props.t('please_select_user'),
        deleteHeader: "Warning",
        deleteButton: "d-none",
        deleteText:this.props.t('ok'),
        deletecolor: "primary"
      })
    }
  }
  closeOkPopup() {
    this.setState({
      modal: !this.state.modal
    });
    window.location.reload();
  }
  tagOk1() {
    window.location.reload();
  }
  tagOk2() {
    window.location.reload();
  }
  toggle1() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }
  closeModel() {
    window.location.reload();
  }
  addNew() {
    this.props.history.push('/addUserManagement');
  }
  // calling function when click on checkbox
  handleChange(id, identifier, event) {

    let arr = [];
    var newlist = [];
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue == true) {
      if (this.state.list[0] != "" && this.state.list[0] != undefined) {
        document.getElementById(this.state.list[0]).checked = false;
        document.getElementById(id).checked = true;
      }
      this.state.list[0] = id;
      this.setState({
        activeArray: identifier
      });
    }
    if (checkedValue == false) {
      this.state.list[0] = "";
      document.getElementById(id).checked = false;
      this.setState({
        activeArray: ""
      });
    }
    this.state.activeDeactiveArray = identifier;
  }
  getUserslist(id1, pageSize, pageNo, action, countValue) {
    var role = localStorage.getItem('role');

    if (id !== "") {
      id = this.state.id;
    } else {
      id = id1;
    }

    this.setState({ id: id })
    var id = document.getElementById("organizationid").value;
    var roleId = '';
    // if(localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS){
    //   roleId= document.getElementById("roleid").value;
    // }
    var el = document.getElementById('organizationid')
    var selectedText = el.options[el.selectedIndex].text
    this.setState({
      org_name: selectedText
    });
    var url = "";
    if(role == roles.WEBASTO_CUSTOMER_ADMIN || role == roles.WEBASTO_SITE_ADMIN){
      url = TenantUser.GET_USERS +"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + this.state.fields.emailId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi +"&role=" + roleId;
    }else if(role == roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role == roles.WEBASTO_SUPER_ADMIN_DEVOPS){
      url = PlatformUser.GET_USERS +"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + this.state.fields.emailId + "&id=" + id + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi+"&role=" + roleId;
    }else if(role == roles.WEBASTO_END_USER_RESIDENTIAL || role == roles.WEBASTO_END_USER_COMMERCIAL){
      url = GeneralUser.GET_USERS +"?pageSize=" + pageSize + "&pageNo=" + pageNo + "&email=" + this.state.fields.emailId + "&fromTime=" + this.state.fromDateApi + "&toTime=" + this.state.toDateApi+"&role=" + roleId;
    }
    document.getElementById("loader_image_div").style.display = "block";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          setTimeout(() => {
            document.getElementById("loader_image_div").style.display = "none";
            this.togglesession();
            localStorage.clear();
        }, 100);
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          if (response.count.count == 0) {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              if (action == "click" || action == "enter") {
                $("#notexists").text(this.props.t('user_not_exists'));
              }else{
                $("#notexists").text(this.props.t('user_not_exists'));
              }
            }, 100);
          } else {
            setTimeout(() => {
              document.getElementById("loader_image_div").style.display = "none";
              if (countValue == false) {
                this.setState({
                  userArray: response.userList,
                  prevPageFirstCreTime: this.state.curPageFirstCreTime,
                  curPageFirstCreTime: moment(response.userList[0].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                  curPageLastCreTime: moment(response.userList[response.userList.length - 1].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
              });
              } else {
                this.setState({
                  userArray: response.userList,
                  prevPageFirstCreTime: this.state.curPageFirstCreTime,
                  noOfRecords: response.count.count,
                  curPageFirstCreTime: moment(response.userList[0].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                  curPageLastCreTime: moment(response.userList[response.userList.length - 1].createdDate).utc().format("YYYY-MM-DDTHH:mm:ss:SSS"),
                });
              }
                $("#notexists").text("");
            }, 100);
          }
        }
      })
      .catch((error) => {
        setTimeout(() => {
          document.getElementById("loader_image_div").style.display = "none";
          console.log(error, "catch the loop");
        }, 100);
      })
  }
  activeUsers(status) {
    var role = localStorage.getItem('role');

    if (this.state.activeArray != "" && this.state.activeArray.length != "0") {
      if (status == true) {
        var url = "";
        if (role == roles.WEBASTO_CUSTOMER_ADMIN || role == roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.ACTIVATE_USER + this.state.activeArray;
        } else if (role == roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role == roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          url = PlatformUser.ACTIVATE_USER + this.state.activeArray;
        } else if (role == roles.WEBASTO_END_USER_RESIDENTIAL || role == roles.WEBASTO_END_USER_COMMERCIAL) {
          url = GeneralUser.ACTIVATE_USER + this.state.activeArray;
        }
      } else {
        var url = "";
        if (role == roles.WEBASTO_CUSTOMER_ADMIN || role == roles.WEBASTO_SITE_ADMIN) {
          url = TenantUser.DEACTIVATE_USER + this.state.activeArray;
        } else if (role == roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role == roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
          url = PlatformUser.DEACTIVATE_USER + this.state.activeArray;
        } else if (role == roles.WEBASTO_END_USER_RESIDENTIAL || role == roles.WEBASTO_END_USER_COMMERCIAL) {
          url = GeneralUser.DEACTIVATE_USER + this.state.activeArray;
        }
      }
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          return resp.json();
        })
        .then((response) => {
          if (status == 0) {
            this.toggle1()
          } else {
            this.toggle2()
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else if (this.state.activeArray == "" && this.state.activeArray.length == "0") {
      this.toggleConfirmation();
    }
  }
  componentDidMount() {
    //to clear saved filters when click on F5 or click on page reload
    window.onload = () => {
      this.removeFilterDetails();
    }
    if(localStorage.getItem('role') == roles.WEBASTO_SUPER_ADMIN_DEVOPS){
      this.getRoles();
    }else{
      this.initialRender();
    }
  }
  initialRender(){
    this.state.user.permissions = localStorage.getItem("roleAccess");
    var orgname = localStorage.getItem("tenant");
    this.setState({
      org_name: orgname
    });
    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ dataArray: response.organisationDTOS }, ()=>{
            //retrieving past filters data 
            if(localStorage.getItem('filterEmail')){
              this.state.fields['emailId'] = localStorage.getItem('filterEmail')
            }
            if(localStorage.getItem('filterOrganization')){
              document.getElementById("organizationid").value = localStorage.getItem('filterOrganization')
            }
            // if(localStorage.getItem('filterRole')){
            //   document.getElementById("roleid").value = localStorage.getItem('filterRole')
            // }
            if(localStorage.getItem('filterShowEntries')){
              this.state.pageSize = localStorage.getItem('filterShowEntries')
            }
            if(localStorage.getItem('filterFromDate')){
              this.state.fromDateApi = localStorage.getItem('filterFromDate')
            }
            if(localStorage.getItem('filterToDate')){
              this.state.toDateApi = localStorage.getItem('filterToDate')
            }
            if(localStorage.getItem('filterActivePage')){
              this.state.activePage = parseInt(localStorage.getItem('filterActivePage'))
            }
            if(localStorage.getItem('filterCurPageFirstCreTime')){
              this.state.curPageFirstCreTime = localStorage.getItem('filterCurPageFirstCreTime')
            }
            if(localStorage.getItem('filterCurPageLastCreTime')){
              this.state.curPageLastCreTime = localStorage.getItem('filterCurPageLastCreTime')
            }
            if(localStorage.getItem('filterPrevPageFirstCreTime')){
              this.state.prevPageFirstCreTime = localStorage.getItem('filterPrevPageFirstCreTime')
            }
            if(localStorage.getItem('filterNoOfRecords')){
              this.state.noOfRecords = localStorage.getItem('filterNoOfRecords')
              if(this.state.noOfRecords > 0){
                this.getUserslist(first_org, this.state.pageSize, this.state.activePage, '', false);
              }
            }else{
              var user_data = [];
              user_data = response.organisationDTOS;
              var first_org = "";
              for (var i = 0; i <= user_data.length; i++) {
                first_org = user_data[0].id;
              }
              this.getUserslist(first_org, this.state.pageSize, this.state.activePage);
            }
            if(localStorage.getItem('filterListOfFirstCreatedtimes')){
              this.state.listOfFirstCreatedtimes = JSON.parse(localStorage.getItem('filterListOfFirstCreatedtimes'))
            }
          })
        } else {
          this.getNonWebastoUsers();
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  getNonWebastoUsers() {
    var url = baseUrl.URLPath + "v1.0/tenant/Nonwebasto";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        if (response != undefined) {
          this.setState({ userArray: response.userDTOS });
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }
  deleteUser() {
    var role = localStorage.getItem('role');
    this.setState({
      modal1: !this.state.modal1
    });
    var id;
    id = this.state.id;
    this.setState({ id: id });
    var url = "";
    if (role == roles.WEBASTO_CUSTOMER_ADMIN || role == roles.WEBASTO_SITE_ADMIN) {
      url = TenantUser.DELETE_USER + this.state.activeArray;
    } else if (role == roles.WEBASTO_SUPER_ADMIN_CUSTOMER_SERVICE || role == roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
      url = PlatformUser.DELETE_USER + this.state.activeArray;
    } else if (role == roles.WEBASTO_END_USER_RESIDENTIAL || role == roles.WEBASTO_END_USER_COMMERCIAL) {
      url = GeneralUser.DELETE_USER + this.state.activeArray;
    }
    fetch(url,
      {
        method: 'DELETE', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization"),
        }
      }
    )
      .then(res => res.json())
      .then(resp => {
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        else if (resp.status == 404) {
          return resp.json();
        }
        else if (resp.status == 500) {
          alert("internal server error")
        } else if (resp.status == 400) {
          alert("Bad request")
        } else {
          if(localStorage.getItem('userId') == this.state.activeArray){
            localStorage.setItem('Authorization','');
            this.props.history.push('/');
            window.location.reload();
          }
          this.setState({
            modal: !this.state.modal
          });
        }
      })
      .catch(error => alert('Error:' + error));
  }
  async getRoles() {
    var url = baseUrl.URLPath + "roles/";
    await fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
    .then((resp) => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
      }
      if (resp.status == 200) {
        return resp.json();
      }
    })
    .then((response) => {
      if (response != undefined) {
        this.setState({ roles: response.roles }, ()=>{
          this.initialRender();
        })
      }
    })
    .catch((error) => {
      console.log(error, "catch the loop")
    })
  }

  saveFilterDetails(){
    var id = document.getElementById("organizationid").value;
    // var role = document.getElementById("roleid").value;
    localStorage.setItem('filterEmail', this.state.fields['emailId']);
    localStorage.setItem('filterOrganization', id);
    // if(localStorage.getItem('role') === roles.WEBASTO_SUPER_ADMIN_DEVOPS){
    //   localStorage.setItem('filterRole', role);
    // }
    localStorage.setItem('filterShowEntries', this.state.pageSize);
    localStorage.setItem('filterFromDate', this.state.fromDateApi);
    localStorage.setItem('filterToDate', this.state.toDateApi);
    localStorage.setItem('filterActivePage', this.state.activePage);
    localStorage.setItem('filterCurPageFirstCreTime', this.state.curPageFirstCreTime);
    localStorage.setItem('filterCurPageLastCreTime', this.state.curPageLastCreTime);
    localStorage.setItem('filterPrevPageFirstCreTime', this.state.prevPageFirstCreTime);
    localStorage.setItem('filterNoOfRecords', this.state.noOfRecords);
    localStorage.setItem('filterListOfFirstCreatedtimes', JSON.stringify(this.state.listOfFirstCreatedtimes));
  }

  removeFilterDetails(){
    localStorage.removeItem('filterEmail');
    localStorage.removeItem('filterOrganization');
    // localStorage.removeItem('filterRole');
    localStorage.removeItem('filterShowEntries');
    localStorage.removeItem('filterFromDate');
    localStorage.removeItem('filterToDate');
    localStorage.removeItem('filterActivePage');
    localStorage.removeItem('filterCurPageFirstCreTime');
    localStorage.removeItem('filterCurPageLastCreTime');
    localStorage.removeItem('filterPrevPageFirstCreTime');
    localStorage.removeItem('filterNoOfRecords');
    localStorage.removeItem('filterListOfFirstCreatedtimes');
  }

  componentWillUnmount(){
    if(window.location.pathname !== '/editUser'){
      this.removeFilterDetails();
    } 
  }

  render() {
    const{t}=this.props
    if (localStorage.getItem("role") == roles.WEBASTO_SUPER_ADMIN_DEVOPS) {
      this.state.actiondisplay = "display:block";
    }
    else {
      this.state.actiondisplay = "display:block";
    }

    let userTenant = localStorage.getItem("tenant");
    const user = this.state.user;
    let selectedId = "";

    const organisationDTOS = this.state.dataArray.map((dataArray, index) => {
      if (userTenant === dataArray.identifier) {
        selectedId = dataArray.id
      }
      return (
        <option value={dataArray.id} selected={selectedId == dataArray.id} >{dataArray.company}</option>
      )
    })

    const roleDTOS = this.state.roles.map((dataArray, index) => {
      return (
        <option value={dataArray.identifier}>{dataArray.name}</option>
      )
    })

    const userDTOS = this.state.userArray.map((userArray, index) => {

      // logic for getting rfids by comma saparated
      var dictRfids = userArray.rfids;
      var getRfids = [];

      if (dictRfids != undefined) {
        for (var i = 0; i < dictRfids.length; i++) {
          getRfids.push(dictRfids[i]["rfidTag"]);
        }
      }

      var commaSepArray = getRfids.join(", ");

      if (userArray.status == true) {
        userArray.status = "Active";
      } else {
        if (userArray.status == false) {
          userArray.status = "Inactive";
        }
      }
      return (
        <tr>
          <td className="text-center">
            <Form.Check
              custom
              type="checkbox"
              id={`custom-${userArray.id}`}
              label=""
              onChange={this.handleChange.bind(this, `custom-${userArray.id}`, userArray.id)}
              key={userArray.id}
              className="checkbox"
            />
          </td>
          <td>{userArray.id}</td>
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.email}</td>}
          <td> {commaSepArray}</td>
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.firstName}</td>}
          {hasPermission(user, ['UserDetail_R']) &&
            <td>{userArray.lastName}</td>}
          <td>{userArray.role}</td>
          <td>{userArray.status}</td>
          {hasPermission(user, ['Users_U']) && <td style={{ display: this.state.actiondisplay }}>
            <Link to={{
              pathname: '/editUser',
              state: userArray.id,
            }}>
              <i class="fas fa-pencil-alt pr-2 action-icon edit_usericon" title="Edit" onClick={this.saveFilterDetails}></i>
            </Link>
          </td>}
        </tr>
      )
    });

    let tenantDisable = false;

    if(this.state.selectedSite && this.state.selectedSite?.name){
      tenantDisable = true;
    }

    return (
      <>
        <main class="content-div" >
          <p>{t('Users')}
            <div className="breadcrumb_div">
              {t('Settings')} &gt; <span className="breadcrumb_page"> {t('Users')}</span>
            </div>
          </p>
          <div className="page-outerdiv">
            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            <div className="row">
              <div className="col-md-12 ">
                <div className="menubar pull-left">
                  {hasPermission(user, ['Users_W']) && <button onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>{t('add')}</span>
                  </button>}
                  {hasPermission(user, ['Users_AD']) && <button onClick={this.activeUsers.bind(this, true)} >
                    <i className="fas fa-toggle-on">
                    </i>
                    <span>{t('active')}</span>
                  </button>}
                  {hasPermission(user, ['Users_AD']) && <button onClick={this.activeUsers.bind(this, false)} >
                    <i className="fas fa-toggle-off">
                    </i>
                    <span>{t('deactive')}</span>
                  </button>}
                  {hasPermission(user, ['Users_D']) && <button onClick={this.toggleConfirmation}>
                    <i className="fas fa-trash">
                    </i>
                    <span>{t('delete')}</span>
                  </button>}
                </div>
                <button class="btn_primary pull-right mt-0  mr-0" type="button" onClick={this.goLoginHistory} >
                 {t('view_login_history')}
                </button>
              </div>
            </div>
            <div className="row  mt-3">
              <MDBCol md="3" className="mt-2" >
                <div class="input-group">
                  <i className="fas fa-search fa-sm input-searchIcon"></i>
                  <input type="text" id="emailId" onKeyDown={this.keyPress} value={this.state.fields["emailId"]} style={{ width: "60%" }} onChange={this.changeHandler.bind(this, "emailId")} class="form-control input-searchBox pad-2" placeholder={t('e_mail')} name="adminAddress" />
                  <button type="button" data-test="button" className="closinginput" aria-label="Close" onClick={this.handleClick} >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="input-group-btn">
                    <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail} >{t('find')}</button>
                  </div>
                  <span className="error_msg w3-animate-top mt-2"><span style={{ color: "red" }}>{this.state.errors["selectChargepoint"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{ color: "red" }}>  <span style={{ color: "red" }}>{this.state.errors["notexists"]}</span></span>
                </div>
              </MDBCol>
              <div className="col-md-3 mt-0">
                <select className="browser-default custom-select select_height " id="organizationid" onChange={this.onChangeOrg.bind(this)} disabled={tenantDisable}>
                  {organisationDTOS}
                </select>
              </div>
              <div className="col-md-3 pull-right mt-0">
              {/* {
                localStorage.getItem('role') === "WEBASTO_SUPER_ADMIN_DEVOPS" &&
                  <select className="browser-default custom-select select_height" id="roleid" onChange={this.onChangeOrg.bind(this)}>
                    <option value=''>-- Select Role --</option>
                    {roleDTOS}
                  </select>
              } */}
              </div>
              <div className="col-md-3 mt-2">
                <div className="pull-right">
                  <label className="sub-text pull-left" >{t('show_entries')}</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* {/ end page menu bar  /}
          
          {/ table start /} */}
            <div className="row mt-2 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">
                  <thead>
                    <tr>
                      <th className="text-center"></th>
                      <th>{t('transactionId')}</th>
                      {hasPermission(user, ['UserDetail_R']) && <th>{t('email')}</th>}
                      <th>{t('idTag')}</th>
                      {hasPermission(user, ['UserDetail_R']) && <th>{t('firstName')}</th>}
                      {hasPermission(user, ['UserDetail_R']) && <th>{t('lastName')}</th>}
                      {/* <th>Mobile No</th> */}
                      <th>{t('role')}</th>
                      <th>{t('chargingSessionState')}</th>
                      {hasPermission(user, ['Users_U']) && <th style={{ display: this.state.actiondisplay }}>{t('action')}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {userDTOS}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  // hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                  prevPageText={t('prev')}
                  nextPageText={t('next')}
                  itemClassPrev="prevBtn"
                  pageRangeDisplayed="1"
                  activeClass="activeLi active"
                  itemClassNext="nextBtn"
                  hideFirstLastPages={true}
                  disableInitialCallback={true}
                  disabledClass="disabled disabledtextColor"
                />
              </div>
              {/* {/ End table  /} */}
            </div>
          </div>
          {/* Loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
            <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
          </div>

          {/* User De - Activate Success Popup */}
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
              {t('user_deactive_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.tagOk1}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* User Activation Success Popup */}
          <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
             {t('user_activated_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
            <button type='button' className='btn_primary' onClick={this.tagOk2}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* User Deletion Popup */}
          <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleConfirmation}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.toggleConfirmation}>{this.state.deleteText}</button>
              {/* <MDBBtn color={this.state.deletecolor} onClick={this.toggleConfirmation}>{this.state.deleteText}</MDBBtn> */}
              <button type='button' className={`btn_primary ${this.state.deleteButton}`} onClick={this.deleteUser}>{t('yes')}</button>
              {/* <MDBBtn color="primary" className={} onClick={this.deleteUser}>YES</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>

          {/* User Deletion Success Popup */}
          <MDBModal isOpen={this.state.modal} backdrop="static" data-backdrop="static" data-keyboard="false" size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>{t('success')}</MDBModalHeader>
            <MDBModalBody>
             {t('user_deleted_successfully')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.closeOkPopup}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for session expire */}
          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>{t('session_has_expired')}</MDBModalHeader>
            <MDBModalBody>
              {t('please_login_again')}
            </MDBModalBody>
            <MDBModalFooter>
              <button type='button' className='btn_primary' onClick={this.sessiontagOk}>{t('ok')}</button>
            </MDBModalFooter>
          </MDBModal>
        </main>
      </>
    );
  }
}

export default withTranslation()(userManagementController);